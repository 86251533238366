import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

import { VerticalDivider } from 'components/UI/Dividers/VerticalDivider';

const Divider = () => {
	const [, theme]: any = useStyletron();

	return (
		<Block
			marginLeft='0.7rem'
			marginRight='0.7rem'
			marginTop={[0, 0, 0, '1.5rem']}
			marginBottom={[0, 0, 0, '1.5rem']}
		>
			<VerticalDivider color={theme.colors.accent400} height='1.5rem' />
		</Block>
	);
};

export default Divider;
