import React from 'react';
import { Block } from 'baseui/block';
import { useSelector } from 'react-redux';

import NavBotInfo from 'components/UI/NavBotInfo/NavBotInfo';

import { botRefSelector } from 'store/Bot/selectors';

function BotInfoHeader() {
	const botRef = useSelector(botRefSelector);

	if (!botRef) {
		return <></>;
	}

	return (
		<Block display='flex' justifyContent='flex-start' alignItems='center' margin='0 1rem'>
			<NavBotInfo isMobileScreen />
		</Block>
	);
}

export default BotInfoHeader;
