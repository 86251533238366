import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';

const AdvancedHorizontalDivider = ({ item }: { item?: string }) => {
	const [css, theme]: any = useStyletron();

	return (
		<Block
			className={css({
				marginTop: '1rem',
				marginBottom: '0.5rem',
				display: 'flex',
				alignItems: 'center',
			})}
		>
			<span
				className={css({
					fontSize: '.75rem',
					marginLeft: '.875rem',
					color: theme.colors.accent50,
				})}
			>
				{item && `${item} `}
			</span>
			<HorizontalDivider
				height='0.5px'
				overrides={{ width: '100%', borderTop: '0.5px solid' }}
				color={theme.colors.accent50}
				borderColor={theme.colors.accent50}
			/>
		</Block>
	);
};

export default AdvancedHorizontalDivider;
