import { BUILDER } from 'shared/consts/QAConsts';

function Builder({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			width={size || 40}
			height={size || 40}
			viewBox='0 0 40 40'
			fill='none'
			className={className}
			data-qa={BUILDER}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='inherit'>
				<rect x='15.4706' y='19.3435' width='2.58824' height='1.94118' fill={fillColor || '#403F42'} />
				<mask id='path-2-inside-1' fill='white'>
					<rect x='24.5294' y='9.25' width='6.47059' height='6.47059' rx='1.29412' />
				</mask>
				<rect
					x='24.5294'
					y='9.25'
					width='6.47059'
					height='6.47059'
					rx='1.29412'
					fill={fillColor || '#403F42'}
					stroke={fillColor || '#403F42'}
					strokeWidth='3.88235'
					mask='url(#path-2-inside-1)'
				/>
				<mask id='path-3-inside-2' fill='white'>
					<rect x='24.5294' y='24.7791' width='6.47059' height='6.47059' rx='1.29412' />
				</mask>
				<rect
					x='24.5294'
					y='24.7791'
					width='6.47059'
					height='6.47059'
					rx='1.29412'
					fill={fillColor || '#403F42'}
					stroke={fillColor || '#403F42'}
					strokeWidth='3.88235'
					mask='url(#path-3-inside-2)'
				/>
				<mask id='path-4-inside-3' fill='white'>
					<rect x='18.0588' y='17.015' width='6.47059' height='6.47059' rx='1.29412' />
				</mask>
				<rect
					x='18.0588'
					y='17.015'
					width='6.47059'
					height='6.47059'
					rx='1.29412'
					fill={fillColor || '#403F42'}
					stroke={fillColor || '#403F42'}
					strokeWidth='3.88235'
					mask='url(#path-4-inside-3)'
				/>
				<mask id='path-5-inside-4' fill='white'>
					<rect x='9' y='17.015' width='6.47059' height='6.47059' rx='1.29412' />
				</mask>
				<rect
					x='9'
					y='17.015'
					width='6.47059'
					height='6.47059'
					rx='1.29412'
					fill={fillColor || '#403F42'}
					stroke={fillColor || '#403F42'}
					strokeWidth='3.88235'
					mask='url(#path-5-inside-4)'
				/>
				<rect x='21.9412' y='11.8382' width='2.58824' height='1.94118' fill={fillColor || '#403F42'} />
				<path
					d='M20.2588 17.0143V13.779C20.2588 12.7069 21.1279 11.8378 22.2 11.8378V17.0143H20.2588Z'
					fill={fillColor || '#403F42'}
				/>
				<rect
					width='2.58824'
					height='1.94118'
					transform='matrix(1 0 0 -1 21.9412 28.6618)'
					fill={fillColor || '#403F42'}
				/>
				<path
					d='M20.2588 23.4857V26.721C20.2588 27.7931 21.1279 28.6622 22.2 28.6622V23.4857H20.2588Z'
					fill={fillColor || '#403F42'}
				/>
			</g>
		</svg>
	);
}

export default Builder;
