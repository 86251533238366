import { useEffect } from 'react';
import posthog from 'posthog-js';
import { useSelector } from 'react-redux';

import { currentPlanStatusSelector } from 'store/App/Plan/selectors';
import { isPostHogEnabledSelector } from 'store/Common/selector';
import { botAdminValue, currentPlan } from 'store/Users/selectors';

const PostHog = () => {
	const isPostHogEnabled = useSelector(isPostHogEnabledSelector);
	const { name: userName, email, uid: userId, cid: customerId, organization } = useSelector(botAdminValue);
	const customerPlanStatus = useSelector(currentPlanStatusSelector);
	const currentActivePlan = useSelector(currentPlan);

	const enablePostHog = () => {
		const posthogConfig = {
			api_host: process.env.POSTHOG_HOST,
			disable_session_recording: true,
			loaded: () => {
				if (email) {
					posthog.identify(`${email}`, {
						email,
						userName,
						userId,
						customerId,
						organization,
						customerPlanStatus,
						currentPlan: currentActivePlan,
					});
				}
				posthog.startSessionRecording();
			},
		};

		posthog.init(process.env.POSTHOG_API_KEY ?? '', posthogConfig);
	};

	useEffect(() => {
		if (isPostHogEnabled) {
			enablePostHog();
		}
	}, [isPostHogEnabled]);

	return <></>;
};

export default PostHog;
