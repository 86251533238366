import React from 'react';
import { useStyletron } from 'baseui';
import { createPortal } from 'react-dom';

import { IObjProps } from 'shared/consts/types';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

const ENGTHeader = (props: { children: React.ReactNode; overrides?: IObjProps }) => {
	const { children, overrides = {} } = props;

	const [css]: any = useStyletron();

	const { isSmallDesktopScreen } = useResponsiveSize();

	const headerElement: HTMLElement = document.getElementById('engtHeaderContent') || document.createElement('div');

	if (isSmallDesktopScreen) {
		return createPortal(
			<header
				className={css({
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
					...overrides,
				})}
			>
				{children}
			</header>,
			headerElement
		);
	}

	return <>{children}</>;
};

export default ENGTHeader;
