import { Button, KIND, SIZE } from 'baseui/button';
import { Filter } from 'baseui/icon';

import { circularButtonCss } from 'shared/consts/consts';

const FilteredIcon = (
	<Button
		size={SIZE.default}
		shape='circle'
		kind={KIND.secondary}
		overrides={{
			BaseButton: {
				style: circularButtonCss,
			},
		}}
	>
		<Filter size={28} />
	</Button>
);

export default FilteredIcon;
