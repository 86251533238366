import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { LabelLarge } from 'baseui/typography';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ENGTLink from 'components/UI/ENGTLink/ENGTLink';
import FormSelect from 'components/UI/Form/FormSelect/FormSelect';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { SUPPORT_OPTIONS, SUPPORT_TYPE_OPTIONS } from 'shared/consts/consts';
import { formSelectValidation } from 'shared/helpers/broadcastHelpers';

import { IMessageType } from './ExpertAssistanceModal';

interface HelpYourselfModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	selectedMessageType: IMessageType | undefined;
	setSelectedMessageType: Function;
	timezone: string;
	setTimezone: (value: string) => void;
	fontStyle: string;
}

const HelpYourselfModal: React.FC<HelpYourselfModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	selectedMessageType,
	setSelectedMessageType,
}) => {
	const [dynamicLinks, setDynamicLinks] = useState({
		docsLink: '',
		videoLink: '',
		docsText: '',
		videoText: '',
	});

	const { t } = useTranslation(['components', 'errors', 'common']);
	const [css, theme]: any = useStyletron();

	const getDynamicLinks = (selectedTypes: IMessageType[]) => {
		const links = {
			docsLink: '',
			videoLink: '',
			docsText: '',
			videoText: '',
		};

		if (selectedTypes.length === 0) {
			return links;
		}

		selectedTypes.forEach((type) => {
			switch (type.id) {
				case SUPPORT_OPTIONS.WHATSAPP_CAMPAIGNS:
					links.docsLink = 'https://help.engati.com/send-whatsapp-template-message';
					links.videoLink = 'https://youtu.be/9ADxqG5rcOI?si=QGNNgwBClJA8OmI0';
					links.docsText = t('components:helpYourselfModal.whatsappDocsText');
					links.videoText = t('components:helpYourselfModal.whatsappVideoText');
					break;
				case SUPPORT_OPTIONS.PLATFORM_TRAINING:
					links.docsLink = 'https://help.engati.com/welcome-to-engati';
					links.docsText = t('components:helpYourselfModal.platformTrainingDocsText');
					break;
				case SUPPORT_OPTIONS.ONBOARDING_NUMBER:
					links.docsLink = 'https://help.engati.com/configuring-integrations';
					links.videoLink = 'https://help.engati.com/json-api';
					links.docsText = t('components:helpYourselfModal.onboardingDocsText');
					links.videoText = t('components:helpYourselfModal.onboardingVideoText');
					break;
				case SUPPORT_OPTIONS.AUTOMATED_WORKFLOWS:
					links.docsLink = 'https://help.engati.com/building-your-bot';
					links.docsText = t('components:helpYourselfModal.automatedWorkflowsDocsText');
					break;
				default:
					break;
			}
		});

		return links;
	};

	const methods = useFormContext();
	const { control } = methods;

	const confirmBtnLabel = (
		<div>
			<span style={{ fontSize: '14px' }}>{t('components:helpYourselfModal.book')}</span>
			<br />
			<span style={{ fontSize: '12px', fontWeight: 400 }}>{t('components:helpYourselfModal.charges')}</span>
		</div>
	);

	const modalContent = (
		<>
			<LabelLarge
				overrides={{
					Block: {
						style: {
							fontWeight: '700',
							fontSize: '1.25rem',
						},
					},
				}}
			>
				{t('components:helpYourselfModal.subHeading')}
			</LabelLarge>
			<ul>
				<li style={{ marginBottom: '10px' }}>
					{t('components:needHelp.helpDocs')} :{' '}
					<ENGTLink
						link={t('common:clickHereCap')}
						url={dynamicLinks.docsLink}
						overrides={{ color: theme.colors.accent }}
					/>
					{dynamicLinks.docsText && ` ${dynamicLinks.docsText}`}
				</li>
				{dynamicLinks.videoLink && (
					<li>
						{t('components:helpYourselfModal.video')} :{' '}
						<ENGTLink
							link={t('common:clickHereCap')}
							url={dynamicLinks.videoLink}
							overrides={{ color: theme.colors.accent }}
						/>
						{dynamicLinks.videoText && ` ${dynamicLinks.videoText}`}
					</li>
				)}
			</ul>
			<p>
				{t('components:needHelp.needHelp')} {t('components:helpYourselfModal.bookASlot')}
			</p>
		</>
	);

	const handleDropdownChange = (option: IMessageType[]) => {
		setSelectedMessageType(option?.[0]);
		setDynamicLinks(getDynamicLinks(option));
	};

	useEffect(() => {
		if (selectedMessageType) {
			setDynamicLinks(getDynamicLinks([selectedMessageType]));
		}
	}, [selectedMessageType]);

	return (
		<ActionModal
			heading={t('components:helpYourselfModal.heading')}
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onConfirm}
			confirmBtnLabel={confirmBtnLabel}
			isConfirmBtnDisabled={false}
			buttonOverrides={{
				closeBtnCss: { paddingTop: '0.475rem !important', paddingBottom: '0.375rem !important' },
				confirmBtnCss: { paddingTop: '0.375rem !important', paddingBottom: '0.375rem !important' },
			}}
			footerOverrideCss={{ paddingTop: '0 !important' }}
		>
			<Controller
				control={control}
				name='messageType'
				rules={{
					validate: (value) => formSelectValidation(value),
					required: t('errors:requiredErrorMessage').toString(),
				}}
				render={({ ref, value }) => (
					<FormSelect
						name='messageType'
						inputRef={ref}
						options={SUPPORT_TYPE_OPTIONS()}
						selectedValue={value || selectedMessageType}
						onChange={handleDropdownChange}
						popupIndex={100}
					/>
				)}
			/>
			{modalContent}
		</ActionModal>
	);
};

export default HelpYourselfModal;
