import React, { Suspense } from 'react';
import { useStyletron } from 'baseui';

import Loader from '../Loader/Loader';

type EmptyPagesLayout = {
	children?: any;
};

const EmptyPagesLayout = ({ children }: EmptyPagesLayout) => {
	const [css]: any = useStyletron();

	return (
		<div className={css({ position: 'relative' })}>
			<section>
				<Suspense fallback={<Loader />}>{children}</Suspense>
			</section>
		</div>
	);
};

export default EmptyPagesLayout;
