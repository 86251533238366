import React from 'react';
import { useStyletron } from 'baseui';

export interface IDividerProps {
	color?: string;
	height?: string;
	overrides?: any;
}

export const VerticalDivider = ({ color, height, overrides }: IDividerProps) => {
	const [css, theme] = useStyletron();

	return (
		<div
			className={css({
				borderRight: '1px solid',
				borderColor: color || theme.colors.accent100,
				height: height || 'auto',
				...overrides,
			})}
		/>
	);
};
