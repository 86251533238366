import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getAdminPartnerMode } from 'components/AccountDropdownMenu/helpers/helpers';

import { ACCOUNT_TYPES, ENGATI_ROLES } from 'shared/consts/consts';

import { RootState } from 'store/rootReducer';

function useIsAdminModeHook() {
	const botAdminData = useSelector((state: RootState) => state.User.botAdmin.data);
	const email = useSelector((state: RootState) => state.User.botAdmin.data.email);
	const isResellerLogin = useSelector((state: RootState) => state.User.botAdmin.data.reseller_login);

	const [isAdminMode, setAdminMode] = useState(false);

	useEffect(() => {
		const { MASTER_ADMIN, RESELLER, AFFILIATE } = ACCOUNT_TYPES;
		const nonDirectCustomers = [MASTER_ADMIN, RESELLER, AFFILIATE];
		const accType = localStorage.getItem('loggedInUserAccountType') || '';
		const [isAdmin, isPartner] = getAdminPartnerMode(isResellerLogin, botAdminData?.customer_account_type, email);
		const userRole = botAdminData?.admin_mode_access?.bot_platform_role;
		const isNotPartnerAdmin = userRole !== ENGATI_ROLES.ROLE_KEY_PARTNER_ADMIN_BD;

		if (isNotPartnerAdmin && isResellerLogin && isPartner && !isAdmin && nonDirectCustomers.indexOf(accType) > -1) {
			setAdminMode(true);
		}
	}, [botAdminData, email]);

	return { isAdminMode };
}

export default useIsAdminModeHook;
