import { ImgHTMLAttributes, useEffect, useState } from 'react';
import LazyLoad from 'react-lazy-load';

import ENGTErrorImg from 'shared/icons/ENGTErrorImg.svg';

type imgHTMLProps = ImgHTMLAttributes<HTMLImageElement>;

type ILazyOnProps = {
	lazy?: true;
	lazyProps?: {
		className?: string;
		elementType?: string;
		height?: number | string;
		offset?: string | number;
		threshold?: number;
		width?: number | string;
		onContentVisible?: () => void;
	};
};

type ILazyOffProps = {
	lazy?: false;
};

type Props = imgHTMLProps & (ILazyOffProps | ILazyOnProps);

function ENGTImage(props: Props & { errorImgSrc?: string }) {
	const { src, alt, errorImgSrc, lazy, ...rest } = props;
	const [isErrored, setError] = useState(false);
	const { width, height } = rest || {};

	useEffect(() => {
		setError(false);
	}, [src]);

	const img = isErrored || !src ? errorImgSrc || ENGTErrorImg : src;

	if (lazy) {
		const { lazyProps, onError } = props;
		const { width: lazyWidth, height: lazyHeight, className } = lazyProps || {};

		return (
			<LazyLoad
				height={lazyHeight || height}
				width={lazyWidth || width}
				className={`${className} engt-lazy-image`}
				{...lazyProps}
			>
				<img src={img} alt={alt} {...rest} onError={onError || (() => setError(true))} />
			</LazyLoad>
		);
	}

	return <img src={img} alt={alt} {...rest} onError={() => setError(true)} />;
}

export default ENGTImage;
