import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { IObjProps } from 'shared/consts/types';

import useDeleteCampaignHook from '../hooks/useDeleteCampaignHook';
import useIsCampaignAnalyticsEnabledHook from '../hooks/useIsCampaignAnalyticsEnabledHook';

interface IDeleteCampaignModal {
	selectedCampaign: IObjProps;
	toggleDeleteModal: Function;
}

function DeleteCampaignModal({ selectedCampaign, toggleDeleteModal }: IDeleteCampaignModal) {
	const { t } = useTranslation(['common', 'pages']);

	const [isLoading, setLoading] = useState<boolean>(false);
	const [deleteCampaign] = useDeleteCampaignHook({ selectedCampaign, toggleDeleteModal, setLoading });
	const showWhatsappCampaignAnalytics = useIsCampaignAnalyticsEnabledHook();

	return (
		<ActionModal
			closeBtnLabel={t('common:cancel')}
			confirmBtnLabel={t('common:confirm')}
			description={
				t('pages:broadcast.campaigns.confirmDelete') +
				(showWhatsappCampaignAnalytics ? selectedCampaign?.campaignName : selectedCampaign?.campaign_name) +
				t('common:questionMark')
			}
			heading={t('pages:broadcast.campaigns.deleteMessage')}
			isConfirmBtnDisabled={false}
			isLoading={isLoading}
			isOpen
			onClose={() => toggleDeleteModal(false)}
			onConfirm={deleteCampaign}
		/>
	);
}

export default DeleteCampaignModal;
