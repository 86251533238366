import React from 'react';

function SearchIcon({ size, strokeColor }: { size?: number; strokeColor: string }) {
	return (
		<svg width={size || 13} height={size || 14} viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.69856 1.85409C5.95062 0.896994 8.55171 1.94697 9.50885 4.19844C10.466 6.44991 9.41597 9.05138 7.1644 10.0085C4.91283 10.9656 2.31125 9.9156 1.35411 7.66413C0.396967 5.41265 1.44698 2.81119 3.69856 1.85409Z'
				stroke={strokeColor}
				strokeWidth='1.24059'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8.56396 9.06366L12 12.5'
				stroke={strokeColor}
				strokeWidth='1.24059'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default SearchIcon;
