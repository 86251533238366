import { createSlice } from '@reduxjs/toolkit';

import { tokenReducer } from './reducers';
import { tokenState } from './state';

export const tokenSlice = createSlice({
	name: 'Token',
	initialState: tokenState,
	reducers: tokenReducer,
});

export const { actions, reducer } = tokenSlice;
