import { useState } from 'react';
import { useStyletron } from 'baseui';
import { BEHAVIOR, Grid } from 'baseui/layout-grid';
import { animated, useSpring } from 'react-spring';

import ENGTBackdrop from 'components/UI/ENGTBackdrop';
import RightPanelArrow from 'components/UI/Navigation/MobileNavigation/components/RightPanelArrow';

import { IObjProps } from 'shared/consts/types';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import CrossButton from 'shared/icons/CrossBtn';

const LayoutRightPanelMobile = ({ rightPanel }: { rightPanel: any }) => {
	const [, theme]: any = useStyletron();
	const [openRightPanel, setOpenRightPanel] = useState(false);
	const { isSmallTabletScreen } = useResponsiveSize();

	const gridCss: IObjProps = {
		Grid: {
			style: {
				flexWrap: 'nowrap',
				zIndex: theme.zIndex300,
				position: 'absolute',
				right: 0,
				top: 0,
				width: '100%',
				height: '100%',
			},
		},
	};

	const cellCss: IObjProps = {
		background: theme.colors.backgroundPrimary,
		height: '100%',
		overflowY: 'auto',
		position: 'fixed',
		right: '0',
		top: '0',
	};

	const width: string = isSmallTabletScreen ? '80%' : '50%';

	const animationProps = useSpring({
		...cellCss,
		opacity: openRightPanel ? 1 : 0,
		width: openRightPanel ? width : '0%',
		config: { tension: 100, friction: 20 },
	});
	const onClose = () => {
		setOpenRightPanel(false);
	};

	return !openRightPanel ? (
		<RightPanelArrow setOpenRightPanel={setOpenRightPanel} />
	) : (
		<Grid gridColumns={12} behavior={BEHAVIOR.fixed} overrides={gridCss}>
			<animated.div style={animationProps}>
				<span onClick={onClose}>
					<CrossButton onClose={onClose} />
				</span>
				{rightPanel}
			</animated.div>

			<ENGTBackdrop onClick={() => setOpenRightPanel(false)} />
		</Grid>
	);
};

export default LayoutRightPanelMobile;
