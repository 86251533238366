import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ENGTLink from 'components/UI/ENGTLink/ENGTLink';
import NavItem from 'components/UI/NavItem/NavItem';

import { ACCOUNT_TYPES, DIVIDER } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { MARKETING_CAMPAIGNS_ENTITLEMENT_VALUES } from 'shared/enum';
import PulseLogo from 'shared/icons/PulseLogo.svg';

import {
	isCDPEntitlementEnabledSelector,
	isCommonWhatsappConfiguredAsProvider,
	isDialog360ConfiguredAsProvider,
	isEmailTemplatesEnabledSelector,
	isPricingPageEnabled as isPricingPageEnabledSelector,
	isWhatsappAcceleratorEnabled,
	isWhatsappAcceleratorTrialEnabled,
	isWhatsAppCloudConfiguredAsProvider,
	isWhatsAppConfiguredSelector,
	isWhatsAppPaymentEnabledSelector,
	marketingCampaignsAnalyticsSelector,
} from 'store/App/User/selectors';
import { isLLMEntitlementEnabled } from 'store/Bot/selectors';
import { RootState } from 'store/rootReducer';
import { brandingPlatformNameSelector } from 'store/Users/selectors';

import { NoColumnRouteLinks } from 'router/NoColumnRoutes';
import { LEFT_PANEL_ACCESS_ROUTES } from 'router/ProtectedRouter/protectedRoute';

import AdvancedHorizontalDivider from './AdvancedHorizontalDivider';

const accountType = localStorage.getItem('loggedInUserAccountType');
const isResellerAccount = accountType === ACCOUNT_TYPES.RESELLER;

const CommonNavigation = (props: { page: string }) => {
	const { page } = props;
	const location = useLocation();
	const [css, theme] = useStyletron();
	const { t } = useTranslation(['common', 'pages']);
	const navigate = useNavigate();

	const isDialog360Configured = useSelector(isDialog360ConfiguredAsProvider);
	const isWhatsAppCloudConfigured = useSelector(isWhatsAppCloudConfiguredAsProvider);
	const isCommonWhatsappConfigured = useSelector(isCommonWhatsappConfiguredAsProvider);
	const restrictTrain: boolean = useSelector((state: RootState) => state.User.botAdmin.data.restrict_train || false);
	const restrictFaq: boolean = useSelector((state: RootState) => state.User.botAdmin.data.restrict_faq || false);
	const leftPanelsV2: any = useSelector((state: RootState) => state.User.botAdmin.data.left_panels_v2);
	const restrictAnalytics: boolean = useSelector((state: RootState) => state.User.botAdmin.data.restrict_analytics);
	const restricConfigure: boolean = useSelector((state: RootState) => state.User.botAdmin.data.restrict_configure);
	const brandName: string = useSelector(brandingPlatformNameSelector);
	const restrictCampaign: boolean = useSelector((state: RootState) => state.User.botAdmin.data.restrict_campaign);
	const leftPanelsV2Data: any = useSelector((state: RootState) => state.User.botAdmin.data.left_panels_v2_keys || []);
	const isWhatsappAcceleratorTrial = useSelector(isWhatsappAcceleratorTrialEnabled);
	const isWhatsappAccelerator = useSelector(isWhatsappAcceleratorEnabled);
	const isPricingPageEnabled = useSelector(isPricingPageEnabledSelector);
	const isCDPEntitlementEnabled = useSelector(isCDPEntitlementEnabledSelector);
	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);
	const isLLMEnabled: boolean = useSelector(isLLMEntitlementEnabled);
	const isWhatsAppConfigured = useSelector(isWhatsAppConfiguredSelector);
	const isWaPaymentEnabled = useSelector(isWhatsAppPaymentEnabledSelector);
	const isEmailTemplatesEnabled = useSelector(isEmailTemplatesEnabledSelector);
	const isAdminMode = useSelector((state: RootState) => state.User.botAdmin.data.is_admin_mode);

	const styles = {
		pulseImage: {
			marginLeft: '0.1rem',
			marginTop: '-0.4rem',
			width: '2.25rem',
			height: '2.25rem',
		},
	};

	const NavOptions: IObjProps = {
		[t('common:locations.train')]: {
			[t('common:locations.navigation.overview')]: '/train/overview',
			[t('common:locations.navigation.faqs')]: '/train/faq',
			[t('common:locations.navigation.entities')]: '/train/entities',
			[t('common:locations.navigation.tabularInput')]: '/train/tabular-input',
			[t('common:locations.navigation.cognitiveSearch')]: '/train/cognitive-search',
			[t('common:locations.navigation.responses&Training')]: '/train/responses',
			[t('common:locations.navigation.settings')]: '/train/settings',
		},
		[t('common:locations.configure')]: {
			...(!isWhatsappAccelerator ? { [t('common:locations.navigation.overview')]: '/configure/overview' } : {}),
			[t('common:locations.navigation.botDetails')]: '/configure/bot-details',
			[t('common:locations.navigation.webDisplay')]: '/configure/display',
			[t('common:locations.navigation.externalLiveChat')]: '/configure/external-livechat',
			[t('common:locations.navigation.liveChat')]: '/configure/live-chat',
			[t('common:locations.navigation.attributes')]: '/configure/attributes',
			[t('common:locations.navigation.languages')]: '/configure/languages',
			[t('common:locations.navigation.advanced')]: '/configure/advanced',
			[t('common:locations.navigation.publish')]: '/configure/publish',
			[t('common:locations.navigation.versionHistory')]: '/configure/version-history',
			[t('common:locations.navigation.deployment')]: '/configure/deployment',
			[t('common:locations.navigation.integrations')]: '/configure/integrations',
			[t('common:locations.navigation.agentProductivity')]: '/configure/agent-productivity',
		},
		[t('common:locations.analytics')]: {
			...(!isWhatsappAccelerator
				? {
						[t('common:locations.navigation.overview')]: '/analytics/overview',
					}
				: {}),
			...(isCDPEntitlementEnabled || !isWhatsappAccelerator
				? {
						[t('common:locations.navigation.engagement')]: DIVIDER,
					}
				: {}),
			...(!isWhatsappAccelerator
				? {
						[t('common:locations.navigation.botEngagement')]: '/analytics/bot/engagement',
					}
				: {}),
			[t('common:locations.navigation.botResponses')]: '/analytics/bot/response',
			[t('common:locations.navigation.userRetentionAnalysis')]: '/analytics/user/retention',
			[t('common:locations.navigation.automationLivechatAnalysis')]: '/analytics/livechat/automation-analysis',
			[t('common:locations.navigation.platformInteractions')]: '/analytics/usage/platform-interactions',
			[t('common:locations.navigation.buttonClickAnalysis')]: '/analytics/usage/button-click-analysis',
			[t('common:locations.navigation.genAIQueryAnalysis')]: '/analytics/gen-ai/query-analysis',
			[t('common:locations.navigation.support')]: DIVIDER,
			[t('common:locations.navigation.liveChatSummary')]: '/analytics/livechat/summary',
			[t('common:locations.navigation.agents')]: '/analytics/livechat/agents',
			[t('common:locations.navigation.channels')]: '/analytics/bot/channels',
			[t('common:locations.navigation.agentPerformance')]: '/analytics/livechat/agent-performance',
			[t('common:locations.navigation.newAgentPerformance')]: '/analytics/livechat/new-agent-performance',
			[t('common:locations.navigation.agentProductivity')]: '/analytics/livechat/agent-productivity',
			[t('common:locations.navigation.conversationOverview')]: '/analytics/livechat/conversation-overview',
			[t('common:locations.navigation.agentAssistanceFeedback')]: '/analytics/livechat/agent-assistance-feedback',
			[t('common:locations.navigation.liveChatDetails')]: '/analytics/livechat/details',
			[t('common:locations.navigation.feedbackSummary')]: '/analytics/livechat/feedback',
			[t('common:locations.navigation.slaBreaches')]: '/analytics/livechat/sla-breaches',
		},
		[t('common:locations.integrations')]: {
			[t('common:locations.navigation.cloudIntegrations')]: '/integrations/cloud-integrations',
			...(isAdminMode || !isWhatsappAccelerator
				? {
						[t('common:locations.navigation.brandNameAPI')]: '/integrations/platform-api',
						[t('common:locations.navigation.APILogs')]: '/integrations/api-logs',
						[t('common:locations.navigation.ChannelLogs')]: '/integrations/channel-logs',
					}
				: {}),
			[t('common:locations.navigation.C2EBridge')]: '/integrations/c2e-bridge',
			...(isWhatsAppConfigured && isWaPaymentEnabled
				? { [t('common:locations.navigation.paymentLogs')]: '/integrations/payment-logs' }
				: {}),
		},
		[t('common:locations.whatsApp')]: {
			[t('common:locations.navigation.numbers')]: '/whatsApp/numbers',
			[t('common:locations.navigation.flows')]: '/whatsApp/flows',
		},
		[t('common:locations.accountSettings')]: {
			[t('common:locations.navigation.profileSettings')]: '/account-settings/profile',
			[t('common:locations.navigation.regionSettings')]: '/account-settings/region',
			[t('common:locations.navigation.themeSettings')]: '/account-settings/theme',
			[t('common:locations.navigation.accountDetails')]: '/account-settings/account',
			[t('common:locations.navigation.usageDetails')]: '/account-settings/usage-details',
			[t('common:locations.navigation.usageBreakdown')]: '/account-settings/usage-breakdown',
			[t('common:locations.navigation.teamMembers')]: '/account-settings/team-members',
			[t('common:locations.navigation.subscriptionDetails')]: '/account-settings/subscription',
			[t('common:locations.navigation.billingDetails')]: '/account-settings/billing',
			[t('common:locations.navigation.securitySettings')]: '/account-settings/security',
			[t('common:locations.navigation.notificationSettings')]: '/account-settings/notification',
			[t('common:locations.navigation.passwordSettings')]: '/account-settings/password',
			...(isPricingPageEnabled
				? {
						[t('common:locations.navigation.managePlans')]: NoColumnRouteLinks.billingPlans,
					}
				: {}),
			...(isResellerAccount && {
				[t('common:locations.navigation.allCustomerMAU')]: '/account-settings/allCustomerMAU',
			}),
		},
		[t('common:locations.broadcast')]: {
			...(marketingCampaignsAnalyticsType
				? {
						[t('common:locations.navigation.analytics')]: DIVIDER,
						[t('common:locations.navigation.execution')]: '/broadcast/execution',
						[t('common:locations.navigation.engagement')]: '/broadcast/engagement',
						...(isCDPEntitlementEnabled
							? {
									[t('common:locations.navigation.pulseReport')]: '/broadcast/report',
								}
							: {}),
						[t('common:setup')]: DIVIDER,
					}
				: { [t('common:locations.navigation.broadcastHistory')]: '/broadcast/published' }),
			...(!marketingCampaignsAnalyticsType && {
				[t('common:locations.navigation.clickToWhatsappAds')]: '/broadcast/clickToWhatsappAds',
			}),
			[t('common:locations.navigation.messageTemplates')]: '/broadcast/templates',
			[t('common:locations.navigation.emailTemplates')]: '/broadcast/email-templates',
			[t('common:locations.navigation.scheduledBroadcasts')]: '/broadcast/scheduled',
			[t('common:locations.navigation.draftBroadcasts')]: '/broadcast/draft',
			...(isCDPEntitlementEnabled ||
			marketingCampaignsAnalyticsType === MARKETING_CAMPAIGNS_ENTITLEMENT_VALUES.CONVERSION_ENABLED
				? {
						[t('common:locations.navigation.pulse')]: '/broadcast/pulse',
					}
				: {}),
			...(!marketingCampaignsAnalyticsType
				? { [t('common:locations.navigation.campaigns')]: '/broadcast/campaigns' }
				: { [t('common:locations.navigation.general')]: DIVIDER }),
			[t('common:locations.navigation.exportHistory')]: '/broadcast/exportHistory',
			...(!isWhatsappAccelerator && { [t('common:locations.navigation.help')]: '/broadcast/help' }),
		},
	};

	const hassAccessFor = (item: string) => {
		const route = LEFT_PANEL_ACCESS_ROUTES[NavOptions[page][item]];

		if (item === t('common:locations.navigation.agentPerformance')) {
			return leftPanelsV2.dashboard.agentPerformance || false;
		}
		if (item === t('common:locations.navigation.newAgentPerformance')) {
			return leftPanelsV2.enable_livechat_analytics_v2 || false;
		}
		if (item === t('common:locations.navigation.campaigns') && restrictCampaign) {
			return true;
		}
		if (item === t('common:locations.navigation.messageTemplates')) {
			return isDialog360Configured || isWhatsAppCloudConfigured || isCommonWhatsappConfigured;
		}
		if (item === t('common:locations.navigation.emailTemplates')) {
			return isEmailTemplatesEnabled;
		}

		return leftPanelsV2Data.indexOf(route) > -1 || route === '';
	};
	const restrictConfigureItems = [t('common:locations.navigation.webDisplay')];
	const restrictAnalyticsItems = [
		t('common:locations.navigation.botEngagement'),
		t('common:locations.navigation.botResponses'),
		t('common:locations.navigation.userRetentionAnalysis'),
	];
	const restrictCampaignItems = [t('common:locations.navigation.campaigns')];
	const waAcceleratorTrialPlanDisabledItems = ['Scheduled Broadcasts'];
	const pulseItems = [
		t('common:locations.navigation.pulseReport'),
		t('common:locations.navigation.pulse'),
		t('common:locations.navigation.slaBreaches'),
		t('common:locations.navigation.automationLivechatAnalysis'),
		t('common:locations.navigation.platformInteractions'),
		t('common:locations.navigation.buttonClickAnalysis'),
		t('common:locations.navigation.genAIQueryAnalysis'),
		t('common:locations.navigation.liveChatDetails'),
		t('common:locations.navigation.feedbackSummary'),
		t('common:locations.navigation.usageBreakdown'),
	];

	const checkTrainPanelAccess = (item: string): boolean =>
		(restrictFaq && item === t('common:locations.navigation.faqs')) ||
		(restrictTrain &&
			[
				t('common:locations.navigation.entities'),
				t('common:locations.navigation.tabularInput'),
				t('common:locations.navigation.cognitiveSearch'),
				t('common:locations.navigation.responses&Training'),
				t('common:locations.navigation.settings'),
			].includes(item)) ||
		(restrictFaq && restrictTrain && item === t('common:locations.navigation.overview'));

	const getLabel = (item: string) => {
		if (item === t('common:locations.navigation.newAgentPerformance')) {
			if (!leftPanelsV2.dashboard.agentPerformance && leftPanelsV2.enable_livechat_analytics_v2) {
				return t('common:locations.navigation.agentPerformance');
			}
		}
		if (item.indexOf('BrandName') > -1) {
			return brandName ? item.replace('BrandName', brandName) : item.replace('BrandName', '');
		}
		if (item === t('common:locations.navigation.cognitiveSearch')) {
			return isLLMEnabled
				? t('common:locations.navigation.generativeAI')
				: t('common:locations.navigation.cognitiveSearch');
		}

		if (pulseItems.includes(item)) {
			return (
				<>
					{item} <img src={PulseLogo} className={css(styles.pulseImage)} />
				</>
			);
		}

		return item;
	};

	return (
		<>
			{Object.keys(NavOptions?.[page] || {}).map((item: string) => (
				<>
					{NavOptions[page][item] === DIVIDER ? (
						<AdvancedHorizontalDivider item={item} />
					) : (
						hassAccessFor(item) &&
						((restricConfigure && restrictConfigureItems.indexOf(item) > -1) ||
						(restrictAnalytics && restrictAnalyticsItems.indexOf(item) > -1) ||
						(restrictCampaign && restrictCampaignItems.indexOf(item) > -1) ||
						(isWhatsappAcceleratorTrial && waAcceleratorTrialPlanDisabledItems.indexOf(item) > -1) ||
						checkTrainPanelAccess(item) ? (
							<NavItem
								key={item}
								label={getLabel(item)}
								className={css({
									marginBottom: '0.25rem',
									color: theme.colors.contentStateDisabled,
									cursor: 'not-allowed',
								})}
								dataQa={item}
								showPopover={
									!!(
										isWhatsappAcceleratorTrial &&
										waAcceleratorTrialPlanDisabledItems.indexOf(item) > -1
									)
								}
								popoverContent={
									<>
										{t('pages:builder.notForTrial')}
										<ENGTLink
											link={t('common:upgradeNow')}
											url='javascript:void(0);'
											onClick={() => navigate(NoColumnRouteLinks.billingPlans)}
											openInNewTab={false}
											overrides={{
												color: theme.colors.accent,
											}}
										/>
									</>
								}
							/>
						) : (
							<NavItem
								key={item}
								label={getLabel(item)}
								to={NavOptions[page][item]}
								isSelected={location.pathname.indexOf(NavOptions[page][item]) > -1}
								className={css({ marginBottom: '0.25rem' })}
								dataQa={item}
							/>
						))
					)}
				</>
			))}
		</>
	);
};

export default CommonNavigation;
