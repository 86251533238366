import { ENGATI_RESPONSE_CODE } from 'shared/consts/consts';
import { IApiDataProps, IObjProps } from 'shared/consts/types';

import { updateSelectedUserInUserListAction } from 'store/Message';
import { RootState } from 'store/rootReducer';

import { API } from './api';
import { actions } from './store';

const {
	toggleLaunchScreen,
	getVideCallInitialisationDetails,
	setSelectedVideoUserAction,
	setVideoUserMessages,
	setGroupConversationForVideo,
	setVideoConversationIdForUser,
	removeVideoConversationIdForUser,
	setUserInstance,
} = actions;

export const jitsiActions = {
	clearAllJitsiData: () => (dispatch: any, getState: () => RootState) => {
		dispatch(getVideCallInitialisationDetails({}));
		API.getUserInstance().then((response: any) => {
			if (response.status === 200) {
				dispatch(
					setUserInstance({
						instance_domain_url: response?.data?.instance_domain_url,
						instance_meet_url: response?.data?.instance_meet_url,
					})
				);
			}
		});

		const { selectedVideoUser } = getState().Jitsi;
		const updatedSelectedVideoUser = { ...selectedVideoUser };
		updatedSelectedVideoUser.in_video_conversation = false;

		dispatch(updateSelectedUserInUserListAction(updatedSelectedVideoUser));
		dispatch(setSelectedVideoUserAction({}));
	},
	updateVideoCallToAgentLeftAction: (videoConversationId: number) => (dispatch: any) => {
		const params = {
			targetApi: 'updateVideoConversationStatus',
			status: 'AGENT_LEFT',
			videoConversationId,
		};

		API.updateVideoCallToAgentLeft(params).then((response: any) => {
			if (response.data && response.data.status && response.data.status.code === 1000) {
				dispatch(jitsiActions.clearAllJitsiData());
			}
		});
	},
	exitVideoCallFromGroupConversationAction:
		(selectedVideoUser: IObjProps, videoCallInitialisationDetails: IObjProps, botAdminData: IObjProps) =>
		(dispatch: any) => {
			const params = {
				targetApi: 'exitVideoGroupConversation',
			};
			const data = {
				csRequestId: selectedVideoUser.cs_request_id,
				agentId: botAdminData.uid,
				platform: selectedVideoUser.platform,
				videoConversationId: videoCallInitialisationDetails.videoConversationId,
			};

			API.exitVideoCallFromGroupConversation(params, data).then((response: any) => {
				if (
					response?.data?.status.code === ENGATI_RESPONSE_CODE.SUCCESS ||
					response?.data?.status?.code === ENGATI_RESPONSE_CODE.STATUS_1039
				) {
					dispatch(jitsiActions.clearAllJitsiData());
				}
			});
		},
	setSelectedVideoUserAction: (selectedVideoUser: IObjProps) => (dispatch: any) => {
		selectedVideoUser.conversation_id &&
			dispatch(jitsiActions.removeVideoConversationIdForUserAction(selectedVideoUser.conversation_id));
		dispatch(setSelectedVideoUserAction(selectedVideoUser));
	},
	setVideoUserMessages: (messageData: IApiDataProps<Array<IObjProps>>) => (dispatch: any) => {
		dispatch(setVideoUserMessages(messageData?.data || []));
	},
	toggleLaunchScreen: (showLauncScreen: boolean) => (dispatch: any) => {
		dispatch(toggleLaunchScreen(showLauncScreen));
	},
	setGroupConversationForVideoAction: (isGroupConversationAllowed: boolean) => (dispatch: any) => {
		dispatch(setGroupConversationForVideo(isGroupConversationAllowed));
	},
	getTokenDetailsAction:
		(botAdminData: IObjProps, selectedUser: IObjProps, errorCallback?: any) => (dispatch: any) => {
			const { uid: botAdminUid, cid: botAdminCid } = botAdminData;
			const {
				bot_ref: botRef,
				cs_request_id: csRequestId,
				user_id: userId,
				conversation_id: conversationId,
				platform,
			} = selectedUser;

			const data = {
				agentId: botAdminUid,
				botRef,
				conversationId,
				csRequestId,
				userId,
				customerId: botAdminCid,
				platform,
			};

			API.getVideoCallToken(data).then((response: any) => {
				if (
					response.data &&
					response.data.status &&
					response.data.status.code === 1000 &&
					response.data.responseObject
				) {
					dispatch(getVideCallInitialisationDetails(response.data.responseObject));
					selectedUser.conversation_id &&
						dispatch(jitsiActions.removeVideoConversationIdForUserAction(selectedUser.conversation_id));
				} else {
					errorCallback && errorCallback();
				}
			});
		},
	closeVideoCallAction: (botAdminData: IObjProps) => (dispatch: any, getState: () => RootState) => {
		const videoInitialisationDetails = getState().Jitsi.videoCallInitialisationDetails;
		const { selectedVideoUser } = getState().Jitsi;

		if (selectedVideoUser.is_group_conversation) {
			dispatch(
				jitsiActions.exitVideoCallFromGroupConversationAction(
					selectedVideoUser,
					videoInitialisationDetails,
					botAdminData
				)
			);
		} else {
			dispatch(jitsiActions.updateVideoCallToAgentLeftAction(videoInitialisationDetails.videoConversationId));
		}
	},
	setVideoConversationIdForUserAction: (cid: string, videoId: any) => (dispatch: any) => {
		dispatch(setVideoConversationIdForUser({ cid, videoId }));
	},
	removeVideoConversationIdForUserAction: (cid: string) => (dispatch: any, getState: () => RootState) => {
		const videoConversationIdForUserMap = { ...getState().Jitsi.videoConversationIdForUserMap };
		delete videoConversationIdForUserMap[cid];
		dispatch(removeVideoConversationIdForUser(videoConversationIdForUserMap));
	},
};
