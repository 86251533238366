import React from 'react';
import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { Modal as BaseModal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';

import Button from 'components/UI/ENGTButton/ENGTButton';
import Loader from 'components/UI/Loader/Loader';

import { IObjProps } from 'shared/consts/types';

export interface IModalProps {
	children?: any;
	closeable?: boolean;
	closeBtnLabel?: string;
	confirmBtnLabel: string;
	description: string | React.ReactNode;
	heading: string;
	height?: string;
	isConfirmBtnDisabled?: boolean;
	isLoading?: boolean;
	isOpen: boolean;
	width?: string;
	dataQa?: string;
	onClose: () => any;
	onConfirm: (args?: any) => any;
	onCrossFn?: () => any;
	btnSize?: keyof SIZE;
	overrides?: IObjProps;
}

const modalHeaderCss = ($theme: any) => ({
	color: `${$theme.modalHeaderColor} !important`,
	fontWeight: 'bold !important',
	fontSize: '1.25rem',
	lineHeight: '1.625rem',
	marginLeft: '0rem !important',
	marginRight: '0rem !important',
	marginBottom: '0.3125rem !important',
});

const modalBodyCss: any = {
	marginRight: '0 !important',
	marginLeft: '0 !important',
	marginTop: '0.3125rem !important',
};
const modalFooterCss = {
	display: 'flex',
	marginBottom: '2rem',
	textAlign: 'center !important' as 'center',
	marginLeft: '0rem !important',
	marginRight: '0rem !important',
	paddingBottom: '0rem !important',
	paddingTop: '0rem !important',
};

const modalLoaderCss = (theme: any) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	background: theme.colors.primaryA,
	width: '100%',
	opacity: '0.3',
	height: '100%',
	borderTopRightRadius: '1rem',
	borderTopLeftRadius: '1rem',
	borderBottomRightRadius: '1rem',
	borderBottomLeftRadius: '1rem',
});

const modalOverrideProps = ({
	height,
	width,
	theme,
	dataQa,
}: {
	height?: string;
	width?: string;
	theme: any;
	dataQa?: string;
}) => ({
	Root: {
		style: {
			zIndex: theme.zIndex300,
		},
	},
	Close: {
		style: {
			borderRadius: '50%',
			height: '3rem',
			width: '3rem',
			right: '0.75rem',
			top: '0.75rem',
			background: `${theme.modalCloseBgColor}`,
			stroke: `${theme.modalCloseStrokeColor}`,
			color: `${theme.modalCloseStrokeColor}`,
		},
		props: {
			'data-qa': `cancel-${dataQa}`,
		},
	},
	Dialog: {
		style: {
			backgroundColor: theme.modalPrimaryBackground,
			borderTopRightRadius: '1rem',
			borderTopLeftRadius: '1rem',
			borderBottomLeftRadius: '1rem',
			borderBottomRightRadius: '1rem',
			paddingRight: '2rem',
			paddingLeft: '2rem',
			width,
			height,
		},
	},
});

function ModalAlert(props: IModalProps) {
	const {
		children,
		closeable = true,
		closeBtnLabel,
		confirmBtnLabel,
		description,
		heading,
		height,
		isConfirmBtnDisabled,
		isLoading,
		isOpen,
		dataQa,
		onClose,
		onConfirm,
		width,
		onCrossFn,
		btnSize,
		overrides,
	} = props;
	const [css, theme]: [any, any] = useStyletron();

	const ModalLoader = () =>
		isLoading ? (
			<div className={css(modalLoaderCss(theme))}>
				<Loader loaderBlockHeight='100%' />
			</div>
		) : (
			<></>
		);

	return (
		<>
			<BaseModal
				closeable={closeable}
				onClose={onCrossFn || onClose}
				isOpen={isOpen}
				overrides={modalOverrideProps({ height, width, theme, dataQa })}
			>
				<ModalLoader />
				<ModalHeader className={css({ ...modalHeaderCss(theme), ...overrides?.ModalHeader })}>
					{heading}
				</ModalHeader>
				<ModalBody className={css(modalBodyCss)}>
					{description && <p style={{ marginTop: '0', marginBottom: '0' }}> {description} </p>}
					{children}
				</ModalBody>
				<ModalFooter className={css(modalFooterCss)}>
					<Button
						kind={KIND['secondary']}
						size={btnSize ?? SIZE.large}
						onClick={onClose}
						label={closeBtnLabel}
						className={css({
							marginRight: '0.5rem !important',
							width: '100%',
						})}
						dataQa={`secondary-${dataQa}`}
					/>
					<Button
						kind={KIND['primary']}
						size={btnSize ?? SIZE.large}
						label={confirmBtnLabel}
						className={css({ width: '100%' })}
						isDisabled={isConfirmBtnDisabled}
						onClick={onConfirm}
						dataQa={`primary-${dataQa}`}
					/>
				</ModalFooter>
			</BaseModal>
		</>
	);
}

ModalAlert.defaultProps = {
	confirmBtnLabel: 'Confirm',
	closeBtnLabel: 'Cancel',
	isLoading: false,
	width: '22.375rem',
};

export default ModalAlert;
