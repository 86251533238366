import React from 'react';
import { PLACEMENT, ToasterContainer } from 'baseui/toast';

export interface IENGTToasterProps {
	toastDuration?: number;
	hideCloseIcon?: boolean;
	placement?: keyof PLACEMENT;
}

function ENGTToaster({ toastDuration, hideCloseIcon, placement }: IENGTToasterProps) {
	const overridesCss = {
		Root: {
			style: ({ $theme }: any) => ({
				zIndex: $theme.zIndex300,
				marginLeft: '2.188rem',
				marginBottom: '1.563rem',
			}),
		},
		ToastBody: {
			style: ({ $theme }: any) => ({
				backgroundColor: $theme.toasterBackgroundColor,
				borderTopLeftRadius: '0.563rem',
				borderTopRightRadius: '0.563rem',
				borderBottomLeftRadius: '0.563rem',
				borderBottomRightRadius: '0.563rem',
				width: '17rem',
			}),
		},
		ToastCloseIcon: {
			style: () => ({
				display: hideCloseIcon ? 'none' : 'inline-block',
			}),
		},
	};

	return <ToasterContainer placement={placement} autoHideDuration={toastDuration} overrides={overridesCss} />;
}

ENGTToaster.defaultProps = {
	toastDuration: 3000,
	hideCloseIcon: true,
	placement: PLACEMENT.bottomLeft,
};

export default ENGTToaster;
