import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormSelect from 'components/UI/Form/FormSelect/FormSelect';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { SUPPORT_TYPE_OPTIONS } from 'shared/consts/consts';
import { formSelectValidation } from 'shared/helpers/broadcastHelpers';

export interface IMessageType {
	id: string;
	label: string;
}

interface ExpertAssistanceModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	selectedMessageType: IMessageType | undefined;
	setSelectedMessageType: (value: IMessageType | undefined) => void;
}

const ExpertAssistanceModal: React.FC<ExpertAssistanceModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	selectedMessageType,
	setSelectedMessageType,
}) => {
	const { t } = useTranslation(['components']);

	return (
		<ActionModal
			heading={t('components:expertAssistanceModal.heading')}
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onConfirm}
			isConfirmBtnDisabled={!selectedMessageType}
		>
			<Controller
				name='messageType'
				rules={{ validate: formSelectValidation }}
				render={({ ref, value, onChange }) => (
					<FormSelect
						name='messageType'
						inputRef={ref}
						options={SUPPORT_TYPE_OPTIONS()}
						placeholder={t('common:select')}
						selectedValue={value}
						onChange={(val) => {
							onChange(val);
							setSelectedMessageType(val[0]);
						}}
						label={t('components:expertAssistanceModal.label')}
						popupIndex={100}
						overrides={{
							Root: {
								style: {
									lineHeight: '1.625rem',
									paddingTop: '9.625rem',
									paddingBottom: '9.625rem',
								},
							},
						}}
					/>
				)}
			/>
		</ActionModal>
	);
};

export default ExpertAssistanceModal;
