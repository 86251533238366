import React, { useEffect, useRef } from 'react';
import { useStyletron } from 'baseui';
import { BEHAVIOR, Cell, Grid } from 'baseui/layout-grid';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Navigation from 'components/UI/Navigation';
import isMobileMenuHidden from 'components/UI/Navigation/helpers/isMobileMenuHidden';

import { getBannerExcludedPageHeight } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import { RootState } from 'store/rootReducer';

import isAuthenticatedRoute from './helpers/isAuthenticatedRoute';
import navigationalRouteProps from './helpers/navigationalRouteProps';

const cellOverrides = {
	Cell: { style: { overflow: 'hidden', height: getBannerExcludedPageHeight(0) } },
};

const gridOverrides = {
	Grid: {
		style: {
			marginLeft: 0,
			marginRight: 0,
			height: 'calc(var(--engt-app-height) - var(--engt-app-banner-height))',
		},
	},
};

const headerWrapperCss = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	minHeight: '4rem',
	height: 'min-content',
	width: '100%',
	paddingTop: '0.25rem',
	paddingBottom: '0.25rem',
};

const root: any = document.querySelector(':root');

const Header = ({ children }: { children: React.ReactNode }) => {
	const [css]: any = useStyletron();
	const location = useLocation();

	const { pathname } = location;

	const headerRef = useRef<HTMLDivElement>(null);

	const { isSmallDesktopScreen } = useResponsiveSize();

	const hideMobileMenu = isMobileMenuHidden(pathname);

	useEffect(() => {
		root.style.setProperty('--engt-app-header-height', `${headerRef?.current?.clientHeight ?? 0}px`);
	});

	return isSmallDesktopScreen && !hideMobileMenu ? (
		<div className={css(headerWrapperCss)} id='engtHeader' ref={headerRef}>
			{children}
			<div
				id='engtHeaderContent'
				className={css({
					width: '100%',
				})}
			/>
		</div>
	) : (
		<>{children}</>
	);
};

function RouterWrapper({ children }: { children: React.ReactNode }) {
	const location = useLocation();

	const token = useSelector((state: RootState) => state.Token.token);

	const csrfToken = localStorage.getItem('csrfToken');

	const isAuthenticated = isAuthenticatedRoute(location.pathname) && token && csrfToken;

	const getWidth = () => {
		if (!isAuthenticated) {
			return [12];
		}

		const { primaryNav, secondNav } = navigationalRouteProps(location.pathname) || {};

		if (primaryNav && secondNav) {
			return [12, 12, 9];
		}
		if (primaryNav) {
			return [12, 12, 11.25];
		}

		return [12];
	};

	return (
		<Grid gridColumns={12} behavior={BEHAVIOR.fluid} overrides={gridOverrides}>
			{isAuthenticated && (
				<Header>
					<Navigation />
				</Header>
			)}
			<Cell span={getWidth()} overrides={cellOverrides}>
				{children}
			</Cell>
		</Grid>
	);
}

export default RouterWrapper;
