import { createSlice } from '@reduxjs/toolkit';

import { shopifyReducer } from './reducers';
import { shopifyState } from './state';

const shopifySlice = createSlice({
	name: 'Shopify',
	initialState: shopifyState,
	reducers: shopifyReducer,
});

export const { actions, reducer } = shopifySlice;
export default shopifySlice;
