const PlusIcon = ({ size, fill }) => (
	<svg width={size} height={size} viewBox='0 -12 24 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M12.6426 0H11.3569V11.3574H0V12.6431H11.3569V24H12.6426V12.6431H24V11.3574H12.6426V0Z' fill={fill} />
	</svg>
);

PlusIcon.defaultProps = {
	size: '24',
	fill: '#403F42',
};

export default PlusIcon;
