import logger from 'redux-logger';

import { configureStore } from '@reduxjs/toolkit';

import { isDevEnvironment, throttle } from 'shared/helpers';

import { loadState, saveState } from './localStorage';
import { reducer } from './rootReducer';

const middleware = (getDefaultMiddleware: () => any[]) =>
	isDevEnvironment() ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware();
const preloadedState = loadState();
const store = configureStore({
	reducer,
	middleware,
	preloadedState,
	devTools: isDevEnvironment(),
});

store.subscribe(
	throttle(() => {
		saveState({
			...store.getState(),
		});
	}, 1000)
);

export default store;
