import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const tokenUrl = '/api/v1/getCsrfToken';
const logoutUrl = '/logout';
const publicKeyUrl = '/public-key';

const tokenApi = new BaseService(tokenUrl);
const logoutApi = new BaseService(logoutUrl);
const publicKeyApi = new BaseService(publicKeyUrl);

export const API = {
	fetchToken() {
		return tokenApi.getRequest();
	},
	logout() {
		return logoutApi.getRequest(serialize({ isPortalV2: true }, '?'));
	},
	getPublicKey() {
		return publicKeyApi.getRequest();
	},
};
