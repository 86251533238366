import { useStyletron } from 'baseui';
import { ParagraphSmall } from 'baseui/typography';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FormInput } from 'components/UI/Form/FormInput/FormInput';
import FormSelect from 'components/UI/Form/FormSelect/FormSelect';
import { FormTextarea } from 'components/UI/Form/FormTextarea/FormTextarea';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { AGENDAS, SUPPORT_OPTIONS, SUPPORT_TYPE_OPTIONS } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { formSelectValidation } from 'shared/helpers/broadcastHelpers';
import { tagsNotAllowed } from 'shared/pathBuilderHelpers';

import { RootState } from 'store/rootReducer';
import { userPhoneNumber } from 'store/Users/selectors';

import { IMessageType } from './ExpertAssistanceModal';

interface ConfirmationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	dateValue: Date;
	agendaAmounts: IObjProps;
	selectedMessageType: IMessageType | undefined;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	dateValue,
	agendaAmounts,
	selectedMessageType,
}) => {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['components', 'errors', 'common']);
	const currency = useSelector((state: RootState) => state.Plan.details.data?.currencyName) || 'USD';

	const methods = useFormContext();

	const email = useSelector((state: RootState) => state.User.profile.data.email);
	const userPhoneNo = useSelector(userPhoneNumber);

	const { control } = methods;

	const MAX_BODY_TEXT_LIMIT = 1024;

	const getAgendaType = (selectedTypes: IMessageType[]) => {
		let agenda = '';

		selectedTypes.forEach((type) => {
			switch (type.id) {
				case SUPPORT_OPTIONS.WHATSAPP_CAMPAIGNS:
					agenda = AGENDAS.RUN_A_WHATSAPP_CAMPAIGN;
					break;
				case SUPPORT_OPTIONS.ONBOARDING_NUMBER:
					agenda = AGENDAS.INTEGRATE_THIRD_PARTY_SYSTEM;
					break;
				case SUPPORT_OPTIONS.AUTOMATED_WORKFLOWS:
					agenda = AGENDAS.CUSTOMISE_EXISTING_AUTOMATION_FLOWS;
					break;
				case SUPPORT_OPTIONS.PLATFORM_TRAINING:
					agenda = AGENDAS.PLATFORM_TRAINING;
					break;
			}
		});

		return agenda;
	};

	const selectedAmount = selectedMessageType ? agendaAmounts[getAgendaType([selectedMessageType])] : '';

	const noteWrapperCss: any = {
		textAlign: 'left',
		maxWidth: '100%',
		marginBottom: '1rem',
	};
	const getNoteText = (selectedTypes: IMessageType[]) => {
		let noteText = '';

		selectedTypes.forEach((type) => {
			switch (type.id) {
				case SUPPORT_OPTIONS.PLATFORM_TRAINING:
					noteText = t('components:confirmationModal.platformTrainingNoteText');
					break;
				case SUPPORT_OPTIONS.ONBOARDING_NUMBER:
					noteText = t('components:confirmationModal.thirdPartyIntegrationsNoteText');
					break;
				case SUPPORT_OPTIONS.AUTOMATED_WORKFLOWS:
					noteText = t('components:confirmationModal.automatedWorkflowsNoteText');
					break;
			}
		});

		return noteText;
	};

	const noteText = (
		<div className={css(noteWrapperCss)}>
			<ParagraphSmall marginBottom='0.125rem'>
				{selectedMessageType && getNoteText([selectedMessageType])}
			</ParagraphSmall>
		</div>
	);

	const modalContent = (
		<>
			<Controller
				control={control}
				defaultValue={t('common:standardDateFormatterWithShortMonthName', {
					date: dateValue,
				})}
				name='date'
				render={({ ref, value, onChange }) => (
					<FormInput
						name='date'
						label={t('components:confirmationModal.date')}
						placeholder={t('components:confirmationModal.date')}
						type='text'
						disabled
						inputRef={ref}
						onChange={onChange}
						value={value}
					/>
				)}
			/>
			<Controller
				control={control}
				defaultValue={t('common:standardTimeFormatter', {
					date: dateValue,
				})}
				name='time'
				render={({ ref, value, onChange }) => (
					<FormInput
						name='time'
						label={t('components:confirmationModal.time')}
						placeholder={t('components:confirmationModal.time')}
						type='text'
						disabled
						inputRef={ref}
						onChange={onChange}
						value={value}
					/>
				)}
			/>
			<Controller
				control={control}
				name='email'
				defaultValue={email}
				render={({ ref, value }) => (
					<FormInput
						name='email'
						disabled
						label={t('pages:accountSettings.profile.email')}
						placeholder={t('pages:accountSettings.profile.enterEmail')}
						type='email'
						inputRef={ref}
						value={value}
					/>
				)}
			/>
			<Controller
				control={control}
				name='communicationEmail'
				defaultValue={email}
				render={({ ref, value, onChange }) => (
					<FormInput
						name='communicationEmail'
						label={t('components:confirmationModal.communicationEmailTitle')}
						placeholder={t('components:confirmationModal.communicationEmailPlaceholder')}
						onChange={onChange}
						type='email'
						inputRef={ref}
						value={value}
					/>
				)}
			/>
			<Controller
				control={control}
				defaultValue={userPhoneNo}
				name='userPhoneNo'
				render={({ ref, value, onChange }) => (
					<FormInput
						name='userPhoneNo'
						label={t('pages:accountSettings.profile.phone')}
						placeholder={t('pages:accountSettings.profile.enterPhone')}
						type='text'
						inputRef={ref}
						onChange={onChange}
						value={value}
					/>
				)}
			/>
			<p>
				<h5>
					{t('components:confirmationModal.subHeading', {
						amount: selectedAmount && `${selectedAmount} ${currency}`,
					})}
				</h5>
			</p>
			{noteText}
			<Controller
				control={control}
				name='additionalComments'
				rules={{
					validate: (value) => {
						if (value.length > MAX_BODY_TEXT_LIMIT) {
							return t('components:node.whatsAppPayment.errors.1024Limit') as string;
						}

						return tagsNotAllowed(t, value, true);
					},
				}}
				render={({ ref, value, onChange }: any) => (
					<FormTextarea
						inputRef={ref}
						value={value}
						onChange={(val: any) => {
							onChange(val);
						}}
						name='additionalComments'
						label={t('components:confirmationModal.additionalComments')}
						placeholder={t('components:confirmationModal.additionalCommentsPlaceholder')}
					/>
				)}
			/>
		</>
	);

	return (
		<ActionModal
			heading={t('components:confirmationModal.heading')}
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onConfirm}
			confirmBtnLabel={t('components:confirmationModal.book')}
			closeBtnLabel={t('components:confirmationModal.edit')}
			isConfirmBtnDisabled={false}
		>
			<Controller
				control={control}
				name='messageType'
				rules={{
					validate: (value) => formSelectValidation(value),
					required: t('errors:requiredErrorMessage').toString(),
				}}
				render={({ ref, value }) => (
					<FormSelect
						name='messageType'
						inputRef={ref}
						disabled
						label={t('components:confirmationModal.subject')}
						options={SUPPORT_TYPE_OPTIONS()}
						selectedValue={value || selectedMessageType}
						onChange={() => {}}
						popupIndex={100}
					/>
				)}
			/>
			{modalContent}
		</ActionModal>
	);
};

export default ConfirmationModal;
