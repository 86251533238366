import { useTranslation } from 'react-i18next';

import ModalAlert from 'components/UI/Modal/ModalAlert/ModalAlert';

interface IWarningModal {
	warning: boolean;
	setShowErrorModal: Function;
}

const WarningModal = ({ warning, setShowErrorModal }: IWarningModal) => {
	const { t } = useTranslation(['common']);

	return (
		<>
			<ModalAlert
				closeBtnLabel={t('common:cancel')}
				confirmBtnLabel={t('common:continue')}
				description={warning}
				heading={t('common:warning')}
				isOpen
				onClose={() => setShowErrorModal(false)}
				onConfirm={() => {
					setShowErrorModal(false);
				}}
			/>
		</>
	);
};

export default WarningModal;
