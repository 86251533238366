import { ENGT_ALERT_NOTIFICATION, ENGT_CHAT_DEFAULT_WALLPAPER } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { showBrowserNotifPermissionModal } from 'shared/helpers';

import { RootState } from 'store/rootReducer';

import { API } from './api';
import { IThemeProps } from './state';
import { actions } from './store';

let extendedNotificationTimeout: any;
let duration: number;
const { getAgentSetting, setAgentSetting, setThemeSettings, setToasterDuration } = actions;

const getProcessedAgentSettings = (data: IObjProps, isSimplifiedOneViewEnabled: boolean): IObjProps => {
	let processedData = JSON.parse(JSON.stringify(data));

	processedData = {
		...processedData,
		wallpaperUrl: data.wallpaperUrl === null ? ENGT_CHAT_DEFAULT_WALLPAPER : data.wallpaperUrl,
		soundOnMessage: data.soundOnMessage === null ? !isSimplifiedOneViewEnabled : data.soundOnMessage,
		soundOnRequest: data.soundOnRequest === null ? true : data.soundOnRequest,
		newMessageNotification:
			data.newMessageNotification === null ? !isSimplifiedOneViewEnabled : data.newMessageNotification,
		extendedNotification: data.extendedNotification === null ? false : data.extendedNotification,
		isSlaNonAdherenceNotificationEnabled:
			data.isSlaNonAdherenceNotificationEnabled === null ? true : data.isSlaNonAdherenceNotificationEnabled,
		browserNotification: data.browserNotification === null ? true : data.browserNotification,
		notificationAudioUrl: data.notificationAudioUrl === null ? ENGT_ALERT_NOTIFICATION : data.notificationAudioUrl,
	};

	return processedData;
};

const playNotificationForLimitedTime = (audio: any) => {
	audio.addEventListener(
		'canplaythrough',
		function () {
			setTimeout(function () {
				duration >= 10 && audio.pause();
			}, 10000);
		},
		false
	);
};

export const showBrowserNotificationPrompt = () => (dispatch: any, getState: () => RootState) => {
	const notificationAccessInfo = getState().Preferences.notifications;

	if (!notificationAccessInfo.browserNotification && Notification.permission !== 'granted') {
		showBrowserNotifPermissionModal().then(({ status }: { status: boolean }) => {
			if (status) {
				const data = { ...notificationAccessInfo, browserNotification: true };
				API.setAgentSetting(data).then((resp: any) => {
					if (resp.data) {
						dispatch(setAgentSettings(resp.data));
					}
				});
			}
		});
	}
};

export const getAgentSettings = () => (dispatch: any, getState: () => RootState) => {
	API.getAgentSetting().then((resp: any) => {
		if (resp.data && resp.data.responseObject) {
			const isSimplifiedOneViewEnabled = getState().User.botAdmin.data.is_simplified_one_view_enabled;
			dispatch(getAgentSetting(getProcessedAgentSettings(resp.data.responseObject, isSimplifiedOneViewEnabled)));
			dispatch(showBrowserNotificationPrompt());
		}
	});
};

export const setAgentSettings = (data: IObjProps) => (dispatch: any) => {
	dispatch(setAgentSetting(data.responseObject));
};

export const getThemeSettingsAction = () => (dispatch: any) => {
	API.getThemeSettings().then((resp: any) => {
		if (resp.data && resp.data.responseObject) {
			dispatch(setThemeSettingsAction(resp.data.responseObject));
		} else {
			console.log('error');
		}
	});
};

export const setThemeSettingsAction = (data: IThemeProps) => (dispatch: any) => {
	dispatch(setThemeSettings(data));
};

export const setToasterTimeDurationAction = (data: number) => (dispatch: any) => {
	dispatch(setToasterDuration(data));
};

export const playSoundNotification =
	({ sound }: { sound: 'message' | 'request' }) =>
	(dispatch: any, getState: () => RootState) => {
		if (localStorage.getItem(`pausednotification_${getState().User.botAdmin.data.uid}`) !== 'true') {
			const notifySettings: any = getState().Preferences.notifications;
			const { extendedNotification, soundOnMessage, soundOnRequest, notificationAudioUrl } = notifySettings;

			const isSoundEnabled = sound === 'message' ? soundOnMessage : soundOnRequest;

			if (isSoundEnabled) {
				const audio = document.getElementById('alertAudio') as HTMLAudioElement;
				audio.src = notificationAudioUrl || audio.src;
				if (audio) {
					audio.currentTime = 0;

					const promise = audio.play();

					playNotificationForLimitedTime(audio);

					audio.onloadedmetadata = function () {
						duration = audio.duration;
						const playAfter = duration > 10 ? 10 * 1000 + 1000 : duration * 1000 + 1000;
						if (extendedNotification && duration) {
							extendedNotificationTimeout && clearTimeout(extendedNotificationTimeout);
							extendedNotificationTimeout = setTimeout(function () {
								audio.currentTime = 0;
								audio.play();
								playNotificationForLimitedTime(audio);
							}, playAfter);
						}
					};

					if (promise !== undefined) {
						promise.catch((error: any) => {
							console.log('Auto-play not enabled');
						});
					}
				}
			}
		}
	};
