import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isEmpty } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import { RootState } from 'store/rootReducer';

import isAuthenticatedRoute from 'router/helpers/isAuthenticatedRoute';

import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

function Navigation() {
	const location = useLocation();

	const botAdminData = useSelector((state: RootState) => state.User.botAdmin.data);

	const { isSmallDesktopScreen } = useResponsiveSize();

	if (isEmpty(botAdminData) || !isAuthenticatedRoute(location.pathname)) {
		return <></>;
	}

	return isSmallDesktopScreen ? <MobileNavigation /> : <DesktopNavigation />;
}

export default Navigation;
