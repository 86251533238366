import posthog from 'posthog-js';

import { REACT_APP_BASE_URL } from 'shared/config/config';
import { clearLsOnLogout, errorHandler, updateCSRFToken } from 'shared/helpers';

import { RootState } from 'store/rootReducer';

import { NoAuthenticationRouteLinks } from 'router/links/NoAuthenticationRouteLinks';

import { API } from './api';
import { actions } from './store';

const { portalLogin } = NoAuthenticationRouteLinks;

const { setToken, setPublicKey } = actions;

export const fetchRefreshTokenAction = (successCB?: any) => (dispatch: any) => {
	API.fetchToken()
		.then((resp: any) => {
			if (resp.data && resp.data.refresh_token) {
				localStorage.setItem('csrfToken', resp.data.refresh_token);
				updateCSRFToken(resp.data.refresh_token);
				dispatch(setToken(resp.data.refresh_token));
				successCB && successCB();
			} else {
				dispatch(redirectToLoginAction());
			}
		})
		.catch((error: any) => {
			errorHandler(error);
			dispatch(redirectToLoginAction());
		});
};

function redirectToLoginPage(to: string) {
	const url: any = decodeURIComponent(window.location.href);
	const verificationToken: any = url.indexOf('token') > -1 && url.split(/=(.+)/)[1];

	const baseUrl = REACT_APP_BASE_URL;
	window.location.replace(`${baseUrl}/admin${to}${verificationToken ? `?token=${verificationToken}` : ''}`);
}

export const redirectToLoginAction = () => (dispatch: any, getState: () => RootState) => {
	dispatch(setToken(''));
	const botId = getState().Bot.data.bot_ref;
	const { uid } = getState().User.botAdmin.data;
	logout(portalLogin, botId, uid);

	posthog.reset();
};

async function logout(to: string, botId: any, uid: any) {
	clearLsOnLogout(botId, uid);

	return await API.logout().then((resp: any) => {
		if (resp.data.success) {
			redirectToLoginPage(to);
		}
	});
}

export const getPublicKey = (errorCB: Function) => (dispatch: any) => {
	dispatch(setPublicKey({ publicKey: null, isEncryptionEnabled: false }));
	// API.getPublicKey().then((resp: any) => {
	// 	if (resp?.data && resp.data?.status === 1000 && resp.data?.response) {
	// 		const isEncryptionEnabled = resp.data?.response?.is_encryption_enabled || false;
	// 		const publicKey = resp.data?.response?.key || null;
	// 		dispatch(setPublicKey({ publicKey, isEncryptionEnabled }));
	// 		if (isEncryptionEnabled && !publicKey) {
	// 			dispatch(getPublicKeyErrorCB(errorCB));
	// 		}
	// 	} else {
	// 		dispatch(getPublicKeyErrorCB(errorCB));
	// 	}
	// });
};

const getPublicKeyErrorCB = (errorCB: Function) => (dispatch: any) => {
	dispatch(setPublicKey({ publicKey: null, isEncryptionEnabled: false }));
	errorCB();
};
