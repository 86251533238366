import DOMPurify from 'dompurify';

import { replaceAll } from 'shared/helpers';

import { i18nHelper } from 'i18nHelper';

export const formatEmailMessage = (html, fontSize) => {
	const div = document.createElement('div');
	div.innerHTML = html;
	html = div;

	html.querySelectorAll('*').forEach(function (node) {
		const { tagName } = node;
		if (tagName === 'P' || tagName === 'DIV' || tagName === 'BLOCKQUOTE') {
			node.style.fontSize = `${fontSize}px`;
		}
		if (tagName === 'P' || tagName === 'BLOCKQUOTE') {
			node.style.marginTop = '0px';
			node.style.marginBottom = '0px';
		}
		if (node.classList.contains('ql-align-center')) {
			node.style.textAlign = 'center';
		}
		if (node.classList.contains('ql-align-right')) {
			node.style.textAlign = 'right';
		}
		if (node.classList.contains('ql-align-justify')) {
			node.style.textAlign = 'justify';
		}
		if (node.classList.contains('ql-font-monospace')) {
			node.style.fontFamily = 'monospace';
		}
		if (node.classList.contains('ql-font-serif')) {
			node.style.fontFamily = 'serif';
		}
		if (tagName === 'IMG') {
			node.style.maxWidth = `${100}%`;
			node.style.maxHeight = `${100}%`;
		}
		if (tagName === 'PRE') {
			node.style.backgroundColor = '#23241f';
			node.style.color = '#f8f8f2';
			node.style.overflow = 'visible';
			node.style.whiteSpace = 'pre-wrap';
			node.style.marginBottom = '5px';
			node.style.marginTop = '5px';
			node.style.marginRight = '0px';
			node.style.marginLeft = '0px';
			node.style.padding = '5px 10px';
			node.style.borderRadius = '3px';
		}
	});

	return quillDecodeIndent(html.innerHTML);
};

/**
 * Wrap the text for
 * eg. {{attr<strong>Val}}</strong> gets converted to: <strong>{{attrVal}}</strong>
 */
export const wrapInPTags = function (text) {
	return `<p>${text}</p>`;
};

/**
 * processes Quill editor's content , replaces tags added by quill that we don't want to save to BE
 * checks and removes any empty tags, if attributes are used inside formatting tags checks for the formatting
 * tags and ensures that they are applied accordingly to ensure correct attr names are send to BE.
 *
 * eg. {{attr<strong>Val}}</strong> gets converted to: <strong>{{attrVal}}</strong>
 */

export const processQuillContent = (editorContent, removeWrappedPTags = false) => {
	if (!editorContent) {
		return;
	}
	let idx = editorContent.lastIndexOf('<p><br></p>');
	let n = editorContent.length - 11;
	while (idx !== -1 && idx === n) {
		editorContent = editorContent.substr(0, idx);
		idx = editorContent.lastIndexOf('<p><br></p>');
		n = editorContent.length - 11;
	}
	if (removeWrappedPTags && editorContent.indexOf('<p>') === 0) {
		editorContent = editorContent.slice(3, -4);
	}
	const tagsToReplace = [
		{ currentTag: '<br>', replaceWith: '' },
		{ currentTag: '&nbsp;', replaceWith: ' ' },
		{ currentTag: '&lt;', replaceWith: '<' },
		{ currentTag: '&gt;', replaceWith: '>' },
		{ currentTag: '<p>', replaceWith: '' },
		{ currentTag: '</p>', replaceWith: '\n' },
		{ currentTag: '&amp;', replaceWith: '&' },
		{ currentTag: '<p class="ql-align-right">', replaceWith: '' },
		{ currentTag: '<p class="ql-align-justify">', replaceWith: '' },
		{ currentTag: '<p class="ql-align-center">', replaceWith: '' },
	];
	tagsToReplace.forEach(function (tag) {
		editorContent = replaceAll(editorContent, tag.currentTag, tag.replaceWith);
	});
	const links = editorContent.match(/(href=\".*\")>/gm);
	if (links) {
		links.forEach(function (link) {
			const oldLink = link;
			link = replaceAll(link, 'rel="noopener noreferrer"', '');
			link = replaceAll(link, '"', "'");

			editorContent = editorContent.replace(oldLink, link);
		});
	}
	// remove ql-cursor from end if present
	if (editorContent.search('<span class="ql-cursor">') > -1) {
		const replaceStr = editorContent.substr(editorContent.search('<span class="ql-cursor">'), 32);
		editorContent = editorContent.replaceAll(replaceStr, '');
	}
	let formattedText = editorContent.match(/<(strong|em|u|s)>([\s])+\S|\S([\s])+<\/(strong|em|u|s)>/g);
	while (formattedText) {
		editorContent = removeTrailingSpaces(editorContent, formattedText);
		formattedText = editorContent.match(/<(strong|em|u|s)>([\s])+\S|\S([\s])+<\/(strong|em|u|s)>/g);
	}
	const attributesWithTags = editorContent.match(/\{\{(.*?)\}\}/g);
	if (attributesWithTags) {
		editorContent = removeTagsFromAttributes(editorContent, attributesWithTags);
	}
	let emptyTags = editorContent.match(/<[^/>][^>]*><\/[^>]+>/gim);
	while (emptyTags) {
		editorContent = editorContent.replace(emptyTags[0], '');
		emptyTags = editorContent.match(/<[^/>][^>]*><\/[^>]+>/gim);
	}
	let scriptTags = editorContent.match(/<[^>]*script/gim);
	while (scriptTags) {
		editorContent = editorContent.replace(scriptTags[0], '<srcipt');
		scriptTags = editorContent.match(/<[^>]*script/gim);
	}
	let spanTag = editorContent?.match(/<[^>]*span/gim);
	while (spanTag) {
		editorContent = editorContent?.replace(spanTag[0], '<sapn');
		spanTag = editorContent?.match(/<[^>]*span/gim);
	}

	return DOMPurify.sanitize(editorContent) || '';
};

function removeTrailingSpaces(editorText, formattedText) {
	formattedText.forEach(function (currentText) {
		const originalText = currentText;
		if (currentText[0] === '<') {
			const startTag = currentText.substr(0, currentText.indexOf('>') + 1);
			currentText = currentText.replace(startTag, '');
			const leadingSpaces = currentText.match(/^\s+/g);
			currentText = currentText.replace(leadingSpaces[0], startTag);
			currentText = leadingSpaces[0] + currentText;
		} else {
			const endTag = currentText.match(/<\/.*>/g);
			currentText = currentText.replace(endTag[0], '');
			const trailingSpaces = currentText.match(/\s+$/);
			currentText = currentText.replace(trailingSpaces[0], endTag[0]);
			currentText += trailingSpaces[0];
		}
		editorText = editorText.replace(originalText, currentText);
	});

	return editorText;
}

function removeTagsFromAttributes(editorContent, attributesWithTags) {
	attributesWithTags.forEach(function (attribute) {
		let changedAttribute = attribute;
		const endTags = changedAttribute.match(/<\/(strong|em|u|s|a)>/g);
		if (endTags) {
			endTags.forEach(function (tag) {
				changedAttribute = changedAttribute.replace(tag, '');
				changedAttribute += tag;
			});
		}
		const startTags = changedAttribute.match(/<(strong|em|u|s|a([^>]+))>/g);
		if (startTags) {
			startTags.forEach(function (tag) {
				changedAttribute = changedAttribute.replace(tag, '');
				changedAttribute = tag + changedAttribute;
			});
		}
		editorContent = editorContent.replace(attribute, changedAttribute);
	});

	return editorContent;
}

const listTypes = ['1', 'a', 'i'];

export function quillDecodeIndent(text) {
	if (!text || text.length === 0) {
		return text;
	}

	const tempEl = window.document.createElement('div');
	tempEl.setAttribute('style', 'display: none;');
	tempEl.innerHTML = text;

	['ul', 'ol'].forEach((type) => {
		// Grab each list, and work on it in turn
		Array.from(tempEl.querySelectorAll(type)).forEach((outerListEl) => {
			const listChildren = Array.from(outerListEl.children).filter((el) => el.tagName === 'LI');

			let lastLiLevel = 0;

			const parentElementsStack = [];
			const root = document.createElement(type);

			parentElementsStack.push(root);

			listChildren.forEach((e, i) => {
				const currentLiLevel = getQuillListLevel(e);
				e.className = e.className.replace(getIndentClass(currentLiLevel), '');
				const difference = currentLiLevel - lastLiLevel;
				lastLiLevel = currentLiLevel;

				if (difference > 0) {
					for (let currentDiff = difference; currentDiff > 0; currentDiff--) {
						let lastLiInCurrentLevel = seekLastElement(parentElementsStack).lastElementChild;

						if (!lastLiInCurrentLevel) {
							lastLiInCurrentLevel = document.createElement('li');
							encode_addChildToCurrentParent(parentElementsStack, lastLiInCurrentLevel);
						}

						const newList = document.createElement(type);
						if (type === 'ol') {
							newList.setAttribute('type', listTypes[currentLiLevel % 3]);
							newList.setAttribute('style', 'padding-left: 20px');
						}
						lastLiInCurrentLevel.appendChild(newList);
						parentElementsStack.push(newList);
					}
				}

				if (difference < 0) {
					let currentDiff = difference;

					while (currentDiff < 0) {
						parentElementsStack.pop();

						currentDiff++;
					}
				}

				encode_addChildToCurrentParent(parentElementsStack, e);
			});

			outerListEl.innerHTML = root.innerHTML;
		});
	});

	const newContent = tempEl.innerHTML;
	tempEl.remove();

	return newContent;
}

export function quillEncodeIndent(text) {
	if (!text || text.length === 0) {
		return text;
	}

	const tempEl = window.document.createElement('div');
	tempEl.setAttribute('style', 'display: none;');
	tempEl.innerHTML = text;

	['ul', 'ol'].forEach((type) => {
		Array.from(tempEl.querySelectorAll(type)).forEach((outerListEl) => {
			const listResult = Array.from(outerListEl.children)
				.filter((e) => e.tagName === 'LI')
				.map((e) => encode_UnwindElement(type.toUpperCase(), e, 0))
				.reduce((prev, c) => [...prev, ...c], []) // flatten list
				.map((e) => encode_GetLi(e))
				.reduce((prev, c) => `${prev}${c}`, ''); // merge to one string

			outerListEl.innerHTML = listResult;
		});
	});

	const newContent = tempEl.innerHTML;
	tempEl.remove();

	return newContent;
}

function encode_UnwindElement(listType, li, level) {
	const childElements = Array.from(li.children)
		.filter((innerElement) => innerElement.tagName === listType)
		.map((innerList) =>
			Array.from(li.removeChild(innerList).children)
				.map((nestedListElement) =>
					encode_UnwindElement(listType, innerList.removeChild(nestedListElement), level + 1)
				)
				.reduce((prev, c) => [...prev, ...c], [])
		)
		.reduce((prev, c) => [...prev, ...c], []);

	const current = {
		classes: li.className,
		content: li.innerHTML,
		indent: level,
	};

	return [current, ...childElements];
}

function encode_GetLi(e) {
	if (e.content.length === 0) {
		return '';
	}
	let cl = '';
	if (e.indent > 0) {
		cl += `${getIndentClass(e.indent)}`;
	}
	if (e.classes.length > 0) {
		cl += ` ${e.classes}`;
	}

	return `<li${cl.length > 0 ? ` class="${cl}"` : ''}>${e.content}</li>`;
}

function seekLastElement(list) {
	return list[list.length - 1];
}

function encode_addChildToCurrentParent(parentStack, child) {
	const currentParent = seekLastElement(parentStack);
	currentParent.appendChild(child);
}

function getQuillListLevel(el) {
	const className = el.className || '0';

	return +className.replace(/[^\d]/g, '');
}

function getIndentClass(level) {
	return `ql-indent-${level}`;
}

export function getErrorMsgForEvalExpression(expr, conditions) {
	const undefinedValues = getUndefinedValues(expr, conditions);
	if (expr) {
		if (expr.length > 35) {
			return 'Invalid Long Expression';
		}
		if (!isExpressionBalanced(expr) || !checkIfValidExpression(expr)) {
			return 'Expression is Invalid';
		}
		if (undefinedValues?.length === 1) {
			return `${getUndefinedValues(expr, conditions)[0]} in rule expression is not defined.`;
		}
		if (undefinedValues?.length > 1) {
			return i18nHelper('components:ruleExprErrorMessage', {
				expr: getUndefinedValues(expr, conditions).join(', '),
			});
		}
	}
}

export function checkIfValidExpression(expr) {
	const operators = ['or', 'OR', 'Or', 'And', 'AND', 'and'];
	expr = expr.replaceAll('(', '');
	expr = expr.replaceAll(')', '');
	let values = expr.split(' ');
	values = values.filter(function (value) {
		return value !== '';
	});
	if (
		values.length === 0 ||
		operators.indexOf(values[0].trim()) !== -1 ||
		operators.indexOf(values[values.length - 1].trim()) !== -1
	) {
		return false;
	}
	for (const index in values) {
		values[index] = values[index].trim();
		if (
			(index % 2 === 0 && operators.indexOf(values[index]) !== -1) ||
			(index % 2 !== 0 && operators.indexOf(values[index]) === -1)
		) {
			return false;
		}
	}

	return true;
}
export function isExpressionBalanced(expr) {
	let count = 0;
	for (const index in expr) {
		if (expr[index] === '(') {
			count++;
		} else if (expr[index] === ')') {
			count--;
		}
		if (count < 0) {
			return false;
		}
	}
	if (count > 0) {
		return false;
	}

	return true;
}
export function getUndefinedValues(expr, conditions) {
	const validSet = ['or', 'OR', 'Or', 'And', 'AND', 'and'];
	validSet.push.apply(
		validSet,
		conditions?.map((condition) => condition.name)
	);
	expr = expr.replaceAll('(', '');
	expr = expr.replaceAll(')', '');
	const undefinedValues = [];
	const values = expr.split(' ');
	for (const index in values) {
		values[index] = values[index].trim();
		if (validSet.indexOf(values[index]) !== -1 || values[index].indexOf('{{') === 0 || values[index] === '') {
			continue;
		} else {
			undefinedValues.push(values[index]);
		}
	}

	return undefinedValues;
}
export function linkify(inputText) {
	let notContainsHttp;
	// URLs starting with http(s)://, http(s):// + some string., or www. and not present inside <a> tags
	const replacePattern1 =
		/(?!([^<]+)<\/a>)((?:https?:\/\/[a-z]*\.)|(www\.)|(?:https?:\/\/))[-A-Z0-9+&@#\/%?=~_|!:,;.]+\.[-A-Z0-9+&@#?\/%=~_|!:,;.]+(?=<|\s|$)/gim;
	// URLs starting with "www." (without // before it, or it'd re-link the ones done above).
	// (?![^<]*>)(?<!\/)(www\.[\S]+(\b|$)) for look behind and can be used in future.
	const replacePattern2 = /((^|[^\/])(www\.[\S]+(\b|$)))/gim;
	// Change email addresses to mailto:: links.
	const replacePattern3 = /(?!([^<]+)<\/a>)(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
	// URLs in href without http
	const replacePattern4 = /((^|[^\/])href='(www\.[\S]+(\b|$)))/gim;
	// anchor tags created from replacePattern3 can also have formatting tags enclosing them
	const replacePattern5 = /(<[a-z]>)(<a href="mailto:(.*?)">(.*?)<\/a>)(<\/[a-z]>)/gim;

	let replacedText = inputText;
	replacedText = replacedText.replace(replacePattern4, " href='http://$3");
	let linksWithoutTags = replacedText.match(replacePattern1);

	if (linksWithoutTags) {
		// remove duplicate links
		linksWithoutTags = linksWithoutTags.filter(function (link, index, links) {
			return links.indexOf(link) == index;
		});
		linksWithoutTags?.forEach(function (link) {
			notContainsHttp = link.match(replacePattern2);
			link = link.replace(/\//g, '\\/');
			link = link.replace(/\./g, '\\.');
			link = link.replace(/\?/g, '\\?');
			link = link.replace(/\=/g, '\\=');
			link = link.replace(/\+/g, '\\+');
			try {
				const regex = new RegExp(`(?!([^<]+)>)(^|[ ]|-|\\()(${link})`, 'gim');
				if (notContainsHttp) {
					replacedText = replacedText.replace(
						regex,
						'$2<a class="clickableLinksA" href="http://$3" target="_blank">$3</a>'
					);
				} else {
					replacedText = replacedText.replace(
						regex,
						'$2<a class="clickableLinksA" href="$3" target="_blank">$3</a>'
					);
				}
			} catch (error) {
				return inputText;
			}
		});
	}
	if (replacedText.indexOf('mailto:') === -1) {
		replacedText = replacedText.replace(replacePattern3, '<a class="clickableLinksA" href="mailto:$2">$2</a>');
		replacedText = replacedText.replace(replacePattern5, '<a class="clickableLinksA" href="mailto:$3">$1$3$5</a>');
	}

	return replacedText;
}
export const regExp = new RegExp('<a href=', 'g');

export const removeNewLineCharacterByIndex = (cursorIndex, message) => {
	const index = message?.slice(cursorIndex - 1, cursorIndex);

	if (index === '\n') {
		const textBeforeNewLineChar = message?.substr(0, cursorIndex - 1);
		const textAfterNewLineChar = message?.substr(cursorIndex, message.length);

		return textBeforeNewLineChar + textAfterNewLineChar;
	}

	return message;
};
