import { IApiDataProps, IObjProps } from 'shared/consts/types';

export interface IUserDetailsStateInterface {
	permittedPlatforms: Array<IObjProps>;
	botAdmin: IApiDataProps<IObjProps & { active_module?: string }>;
	profile: IApiDataProps<IObjProps>;
	security: IApiDataProps<IObjProps>;
	categoriesMap: Array<IObjProps>;
	integrationAccountDetails: IObjProps;
	journey: IApiDataProps<IObjProps>;
	geoLocation: IObjProps;
	stripeIdInCustomerDetails: boolean;
}

export const userDetailsState: IUserDetailsStateInterface = {
	botAdmin: {
		data: {},
		error: false,
		loading: true,
	},
	profile: {
		data: {},
		error: false,
		loading: true,
	},
	security: {
		data: {},
		error: false,
		loading: true,
	},
	journey: {
		data: {},
		error: false,
		loading: true,
	},
	geoLocation: {},
	categoriesMap: [],
	permittedPlatforms: [],
	integrationAccountDetails: {},
	stripeIdInCustomerDetails: false,
};
