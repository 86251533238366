import { IObjProps } from 'shared/consts/types';

export const notificationTrayState: INotificationInterface = {
	data: [],
	isOpen: false,
};

export interface INotificationInterface {
	data: IObjProps[];
	isOpen: boolean;
}
