import React from 'react';
import { useStyletron } from 'baseui';
import { Menu } from 'baseui/icon';
import { useDispatch } from 'react-redux';

import { common } from 'store/Common';

function HamburgerMenu() {
	const [css, theme]: any = useStyletron();
	const dispatch = useDispatch<any>();

	return (
		<div
			onClick={() => dispatch(common.openMobileMenu())}
			className={css({
				marginLeft: '1rem',
				cursor: 'pointer',
				zIndex: theme.zIndex500,
			})}
		>
			<Menu
				overrides={{
					Svg: {
						style: {
							color: theme.colors.primaryA,
							width: '2rem',
							height: '2rem',
						},
					},
				}}
			/>
		</div>
	);
}

export default HamburgerMenu;
