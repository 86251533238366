import ENGTLink from 'components/UI/ENGTLink/ENGTLink';

import { DOC_LINKS_V2 } from '../DockLinks';

export const ADD_PATH_BUTTON_CSS = {
	backgroundColor: '#E8E6E7',
	paddingTop: '0 !important',
	paddingBottom: '0 !important',
	marginTop: '0.5rem',
	marginBottom: '0.5rem !important',
	width: '6.5rem',
};

export const AGGREGATED_BROADCASTS: string[] = ['AGGREGATED_ANALYTICS', 'AGGREGATED_BROADCAST_USER_ANALYTICS'];

export const ENGAGEMENT_EXPORTS: string[] = ['BROADCAST_ENGAGEMENT_USERS_EXPORT', 'META_ADS_ENGAGEMENT_USERS_EXPORT'];

export const ADD_PHONE_BUTTON_CSS = {
	paddingTop: '0 !important',
	paddingBottom: '0 !important',
	marginTop: '0.5rem',
	marginBottom: '0.5rem !important',
	width: '8rem',
	height: '1.5rem',
};

export const OVERRIDE_ACCORDION_CSS = {
	style: {
		Root: {
			style: () => ({
				borderTopStyle: 'none',
				marginBottom: '0.5rem',
				width: '100%',
			}),
		},
		Header: {
			style: ({ $expanded, $theme }: any) => ({
				backgroundColor: 'transparent',
				borderBottomStyle: 'none',
				fontWeight: 600,
				fontSize: '0.825rem',
				lineHeight: '1.25rem',
				paddingLeft: '0',
				paddingRight: '0',
				paddingTop: '0.75rem',
				paddingBottom: '0rem',
			}),
		},
		Content: {
			style: ({ $expanded, $theme }: any) => ({
				paddingBottom: $expanded ? '0.5rem' : '0rem',
				paddingTop: $expanded ? '0.5rem' : '0rem',
				paddingRight: '0rem',
				paddingLeft: '0rem',
				backgroundColor: $theme.colors.shopifyAccordionBackgroundColor,
				borderBottomWidth: '0rem',
				width: '100%',
			}),
		},
		PanelContainer: {
			style: ({ $theme }: any) => ({
				borderBottomStyle: 'none',
			}),
		},
	},
};

export const renderWhatsappTemplateNote = (isEngatiBrand: boolean, t: any) =>
	isEngatiBrand ? (
		<>
			{t('pages:broadcast.broadcastHistory.whatsapppTemplateNote.note1')}
			<ENGTLink
				link={t('pages:broadcast.basic.clickHere')}
				url={DOC_LINKS_V2.broadcast.whatsappMarketingTemplate}
				overrides={{ color: 'inherit', fontWeight: 'inherit' }}
			/>
			{t('pages:broadcast.broadcastHistory.whatsapppTemplateNote.note2')}
			<ENGTLink
				link={t('pages:broadcast.broadcastHistory.whatsapppTemplateNote.here')}
				url={DOC_LINKS_V2.broadcast.whatsappCloudErrorCodes}
				overrides={{ color: 'inherit', fontWeight: 'inherit' }}
			/>
		</>
	) : (
		<>
			{t('pages:broadcast.broadcastHistory.whatsapppTemplateNote.whiteLabelNote1')}
			<ENGTLink
				link={t('pages:broadcast.basic.clickHere')}
				url={DOC_LINKS_V2.broadcast.whatsappCloudErrorCodes}
				overrides={{ color: 'inherit', fontWeight: 'inherit' }}
			/>
			{t('pages:broadcast.broadcastHistory.whatsapppTemplateNote.whiteLabelNote2')}
		</>
	);
