import { createSlice } from '@reduxjs/toolkit';

import { campaignsReducer } from './reducers';
import { campaignState } from './state';

const campaignSlice = createSlice({
	name: 'Campaign',
	initialState: campaignState,
	reducers: campaignsReducer,
});

export const { actions, reducer } = campaignSlice;
export default campaignSlice;
