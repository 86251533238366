import React from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ListItemWithIcon } from 'components/UI/ListItemWithIcon/ListItemWithIcon';

import { DOC_LINKS_V2 } from 'shared/consts/DockLinks';
import WhatsnewIcon from 'shared/icons/WhatsnewIcon';

import { isShopifyEnabledAcc, isWhatsappAcceleratorEnabled } from 'store/App/User/selectors';
import { RootState } from 'store/rootReducer';

const WhatsNew = () => {
	const [, theme]: [any, any] = useStyletron();
	const { t }: any = useTranslation(['common']);

	const isEngatiUser = useSelector((state: RootState) => state.User.botAdmin.data.is_engati_brand);
	const isWhatsappAccelerator = useSelector(isWhatsappAcceleratorEnabled);
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);

	const whatsNewIcon = () => <WhatsnewIcon fill={theme.colors.navIconFillColor} />;

	return isEngatiUser && !isWhatsappAccelerator ? (
		<ListItemWithIcon
			iconElement={whatsNewIcon}
			itemLabel={t('common:whats_new')}
			linkTo={isShopifyEnabled ? DOC_LINKS_V2.shopifyReleaseNotes : DOC_LINKS_V2.releaseNotes}
		/>
	) : (
		<></>
	);
};

export default WhatsNew;
