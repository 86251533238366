import React from 'react';
import { useStyletron } from 'baseui';
import { useSelector } from 'react-redux';

import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';
import { ProfileAvatar } from 'components/UI/ProfileAvatar/ProfileAvatar';

import { isWhatsappAcceleratorEnabled } from 'store/App/User/selectors';
import { RootState } from 'store/rootReducer';

import AccountSettings from './components/AccountSettings';
import Feedback from './components/Feedback';
import GetProfessionalHelp from './components/GetProfessionalHelp';
import Integrations from './components/Integrations';
import LogOut from './components/Logout';
import MasterAdminModes from './components/MasterAdmin';
import MyBots from './components/MyBots';
import PoliciesCTA from './components/PoliciesCTA';
import WhatsNew from './components/WhatsNew';

import './AccountDropdownMenu.scss';

export default function AccountDropdownMenu({ onClose }: { onClose: () => void }) {
	const [css, theme]: [any, any] = useStyletron();

	const userName = useSelector((state: RootState) => state.User.profile.data.name);
	const userAvatar = useSelector((state: RootState) => state.User.profile.data.profileImg);
	const userEmail = useSelector((state: RootState) => state.User.botAdmin.data.email);

	const isWhatsappAccelerator = useSelector(isWhatsappAcceleratorEnabled);

	const dropdownContainerCss = {
		width: '17.94rem',
		borderBlockStyle: 'solid',
		borderWidth: '1px',
		borderTopColor: theme.colors.horizantalDividerColor,
		borderLeftColor: theme.colors.horizantalDividerColor,
		borderRightColor: theme.colors.horizantalDividerColor,
		borderBottomColor: theme.colors.horizantalDividerColor,
		borderImageSource:
			'linear-gradient(0deg, rgba(234, 234, 239, 0), rgba(234, 234, 239, 0)), linear-gradient(179.53deg, rgba(177, 177, 177, 0.24) -32.04%, rgba(255, 255, 255, 0) 64.89%)',
		boxShadow: '0px 22px 52px rgba(0, 0, 0, 0.08)',
		borderRadius: '0.5rem',
		background: theme.colors.backgroundPrimary,
		paddingTop: '1.5rem',
		paddingLeft: '1.5rem',
		paddingRight: '1.5rem',
		paddingBottom: '1.5rem',
	};

	return (
		<div className={css(dropdownContainerCss)}>
			<div className={css({ display: 'flex', flexDirection: 'column', gap: '0.75rem', marginBottom: '1.5rem' })}>
				<ProfileAvatar
					bgColor='#403F42'
					userEmail={userEmail}
					initialsFontSize='1.5rem'
					userAvatar={userAvatar}
					userName={userName}
					userAvatarSize='4rem'
					onClose={onClose}
				/>
				<Feedback />
			</div>
			<HorizontalDivider height={0} />
			<div
				className={css({
					paddingBottom: '0.5rem',
					paddingTop: '0.5rem',
				})}
			>
				<GetProfessionalHelp />
				<WhatsNew />
				<AccountSettings onClose={onClose} />
				<MasterAdminModes />
			</div>
			{!isWhatsappAccelerator && <HorizontalDivider height={0} />}
			<div className={css({ marginTop: isWhatsappAccelerator ? '0rem' : '0.75rem' })}>
				<MyBots />
				<Integrations />
				<LogOut />
			</div>
			<PoliciesCTA />
		</div>
	);
}
