import React from 'react';
import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AVATAR_BG_COLORS, ENGATI_ROLES, OFFERING_KEY } from 'shared/consts/consts';

import { fontStyleSelector, isShopifyEnabledAcc, isShopifyPrivateAcc } from 'store/App/User/selectors';
import { RootState } from 'store/rootReducer';
import { isEngatiBrandSelector } from 'store/Users/selectors';

import { NoColumnRouteLinks } from 'router/NoColumnRoutes';

import './ProfileAvatar.scss';

export interface IProfileAvatarProps {
	/**
	 * background color for the initials in the user avatar img when no profile img is present
	 * if a color is given that is used as bg else use a random color from a set of colors
	 */
	bgColor: string;
	/**
	 * font size for the text used to show the initials of user if profile image is not present
	 */
	initialsFontSize: string;
	/**
	 * user's profile img url, if undefined initials of the name shown
	 */
	userAvatar?: string;
	/**
	 * size of the profile img container
	 */
	userAvatarSize: string;
	userEmail: string;
	userName: string;
	onClose?: () => void;
}

const avatarOverrideCss = ({ bgColor, initialsFontSize }: { bgColor: string; initialsFontSize: string }) => ({
	Root: {
		style: {
			backgroundColor: bgColor || AVATAR_BG_COLORS[Math.floor(Math.random() * AVATAR_BG_COLORS.length)],
		},
	},
	Initials: {
		style: {
			height: 'calc(100% + 5px)',
			fontSize: initialsFontSize,
			fontWeight: 600,
			color: '#fff',
		},
	},
});

export const ProfileAvatar: React.FC<React.PropsWithChildren<IProfileAvatarProps>> = ({
	bgColor,
	initialsFontSize = '1.5rem',
	userAvatar = '',
	userAvatarSize,
	userEmail,
	userName,
	onClose,
	...props
}) => {
	const [css, theme] = useStyletron();
	const navigate = useNavigate();
	const { t } = useTranslation(['pages']);
	const isOwnerAccount =
		useSelector((state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_OWNER) ||
		false;
	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const isNotReseller = useSelector((state: RootState) => !state.User.botAdmin.data.reseller_login);
	const customerPlanDetails: any = useSelector((state: RootState) => state.Plan.current.data);
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const isShopifyPrivateAccount = useSelector(isShopifyPrivateAcc);

	const isShopifyPublicAccount = () => isShopifyEnabled && !isShopifyPrivateAccount;

	const fontStyle: string = useSelector(fontStyleSelector);

	const offeringKey: any = customerPlanDetails?.offeringKey;

	const upgradeBtnCss = css({
		color: `${theme.colors.accent}`,
		borderBottomWidth: '1px',
		borderBottomStyle: 'solid' as const,
		cursor: 'pointer',
	});

	const navigateToBilling = () => {
		navigate(NoColumnRouteLinks.billing);
		onClose && onClose();
	};

	return (
		<div className={css({ display: 'flex', fontFamily: 'Hind', width: '100%' })}>
			<span className={css({ height: userAvatarSize })}>
				<Avatar
					overrides={avatarOverrideCss({ bgColor, initialsFontSize })}
					src={userAvatar}
					name={userName || ''}
					size={userAvatarSize}
				/>
			</span>
			<span className='profile-body'>
				<label className='profile-body__title' style={{ color: theme.colors.primaryA, fontFamily: fontStyle }}>
					{userName}
				</label>
				<label
					className='profile-body__subtitle'
					style={{ color: theme.colors.primaryA, fontFamily: fontStyle }}
				>
					{userEmail}
				</label>
				{isOwnerAccount && isEngatiBrand && isNotReseller && !isShopifyPublicAccount() && (
					<a className={upgradeBtnCss} onClick={navigateToBilling}>
						{offeringKey && OFFERING_KEY[offeringKey] !== OFFERING_KEY['ENGATI_STARTER']
							? t('pages:accountSettings.subscriptionDetailsPage.manageBilling')
							: t('pages:accountSettings.subscriptionDetailsPage.upgradeSubscription')}
					</a>
				)}
			</span>
		</div>
	);
};
