import { toaster } from 'baseui/toast';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { MESSAGE_TAB_HEADERS_MAP, ONE_VIEW_LITE } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';

import { jitsiActions } from 'store/App/Jitsi';
import {
	getCsRequestAction,
	getDraftMessage,
	getLiteQuickFiltersFromLS,
	markConversationAsReadOrUnread,
	removeDraftMessageByConversationId,
	removeSelectedUserFromListAction,
	setSelectedUserAction,
	setUserListAction,
	updateAllUsersCountAction,
} from 'store/Message';
import { openInfoModalAction } from 'store/Modal';
import { RootState } from 'store/rootReducer';

import { i18nHelper } from 'i18nHelper';

import { actions } from '../../Message/store';
import { SOCKET_EVENTS, socketEventEmitter, stateToTabMapping, translation } from '../consts';
import { currentTab, filterUsersByConversationId, getUserFromList } from '../helper';

const { setUserMessages } = actions;

export const notifySenderActionListener =
	(socket: any, clearTimeoutTypingOff: any) => (dispatch: any, getState: () => RootState) => {
		socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_SENDER_REACTION);

		socket.on(SOCKET_EVENTS.NOTIFY_SENDER_REACTION, (d: any) => {
			const data = JSON.parse(d);
			const { selectedUser } = getState().Message;
			const messageTypingDiv = document.getElementById('message-typing-loader');
			const messageContainer = document.getElementById('messages-container');

			if (selectedUser.user_id === data.user_id) {
				if (messageTypingDiv && messageContainer) {
					messageTypingDiv.style.display = 'block';
					messageContainer.scrollTop += 50;
				}
				if (clearTimeoutTypingOff) {
					clearTimeout(clearTimeoutTypingOff);
				}
				clearTimeoutTypingOff = setTimeout(function () {
					if (messageTypingDiv) {
						messageTypingDiv.style.display = 'none';
					}
				}, 3000);
			} else {
				if (messageTypingDiv) {
					messageTypingDiv.style.display = 'none';
				}
				clearTimeout(clearTimeoutTypingOff);
			}
		});
	};

export const notifyExitLiveChatListener = (socket: any) => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_EXIT_LIVECHAT);

	socket.on(SOCKET_EVENTS.NOTIFY_EXIT_LIVECHAT, (d: any) => {
		const tab = currentTab();
		const data = JSON.parse(d);
		const allDraftMessage = dispatch(getDraftMessage());
		const { selectedUser } = getState().Message;
		const quickFiltersInLs: any = dispatch(getLiteQuickFiltersFromLS());

		if (allDraftMessage?.[data.user_id]) {
			dispatch(removeDraftMessageByConversationId(data.user_id));
		}
		if (tab === MESSAGE_TAB_HEADERS_MAP.all || (tab === ONE_VIEW_LITE && !quickFiltersInLs?.myConversations)) {
			if (data.user_id === selectedUser.user_id) {
				dispatch(getCsRequestAction(selectedUser));
			}

			return;
		}
		const { selectedVideoUser } = getState().Jitsi;
		const userListData = getState().Message.userList.data;
		const userDetail = getState().User.botAdmin.data;
		const users = Array.isArray(userListData) ? userListData : [];
		const user = getUserFromList(users, data.user_id);
		if (!user) {
			return;
		}
		const heading = translation('notifyExitLiveChat.heading');
		const description = translation('notifyExitLiveChat.description');
		if (data.user_id === selectedUser.user_id) {
			if (selectedVideoUser && selectedUser.conversation_id === selectedVideoUser.conversation_id) {
				dispatch(jitsiActions.closeVideoCallAction(userDetail));
			}
			dispatch(removeSelectedUserFromListAction({ conversation_id: data.user_id }));
			socketEventEmitter.emit(SOCKET_EVENTS.NOTIFY_EXIT_LIVECHAT, { ...data });

			dispatch(openInfoModalAction(heading, description));
		} else {
			dispatch(setUserListAction(filterUsersByConversationId(data.user_id, getState)));
			toaster.positive(<ENGTToasterContainer title={heading} description={description} />, {});
		}
	});
};

export const notifyChatStateUpdateListener =
	(socket: any, liveChatStatusChange: () => void, getConversationData: () => void) =>
	(dispatch: any, getState: any) => {
		socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_CHAT_STATE_UPDATE);

		socket.on(SOCKET_EVENTS.NOTIFY_CHAT_STATE_UPDATE, (d: any) => {
			const data = JSON.parse(d);
			const { conversation_id: conversationId, username, assigned_by: assignedBy, from_state: fromState } = data;
			const { selectedUser } = getState().Message;
			const isNewBannerEnabled = getState().User.botAdmin.data?.enable_start_conversation_from_backend || false;
			const tab = currentTab();
			let dispatchUser = false;
			let showModal = false;
			let showToaster = false;
			const quickFilters: any = dispatch(getLiteQuickFiltersFromLS());

			if (
				tab === MESSAGE_TAB_HEADERS_MAP.active ||
				tab === MESSAGE_TAB_HEADERS_MAP.new ||
				tab === MESSAGE_TAB_HEADERS_MAP.unassigned ||
				(tab === ONE_VIEW_LITE && quickFilters?.myConversations)
			) {
				dispatchUser = true;
				if (
					selectedUser &&
					selectedUser.conversation_id === conversationId &&
					(tab === ONE_VIEW_LITE ? !quickFilters?.myConversations : true)
				) {
					showModal = true;
				} else {
					showToaster = true;
				}
			} else {
				showToaster = true;
			}

			if (dispatchUser) {
				if (selectedUser && selectedUser.conversation_id === conversationId) {
					dispatch(removeSelectedUserFromListAction({ conversation_id: conversationId }));
					socketEventEmitter.emit(SOCKET_EVENTS.NOTIFY_CHAT_STATE_UPDATE, {
						...data,
					});
				} else {
					dispatch(setUserListAction(filterUsersByConversationId(conversationId, getState)));
				}
			}
			dispatch(liveChatStatusChange());

			const heading = translation('notifyChatStateUpdate.heading');
			const description = `${translation('notifyChatStateUpdate.descriptionStart')} ${
				selectedUser.username || username
			} ${translation('notifyChatStateUpdate.descriptionEnd')}`;
			showModal && dispatch(openInfoModalAction(heading, description));
			if (showToaster && assignedBy && fromState) {
				dispatch(
					updateAllUsersCountAction(
						'remove',
						data,
						stateToTabMapping[fromState] || stateToTabMapping['DEFAULT']
					)
				);

				toaster.positive(<ENGTToasterContainer title={heading} description={description} />, {});
			} else if (showToaster) {
				dispatch(updateAllUsersCountAction('remove', data, MESSAGE_TAB_HEADERS_MAP.unassigned));
			}
			if (isNewBannerEnabled) {
				dispatch(getConversationData());
			}
		});
	};

export const chatStatusChangeListener =
	(socket: any, getConversationData: () => void) => (dispatch: any, getState: any) => {
		socket.removeAllListeners(SOCKET_EVENTS.CHAT_STATUS_CHANGE);

		socket.on(SOCKET_EVENTS.CHAT_STATUS_CHANGE, (d: any) => {
			const data = JSON.parse(d);
			const isNewBannerEnabled = getState().User.botAdmin.data?.enable_start_conversation_from_backend || false;
			const { selectedUser } = getState().Message;
			const userListData = getState().Message.userList.data;
			const allDraftMessage = dispatch(getDraftMessage());
			const currentTabValue = currentTab();

			if (
				currentTabValue === MESSAGE_TAB_HEADERS_MAP.active ||
				currentTabValue === MESSAGE_TAB_HEADERS_MAP.new ||
				currentTabValue === MESSAGE_TAB_HEADERS_MAP.all ||
				currentTabValue === MESSAGE_TAB_HEADERS_MAP.unassigned ||
				currentTabValue === MESSAGE_TAB_HEADERS_MAP.oneviewlite
			) {
				Object.keys(data).forEach(function (userKey) {
					let users = Array.isArray(userListData) ? [...userListData] : [];
					users = JSON.parse(JSON.stringify(users));
					let isChatWindowActive = false;
					const { message } = data[userKey];
					if (message === 'chat opened') {
						isChatWindowActive = true;
					}
					for (let i = 0; i < users.length; i++) {
						if (users[i].conversation_id === data[userKey].conversation_id) {
							users[i].is_chat_window_active = isChatWindowActive;
							users[i].user_online = isChatWindowActive;
							break;
						}
					}
					if (selectedUser?.conversation_id === data[userKey].conversation_id) {
						dispatch(
							setSelectedUserAction({
								...selectedUser,
								is_chat_window_active: isChatWindowActive,
								user_online: isChatWindowActive,
							})
						);
					}
					dispatch(setUserListAction(users));
					if (message === 'chat closed' && allDraftMessage?.[data[userKey].conversation_id]) {
						dispatch(removeDraftMessageByConversationId(data[userKey].conversation_id));
					}
				});
				if (isNewBannerEnabled) {
					dispatch(getConversationData());
				}
			}
		});
	};

export const notifyConversationReadStatusListener =
	(socket: any, payload_identifier: string) => (dispatch: any, getState: () => RootState) => {
		socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_CONVERSATION_READ_STATUS);

		socket.on(SOCKET_EVENTS.NOTIFY_CONVERSATION_READ_STATUS, (d: any) => {
			const data = JSON.parse(d);
			const { uid } = getState().User.botAdmin.data;
			const { agent_id, payload_identifier: id, conversation_id, is_conversation_read } = data;

			if (id && payload_identifier && id === payload_identifier) {
				return;
			}
			payload_identifier = id;

			if (uid === agent_id) {
				return;
			}
			dispatch(markConversationAsReadOrUnread(conversation_id, is_conversation_read));
		});
	};

export const notifyNewMessageStatus = (socket: any) => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.MESSAGE_STATUS_UPDATE);

	socket.on(SOCKET_EVENTS.MESSAGE_STATUS_UPDATE, (socketData: any) => {
		const data: IObjProps = JSON.parse(socketData);

		const { status, messageId, userId, platform } = data;

		const { selectedUser } = getState().Message;
		const userMessages = getState().Message.userMessages.data;

		if (selectedUser.user_id === userId && selectedUser.is_live_chat_enable) {
			userMessages.forEach((messageData: IObjProps, index: number) => {
				if (messageData.messageId && messageId && messageData.messageId === messageId) {
					const updatedUserMessages = [...userMessages];
					const updatedMesageData = { ...updatedUserMessages[index] };
					updatedMesageData['messageStatus'] = status;
					updatedMesageData['platform'] = platform;
					updatedUserMessages[index] = updatedMesageData;
					dispatch(setUserMessages(updatedUserMessages));
				}
			});
		}
	});
};

export const notifyTestBroadcastStatusListener = (socket: any) => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.TEST_BROADCAST_STATUS_UPDATE);

	socket.on(SOCKET_EVENTS.TEST_BROADCAST_STATUS_UPDATE, (socketData: any) => {
		const data: any = JSON.parse(socketData);

		const { phoneNumber, status } = data;

		return toaster.positive(
			<ENGTToasterContainer
				title={i18nHelper('pages:socketNotifications.testBroadcastStatusUpdate.heading', { status })}
				description={i18nHelper('pages:socketNotifications.testBroadcastStatusUpdate.description', {
					status,
					phoneNumber,
				})}
			/>,
			{}
		);
	});
};
