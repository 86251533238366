import { MessageScreens } from 'shared/enum';

import packages from '../../package.json';

const STORE_VERSION = packages.version;

export const loadState = () => {
	try {
		const serializedState = localStorage.getItem('storeData');
		const storeDataVersion = localStorage.getItem('storeDataVersion');

		if (storeDataVersion && storeDataVersion === STORE_VERSION) {
			if (serializedState === null) {
				return {};
			}

			return JSON.parse(decodeURI(window.atob(serializedState)));
		}
		localStorage.removeItem('storeData');

		return {};
	} catch (err) {
		console.log('error occurred while getting the state from LS', err);

		return {};
	}
};

export const saveState = (state: any) => {
	try {
		const storeData = JSON.parse(JSON.stringify(state));

		// exceptions to be not stored in LS
		if (storeData.PathBuilder) {
			storeData.PathBuilder.flows.data = [];
		}
		if (storeData.Bot) {
			storeData.Bot.agentOnlineStatus = false;
		}
		if (storeData.Message) {
			storeData.Message.mobileVisibleScreen = MessageScreens.LEFT;
			storeData.Message.usersCount = {};
			storeData.Message.botLevelTagsList = null;
		}
		if (storeData.Token) {
			storeData.Token = {};
		}
		if (storeData.Modal) {
			storeData.Modal = {
				errorSavingModal: {
					isOpen: false,
					errorCode: null,
				},
				infoModal: {
					isOpen: false,
					heading: '',
					description: '',
				},
			};
		}
		if (storeData.Train) {
			storeData.Train.entityTypes = {};
			if (storeData.Train.Faq) {
				storeData.Train.Faq.isLoading = true;
			}
		}
		if (storeData.Shopify) {
			storeData.Shopify.account = {};
		}

		if (storeData.Broadcast) {
			storeData.Broadcast.published.data = [];
			storeData.Broadcast.scheduled.data = [];
			storeData.Broadcast.draft.data = [];
			storeData.Broadcast.published.pagination = {};
			storeData.Broadcast.scheduled.pagination = {};
			storeData.Broadcast.draft.pagination = {};
		}
		if (storeData.User) {
			storeData.User.journey.loading = true;
		}

		if (state?.Token?.token) {
			const serializedState = window.btoa(encodeURI(JSON.stringify(storeData)));

			localStorage.setItem('storeData', serializedState);
			localStorage.setItem('storeDataVersion', STORE_VERSION);
		}
	} catch (error) {
		console.log('error occurred while saving the state to LS: ', error);
		localStorage.removeItem('storeData');
		localStorage.removeItem('storeDataVersion');
	}
};
