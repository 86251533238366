import React, { useCallback, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { MonoLabelXSmall } from 'baseui/typography';
import { useTranslation } from 'react-i18next';

import FormLabel from 'components/UI/Form/FormLabel/FormLabel';
import ActionModalWithInput from 'components/UI/Modal/ActionModalWithInput/ActionModalWithInput';

import { ISelectValue } from 'shared/consts/types';

interface ISaveSelectedFilters {
	savedFilters: Array<ISelectValue>;
	onChange: Function;
	saveSelectedFilterDescription: Function;
	onSave: () => any;
	getCustomFilterGroupApi: Function;
}

function SaveSelectedFilters({
	savedFilters,
	onChange,
	saveSelectedFilterDescription,
	onSave,
	getCustomFilterGroupApi,
}: ISaveSelectedFilters) {
	const [css]: any = useStyletron();
	const { t } = useTranslation(['components']);

	const [isCustomFilterGroupOpen, toggleAddCustomFilterModal] = useState<boolean>(false);

	useEffect(() => {
		getCustomFilterGroupApi();
	}, [isCustomFilterGroupOpen]);

	const checkValidationForName = (value: any) => {
		const check = savedFilters?.filter((filter: any) => filter.filter_name === value);
		if (check?.length) {
			return true;
		}

		return false;
	};

	const onCloseFilterModal = useCallback(() => toggleAddCustomFilterModal(false), [toggleAddCustomFilterModal]);

	return (
		<>
			<MonoLabelXSmall
				className={css({
					cursor: 'pointer',
					textDecoration: 'underline',
					fontWeight: 500,
					fontSize: '0.75rem',
					lineHeight: '1rem',
					marginRight: '10rem',
					opacity: savedFilters.length >= 10 ? '0.5' : '',
					width: 'fit-content',
				})}
			>
				<p
					onClick={() => {
						if (savedFilters.length < 10) {
							toggleAddCustomFilterModal(true);
						} else {
							toggleAddCustomFilterModal(false);
						}
					}}
					className={css({
						marginBottom: '0.25rem',
					})}
				>
					{savedFilters.length >= 10 ? (
						<FormLabel
							id='saveSelectedFilters'
							label={t('components:messageFiltersLite.saveSelectedFilters')}
							tooltip={t('components:messageFiltersLite.tooltip')}
							fontSize='0.75rem'
						/>
					) : (
						<FormLabel
							id='saveSelectedFilters'
							label={t('components:messageFiltersLite.saveSelectedFilters')}
							fontSize='0.75rem'
						/>
					)}
				</p>
			</MonoLabelXSmall>
			{isCustomFilterGroupOpen && (
				<div>
					<ActionModalWithInput
						onChange={onChange}
						confirmBtnLabel={t('common:save')}
						heading={t('components:messageFiltersLite.saveSelectedFilters')}
						placeholder={t('components:messageFiltersLite.placeHolder')}
						description={saveSelectedFilterDescription()}
						isOpen
						isLoading
						isInputRequired
						toggleModal={onCloseFilterModal}
						onClose={onCloseFilterModal}
						validateError={checkValidationForName}
						onConfirm={onSave}
						hideCloseIcon
					/>
				</div>
			)}
		</>
	);
}

export default React.memo(SaveSelectedFilters);
