import { useEffect } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { KIND, SIZE as ButtonSize } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { EXPERT_ASSISTANCE_PARAM } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { isValidResponseObject } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import PaymentSuccessImg from 'shared/icons/PaymentSuccessful.svg';

import { API } from 'store/AccountSettings/api';

import { TwoColumnRouteLinks } from 'router/links/TwoColumnRouteLinks';

function PaymentSuccessModalExpertAssistance({
	toggleModal,
	isModalOpen,
	dateValue,
}: {
	toggleModal: Function;
	isModalOpen: boolean;
	dateValue: Date;
}) {
	const [css, theme]: [Function, IObjProps] = useStyletron();
	const navigate = useNavigate();
	const { t } = useTranslation(['pages', 'common']);
	const [searchParams, setSearchParams] = useSearchParams();
	const { isSmallDesktopScreen } = useResponsiveSize();

	useEffect(() => {
		if (searchParams.get(EXPERT_ASSISTANCE_PARAM) === 'true') {
			navigate('', { replace: true });
			searchParams.delete(EXPERT_ASSISTANCE_PARAM);
			setSearchParams(searchParams);
		}
	}, [searchParams]);

	const commonStyles = {
		font: {
			fontSize: '1rem',
			lineHeight: '1.25rem',
			fontWeight: 400,
			textAlign: 'center',
			color: theme.colors.primaryA,
		},
	};

	const styles = {
		header: {
			fontSize: '1.25rem',
			lineHeight: '1.625rem',
			fontWeight: 700,
			marginTop: '1.5rem',
			color: theme.colors.primaryA,
		},
		font1: {
			...commonStyles.font,
			fontWeight: 500,
		},
	};

	const failedToaster = () =>
		toaster.negative(<ENGTToasterContainer title={t('common:failed')} description={t('common:wentWrong')} />, {});

	const generateCustomerPortalLink = () => {
		API.getStripeCustomerPortal()
			.then((response: any) => {
				if (isValidResponseObject(response)) {
					window.open(response.data.responseObject, '_blank');
				} else {
					failedToaster();
				}
			})
			.catch(() => {
				failedToaster();
			});
	};

	const onConfirm = () => {
		toggleModal(false);
		navigate(TwoColumnRouteLinks.waQuickNavigator);
	};

	const onClose = () => {
		toggleModal(false);
	};

	return (
		<>
			<ActionModal
				autoFocus={false}
				isOpen={isModalOpen}
				cancelButtonUsage
				width={isSmallDesktopScreen ? '90vw' : '28rem'}
				isFooterReq={false}
				onConfirm={onConfirm}
				onClose={onClose}
				onCancelBtnClick={onClose}
			>
				<Block display='flex' flexDirection='column' gridGap='0.5rem' alignItems='center'>
					<ENGTImage src={PaymentSuccessImg} />
					<span className={css(styles.header)}>{t('components:confirmationModal.paymentSuccessful')}!</span>
					<span className={css(styles.font1)}>{t('components:confirmationModal.paySuccessMsg1')}</span>
					<div>
						<ENGTButton
							label={t('pages:accountSettings.subscriptionDetailsPage.viewInvoices')}
							kind={KIND['primary']}
							size={ButtonSize.default}
							type='submit'
							onClick={generateCustomerPortalLink}
						/>
					</div>
					<span className={css(commonStyles.font)}>
						{t('components:confirmationModal.confirmedSlot')}{' '}
						{t('common:standardDateTimeFormatterWithShortMonthNameWithoutTimezone', {
							date: dateValue,
						})}
					</span>
				</Block>
			</ActionModal>
		</>
	);
}

export default PaymentSuccessModalExpertAssistance;
