import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { REACT_APP_BASE_URL } from 'shared/config/config';
import { ENGATI_PLATFORM_BOT, ENGATI_PLATFORM_CONNECT, MAX_ZOOM_IN, SUPPORT_BOT_KEY } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { checkForSupportBot, isWindows, SUPPORT_BOTS } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import { RootState } from 'store/withReducer';

export const addSupportBot = () => (dispatch: any, getState: () => RootState) => {
	const activeModule = getState().User.botAdmin.data.active_module;
	const isMultiLanguageEnabled = getState().Bot.configData.multi_language_enabled;
	const customerEngagePlan: any = getState().Plan.current.data.planName;
	const { userPhoneNumber, roleName, name } = getState().User.profile.data;

	const isSmallDesktopScreen = window.matchMedia('(max-width: 900px)').matches;
	const isZoomIn = window.devicePixelRatio > (isWindows() ? MAX_ZOOM_IN.WINDOWS : MAX_ZOOM_IN.MAC);
	const isSmallScreen = isSmallDesktopScreen || isZoomIn;

	const attributes: IObjProps = {
		UserName: name,
		UserEmail: getState().User.botAdmin.data.email,
		UserPhoneNumber: userPhoneNumber,
		BotId: getState().Bot.data.bot_key,
		BotRef: getState().Bot.data.bot_ref,
		BotName: getState().Bot.data.bot_name,
		BotTimeZone: getState().Bot.data.bot_timezone,
	};

	attributes.CurrentModule = '';
	if (activeModule === ENGATI_PLATFORM_BOT) {
		attributes.CurrentModule = 'BotPlatform';
	} else if (activeModule === ENGATI_PLATFORM_CONNECT) {
		attributes.CurrentModule = 'LiveChat';
	}

	if (isMultiLanguageEnabled === 'NOT_ENABLED') {
		attributes.BotLanguage = 'en';
	} else {
		attributes.BotLanguage = getState().User.profile.data.locale;
	}
	if (roleName && roleName.split('_')) {
		attributes.Role = roleName.split('_')[2];
	}
	if (!customerEngagePlan) {
		attributes.CurrentPlan = 'STARTER';
	} else {
		attributes.CurrentPlan = customerEngagePlan.split('_')[1];
	}

	const userAttributes = [{ name: 'CurrentPage', value: '' }];
	const botAttributes: any = {
		bot_key: SUPPORT_BOT_KEY.PROD,
		welcome_msg: true,
		branding_key: 'default',
		server: REACT_APP_BASE_URL,
		e: 'p',
		repeatFlowOnClick: true,
	};

	const originLocation = window.location.origin;
	if (originLocation.includes('qa') && originLocation.includes('engati')) {
		botAttributes.bot_key = SUPPORT_BOT_KEY.QA;
		botAttributes.e = 'qa';
	}
	if (originLocation.includes('dev') && originLocation.includes('engati')) {
		botAttributes.bot_key = SUPPORT_BOT_KEY.DEV;
		botAttributes.e = 'd';
	}

	for (const userAttributesIterator in attributes) {
		const newObj = { name: '', value: '' };
		newObj.name = userAttributesIterator;
		newObj.value = attributes[userAttributesIterator];
		userAttributes.push(newObj);
	}

	botAttributes.user_attributes = userAttributes;
	botAttributes.initFunction = `return ${JSON.stringify(userAttributes)}`;

	try {
		if (!document.querySelector(SUPPORT_BOTS.ENGATI_BOT)) {
			(function (e, t, a) {
				const c = e.head || e.getElementsByTagName('head')[0];
				const n: any = e.createElement('script');
				n.async = !0;
				n.defer = !0;
				n.type = 'text/javascript';
				n.src = `${t}/static/js/widget.js?config=${JSON.stringify(a)}`;
				c.appendChild(n);
				n.onload = function () {
					let counter = 0; // check for 5 counts to know if bot is loaded in dom

					const eventListener = window.setInterval(() => {
						counter += 1;
						if (checkForSupportBot(SUPPORT_BOTS.ENGATI_BOT, isSmallScreen) || counter > 5) {
							window.clearInterval(eventListener);
						}
					}, 1000);
				};
			})(document, REACT_APP_BASE_URL, botAttributes);
		}
	} catch (e) {
		console.error('Error with opening support bot', e);
	}
};

const SupportBots = () => {
	const dispatch = useDispatch<any>();
	const location = useLocation();
	const { isSmallDesktopScreen } = useResponsiveSize();
	const [isZoomIn, setIsZoomIn] = useState(false);

	useEffect(() => {
		checkForSupportBot(SUPPORT_BOTS.ENGATI_BOT, isSmallDesktopScreen || isZoomIn);
	}, [location, isSmallDesktopScreen, isZoomIn]);

	useEffect(() => {
		const handleZoomOut = () => {
			setIsZoomIn(window.devicePixelRatio > (isWindows() ? MAX_ZOOM_IN.WINDOWS : MAX_ZOOM_IN.MAC));
		};
		window.addEventListener('resize', handleZoomOut);

		return () => {
			window.removeEventListener('resize', handleZoomOut);
		};
	}, []);

	return <></>;
};

export default SupportBots;
