import { createSlice } from '@reduxjs/toolkit';

import { messageReducer } from './reducers';
import { messageState } from './state';

export const messageSlice = createSlice({
	name: 'Message',
	initialState: messageState,
	reducers: messageReducer,
});

export const { actions, reducer } = messageSlice;
