import React from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';

import { VerticalDivider } from 'components/UI/Dividers/VerticalDivider';

import { ENGATI_SUPPORT_MAIL } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { PlgGTMTrackingIds } from 'shared/enum/trackingEnums';
import { sendPLGButtonClickEventToGA4 } from 'shared/trackingHelpers';

interface IContactSupportEmailBtn {
	wrapperCss?: IObjProps;
}

const ContactSupportEmailBtn = ({ wrapperCss = {} }: IContactSupportEmailBtn) => {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['pages', 'common']);

	return (
		<a
			href={`mailto: ${ENGATI_SUPPORT_MAIL}?subject= ${t(
				'pages:upgradeRequestForWAAccelerator.supportForWAAcceleratorSubject'
			)}`}
			className={css({
				fontSize: '0.875rem',
				lineHeight: '1.25rem',
				cursor: 'pointer',
				textDecoration: 'none',
				color: theme.colors.primaryA,
				letterSpacing: '0.0015em',
				fontWeight: 500,
				...wrapperCss,
			})}
			onClick={() =>
				sendPLGButtonClickEventToGA4({
					gtmTrackingId: PlgGTMTrackingIds.CONTACT_SUPPORT_BUTTON_CLICKED,
				})
			}
		>
			{t('common:contactSupport')}
		</a>
	);
};

const ContactSupportEmailBtnWithDivider = (props: IContactSupportEmailBtn) => {
	const [, theme]: any = useStyletron();

	return (
		<>
			<VerticalDivider
				color={theme.colors.accent400}
				height='1.25rem'
				overrides={{
					marginLeft: '0.75rem',
					marginRight: '0.75rem',
					marginTop: '0.125rem',
					marginBottom: '0.125rem',
				}}
			/>
			<ContactSupportEmailBtn {...props} />
		</>
	);
};

export { ContactSupportEmailBtn, ContactSupportEmailBtnWithDivider };
