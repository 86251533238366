import React from 'react';
import { useStyletron } from 'baseui';

function MinusIcon({ fillPath, fillCircle }: { fillPath?: string; fillCircle?: string }) {
	const [, themes] = useStyletron();

	return (
		<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<circle cx='10' cy='10' r='10' fill={fillCircle || themes.colors.backgroundSecondary} />
			<path d='M6 10.5V9.5H14V10.5H6Z' fill={fillPath || themes.colors.primaryA} />
		</svg>
	);
}

export default MinusIcon;
