import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ListItemWithIcon } from 'components/UI/ListItemWithIcon/ListItemWithIcon';

import { ACCOUNT_TYPES } from 'shared/consts/consts';
import { isEmpty } from 'shared/helpers';
import AdminModeIcon from 'shared/icons/AdminMode';

import { API as adminAPI } from 'store/App/User/api';
import { RootState } from 'store/rootReducer';

import { getAdminPartnerMode, isCurrentUserNotAdmin, setAdminModeInLs } from '../helpers/helpers';

const MasterAdminModes = () => {
	const [adminMode, setAdminMode] = useState(false);
	const [partnerMode, setIsPartnerMode] = useState(false);

	const botAdminData = useSelector((state: RootState) => state.User.botAdmin.data);
	const { email, reseller_login: resellerLogin, customer_account_type: accountType } = botAdminData || {};

	useEffect(() => {
		const [isAdminMode, isPartner] = getAdminPartnerMode(resellerLogin, accountType, email);

		setAdminMode(isAdminMode);
		setIsPartnerMode(isPartner);
	}, [email, resellerLogin, accountType]);

	const isUserNotAdmin = isCurrentUserNotAdmin(email);

	const redirectToOldUI = () => {
		window.location.href = '/?classic#/';
	};

	const updateAndRedirectToOldUI = () => {
		setAdminMode(true);
		localStorage.setItem('adminMode', 'true');
		redirectToOldUI();
	};

	const changeAdminMode = () => {
		if (adminMode) {
			setAdminMode(false);
			setAdminModeInLs();
		} else if (isUserNotAdmin) {
			adminAPI.changeCustomerToAdmin().then((response: any) => {
				if (response?.data?.message === 'success') {
					updateAndRedirectToOldUI();
				}
			});
		} else {
			updateAndRedirectToOldUI();
		}
	};

	return (
		<>
			{adminMode ? (
				<ActiveMode onClick={changeAdminMode} partnerMode={partnerMode} />
			) : (
				<AdminMode onClick={changeAdminMode} partnerMode={partnerMode} />
			)}
		</>
	);
};

const AdminMode = ({ onClick, partnerMode }: { onClick: () => any; partnerMode: boolean }) => {
	const [, theme]: [any, any] = useStyletron();
	const { t }: any = useTranslation(['common']);

	const resellerLogin = useSelector((state: RootState) => state.User.botAdmin.data.reseller_login);

	const adminModeIcon = () => <AdminModeIcon fill={theme.colors.navIconFillColor} />;

	return resellerLogin && partnerMode ? (
		<ListItemWithIcon iconElement={adminModeIcon} itemLabel={t('common:adminMode')} handleClick={onClick} />
	) : (
		<></>
	);
};

const ActiveMode = ({ onClick }: { onClick: () => any; partnerMode: boolean }) => {
	const [, theme]: [any, any] = useStyletron();
	const { t }: any = useTranslation(['common']);

	const botAdminData = useSelector((state: RootState) => state.User.botAdmin.data);

	let showCreatorMode = botAdminData?.bot_creator_mode;

	if (botAdminData.customer_account_type === ACCOUNT_TYPES.MASTER_ADMIN || isEmpty(botAdminData)) {
		showCreatorMode = false;
	}

	const adminModeIcon = () => <AdminModeIcon fill={theme.colors.navIconFillColor} />;

	return !showCreatorMode ? (
		<ListItemWithIcon iconElement={adminModeIcon} itemLabel={t('common:activeMode')} handleClick={onClick} />
	) : (
		<></>
	);
};

export default MasterAdminModes;
