import { useState } from 'react';
import { useStyletron } from 'baseui';
import { formatDateToLongDateAndTime } from 'i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ENGTRadio from 'components/UI/ENGTRadio/ENGTRadio';
import ModalAlert from 'components/UI/Modal/ModalAlert/ModalAlert';

import { IObjProps } from 'shared/consts/types';
import { BROADCAST_TYPE } from 'shared/enum';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

enum USERS {
	ALL_USERS = 'all_users',
	FREE_USERS = 'free_users',
}

interface ICtwaInfoInfoCardProps {
	isModalOpen: Function;
	adId: string;
	segmentData: IObjProps;
}

const ClickToWARetargetModal = ({ isModalOpen, segmentData }: ICtwaInfoInfoCardProps) => {
	const { t } = useTranslation(['common', 'pages']);
	const [css]: [any, any] = useStyletron();
	const navigate = useNavigate();

	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);
	const [retargetSegments, setRetargetSegments] = useState({
		id: '',
		count: '',
		name: '',
	});

	const getFormattedDate = (date: Date) => formatDateToLongDateAndTime(date);

	const onConfirmRetarget = () => {
		const segmentDetails = {
			id: retargetSegments.id,
			label: retargetSegments.id,
			content: {},
		};

		const data = {
			retargetUsers: retargetSegments.count,
			retargetSegments: segmentDetails,
			retargetPayload: {
				name: `${retargetSegments.name}-${getFormattedDate(new Date())}`,
			},
		};

		const queryString = `mode=${BROADCAST_TYPE.ADS_SEGMENT}&payload=${encodeURIComponent(
			JSON.stringify(data)
		)}&path=adsSegment`;
		navigate(`${ThreeColumnRouteLinks.createBroadcast}?${queryString}`);
	};

	const retargetOptions = () => (
		<div
			className={css({
				marginTop: '0.5rem',
			})}
		>
			<ENGTRadio
				group='options'
				buttons={predefinedOptions}
				value={retargetSegments.id}
				onChange={(value: string) => {
					if (value === USERS.ALL_USERS) {
						setRetargetSegments({
							id: segmentData.all_users_segment_id,
							count: segmentData.all_users_count,
							name: segmentData.all_users_segment_name,
						});
					} else {
						setRetargetSegments({
							id: segmentData.free_users_segment_id,
							count: segmentData.free_users_count,
							name: segmentData.free_users_segment_name,
						});
					}
					setIsDisabled(false);
				}}
			/>
		</div>
	);

	const predefinedOptions = [
		{
			isDisabled: !segmentData.all_users_count,
			name: t('pages:broadcast.clickToWhatsappAds.allUsersRetarget', {
				allUsersCount: segmentData.all_users_count,
			}),
			value: USERS.ALL_USERS,
		},
		{
			isDisabled: !segmentData.free_users_count,
			name: t('pages:broadcast.clickToWhatsappAds.usersWithFreeConversation', {
				freeUsersCount: segmentData.free_users_count,
			}),
			value: USERS.FREE_USERS,
		},
	];

	return (
		<ModalAlert
			closeBtnLabel={t('common:cancel')}
			confirmBtnLabel={t('common:confirm')}
			isLoading={loading}
			description={t('pages:broadcast.clickToWhatsappAds.retargetDescription', {
				allUsersCount: segmentData.all_users_count,
				freeUsersCount: segmentData.free_users_count,
			})}
			heading={t('pages:broadcast.clickToWhatsappAds.retargetViaCTWA')}
			children={retargetOptions()}
			isOpen
			width='25rem'
			height='35%'
			isConfirmBtnDisabled={isDisabled}
			onClose={() => {
				isModalOpen(false);
				setIsDisabled(true);
			}}
			closeable
			onConfirm={onConfirmRetarget}
			overrides={{
				ModalHeader: {
					marginTop: '1.75rem !important',
					marginBottom: '1rem',
				},
			}}
		/>
	);
};

export default ClickToWARetargetModal;
