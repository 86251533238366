import React from 'react';
import { useStyletron } from 'baseui';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import ENGTRadio from 'components/UI/ENGTRadio/ENGTRadio';

import { IObjProps } from 'shared/consts/types';

import FormLabel from '../FormLabel/FormLabel';

interface IFormRadioProps {
	error?: IObjProps;
	inputRef?: any;
	[key: string]: any;
	label?: string;
	tooltip?: string | React.ReactElement;
	usage?: 'primary' | 'modal';
	wrapperCss?: IObjProps;
}

function FormRadio({
	error,
	label,
	initialValue,
	inputRef,
	usage,
	name,
	radioButtons,
	onChange: onChangeProp,
	tooltip,
	wrapperCss = {},
	...restProps
}: IFormRadioProps) {
	const [css, theme]: any = useStyletron();
	const { control } = useFormContext();

	useWatch({
		control,
		name: [name],
	});

	const errorMsgCss = {
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		marginTop: '0.5rem',
		color: theme.inputErrorMsgColor,
	};

	return (
		<div className={css({ textAlign: 'left', marginBottom: '1.25rem', position: 'relative', ...wrapperCss })}>
			{label && <FormLabel id={name} label={label} tooltip={tooltip} />}
			<Controller
				control={control}
				name={name}
				defaultValue={initialValue}
				render={({ ref, value, onChange }) => (
					<>
						<ENGTRadio
							group={name}
							buttons={radioButtons}
							value={value}
							usage={usage}
							onChange={(d) => {
								onChange(d);
								onChangeProp && onChangeProp(d);
							}}
							inputRef={ref}
							{...restProps}
						/>
						{error?.message && <div className={css(errorMsgCss)}>{error.message}</div>}
					</>
				)}
			/>
		</div>
	);
}

export default FormRadio;
