import { useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';

import ResetAccountPasswordModal from './ResetAccountPasswordModal';

export enum PASSWORD_STATUS {
	ACTIVE = 'ACTIVE',
	EXPIRED = 'PASSWORD_EXPIRED',
}

function ResetAccountPassword() {
	const isPasswordExpired = useSelector(
		(state: RootState) => state.User.botAdmin.data.status === PASSWORD_STATUS.EXPIRED
	);

	return isPasswordExpired ? <ResetAccountPasswordModal /> : <></>;
}

export default ResetAccountPassword;
