import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const genericProxyRequestUrl = '/v2/genericProxyRequest/';

const genericProxyRequest = new BaseService(genericProxyRequestUrl);
const genericProxyRequestCreatorWithCancelToken = (cancelToken) => new BaseService(genericProxyRequestUrl, cancelToken);

export const postListAndOverviewAPI = {
	getPaginatedPosts(platform, page, size, cancelToken) {
		const params = {
			platform,
			page,
			size,
			targetApi: 'getSocialInboxPosts',
		};
		const genericProxyRequestWithCancelToken = genericProxyRequestCreatorWithCancelToken(cancelToken);

		return genericProxyRequestWithCancelToken.getRequest(serialize(params, '?'));
	},

	getPaginatedPostsBySearchTerm(platform, searchTerm, searchPosts, page, size, cancelToken) {
		const params = {
			platform,
			searchTerm,
			searchPosts,
			page,
			size,
			targetApi: 'getSocialInboxPostsBySearchTerm',
		};
		const genericProxyRequestWithCancelToken = genericProxyRequestCreatorWithCancelToken(cancelToken);

		return genericProxyRequestWithCancelToken.getRequest(serialize(params, '?'));
	},
	getUserCommentedPostAndComments: (userId, platform, pageNo) => {
		const params = {
			targetApi: 'getUserCommentedPostsAndComments',
			userId,
			platform,
			page: pageNo,
			size: 10,
		};

		return genericProxyRequest.getRequest(serialize(params, '?'));
	},
	getInstanceBotRefByPageId: (pageId) => {
		const params = {
			targetApi: 'getInstanceBotRefByPageId',
			pageId,
		};

		return genericProxyRequest.getRequest(serialize(params, '?'));
	},
};
