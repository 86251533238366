function DownloadIcon({ fillColor, size = 14 }: { fillColor?: string; size?: number }) {
	return (
		<svg width={size} height={size} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.25 8.78125V11.1146C12.25 11.424 12.1271 11.7207 11.9083 11.9395C11.6895 12.1583 11.3928 12.2812 11.0833 12.2812H2.91667C2.60725 12.2812 2.3105 12.1583 2.09171 11.9395C1.87292 11.7207 1.75 11.424 1.75 11.1146V8.78125'
				stroke={fillColor}
				strokeWidth='1.5'
				strokeLinecap='square'
				strokeLinejoin='bevel'
			/>
			<path
				d='M4.08331 5.86426L6.99998 8.78092L9.91665 5.86426'
				stroke={fillColor}
				strokeWidth='1.5'
				strokeLinecap='square'
			/>
			<path
				d='M7 7.70301V1.78125'
				stroke={fillColor}
				strokeWidth='1.5'
				strokeLinecap='square'
				strokeLinejoin='bevel'
			/>
		</svg>
	);
}

export default DownloadIcon;
