import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ACCOUNT_TYPES } from 'shared/consts/consts';

import { RootState } from 'store/rootReducer';
import { isEngatiBrandSelector } from 'store/Users/selectors';

import FeedBackForm from './FeedBackForm';

const Feedback = () => {
	const [css, theme]: [any, any] = useStyletron();
	const { t }: any = useTranslation(['common']);

	const [isBannerModelOpen, setBannerModelOpen] = useState<boolean>(false);

	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const isWhiteLabelPartner = useSelector((state: RootState) => !state.User.botAdmin.data.is_engati_brand);

	const { RESELLER } = ACCOUNT_TYPES;
	const isReseller = localStorage.getItem('loggedInUserAccountType') === RESELLER;
	const isWhiteLabelReseller = isReseller && isWhiteLabelPartner;

	return isEngatiBrand || isWhiteLabelReseller ? (
		<>
			<div
				className={css({
					cursor: 'pointer',
					background: theme.colors.accent100,
					borderRadius: '0.75rem',
					padding: '0.75rem',
					backdropFilter: 'blur(11px)',
				})}
				onClick={() => setBannerModelOpen(true)}
			>
				<div
					className={css({
						color: theme.colors.defaultPrimaryAColor,
						fontWeight: 700,
						fontSize: '0.75rem',
						lineHeight: '1rem',
						marginBottom: '0.25rem',
					})}
				>
					{t('pages:banner.submitFeedback')}
				</div>
				<div
					className={css({
						color: theme.colors.defaultPrimaryAColor,
						fontWeight: 400,
						fontSize: '0.75rem',
						lineHeight: '1rem',
					})}
				>
					<span>{t('pages:banner.youHaveAVoice')}</span>
				</div>
			</div>
			<FeedBackForm isOpen={isBannerModelOpen} toggleModal={setBannerModelOpen} />
		</>
	) : (
		<></>
	);
};

export default Feedback;
