import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { ACCOUNT_TYPES, CREDITS_EXHAUSTED, ENGATI_ROLES } from 'shared/consts/consts';
import { LS_KEYS, readFromLs } from 'shared/consts/localStorageConsts';

import { toggleOnlineStatus } from 'store/Message';
import { RootState } from 'store/rootReducer';

function MarkOnlinePromptModal() {
	const { t } = useTranslation(['common']);
	const dispatch: any = useDispatch<any>();

	const [isMarkOnlinePromptOpen, setMarkOnlinePrompt] = useState<boolean>(false);

	const isOnline = useSelector((state: RootState) => state.Bot.agentOnlineStatus);

	const isOwnerOrSupervisor = useSelector(
		(state: RootState) =>
			state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_OWNER ||
			state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_SUPERVISOR
	);

	const setAgentOnlineStatus = () => {
		dispatch(toggleOnlineStatus(true));
		setMarkOnlinePrompt(false);
	};

	const showOnlinePrompt = () => {
		if (
			localStorage.getItem('showMarkOnlinePrompt') === 'true' &&
			localStorage.getItem('loggedInUserAccountType') !== ACCOUNT_TYPES.MASTER_ADMIN &&
			readFromLs(LS_KEYS.ENFORCEMENT_STATUS) !== CREDITS_EXHAUSTED
		) {
			setMarkOnlinePrompt(true);
			localStorage.setItem('showMarkOnlinePrompt', 'false');
		}
	};

	useEffect(() => {
		const timeOutRef = setTimeout(showOnlinePrompt, 5000);

		if (isOwnerOrSupervisor) {
			return () => clearTimeout(timeOutRef);
		}
	}, []);

	return (
		<ActionModal
			confirmBtnLabel={t('components:markOnlinePromptModal.markAsAvailable')}
			closeBtnLabel={t('common:cancel')}
			description={t('components:markOnlinePromptModal.onlinePromptDescription')}
			heading={t('components:markOnlinePromptModal.onlinePromptTitle')}
			isOpen={isMarkOnlinePromptOpen && !isOnline}
			onClose={() => setMarkOnlinePrompt(false)}
			onConfirm={() => setAgentOnlineStatus()}
			width='25rem'
		/>
	);
}

export default MarkOnlinePromptModal;
