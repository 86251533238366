const FacebookFullIcon = ({ size = 10 }: { size?: number }) => (
	<svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M11.3174 1.88281C6.10775 1.88281 1.88477 6.1058 1.88477 11.3154C1.88477 16.5251 6.10775 20.7481 11.3174 20.7481C16.527 20.7481 20.75 16.5251 20.75 11.3154C20.75 6.1058 16.527 1.88281 11.3174 1.88281Z'
			fill='url(#paint0_linear_121_5583)'
		/>
		<path
			d='M12.5937 13.8165H15.0349L15.4183 11.3367H12.5937V9.98124C12.5937 8.95119 12.9305 8.03764 13.894 8.03764H15.4424V5.8738C15.1702 5.83701 14.5949 5.75684 13.5077 5.75684C11.2373 5.75684 9.90637 6.95572 9.90637 9.68741V11.3372H7.57227V13.817H9.9059V20.633C10.3681 20.7019 10.8364 20.749 11.317 20.749C11.7514 20.749 12.1754 20.7094 12.5937 20.6528V13.8165Z'
			fill='white'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_121_5583'
				x1='4.71125'
				y1='4.7093'
				x2='19.1535'
				y2='19.1516'
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#2AA4F4' />
				<stop offset='1' stopColor='#007AD9' />
			</linearGradient>
		</defs>
	</svg>
);

export default FacebookFullIcon;
