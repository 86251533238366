import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Panel } from 'baseui/accordion';
import { PLACEMENT } from 'baseui/tooltip';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ENGTAccordion from 'components/UI/ENGTAccordion/ENGTAccordion';
import DefaultDatePicker from 'components/UI/ENGTDatePicker/DefaultDatePicker';
import FilterDropdown from 'components/UI/FilterDropdown/FilterDropdown';

import { IObjProps } from 'shared/consts/types';
import { filterAccordionCss, isChannelsArray } from 'shared/helpers/broadcastHelpers';

import { broadcastFilterActions, IFilterAndSort, setBroadcastDataFilterAction } from 'store/Broadcast';
import { publishedFilters, publishedSort } from 'store/Broadcast/selectors';

interface IDateFilter {
	applyFilters: Function;
	filterIndex: number;
}

const DateFilter = ({ applyFilters, filterIndex }: IDateFilter) => {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['common', 'components', 'errors']);
	const dispatch = useDispatch<any>();

	const sort = useSelector(publishedSort);
	const filters = useSelector(publishedFilters);

	const preFilterOptions = [
		t('pages:broadcast.broadcastHistory.selectFromDate'),
		t('pages:broadcast.broadcastHistory.selectToDate'),
	];

	const storedFilters: IObjProps = dispatch(broadcastFilterActions.getFiltersFromLs());

	const STORED_DATE = filterIndex === 0 ? storedFilters.filters.startDate : storedFilters.filters.endDate;

	const [currentTitle, setCurrentTitle] = useState<string>(preFilterOptions[filterIndex]);
	const [expand, setExpand] = useState<boolean>(false);
	const [dateSelected, setDateSelected] = useState<Array<any>>(STORED_DATE.length ? [new Date(STORED_DATE)] : [null]);
	const [fromDate, toDate] = dateSelected;
	const [minDate, setMinDate] = useState<Date>();
	const [maxDate, setMaxDate] = useState<Date>();

	useEffect(() => {
		if (STORED_DATE.length) {
			setCurrentTitle(getFormattedDate(new Date(STORED_DATE).toString()));
		}
	}, []);

	const dateProcessing = () => {
		const { filters }: IObjProps = dispatch(broadcastFilterActions.getFiltersFromLs());
		const modifiedFromDate = filters.startDate;
		const modifiedToDate = filters.endDate;
		minDateHandler(modifiedFromDate);
		maxDateHandler(modifiedFromDate, modifiedToDate);
	};

	useEffect(() => {
		dateProcessing();
	}, []);

	const onApply = () => {
		if (fromDate) {
			const formattedDate = getFormattedDate(fromDate);
			const date = new Date(fromDate).toISOString();
			dispatch(
				setBroadcastDataFilterAction({
					filters: {
						startDate: filterIndex === 0 ? date : filters.startDate,
						endDate: filterIndex === 1 ? date : filters.endDate,
						channels: isChannelsArray(filters.channels),
					},
					sort,
				})
			);
			setCurrentTitle(formattedDate);
			setExpand(false);
			applyFilters(date, filterIndex);
		}
	};

	const onReset = () => {
		const { filters, sort }: IObjProps = dispatch(broadcastFilterActions.getFiltersFromLs());
		const filterData: IFilterAndSort = {
			filters: {
				...filters,
				startDate: filterIndex === 0 ? '' : filters.startDate,
				endDate: filterIndex === 1 ? '' : filters.endDate,
			},
			sort,
		};
		dispatch(setBroadcastDataFilterAction(filterData));
		setCurrentTitle(preFilterOptions[filterIndex]);
		setExpand(false);
		applyFilters('', filterIndex);
		setMinDate(undefined);
		setMaxDate(new Date());
		setDateSelected([null]);
	};

	function getFormattedDate(date: Date | string) {
		const splittedDate = date.toString().split(' ');

		return `${splittedDate[1]} ${splittedDate[2]}, ${splittedDate[3]}`;
	}

	const minDateHandler = (fromDate: string) =>
		setMinDate(filterIndex === 0 ? undefined : fromDate.length ? new Date(fromDate) : undefined);

	const maxDateHandler = (fromDate: string, toDate: string) =>
		setMaxDate(
			filterIndex === 0
				? toDate.length
					? new Date(toDate)
					: new Date()
				: fromDate.length
					? new Date()
					: new Date()
		);

	const renderFilters = () => (
		<div className={css({ width: '48%', float: 'left' })}>
			<FilterDropdown
				rootButton={
					<ENGTAccordion
						overrides={filterAccordionCss(theme, preFilterOptions.includes(currentTitle))}
						expandedPanel={!expand ? undefined : '0'}
					>
						<Panel title={currentTitle} onClick={() => setExpand(!expand)}>
							<></>
						</Panel>
					</ENGTAccordion>
				}
				openOnClick
				onClose={() => setExpand(false)}
				onOpen={() => dateProcessing()}
				ignoreBoundary
				onApply={onApply}
				bodyCss={{ zIndex: '', marginTop: '0.1rem' }}
				popoverMargin={-7}
				placement={PLACEMENT.bottomLeft}
				isBlockFilter
				onReset={onReset}
			>
				<DefaultDatePicker
					value={fromDate}
					onChange={(dates: any) => {
						setDateSelected([dates]);
					}}
					inputWidth='90%'
					maxDate={maxDate}
					minDate={minDate}
					isNewFilter
				/>
			</FilterDropdown>
		</div>
	);

	return (
		<div className={css({ display: 'flex' })}>
			<div className={css({ width: '83.4%' })}>{renderFilters()}</div>
		</div>
	);
};

export default DateFilter;
