import { useStyletron } from 'baseui';
import { PLACEMENT } from 'baseui/popover';
import merge from 'lodash/merge';

import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import { Pie } from '@visx/shape';
import { useTooltip } from '@visx/tooltip';

import { IObjProps } from 'shared/consts/types';
import { SHOPIFY_DASHBOARD } from 'shared/enum';
import { borderRadius, getEllipsisedText } from 'shared/helpers';
import NoPieChartData from 'shared/icons/NoPieChartData.svg';

import ENGTTooltip from '../ENGTTooltip/ENGTTooltip';

import { IPieChartProps } from './ENGTGraphProps';
import ENGTGraphToolTip from './ENGTGraphToolTip';

const defaults = {
	fontSize: 22,
};

const ENGTPieChart = ({ width, height, usage = 'OTHERS', accessor, colors, data, ...additionals }: IPieChartProps) => {
	const [css, theme] = useStyletron();

	let {
		header,
		overrides,
		background,
		displayLabel = false,
		textColor = theme.colors.white,
		labelFormat = (value: any) => value,
		margin = { top: 40, right: 32, bottom: 24, left: 32 },
		legendTooltipData = [],
		showLegendTooltip = false,
		ellipsisedTextAfter = 12,
		legendLabelMarginBottom = '0rem',
		tooltipDataOverrides = {},
		tooltipDataMaxHeight,
		tooltipDataLeftPosition,
	} = additionals;

	overrides = merge({}, defaults, overrides);

	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;

	const radius = Math.min(xMax, yMax) / 2;

	const centerX = margin.left + radius;
	const centerY = yMax / 2;

	const labelScale = scaleOrdinal<string, string>({
		range: colors,
		domain: [...Array.from(new Set(data.map((item: IObjProps) => item.key)))],
	});

	const colorScale = scaleOrdinal({
		domain: data.map(accessor),
		range: colors,
	});

	const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<IObjProps>();

	let tooltipTimeout: any;

	return (
		<div style={{ position: 'relative', height: '100%' }}>
			{/* Graph Header */}
			<div
				style={{
					position: 'absolute',
					fontSize: '0.875rem',
					display: 'flex',
					width: '100%',
					marginTop: '1rem',
					justifyContent: 'space-between',
				}}
			>
				{header}
			</div>
			<div
				style={{
					position: 'absolute',
					bottom: margin.bottom,
					left: tooltipDataLeftPosition || xMax * 0.75,
					fontSize: '0.875rem',
					display: 'flex',
					width: '35%',
					marginTop: '1rem',
					justifyContent: 'space-between',
					maxHeight: tooltipDataMaxHeight || 'none',
				}}
			>
				<LegendOrdinal scale={labelScale} labelFormat={labelFormat} itemMargin={2} labelMargin={0}>
					{(labels) => (
						<div
							style={{ marginBottom: legendLabelMarginBottom }}
							className={css({ ...tooltipDataOverrides })}
						>
							{labels.map((label, i) => (
								<LegendItem key={`legend-quantile-${i}`}>
									<svg width='0.75rem' height='0.75rem' style={{ ...borderRadius('0.25rem') }}>
										<rect fill={label.value} width='0.75rem' height='0.75rem' />
									</svg>
									<LegendLabel
										className={css({
											color: textColor,
											textAlign: 'left',
											fontSize: '0.75rem',
											fontWeight: 500,
											overflow: 'hidden',
											paddingLeft: '0.25rem',
										})}
									>
										{showLegendTooltip ? (
											<ENGTTooltip
												placement={PLACEMENT.top}
												content={legendTooltipData[i].value}
												ignoreBoundary={false}
											>
												<div
													className={css({
														textDecoration: 'none',
														color: theme.colors.primaryA,
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap',
													})}
												>
													{label.text}
												</div>
											</ENGTTooltip>
										) : (
											<ENGTTooltip content={label.text}>
												<div
													className={css({
														textDecoration: 'none',
														color: theme.colors.primaryA,
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap',
													})}
												>
													{getEllipsisedText(label.text, ellipsisedTextAfter)}
												</div>
											</ENGTTooltip>
										)}
									</LegendLabel>
								</LegendItem>
							))}
						</div>
					)}
				</LegendOrdinal>
			</div>

			{/* Graph Container */}
			{data?.length && usage !== SHOPIFY_DASHBOARD.SHOPIFY_DASHBOARD_EMPTY ? (
				<svg width={width} height={height}>
					<rect x={0} y={0} rx={8} width={width} height={height} fill={background} />
					<Group top={margin.top + centerY} left={margin.left + centerX}>
						<Pie
							data={data}
							outerRadius={radius}
							pieValue={(data: IObjProps) => data.value}
							pieSortValues={(a: number, b: number) => a - b}
						>
							{(pie) =>
								pie.arcs.map((arc, index) => {
									const { key }: IObjProps = arc.data;
									const arcPath = pie.path(arc) || '';
									const [x, y] = pie.path.centroid(arc);
									const labelSpace = arc.endAngle - arc.startAngle >= 0.1;

									return (
										<g key={`arc-${key}-${index}`}>
											<path
												d={arcPath}
												fill={colorScale(key)}
												onMouseMove={(event) => {
													if (tooltipTimeout) {
														clearTimeout(tooltipTimeout);
													}
													const point = localPoint(event);
													showTooltip({
														tooltipData:
															usage === 'SHOPIFY_DASHBOARD'
																? { key: arc.data.key, value: `${arc.data.value}%` }
																: arc.data,
														tooltipLeft: point?.x,
														tooltipTop: point?.y,
													});
												}}
												onMouseLeave={() => {
													tooltipTimeout = window.setTimeout(() => {
														hideTooltip();
													}, 200);
												}}
											/>
											{displayLabel && labelSpace && (
												<text
													x={x}
													y={y}
													dy='.33em'
													fontSize={22}
													textAnchor='middle'
													pointerEvents='none'
													fill={theme.colors.primaryB}
												>
													{arc.data.key}
												</text>
											)}
										</g>
									);
								})
							}
						</Pie>
					</Group>
				</svg>
			) : (
				<img
					src={NoPieChartData}
					alt='no-piechart-data'
					className={css({
						position: 'absolute',
						top: '50%',
						left: usage === SHOPIFY_DASHBOARD.SHOPIFY_DASHBOARD_EMPTY ? '30%' : '50%',
						transform: `translate(-50%, -50%)`,
					})}
				/>
			)}
			{tooltipOpen && tooltipTop && tooltipLeft && (
				<ENGTGraphToolTip
					top={tooltipTop}
					left={tooltipLeft}
					data={tooltipData}
					label={labelFormat}
					overrides={overrides?.graphTooltip}
				/>
			)}
		</div>
	);
};

export default ENGTPieChart;
