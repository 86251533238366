import { USERS } from 'shared/consts/QAConsts';

function Users({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			width={size || 40}
			height={size || 40}
			viewBox='0 0 40 40'
			fill='none'
			className={className}
			data-qa={USERS}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='inherit'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M11.6455 16.3522C11.6455 14.2281 13.3736 12.5 15.4977 12.5C17.6218 12.5 19.3499 14.2281 19.3499 16.3522C19.3499 18.4763 17.6218 20.2044 15.4977 20.2044C13.3736 20.2044 11.6455 18.4763 11.6455 16.3522ZM16.3538 21.0602C17.8379 21.0602 19.236 21.6499 20.2906 22.7208C21.3401 23.7864 21.9181 25.1929 21.9181 26.6815C21.9181 26.9179 21.7264 27.1095 21.49 27.1095H9.50546C9.26908 27.1095 9.07744 26.9179 9.07744 26.6815C9.07744 25.1929 9.65541 23.7864 10.7049 22.7208C11.7595 21.6499 13.1577 21.0602 14.6417 21.0602H16.3538Z'
					fill={fillColor || '#403F42'}
				/>
				<path
					d='M20.2906 22.7208L19.64 23.3615L19.64 23.3615L20.2906 22.7208ZM10.7049 22.7208L11.3555 23.3615L11.3555 23.3615L10.7049 22.7208ZM15.4977 11.5869C12.8693 11.5869 10.7324 13.7238 10.7324 16.3522H12.5587C12.5587 14.7324 13.8779 13.4131 15.4977 13.4131V11.5869ZM20.263 16.3522C20.263 13.7238 18.1261 11.5869 15.4977 11.5869V13.4131C17.1175 13.4131 18.4368 14.7324 18.4368 16.3522H20.263ZM15.4977 21.1175C18.1261 21.1175 20.263 18.9806 20.263 16.3522H18.4368C18.4368 17.972 17.1175 19.2913 15.4977 19.2913V21.1175ZM10.7324 16.3522C10.7324 18.9806 12.8693 21.1175 15.4977 21.1175V19.2913C13.8779 19.2913 12.5587 17.972 12.5587 16.3522H10.7324ZM20.9412 22.08C19.7174 20.8374 18.0859 20.1471 16.3538 20.1471V21.9733C17.5898 21.9733 18.7546 22.4624 19.64 23.3615L20.9412 22.08ZM22.8312 26.6815C22.8312 24.9519 22.158 23.3155 20.9412 22.08L19.64 23.3615C20.5222 24.2572 21.005 25.434 21.005 26.6815H22.8312ZM21.49 28.0227C22.2307 28.0227 22.8312 27.4222 22.8312 26.6815H21.005C21.005 26.4136 21.2221 26.1964 21.49 26.1964V28.0227ZM9.50546 28.0227H21.49V26.1964H9.50546V28.0227ZM8.16433 26.6815C8.16433 27.4222 8.76479 28.0227 9.50546 28.0227V26.1964C9.77338 26.1964 9.99055 26.4136 9.99055 26.6815H8.16433ZM10.0543 22.08C8.83751 23.3155 8.16433 24.9519 8.16433 26.6815H9.99055C9.99055 25.434 10.4733 24.2572 11.3555 23.3615L10.0543 22.08ZM14.6417 20.1471C12.9096 20.1471 11.2781 20.8374 10.0543 22.08L11.3555 23.3615C12.2409 22.4624 13.4058 21.9733 14.6417 21.9733V20.1471ZM16.3538 20.1471H14.6417V21.9733H16.3538V20.1471Z'
					fill={fillColor || '#403F42'}
				/>
				<g opacity='0.7'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M22.1567 17.93C22.1567 16.1175 23.6314 14.6428 25.4439 14.6428C27.2565 14.6428 28.7311 16.1175 28.7311 17.93C28.7311 19.7426 27.2565 21.2172 25.4439 21.2172C23.6314 21.2172 22.1567 19.7426 22.1567 17.93ZM26.1744 21.9475C27.4408 21.9475 28.6338 22.4507 29.5338 23.3645C30.4293 24.2738 30.9225 25.4741 30.9225 26.7444C30.9225 26.9461 30.759 27.1096 30.5573 27.1096H20.3305C20.1287 27.1096 19.9652 26.9461 19.9652 26.7444C19.9652 25.4741 20.4584 24.2738 21.354 23.3645C22.2539 22.4507 23.447 21.9475 24.7134 21.9475H26.1744Z'
						fill={fillColor || '#403F42'}
					/>
					<path
						d='M29.5338 23.3645L28.8832 24.0053L28.8832 24.0053L29.5338 23.3645ZM21.354 23.3645L22.0045 24.0053L22.0045 24.0053L21.354 23.3645ZM25.4439 13.7297C23.1271 13.7297 21.2436 15.6132 21.2436 17.93H23.0698C23.0698 16.6218 24.1357 15.5559 25.4439 15.5559V13.7297ZM29.6442 17.93C29.6442 15.6132 27.7608 13.7297 25.4439 13.7297V15.5559C26.7522 15.5559 27.818 16.6218 27.818 17.93H29.6442ZM25.4439 22.1303C27.7608 22.1303 29.6442 20.2469 29.6442 17.93H27.818C27.818 19.2383 26.7522 20.3041 25.4439 20.3041V22.1303ZM21.2436 17.93C21.2436 20.2469 23.1271 22.1303 25.4439 22.1303V20.3041C24.1357 20.3041 23.0698 19.2383 23.0698 17.93H21.2436ZM30.1844 22.7238C29.1152 21.6383 27.6888 21.0344 26.1744 21.0344V22.8606C27.1927 22.8606 28.1524 23.2632 28.8832 24.0053L30.1844 22.7238ZM31.8357 26.7444C31.8357 25.2331 31.2472 23.803 30.1844 22.7238L28.8832 24.0053C29.6114 24.7447 30.0094 25.7152 30.0094 26.7444H31.8357ZM30.5573 28.0227C31.2633 28.0227 31.8357 27.4504 31.8357 26.7444H30.0094C30.0094 26.4418 30.2547 26.1965 30.5573 26.1965V28.0227ZM20.3305 28.0227H30.5573V26.1965H20.3305V28.0227ZM19.0521 26.7444C19.0521 27.4504 19.6244 28.0227 20.3305 28.0227V26.1965C20.633 26.1965 20.8783 26.4418 20.8783 26.7444H19.0521ZM20.7034 22.7238C19.6405 23.803 19.0521 25.2331 19.0521 26.7444H20.8783C20.8783 25.7152 21.2763 24.7447 22.0045 24.0053L20.7034 22.7238ZM24.7134 21.0344C23.1989 21.0344 21.7725 21.6383 20.7034 22.7238L22.0045 24.0053C22.7353 23.2632 23.6951 22.8606 24.7134 22.8606V21.0344ZM26.1744 21.0344H24.7134V22.8606H26.1744V21.0344Z'
						fill={fillColor || '#403F42'}
					/>
				</g>
			</g>
		</svg>
	);
}

export default Users;
