import { BACK_TO_HOME } from 'shared/consts/QAConsts';

function BackToHome({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			width={size || 24}
			height={size || 24}
			viewBox='0 1.5 24 24'
			fill='none'
			className={className}
			data-qa={BACK_TO_HOME}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.7743 7.5L14.8349 8.56066L11.1226 12.273L14.835 15.9853L13.7743 17.046L8.99992 12.2716L10.0606 11.2109L10.062 11.2123L13.7743 7.5Z'
				fill={fillColor || '#403F42'}
			/>
		</svg>
	);
}

export default BackToHome;
