import { useState } from 'react';

import { IObjProps, IPaginationState } from 'shared/consts/types';

function usePaginationHook({ onChange, size }: { onChange?: Function; size?: number }) {
	const [pagination, setPagination] = useState<IPaginationState>({
		page: 1,
		size: size || 10,
		totalPages: 0,
		totalElements: 0,
	});

	const changePageNumber = (pageNo: number) => {
		setPagination({ ...pagination, page: pageNo });
		onChange && onChange(pageNo, pagination.size);
	};

	const changePaginationSize = (size: number) => {
		setPagination({ page: 1, size, totalPages: 1, totalElements: 0 });
		onChange && onChange(1, size);
	};

	const resetPagination = (prop?: IObjProps) => {
		const { page = 1, size = 10, totalPages = 1, totalElements = 0 } = prop || {};

		setPagination({ page, size, totalPages, totalElements });
		onChange && onChange(page, size);
	};

	return { pagination, setPagination, changePageNumber, changePaginationSize, resetPagination };
}

export default usePaginationHook;
