import { SyntheticEvent } from 'react';
import { useStyletron } from 'baseui';
import { SIZE } from 'baseui/input';
import { Textarea } from 'baseui/textarea';

import { IObjProps } from 'shared/consts/types';
import { decodeAndSanitizeInput } from 'shared/helpers';

export interface IENGTTextareaProps {
	disabled?: boolean;
	error?: IObjProps;
	inputRef: any;
	height?: string;
	placeholder: string;
	required?: boolean;
	resize?: boolean;
	size?: keyof SIZE;
	usage?: 'primary' | 'modal' | 'script';
	[key: string]: any;
	dataQa?: string;
	maxLength?: number;
	sanitizeInput?: boolean;

	onChange?: (args: any) => any;
}

function ENGTTextarea(props: IENGTTextareaProps) {
	const {
		disabled,
		error,
		inputRef,
		height,
		placeholder,
		required,
		resize,
		size,
		usage,
		dataQa,
		onChange,
		maxLength,
		sanitizeInput = true,
		...rest
	} = props;
	const [css, theme]: any = useStyletron();

	const errorMsgCss = {
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		marginTop: '0.5rem',
		color: theme.inputErrorMsgColor,
	};

	const overrideProps = {
		Root: {
			style: ({ $theme, $isFocused }: any) => {
				const { colors, inputBorderColor, inputFillModal, inputFillPrimary, inputSecondaryFillColor } = $theme;

				const borderColor =
					error?.message || usage === 'script'
						? colors.inputBorderError
						: $isFocused
							? colors.focusedInputBorderColor
							: colors.inputBorderColor;

				return {
					backgroundColor: disabled
						? inputSecondaryFillColor
						: usage === 'modal'
							? inputFillModal
							: inputFillPrimary,
					borderTopRightRadius: $theme.radius350,
					borderTopLeftRadius: $theme.radius350,
					borderBottomLeftRadius: $theme.radius350,
					borderBottomRightRadius: $theme.radius350,
					borderTopWidth: '1px',
					borderRightWidth: '1px',
					borderBottomWidth: '1px',
					borderLeftWidth: '1px',
					borderTopColor: borderColor,
					borderRightColor: borderColor,
					borderBottomColor: borderColor,
					borderLeftColor: borderColor,
				};
			},
		},
		Input: {
			style: ({ $theme }: any) => {
				const { inputFillModal, inputFillPrimary, inputSecondaryFillColor, modalTextColor } = $theme;
				const bgColor = disabled
					? inputSecondaryFillColor
					: usage === 'modal'
						? inputFillModal
						: inputFillPrimary;
				const color =
					bgColor === inputSecondaryFillColor
						? modalTextColor
						: bgColor === inputFillModal
							? '#000000'
							: '#FFFFFF';

				return {
					color,
					caretColor: color,
					backgroundColor: bgColor,
					height,
					maxHeight: '25rem',
					resize: resize ? ('none' as const) : ('vertical' as const),
					fontSize: '0.875rem',
					lineHeight: '1.25rem',
				};
			},
			props: {
				'data-qa': dataQa,
			},
		},
	};

	const handleError = required && {
		error: inputRef.current ? !inputRef.current.value.length : true,
	};

	const sanitize = (event: SyntheticEvent) => {
		const input = event.target as HTMLInputElement;
		input.value = decodeAndSanitizeInput(input.value);
		onChange?.(event);
	};

	return (
		<>
			<Textarea
				disabled={disabled}
				inputRef={inputRef}
				placeholder={placeholder}
				size={size}
				onChange={(event: SyntheticEvent) => (sanitizeInput ? sanitize(event) : onChange?.(event))}
				overrides={overrideProps}
				maxLength={maxLength}
				{...handleError}
				{...rest}
			/>
			{error?.message && <div className={css(errorMsgCss)}>{error.message}</div>}
		</>
	);
}

ENGTTextarea.defaultProps = {
	required: false,
	size: SIZE.default,
	usage: 'primary',
	resize: false,
};

export default ENGTTextarea;
