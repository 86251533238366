import React from 'react';
import { useStyletron } from 'baseui';
import { Modal as BaseModal, ModalBody, ModalHeader } from 'baseui/modal';

const modalOverrideProps = ({ theme, width }: { theme: any; width: string }) => ({
	Root: {
		style: {
			zIndex: theme.zIndex300,
		},
	},
	Close: {
		style: {
			borderRadius: '50%',
			height: '3rem',
			width: '3rem',
			right: '0.75rem',
			top: '0.75rem',
			background: `${theme.modalCloseBgColor} !important`,
			stroke: `${theme.modalCloseStrokeColor} !important`,
			color: `${theme.modalCloseStrokeColor} !important`,
		},
	},
	Dialog: {
		style: {
			backgroundColor: theme.modalPrimaryBackground,
			borderTopRightRadius: '1rem',
			borderTopLeftRadius: '1rem',
			borderBottomLeftRadius: '1rem',
			borderBottomRightRadius: '1rem',
			paddingRight: '2rem',
			paddingLeft: '2rem',
			width,
			'box-sizing': 'border-box',
		},
	},
});

const modalHeaderCss = ($theme: any) => ({
	color: $theme.modalTextColor,
	fontWeight: 'bold !important',
	fontSize: '1.25rem !important',
	lineHeight: '1.625rem !important',
	marginLeft: '0rem !important',
	marginRight: '0rem !important',
	marginBottom: '0.5rem !important',
});

const modalBodyCss: any = {
	marginRight: '0 !important',
	marginLeft: '0 !important',
	marginTop: '0 !important',
	marginBottom: '2rem !important',
	paddingRight: '1.75rem !important',
};

export interface IInfoModalBase {
	onCloseFn: any;
	heading: string;
	isModalOpen: boolean;
	description: string | JSX.Element;
	closeable?: boolean;
	width?: string;
}

function InfoModalBase({
	onCloseFn,
	heading,
	isModalOpen,
	description,
	closeable = true,
	width = '26.375rem',
}: IInfoModalBase) {
	const [css, theme]: [any, any] = useStyletron();

	return (
		<>
			<BaseModal
				onClose={onCloseFn}
				isOpen={isModalOpen}
				overrides={modalOverrideProps({ theme, width })}
				closeable={closeable}
			>
				<ModalHeader className={css(modalHeaderCss(theme))}>{heading}</ModalHeader>
				<ModalBody className={css(modalBodyCss)}>
					<div
						className={css({
							fontSize: '1rem',
							lineHeight: '1.25rem',
						})}
					>
						{description}
					</div>
				</ModalBody>
			</BaseModal>
		</>
	);
}

export default InfoModalBase;
