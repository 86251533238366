import { createSlice } from '@reduxjs/toolkit';

import { commonReducer } from './reducers';
import { commonState } from './state';

export const commonSlice = createSlice({
	name: 'Common',
	initialState: commonState,
	reducers: commonReducer,
});

export const { actions, reducer } = commonSlice;
