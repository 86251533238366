import React from 'react';

function WhatsnewIcon({ fill }) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
			<g stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' opacity='0.8'>
				<path d='M12 6.167V8.5'></path>
				<path d='M12 15.5v2.333' opacity='0.6'></path>
				<path d='M7.876 7.876l1.65 1.65' opacity='0.4'></path>
				<path d='M14.473 14.473l1.651 1.651' opacity='0.8'></path>
				<path d='M6.167 12H8.5' opacity='0.4'></path>
				<path d='M15.5 12h2.333' opacity='0.8'></path>
				<path d='M7.876 16.124l1.65-1.65' opacity='0.6'></path>
				<path d='M14.473 9.527l1.651-1.651'></path>
			</g>
		</svg>
	);
}

export default WhatsnewIcon;
