import React from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { ENGATI_ROLES } from 'shared/consts/consts';
import { hexToRgb } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import BackToHome from 'shared/icons/navigation/BackToHome';

import { isShopifyEnabledAcc, isWhatsappAcceleratorEnabled } from 'store/App/User';
import { RootState } from 'store/rootReducer';

import useIconSizeHook from '../hooks/IconSizeHook';

import NavigationDivider from './NavigationDivider';

function BackToBots() {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['common']);

	const { iconSize = 30 } = useIconSizeHook();
	const { isSmallDesktopScreen } = useResponsiveSize();

	const isAgent = useSelector(
		(state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT
	);
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const whatsappAcceleratorEnabled = useSelector(isWhatsappAcceleratorEnabled);

	const centerAlignCss = {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: isSmallDesktopScreen ? '0.5rem' : 0,
	};

	const backToHomeBtnCss = {
		marginBottom: isSmallDesktopScreen ? '1rem' : '0.5rem',
		marginTop: '0.5rem',
		marginRight: '0.5rem',
		textDecoration: 'none',
		color: theme.colors.primaryA,
		...centerAlignCss,
	};

	const iconWrapperCss = {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderTopRightRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		borderTopLeftRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		backgroundColor: hexToRgb(theme.colors.backgroundSecondary, 0.7),
		overflow: 'hidden',
		height: `${iconSize}px`,
		width: `${iconSize}px`,
	};

	if (isShopifyEnabled || whatsappAcceleratorEnabled || isAgent) {
		return <></>;
	}

	return (
		<>
			<NavLink to='/' className={css(backToHomeBtnCss)}>
				<span className={css(isSmallDesktopScreen ? iconWrapperCss : { height: '1.5rem', width: '1.5rem' })}>
					<BackToHome fillColor={theme.colors.primaryA} />
				</span>
				<span
					className={css({
						color: theme.colors.primaryA,
					})}
				>
					{t('common:bots')}
				</span>
			</NavLink>
			<NavigationDivider />
		</>
	);
}

export default BackToBots;
