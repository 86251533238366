import { memo } from 'react';
import { useStyletron } from 'baseui';
import { Label1 } from 'baseui/typography';
import { useSelector } from 'react-redux';

import { fontStyleSelector } from 'store/App/User';

const MessagePageHeader = ({ title }: { title: string }) => {
	const [css]: any = useStyletron();
	const fontStyle: string = useSelector(fontStyleSelector);

	const pageNameCss = {
		fontFamily: fontStyle,
		fontStyle: 'normal',
		fontSize: '1.25rem',
		lineHeight: '1.5rem',
		fontWeight: '700',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap' as const,
		marginTop: '0',
		marginBottom: '0',
	};

	return <Label1 className={css(pageNameCss)}>{title}</Label1>;
};

export default memo(MessagePageHeader);
