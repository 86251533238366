import React from 'react';
import { KIND } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { withTranslation } from 'react-i18next';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { FB_ME_API, getScopeByApp } from 'shared/consts/consts';
import { CONFIGURE_DEPLOYMENT_FACEBOOK_LOGIN } from 'shared/consts/QAConsts';
import { IObjProps } from 'shared/consts/types';
import { RESPONSE_STATUS } from 'shared/enum/enum';

import { API } from 'store/App/Configure/API/Deployment/Facebook/api';
import { INSTA_API } from 'store/App/Configure/API/Deployment/Instagram/api';
import { whatsAppAPI as WA_API } from 'store/App/Configure/API/Deployment/WhatsApp/api';

declare global {
	interface Window {
		FB: any;
	}
}

const FB = window.FB || {};

const FB_SDK_VERSION = 'v16.0';

class LoginComponent extends React.Component<
	{
		botKey: string;
		appId: string;
		appName: string;
		label: string;
		isReAuth?: boolean;
		isDisabled?: boolean;
		pageIdMap?: IObjProps;
		setReauthorized?: Function;
		setRefreshAvailablePages?: Function;
		callOnSignIn?: Function;
		setIsAdAuthTokenPresent?: Function;
	},
	any
> {
	constructor(props: any) {
		super(props);
		this.state = {};
	}

	loadFbLoginApi() {
		(function (d, s, id) {
			var js: any = '';
			var js;
			const fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = '//connect.facebook.net/en_US/sdk.js';
			if (fjs.parentNode) {
				fjs.parentNode.insertBefore(js, fjs);
			}
		})(document, 'script', 'facebook-jssdk');
		FB.fbAsyncInit = function () {
			window.FB.init({
				appId: this.props.appId,
				cookie: true,
				xfbml: true,
				version: FB_SDK_VERSION,
			});
		};
	}

	componentDidMount() {
		this.loadFbLoginApi();
	}

	fetchFacebookData(type: any, accessToken: any) {
		window.FB.api(FB_ME_API, (response: any) => {
			this.submitFacebookLoginForm(type, response, accessToken);
		});
	}

	statusChangeCallback(type: any, response: any) {
		const { t }: any = this.props;
		if (this.props?.isReAuth) {
			window.FB.login(
				(response: { status: string; authResponse: { accessToken: any } }) => {
					if (response?.status === 'connected') {
						this.props.setReauthorized?.(true);
						this.fetchFacebookData(type, response.authResponse.accessToken);
					}
				},
				{
					scope: getScopeByApp(this.props.appName),
					extras: 'whatsapp_embedded_signup',
				},
				{
					auth_type: 'reauthenticate',
				}
			);
		} else if (response?.status === RESPONSE_STATUS.connected) {
			this.props.setReauthorized?.(true);
			this.fetchFacebookData(type, response.authResponse.accessToken);
		} else if (response?.status === RESPONSE_STATUS.notAuthorized) {
			toaster.positive(
				<ENGTToasterContainer
					title={t('pages:configure.deployment.facebookDeployment.authFailed')}
					description={t('common:somethingWentWrong')}
				/>,
				{}
			);
		} else {
			// The person is logged into Facebook, but not your app.
			window.FB.login(
				(response: { status: string; authResponse: { accessToken: any } }) => {
					if (response?.status === 'connected') {
						this.props.setReauthorized?.(true);
						this.fetchFacebookData(type, response.authResponse.accessToken);
					}
				},
				{
					scope: getScopeByApp(this.props.appName),
				}
			);
		}
	}

	getApiFromAppName(data: any) {
		switch (this.props?.appName) {
			case 'ads':
				return API.saveBotMetaConfig(data);
			case 'facebook':
				return API.fbSignIn(data);
			case 'whatsapp':
				return WA_API.waCommerceSignIn(data);
			case 'whatsAppCloud':
				return WA_API.loginWaCloud(data);
			default:
				return INSTA_API.login(data);
		}
	}

	submitFacebookLoginForm(type: string, facebookResponse: any, accessToken: any) {
		const data = { botkey: this.props.botKey, accessToken };
		this.submitData(data);
	}

	submitData(data: any) {
		if (this.props?.isReAuth) {
			data['updateInstances'] = true;
			if (this.props?.appName === 'instagram') {
				data['page_id_map'] = this.props?.pageIdMap;
			}
		}
		const { t }: any = this.props;
		this.getApiFromAppName(data).then((resp: any) => {
			if (resp.data) {
				this.props.setReauthorized?.(false);
				this.props.callOnSignIn && this.props.callOnSignIn();
				this.props.setRefreshAvailablePages?.(true);
				if (this.props?.appName === 'ads') {
					this.props.setIsAdAuthTokenPresent?.(true);
				}
				toaster.positive(
					<ENGTToasterContainer
						title={t('pages:configure.deployment.facebookDeployment.loggedIn')}
						description={t('pages:configure.deployment.facebookDeployment.loggedInSuccessful')}
					/>,
					{}
				);
			} else {
				this.props.setReauthorized?.(false);
				toaster.positive(
					<ENGTToasterContainer
						title={t('pages:configure.deployment.facebookDeployment.authFailed')}
						description={t('common:somethingWentWrong')}
					/>,
					{}
				);
			}
		});
	}

	checkLoginState() {
		window.FB.getLoginStatus(
			function (this: any, response: any) {
				this.statusChangeCallback(response);
			}.bind(this)
		);
	}

	handleFBLogin() {
		window.FB.init({
			appId: this.props.appId,
			cookie: true,
			xfbml: true,
			version: FB_SDK_VERSION,
		});
		window.FB.login(this.checkLoginState());
	}

	render() {
		const { t }: any = this.props;

		return (
			<>
				<ENGTButton
					kind={this.props?.isReAuth || this.props?.isDisabled ? KIND['secondary'] : KIND['primary']}
					label={t(this.props.label)}
					isDisabled={this.props?.isDisabled}
					onClick={() => {
						this.handleFBLogin();
					}}
					dataQa={CONFIGURE_DEPLOYMENT_FACEBOOK_LOGIN}
				/>
			</>
		);
	}
}

export default withTranslation()(LoginComponent);
