import React from 'react';

function AddIcon({ fillColor, size }: { fillColor?: string; size?: number }) {
	return (
		<svg width={size || 24} height={size || 24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.75 6H11.25V11.25H6V12.75H11.25V18H12.75V12.75H18V11.25H12.75V6Z'
				fill={fillColor || 'white'}
			/>
		</svg>
	);
}

export default AddIcon;
