import { Suspense, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';

import UserProfileHeader from 'components/UserProfileHeader/UserProfileHeader';

import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import { NoColumnRouteLinks } from 'router/NoColumnRoutes';

import Loader from '../Loader/Loader';

type NoColumnLayoutProps = {
	children?: any;
};

const MESSAGE_PATH = ['/messages/', '/allmessages', 'social-inbox/instagram/posts/', 'social-inbox/facebook/posts/'];

const NoColumnLayout = ({ children }: NoColumnLayoutProps) => {
	const [css]: any = useStyletron();

	const [showUserProfile, setShowUserProfile] = useState<boolean>(true);
	const { whatsappSetup } = NoColumnRouteLinks;
	const WA_ONBOARDING_PATH = [whatsappSetup];

	const { isSmallDesktopScreen } = useResponsiveSize();

	useEffect(() => {
		let shouldShowProfile = true;
		MESSAGE_PATH.forEach((path) => {
			if (window.location.pathname.indexOf(path) > -1) {
				shouldShowProfile = false;
			}
		});

		WA_ONBOARDING_PATH.forEach((path) => {
			if (window.location.pathname.indexOf(path) > -1) {
				shouldShowProfile = false;
			}
		});

		setShowUserProfile(shouldShowProfile);
	});

	return (
		<div className={css({ position: 'relative' })}>
			{!isSmallDesktopScreen && showUserProfile && <UserProfileHeader />}
			<section>
				<Suspense fallback={<Loader />}>{children}</Suspense>
			</section>
		</div>
	);
};

export default NoColumnLayout;
