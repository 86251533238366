import React, { Suspense } from 'react';
import { useStyletron } from 'baseui';
import { Cell } from 'baseui/layout-grid';
import { useLocation } from 'react-router-dom';

import { useBannerVisiblityFinder } from 'components/UI/ENGTBanner/ENGTBannerContext';
import Loader from 'components/UI/Loader/Loader';

import { getBannerExcludedPageHeight } from 'shared/helpers';

import navigationalRouteProps from 'router/helpers/navigationalRouteProps';

import PrimaryNavigation from '../PrimaryNavigation';
import SecondaryNavigation from '../SecondaryNavigation';

function DesktopNavigation() {
	const [, theme]: any = useStyletron();
	const bannerHeight = useBannerVisiblityFinder();
	const location = useLocation();

	const cellBorderCss: any = {
		Cell: {
			style: {
				height: getBannerExcludedPageHeight(bannerHeight),
				borderTop: '0',
				borderRight: '0px',
				borderBottom: '0',
				borderLeft: '0',
				borderStyle: 'solid',
				borderColor: theme.navigationBorderColor,
				borderOpacity: 0.4,
				overflow: 'auto',
			},
		},
	};

	const { primaryNav, secondNav } = navigationalRouteProps(location.pathname) || {};

	return (
		<>
			{primaryNav && (
				<Cell span={0.75}>
					<PrimaryNavigation />
				</Cell>
			)}
			{secondNav && (
				<Cell span={2.25} overrides={cellBorderCss}>
					<Suspense fallback={<Loader />}>
						<SecondaryNavigation {...secondNav} />
					</Suspense>
				</Cell>
			)}
		</>
	);
}

export default DesktopNavigation;
