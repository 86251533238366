import React from 'react';
import { useStyletron } from 'baseui';
import { PLACEMENT, StatefulTooltip } from 'baseui/tooltip';
import { LabelSmall } from 'baseui/typography';
import { useTranslation } from 'react-i18next';

import { tooltipPopoverOverrides } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { decodeAndSanitizeInput, labelWithCharacterLimit } from 'shared/helpers';

export interface ILabelWithTooltipProps {
	appendToBody?: boolean;
	fontSize?: string;
	fontWeight?: any;
	lineHeight?: string;
	backgroundColor?: string;
	color?: string;
	tooltipColor?: string;
	children?: any;
	characterLimit?: number;
	dangerouslySetHTML?: boolean;
	placement?: PLACEMENT[keyof PLACEMENT];
	style?: IObjProps;
	className?: string;
	dataQa?: string;
	maxWidth?: string;
	unreadCount?: number;
}

export const LabelWithTooltip: React.FC<React.PropsWithChildren<ILabelWithTooltipProps>> = ({
	appendToBody,
	fontSize,
	fontWeight = 400,
	lineHeight,
	backgroundColor,
	color,
	children,
	characterLimit,
	className,
	dangerouslySetHTML = true,
	placement = PLACEMENT.top,
	tooltipColor,
	style = {},
	dataQa,
	maxWidth,
	unreadCount,
	...rest
}) => {
	const [css, theme] = useStyletron();
	const { t } = useTranslation(['pages']);

	return (
		<LabelSmall
			className={`${css({
				fontStyle: 'normal',
				fontSize: fontSize || '1rem',
				lineHeight: lineHeight || '1.25rem',
				fontWeight: `${fontWeight} !important`,
				color: color || theme.colors.primaryA,
				whiteSpace: characterLimit ? 'nowrap' : '',
			} as any)} ${className}`}
			data-qa={dataQa}
			{...rest}
		>
			{characterLimit && children && children.length > characterLimit ? (
				<StatefulTooltip
					content={() => (
						<div
							className={css({
								textAlign: 'center',
								color: tooltipColor || theme.colors.primaryA,
							})}
						>
							{dangerouslySetHTML ? (
								<span
									dangerouslySetInnerHTML={{ __html: decodeAndSanitizeInput(children) }}
									className={css({
										wordWrap: 'break-word',
									})}
								/>
							) : (
								<span
									className={css({
										wordWrap: 'break-word',
									})}
								>
									{children}
								</span>
							)}
						</div>
					)}
					placement={placement}
					showArrow
					returnFocus
					autoFocus
					ignoreBoundary={appendToBody}
					overrides={tooltipPopoverOverrides({ backgroundColor, style, maxWidth })}
				>
					{dangerouslySetHTML ? (
						<span
							className={css({
								cursor: 'pointer',
							})}
							dangerouslySetInnerHTML={{
								__html: decodeAndSanitizeInput(labelWithCharacterLimit(characterLimit, children)),
							}}
						/>
					) : (
						<span
							className={css({
								cursor: 'pointer',
							})}
						>
							{labelWithCharacterLimit(characterLimit, children)}
						</span>
					)}
				</StatefulTooltip>
			) : dangerouslySetHTML ? (
				<span dangerouslySetInnerHTML={{ __html: decodeAndSanitizeInput(children) }} />
			) : (
				children
			)}
			{!!unreadCount && unreadCount > 1 && (
				<div>
					{t('pages:configure.deployment.instagramDeployment.socialInbox.unreadComments', {
						unreadCount,
					})}
				</div>
			)}
			{!!unreadCount && unreadCount === 1 && (
				<div>
					{t('pages:configure.deployment.instagramDeployment.socialInbox.unreadComment', {
						unreadCount,
					})}
				</div>
			)}
		</LabelSmall>
	);
};

export default LabelWithTooltip;
