import { useCallback, useState } from 'react';
import { useStyletron } from 'baseui';
import { Panel } from 'baseui/accordion';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ITableColumnProps } from 'components/UI/ENGTTable/ENGTTable';
import ENGTTableRow from 'components/UI/ENGTTable/ENGTTableRow';
import { ITableRowAction } from 'components/UI/ENGTTable/ENGTTableRowActions';

import { IObjProps } from 'shared/consts/types';
import DropDownToggleDown from 'shared/icons/DropDownToggleDown';
import DropDownToggleUp from 'shared/icons/DropDownToggleUp';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

import ENGTAccordion from '../ENGTAccordion/ENGTAccordion';

interface ITableAccordionRowProps {
	rows: Array<IObjProps>;
	columns: Array<ITableColumnProps>;
	actions?: Array<ITableRowAction>;
	overrides?: IObjProps;
	botMode?: string;
	boldWords?: Array<string>;
	rowToBeBold?: string;
	accordionOverrides?: IObjProps;
	rowActions?: Array<ITableRowAction> | { (rowData: IObjProps): Array<ITableRowAction> };
}
const ENGTTableAccordionRow = ({
	rows,
	columns,
	actions = [],
	overrides = {},
	botMode,
	boldWords,
	rowToBeBold,
	accordionOverrides = {},
	rowActions = [],
}: ITableAccordionRowProps) => {
	const { t } = useTranslation(['pages']);
	const [isPanelDisabled, setPanelDisabled] = useState<boolean>(false);
	const [css, theme]: [any, any] = useStyletron();

	const setIsPanelDisabledCb = useCallback(
		(flag: boolean) => {
			setPanelDisabled(flag);
		},
		[setPanelDisabled]
	);

	const accordionOverrideCss = {
		style: {
			Root: {
				style: () => ({
					borderTopStyle: 'none',
					marginBottom: '0.5rem',
					width: '100%',
					...accordionOverrides?.root,
				}),
			},
			Header: {
				style: ({ $expanded }: any) => ({
					backgroundColor: $expanded ? theme.colors.backgroundSecondary : 'transparent',
					borderBottomStyle: $expanded ? 'solid' : 'none',
					fontWeight: 600,
					fontSize: '0.825rem',
					lineHeight: '1.25rem',
					paddingLeft: '0rem',
					paddingRight: '0rem',
					paddingTop: '0.55rem',
					paddingBottom: '0.2rem',
					':hover': {
						backgroundColor: theme.colors.backgroundSecondary,
					},
					cursor: 'pointer',
					borderTopLeftRadius: '0.625rem',
					borderTopRightRadius: '0.625rem',
					borderBottomLeftRadius: $expanded ? '' : '0.625rem',
					borderBottomRightRadius: $expanded ? '' : '0.625rem',
					...accordionOverrides?.header,
				}),
			},
			Content: {
				style: ({ $expanded }: any) => ({
					paddingBottom: $expanded ? '0.5rem' : '0rem',
					paddingTop: $expanded ? '0.5rem' : '0rem',
					paddingRight: '0rem',
					paddingLeft: '0rem',
					backgroundColor: theme.colors.backgroundSecondary,
					borderBottomWidth: '0.0625rem',
					borderBottomColor: 'rgba(0, 0, 0, 0.1)',
					borderBottomStyle: 'solid',
					width: '100%',
					borderBottomLeftRadius: '0.625rem',
					borderBottomRightRadius: '0.625rem',
					...accordionOverrides?.content,
				}),
			},
			ToggleIcon: {
				component: ({ title }: { title: string }) => (
					<div style={{ marginRight: '0.7rem', marginTop: '0.188rem' }}>
						{title === 'Collapse' ? <DropDownToggleDown /> : <DropDownToggleUp />}
					</div>
				),
			},
		},
	};
	const overridesRowCss = {
		backgroundPrimary: theme.colors.backgroundSecondary,
		borderBlockWidth: '0rem',
		...overrides?.panelRowCss,
	};
	const overridesChildRowCss = {
		backgroundPrimary: theme.colors.backgroundSecondary,
		borderBlockWidth: '0rem',
		marginRight: '2.63rem',
		...overrides?.overridesPanelChildRowCss,
	};

	const renderAccordionRow = () => (
		<ENGTAccordion overrides={accordionOverrideCss}>
			{rows?.map((row: IObjProps, idx: number) => (
				<Panel
					disabled={isPanelDisabled}
					title={
						<ENGTTableRow
							key={`table-row-wrap-${idx}`}
							id={`table-row-${idx}`}
							row={row.campaignLevelData}
							columns={columns}
							botMode={botMode}
							actions={typeof rowActions === 'function' ? rowActions(row) : rowActions}
							overrides={overridesRowCss}
							isLastRow={false}
							boldWords={boldWords ?? []}
							rowToBeBold={rowToBeBold}
							setAccordionPanelDisabled={setIsPanelDisabledCb}
							accordionRow
						/>
					}
				>
					{row?.stepLevelData?.length > 0 ? (
						row.stepLevelData.map((row: IObjProps, idx: number) => (
							<ENGTTableRow
								key={`table-row-wrap-${idx}`}
								id={`table-row-${idx}`}
								row={row}
								columns={columns}
								botMode={botMode}
								overrides={overridesChildRowCss}
								isLastRow={false}
								boldWords={boldWords ?? []}
								rowToBeBold={rowToBeBold}
								accordionRow
							/>
						))
					) : (
						<div
							className={css({
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								color: theme.colors.primaryA,
								height: '1.875rem',
							})}
						>
							<NavLink
								className={css({
									color: theme.colors.primaryA,
								})}
								to={`${ThreeColumnRouteLinks.campaignAnalyticsSelected}?id=${encodeURIComponent(
									row?.campaignLevelData?.campaign_id
								)}`}
							>
								{t('pages:broadcast.campaigns.addNewStep')}
							</NavLink>
						</div>
					)}
				</Panel>
			))}
		</ENGTAccordion>
	);

	return renderAccordionRow();
};
export default ENGTTableAccordionRow;
