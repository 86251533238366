export const NoAuthenticationRouteLinks: { [key: string]: string } = {
	shopifyV2Registration: '/shopify-get-started',
	wooCommerceV2Registration: '/woocommerce-get-started',
	whatsappGetStarted: '/wa-get-started',
	portalLogin: '/login',
	portalLoginInstance: '/login-instance',
	portalGetStartedLink: '/get-started',
	preferredPartnerLink: '/:partnerCode/get-started',
	portalForgotPwdLink: '/forgot-password',
	portalResetLink: '/back-to-login',
	portalResetPassword: '/reset-password',
	verificationLinkExpired: '/link-expired',
	whiteLabelBrandingError: '/page-not-found',
};
