import { mergeOverrides, useStyletron } from 'baseui';
import { Skeleton } from 'baseui/skeleton';

import { IObjProps } from 'shared/consts/types';

function ENGTSkeletonSquareView({ height, width, override }: { height: string; width: string; override: IObjProps }) {
	const [css, theme]: any = useStyletron();

	const skeletonSquareCss = () =>
		mergeOverrides(
			{
				Root: {
					style: {
						marginTop: '0.25rem',
						backgroundImage: `${theme.skeletonBackgroundImage}`,
						borderRadius: '5px',
					},
				},
			},
			override
		);

	return <Skeleton height={height} width={width} animation overrides={skeletonSquareCss()} />;
}

export default ENGTSkeletonSquareView;
