const IntegrationsIcon = ({ size = '24', fill = '#403F42' }) => (
	<svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g opacity='0.8'>
			<path
				opacity='0.7'
				d='M11.825 18.3748V16.9166H11.6417C10.8398 16.9166 10.1874 16.2642 10.1874 15.4623C10.1874 14.6605 10.8398 14.0081 11.6417 14.0081H11.825V12.5499H10.0042C9.80393 12.5499 9.64161 12.3875 9.64161 12.1873V11.6415C9.64161 11.2394 9.31452 10.9124 8.91248 10.9124C8.51043 10.9124 8.18334 11.2394 8.18334 11.6415V12.1873C8.18334 12.3875 8.02102 12.5499 7.82079 12.5499H6V17.6457C6 18.0477 6.32709 18.3748 6.72913 18.3748H11.825Z'
				fill={fill}
			/>
			<path
				opacity='0.7'
				d='M17.6459 18.375C18.0479 18.375 18.375 18.0479 18.375 17.6459V12.55H16.9168V12.7333C16.9168 13.5352 16.2644 14.1876 15.4625 14.1876C14.6607 14.1876 14.0083 13.5352 14.0083 12.7333V12.55H12.55V14.3708C12.55 14.5711 12.3877 14.7334 12.1875 14.7334H11.6417C11.2396 14.7334 10.9125 15.0605 10.9125 15.4625C10.9125 15.8646 11.2396 16.1917 11.6417 16.1917H12.1875C12.3877 16.1917 12.55 16.354 12.55 16.5542V18.375H17.6459Z'
				fill={fill}
			/>
			<path
				opacity='0.7'
				d='M15.4625 13.4625C15.8646 13.4625 16.1917 13.1354 16.1917 12.7333V12.1875C16.1917 11.9873 16.354 11.825 16.5542 11.825H18.375V6.72913C18.375 6.32709 18.0479 6 17.6459 6H12.55V7.45824H12.7333C13.5352 7.45824 14.1876 8.11061 14.1876 8.91248C14.1876 9.71434 13.5352 10.3667 12.7333 10.3667H12.55V11.825H14.3708C14.5711 11.825 14.7334 11.9873 14.7334 12.1875V12.7333C14.7334 13.1354 15.0605 13.4625 15.4625 13.4625Z'
				fill={fill}
			/>
			<path
				d='M6.72913 6C6.32709 6 6 6.32709 6 6.72913V11.825H7.45824V11.6417C7.45824 10.8398 8.11061 10.1874 8.91248 10.1874C9.71434 10.1874 10.3667 10.8398 10.3667 11.6417V11.825H11.825V10.0042C11.825 9.80393 11.9873 9.64161 12.1875 9.64161H12.7333C13.1354 9.64161 13.4625 9.31452 13.4625 8.91248C13.4625 8.51043 13.1354 8.18334 12.7333 8.18334H12.1875C11.9873 8.18334 11.825 8.02102 11.825 7.82079V6H6.72913Z'
				fill={fill}
			/>
		</g>
	</svg>
);

export default IntegrationsIcon;
