export interface IErrorSaving {
	isOpen: boolean;
	errorCode: any;
}

export interface IInfo {
	isOpen: boolean;
	heading: string;
	description: any;
}

export enum SurveyModule {
	'PRICING_PAGE_EXIT' = 'PRICING_PAGE_EXIT',
}

export interface ISurveyModal {
	isOpen: boolean;
	data: {
		module: SurveyModule | '';
		currency?: string;
		[key: string]: any;
	};
}

export interface IModalStateInterface {
	errorSavingModal: IErrorSaving;
	infoModal: IInfo;
	surveyModal: ISurveyModal;
}

export const modalState: IModalStateInterface = {
	surveyModal: {
		isOpen: false,
		data: {
			module: '',
			currency: '',
		},
	},
	errorSavingModal: {
		isOpen: false,
		errorCode: null,
	},
	infoModal: {
		isOpen: false,
		heading: '',
		description: '',
	},
};
