import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Loader from 'components/UI/Loader/Loader';

import { RootState } from 'store/rootReducer';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';
import { NoColumnRouteLinks } from 'router/NoColumnRoutes';
import { LEFT_PANEL_ACCESS_ROUTES } from 'router/ProtectedRouter/protectedRoute';

function Integration() {
	const navigate = useNavigate();
	const { cloudIntegrationsLink, engatiApiLink } = ThreeColumnRouteLinks;
	const { noAccess } = NoColumnRouteLinks;
	const leftPanelsV2Data: any = useSelector((state: RootState) => state.User.botAdmin.data.left_panels_v2_keys || []);

	const hasEngatiApiAccess = leftPanelsV2Data.indexOf(LEFT_PANEL_ACCESS_ROUTES[engatiApiLink]) > -1;
	const hasCloudIntegrationAccess = leftPanelsV2Data.indexOf(LEFT_PANEL_ACCESS_ROUTES[cloudIntegrationsLink]) > -1;

	useEffect(() => {
		if (leftPanelsV2Data.length) {
			hasCloudIntegrationAccess
				? navigate(cloudIntegrationsLink, { replace: true })
				: hasEngatiApiAccess
					? navigate(engatiApiLink, { replace: true })
					: navigate(noAccess, { replace: true });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leftPanelsV2Data]);

	return (
		<div>
			<Loader loaderBlockHeight='100%' />
		</div>
	);
}

export default Integration;
