import { MESSAGES } from 'shared/consts/QAConsts';

function Messages({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			width={size || 40}
			height={size || 40}
			viewBox='0 0 40 40'
			fill='none'
			className={className}
			data-qa={MESSAGES}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='inherit'>
				<path
					d='M22.4785 9.51465H9.19727C7.9814 9.51465 7 10.496 7 11.7119V20.501C7 21.7167 7.9814 22.6982 9.19727 22.6982H9.92969V24.8955C9.92969 25.4247 10.4776 25.8079 11.0137 25.54C11.0283 25.5254 11.0577 25.5254 11.0723 25.5107C15.1014 22.8088 14.1984 23.4112 15.1006 22.8154C15.2178 22.7421 15.3496 22.6982 15.4961 22.6982H22.4785C23.6944 22.6982 24.6758 21.7167 24.6758 20.501V11.7119C24.6758 10.496 23.6944 9.51465 22.4785 9.51465ZM16.6191 18.3037H10.6621C9.69663 18.3037 9.69268 16.8389 10.6621 16.8389H16.6191C17.5846 16.8389 17.5886 18.3037 16.6191 18.3037ZM21.0137 15.374H10.6621C9.69663 15.374 9.69268 13.9092 10.6621 13.9092H21.0137C21.9792 13.9092 21.9831 15.374 21.0137 15.374Z'
					fill={fillColor || '#403F42'}
				/>
				<path
					opacity='0.7'
					d='M29.8027 15.374H26.1406V20.501C26.1406 22.5225 24.5 24.1631 22.4785 24.1631H15.7158L14.3242 25.0859V26.3604C14.3242 27.5761 15.3056 28.5576 16.5215 28.5576H23.7236L27.9277 31.3701C28.5015 31.6887 29.0703 31.3012 29.0703 30.7549V28.5576H29.8027C31.0186 28.5576 32 27.5761 32 26.3604V17.5713C32 16.3555 31.0186 15.374 29.8027 15.374Z'
					fill={fillColor || '#403F42'}
				/>
			</g>
		</svg>
	);
}

export default Messages;
