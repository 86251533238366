import React from 'react';

export interface IENGTLeftArrowIcon {
	className?: string;
	fillColor: string;
	height: string;
	width: string;
	viewX?: number;
	viewY?: number;
	viewBoxWidth?: number;
	viewBoxHeight?: number;
}

function LeftArrowIcon(props: IENGTLeftArrowIcon) {
	const {
		className = '',
		fillColor = '#403F42',
		height,
		width,
		viewX = 0,
		viewY = 0,
		viewBoxWidth = 7,
		viewBoxHeight = 10,
	} = props;
	const viewBox = `${viewX} ${viewY} ${viewBoxWidth} ${viewBoxHeight}`;

	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox={viewBox}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.71242 0.165039L6.77308 1.2257L3.06073 4.93805L6.77319 8.65051L5.71253 9.71117L0.938149 4.93679L1.99881 3.87613L2.00007 3.87739L5.71242 0.165039Z'
				fill={fillColor}
			/>
		</svg>
	);
}

export default LeftArrowIcon;
