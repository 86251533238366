import { useSelector } from 'react-redux';

import { ENGATI_ROLES } from 'shared/consts/consts';

import { RootState } from 'store/rootReducer';

import MarkOnlinePromptModal from '.';

function MarkOnlineWrapper() {
	const isAgent = useSelector(
		(state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT
	);

	return isAgent ? <MarkOnlinePromptModal /> : <></>;
}

export default MarkOnlineWrapper;
