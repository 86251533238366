import React from 'react';
import { useStyletron } from 'baseui';

function SortIcon({ fillColor, size }: { fillColor?: string; size?: number }) {
	const [css, theme]: [any, any] = useStyletron();

	return (
		<div
			className={css({
				marginTop: '0.5rem',
			})}
		>
			<svg
				width={size || '40'}
				height={size || '40'}
				viewBox='0 0 40 40'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<rect width='40' height='40' rx='20' fill='transparent' />
				<g clipPath='url(#clip0)'>
					<path d='M17 11L13 14.99H16V22H18V14.99H21L17 11Z' fill={fillColor || '#403F42'} />
					<path d='M24 25.01V18H22V25.01H19L23 29L27 25.01H24Z' fill={fillColor || '#403F42'} />
				</g>
				<defs>
					<clipPath id='clip0'>
						<rect width='18' height='18' fill='white' transform='translate(11 11)' />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
}

export default SortIcon;
