import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { useSelector } from 'react-redux';

import AccountDropdownMenu from 'components/AccountDropdownMenu/AccountDropdownMenu';

import { RootState } from 'store/rootReducer';

export default function AccountDropdownAvatar() {
	const [css, theme]: [any, any] = useStyletron();

	const userName = useSelector((state: RootState) => state.User.profile.data.name);
	const userAvatar = useSelector((state: RootState) => state.User.profile.data.profileImg);

	return (
		<StatefulPopover
			overrides={{
				Inner: {
					style: {
						backgroundColor: 'transparent',
					},
				},
				Body: {
					style: ({ $theme }: any) => ({
						backgroundColor: 'transparent',
						zIndex: $theme.zIndex300,
					}),
				},
			}}
			popoverMargin={16}
			placement={PLACEMENT.bottomRight}
			triggerType={TRIGGER_TYPE.click}
			content={({ close }) => <AccountDropdownMenu onClose={close} />}
		>
			<div
				className={css({
					marginTop: '1rem',
					marginBottom: '1rem',
					marginLeft: '0rem',
					marginRight: '1.5rem',
					float: 'right',
					display: 'flex',
				})}
			>
				<Avatar
					src={userAvatar}
					name={userName || ''}
					overrides={{
						Root: {
							style: {
								backgroundColor: theme.userProfileColor,
								cursor: 'pointer',
							},
						},
						Initials: {
							style: {
								color: '#FFF',
								fontSize: '0.94rem',
								lineHeight: '1.06rem',
								fontWeight: 600,
							},
						},
					}}
					size='2.5rem'
				/>
			</div>
		</StatefulPopover>
	);
}
