import { findEnv } from 'shared/helpers';

import { RootState } from 'store/rootReducer';

import { actions } from './store';

const { addNotification, clearNotifications, updateNotifications, toggleNotificaitonTray } = actions;

const NOTIFICATION_LS_KEY = (uid: any) => `notifications-${findEnv()}-${uid}`;
const LATEST_NOTIFICATION_LS_KEY = (uid: any) => `latestnotifications-${findEnv()}-${uid}`;

export const notificationFilterTypes = {
	notificationType: 'NOTIFICATION_TYPE',
	userId: 'USER_ID',
	conversationId: 'CONVERSATION_ID',
	generalNotification: 'GENERAL_NOTIFICATION',
	slaNonAdherence: 'SLA non-adherence',
};

export const addNotificationAction = (notification: any) => (dispatch: any, getState: any) => {
	if (localStorage.getItem(`pausednotification_${getState().User.botAdmin.data.uid}`) !== 'true') {
		const notifications = [];
		const oldNotifications = getState().Notifications.data;
		const data: any = notification;
		data.isLatest = true;
		const { conversationId, type } = data;
		notifications.unshift(data);
		oldNotifications.forEach((notification: any) => {
			if (
				!(notification.conversationId === conversationId && notification.type === type) ||
				(notification.conversationId === conversationId && notification.botRef !== data.botRef)
			) {
				notifications.push(notification);
			}
		});
		dispatch(addNotification({ data: notifications }));
		dispatch(showBrowserNotificaiton(notification));
		if (localStorage.getItem(LATEST_NOTIFICATION_LS_KEY(getState().User.botAdmin.data.uid))) {
			const arr: any = JSON.parse(
				localStorage.getItem(LATEST_NOTIFICATION_LS_KEY(getState().User.botAdmin.data.uid))!
			);
			arr.push(notification);
			localStorage.setItem(LATEST_NOTIFICATION_LS_KEY(getState().User.botAdmin.data.uid), JSON.stringify(arr));
		} else {
			localStorage.setItem(
				LATEST_NOTIFICATION_LS_KEY(getState().User.botAdmin.data.uid),
				JSON.stringify([notification])
			);
		}
		dispatch(storeNotificationInLS());
	}
};

export const clearNotificationsAction = () => (dispatch: any) => {
	dispatch(clearNotifications());
	dispatch(storeNotificationInLS());
};

export const getNotificationFromLS = () => (dispatch: any, getState: () => RootState) => {
	const { uid } = getState().User.botAdmin.data;
	const lsData = localStorage.getItem(NOTIFICATION_LS_KEY(uid));

	let notifications: any = lsData ? JSON.parse(lsData) : [];
	notifications = Array.isArray(notifications) ? notifications : [];

	dispatch(updateNotifications([...notifications]));
};

export const removeNotificationAction =
	(type: string, filterData: any) => (dispatch: any, getState: () => RootState) => {
		let notifications = getState().Notifications.data;

		if (type === notificationFilterTypes.notificationType) {
			notifications = notifications.filter((notification) => notification.type !== filterData);
		} else if (type === notificationFilterTypes.userId) {
			notifications = notifications.filter((notification) => {
				if (!notification.userId) {
					return true;
				}

				return (
					notification.type === 'notifyGroupVideoConversationInvite' ||
					decodeURIComponent(notification.userId) !== filterData
				);
			});
		} else if (type === notificationFilterTypes.conversationId) {
			notifications = notifications.filter((notification) => {
				if (!notification.conversationId) {
					return true;
				}

				return decodeURIComponent(notification.conversationId) !== filterData;
			});
		} else if (
			type === notificationFilterTypes.generalNotification ||
			type === notificationFilterTypes.slaNonAdherence
		) {
			notifications = notifications.filter(
				(notification) => (notification.notificationId || notification.conversationId) !== filterData
			);
		}

		dispatch(updateNotifications([...notifications]));
		dispatch(storeNotificationInLS());
	};

export const showBrowserNotificaiton = (notification: any) => (dispatch: any, getState: () => RootState) => {
	const favIconUrl = getState().User.botAdmin.data.branding_favicon_path;
	const currentBotRef = getState().Bot.data?.bot_ref;

	const isBrowserNotificaionAllowed = getState().Preferences.notifications.browserNotification;

	const detectMob = () => {
		const listOfMobileVariants = [
			/Android/i,
			/webOS/i,
			/iPhone/i,
			/iPad/i,
			/iPod/i,
			/BlackBerry/i,
			/Windows Phone/i,
		];

		return listOfMobileVariants.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
	};

	const { title, description, url, userName, botRef: notifBotRef, conversationId } = notification;
	let icon = '';
	const notificationTitle = userName || title;

	if (detectMob() || !isBrowserNotificaionAllowed) {
		return;
	}

	if (favIconUrl) {
		icon = `${favIconUrl}/favicon-32x32.png`;
	}

	const notificationInstance = new Notification(notificationTitle, { body: description, icon, url } as any);
	notificationInstance.onclick = () => {
		notificationInstance.close();
		window.parent.focus();
		if (url && window.location.pathname !== `/admin${url}`) {
			if (currentBotRef && notifBotRef && currentBotRef !== notifBotRef) {
				window.location.href = `/admin/bots?modal=switchBot&convId=${conversationId}&convUrl=${url}&notifBotRef=${notifBotRef}`;

				return;
			}
			window.location.href = `/admin${url}`;
		}
	};
};

export const storeNotificationInLS = () => (dispatch: any, getState: () => RootState) => {
	const { uid } = getState().User.botAdmin.data;
	localStorage.setItem(
		NOTIFICATION_LS_KEY(uid),
		JSON.stringify(Array.isArray(getState().Notifications.data) ? getState().Notifications.data : [])
	);

	dispatch(updateBrowserTitleAction());
};

export const updateBrowserTitleAction = () => (dispatch: any, getState: () => RootState) => {
	const notifications = getState().Notifications.data;

	const title = document.title.indexOf(')') > -1 ? document.title.split(')')[1].trim() : document.title;
	if (notifications.length > 0) {
		document.title = `(${notifications.length}) ${title}`;
	} else {
		document.title = title;
	}
};

export const toggleNotificaitonTrayAction = (isOpen?: boolean) => (dispatch: any, getState: () => RootState) => {
	!isOpen && dispatch(updateNotificationAction());
	dispatch(toggleNotificaitonTray({ isOpen }));
};

export const updateNotificationAction = (callback?: any) => (dispatch: any, getState: () => RootState) => {
	const { uid } = getState().User.botAdmin.data;
	const lsData = localStorage.getItem(NOTIFICATION_LS_KEY(uid));

	let notifications: any = lsData ? JSON.parse(lsData) : [];
	notifications = Array.isArray(notifications) ? notifications : [];
	const updatedNotifications = notifications.map(({ isLatest, ...rest }: any) => ({ ...rest }));
	dispatch(updateNotifications([...updatedNotifications]));
	callback && callback();
};
