const InfoIcon = ({ fill, size }) => {
	return (
		<svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12 6C8.69164 6 6 8.69164 6 12C6 15.3084 8.69164 18 12 18C15.3084 18 18 15.3084 18 12C18 8.69164 15.3083 6 12 6ZM12.3903 15.5599C12.1052 15.6075 11.538 15.7262 11.25 15.75C11.0062 15.7702 10.7761 15.6306 10.6359 15.4303C10.4953 15.23 10.4616 14.9736 10.545 14.7436L11.6792 11.625H10.5C10.499 10.9752 10.9864 10.4974 11.6098 10.314C11.9073 10.2264 12.4616 10.1067 12.75 10.125C12.9227 10.136 13.2239 10.2444 13.3641 10.4447C13.5047 10.645 13.5384 10.9014 13.4549 11.1314L12.3208 14.25H13.4996C13.4999 14.8991 13.0306 15.4532 12.3903 15.5599ZM12.75 9.75001C12.3358 9.75001 12 9.41418 12 9C12 8.58577 12.3358 8.24999 12.75 8.24999C13.1642 8.24999 13.5 8.58577 13.5 9C13.5 9.4142 13.1642 9.75001 12.75 9.75001Z'
				fill={fill}
			/>
		</svg>
	);
};

InfoIcon.defaultProps = {
	size: '24',
	fill: '#403F42',
};

export default InfoIcon;
