import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
	BEST_PRACTICES_WA_TEMPLATE_MESSAGE_LINK,
	ENGATI_RESPONSE_CODE,
	WHATSAPP_ACCOUNT_STATUS,
} from 'shared/consts/consts';
import { BROADCAST_TYPE } from 'shared/enum';
import { isValidResponseObject } from 'shared/helpers';

import { API } from 'store/Broadcast/api';

interface IAccountStatus {
	publishedPath: string;
}

function useAccountStatusHook({ publishedPath }: IAccountStatus) {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common', 'pages']);

	const [accountStatus, setAccountStatus] = useState<string>('');
	const [warning, setWarning] = useState<any>('');
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		API.fetchAccountStatus().then((response: any) => {
			const { status, responseObject } = response?.data || {};
			const { code, desc } = status || {};

			if (code === ENGATI_RESPONSE_CODE.STATUS_1025 || (code === ENGATI_RESPONSE_CODE.STATUS_1031 && desc)) {
				setAccountStatus(desc);
			} else if (isValidResponseObject(response)) {
				const status = responseObject?.status || '';
				setAccountStatus(status);
			}
		});
	}, []);

	const fetchAccountStatus = (redirectionQuery = '') => {
		if (accountStatus === WHATSAPP_ACCOUNT_STATUS.FLAGGED) {
			setWarning(
				<>
					{t('common:flaggedAccountStatus')}
					<a
						href={BEST_PRACTICES_WA_TEMPLATE_MESSAGE_LINK}
						target='_blank'
						className={css({ color: theme.colors.primaryA })}
						rel='noreferrer'
					>
						{t('common:Here')}
					</a>
				</>
			);
			setShowErrorModal(true);
		} else if (accountStatus === WHATSAPP_ACCOUNT_STATUS.RATE_LIMITED) {
			setWarning(
				<>
					{t('common:rateLimitedAccountStatus')}
					<a
						href={BEST_PRACTICES_WA_TEMPLATE_MESSAGE_LINK}
						target='_blank'
						className={css({ color: theme.colors.primaryA })}
						rel='noreferrer'
					>
						{t('common:Here')}
					</a>
				</>
			);
			setShowErrorModal(true);
		} else {
			const dataQuery = {
				status: accountStatus,
			};
			navigate(
				`${'/broadcast/create' + '?mode='}${BROADCAST_TYPE.CREATE}&payload=${encodeURIComponent(
					JSON.stringify(dataQuery)
				)}&path=${publishedPath}${redirectionQuery}`
			);
		}
	};

	return [fetchAccountStatus, accountStatus, warning, showErrorModal, setShowErrorModal];
}

export default useAccountStatusHook;
