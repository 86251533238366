import { useStyletron } from 'baseui';
import { useSelector } from 'react-redux';

import { RootState } from 'store/withReducer';

const FilterSelectionDot = ({ className }: { className?: any }) => {
	const themeColor: string = useSelector((state: RootState) => state.User.botAdmin.data.theme_color);
	const [css]: any = useStyletron();

	return (
		<div
			className={css({
				borderRadius: '50%',
				position: 'absolute',
				top: '26%',
				left: '58%',
				display: 'inline-block',
				width: '0.5rem',
				height: '0.5rem',
				backgroundColor: themeColor,
				...className,
			})}
		/>
	);
};

export default FilterSelectionDot;
