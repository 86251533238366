import { useEffect, useState } from 'react';

import { IObjProps } from 'shared/consts/types';
import { debounce } from 'shared/helpers';

export enum DEVICE_SIZE {
	TABLET = 'TABLET',
	SMALL_DESKTOP = 'SMALL_DESKTOP',
	DESKTOP = 'DESKTOP',
}

const DEVICE_SIZE_LIST = {
	SMALL_DESKTOP: 1080,
	MEDIUM_DESKTOP: 1280,
};

export interface IDeviceDetails {
	device: DEVICE_SIZE;
	width: number;
	height: number;
	window: IObjProps;
}

export const DEFAULT = {
	device: DEVICE_SIZE.DESKTOP,
	width: window.innerWidth,
	height: window.innerHeight,
	window,
};

export default function useResizerHook() {
	const [deviceDetails, setDeviceDetails] = useState<IDeviceDetails>(DEFAULT);

	useEffect(() => {
		const onResize = debounce(() => {
			if (window.innerWidth <= DEVICE_SIZE_LIST.SMALL_DESKTOP) {
				setDeviceDetails({
					device: DEVICE_SIZE.TABLET,
					width: window.innerWidth,
					height: window.innerHeight,
					window,
				});
			} else if (window.innerWidth <= DEVICE_SIZE_LIST.MEDIUM_DESKTOP) {
				setDeviceDetails({
					device: DEVICE_SIZE.SMALL_DESKTOP,
					width: window.innerWidth,
					height: window.innerHeight,
					window,
				});
			} else {
				setDeviceDetails({
					device: DEVICE_SIZE.DESKTOP,
					width: window.innerWidth,
					height: window.innerHeight,
					window,
				});
			}
		}, 600);

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	return { device: deviceDetails.device, width: deviceDetails.width, height: deviceDetails.height, window };
}
