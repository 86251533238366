import React, { useEffect } from 'react';

import { IObjProps } from 'shared/consts/types';

const TimeTrackingForGA4 = ({
	gtmTrackingId,
	trackerFunction,
	restData = {},
}: {
	gtmTrackingId: string;
	trackerFunction: (gtmTrackingId: string, timeSpentInSeconds: number, restPayload?: IObjProps) => void;
	restData?: IObjProps;
}) => {
	const sendTimeSpentToGA = (startTime: number) => {
		const timeSpentInSeconds = (new Date().getTime() - startTime) / 1000;

		trackerFunction(gtmTrackingId, timeSpentInSeconds, restData);
	};

	useEffect(() => {
		let startTime = new Date().getTime();
		const handlePageUnload = () => sendTimeSpentToGA(startTime);
		const handlePageVisibility = () => {
			if (document.visibilityState === 'visible') {
				startTime = new Date().getTime();
			} else {
				sendTimeSpentToGA(startTime);
			}
		};

		window.addEventListener('beforeunload', handlePageUnload);
		window.addEventListener('visibilitychange', handlePageVisibility);

		return () => {
			sendTimeSpentToGA(startTime);
			window.removeEventListener('beforeunload', handlePageUnload);
			window.removeEventListener('visibilitychange', handlePageVisibility);
		};
	}, []);

	return <></>;
};

export default React.memo(TimeTrackingForGA4);
