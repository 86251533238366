import React from 'react';
import { Block } from 'baseui/block';

import AccountSettingsWithOptions from './components/AccountSettingsWithOptions';
import Feedback from './components/Feedback';
import GetProfessionalHelp from './components/GetProfessionalHelp';
import Integrations from './components/Integrations';
import LogOut from './components/Logout';
import MasterAdminModes from './components/MasterAdmin';
import MyBots from './components/MyBots';
import PoliciesCTA from './components/PoliciesCTA';
import WhatsNew from './components/WhatsNew';

import './AccountDropdownMenu.scss';

function AccountMenuForMobile() {
	return (
		<>
			<Block marginTop='0.25rem' marginBottom='0.25rem'>
				<Feedback />
			</Block>
			<GetProfessionalHelp />
			<WhatsNew />
			<AccountSettingsWithOptions />
			<MasterAdminModes />
			<MyBots />
			<Integrations />
			<LogOut />
			<PoliciesCTA />
		</>
	);
}

export default AccountMenuForMobile;
