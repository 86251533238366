import { createSlice } from '@reduxjs/toolkit';

import { broadcastReducer } from './reducers';
import { broadcastState } from './state';

export const broadcastSlice = createSlice({
	name: 'Broadcast',
	initialState: broadcastState,
	reducers: broadcastReducer,
});

export const { actions, reducer } = broadcastSlice;
