import React, { ReactNode } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

import { IObjProps } from 'shared/consts/types';

interface IPageHeaderWithSectionsProps {
	/* Searchbar like over any configure page */
	children?: ReactNode;
	overrides?: IObjProps;
}

export const PageHeaderSearch = ({ children }: { children: ReactNode }) => {
	const [css] = useStyletron();

	return (
		<div
			className={css({
				position: 'absolute',
				top: 0,
				left: 0,
				width: '50%',
				marginTop: '1.5rem',
			})}
		>
			{children}
		</div>
	);
};

export const PageHeaderSection = ({ children, overrides }: { children: ReactNode; overrides?: IObjProps }) => {
	const [css] = useStyletron();

	return (
		<div
			className={css({
				marginBottom: '2rem',
				...overrides,
			})}
		>
			{children}
		</div>
	);
};

function PageHeaderWithSections(props: IPageHeaderWithSectionsProps) {
	const { children, overrides = {} } = props;

	return (
		<Block
			display='flex'
			alignItems='flex-end'
			justifyContent='space-between'
			position='relative'
			overrides={{
				Block: {
					style: { ...overrides },
				},
			}}
		>
			{children}
		</Block>
	);
}

export default PageHeaderWithSections;
