import React from 'react';
import { useStyletron } from 'baseui';
import { Select, SIZE, TYPE, Value } from 'baseui/select';

import { WINDOWS_SCROLL_BAR_CSS } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { hexToRgb, isWindows } from 'shared/helpers';
import DropDownToggleDown from 'shared/icons/DropDownToggleDown';
import DropDownToggleUp from 'shared/icons/DropDownToggleUp';

export type SelectOptionsType = Array<{
	id: string;
	label: string | React.ReactNode;
	disabled?: boolean;
}>;

export interface ISelectProps<String> {
	autoFocus?: boolean;
	dropDownListItemHeight?: number;
	error?: IObjProps;
	inputRef?: any;
	clearable?: boolean;
	closeOnSelect?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	multi?: boolean;
	numberOfDropDownItems?: number;
	placeholder?: string;
	popupIndex?: number;
	required?: boolean;
	options: SelectOptionsType;
	overrides?: IObjProps;
	searchable?: boolean;
	selectedValue?: Value;
	startOpen?: boolean;
	createable?: boolean;
	width?: string;
	fontFamily?: string;
	marginLeft?: string;
	openOnClick?: boolean;
	size?: keyof SIZE;
	showDropIcons?: boolean;
	type?: any;
	showDropdownIcons?: boolean;
	noResultsMsg?: any;
	customComponentPassed?: any;
	customErrorMessage?: any;
	dataQa?: string;
	onChange: (value: any) => any;
	onInputChange?: (value: any) => any;
	onFocus?: any;
	filterOptions?: (value: any) => any;
	filterOutSelected?: boolean;
	getOptionLabel?: (value: any) => any;
}

const ENGTSelect = ({
	autoFocus,
	createable,
	disabled,
	error,
	dropDownListItemHeight,
	isLoading,
	inputRef,
	multi,
	filterOptions,
	filterOutSelected,
	numberOfDropDownItems,
	onChange,
	onInputChange,
	options,
	overrides,
	placeholder,
	popupIndex,
	required,
	searchable,
	selectedValue,
	startOpen,
	clearable,
	width,
	fontFamily,
	marginLeft,
	openOnClick,
	size,
	showDropdownIcons = true,
	type,
	showDropIcons = true,
	noResultsMsg,
	customComponentPassed,
	customErrorMessage,
	closeOnSelect = true,
	dataQa,
	...rest
}: ISelectProps<string>) => {
	const [css, theme]: any = useStyletron();

	const setBorderRadius = ({ id, $placement }: any) => {
		if (id && document.querySelectorAll(`#${id} ul`)?.length > 0) {
			const popover: any = document.querySelectorAll(`#${id} ul`);
			if (!popover) {
				return;
			}
			if ($placement === 'top') {
				popover[0].style.borderRadius = '0.5rem';
			} else {
				popover[0].style.borderRadius = '0 0 0.5rem 0.5rem';
			}
		}
	};

	const overrideProps: any = {
		Root: {
			style: {
				width: 'auto',
			},
		},
		ValueContainer: {
			style: {
				lineHeight: '1.5rem',
				...overrides?.ValueContainer?.style,
			},
		},
		ControlContainer: {
			style: ({ $isOpen, $theme, $isFocused, ...rest }: any) => {
				const { inputSecondaryFillColor } = $theme;

				const borderColor = error?.message
					? $theme.colors.inputBorderError
					: $isFocused && !$isOpen
						? $theme.colors.focusedInputBorderColor
						: $theme.colors.horizantalDividerColor;

				return {
					width,
					minHeight: dropDownListItemHeight || '3rem',
					backgroundColor: disabled ? inputSecondaryFillColor : theme.colors.inputFillPrimary,
					borderBottomWidth: '0.063rem',
					borderLeftWidth: '0.063rem',
					borderRightWidth: '0.063rem',
					borderTopWidth: '0.063rem',
					borderBottomColor: borderColor,
					borderLeftColor: borderColor,
					borderRightColor: borderColor,
					borderTopColor: borderColor,
					borderBottomLeftRadius: '0.5rem',
					borderBottomRightRadius: '0.5rem',
					borderTopLeftRadius: '0.5rem',
					borderTopRightRadius: '0.5rem',
					boxShadow: 'none',
					boxSizing: 'border-box' as const,
					color: `${theme.colors.primaryA}`,
					'::placeholder': {
						color: theme.inputBorderColor,
					},
					fontSize: '0.875rem',
					lineHeight: '1.25rem',
					marginBottom: 'auto',
					marginLeft: width ? '0' : 'auto',
					marginRight: width ? '0' : 'auto',
					marginTop: 'auto',
					paddingLeft: '0.25rem',
					...overrides?.ControlContainer?.style,
				};
			},
		},

		Dropdown: {
			style: {
				width,
				backgroundColor: `${theme.colors.inputFillPrimary} !important`,
				borderBottomWidth: '0.063rem',
				borderLeftWidth: '0.063rem',
				borderRightWidth: '0.063rem',
				borderTopWidth: '0.063rem',
				borderBottomColor: theme.colors.horizantalDividerColor,
				borderLeftColor: theme.colors.horizantalDividerColor,
				borderRightColor: theme.colors.horizantalDividerColor,
				borderTopColor: theme.colors.horizantalDividerColor,
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
				borderBottomStyle: 'solid' as const,
				borderLeftStyle: 'solid' as const,
				borderRightStyle: 'solid' as const,
				borderTopStyle: 'solid' as const,
				boxShadow: 'none' as const,
				boxSizing: 'border-box' as const,
				paddingBottom: '0.5rem',
				paddingLeft: '0rem',
				paddingRight: '0rem',
				paddingTop: '0.5rem',
				marginLeft: marginLeft || '',
				marginTop: '0rem',
				marginBottom: '0rem',
				...overrides?.Dropdown?.style,
				...(isWindows() && WINDOWS_SCROLL_BAR_CSS),
			},
		},
		DropdownContainer: {
			style: {
				backgroundColor: theme.colors.inputFillPrimary,
				borderRadius: '0.5rem',
				...overrides?.DropdownContainer?.style,
			},
		},

		DropdownListItem: {
			style: ({ $isHighlighted }: any) => ({
				width: '96%',
				alignItems: 'center',
				borderBottomColor: $isHighlighted
					? theme.colors.horizantalDividerColor
					: theme.colors.backgroundPrimary,
				borderBottomStyle: 'solid' as const,
				borderBottomWidth: '0.063rem',
				borderBottomRightRadius: $isHighlighted ? '0.5rem' : 0,
				borderBottomLeftRadius: $isHighlighted ? '0.5rem' : 0,
				borderTopRightRadius: $isHighlighted ? '0.5rem' : 0,
				borderTopLeftRadius: $isHighlighted ? '0.5rem' : 0,
				display: 'flex',
				marginBottom: '0rem',
				marginLeft: 'auto',
				marginRight: 'auto',
				marginTop: '-2px',
				paddingBottom: '0rem',
				paddingLeft: '0rem',
				paddingRight: '0rem',
				paddingTop: '0rem',
				position: 'relative' as const,
				':last-child': {
					borderBottomStyle: 'none' as const,
				},
				...($isHighlighted
					? { minHeight: `${dropDownListItemHeight}px`, height: 'unset' }
					: {
							height: `${dropDownListItemHeight}px`,
						}),
				...overrides?.DropdownListItem?.style,
			}),
		},

		OptionContent: {
			style: ({ $isHighlighted }: any) => ({
				fontSize: '0.875rem',
				fontWeight: 'normal' as const,
				lineHeight: '1.25rem',
				marginLeft: '0.5rem',
				...($isHighlighted
					? {
							paddingTop: '0.5rem',
							paddingBottom: '0.5rem',
							paddingRight: '0.5rem',
							wordBreak: 'break-word',
						}
					: {
							textOverflow: 'ellipsis',
							width: '100%',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
						}),
				...overrides?.OptionContent?.style,
			}),
		},

		Popover: {
			props: {
				ignoreBoundary: true,
				overrides: {
					Body: {
						style: (props: any) => {
							setBorderRadius(props);

							return {
								boxShadow: 'none',
								borderTopRightRadius: '0.5rem',
								borderTopLeftRadius: '0.5rem',
								borderBottomRightRadius: '0.5rem',
								borderBottomLeftRadius: '0.5rem',
								zIndex: popupIndex,
								marginTop: '-0.25rem',
								marginLeft: '-0.25rem',
								marginRight: '-0.35rem',
								...overrides?.Popover?.Body?.style,
							};
						},
					},
					Inner: {
						style: {
							boxShadow: 'none',
							backgroundColor: 'transparent',
							...overrides?.Popover?.Inner?.style,
						},
					},
				},
			},
		},
		Tag: {
			props: {
				overrides: {
					Root: {
						style: {
							backgroundColor: multi ? theme.colors.backgroundSecondary : '',
							borderTopLeftRadius: '0.2rem',
							borderTopRightRadius: '0.2rem',
							borderBottomLeftRadius: '0.2rem',
							borderBottomRightRadius: '0.2rem',
							color: theme.colors.primaryA,
							...overrides?.Tag?.props?.overrides?.Root?.style,
						},
					},
					ActionIcon: {
						style: {
							marginTop: '-0.15rem',
						},
					},
				},
			},
		},
		IconsContainer: {
			style: {
				display: !showDropdownIcons ? 'none' : 'flex',
			},
		},
		SingleValue: {
			style: {
				...overrides?.SingleValue?.style,
			},
			props: {
				'data-qa': dataQa,
			},
		},
		...(customComponentPassed !== false && {
			SelectArrow: ({ $isOpen }: any) =>
				customComponentPassed || ($isOpen ? DropDownToggleDown({}) : DropDownToggleUp({})),
		}),
		Placeholder: {
			style: ({ $theme }: any) => ({
				color: hexToRgb(`${theme.colors.inputPlaceholderColor}`, 0.6),
			}),
		},
	};

	const errorMsgCss = {
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		marginTop: '0.5rem',
		color: theme.inputErrorMsgColor,
	};

	return (
		<>
			<Select
				clearable={clearable}
				multi={multi}
				maxDropdownHeight={`${numberOfDropDownItems! * dropDownListItemHeight! + 16}px`}
				isLoading={isLoading}
				disabled={disabled}
				autoFocus={autoFocus}
				onChange={(params) => {
					onChange(params.value);
				}}
				onInputChange={onInputChange}
				size={size}
				controlRef={inputRef}
				openOnClick={openOnClick}
				options={options}
				filterOptions={filterOptions}
				filterOutSelected={filterOutSelected}
				placeholder={placeholder}
				required={required}
				searchable={searchable}
				value={selectedValue || []}
				overrides={overrideProps}
				creatable={createable}
				type={type}
				noResultsMsg={noResultsMsg}
				closeOnSelect={closeOnSelect}
				{...rest}
			/>
			{(error || customErrorMessage?.message) && (
				<div className={css(errorMsgCss)}>
					{customErrorMessage?.message ? customErrorMessage?.message : error?.message}
				</div>
			)}
		</>
	);
};

ENGTSelect.defaultProps = {
	required: false,
	numberOfDropDownItems: 4,
	dropDownListItemHeight: 47,
	openOnClick: true,
	type: TYPE.select,
	size: SIZE.default,
	showDropdownIcons: true,
};
export default ENGTSelect;
