import { NAVIGATIONAL_HELPER_BOT_ACTIONS } from 'shared/consts/QAConsts';

const NavigationalHelperBotSetup = ({
	className,
	size,
	fillColor,
}: {
	className?: string;
	size?: number;
	fillColor?: string;
}) => (
	<svg
		width={size || 256}
		height={size || 82}
		viewBox='0 0 256 82'
		className={className}
		data-qa={NAVIGATIONAL_HELPER_BOT_ACTIONS}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M0 8C0 3.58172 3.58172 0 8 0H248C252.418 0 256 3.58172 256 8V82H0V8Z' fill='white' />
		<g opacity='0.4' filter='url(#filter0_b_11761_12082)'>
			<rect x='29' y='23' width='36' height='36' rx='8' fill='#E8E6E7' />
		</g>
		<path
			d='M49.6024 29.9902H35.6571C34.3805 29.9902 33.35 31.0207 33.35 32.2974V41.5259C33.35 42.8024 34.3805 43.833 35.6571 43.833H36.4262V46.1401C36.4262 46.6957 37.0015 47.0981 37.5644 46.8169C37.5797 46.8015 37.6106 46.8015 37.6259 46.7861C41.8565 43.9491 40.9084 44.5816 41.8557 43.956C41.9787 43.8791 42.1171 43.833 42.2709 43.833H49.6024C50.8791 43.833 51.9096 42.8024 51.9096 41.5259V32.2974C51.9096 31.0207 50.8791 29.9902 49.6024 29.9902ZM43.4501 39.2188H37.1952C36.1815 39.2188 36.1773 37.6807 37.1952 37.6807H43.4501C44.4639 37.6807 44.468 39.2188 43.4501 39.2188ZM48.0644 36.1426H37.1952C36.1815 36.1426 36.1773 34.6045 37.1952 34.6045H48.0644C49.0781 34.6045 49.0823 36.1426 48.0644 36.1426Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			opacity='0.7'
			d='M57.2929 36.1426H53.4477V41.5259C53.4477 43.6484 51.725 45.3711 49.6024 45.3711H42.5016L41.0404 46.3401V47.6782C41.0404 48.9548 42.0709 49.9854 43.3476 49.9854H50.9098L55.3241 52.9384C55.9266 53.273 56.5238 52.8661 56.5238 52.2925V49.9854H57.2929C58.5695 49.9854 59.6 48.9548 59.6 47.6782V38.4497C59.6 37.1731 58.5695 36.1426 57.2929 36.1426Z'
			fill={fillColor || '#AE1536'}
		/>
		<g opacity='0.4' filter='url(#filter1_b_11761_12082)'>
			<rect x='83' y='23' width='36' height='36' rx='8' fill='#E8E6E7' />
		</g>
		<path
			d='M100.999 43.6908V35.6826C100.772 35.7036 100.548 35.7391 100.319 35.7495H93.781C93.4187 35.7495 93.1248 36.0435 93.1248 36.4057V37.0619H91.1562C90.7939 37.0619 90.5 37.3559 90.5 37.7181V41.6553C90.5 42.0175 90.7939 42.3115 91.1562 42.3115H93.1248V42.9677C93.1248 43.3299 93.4187 43.6239 93.781 43.6239H94.1786L96.8165 50.6557C96.9779 51.077 97.3389 51.3828 97.7824 51.472C97.8704 51.4904 97.9583 51.4996 98.0449 51.4996C98.3953 51.4996 98.7287 51.3605 98.9675 51.1111L100.544 49.5481C100.947 49.1452 101.042 48.5165 100.785 48.0296L98.583 43.6239H100.326C100.552 43.6344 100.775 43.6698 100.999 43.6908Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			opacity='0.7'
			d='M111.391 30.8176C111.378 30.7953 111.366 30.7769 111.349 30.7559C111.276 30.664 111.186 30.5892 111.074 30.5459C111.07 30.5446 111.068 30.5394 111.064 30.5381C111.051 30.5341 111.039 30.5407 111.026 30.5367C110.967 30.5197 110.908 30.5 110.842 30.5C110.797 30.5 110.758 30.5171 110.715 30.5262C110.69 30.5315 110.666 30.5328 110.641 30.5407C110.528 30.5774 110.431 30.6404 110.354 30.727C110.349 30.7323 110.341 30.7336 110.335 30.7389C108.289 33.2232 105.436 34.8847 102.311 35.4937V43.8812C105.436 44.4901 108.289 46.1503 110.335 48.6347C110.341 48.6412 110.35 48.6438 110.356 48.6517C110.402 48.7029 110.459 48.7436 110.519 48.7777C110.539 48.7895 110.555 48.8066 110.574 48.8158C110.657 48.8512 110.746 48.8735 110.842 48.8735C110.917 48.8735 110.99 48.8604 111.064 48.8355C111.068 48.8341 111.07 48.8289 111.074 48.8276C111.186 48.7843 111.276 48.7108 111.349 48.6176C111.364 48.5966 111.376 48.5782 111.391 48.5559C111.454 48.4549 111.498 48.3433 111.498 48.2173V31.1562C111.498 31.0302 111.454 30.9187 111.391 30.8176Z'
			fill={fillColor || '#AE1536'}
		/>
		<g opacity='0.4' filter='url(#filter2_b_11761_12082)'>
			<rect x='137' y='23' width='36' height='36' rx='8' fill='#E8E6E7' />
		</g>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M146.228 37.1698C146.228 34.9395 148.042 33.125 150.273 33.125C152.503 33.125 154.317 34.9395 154.317 37.1698C154.317 39.4001 152.503 41.2146 150.273 41.2146C148.042 41.2146 146.228 39.4001 146.228 37.1698ZM151.171 42.1132C152.73 42.1132 154.198 42.7324 155.305 43.8568C156.407 44.9757 157.014 46.4526 157.014 48.0156C157.014 48.2638 156.813 48.465 156.565 48.465H143.981C143.733 48.465 143.531 48.2638 143.531 48.0156C143.531 46.4526 144.138 44.9757 145.24 43.8568C146.348 42.7324 147.816 42.1132 149.374 42.1132H151.171Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			d='M155.305 43.8568L154.655 44.4975L154.655 44.4975L155.305 43.8568ZM145.24 43.8568L145.891 44.4975L145.891 44.4975L145.24 43.8568ZM150.273 32.2119C147.538 32.2119 145.315 34.4352 145.315 37.1698H147.141C147.141 35.4438 148.547 34.0381 150.273 34.0381V32.2119ZM155.231 37.1698C155.231 34.4352 153.007 32.2119 150.273 32.2119V34.0381C151.999 34.0381 153.404 35.4438 153.404 37.1698H155.231ZM150.273 42.1277C153.007 42.1277 155.231 39.9044 155.231 37.1698H153.404C153.404 38.8958 151.999 40.3015 150.273 40.3015V42.1277ZM145.315 37.1698C145.315 39.9044 147.538 42.1277 150.273 42.1277V40.3015C148.547 40.3015 147.141 38.8958 147.141 37.1698H145.315ZM155.956 43.2161C154.679 41.9199 152.978 41.2001 151.171 41.2001V43.0263C152.482 43.0263 153.716 43.5449 154.655 44.4975L155.956 43.2161ZM157.927 48.0156C157.927 46.2116 157.225 44.5049 155.956 43.2161L154.655 44.4975C155.589 45.4465 156.101 46.6936 156.101 48.0156H157.927ZM156.565 49.3781C157.317 49.3781 157.927 48.7681 157.927 48.0156H156.101C156.101 47.7595 156.308 47.5519 156.565 47.5519V49.3781ZM143.981 49.3781H156.565V47.5519H143.981V49.3781ZM142.618 48.0156C142.618 48.7681 143.228 49.3781 143.981 49.3781V47.5519C144.237 47.5519 144.444 47.7595 144.444 48.0156H142.618ZM144.59 43.2161C143.32 44.5049 142.618 46.2116 142.618 48.0156H144.444C144.444 46.6936 144.956 45.4465 145.891 44.4975L144.59 43.2161ZM149.374 41.2001C147.567 41.2001 145.866 41.9199 144.59 43.2161L145.891 44.4975C146.829 43.5449 148.064 43.0263 149.374 43.0263V41.2001ZM151.171 41.2001H149.374V43.0263H151.171V41.2001Z'
			fill={fillColor || '#AE1536'}
		/>
		<g opacity='0.7'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M157.265 38.8266C157.265 36.9234 158.813 35.375 160.716 35.375C162.619 35.375 164.168 36.9234 164.168 38.8266C164.168 40.7297 162.619 42.2781 160.716 42.2781C158.813 42.2781 157.265 40.7297 157.265 38.8266ZM161.483 43.0449C162.813 43.0449 164.065 43.5733 165.01 44.5328C165.951 45.4876 166.469 46.7479 166.469 48.0816C166.469 48.2934 166.297 48.4651 166.085 48.4651H155.347C155.135 48.4651 154.963 48.2934 154.963 48.0816C154.963 46.7479 155.481 45.4876 156.422 44.5328C157.367 43.5733 158.619 43.0449 159.949 43.0449H161.483Z'
				fill={fillColor || '#AE1536'}
			/>
			<path
				d='M165.01 44.5328L164.36 45.1735L164.36 45.1735L165.01 44.5328ZM156.422 44.5328L157.072 45.1735L157.072 45.1735L156.422 44.5328ZM160.716 34.4619C158.309 34.4619 156.351 36.4191 156.351 38.8266H158.178C158.178 37.4277 159.317 36.2881 160.716 36.2881V34.4619ZM165.081 38.8266C165.081 36.4191 163.124 34.4619 160.716 34.4619V36.2881C162.115 36.2881 163.255 37.4277 163.255 38.8266H165.081ZM160.716 43.1912C163.124 43.1912 165.081 41.234 165.081 38.8266H163.255C163.255 40.2255 162.115 41.365 160.716 41.365V43.1912ZM156.351 38.8266C156.351 41.234 158.309 43.1912 160.716 43.1912V41.365C159.317 41.365 158.178 40.2255 158.178 38.8266H156.351ZM165.661 43.8921C164.547 42.7608 163.061 42.1318 161.483 42.1318V43.958C162.565 43.958 163.584 44.3858 164.36 45.1735L165.661 43.8921ZM167.382 48.0816C167.382 46.5068 166.769 45.0168 165.661 43.8921L164.36 45.1735C165.133 45.9584 165.556 46.9889 165.556 48.0816H167.382ZM166.085 49.3783C166.801 49.3783 167.382 48.7977 167.382 48.0816H165.556C165.556 47.7891 165.793 47.552 166.085 47.552V49.3783ZM155.347 49.3783H166.085V47.552H155.347V49.3783ZM154.05 48.0816C154.05 48.7977 154.631 49.3783 155.347 49.3783V47.552C155.639 47.552 155.877 47.7891 155.877 48.0816H154.05ZM155.771 43.8921C154.663 45.0168 154.05 46.5068 154.05 48.0816H155.877C155.877 46.9889 156.299 45.9584 157.072 45.1735L155.771 43.8921ZM159.949 42.1318C158.371 42.1318 156.885 42.7608 155.771 43.8921L157.072 45.1735C157.848 44.3858 158.867 43.958 159.949 43.958V42.1318ZM161.483 42.1318H159.949V43.958H161.483V42.1318Z'
				fill={fillColor || '#AE1536'}
			/>
		</g>
		<g opacity='0.4' filter='url(#filter3_b_11761_12082)'>
			<rect x='191' y='23' width='36' height='36' rx='8' fill='#E8E6E7' />
		</g>
		<path
			opacity='0.7'
			d='M203.749 38.7495H199.249C198.836 38.7495 198.502 39.0834 198.498 39.5008V50.7488C198.498 51.1662 198.836 51.5001 199.249 51.5001H203.749C204.166 51.5001 204.5 51.1618 204.5 50.7488V39.5008C204.5 39.0834 204.162 38.7495 203.749 38.7495Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			d='M211.249 30.5024H206.749C206.336 30.5024 205.998 30.8364 205.998 31.2494V50.7487C205.998 51.1661 206.336 51.5 206.749 51.5H211.249C211.666 51.5 212 51.1617 212 50.7487V31.2538C212 30.8364 211.662 30.5024 211.249 30.5024Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			opacity='0.7'
			d='M218.749 36.5H214.25C213.832 36.5 213.498 36.8339 213.498 37.2513V50.7488C213.498 51.1662 213.837 51.5002 214.25 51.5002H218.749C219.166 51.5002 219.5 51.1618 219.5 50.7488V37.2513C219.5 36.8339 219.162 36.5 218.749 36.5Z'
			fill={fillColor || '#AE1536'}
		/>
		<defs>
			<filter
				id='filter0_b_11761_12082'
				x='25'
				y='19'
				width='44'
				height='44'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
				<feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_11761_12082' />
				<feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_11761_12082' result='shape' />
			</filter>
			<filter
				id='filter1_b_11761_12082'
				x='79'
				y='19'
				width='44'
				height='44'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
				<feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_11761_12082' />
				<feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_11761_12082' result='shape' />
			</filter>
			<filter
				id='filter2_b_11761_12082'
				x='133'
				y='19'
				width='44'
				height='44'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
				<feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_11761_12082' />
				<feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_11761_12082' result='shape' />
			</filter>
			<filter
				id='filter3_b_11761_12082'
				x='187'
				y='19'
				width='44'
				height='44'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
				<feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_11761_12082' />
				<feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_11761_12082' result='shape' />
			</filter>
		</defs>
	</svg>
);
export default NavigationalHelperBotSetup;
