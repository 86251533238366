import { IMessageDetailsStateInterface, messageDetailsState } from './messageDetails/state';
import { ImessageListStateInterface, messageListState } from './messageList/state';
import { ImessageOverviewStateInterface, messageOverviewState } from './messageOverview/state';
import { IMessageCommonInterface, messageCommonState } from './messagesCommon/state';
import { IPostCommentsInterface, postCommentsState } from './PostComments/state';
import { IPostListAndOverviewInterface, postListAndOverviewState } from './PostListAndOverview/state';

export type IMessageStateInterface = ImessageListStateInterface &
	IMessageDetailsStateInterface &
	ImessageOverviewStateInterface &
	IPostListAndOverviewInterface &
	IPostCommentsInterface &
	IMessageCommonInterface;

export const messageState: IMessageStateInterface = {
	...messageDetailsState,
	...messageListState,
	...messageOverviewState,
	...postListAndOverviewState,
	...postCommentsState,
	...messageCommonState,
};
