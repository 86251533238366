import { useStyletron } from 'baseui';

import { hexToRgb } from 'shared/helpers';

function ENGTBackdrop({ onClick }: { onClick: any }) {
	const [css, theme]: any = useStyletron();

	return (
		<div
			className={css({
				backgroundColor: hexToRgb(theme.colors.modalOverlayColor, 0.7),
				width: '100%',
				height: '100%',
				cursor: 'pointer',
				position: 'fixed',
				top: '0',
				left: '0',
				zIndex: -1,
			})}
			onClick={() => onClick()}
		/>
	);
}

export default ENGTBackdrop;
