import { IObjProps } from 'shared/consts/types';

export const redirectFromModalAlert = (supportEmailOrLink: IObjProps, subject?: string) => {
	if (supportEmailOrLink?.type === 'email') {
		window.location.href = `mailto:${supportEmailOrLink.value}${subject ? `?subject=${subject}` : ''}`;
	}
	if (supportEmailOrLink?.type === 'link') {
		window.open(supportEmailOrLink.value, '_blank')?.focus();
	}
};
