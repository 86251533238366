export const LS_KEYS = {
	BROADCAST_FILTERS: 'broadcast_filters_',
	ENFORCEMENT_STATUS: 'enforcement_status',
	COUNTRY_CODE: 'CountryCode_uid',
	FIRST_ONEVIEW_VISIT: 'FirtOneViewVisitDone',
	IS_MOBILE_TO_DESKTOP_MODAL_SHOWN: 'MobileToDesktop',
	SHORTCUT_QUICK_FILTERS: 'shortcutQuickFilters',
	SHORTCUT_MY_CONVERSATION_FILTER: 'shortcutQuickFilters_myConversation',
	MESSAGE_TEMPLATE_FILTERS: 'message_template_filters',
	MESSAGE_TEMPLATE_SORT_OPTION: 'message_template_sort_option',
	SELECTED_INTENTS: 'selected_intents',
	SYSTEM_INTENTS: 'system_intent',
	OTP_TRY_COUNT: 'otpTryCount',
	RESEND_CLICKED: 'resendClicked',
	PRICING_PAGE_EXIT: 'pricing_page_exit',
	ENGAGEMENT_FILTER: 'engagement_filter',
};

export const getUniqueLSKey = (key, botRef, uid) => `${key}_${botRef}_${uid}`;

export const getUniqueTabLSKey = (key, tab, botRef, uid) => `${key}_${tab}_${botRef}_${uid}`;

export const getFilterKeys = (getState) =>
	`${LS_KEYS.BROADCAST_FILTERS}${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`;

export const writeToLs = (a, b) => localStorage.setItem(a, b);

export const readFromLs = (a) => localStorage.getItem(a);

export const deleteFromLs = (a) => localStorage.removeItem(a);

export const LsKeysMapping = (key, uid) => key?.replace(uid);
