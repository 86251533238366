import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { HeadingLevel } from 'baseui/heading';
import { SIZE } from 'baseui/progress-bar';
import { ParagraphMedium } from 'baseui/typography';
import toaster from 'baseui-new/toast/toaster';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CreateBroadcastBtn from 'components/shared/CreateBroadcastBtn/CreateBroadcastBtn';
import CreateClickToWAAdBtn from 'components/shared/CreateClickToWAAdBtn/CreateClickToWAAdBtn';
import { VerticalDivider } from 'components/UI/Dividers/VerticalDivider';
import ENGTLink from 'components/UI/ENGTLink/ENGTLink';
import { ENGTProgress } from 'components/UI/ENGTProgress/ENGTProgress';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';
import Loader from 'components/UI/Loader/Loader';

import { LS_KEYS, readFromLs } from 'shared/consts/localStorageConsts';
import { IObjProps } from 'shared/consts/types';
import { DAYS_FILTER, ENGAGEMENT_TAB, EXECUTION_TAB, MARKETING_CAMPAIGNS_ENTITLEMENT_VALUES } from 'shared/enum';
import { decodeAndSanitizeInput, isValidResponseObject } from 'shared/helpers';
import { isValidPercentIncrease } from 'shared/helpers/broadcastHelpers';
import useAccountStatusHook from 'shared/hooks/feature/useAccountStatusHook';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import FilledDownCaret from 'shared/icons/FilledDownCaret';
import FilledUpCaret from 'shared/icons/FilledUpCaret';
import InfoIcon from 'shared/icons/PathBuilder/InfoIcon';

import { isCTWAEntitlementEnabledSelector, marketingCampaignsAnalyticsSelector } from 'store/App/User';
import { API } from 'store/Broadcast/api';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

import InfoCardWithIcon from 'pages/BroadcastRevamp/ClickToWhatsappAds/components/InfoCardWithIcon';

import DaysFilter from './DaysFilter';

enum SOURCE_TYPE {
	BROADCAST = 'BROADCAST',
	CTWA = 'CTWA',
}

function EngagementSummary() {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common', 'pages']);
	const { isSmallDesktopScreen } = useResponsiveSize();
	const navigate = useNavigate();
	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);
	const isCTWAEntitlementEnabled = useSelector(isCTWAEntitlementEnabledSelector);

	const isPartnerCustomer =
		marketingCampaignsAnalyticsType === MARKETING_CAMPAIGNS_ENTITLEMENT_VALUES.PARTNER || !isCTWAEntitlementEnabled;

	const [isLoading, setLoading] = useState<boolean>(true);
	const [filterOn, setFilterOn] = useState<string>(() => {
		const savedFilter = readFromLs(LS_KEYS.ENGAGEMENT_FILTER);

		return savedFilter !== null ? savedFilter : DAYS_FILTER.DAYS_7;
	});
	const [engagedUsersData, setEngagedUsersData] = useState<IObjProps>([]);
	const [fetchAccountStatus] = useAccountStatusHook({
		publishedPath: 'engaged',
	});

	const styles = {
		engagementBySourceWrapper: {
			display: 'flex',
			gap: '1rem',
			alignItems: 'flex-start',
		},

		leftSection: {
			display: 'flex',
			flexDirection: 'column',
			gap: '0.5rem',
			flex: 3,
		},

		rightSection: {
			marginTop: '2rem',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},

		headingCss: {
			marginBottom: '0.3125rem',
			fontWeight: '700 !important',
			fontSize: '1.125rem !important',
		},

		subHeadingCss: {
			marginTop: '0.5rem',
			lineHeight: '1.25rem',
			width: '100%',
			fontSize: '0.875rem !important',
			fontWeight: '400 !important',
		},

		cardWrapperCss: {
			marginTop: '0.75rem',
			marginBottom: '0.75rem',
			display: 'grid',
			gridTemplateColumns: isSmallDesktopScreen ? '1fr' : '3fr 3fr',
			gap: '2rem',
			color: `${theme.colors.primaryA}`,
		},

		progressBarSection: {
			display: 'flex',
			flexDirection: 'column',
			gap: '0.5rem',
			flexGrow: 3,
			color: `${theme.colors.primaryA}`,
			fontSize: '0.875rem',
		},

		progressBarRow: {
			display: 'grid',
			gridTemplateColumns: isPartnerCustomer ? '0.3fr 1fr 1.5fr' : '0.65fr 2fr 1.2fr 0.85fr',
			alignItems: 'center',
			gap: isPartnerCustomer ? '2rem' : '0.75rem',
		},

		buttonOverrides: {
			height: '2.25rem',
			marginBottom: '0rem',
			marginLeft: '0.5rem',
		},

		infoCardCss: {
			display: 'inline-flex',
			alignItems: 'center',
			marginTop: '4rem',
			gap: '0.2rem',
		},
	};

	const engagementHeader = (
		<HeadingLevel>
			<ParagraphMedium
				color={theme.colors.primaryA}
				className={css({
					...styles.headingCss,
					marginTop: '0.5rem',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				})}
			>
				<div
					className={css({
						display: 'flex',
						alignItems: 'center',
					})}
				>
					{t('pages:marketingCampaigns.engagement.allEngagement')}
					{/* <Button
						size={SIZE.large}
						shape='circle'
						kind={KIND.secondary}
						overrides={{
							BaseButton: {
								style: {
									...circularButtonCss,
									marginLeft: '0.5rem',
									height: '1.7rem',
									width: '1.7rem',
								},
							},
						}}
						// onClick={() => {
						// 	downLoadFile(
						// 		`${botId}_engaged_users_${noOfDays}days.csv`,
						// 		'CONNECT_USERS',
						// 		noOfDays
						// 	);
						// }}
					>
						<ArrowIcon width={22} height={22} />
					</Button> */}
				</div>
				<DaysFilter filterOn={setFilterOn} />
			</ParagraphMedium>
			<ParagraphMedium
				color={theme.colors.primaryA}
				className={css({ ...styles.subHeadingCss, marginBottom: '1rem' })}
			>
				{t('pages:marketingCampaigns.engagement.allEngagementSubHeading')}
			</ParagraphMedium>
		</HeadingLevel>
	);

	const engagementInfoIcon = (content: string) => (
		<ENGTTooltip content={content}>
			<label
				style={{
					cursor: 'pointer',
				}}
			>
				<InfoIcon fill={theme.colors.accent50} />
			</label>
		</ENGTTooltip>
	);

	const progressBar = (
		<div className={css(styles.progressBarSection)}>
			<div className={css(styles.progressBarRow)}>
				<span style={{ display: 'flex', alignItems: 'center' }}>
					{t('pages:marketingCampaigns.engagement.broadcast')}
					{engagementInfoIcon(t('pages:marketingCampaigns.engagement.broadcastInfo'))}
				</span>
				<ENGTProgress
					value={engagedUsersData?.broadcastEngagement?.engagedCount}
					size={SIZE.medium}
					color={
						engagedUsersData?.broadcastEngagement?.engagedCount
							? theme.colors.engatiBlue
							: theme.colors.accent400
					}
					fillColor={theme.colors.accent400}
					maxValue={engagedUsersData?.broadcastEngagement?.totalCount}
					borderRadius='0.5rem'
				/>
				{!isPartnerCustomer &&
					`${engagedUsersData?.broadcastEngagement?.effectiveness}% ${t(
						'pages:marketingCampaigns.engagement.engagementRate'
					)}`}
				<ENGTLink
					link={t('pages:marketingCampaigns.engagement.viewDetails')}
					onClick={() => navigate(`${ThreeColumnRouteLinks.engagementLink}/` + `${ENGAGEMENT_TAB.BROADCAST}`)}
					url='javascript:void(0);'
					openInNewTab={false}
				/>
			</div>

			<div className={css(styles.progressBarRow)}>
				<span style={{ display: 'flex', alignItems: 'center' }}>
					{t('pages:marketingCampaigns.engagement.CTWA')}
					{engagementInfoIcon(t('pages:marketingCampaigns.engagement.ctwaInfo'))}
				</span>
				<ENGTProgress
					value={engagedUsersData?.ctwaEngagement?.engagedCount}
					size={SIZE.small}
					color={
						engagedUsersData?.ctwaEngagement?.engagedCount
							? theme.colors.inputProgressBarColor
							: theme.colors.accent400
					}
					fillColor={theme.colors.accent400}
					maxValue={!isPartnerCustomer ? engagedUsersData?.ctwaEngagement?.totalCount : undefined}
					borderRadius='0.5rem'
				/>
				{!isPartnerCustomer &&
					`${engagedUsersData?.ctwaEngagement?.effectiveness}% ${t(
						'pages:marketingCampaigns.engagement.engagementRate'
					)}`}
				<ENGTLink
					link={t('pages:marketingCampaigns.engagement.viewDetails')}
					onClick={() => navigate(`${ThreeColumnRouteLinks.engagementLink}/` + `${ENGAGEMENT_TAB.CTWA}`)}
					url='javascript:void(0);'
					openInNewTab={false}
				/>
			</div>

			{!engagedUsersData?.ctwaEngagement?.engagedCount &&
				!engagedUsersData?.ctwaEngagement?.totalCount &&
				!isPartnerCustomer && (
					<div style={{ marginTop: '0.5rem' }}>
						<ENGTLink
							link={t('pages:marketingCampaigns.engagement.login')}
							overrides={{ color: theme.colors.primaryA }}
							onClick={() =>
								navigate(`${ThreeColumnRouteLinks.executionLink}/` + `${EXECUTION_TAB.CTWA}`)
							}
							openInNewTab={false}
						/>
						<span>{t('pages:marketingCampaigns.engagement.metaAdAccountDescription')}</span>
					</div>
				)}
		</div>
	);

	const showCtwaPercentIncrease = isValidPercentIncrease(engagedUsersData?.ctwaEngagement?.percentIncrease);

	const showBroadcastPercentIncrease = isValidPercentIncrease(engagedUsersData?.broadcastEngagement?.percentIncrease);

	const engagementInfoCards = (
		<div className={css(styles.cardWrapperCss)}>
			<InfoCardWithIcon
				title={<strong>{t('pages:marketingCampaigns.engagement.clickToWhatsappAds')}</strong>}
				infoIcon={t('')}
				value={engagedUsersData?.ctwaEngagement?.engagedCount || 0}
				marginTop='2rem'
			>
				{showCtwaPercentIncrease ? (
					<div className={css(styles.infoCardCss)}>
						{engagedUsersData.ctwaEngagement.percentIncrease > 0 ? (
							<FilledUpCaret
								fillColor={theme.colors.dotStatusFill}
								height={12}
								width={14}
								pathToDraw='M8.0752 1.24658C7.55424 0.497235 6.44576 0.497235 5.92481 1.24658L0.236574 9.42852C-0.36707 10.2968 0.254272 11.4855 1.31177 11.4855L12.6882 11.4855C13.7457 11.4855 14.3671 10.2968 13.7634 9.42852L8.0752 1.24658Z'
							/>
						) : (
							<FilledDownCaret
								fillColor={theme.colors.dotPendingFill}
								height={12}
								width={14}
								pathToDraw='M8.0752 10.7362C7.55424 11.4855 6.44576 11.4855 5.92481 10.7362L0.236574 2.55426C-0.36707 1.68598 0.254272 0.497235 1.31177 0.497235L12.6882 0.497235C13.7457 0.497235 14.3671 1.68598 13.7634 2.55426L8.0752 10.7362Z'
							/>
						)}

						<span style={{ fontWeight: 700 }}>{engagedUsersData.ctwaEngagement.percentIncrease}</span>
						{t('% ') +
							t('pages:marketingCampaigns.engagement.percentIncrease', {
								calenderTime:
									filterOn === DAYS_FILTER.DAYS_7
										? t('pages:marketingCampaigns.week')
										: t('pages:marketingCampaigns.month'),
							})}
					</div>
				) : (
					<div style={{ color: `${theme.colors.primaryA}`, marginTop: '4rem' }}>
						{t('pages:marketingCampaigns.engagement.noTrendAvailable')}
					</div>
				)}
			</InfoCardWithIcon>

			<InfoCardWithIcon
				title={<strong>{t('pages:marketingCampaigns.engagement.broadcasts')}</strong>}
				infoIcon={t('')}
				value={engagedUsersData?.broadcastEngagement?.engagedCount || 0}
				marginTop='2rem'
			>
				{showBroadcastPercentIncrease ? (
					<div className={css(styles.infoCardCss)}>
						{engagedUsersData.broadcastEngagement.percentIncrease > 0 ? (
							<FilledUpCaret
								fillColor={theme.colors.dotStatusFill}
								height={12}
								width={14}
								pathToDraw='M8.0752 1.24658C7.55424 0.497235 6.44576 0.497235 5.92481 1.24658L0.236574 9.42852C-0.36707 10.2968 0.254272 11.4855 1.31177 11.4855L12.6882 11.4855C13.7457 11.4855 14.3671 10.2968 13.7634 9.42852L8.0752 1.24658Z'
							/>
						) : (
							<FilledDownCaret
								fillColor={theme.colors.dotPendingFill}
								height={12}
								width={14}
								pathToDraw='M8.0752 10.7362C7.55424 11.4855 6.44576 11.4855 5.92481 10.7362L0.236574 2.55426C-0.36707 1.68598 0.254272 0.497235 1.31177 0.497235L12.6882 0.497235C13.7457 0.497235 14.3671 1.68598 13.7634 2.55426L8.0752 10.7362Z'
							/>
						)}
						<span style={{ fontWeight: 700 }}>{engagedUsersData.broadcastEngagement.percentIncrease}</span>
						{t('% ') +
							t('pages:marketingCampaigns.engagement.percentIncrease', {
								calenderTime:
									filterOn === DAYS_FILTER.DAYS_7
										? t('pages:marketingCampaigns.week')
										: t('pages:marketingCampaigns.month'),
							})}
					</div>
				) : (
					<div style={{ color: `${theme.colors.primaryA}`, marginTop: '4rem' }}>
						{t('pages:marketingCampaigns.engagement.noTrendAvailable')}
					</div>
				)}
			</InfoCardWithIcon>
		</div>
	);

	const sanitizedHtml = decodeAndSanitizeInput(
		t('pages:marketingCampaigns.engagement.engagementBySourceSublabel', {
			percent: engagedUsersData?.percentMajorEffectiveness,
			source:
				engagedUsersData?.majorEffectiveness === SOURCE_TYPE.BROADCAST
					? t('pages:marketingCampaigns.engagement.broadcast')
					: t('pages:marketingCampaigns.engagement.CTWA'),
		})
	);

	const engagementBySource = (
		<div className={css(styles.engagementBySourceWrapper)}>
			<div className={css(styles.leftSection)}>
				<ParagraphMedium className={css({ ...styles.headingCss, fontSize: '0.905rem' })}>
					{t('pages:marketingCampaigns.engagement.engagementBySource')}
				</ParagraphMedium>
				{progressBar}
			</div>

			{!isPartnerCustomer && (
				<>
					<VerticalDivider color={theme.colors.accent400} height='70%' overrides={{ marginTop: '2.2rem' }} />
					<div className={css(styles.rightSection)}>
						<ParagraphMedium className={css(styles.subHeadingCss)}>
							<span
								dangerouslySetInnerHTML={{
									__html: sanitizedHtml,
								}}
							/>
						</ParagraphMedium>
						{engagedUsersData?.majorEffectiveness === SOURCE_TYPE.BROADCAST ? (
							<CreateBroadcastBtn
								onClick={fetchAccountStatus}
								overrides={styles.buttonOverrides}
								label={t('pages:marketingCampaigns.engagement.runMoreBroadcasts')}
							/>
						) : (
							<CreateClickToWAAdBtn
								overrides={styles.buttonOverrides}
								label={t('pages:marketingCampaigns.engagement.runMoreAds')}
							/>
						)}
					</div>
				</>
			)}
		</div>
	);

	useEffect(() => {
		getAllEngagedUsers();
	}, [filterOn]);

	const getAllEngagedUsers = () => {
		setLoading(true);
		API.getAllEngagedUsers(filterOn)
			.then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					setEngagedUsersData(response.data.responseObject);
				} else {
					toaster.negative(
						<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
						{}
					);
					setEngagedUsersData([]);
				}
				setLoading(false);
			})
			.catch(() => {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return isLoading ? (
		<Loader loaderBlockHeight='100%' />
	) : (
		<>
			{engagementHeader}
			{engagementInfoCards}
			{engagedUsersData?.percentMajorEffectiveness ? engagementBySource : <></>}
		</>
	);
}

export default EngagementSummary;
