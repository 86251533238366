import { useState } from 'react';
import { useStyletron } from 'baseui';
import { Cell, Grid } from 'baseui/layout-grid';
import merge from 'lodash/merge';
import { useSelector } from 'react-redux';

import SkeletonSquareView from 'components/UI/ENGTSkeleton/ENGTSkeletonSquareView';
import { ITableColumnProps } from 'components/UI/ENGTTable/ENGTTable';
import TableRowAction, { ITableRowAction } from 'components/UI/ENGTTable/ENGTTableRowActions';

import { ROW_ACTION_SPAN_SIZE } from 'shared/consts/consts';
import { ACTIVE_CONVERSATION, NEW_REQUEST } from 'shared/consts/QAConsts';
import { BOT_MODE } from 'shared/helpers';

import { RootState } from 'store/rootReducer';

export interface ITableRowProps {
	id: any;
	row: any;
	columns: Array<ITableColumnProps>;
	actions?: Array<ITableRowAction>;
	overrides?: any;
	botMode?: string;
	isLastRow: boolean;
	boldWords?: Array<string>;
	rowToBeBold?: string;
	loader?: boolean;
	setAccordionPanelDisabled?: (value: boolean) => void;
	accordionRow?: boolean;
}
enum REQUEST_TYPE {
	NEW = 'newRequest',
	ACTIVE = 'activeRequest',
}
const defaults = {
	height: '2.5rem',
	borderBlockWidth: '1px',
};
``;
const ENGTTableRow = ({
	id,
	row,
	columns,
	actions = [],
	overrides = {},
	botMode,
	isLastRow = false,
	boldWords,
	rowToBeBold,
	loader,
	setAccordionPanelDisabled,
	accordionRow = false,
}: ITableRowProps) => {
	const [css, theme] = useStyletron();

	overrides = merge({}, defaults, overrides);

	const squareOverrideCss = {
		height: '2rem',
		width: '100%',
		override: {
			Root: {
				style: {
					marginBottom: '0.25rem',
					opacity: 0.05,
				},
			},
		},
	};

	const [isActiveRow, setActiveRow] = useState(false);
	const [isActionActive, setActionsActive] = useState(false);
	const isLiveDraftEntitlement = useSelector((state: RootState) => state.User.botAdmin.data.is_live_draft_enabled);

	const handleMouseOver = (event: any) => actions.length > 0 && setActiveRow(true);
	const handleMouseOut = (event: any) => !isActionActive && setActiveRow(false);

	const wordsToBeBold: any[] = [];
	let after: {} | null | undefined;
	const process = (handler: any, column: any, row?: any) => {
		let data = handler ? handler(column, row) : typeof column === 'boolean' ? column.toString() : column;

		const checkIfBoldWords = boldWords || [];
		if (checkIfBoldWords.length > 0) {
			checkIfBoldWords.forEach((word) => {
				if (typeof data === 'string' && data.includes(word)) {
					data = addBoldWords(data, word);
				}
			});
		}

		return data;
	};
	const addBoldWords = (data: string, word: string) => {
		const before = data.substr(0, data.indexOf('<'));
		wordsToBeBold.length = 0;
		wordsToBeBold.push(` ${word} `);
		const lastIndex = data.lastIndexOf('>');
		after = data.slice(lastIndex + 1);

		return before;
	};

	const overloadActions: any = (row: any, actions: Array<ITableRowAction>) =>
		actions.map((action) => ({
			...action,
			onClick: () => action.onClick(row),
			visible: () => (action.visible ? action.visible(row) : true),
			disable: () => (action.disable ? action.disable(row) : false),
			items: action.items ? overloadActions(row, action.items) : [],
		}));

	const renderActions = (row: any, actions: Array<ITableRowAction>) => {
		const width = `${(ROW_ACTION_SPAN_SIZE / 12) * 100}%`;

		return (
			<Cell
				key='table-row-action'
				overrides={{
					Cell: {
						style: {
							display: 'flex !important',
							justifyContent: 'flex-end',
							width,
							minWidth: width,
							maxWidth: width,
						},
					},
				}}
			>
				{actions.map((action, index) => (
					<TableRowAction
						key={`table-row-action-${index}`}
						action={action}
						isActiveRow={isActiveRow}
						setActiveRow={setActiveRow}
						setActionsActive={setActionsActive}
						visibility={
							action.visible &&
							action.visible({}) &&
							(botMode !== BOT_MODE.LIVE || !isLiveDraftEntitlement)
						}
						rowId={id}
						setAccordionDisabled={setAccordionPanelDisabled}
						accordionRow={accordionRow}
					/>
				))}
			</Cell>
		);
	};
	const setwordsToBeBoldLength = () => {
		wordsToBeBold.length = 0;
	};

	const checkIsNotActive = (colname: any) =>
		(colname?.id === 'name' && row && 'isActive' in row && !row?.isActive) || false;

	return (
		<div
			key={`table-${id}`}
			onMouseOut={handleMouseOut}
			onMouseOver={handleMouseOver}
			className={`${css({
				wordWrap: 'break-word',
				paddingTop: overrides.paddingTop || '0rem',
				paddingBottom: overrides.paddingBottom || '0rem',
				paddingLeft: overrides.paddingLeft || '0.5rem',
				paddingRight: overrides.paddingRight || '0.5rem',
				borderTopLeftRadius: isActiveRow ? '0.5rem' : '0',
				borderTopRightRadius: isActiveRow ? '0.5rem' : '0',
				borderBottomLeftRadius: isActiveRow ? '0.5rem' : '0',
				borderBottomRightRadius: isActiveRow ? '0.5rem' : '0',
				backgroundColor: accordionRow
					? 'transparent'
					: isActiveRow
						? overrides.backgroundSecondary || theme.colors.backgroundSecondary
						: overrides.backgroundPrimary || theme.colors.backgroundPrimary,
				display: 'flex',
				alignItems: 'center',
				fontWeight: id === rowToBeBold ? 550 : overrides.fontWeight || 400,
				fontStyle: 'normal',
				fontSize: '0.875rem',
				lineHeight: '1rem',
				textAlign: 'left',
				color: overrides.color || theme.colors.primaryA,
				width: 'calc( 100% - 1rem )',
				borderBottomStyle: overrides.borderBottomStyle || 'solid',
				borderBlockWidth: overrides.borderBlockWidth,
				borderBottomColor: overrides.borderBottomColor || theme.colors.backgroundSecondary,
				':last-child': {
					borderBottomStyle: overrides.hideLastRowBorder ? ('none' as const) : 'solid',
				},
			})} row-wrapper`}
		>
			<Grid
				key='table-grid'
				overrides={{
					Grid: {
						style: {
							alignItems: 'center',
							width: '100%',
							maxWidth: '100%',
							height: 'fit-content',
							minHeight: overrides.height,
							flexWrap: 'nowrap',
							marginRight: overrides.marginRight ?? 0,
							marginLeft: 0,
							borderBlockWidth:
								overrides.hideLastRowBorder && isLastRow ? '0px' : overrides.borderBlockWidth,
							borderBottomColor: theme.colors.backgroundSecondary,
						},
					},
				}}
			>
				{columns.map((column, index) => {
					const spannablePart: any =
						column?.hasOwnProperty('span') && (column.span as number) >= 0 ? column.span : 1;
					const width = `${(spannablePart / 12) * 100}%`;
					const CONVERSATION_DATA_QA = column?.id == REQUEST_TYPE.NEW ? NEW_REQUEST : ACTIVE_CONVERSATION;

					return (
						<Cell
							key={`table-column-${id}-${index}`}
							overrides={{
								Cell: {
									style: () => ({
										color: checkIsNotActive(column) ? theme.colors.accent50 : theme.colors.primaryA,
										paddingRight: '0.5rem !important',
										paddingTop: '0.375rem',
										paddingBottom: '0.375rem',
										width,
										minWidth: width,
										maxWidth: width,
										...column.overrides,
									}),
								},
							}}
						>
							<>
								{
									/* {typeof row[column.id] == 'boolean'
									? row[column.id].toString()
									: process(column.processor, row[column.id], row)} */
									loader ? (
										<SkeletonSquareView
											height={squareOverrideCss.height}
											width={squareOverrideCss.width}
											override={squareOverrideCss.override}
										/>
									) : column?.id == REQUEST_TYPE.NEW || column?.id == REQUEST_TYPE.ACTIVE ? (
										<span data-qa={CONVERSATION_DATA_QA}>
											{process(column.processor, row[column.id], row)}
										</span>
									) : (
										process(column.processor, row[column.id], row)
									)
								}
								{wordsToBeBold.length > 0 ? (
									<>
										<b>{wordsToBeBold[0]}</b>
										{after}
									</>
								) : (
									''
								)}
								{setwordsToBeBoldLength()}
							</>
						</Cell>
					);
				})}
				{!loader && actions?.length ? renderActions(row, overloadActions(row, actions)) : ''}
			</Grid>
		</div>
	);
};

export default ENGTTableRow;
