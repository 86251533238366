import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Checkbox from 'components/UI/ENGTCheckBox/ENGTCheckBox';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ModalWithSections from 'components/UI/Modal/ModalWithSections/ModalWithSections';

import { IObjProps } from 'shared/consts/types';

import { isWhatsappAcceleratorTrialEnabled } from 'store/App/User';

export interface IConfigureColumn {
	close?: any;
	checkBoxesState: IObjProps;
	setCheckBoxesState: (data: {}) => any;
	columns: IObjProps;
	isModalOpen: boolean;
	toggleModal: (data: boolean) => any;
	configureColumnApply: (data: any) => any;
}

function ConfigureBroadcastColumns(props: IConfigureColumn) {
	const { t } = useTranslation(['common']);
	const [css, theme]: [any, any] = useStyletron();

	const mandatoryColumns: any = {
		broadcastTitle: 'Broadcast Name',
		targetUserCount: 'Target Users',
		status: 'Status',
	};

	const {
		close,
		checkBoxesState,
		setCheckBoxesState,
		columns,
		isModalOpen,
		toggleModal,
		configureColumnApply,
	}: IConfigureColumn = props;

	const [configurableColumnCount, setConfigurableColumnCount] = useState(Object.keys(mandatoryColumns).length - 1);
	const [columnData, setColumnData] = useState<any>({});
	const isWhatsappAcceleratorTrial = useSelector(isWhatsappAcceleratorTrialEnabled);

	const whatsAppAcceleratorDisabled: IObjProps = {
		segmentResponse: true,
		buttonsClicked: true,
	};

	useEffect(() => {
		const configurableColumns = Object.keys(columns)
			.filter(
				(key) => !mandatoryColumns[key] && !(isWhatsappAcceleratorTrial && whatsAppAcceleratorDisabled[key])
			)
			.reduce((acc: any, column: any) => ({ ...acc, [column]: columns[column] }), {});
		setColumnData({ ...mandatoryColumns, ...configurableColumns });
	}, [columns]);

	return (
		<ModalWithSections
			confirmBtnLabel={t('common:apply')}
			isOpen={isModalOpen}
			closeBtnLabel='Reset'
			heading={t('components:configureColumn.heading')}
			onClose={() => {
				toggleModal(false);
				close && close();
			}}
			reset={() => {
				const state = {
					...checkBoxesState,
				};
				Object.keys(columns).map((column: any) => {
					if (!mandatoryColumns[column]) {
						state[column] = false;
					}
				});
				setCheckBoxesState(state);
			}}
			onConfirm={() => {
				let count = 0;
				const list: Array<string> = Object.keys(checkBoxesState);
				for (let i = 0; i < list.length; i++) {
					checkBoxesState[list[i]] && count++;
					if (count > 9) {
						toaster.negative(
							<ENGTToasterContainer
								title={t('common:error')}
								description={t('pages:broadcast.broadcastHistory.configureLimitToaster')}
							/>,
							{}
						);

						return;
					}
				}
				configureColumnApply(close);
			}}
			cancelButtonUsage
			children={
				<div>
					<div
						className={css({
							marginTop: '0rem',
							paddingBottom: '1.5rem',
							paddingTop: '1rem',
							lineHeight: '1.65rem',
							fontSize: '0.875rem',
							fontWeight: 'normal',
							textAlign: 'center',
						})}
					>
						{t('pages:broadcast.broadcastHistory.configureColumn.subHeading')}
					</div>
					<div
						className={css({
							marginLeft: '2rem',
							paddingRight: '2rem',
							paddingLeft: '2rem',
							paddingBottom: '1rem',
						})}
					>
						{Object.entries(columnData).map((column: IObjProps, index: number) => (
							<Checkbox
								key={index}
								values={{
									isChecked: checkBoxesState[column[0]],
									label: column[1],
									isDisabled: mandatoryColumns[column[0]],
								}}
								onChange={(value) => {
									const state = {
										...checkBoxesState,
									};
									state[column[0]] = value;
									setCheckBoxesState(state);
								}}
								style={{
									marginLeft: '-2rem',
									borderBottomWidth: index === configurableColumnCount ? '1px' : '',
									borderBottomColor:
										index === configurableColumnCount ? theme.colors.backgroundSecondary : '',
									borderBottomStyle: index === configurableColumnCount ? 'solid' : '',
									paddingTop: index === configurableColumnCount + 1 ? '0.5rem' : '',
								}}
								labelCss={{
									color: mandatoryColumns[column[0]] ? '' : theme.modalTextColor,
									paddingBottom: '0.5rem',
								}}
							/>
						))}
					</div>
				</div>
			}
			footerCss={{
				display: 'flex',
				justifyContent: 'space-between',
				marginTop: 'auto !important',
				marginBottom: 'auto !important',
				paddingTop: '1.25rem !important',
				paddingBottom: '1.25rem !important',
				marginRight: '2rem !important',
				marginLeft: '2rem !important',
				borderTop: `1px solid ${theme.colors.accent100}`,
			}}
			modalBodyCssOverride={{
				width: '100%',
				position: 'fixed',
				marginTop: '0rem !important',
				marginBottom: '0rem !important',
				marginRight: '0rem !important',
				marginLeft: '0rem !important',
				paddingRight: '0rem !important',
				paddingLeft: '0rem !important',
			}}
			height='34.375rem'
			width='26.375rem'
			cancelButtonCss={{
				marginTop: '25rem',
				marginRight: '0rem !important',
				width: '10.938rem',
			}}
			actionButtonCss={{
				marginTop: '25rem',
				width: '10.938rem',
			}}
		/>
	);
}

export default ConfigureBroadcastColumns;
