import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';

import store from 'store/store';

import { IObjProps } from './consts/types';
import { GA4EventTypes, LoginMethods, PlatformTypes } from './enum/trackingEnums';
import { PAYMENT_STATUS } from './enum';
import { getDeviceBrowser, getUrlParams, isMobile, isTestEmail } from './helpers';

interface IButtonClickEvent {
	gtmTrackingId: string;
	channelUsedForBroadcast?: string;
	segmentParametersSelected?: string;
	toSendCustomerData?: boolean;
	loginMethod?: LoginMethods;
	sharedProductType?: string;
}

interface ICustomizedEvent {
	gtmTrackingId: string;
	pagePath?: boolean;
	customerId?: boolean;
	location?: boolean;
	plan?: boolean;
	customerType?: boolean;
	restData?: IObjProps;
	device?: string;
	browser?: string;
	email?: boolean;
}

const customDataGenerator = (props: Omit<ICustomizedEvent, 'gtmTrackingId'>) => {
	const {
		customerId = true,
		customerType = true,
		pagePath = true,
		plan = true,
		location = true,
		device = true,
		browser = true,
		email = true,
		restData = {},
	} = props;

	const customerData = getCustomerData();
	const { isTrialCustomer } = customerData;
	const planDetails = store.getState().Plan.current.data;
	const { email: customerEmail } = store.getState().User.botAdmin.data;
	let locationDetails: IObjProps | string = store.getState().User.geoLocation || {};
	try {
		locationDetails = JSON.stringify(locationDetails);
	} catch (e) {
		console.error('Error stringifying geo location information');
	}

	const payload = {
		...(pagePath && { pagePath: window.location.href }),
		...(customerId && customerData.customerId && { customerId: customerData.customerId }),
		...(device && { device: isMobile() ? 'Mobile' : 'Desktop' }),
		...(browser && { browser: getDeviceBrowser() }),
		...(plan && { plan: isTrialCustomer ? planDetails?.trailPlanName : planDetails?.planName }),
		...(location && { geoLocation: locationDetails }),
		...(email && { customerEmail, email: customerEmail }),
		...(customerType && { customerType: isTrialCustomer ? 'Trial' : 'Paid' }),
		...restData,
	};

	return payload;
};

export const publishGAevent = (event: string, value: string, label: any = getLabel()) => {
	TagManager.dataLayer({
		dataLayer: {
			category: 'EXTERNAL',
			event,
			label: `${label}_${value}`,
		},
	});
};

export const publishGA4EventForPlg = (event: string, clickEventParams: Omit<ICustomizedEvent, 'gtmTrackingId'>) => {
	publishGA4ExternalEvent(event, clickEventParams);
};

export const publishGA4ExternalEvent = (event: string, clickEventParams: Omit<ICustomizedEvent, 'gtmTrackingId'>) => {
	const { email } = store.getState().User.botAdmin.data;
	if (isTestEmail(email)) {
		return;
	}

	TagManager.dataLayer({
		dataLayer: {
			category: 'EXTERNAL',
			event,
			...customDataGenerator(clickEventParams),
		},
	});
};

export const getLabel = () => {
	const params = getUrlParams();

	const utm_campaign = params.get('utm_campaign');
	const utm_source = params.get('utm_source');
	const utm_medium = params.get('utm_medium');

	return utm_campaign ? `${utm_campaign}_${utm_source}_${utm_medium}` : 'direct';
};

export const trackMixpanelEvent = (
	event: string,
	visitor_id: any = '',
	path: any = { 'Page Path': window.location }
) => {
	const visitor = visitor_id || getVisitorId();
	if (mixpanel) {
		if (visitor) {
			mixpanel.identify(visitor);
		}
		mixpanel.track(event, path);
	}
};

export const isNewVisitor = () => !localStorage.getItem('visitor_id');

export const isReturningVisitor = (visitor_id: string) => localStorage.getItem('visitor_id') === visitor_id;

export const checkVisitor = () => {
	const visitor_id = getUrlParams()?.get('visitor_id') || localStorage.getItem('visitor_id');
	if (visitor_id) {
		if (isNewVisitor() || !isReturningVisitor(visitor_id)) {
			setVisitorId(visitor_id);
		}
	}
};

export const setVisitorId = (visitor_id: string) => {
	localStorage.setItem('visitor_id', visitor_id);
};

export const getVisitorId = () => localStorage.getItem('visitor_id');

const getCustomerData = () => {
	const customerId = store.getState().User.botAdmin.data.cid;
	const isSandboxConfigured = store.getState().Bot.data.is_sandbox_configured;
	const isShopifyEnabled = store.getState().User.botAdmin.data.enable_shopify_shopper_assitance;
	const isWooCommerceEnabled = store.getState().User.botAdmin.data.enable_woocommerce_shopper_assitance;
	const customerPlanDetails = store.getState().Plan.current.data;
	const isTrialPlan =
		customerPlanDetails?.status === PAYMENT_STATUS.TRIAL ||
		customerPlanDetails?.status === PAYMENT_STATUS.TRIAL_ABOUT_TO_EXPIRE;
	const configuredWhatsAppNo = store.getState().Bot.data.nexmo_contact;

	const getCustomerPlatform = () => {
		const { PLATFORM, SHOPIFY, WOOCOMMERCE } = PlatformTypes;
		let customerPlatform = PLATFORM;

		if (isWooCommerceEnabled) {
			customerPlatform = WOOCOMMERCE;
		} else if (isShopifyEnabled) {
			customerPlatform = SHOPIFY;
		}

		return customerPlatform;
	};

	const customerData: IObjProps = {
		customerId,
		customerPlatorm: getCustomerPlatform(),
		isSandboxConfigured,
		isTrialCustomer: isTrialPlan,
		...(!isSandboxConfigured && configuredWhatsAppNo && { whatsAppNo: configuredWhatsAppNo }),
	};

	return customerData;
};

export const sendButtonClickEventToGA4 = (clickEventParams: IButtonClickEvent & ICustomizedEvent) => {
	const {
		gtmTrackingId,
		channelUsedForBroadcast,
		segmentParametersSelected,
		toSendCustomerData = true,
		loginMethod,
		sharedProductType,
	} = clickEventParams;

	TagManager.dataLayer({
		dataLayer: {
			gtmTrackingId,
			event: GA4EventTypes.GTM_CLICK,
			...(toSendCustomerData && getCustomerData()),
			...(channelUsedForBroadcast && { channelUsedForBroadcast }),
			...(segmentParametersSelected && { segmentParametersSelected }),
			...(loginMethod && { loginMethod }),
			...(sharedProductType && { sharedProductType }),
			...customDataGenerator(clickEventParams),
		},
	});
};

export const sendPLGButtonClickEventToGA4 = (clickEventParams: ICustomizedEvent) => {
	const { gtmTrackingId } = clickEventParams;
	const { email } = store.getState().User.botAdmin.data;
	if (isTestEmail(email)) {
		return;
	}

	TagManager.dataLayer({
		dataLayer: {
			gtmTrackingId,
			event: GA4EventTypes.GTM_CLICK,
			...customDataGenerator(clickEventParams),
		},
	});
};

export const sendPlgTimeTrackingDataToGA4 = (
	gtmTrackingId: string,
	timeSpentInSeconds: number,
	clickEventParams?: Omit<ICustomizedEvent, 'gtmTrackingId'>
) => {
	const { email } = store.getState().User.botAdmin.data;
	if (isTestEmail(email)) {
		return;
	}

	TagManager.dataLayer({
		dataLayer: {
			gtmTrackingId,
			event: GA4EventTypes.TIME_TRACKING,
			timeSpentInSeconds,
			...customDataGenerator(clickEventParams || {}),
		},
	});
};

export const sendTimeTrackingDataToGA4 = (gtmTrackingId: string, timeSpentInSeconds: number) => {
	TagManager.dataLayer({
		dataLayer: {
			gtmTrackingId,
			event: GA4EventTypes.TIME_TRACKING,
			timeSpentInSeconds,
			...getCustomerData(),
		},
	});
};

export const sendPageViewDataToGA4 = (path: string, data?: ICustomizedEvent) => {
	TagManager.dataLayer({
		dataLayer: {
			event: GA4EventTypes.PORTAL_PAGE_VIEW,
			pagePath: path,
			...getCustomerData(),
			...customDataGenerator(data || {}),
		},
	});
};
