import { RootState } from 'store/rootReducer';

export const publishedBroadcastList = (state: RootState) => state.Broadcast.published;

export const broadcastStatistics = (state: RootState) => state.Broadcast.statistics.data;

export const scheduledBroadcastList = (state: RootState) => state.Broadcast.scheduled;

export const draftBroadcastList = (state: RootState) => state.Broadcast.draft;

export const publishedFilters = (state: RootState) => state.Broadcast.published.filters;

export const publishedSort = (state: RootState) => state.Broadcast.published.sort;

export const numberMessageLimit = (state: RootState) => state.Broadcast.messageLimit;

export const messageTemplatesSelector = (state: RootState) => state.Broadcast.messageTemplates;
