import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';
import { API_GATEWAY_URL } from 'shared/consts/consts';

const getPromoteDetails = '/api/v1/get_messenger_code/';
const getFbMenu = '/page/fb_menu';
const saveFbMenu = '/set_fb_menu';
const fbDetails = '/list_of_fb_pages';
const changeFbBot = '/create_fb_instance_bot';
const disableFbBot = '/api/v1/disable_fb_bot';
const fbLogout = '/logout_fb';
const fbSignIn = '/messenger/fb_oath/';
const linkFacebookPagesApi = '/add_facebook_pages';
const enableFacebookPageApi = '/api/v1/enable_fb_bot';
const deleteFacebookPageApi = '/api/v1/delete_fb_bot';
const fbSettings = '/fb_settings';
const subscribedApps = '/subscribed_apps';
const genericProxyRequest = '/v2/genericProxyRequest/';

const getPromoteDetailsAPI = new BaseService(getPromoteDetails);
const getFbMenuAPI = new BaseService(getFbMenu);
const saveFbAPI = new BaseService(saveFbMenu);
const fbDetailsAPI = new BaseService(fbDetails);
const changeFbBotAPI = new BaseService(changeFbBot);
const disableFbBotAPI = new BaseService(disableFbBot);
const fbLogoutAPI = new BaseService(fbLogout);
const fbSignInAPI = new BaseService(fbSignIn);
const linkFacebookPages = new BaseService(linkFacebookPagesApi);
const enableFacebookPage = new BaseService(enableFacebookPageApi);
const removeFacebookPage = new BaseService(deleteFacebookPageApi);
const fbSettingsApi = new BaseService(fbSettings);
const subscribedAppsApi = new BaseService(subscribedApps);
const genericProxyRequestAPI = new BaseService(genericProxyRequest);
const apiGatewayRequest = new BaseService(API_GATEWAY_URL);

export const API = {
	getPromoteDetails(selected_page_id) {
		const params = { page_id: selected_page_id };

		return getPromoteDetailsAPI.getRequest(serialize(params, '?'));
	},
	getFacebookMenu(pageId) {
		const params = { page_id: pageId };

		return getFbMenuAPI.getRequest(serialize(params, '?'));
	},
	saveFacebookMenu(buttonList) {
		const fb_menu = {
			persistent_menu: [
				{
					locale: 'default',
					composer_input_disabled: false,
					call_to_actions: buttonList,
				},
			],
		};

		return saveFbAPI.postRequest(fb_menu);
	},
	fetchAvailablePages() {
		return fbDetailsAPI.getRequest();
	},
	changeFbBot(data) {
		return changeFbBotAPI.postRequest(data);
	},
	disableOnLinkedPages(selected_page_id) {
		const data = { page_id: selected_page_id };

		return disableFbBotAPI.postRequest(data);
	},
	logoutFb(bot_ref, removeBots) {
		const params = {
			bot_key: bot_ref,
			remove_bots: removeBots,
		};

		return fbLogoutAPI.getRequest(serialize(params, '?'));
	},
	fbSignIn(data) {
		return fbSignInAPI.postRequest(data);
	},
	linkFacebookPages(payload) {
		return linkFacebookPages.postRequest(payload);
	},
	enableFacebookPage(payload) {
		return enableFacebookPage.postRequest(payload);
	},
	removeFacebookPage(payload) {
		return removeFacebookPage.postRequest(payload);
	},

	updateMediaSettings: (payload, pageId) => {
		const params = {
			pageId,
		};

		return fbSettingsApi.putRequest(payload, serialize(params, '?'));
	},

	getMediaSettings: (pageId) => {
		const params = {
			pageId,
		};

		return fbSettingsApi.getRequest(serialize(params, '?'));
	},

	getSubscribedApps: (pageId, accessToken) => {
		const params = {
			access_token: accessToken,
			page_id: pageId,
		};

		return subscribedAppsApi.getRequest(serialize(params, '?'));
	},

	getAdAuthToken: () => {
		const params = {
			targetApi: 'adAuthToken',
		};

		return genericProxyRequestAPI.getRequest(serialize(params, '?'));
	},

	getBotMetaConfig: () => {
		const params = {
			targetApi: 'getBotMetaConfig',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},

	deleteAdAuthToken: () => {
		const params = {
			targetApi: 'adAuthToken',
		};

		return genericProxyRequestAPI.deleteRequest(serialize(params, '?'));
	},

	saveBotMetaConfig: (data) => {
		const params = {
			targetApi: 'adAuthToken',
		};

		return genericProxyRequestAPI.postRequest(data, serialize(params, '?'));
	},
	getBusinessAccounts: () => {
		const params = {
			targetApi: 'getBusinessAccountsOfFBM',
			after: '',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	getAdAccounts: (metaBusinessId) => {
		const params = {
			targetApi: 'getAvailableAdsAccounts',
			after: '',
			replaceable_params: {
				metaBusinessId,
			},
		};

		return genericProxyRequestAPI.getRequest(serialize(params, '?'));
	},
};
