import React from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';

import { ListItemWithIcon } from 'components/UI/ListItemWithIcon/ListItemWithIcon';

import FilledConfigureIcon from 'shared/icons/FilledConfigureIcon';

const AccountSettings = ({ onClose }: { onClose?: () => void }) => {
	const [, theme]: [any, any] = useStyletron();
	const { t }: any = useTranslation(['common']);

	const configureIcon = () => <FilledConfigureIcon fill={theme.colors.navIconFillColor} />;

	return (
		<ListItemWithIcon
			handleClick={onClose}
			iconElement={configureIcon}
			itemLabel={t('common:account_settings')}
			linkTo='/account-settings/profile'
		/>
	);
};

export default AccountSettings;
