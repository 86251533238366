import React from 'react';

const CloseIconLite = ({ fillColor, height, width }: { fillColor: string; height: string; width: string }) => (
	<svg width={width || 40} height={height || 40} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M15.0505 13.636L13.6363 15.0503L18.5858 19.9998L13.6361 24.9496L15.0503 26.3638L20 21.414L24.95 26.364L26.3642 24.9497L21.4143 19.9998L26.364 15.0501L24.9498 13.6358L20 18.5856L15.0505 13.636Z'
			fill={fillColor}
		/>
	</svg>
);

export default CloseIconLite;
