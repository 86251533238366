import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useNavigate } from 'react-router-dom';

import LeftArrowIcon from 'shared/icons/LeftArrowIcon';

export interface IGotoPreviousScreenProps {
	/**
	 * heading represents main page heading after the left arrow
	 */
	heading: string | React.ReactNode;
	/**
	 * previousScreenUrl prop represents the url to which it redirects on clicking of the left arrow
	 * If the previousScreenUrl is not being sent then loads the previous URL in the history list. ie. history.goBack()
	 */
	previousScreenUrl?: string;
	dataQa?: string;
	onBack?: Function;
}

const GotoPreviousScreen = ({ previousScreenUrl, heading, dataQa, onBack }: IGotoPreviousScreenProps) => {
	const [css, theme] = useStyletron();

	const navigate = useNavigate();

	const headingCss = {
		fontSize: '1rem',
		lineHeight: '1.25rem',
		fontWeight: 500,
		marginTop: '0.1875rem',
		color: theme.colors.primaryA,
	};

	return (
		<Block display='flex' paddingLeft='0rem' paddingRight='1rem' alignItems='center' marginBottom='0.625rem'>
			<div
				className={css({
					display: 'flex',
					justifyContent: 'left',
					alignItems: 'center',
					cursor: 'pointer',
				})}
				onClick={() => {
					if (onBack) {
						onBack();
					} else if (previousScreenUrl) {
						navigate(previousScreenUrl);
					} else {
						navigate(-1);
					}
				}}
				data-qa={dataQa}
			>
				<LeftArrowIcon
					className={css({ verticalAlign: 'middle', marginRight: '0.75rem' })}
					fillColor={theme.colors.primaryA}
					height='1rem'
					width='1rem'
				/>

				<Block className={css(headingCss)} paddingLeft='0rem'>
					{heading}
				</Block>
			</div>
		</Block>
	);
};

export default GotoPreviousScreen;
