import { useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { PLACEMENT, Popover } from 'baseui/popover';

import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ENGTInput, { IENGTInputProps } from 'components/UI/ENGTInput/ENGTInput';
import NewPasswordWidget from 'components/UI/NewPasswordWidget/NewPasswordWidget';
import LoginInputError from 'components/UI/Validation/LoginInputError';

import RegistrationPasswordSuccessCheck from 'shared/icons/RegistrationPasswordSuccessCheck.svg';

import PasswordWidget from './PasswordWidget';

export interface IENGTPasswordProps {
	isPopverWidget?: boolean;
	isPasswordWidgetOpen?: boolean;
	isNewPasswordWidgetOpen?: boolean;
	showErrorMessage?: boolean;
	validPassword?: boolean;
	errorText?: string;
	password?: any;
}

function ENGTPassword(props: IENGTPasswordProps & IENGTInputProps) {
	const [css, theme] = useStyletron();
	const wrapperDiv = useRef<HTMLDivElement>(null);
	const [position, setposition] = useState('topLeft');

	const {
		isPopverWidget = false,
		isPasswordWidgetOpen = false,
		isNewPasswordWidgetOpen = false,
		password = '',
		showErrorMessage = false,
		validPassword = false,
		errorText = '',
	} = props;

	const iconCss: any = {
		top: '1rem',
		position: 'absolute',
		display: 'inline-block',
	};

	if (isPopverWidget) {
		return (
			<div ref={wrapperDiv}>
				<Popover
					isOpen={isPasswordWidgetOpen}
					content={
						<PasswordWidget
							value={password}
							wrapperCss={{
								marginBottom: 0,
								borderRadius: position === 'topLeft' ? '0.5rem 0.5rem 0 0' : '0 0 0.5rem 0.5rem',
							}}
						/>
					}
					placement={PLACEMENT.bottomLeft}
					overrides={{
						Body: {
							style: ({ $placement }) => {
								if ($placement !== position) {
									setposition($placement || 'topLeft');
								}

								return {
									width: `${wrapperDiv.current?.clientWidth}px`,
									boxShadow: '0',
								};
							},
						},
					}}
					popoverMargin={0}
					renderAll
					ignoreBoundary
				>
					<div className={css({ position: 'relative', display: 'inline-block', width: '100%' })}>
						<ENGTInput type='password' isPasswordWidgetOpen={isNewPasswordWidgetOpen} {...props} />
					</div>
					{validPassword ? (
						<div className={css({ height: '1.125rem', ...iconCss })}>
							<ENGTImage lazy src={RegistrationPasswordSuccessCheck} width={21} />
						</div>
					) : (
						<div
							className={css({
								left: '100%',
								...iconCss,
							})}
						>
							{isNewPasswordWidgetOpen && <NewPasswordWidget value={password} />}
							{showErrorMessage && <LoginInputError text={errorText} />}
						</div>
					)}
				</Popover>
			</div>
		);
	}

	return (
		<>
			<div className={css({ position: 'relative', display: 'inline-block', width: '100%' })}>
				<ENGTInput type='password' isPasswordWidgetOpen={isNewPasswordWidgetOpen} {...props} />
			</div>
			{validPassword ? (
				<div className={css({ height: '1.125rem', ...iconCss })}>
					<ENGTImage lazy src={RegistrationPasswordSuccessCheck} width={21} />
				</div>
			) : (
				<div
					className={css({
						left: '100%',
						...iconCss,
					})}
				>
					{isNewPasswordWidgetOpen && <NewPasswordWidget value={password} />}
					{showErrorMessage && <LoginInputError text={errorText} />}
				</div>
			)}

			{isPasswordWidgetOpen && <PasswordWidget value={password} />}
		</>
	);
}

export default ENGTPassword;
