import { NAVIGATIONAL_HELPER_LIVECHAT_ACTIONS } from 'shared/consts/QAConsts';

const NavigationalHelperLiveChatActions = ({
	className,
	size,
	fillColor,
}: {
	className?: string;
	size?: number;
	fillColor?: string;
}) => (
	<svg
		width={size || 256}
		height={size || 132}
		viewBox='0 0 256 132'
		className={className}
		data-qa={NAVIGATIONAL_HELPER_LIVECHAT_ACTIONS}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M0 8C0 3.58172 3.58172 0 8 0H248C252.418 0 256 3.58172 256 8V132H0V8Z' fill='white' />
		<g opacity='0.4' filter='url(#filter0_b_32961_18839)'>
			<rect x='52' y='73' width='36' height='36' rx='8' fill='#E8E6E7' />
		</g>
		<g opacity='0.4' filter='url(#filter1_b_32961_18839)'>
			<rect x='111' y='73' width='36' height='36' rx='8' fill='#E8E6E7' />
		</g>
		<g opacity='0.4' filter='url(#filter2_b_32961_18839)'>
			<rect x='169' y='73' width='36' height='36' rx='8' fill='#E8E6E7' />
		</g>
		<g opacity='0.4' filter='url(#filter3_b_32961_18839)'>
			<rect x='140' y='23' width='36' height='36' rx='8' fill='#E8E6E7' />
		</g>
		<g opacity='0.4' filter='url(#filter4_b_32961_18839)'>
			<rect x='81' y='23' width='36' height='36' rx='8' fill='#E8E6E7' />
		</g>
		<rect x='94.2441' y='40.3105' width='2.71765' height='2.03824' fill={fillColor || '#E8E6E7'} />
		<mask id='path-8-inside-1_32961_18839' fill='white'>
			<rect x='103.756' y='29.7124' width='6.79412' height='6.79412' rx='1.29412' />
		</mask>
		<rect
			x='103.756'
			y='29.7124'
			width='6.79412'
			height='6.79412'
			rx='1.29412'
			fill={fillColor || '#AE1536'}
			stroke={fillColor || '#AE1536'}
			strokeWidth='3.88235'
			mask='url(#path-8-inside-1_32961_18839)'
		/>
		<mask id='path-9-inside-2_32961_18839' fill='white'>
			<rect x='103.756' y='46.0181' width='6.79412' height='6.79412' rx='1.29412' />
		</mask>
		<rect
			x='103.756'
			y='46.0181'
			width='6.79412'
			height='6.79412'
			rx='1.29412'
			fill={fillColor || '#AE1536'}
			stroke={fillColor || '#AE1536'}
			strokeWidth='3.88235'
			mask='url(#path-9-inside-2_32961_18839)'
		/>
		<mask id='path-10-inside-3_32961_18839' fill='white'>
			<rect x='96.9617' y='37.8657' width='6.79412' height='6.79412' rx='1.29412' />
		</mask>
		<rect
			x='96.9617'
			y='37.8657'
			width='6.79412'
			height='6.79412'
			rx='1.29412'
			fill={fillColor || '#AE1536'}
			stroke={fillColor || '#AE1536'}
			strokeWidth='3.88235'
			mask='url(#path-10-inside-3_32961_18839)'
		/>
		<mask id='path-11-inside-4_32961_18839' fill='white'>
			<rect x='87.45' y='37.8657' width='6.79412' height='6.79412' rx='1.29412' />
		</mask>
		<rect
			x='87.45'
			y='37.8657'
			width='6.79412'
			height='6.79412'
			rx='1.29412'
			fill={fillColor || '#AE1536'}
			stroke={fillColor || '#AE1536'}
			strokeWidth='3.88235'
			mask='url(#path-11-inside-4_32961_18839)'
		/>
		<rect x='101.038' y='32.4302' width='2.71765' height='2.03824' fill={fillColor || '#AE1536'} />
		<path
			d='M99.2717 37.8647V34.4677C99.2717 33.342 100.184 32.4295 101.31 32.4295V37.8647H99.2717Z'
			fill={fillColor || '#AE1536'}
		/>
		<rect
			width='2.71765'
			height='2.03824'
			transform='matrix(1 0 0 -1 101.038 50.0947)'
			fill={fillColor || '#AE1536'}
		/>
		<path
			d='M99.2717 44.6602V48.0572C99.2717 49.1829 100.184 50.0954 101.31 50.0954V44.6602H99.2717Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			d='M162.224 48.2314C162.185 48.4545 162.158 48.6777 162.158 48.9139V51.8279C162.158 53.088 161.121 54.1249 159.861 54.1249H156.58C155.477 54.1249 154.283 53.2849 154.283 51.4472V49.124C154.283 48.8221 154.257 48.5202 154.191 48.2314L154.283 48.2183H162.158L162.224 48.2314Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			opacity='0.7'
			fillRule='evenodd'
			clipRule='evenodd'
			d='M163.904 45.5932C166.122 43.8343 167.409 41.2091 167.409 38.3739C167.409 35.5912 166.175 32.9922 164.009 31.2334C161.857 29.4876 159.021 28.805 156.252 29.3957C152.773 30.1176 149.951 32.9529 149.229 36.4312C148.494 40.0015 149.833 43.5455 152.695 45.7113C153.469 46.2888 154.007 47.1945 154.191 48.2315L154.283 48.2184H162.158L162.224 48.2315C162.408 47.2339 162.998 46.302 163.904 45.5932ZM162.158 38.046C162.158 38.5894 162.599 39.0304 163.143 39.0304C163.686 39.0304 164.127 38.5894 164.127 38.046C164.127 34.9706 161.624 32.4674 158.549 32.4674C158.005 32.4674 157.564 32.9085 157.564 33.4519C157.564 33.9953 158.005 34.4363 158.549 34.4363C160.54 34.4363 162.158 36.0548 162.158 38.046Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M67.6357 80.5C67.6357 79.0526 68.8133 77.875 70.2607 77.875C71.7081 77.875 72.8857 79.0526 72.8857 80.5C72.8857 81.9474 71.7081 83.125 70.2607 83.125C68.8133 83.125 67.6357 81.9474 67.6357 80.5ZM70.7352 83.8357C72.0098 83.8357 73.2106 84.3422 74.1164 85.2619C75.0177 86.1771 75.5141 87.3852 75.5141 88.6636C75.5141 88.8667 75.3495 89.0312 75.1465 89.0312H64.8534C64.6504 89.0312 64.4858 88.8667 64.4858 88.6636C64.4858 87.3852 64.9822 86.1771 65.8836 85.2619C66.7894 84.3422 67.9902 83.8357 69.2648 83.8357H70.7352Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			d='M74.1164 85.2619L73.7601 85.6128L73.7601 85.6128L74.1164 85.2619ZM65.8836 85.2619L66.2398 85.6128L66.2398 85.6128L65.8836 85.2619ZM70.2607 77.375C68.5371 77.375 67.1357 78.7764 67.1357 80.5H68.1357C68.1357 79.3287 69.0894 78.375 70.2607 78.375V77.375ZM73.3857 80.5C73.3857 78.7764 71.9843 77.375 70.2607 77.375V78.375C71.432 78.375 72.3857 79.3287 72.3857 80.5H73.3857ZM70.2607 83.625C71.9843 83.625 73.3857 82.2236 73.3857 80.5H72.3857C72.3857 81.6713 71.432 82.625 70.2607 82.625V83.625ZM67.1357 80.5C67.1357 82.2236 68.5371 83.625 70.2607 83.625V82.625C69.0894 82.625 68.1357 81.6713 68.1357 80.5H67.1357ZM74.4726 84.9111C73.4742 83.8973 72.1456 83.3357 70.7352 83.3357V84.3357C71.874 84.3357 72.947 84.7871 73.7601 85.6128L74.4726 84.9111ZM76.0141 88.6636C76.0141 87.2532 75.4656 85.9193 74.4726 84.9111L73.7601 85.6128C74.5698 86.4349 75.0141 87.5172 75.0141 88.6636H76.0141ZM75.1465 89.5312C75.6257 89.5312 76.0141 89.1428 76.0141 88.6636H75.0141C75.0141 88.5905 75.0734 88.5312 75.1465 88.5312V89.5312ZM64.8534 89.5312H75.1465V88.5312H64.8534V89.5312ZM63.9858 88.6636C63.9858 89.1428 64.3743 89.5312 64.8534 89.5312V88.5312C64.9266 88.5312 64.9858 88.5905 64.9858 88.6636H63.9858ZM65.5273 84.9111C64.5344 85.9193 63.9858 87.2532 63.9858 88.6636H64.9858C64.9858 87.5172 65.4301 86.4349 66.2398 85.6128L65.5273 84.9111ZM69.2648 83.3357C67.8543 83.3357 66.5258 83.8973 65.5273 84.9111L66.2398 85.6128C67.053 84.7871 68.126 84.3357 69.2648 84.3357V83.3357ZM70.7352 83.3357H69.2648V84.3357H70.7352V83.3357Z'
			fill={fillColor || '#AE1536'}
		/>
		<rect x='64.5' y='84.5' width='1' height='1.5' fill={fillColor || '#AE1536'} />
		<rect x='76.5625' y='92.3125' width='1.3125' height='2.625' fill={fillColor || '#AE1536'} />
		<rect
			width='1.3125'
			height='2.625'
			transform='matrix(1 0 1.11209e-09 -1 62.125 94.9375)'
			fill={fillColor || '#AE1536'}
		/>
		<path
			d='M70 91L76.5625 91C77.2874 91 77.875 91.5876 77.875 92.3125L70 92.3125L70 91Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			d='M70 91L63.4375 91C62.7126 91 62.125 91.5876 62.125 92.3125L70 92.3125L70 91Z'
			fill={fillColor || '#AE1536'}
		/>
		<g opacity='0.7'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M74.5938 97.5625C74.5938 96.1151 75.7713 94.9375 77.2188 94.9375C78.6662 94.9375 79.8438 96.1151 79.8438 97.5625C79.8438 98.3956 79.4537 99.1392 78.8466 99.6205C79.7073 99.7886 80.4996 100.19 81.138 100.792C81.9961 101.601 82.4688 102.67 82.4688 103.8C82.4688 103.979 82.312 104.125 82.1187 104.125H72.3187C72.1255 104.125 71.9688 103.979 71.9688 103.8C71.9688 102.67 72.4414 101.601 73.2995 100.792C73.9379 100.19 74.7302 99.7886 75.5909 99.6205C74.9838 99.1392 74.5938 98.3956 74.5938 97.5625Z'
				fill={fillColor || '#AE1536'}
			/>
			<path
				d='M78.8466 99.6205L78.6602 99.3854L78.1497 99.79L78.7891 99.9149L78.8466 99.6205ZM81.138 100.792L80.9321 101.011V101.011L81.138 100.792ZM73.2995 100.792L73.5054 101.011L73.5054 101.011L73.2995 100.792ZM75.5909 99.6205L75.6484 99.9149L76.2878 99.79L75.7773 99.3854L75.5909 99.6205ZM77.2188 94.6375C75.6056 94.6375 74.2938 95.9494 74.2938 97.5625H74.8937C74.8937 96.2808 75.937 95.2375 77.2188 95.2375V94.6375ZM80.1438 97.5625C80.1438 95.9494 78.8319 94.6375 77.2188 94.6375V95.2375C78.5005 95.2375 79.5437 96.2808 79.5437 97.5625H80.1438ZM79.0329 99.8556C79.7085 99.3201 80.1438 98.4914 80.1438 97.5625H79.5437C79.5437 98.2998 79.1989 98.9584 78.6602 99.3854L79.0329 99.8556ZM81.3438 100.574C80.6634 99.9324 79.8194 99.5048 78.9041 99.326L78.7891 99.9149C79.5952 100.072 80.3358 100.448 80.9321 101.011L81.3438 100.574ZM82.7688 103.8C82.7688 102.583 82.2591 101.437 81.3438 100.574L80.9321 101.011C81.7332 101.766 82.1687 102.756 82.1687 103.8H82.7688ZM82.1187 104.425C82.4564 104.425 82.7688 104.166 82.7688 103.8H82.1687C82.1687 103.801 82.1687 103.801 82.1682 103.802C82.1676 103.803 82.1659 103.806 82.1621 103.81C82.1542 103.817 82.1396 103.825 82.1187 103.825V104.425ZM72.3187 104.425H82.1187V103.825H72.3187V104.425ZM71.6687 103.8C71.6687 104.166 71.9811 104.425 72.3187 104.425V103.825C72.2979 103.825 72.2833 103.817 72.2754 103.81C72.2716 103.806 72.2699 103.803 72.2693 103.802C72.2688 103.801 72.2688 103.801 72.2688 103.8H71.6687ZM73.0937 100.574C72.1784 101.437 71.6687 102.583 71.6687 103.8H72.2688C72.2688 102.756 72.7043 101.766 73.5054 101.011L73.0937 100.574ZM75.5334 99.326C74.6181 99.5048 73.7741 99.9324 73.0937 100.574L73.5054 101.011C74.1017 100.448 74.8423 100.072 75.6484 99.9149L75.5334 99.326ZM74.2938 97.5625C74.2938 98.4914 74.729 99.3201 75.4046 99.8556L75.7773 99.3854C75.2386 98.9584 74.8937 98.2998 74.8937 97.5625H74.2938Z'
				fill={fillColor || '#AE1536'}
			/>
		</g>
		<g opacity='0.7'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M60.1562 97.5625C60.1562 96.1151 61.3338 94.9375 62.7812 94.9375C64.2287 94.9375 65.4062 96.1151 65.4062 97.5625C65.4062 98.3956 65.0162 99.1392 64.4091 99.6205C65.2698 99.7886 66.0621 100.19 66.7005 100.792C67.5586 101.601 68.0312 102.67 68.0312 103.8C68.0312 103.979 67.8745 104.125 67.6812 104.125H57.8812C57.688 104.125 57.5312 103.979 57.5312 103.8C57.5312 102.67 58.0039 101.601 58.862 100.792C59.5004 100.19 60.2927 99.7886 61.1534 99.6205C60.5463 99.1392 60.1562 98.3956 60.1562 97.5625Z'
				fill={fillColor || '#AE1536'}
			/>
			<path
				d='M64.4091 99.6205L64.2227 99.3854L63.7122 99.79L64.3516 99.9149L64.4091 99.6205ZM66.7005 100.792L66.4946 101.011V101.011L66.7005 100.792ZM58.862 100.792L59.0679 101.011L59.0679 101.011L58.862 100.792ZM61.1534 99.6205L61.2109 99.9149L61.8503 99.79L61.3398 99.3854L61.1534 99.6205ZM62.7812 94.6375C61.1681 94.6375 59.8563 95.9494 59.8563 97.5625H60.4562C60.4562 96.2808 61.4995 95.2375 62.7812 95.2375V94.6375ZM65.7063 97.5625C65.7063 95.9494 64.3944 94.6375 62.7812 94.6375V95.2375C64.063 95.2375 65.1062 96.2808 65.1062 97.5625H65.7063ZM64.5954 99.8556C65.271 99.3201 65.7063 98.4914 65.7063 97.5625H65.1062C65.1062 98.2998 64.7614 98.9584 64.2227 99.3854L64.5954 99.8556ZM66.9063 100.574C66.2259 99.9324 65.3819 99.5048 64.4666 99.326L64.3516 99.9149C65.1577 100.072 65.8983 100.448 66.4946 101.011L66.9063 100.574ZM68.3313 103.8C68.3313 102.583 67.8216 101.437 66.9063 100.574L66.4946 101.011C67.2957 101.766 67.7312 102.756 67.7312 103.8H68.3313ZM67.6812 104.425C68.0189 104.425 68.3313 104.166 68.3313 103.8H67.7312C67.7312 103.801 67.7312 103.801 67.7307 103.802C67.7301 103.803 67.7284 103.806 67.7246 103.81C67.7167 103.817 67.7021 103.825 67.6812 103.825V104.425ZM57.8812 104.425H67.6812V103.825H57.8812V104.425ZM57.2312 103.8C57.2312 104.166 57.5436 104.425 57.8812 104.425V103.825C57.8604 103.825 57.8458 103.817 57.8379 103.81C57.8341 103.806 57.8324 103.803 57.8318 103.802C57.8313 103.801 57.8313 103.801 57.8313 103.8H57.2312ZM58.6562 100.574C57.7409 101.437 57.2312 102.583 57.2312 103.8H57.8313C57.8313 102.756 58.2668 101.766 59.0679 101.011L58.6562 100.574ZM61.0959 99.326C60.1806 99.5048 59.3366 99.9324 58.6562 100.574L59.0679 101.011C59.6642 100.448 60.4048 100.072 61.2109 99.9149L61.0959 99.326ZM59.8563 97.5625C59.8563 98.4914 60.2915 99.3201 60.9671 99.8556L61.3398 99.3854C60.8011 98.9584 60.4562 98.2998 60.4562 97.5625H59.8563Z'
				fill={fillColor || '#AE1536'}
			/>
		</g>
		<path
			d='M137.125 88.4226L137.023 88.3863L136.625 87.4357L136.668 87.338C137.875 84.6018 137.791 84.5202 137.591 84.3218L135.982 82.7519C135.916 82.6867 135.801 82.6408 135.701 82.6408C135.611 82.6408 135.336 82.6408 132.96 83.7172L132.864 83.76L131.884 83.3651L131.844 83.2675C130.727 80.5 130.612 80.5 130.334 80.5H128.061C127.784 80.5 127.658 80.5 126.622 83.2723L126.583 83.3732L125.607 83.7709L125.514 83.7321C123.905 83.0539 122.966 82.71 122.721 82.71C122.622 82.71 122.506 82.7546 122.44 82.8197L120.829 84.3941C120.625 84.5956 120.54 84.68 121.809 87.3591L121.856 87.4584L121.457 88.408L121.36 88.4457C118.527 89.5403 118.527 89.647 118.527 89.9321V92.1591C118.527 92.445 118.527 92.5637 121.365 93.5807L121.467 93.6166L121.866 94.5634L121.823 94.6601C120.616 97.3975 120.693 97.4716 120.898 97.6759L122.505 99.2479C122.572 99.3127 122.689 99.3589 122.788 99.3589C122.878 99.3589 123.152 99.3589 125.53 98.283L125.626 98.2384L126.607 98.6353L126.645 98.7334C127.763 101.5 127.878 101.5 128.157 101.5H130.43C130.715 101.5 130.834 101.5 131.871 98.7258L131.91 98.6248L132.887 98.2298L132.98 98.2678C134.587 98.9479 135.526 99.2911 135.769 99.2911C135.868 99.2911 135.985 99.2479 136.052 99.1814L137.665 97.6036C137.868 97.401 137.953 97.3181 136.681 94.6416L136.634 94.5411L137.032 93.5956L137.127 93.5584C139.964 92.4581 139.964 92.3508 139.964 92.0654V89.8397C139.964 89.5539 139.964 89.4359 137.125 88.4226ZM129.245 94.6118C127.214 94.6118 125.562 92.9911 125.562 91.0004C125.562 89.0097 127.214 87.3911 129.245 87.3911C131.276 87.3911 132.928 89.01 132.928 91.0004C132.928 92.9907 131.276 94.6118 129.245 94.6118Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			opacity='0.7'
			d='M186.694 102.156V99.6041H186.373C184.97 99.6041 183.828 98.4624 183.828 97.0592C183.828 95.6559 184.97 94.5143 186.373 94.5143H186.694V91.9623H183.507C183.157 91.9623 182.873 91.6783 182.873 91.3279V90.3727C182.873 89.6691 182.3 89.0967 181.597 89.0967C180.893 89.0967 180.321 89.6691 180.321 90.3727V91.3279C180.321 91.6783 180.037 91.9623 179.686 91.9623H176.5V100.88C176.5 101.584 177.072 102.156 177.776 102.156H186.694Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			opacity='0.7'
			d='M196.88 102.156C197.584 102.156 198.156 101.584 198.156 100.88V91.9624H195.604V92.2831C195.604 93.6864 194.463 94.828 193.059 94.828C191.656 94.828 190.514 93.6864 190.514 92.2831V91.9624H187.963V95.1488C187.963 95.4992 187.679 95.7833 187.328 95.7833H186.373C185.669 95.7833 185.097 96.3557 185.097 97.0592C185.097 97.7628 185.669 98.3352 186.373 98.3352H187.328C187.679 98.3352 187.963 98.6193 187.963 98.9697V102.156H196.88Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			opacity='0.7'
			d='M193.059 93.5593C193.763 93.5593 194.335 92.9869 194.335 92.2833V91.3281C194.335 90.9777 194.62 90.6937 194.97 90.6937H198.156V81.776C198.156 81.0724 197.584 80.5 196.88 80.5H187.963V83.0519H188.283C189.687 83.0519 190.828 84.1936 190.828 85.5968C190.828 87.0001 189.687 88.1417 188.283 88.1417H187.963V90.6937H191.149C191.499 90.6937 191.783 90.9777 191.783 91.3281V92.2833C191.783 92.9869 192.356 93.5593 193.059 93.5593Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			d='M177.776 80.5C177.072 80.5 176.5 81.0724 176.5 81.776V90.6937H179.052V90.3729C179.052 88.9697 180.194 87.828 181.597 87.828C183 87.828 184.142 88.9697 184.142 90.3729V90.6937H186.694V87.5073C186.694 87.1569 186.978 86.8728 187.328 86.8728H188.283C188.987 86.8728 189.559 86.3004 189.559 85.5968C189.559 84.8933 188.987 84.3208 188.283 84.3208H187.328C186.978 84.3208 186.694 84.0368 186.694 83.6864V80.5H177.776Z'
			fill={fillColor || '#AE1536'}
		/>
		<defs>
			<filter
				id='filter0_b_32961_18839'
				x='48'
				y='69'
				width='44'
				height='44'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
				<feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_32961_18839' />
				<feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_32961_18839' result='shape' />
			</filter>
			<filter
				id='filter1_b_32961_18839'
				x='107'
				y='69'
				width='44'
				height='44'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
				<feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_32961_18839' />
				<feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_32961_18839' result='shape' />
			</filter>
			<filter
				id='filter2_b_32961_18839'
				x='165'
				y='69'
				width='44'
				height='44'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
				<feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_32961_18839' />
				<feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_32961_18839' result='shape' />
			</filter>
			<filter
				id='filter3_b_32961_18839'
				x='136'
				y='19'
				width='44'
				height='44'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
				<feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_32961_18839' />
				<feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_32961_18839' result='shape' />
			</filter>
			<filter
				id='filter4_b_32961_18839'
				x='77'
				y='19'
				width='44'
				height='44'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
				<feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_32961_18839' />
				<feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_32961_18839' result='shape' />
			</filter>
		</defs>
	</svg>
);

export default NavigationalHelperLiveChatActions;
