import { toaster } from 'baseui/toast';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { GROUP_CONVERSATION_STATES, MESSAGE_STATE_MAP, MESSAGE_TAB_HEADERS_MAP } from 'shared/consts/consts';

import { jitsiActions } from 'store/App/Jitsi';
import { addNotificationAction } from 'store/App/Notifications';
import { playSoundNotification } from 'store/App/Preferences';
import {
	getFacetsSortFromLs,
	getLiveChatUsersAction,
	removeSelectedUserFromListAction,
	setUserListAction,
	updateUserListAndSelectedUserAction,
} from 'store/Message';
import { openInfoModalAction } from 'store/Modal';
import { RootState } from 'store/rootReducer';

import { TwoColumnRouteLinks } from 'router/links/TwoColumnRouteLinks';

import { groupTabToStateMapping, notificationActionTitles, SOCKET_EVENTS, translation } from '../consts';
import { constructMessageUrl, currentTab, filterUsersByConversationId, getUserFromList } from '../helper';

export const notifyGroupConversationInviteListener =
	(socket: any, liveChatStatusChange: () => void) => (dispatch: any, getState: () => RootState) => {
		socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_INVITE);

		socket.on(SOCKET_EVENTS.NOTIFY_GROUP_VIDEO_CONVERSATION_INVITE, (d: any) => {
			const data = JSON.parse(d);
			const { is_simplified_one_view_enabled: isSimplifiedOneViewEnabled } = getState().User.botAdmin.data;
			dispatch(liveChatStatusChange());

			if (currentTab() === MESSAGE_TAB_HEADERS_MAP.new) {
				const filters: any = dispatch(getFacetsSortFromLs(MESSAGE_TAB_HEADERS_MAP.new));
				const currentPage = JSON.parse(
					localStorage.getItem(`${MESSAGE_TAB_HEADERS_MAP.new}_current_page`) || '1'
				);
				dispatch(getLiveChatUsersAction(filters, currentPage, MESSAGE_TAB_HEADERS_MAP.new));
			}

			const {
				bot_ref: notificationBotRef,
				participant_details: participantDetails,
				conversation_id: conversationId,
				username,
				invited_by: invitedBy,
				is_video_group_conversation: isVideoGroupConversation,
			} = data;

			const url = `${
				isSimplifiedOneViewEnabled
					? TwoColumnRouteLinks.simplifiedMessages
					: `/messages/${MESSAGE_TAB_HEADERS_MAP.new}/`
			}${conversationId}`;
			const currentTime = new Date().toISOString();
			const payload = {
				title: notificationActionTitles.groupConversationRequest,
				description: `${participantDetails[invitedBy].actorName} invited you to a group conversation with ${username}`,
				url,
				type: 'notifyGroupConversationInvite',
				timestamp: currentTime,
				userId: conversationId,
				conversationId,
				botRef: notificationBotRef,
			};
			if (isVideoGroupConversation) {
				payload['title'] = notificationActionTitles.newVideoCallRequest;
				payload['description'] =
					`${participantDetails[invitedBy].actorName} has invited you to a video call with ${username}`;
				payload['type'] = 'notifyVideoGroupConversationInvite';
			}

			dispatch(playSoundNotification({ sound: 'request' }));
			dispatch(addNotificationAction(payload));
		});
	};

export const notifyGroupVideoConversationInviteListener =
	(socket: any, liveChatStatusChange: () => void) => (dispatch: any, getState: () => RootState) => {
		socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_GROUP_VIDEO_CONVERSATION_INVITE);

		socket.on(SOCKET_EVENTS.NOTIFY_GROUP_VIDEO_CONVERSATION_INVITE, (d: any) => {
			const groupVideoConversationInvitePayload = JSON.parse(d);
			const { is_simplified_one_view_enabled: isSimplifiedOneViewEnabled } = getState().User.botAdmin.data;
			dispatch(liveChatStatusChange());

			const {
				bot_ref: notificationBotRef,
				participant_details: participantDetails,
				conversation_id: conversationId,
				username,
				invited_by: invitedBy,
				video_conversation_id: videoConversationId,
			} = groupVideoConversationInvitePayload;

			const url = `${
				isSimplifiedOneViewEnabled
					? TwoColumnRouteLinks.simplifiedMessages
					: `/messages/${MESSAGE_TAB_HEADERS_MAP.active}/`
			}${conversationId}`;
			const currentTime = new Date().toISOString();
			const payload = {
				title: notificationActionTitles.newVideoCallRequest,
				description: `${participantDetails[invitedBy].actorName} has invited you to a video call with ${username}`,
				url,
				type: SOCKET_EVENTS.NOTIFY_GROUP_VIDEO_CONVERSATION_INVITE,
				timestamp: currentTime,
				userId: conversationId,
				conversationId,
				botRef: notificationBotRef,
				videoConversationId,
			};

			dispatch(playSoundNotification({ sound: 'request' }));
			dispatch(addNotificationAction(payload));
		});
	};

export const notifyGroupConversationJoinListener = (socket: any) => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_JOIN);

	socket.on(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_JOIN, (d: any) => {
		const data = JSON.parse(d);
		const userListData = getState().Message.userList.data;
		const { uid, is_simplified_one_view_enabled: isSimplifiedOneViewEnabled } = getState().User.botAdmin.data;
		const {
			bot_ref: notificationBotRef,
			participant_details: participantDetails,
			conversation_id: conversationId,
			username,
			agent_id: agentId,
		} = data;
		const currentTabValue = currentTab();

		const agent = participantDetails[uid];

		if (agentId === uid || !agent) {
			return;
		}

		const url = constructMessageUrl(agent, conversationId, isSimplifiedOneViewEnabled);

		dispatch(playSoundNotification({ sound: 'request' }));
		dispatch(
			addNotificationAction({
				title: notificationActionTitles.groupConversationRequest,
				description: `${participantDetails[agentId].actorName} has joined the conversation with ${username}`,
				url,
				type: SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_JOIN,
				timestamp: new Date().toISOString(),
				userId: conversationId,
				conversationId,
				botRef: notificationBotRef,
			})
		);

		if (currentTabValue !== MESSAGE_TAB_HEADERS_MAP.new && currentTabValue !== MESSAGE_TAB_HEADERS_MAP.active) {
			return;
		}
		const state = (currentTabValue && groupTabToStateMapping[currentTabValue]) || groupTabToStateMapping['DEFAULT'];

		if (!agent || agent.state !== state) {
			return;
		}

		const users = Array.isArray(userListData) ? JSON.parse(JSON.stringify(userListData)) : [];

		const user = getUserFromList(users, conversationId);
		if (!user) {
			return;
		}

		let processed = false;
		if (!user.participant_details) {
			user.participant_details = [];
			user.is_group_conversation = true;
			// eslint-disable-next-line array-callback-return
			Object.keys(participantDetails).map((id) => {
				user.participant_details.push(participantDetails[id]);
			});
			processed = true;
		}

		if (!processed) {
			for (let j = 0; j < user.participant_details.length; j++) {
				if (user.participant_details[j].actorId === agentId) {
					user.participant_details[j].state = GROUP_CONVERSATION_STATES.joined;
					processed = true;
					break;
				}
			}
		}

		if (!processed) {
			user.participant_details.push(participantDetails[agentId]);
			processed = true;
		}
		processed && dispatch(updateUserListAndSelectedUserAction(users, user));
	});
};

export const notifyGroupConversationExitListener = (socket: any) => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_EXIT);

	socket.on(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_EXIT, (d: any) => {
		const data = JSON.parse(d);

		const userListData = getState().Message.userList.data;
		const { uid, is_simplified_one_view_enabled: isSimplifiedOneViewEnabled } = getState().User.botAdmin.data;
		const {
			bot_ref: notificationBotRef,
			participant_details: participantDetails,
			conversation_id: conversationId,
			agent_id: agentId,
			username,
			new_agent_id: newAgentId,
		} = data;
		const currentTabValue = currentTab();

		const agent = participantDetails[uid];

		if (agentId === uid || !agent) {
			return;
		}

		const url = constructMessageUrl(agent, conversationId, isSimplifiedOneViewEnabled);

		dispatch(playSoundNotification({ sound: 'request' }));
		dispatch(
			addNotificationAction({
				title: notificationActionTitles.groupConversationRequest,
				description: `${participantDetails[agentId].actorName} has left the conversation with ${username}`,
				url,
				type: SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_EXIT,
				timestamp: new Date().toISOString(),
				userId: conversationId,
				conversationId,
				botRef: notificationBotRef,
			})
		);

		if (currentTabValue !== MESSAGE_TAB_HEADERS_MAP.new && currentTabValue !== MESSAGE_TAB_HEADERS_MAP.active) {
			return;
		}
		const state = (currentTabValue && groupTabToStateMapping[currentTabValue]) || groupTabToStateMapping['DEFAULT'];
		if (!agent || agent.state !== state) {
			return;
		}

		const users = Array.isArray(userListData) ? JSON.parse(JSON.stringify(userListData)) : [];
		const user = getUserFromList(users, conversationId);
		if (!user) {
			return;
		}
		for (let j = 0; j < user.participant_details.length; j++) {
			if (user.participant_details[j].actorId === agentId) {
				user.participant_details[j].state = GROUP_CONVERSATION_STATES.exited;
				break;
			}
		}
		if (newAgentId) {
			user.actor_id = newAgentId;
			user.actor_name = data.participant_details[newAgentId].actorName;
			user.actor_email = data.participant_details[newAgentId].actorEmail;
		}
		dispatch(updateUserListAndSelectedUserAction(users, user));
	});
};

export const notifyGroupConversationInviteExpireListener = (socket: any) => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_INVITE_EXPIRE);

	socket.on(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_INVITE_EXPIRE, (d: any) => {
		const data = JSON.parse(d);
		const userListData = getState().Message.userList.data;

		if (currentTab() !== MESSAGE_TAB_HEADERS_MAP.new && currentTab() !== MESSAGE_TAB_HEADERS_MAP.active) {
			return;
		}

		const { conversation_id: conversationId, agent_id: agentId } = data;

		const users = Array.isArray(userListData) ? JSON.parse(JSON.stringify(userListData)) : [];
		const user = getUserFromList(users, conversationId);
		if (!user) {
			return;
		}

		if (currentTab() === MESSAGE_TAB_HEADERS_MAP.new) {
			const { selectedUser } = getState().Message;
			if (selectedUser.conversation_id === conversationId) {
				dispatch(removeSelectedUserFromListAction({ conversation_id: conversationId }));
			} else {
				dispatch(setUserListAction(filterUsersByConversationId(conversationId, getState)));
			}
		} else if (currentTab() === MESSAGE_TAB_HEADERS_MAP.active) {
			user.participant_details = Array.isArray(user.participant_details) ? user.participant_details : [];
			for (let j = 0; j < user.participant_details.length; j++) {
				if (user.participant_details[j].actorId === agentId) {
					user.participant_details[j].state = GROUP_CONVERSATION_STATES.expired;
					break;
				}
			}
			dispatch(updateUserListAndSelectedUserAction(users, user));
		}
	});
};

export const notifyGroupConversationInvitationSentListener = (socket: any) => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_INVITE_SENT);

	socket.on(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_INVITE_SENT, (d: any) => {
		const data = JSON.parse(d);
		const {
			participant_details: participantDetails,
			invited_by: invitedBy,
			agent_id: agentId,
			username,
			conversation_id: conversationId,
			bot_ref: notificationBotRef,
		} = data;
		const userListData = getState().Message.userList.data;
		const { uid, is_simplified_one_view_enabled: isSimplifiedOneViewEnabled } = getState().User.botAdmin.data;
		const agent = participantDetails[uid];

		if (!agent || uid === invitedBy) {
			return;
		}

		const url = constructMessageUrl(agent, conversationId, isSimplifiedOneViewEnabled);

		dispatch(playSoundNotification({ sound: 'request' }));
		dispatch(
			addNotificationAction({
				title: notificationActionTitles.groupConversationRequest,
				description: `${participantDetails[invitedBy].actorName} has invited ${participantDetails[agentId].actorName} to the conversation with ${username}`,
				url,
				type: SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_INVITE_SENT,
				timestamp: new Date().toISOString(),
				userId: conversationId,
				conversationId,
				botRef: notificationBotRef,
			})
		);

		const users = Array.isArray(userListData) ? JSON.parse(JSON.stringify(userListData)) : [];
		const user = getUserFromList(users, conversationId);
		if (!user) {
			return;
		}

		let processed = false;
		for (let j = 0; j < user.participant_details.length; j++) {
			if (user.participant_details[j].actorId === agentId) {
				user.participant_details[j].state = GROUP_CONVERSATION_STATES.invited;
				processed = true;
				break;
			}
		}

		if (!processed) {
			user.participant_details.push(participantDetails[agentId]);
			processed = true;
		}
		processed && dispatch(updateUserListAndSelectedUserAction(users, user));
	});
};

export const notifyGroupConversationPendingResolutionListener = (socket: any) => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_PENDING);

	socket.on(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_PENDING, (d: any) => {
		if (currentTab() !== MESSAGE_TAB_HEADERS_MAP.new && currentTab() !== MESSAGE_TAB_HEADERS_MAP.active) {
			return;
		}

		const data = JSON.parse(d);
		const userListData = getState().Message.userList.data;
		const { uid } = getState().User.botAdmin.data;
		const { conversation_id: conversationId } = data;
		const users = Array.isArray(userListData) ? JSON.parse(JSON.stringify(userListData)) : [];
		const user = getUserFromList(users, conversationId);
		if (!user || uid === user.actor_id) {
			return;
		}
		user.current_state = MESSAGE_STATE_MAP.PENDING_RESOLUTION;
		dispatch(updateUserListAndSelectedUserAction(users, user));
	});
};

export const notifyGroupConversationResolutionListener = (socket: any) => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_RESOLUTION);

	socket.on(SOCKET_EVENTS.NOTIFY_GROUP_CONVERSATION_RESOLUTION, (d: any) => {
		const tab = currentTab();
		const userListData = getState().Message.userList.data;
		if (tab !== MESSAGE_TAB_HEADERS_MAP.new && tab !== MESSAGE_TAB_HEADERS_MAP.active) {
			return;
		}

		const data = JSON.parse(d);
		const { uid } = getState().User.botAdmin.data;
		const { conversation_id: conversationId } = data;
		const users = Array.isArray(userListData) ? JSON.parse(JSON.stringify(userListData)) : [];
		const user = getUserFromList(users, conversationId);
		if (!user || uid === user.actor_id) {
			return;
		}
		const { selectedUser } = getState().Message;
		const { selectedVideoUser } = getState().Jitsi;
		const heading = translation('notifyGroupConversationResolution.heading');
		const description = `${translation('notifyGroupConversationResolution.descriptionStart')} ${
			user.username
		} ${translation('notifyGroupConversationResolution.descriptionEnd')} ${user.actor_name}`;
		if (selectedUser.conversation_id === conversationId) {
			if (selectedVideoUser && selectedVideoUser.conversation_id === conversationId) {
				dispatch(jitsiActions.clearAllJitsiData());
			}
			dispatch(removeSelectedUserFromListAction({ conversation_id: conversationId }));
			dispatch(openInfoModalAction(heading, description));
		} else {
			dispatch(setUserListAction(filterUsersByConversationId(conversationId, getState)));
			toaster.positive(<ENGTToasterContainer title={heading} description={description} />, {});
		}
	});
};
