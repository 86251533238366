import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ModalAlert from 'components/UI/Modal/ModalAlert/ModalAlert';

import { RootState } from 'store/rootReducer';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

interface IWarningModal {
	setWhatsappNotConfigured: Function;
}

const WhatsappConfigurationModal = ({ setWhatsappNotConfigured }: IWarningModal) => {
	const { t } = useTranslation(['common', 'pages']);
	const navigate = useNavigate();

	const botName = useSelector((state: RootState) => state.Bot.data.bot_name);

	const errorModalDetails = {
		cancelBtnLabel: t('common:cancel'),
		confirmBtnLabel: t('pages:broadcast.broadcastData.configureWhatsApp'),
		description: t('pages:broadcast.broadcastData.configureWhatsappDescription', { botName }),
		heading: t('pages:broadcast.broadcastData.cannotSendBroadcast'),
	};

	return (
		<>
			<ModalAlert
				closeBtnLabel={errorModalDetails.cancelBtnLabel}
				confirmBtnLabel={errorModalDetails.confirmBtnLabel}
				description={errorModalDetails.description}
				heading={errorModalDetails.heading}
				isConfirmBtnDisabled={false}
				isOpen
				onClose={() => setWhatsappNotConfigured(false)}
				onConfirm={() => navigate(ThreeColumnRouteLinks.configureDepWhatsappLink)}
				width='28rem'
			/>
		</>
	);
};

export default WhatsappConfigurationModal;
