import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { marketingCampaignsAnalyticsSelector } from 'store/App/User/selectors';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

function BroadcastRedirection() {
	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);

	return (
		<Navigate
			to={
				marketingCampaignsAnalyticsType
					? ThreeColumnRouteLinks.executionLink
					: ThreeColumnRouteLinks.broadcastHistoryLink
			}
		/>
	);
}

export default BroadcastRedirection;
