import { IObjProps, IPaginationStoreData } from 'shared/consts/types';

import { ICampaignState } from './state';

export const campaignsReducer = {
	setCampaignsLoading: (state: ICampaignState, action: { payload: boolean }) => {
		state.campaigns.isLoading = action.payload;
	},

	setCampaignData: (state: ICampaignState, action: { payload: IPaginationStoreData<Array<IObjProps>> }) => {
		state.campaigns = action.payload;
	},

	setCampaignDataError: (state: ICampaignState, action: { payload: IObjProps }) => {
		state.campaigns.isError = action.payload.isError;
		state.campaigns.isLoading = action.payload.isLoading;
	},
};
