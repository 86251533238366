import { IErrorSaving, IInfo, IModalStateInterface, ISurveyModal } from './state';

export const modalReducer = {
	openErrorSavingModal: (state: IModalStateInterface, action: { payload: IErrorSaving }) => {
		state.errorSavingModal = action.payload;
	},
	closeErrorSavingModal: (state: IModalStateInterface, action: { payload: IErrorSaving }) => {
		state.errorSavingModal = action.payload;
	},
	openInfoModal: (state: IModalStateInterface, action: { payload: IInfo }) => {
		state.infoModal = action.payload;
	},
	closeInfoModal: (state: IModalStateInterface, action: { payload: IInfo }) => {
		state.infoModal = action.payload;
	},
	openSurveyModal: (state: IModalStateInterface, action: { payload: ISurveyModal }) => {
		state.surveyModal = action.payload;
	},
	closeSurveyModal: (state: IModalStateInterface, action: { payload: ISurveyModal }) => {
		state.surveyModal = action.payload;
	},
};
