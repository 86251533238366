import { toaster } from 'baseui/toast';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { ENGATI_RESPONSE_CODE, OFFERING_KEY_ENUM, REFRESH_TOKEN_INTERVAL } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { isEmpty } from 'shared/helpers';

import { shopifyActions } from 'store/App/Shopify';
import { API as exportBotApi } from 'store/BotExport/api';
import { JourneyAnalyticsApi } from 'store/Configure/Integration/api';
import { API as SaveAsTemplateAPI } from 'store/SaveAsTemplate/api';
import { RootState } from 'store/withReducer';

import { i18nHelper } from 'i18nHelper';

import { getBotListAction } from '../BotList';
import { addNotificationAction, getNotificationFromLS } from '../Notifications';
import { getBotAdminAction } from '../User';

import { API } from './api';
import { actions } from './store';

const {
	getBotConfigDetails,
	getBotConfiguredChannels,
	getBotDetails,
	getBotDetailsError,
	updateBotDetailsLoading,
	updateGeneralSettings,
	updateOnlineStatus,
	getGeneralSettings,
	getVideoConfigurations,
	setEmailStatus,
	setGoogleAnalyticsSettingsEnabled,
	setAgentOnlineStatus,
} = actions;

export const getBotDetailsAction = () => (dispatch: any) => {
	dispatch(updateBotDetailsLoading());

	return API.fetchBotDetails()
		.then((resp: any) => {
			if (resp.data) {
				dispatch(getBotDetails(resp.data));
				dispatch(getBotConfigDetailsAction());
				dispatch(getGoogleAnalyticsSettings());
			} else {
				dispatch(getBotDetailsError());
			}

			return resp;
		})
		.catch(() => {
			dispatch(getBotDetailsError());
		});
};

export const getBotConfigDetailsAction = (successCb?: any) => (dispatch: any, getState: () => RootState) =>
	API.fetchBotConfigDetails().then((resp: any) => {
		if (resp.data) {
			dispatch(getBotConfigDetails(resp.data));
			REFRESH_TOKEN_INTERVAL.timer = resp.data?.inactivity_timeout_config;
			/* When bot_config changes, it means bot context is revised. So we need to get the updated configured-channels and general-bot-settings*/
			const isBotContextChanged =
				!isEmpty(getState().Bot.generalBotConfigData) &&
				getState().Bot.data.bot_key !== getState().Bot.generalBotConfigData.botKey;
			if (isBotContextChanged) {
				dispatch(getGeneralSettingsAction());
				dispatch(getConfiguredChannelsAction());
			}
			if (resp.data.multi_language_enabled === 'ENABLED' || isBotContextChanged) {
				dispatch(setVideoConfigurationsAction({}));
			}
			successCb && successCb();
		} else {
			dispatch(getBotDetailsError());
		}
	});

export const getBotAction = (botRef: number, successCb: any) => (dispatch: any, getState: () => RootState) => {
	API.fetchBotDetails().then((resp: any) => {
		if (resp.data) {
			if (getState().Bot.data.bot_ref !== resp.data.bot_ref) {
				dispatch(getBotDetails(resp.data));
				dispatch(getBotConfigDetailsAction(() => successCb(botRef, getState().Bot.data.bot_key)));
				dispatch(getNotificationFromLS());
				if (getState().Shopify) {
					dispatch(shopifyActions.fetchIntegratedAccountAction());
				}
			} else {
				successCb(botRef, getState().Bot.data.bot_key);
			}
		}
	});
};

export const getConfiguredChannelsAction = (successCB?: any) => (dispatch: any) =>
	API.fetchConfiguredChannels().then((resp: any) => {
		if (resp.data) {
			dispatch(getBotConfiguredChannels(resp.data.responseObject));
			successCB && successCB(resp.data.responseObject);
		} else {
			console.log('error in fetching configured channels');
		}
	});

export const getAgentOnlineStatusAction = () => (dispatch: any) => {
	API.getAgentOnlineStatus().then((resp: any) => {
		if (resp?.data?.status?.code === 1000) {
			dispatch(setAgentOnlineStatus(resp.data.responseObject));
		}
	});
};

export const setAgentOnlineStatusAction = (status: boolean) => (dispatch: any) => {
	dispatch(setAgentOnlineStatus(status));
};

export const botSelect = (botRef: number, successCb: any) => (dispatch: any) => {
	API.botSelect(botRef).then((resp: any) => {
		if (resp.data) {
			dispatch(getBotAction(botRef, successCb));
			dispatch(getConfiguredChannelsAction());
			dispatch(getBotAdminAction());
		}
	});
};

export const exportBotAction =
	(botRef: any, activeModule: string | undefined) => (dispatch: any, getState: () => RootState) => {
		exportBotApi.exportBot(botRef, activeModule).then((response: any) => {
			response?.data?.responseObject && dispatch(getBotListAction(getState().User.botAdmin.data.active_module));
		});
	};

export const createBotAction = (botName: string, successCb: any) => (dispatch: any, getState: () => RootState) => {
	const data: { [key: string]: any } = {
		bot_description: '',
		bot_category: 'default',
		hostname: '',
		name: botName,
		website_url: '',
		default_fallback_msg: 'Sorry, I did not understand that.',
		return_greeting_msg: 'Hi {{first_name}}, welcome back.',
		welcome_msg: 'Hi {{first_name}}. Welcome!',
	};

	const body = new FormData();

	Object.keys(data).forEach((key) => {
		body.append(key, data[key]);
	});

	return API.createBot(body).then((resp: any) => {
		if (resp.data) {
			dispatch(getBotListAction(getState().User.botAdmin.data.active_module));
			dispatch(botSelect(resp.data.bot_ref, successCb));
		}
	});
};

export const createTemplateBotAction =
	(botName: string, successCb: any) => (dispatch: any, getState: () => RootState) => {
		const isWooCommerceEnabled = getState().User.botAdmin.data.is_woocommerce_accelerator_enabled;
		const isShopifyEnabled = getState().User.botAdmin.data.is_shopify_accelerator_enabled;
		const isWhatsAppAccEnabled = getState().User.botAdmin.data.enable_whatsapp_accelerator;
		const { customerPlan } = getState().User.botAdmin.data;
		const { ENGATI_GROWTH_2024, ENGATI_PRO_2024 } = OFFERING_KEY_ENUM;
		let botType = 'SHOPIFY_BOT';
		if (isWooCommerceEnabled || isShopifyEnabled) {
			botType = 'SHOPIFY_ACC_BOT';
		}
		if (isWhatsAppAccEnabled || [ENGATI_GROWTH_2024, ENGATI_PRO_2024].indexOf(customerPlan) > -1) {
			botType = 'WA_ACC';
		}
		const body = {
			name: botName,
			bot_description: '',
			bot_category: 'default',
			welcome_msg: 'Hi {{first_name}}. Welcome!',
			return_greeting_msg: 'Hi {{first_name}}, welcome back.',
			default_fallback_msg: 'Sorry, I did not understand that.',
			bot_type: botType,
		};

		return API.createTemplateBot(body).then((resp: any) => {
			if (resp?.data) {
				const botRef = resp.data?.response?.bot_ref;
				dispatch(getBotListAction(getState().User.botAdmin.data.active_module));
				dispatch(
					addNotificationAction({
						title: i18nHelper('common:notificationsLabels.botCreationStatus'),
						description: i18nHelper('common:notificationsLabels.botCreationInProgress'),
						url: '',
						type: 'generalNotification',
						timestamp: new Date().toISOString(),
						userId: botRef,
						conversationId: botRef,
						bot_ref: botRef,
						notificationActionLabel: ' ',
					})
				);
				dispatch(botSelect(botRef, successCb));
			}
		});
	};

const sendNotification = (title: string, description: string, botRef: number, dispatch: any) => {
	dispatch(
		addNotificationAction({
			title,
			description,
			url: '',
			type: 'generalNotification',
			timestamp: new Date().toISOString(),
			userId: botRef,
			conversationId: botRef,
			bot_ref: botRef,
			notificationActionLabel: ' ',
		})
	);
};

export const createBotFromTemplateAccountBot =
	(botName: string, botRef: number, botCategory: string) => (dispatch: any, getState: () => RootState) =>
		API.createBotFromTemplateAccountBot(botName, botRef, botCategory).then((resp: any) => {
			if (resp?.data?.status?.code) {
				const { code } = resp.data.status;
				const title = i18nHelper('common:notificationsLabels.templateBotStatus');
				let description = i18nHelper('common:notificationsLabels.templateBotStarted');
				if (code === 1000) {
					dispatch(getBotListAction(getState().User.botAdmin.data.active_module));
				} else if (code === 2001) {
					description = i18nHelper('common:notificationsLabels.creationAlreadyInProgress');
				} else {
					description = i18nHelper('common:notificationsLabels.templateBotFailed');
				}
				sendNotification(title, description, botRef, dispatch);
			} else {
				toaster.negative(
					<ENGTToasterContainer
						title={i18nHelper('common:error')}
						description={i18nHelper('common:somethingWentWrong')}
					/>,
					{}
				);
			}
		});

export const copyBotAction =
	(botName: string, botRef: number, botCategory: string) => (dispatch: any, getState: () => RootState) =>
		API.copyBot(botName, botRef, botCategory).then((resp: any) => {
			if (resp?.data?.status?.code) {
				const { code } = resp.data.status;
				const title = i18nHelper('common:notificationsLabels.copyBotStatus');
				let description = i18nHelper('common:notificationsLabels.copyBotStarted');
				if (code === 1000) {
					dispatch(getBotListAction(getState().User.botAdmin.data.active_module));
				} else if (code === 2001) {
					description = i18nHelper('common:notificationsLabels.copyAlreadyInProgress');
				} else {
					description = i18nHelper('common:notificationsLabels.copyBotFailed');
				}
				sendNotification(title, description, botRef, dispatch);
			} else {
				toaster.negative(
					<ENGTToasterContainer
						title={i18nHelper('common:error')}
						description={i18nHelper('common:somethingWentWrong')}
					/>,
					{}
				);
			}
		});

export const deleteBotAction =
	(botName: string, botRef: number, successCb: any, errorCb: any) => (dispatch: any, getState: () => RootState) => {
		const data = {
			bot_ref: botRef,
			new_bot_name: botName,
		};

		API.deleteBot(data).then((resp: any) => {
			if (resp.data) {
				dispatch(getBotListAction(getState().User.botAdmin.data.active_module));
				successCb();
			} else {
				errorCb();
			}
		});
	};

export const upgradeTemplateOption =
	(data: any, successCb: any, onFailure: any, errorCb: any) => (dispatch: any, getState: () => RootState) => {
		SaveAsTemplateAPI.upgradeBotTemplate(data)
			.then((response: any) => {
				if (response.data && response.data.responseObject) {
					dispatch(getBotListAction(getState().User.botAdmin.data.active_module));
					successCb();
				} else {
					onFailure();
				}
			})
			.catch((e: any) => {
				onFailure();
			});
	};

export const setBotStatusAction = () => (dispatch: any) => {
	API.setBotStatus().then((resp: any) => {
		if (resp.data) {
			dispatch(updateOnlineStatus(resp.data.livechat_agent_online));
		} else {
			console.log('error');
		}
	});
};

export const setEmailStatusAction = (status: boolean) => (dispatch: any) => {
	dispatch(setEmailStatus(status));
};

export const getGeneralSettingsAction = () => (dispatch: any) => {
	API.getGeneralSettings().then((resp: any) => {
		if (resp.data && resp.data.responseObject) {
			dispatch(getGeneralSettings(resp.data.responseObject));
		} else {
			console.log('error');
		}
	});
};

export const updateGeneralSettingsAction = (updateData: IObjProps) => (dispatch: any, getState: () => RootState) => {
	const oldGeneralSettingsData = getState().Bot.generalBotConfigData || {};

	dispatch(
		updateGeneralSettings({
			...oldGeneralSettingsData,
			...updateData,
		})
	);
};

export const getGoogleAnalyticsSettings = () => (dispatch: any) => {
	JourneyAnalyticsApi.getGoogleAnalyticsSettings().then((response: any) => {
		if (response?.status === ENGATI_RESPONSE_CODE.STATUS_200) {
			dispatch(setGoogleAnalyticsSettingsEnabled(response?.data?.customEventAttributesEnabled));
		}
	});
};

export const setGoogleAnanlyticsSettingsAction = (data: any) => (dispatch: any) => {
	dispatch(setGoogleAnalyticsSettingsEnabled(data));
};

export const setGeneralSettingsAction = (data: IObjProps) => (dispatch: any) => {
	dispatch(getGeneralSettings(data));
};

export const getVideoConfigurationsAction =
	(isMultiLanguageEnabled: boolean) => (dispatch: any, getState: () => RootState) => {
		const params: any = {
			targetApi: 'videoConversations',
		};
		if (isMultiLanguageEnabled) {
			params['locale'] = getState().Bot.configData.locale;
		}

		API.fetchVideoConfigurations(params).then((resp: any) => {
			if (resp.data) {
				dispatch(getVideoConfigurations(resp.data.responseObject));
			} else {
				console.log('error in fetching video configurations');
			}
		});
	};

export const setVideoConfigurationsAction = (data: IObjProps) => (dispatch: any) => {
	dispatch(getVideoConfigurations(data));
};
