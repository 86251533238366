import { useStyletron } from 'baseui';
import { StyledLink } from 'baseui/link';
import { useTranslation } from 'react-i18next';

import { DOC_LINKS_V2 } from 'shared/consts/DockLinks';
import { IBrandingContext } from 'shared/consts/types';

const ENGTFooter = ({ context }: { context?: IBrandingContext }) => {
	const { t } = useTranslation(['pages', 'shopify', 'common']);
	const [css]: [any, any] = useStyletron();
	const footerLinkCss = {
		textDecoration: 'none !important',
	};

	const brand = context?.brand_title || t('common:engati');
	const termsOfUseLink = context?.terms_of_condition || DOC_LINKS_V2.termOfUse;
	const privacyLink = context?.privacy_policy || DOC_LINKS_V2.privacyLink;

	return (
		<p
			className={css({
				fontSize: '0.625rem !important',
				lineHeight: '1rem !important',
				width: '100%',
				textAlign: 'center',
				marginBottom: '1rem',
				marginTop: '1rem',
				alignSelf: 'flex-end',
				justifySelf: 'flex-end',
			})}
		>
			&copy; {brand} {' | '}
			<StyledLink href={termsOfUseLink} target='_blank' className={css(footerLinkCss)}>
				{t(`shopify:onboarding.registration.termsOfUse`)}
			</StyledLink>
			{' | '}
			<StyledLink href={privacyLink} target='_blank' className={css(footerLinkCss)}>
				{t(`shopify:onboarding.registration.privacyPolicy`)}
			</StyledLink>
		</p>
	);
};

export default ENGTFooter;
