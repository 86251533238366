import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';

import Popover from 'components/UI/ENGTTooltip/ENGTTooltip';

import { IPrimaryNavItems, IPrimaryShopifyNavItems } from 'shared/consts/types';
import { hexToRgb } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import Analytics from 'shared/icons/navigation/Analytics';
import Broadcast from 'shared/icons/navigation/Broadcast';
import Builder from 'shared/icons/navigation/Builder';
import Configure from 'shared/icons/navigation/Configure';
import Contacts from 'shared/icons/navigation/Contacts';
import Home from 'shared/icons/navigation/Home';
import Integrations from 'shared/icons/navigation/Integrations';
import Messages from 'shared/icons/navigation/Messages';
import QuickNavigator from 'shared/icons/navigation/QuickNavigator';
import QuickStartGuide from 'shared/icons/navigation/QuickStartGuide';
import Store from 'shared/icons/navigation/Store';
import Team from 'shared/icons/navigation/Team';
import Train from 'shared/icons/navigation/Train';
import Users from 'shared/icons/navigation/Users';
import WhatsAppManager from 'shared/icons/navigation/WhatsAppManager';

export interface INavIconProps {
	routeName: IPrimaryNavItems | IPrimaryShopifyNavItems;
	isActive: boolean;
	iconSize: number;
}

function NavIcon(props: INavIconProps) {
	const { routeName, isActive, iconSize } = props;
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['common']);

	const { isSmallDesktopScreen } = useResponsiveSize();

	const iconWrapperDesktopCss = (page: string) => ({
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderTopRightRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		borderTopLeftRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		backgroundColor: isActive ? theme.primaryNavBackground : 'transparent',
		overflow: 'hidden',
		':hover': {
			backgroundColor: theme.primaryNavBackground,
		},
	});

	const iconWrapperMobileCss = (page: string) => ({
		...iconWrapperDesktopCss(page),
		backgroundColor: 'transparent',
		':hover': {},
	});

	const iconWrapperCss = isSmallDesktopScreen ? iconWrapperMobileCss : iconWrapperDesktopCss;

	const svgCss = {
		className: css({
			':hover': {
				backgroundColor: theme.primaryNavBackground,
				opacity: 1,
			},
			opacity: isActive ? 1 : 0.8,
		}),
		size: iconSize,
	};

	const svgMobileCss = {
		...svgCss,
		className: css({
			backgroundColor: hexToRgb(theme.colors.backgroundSecondary, 0.7),
			opacity: 1,
		}),
	};

	const svgProps = (page: string) => ({
		fillColor: theme.colors.primaryA,
		strokeColor: page === 'quickGuide' && theme.colors.primaryB,
		...(isSmallDesktopScreen ? svgMobileCss : svgCss),
	});

	const iconMap: any = {
		home: <Home {...svgProps('home')} />,
		quickNavigator: <QuickNavigator {...svgProps('quickNavigator')} />,
		shopifyQuickNavigator: <QuickNavigator {...svgProps('quickNavigator')} />,
		messages: <Messages {...svgProps('messages')} />,
		builder: <Builder {...svgProps('builder')} />,
		train: <Train {...svgProps('train')} />,
		team: <Team {...svgProps('team')} />,
		configure: <Configure {...svgProps('configure')} />,
		store: <Store {...svgProps('store')} />,
		users: <Users {...svgProps('users')} />,
		contacts: <Contacts {...svgProps('contacts')} />,
		integrations: <Integrations {...svgProps('integrations')} />,
		analytics: <Analytics {...svgProps('analytics')} />,
		broadcast: <Broadcast {...svgProps('analytics')} />,
		conversationBuilder: <Builder {...svgProps('builder')} />,
		conversationSupport: <Train {...svgProps('train')} />,
		storeSettings: <Configure {...svgProps('configure')} />,
		quickGuide: <QuickStartGuide {...svgProps('quickGuide')} />,
		whatsApp: <WhatsAppManager {...svgProps('whatsApp')} />,
		waQuickNavigator: <QuickNavigator {...svgProps('quickNavigator')} />,
	};

	return (
		<Popover content={t(`common:navigation.${routeName}`)} ignoreBoundary>
			<span className={css(iconWrapperCss(routeName))}>{iconMap[routeName]}</span>
		</Popover>
	);
}

export default NavIcon;
