import React from 'react';
import { useStyletron } from 'baseui';

const DownTriangularArrow = ({ fill, width, height }: { fill?: string; width?: number; height?: number }) => {
	const [css, theme] = useStyletron();

	return (
		<svg
			width={width || 8}
			height={height || 5}
			viewBox={`0 0 ${width || 8} ${height || 5}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M4.98168 3.96624C4.67193 4.26429 4.18202 4.26429 3.87228 3.96624L1.1809 1.37646C0.661967 0.877116 1.01544 0 1.7356 0H7.11836C7.83852 0 8.19199 0.877115 7.67306 1.37646L4.98168 3.96624Z'
				fill={fill || theme.colors.primaryA}
			/>
		</svg>
	);
};

export default DownTriangularArrow;
