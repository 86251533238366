import { SOCIAL_INBOX_MEDIA_TYPES } from 'shared/consts/SocialInbox/constants';
import { IObjProps } from 'shared/consts/types';

import { IPostListAndOverviewInterface } from './state';

export const postListAndOverviewReducer = {
	setCurrentOneviewScreenAs: (state: IPostListAndOverviewInterface, action: { payload: string }) => {
		state.currentOneViewScreen = action.payload;
	},

	populatePostListAndOverviewData: (state: IPostListAndOverviewInterface, action: { payload: IObjProps }) => {
		state.listOfPosts = {
			data: action.payload.content,
			error: false,
			loading: false,
			pagination: {
				page: action.payload.number + 1,
				size: action.payload.size,
				totalPages: action.payload.totalPages,
				totalElements: action.payload.totalElements,
			},
		};
	},
	populatePostList: (state: IPostListAndOverviewInterface, action: { payload: Array<IObjProps> }) => {
		const oldUserList = JSON.parse(JSON.stringify(state.listOfPosts));
		state.listOfPosts = {
			...oldUserList,
			data: action.payload,
			loading: false,
			error: false,
		};
	},
	setSelectedPost: (state: IPostListAndOverviewInterface, action: { payload: IObjProps }) => {
		const newlySelectedPost = action.payload.post;
		const pageOfSelectedPost = action.payload.user;

		state.selectedPost = {
			postId: newlySelectedPost?.postId || '',
			pageId: pageOfSelectedPost?.userId || newlySelectedPost?.pageId || '',
			caption: newlySelectedPost?.caption || '',
			previewMediaUrl: newlySelectedPost?.previewMediaUrl || '',
			totalCommentsCount: newlySelectedPost?.totalCommentsCount || 0,
			updatedOn: newlySelectedPost?.updatedOn || '',
			createdOn: newlySelectedPost?.createdOn || '',
			likesOrReactionsCount: newlySelectedPost?.reactionsCount || 0,
			attachmentMediaType: newlySelectedPost?.mediaType || SOCIAL_INBOX_MEDIA_TYPES.TEXT,
			attachments: newlySelectedPost?.attachments || [],
			pageName: pageOfSelectedPost?.userName || '',
			// TODO: add this to API response which gets all the details in right panel in oneview make it visible in social inbox
			pageProfilePic: pageOfSelectedPost?.profilePicUrl || '',
			commentsNextCursor: newlySelectedPost?.commentsNextCursor || '',
			commentsSyncStatus: newlySelectedPost?.commentsSyncStatus || '',
		};
	},
	updateSelectedPost: (state: IPostListAndOverviewInterface, action: { payload: IObjProps }) => {
		state.selectedPost = {
			postId: action.payload?.postId || '',
			pageId: action.payload?.pageId || '',
			caption: action.payload?.caption || '',
			previewMediaUrl: action.payload?.previewMediaUrl || '',
			totalCommentsCount: action.payload?.totalCommentsCount || 0,
			updatedOn: action.payload?.updatedOn || '',
			createdOn: action.payload?.createdOn || '',
			likesOrReactionsCount: action.payload?.reactionsCount || 0,
			attachmentMediaType: action.payload?.attachmentMediaType || SOCIAL_INBOX_MEDIA_TYPES.TEXT,
			attachments: action.payload?.attachments || [],
			pageName: action.payload?.pageName || '',
			pageProfilePic: action.payload?.profilePicUrl || '',
			commentsNextCursor: action.payload?.commentsNextCursor || '',
			commentsSyncStatus: action.payload?.commentsSyncStatus || '',
		};
	},
	setPostsLoading: (state: IPostListAndOverviewInterface, action: { payload: boolean }) => {
		const existingListOfPostsData = JSON.parse(JSON.stringify(state.listOfPosts));

		state.listOfPosts = {
			...existingListOfPostsData,
			loading: action.payload,
		};
	},
	updateUnreadPostIdCountMap: (state: IPostListAndOverviewInterface, action: { payload: IObjProps }) => {
		state.unreadPostIdCountMap = action.payload;
	},
	incrementTotalCommentsCountForThePost: (state: IPostListAndOverviewInterface, action: { payload: string }) => {
		if (state.selectedPost.postId === action.payload) {
			state.selectedPost.totalCommentsCount += 1;
		}

		for (const postData of state.listOfPosts.data) {
			if (postData.post.postId === action.payload) {
				postData.post.totalCommentsCount += 1;
				break;
			}
		}
	},
	setViewStitchedUserComment: (state: IPostListAndOverviewInterface, action: { payload: boolean }) => {
		state.viewStitchedUserComment = action.payload;
	},
	setInstanceBotRefOfThePage: (state: IPostListAndOverviewInterface, action: { payload: string }) => {
		state.instanceBotRef = action.payload;
	},
};
