import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const genericProxyRequest = new BaseService(genericProxyRequestUrl);
const genericProxyRequestCreatorWithCancelToken = (cancelToken) => new BaseService(genericProxyRequestUrl, cancelToken);

export const postCommentsAPI = {
	getCommentsForPost(payload, cancelToken) {
		const params = {
			platform: payload.platform,
			page: payload.page,
			size: payload.size,
			postId: payload.postId,
			pageId: payload.pageId,
			targetApi: 'commentsForPost',
		};
		const genericProxyRequestWithCancelToken = genericProxyRequestCreatorWithCancelToken(cancelToken);

		return genericProxyRequestWithCancelToken.getRequest(serialize(params, '?'));
	},
	getRepliesForComment(payload, cancelToken) {
		const params = {
			platform: payload.platform,
			page: payload.page,
			size: payload.size,
			postId: payload.postId,
			pageId: payload.pageId,
			parentCommentId: payload.parentCommentId,
			targetApi: 'getRepliesForComment',
		};
		const genericProxyRequestWithCancelToken = genericProxyRequestCreatorWithCancelToken(cancelToken);

		return genericProxyRequestWithCancelToken.getRequest(serialize(params, '?'));
	},
	postCommentOrReply: (payload) => {
		const params = {
			platform: payload.platform,
			postId: payload.postId,
			parentCommentId: payload.parentCommentId,
			message: payload.message,
			targetApi: 'commentsForPost',
		};

		return genericProxyRequest.postRequest(null, serialize(params, '?'));
	},
};
