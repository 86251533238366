import React from 'react';
import { useStyletron } from 'baseui';

import './BouncingEffect.scss';

export interface IBouncingEffectProps {
	backgroundColor?: string;
}

function BouncingEffect(props: IBouncingEffectProps) {
	const [css, theme]: [any, any] = useStyletron();
	const { backgroundColor = theme.colors.primaryA } = props;

	const dotsCss = () => ({
		width: '0.5rem',
		height: '0.5rem',
		backgroundColor,
		borderRadius: '100%',
		display: 'inline-block',
	});

	return (
		<div
			id='messages-spinner'
			className={css({
				textAlign: 'center',
			})}
		>
			<div className={css(dotsCss())} id='messages-spinner-bounce1' />
			<div className={css(dotsCss())} id='messages-spinner-bounce2' />
			<div className={css(dotsCss())} id='messages-spinner-bounce3' />
		</div>
	);
}

export default BouncingEffect;
