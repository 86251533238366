export enum GTMTrackingIds {
	CREATE_NEW_BROADCAST_BUTTON = 'create-new-broadcast-button',
	PUBLISH_BROADCAST_BUTTON = 'publish-broadcast-button',
	PUBLISH_BROADCAST_BUTTON_QUICK_NAV_RETARGET = 'publish-broadcast-button-quick-nav-retarget',
	PUBLISH_BROADCAST_BUTTON_HEADER_RETARGET = 'publish-broadcast-button-header-retarget',
	PUBLISH_BROADCAST_BUTTON_THREE_DOTS_RETARGET = 'publish-broadcast-button-three-dots-retarget',
	TEST_BROADCAST_BUTTON = 'test-broadcast-button',
	TIME_SPENT_ON_SIMPLIFIED_ONEVIEW = 'time_spent_on_simplified_oneview',
	TEMPLATE_MESSAGES_SENT_FROM_ONEVIEW = 'template_messages_sent_from_oneview',
	ADD_SEGMENT_BUTTON = 'add-segment-button',
	CREATE_SEGMENT_BUTTON = 'create-segment-button',
	LOGIN = 'login',
	TIME_SPENT_ON_PORTAL = 'time_spent_on_portal',
	CONNECT_TO_STORE_BUTTON_QUICK_NAV = 'connect-to-store-button-quick-nav',
	ADD_COLLECTIONS_BUTTON_QUICK_NAV = 'add-collections-button-quick-nav',
	CREATE_SEGMENTS_BUTTON_QUICK_NAV = 'create-segments-button-quick-nav',
	CREATE_NEW_BROADCAST_BUTTON_QUICK_NAV = 'create-new-broadcast-button-quick-nav',
	ENABLE_ABANDONED_CHECKOUT_BUTTON = 'enable-abandoned-checkout-button',
	ABANDONED_CHECKOUT_EDIT_SETTINGS_BUTTON = 'abandoned-checkout-edit-settings-button',
	ENABLE_COD_TO_PREPAID_BUTTON = 'enable-cod-to-prepaid-button',
	COD_TO_PREPAID_EDIT_SETTINGS_BUTTON = 'cod-to-prepaid-edit-settings-button',
	ENABLE_PRODUCT_RECOMMENDATION_BUTTON = 'enable-product-recommendation-quick-nav',
	PRODUCT_RECOMMENDATION_EDIT_SETTINGS_BUTTON = 'product-recommendation-edit-settings-button',
	ENABLE_REORDER_CAMPAIGN_BUTTON = 'enable-reorder-campaign-button-quick-nav',
	REORDER_CAMPAIGN_EDIT_SETTINGS_BUTTON = 'reorder-campaign-edit-settings-button',
	ENABLE_FEEDBACK_CAMPAIGN_BUTTON = 'enable-feedback-campaign-buttin-quick-nav',
	FEEDBACK_CAMPAIGN_EDIT_SETTINGS_BUTTON = 'feedback-campaign-edit-settings-button',
	RETARGET_CUSTOMERS_BUTTON_QUICK_NAV = 'retarget-customers-button-quick-nav',
	RETARGET_CUSTOMERS_BUTTON_HEADER = 'retarget-customers-button-header',
	RETARGET_CUSTOMERS_BUTTON_THREE_DOTS = 'retarget-customers-button-three-dots',
	SHARE_PRODUCTS_ACCORDION = 'share-products-accordion',
	SHARE_PRODUCT_BUTTON = 'share-product-button',
	CROSS_SELL_SELECTED = 'cross-sell-selected',
	UPSELL_SELLECTED = 'upsell-selected',

	// Template V2 events
	MESSAGE_TEMPLATE_V2_STEP1_VISIT = 'step1_message_templates_views',
	MESSAGE_TEMPLATE_V2_STEP2_VISIT = 'step2_message_templates_views',
	MESSAGE_TEMPLATE_V2_STEP1_DROPOFF = 'step1_message_templates_dropoff',
	MESSAGE_TEMPLATE_V2_STEP2_DROPOFF = 'step2_message_templates_dropoff',
	MESSAGE_TEMPLATE_CREATED = 'template_created',
	MESSAGE_TEMPLATE_V2_TIME_FOR_CREATION = 'template_creation_time',
}

export enum QuickGuideTrackingIds {
	VIEW_TEMPLATE_LIBRARY = 'quick-guide-template-library-view',
	SEND_BROADCAST = 'quick-guide-send-broadcast-view',
	LEAD_CAPTURE = 'quick-guide-lead-capture-view',
	VISIT_BROADCAST = 'quick-guide-broadcast-view',
	VIEW_LIVE_CHAT_PERFORMANCE = 'quick-guide-livechat-performance-view',
	VIEW_BOT_ANALYTICS = 'quick-guide-bot-analytics-preview',
	PREVIEW_CONVERSATION_BUILDER = 'quick-guide-bot-building-preview',
	PREVIEW_SUPPORT_AUTOMATION = 'quick-guide-faq-preview',
	PREVIEW_AUTOMATE_NOTIFICATION = 'quick-guide-notifications-preview',
	PREVIEW_SHOP_ASSIST = 'quick-guide-shop-assist-preview',
	PREVIEW_GENERATIVE_AI = 'quick-guide-gpt-preview',
	CLICK_VIEW_ALL_INTEGRATIONS = 'quick-guide-all-integrations-view',
	CLICK_VIEW_DEPLOYMENT = 'quick-guide-all-channels-view',
}

export enum PlgGTMTrackingIds {
	// custom events
	GET_STARTED_PAGE_VISIT = 'plg-wa-get-started-views',
	GET_STARTED_DROP_OFF = 'plg-wa-get-started-bounce',
	GET_STARTED_TIME_SPENT = 'plg-wa-get-started-time-spent',
	SIGN_UP_SUCCESS = 'plg-wa-get-started-success',
	SIGN_UP_USING_GOOGLE = 'plg-wa-google-sign-up',
	SIGN_UP_USING_OFFICE_365 = 'plg-wa-office-sign-up',
	SIGN_UP_USING_EMAIL = 'plg-wa-email-sign-up',
	WA_PHONE_SETUP_STEP_VISIT = 'plg-wa-phone-views',
	WA_PHONE_SETUP_STEP_DROP_OFF = 'plg-wa-phone-bounce',
	WA_PHONE_SETUP_STEP_TIME_SPENT = 'plg-wa-phone-time-spent',
	WA_PHONE_SETUP_STEP_SUCCESS = 'plg-wa-phone-success',
	LIVE_CHAT_JOURNEY_COMPLETED = 'plg-wa-live-chat-jounrey-completed',
	TEMPLATE_SUBMISSION_TRIGGERED = 'plg-wa-template-creation-triggered',
	UPGRADE_NOW_BANNER_FORM_SUBMITTED = 'plg-banner-upgrade-request-sent',
	WA_BASE_UPGRADE_REQUESTED = 'plg-wa-base-upgrade-requested',
	BROADCAST_MORE_THAN_ONCE = 'plg-broadcast-more-than-once',
	PRICNIG_PAGE_VISIT = 'plg-wa-pricing-page-views',
	ACTIVATION = 'plg-activation',
	// payment events
	PAID_PLAN_BILLING_MODAL_OPENED = 'plg-customer-billing-modal-opened',
	PAYMENT_PAGE_DROP_OFF = 'plg-payment-page-drop-off',
	PAID_PLAN_PAYMENT_SUCCESS = 'plg-paid-plan-payment-successful',
	PAID_PLAN_PAYMENT_FAILED = 'plg-paid-plan-payment-failed',
	PAYMENT_EXIT_INTENT = 'plg-pricing-exit-intent',
	CHECKOUT_BUTTON_CLICKED = 'plg-pricing-checkout-btn-clicked',
	RETRY_CHECKOUT_BUTTON_CLICKED = 'plg-retry-pricing-checkout-btn-clicked',
	SKIP_BUTTON_CLICKED = 'plg-skip-discount-btn-clicked',
	TALK_TO_US_BUTTON_CLICKED = 'plg-talk-to-us-btn-clicked',
	PAYMENT_EXIT_INTENT_MODAL_SHOWN = 'plg-pricing-exit-modal-shown',
	// click events
	GET_STARTED_TUTORIAL_CLICKED = 'wa-plg-intro-start-btn-clicked',
	GET_STARTED_TUTORIAL_PLAYED = 'plg-intro-video-played',
	SEND_BROADCAST_TUTORIAL_VIDEO_PLAYED = 'plg-broadcast-tutorial-played',
	START_CONVERSATION_TUTORIAL_VIDEO_PLAYED = 'plg-live-chat-tutorial-played',
	UPGRADE_NOW_BANNER_BUTTON_CLICKED = 'plg-banner-upgrade-initiated',
	CONTACT_SUPPORT_BUTTON_CLICKED = 'plg-header-contact-support',
	BROADCAST_PUBLISH_CLICKED = 'plg-broadcast-published',
	BROADCAST_PUBLISHED_MODAL_LIVECHAT_CLICK = 'plg-broadcast-published-modal-livechat-click',
	BROADCAST_PUBLISHED_MODAL_PRICING_PAGE_CLICK = 'plg-broadcast-published-modal-pricing-page-click',
	BROADCAST_PUBLISHED_MODAL_EXPLORE_MORE_CLICK = 'plg-broadcast-published-modal-explore-more-click',
	GO_TO_BROADCAST_REDIRECTION_CLICKED = 'plg-post-broadcast-redirection-clicked',
	PRICING_PLAN_UPGRADE_CLICKED = 'plg-wa-pricing-plan-upgrade-clicked',
	PRICING_PLAN_META_RATES_CLICKED = 'plg-wa-pricing-plan-meta-rates-clicked',
	SEND_BROADCAST_USE_TEMPLATE_CLICKED = 'plg-broadcast-template-selected',
	SEND_BROADCAST_GET_STARTED_CLICKED = 'plg-broadcast-tutorial-get-started-clicked',
	SEND_BROADCAST_QUICK_GUIDE_CLICKED = 'plg-send-broadcast-quick-guide',
	START_CONVERSATION_GET_STARTED_CLICKED = 'plg-live-chat-tutorial-get-started-clicked',
	START_CONVERSATION_QUICK_GUIDE_CLICKED = 'plg-start-live-chat-quick-guide',
	START_CONVERSATION_BTN_CLICKED = 'plg-start-conversation-live-chat',
	RESOLVE_CONVERSATION_BTN_CLICKED = 'plg-resolve-conversation',
	TERMS_CONDITIONS_LINK_CLICKED = 'plg-payment-terms-conditions-clicked',
	BILLING_MODAL_PAY_CTA_CLICKED = 'plg-billing-modal-pay-now-cta-clicked',
	RETRY_PAYMENT_CLICKED = 'plg-retry-payment-clicked',
}

export enum GA4EventTypes {
	BUTTON_CLICK = 'button_click',
	TIMING = 'timing',
	TIME_SPENT_ON_PAGE = 'time_spent_on_page',
	PORTAL_PAGE_VIEW = 'portal_page_view',
	GTM_CLICK = 'gtm.click',
	TIME_TRACKING = 'time_tracking',
}

export enum PlatformTypes {
	PLATFORM = 'Platform',
	SHOPIFY = 'Shopify',
	WOOCOMMERCE = 'WooCommerce',
	WA_ACCELERATOR = 'WAAccelerator',
}

export enum LoginMethods {
	PORTAL_LOGIN = 'portal_login',
	FACEBOOK = 'facebook',
	GOOGLE = 'google',
	OFFICE_365 = 'office_365',
}
