import { useCallback } from 'react';

import { IPrimaryNavItems } from 'shared/consts/types';

import NavigationHelperToolTips from '../components/NavigationHelperToolTips';
import NavigationLinks from '../components/NavigationLinks';
import PublishLiveStatus from '../components/PublishLiveStatus';
import useNavigationHook from '../hooks/useNavigationHook';

const Navigations = ({ path }: { path: string }) => {
	const { navigations, isActiveCommonPath } = useNavigationHook(path);

	const getNavLink = useCallback(
		(navItem: IPrimaryNavItems) => (navItem === 'waQuickNavigator' ? '/wa-quick-guide' : `/${navItem}`),
		[]
	);

	return <NavigationLinks navigations={navigations} isActive={isActiveCommonPath} navLink={getNavLink} />;
};

function CommonNavigation(props: { path: string; setIsLoading: Function }) {
	const { path, setIsLoading } = props;

	return (
		<>
			<Navigations path={path} />
			<PublishLiveStatus setIsLoading={setIsLoading} />
			<NavigationHelperToolTips />
		</>
	);
}

export default CommonNavigation;
