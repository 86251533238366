import { createSlice } from '@reduxjs/toolkit';

import { preferenceReducer } from './reducers';
import { preferenceState } from './state';

export const preferenceSlice = createSlice({
	name: 'Preferences',
	initialState: preferenceState,
	reducers: preferenceReducer,
});

export const { actions, reducer } = preferenceSlice;
