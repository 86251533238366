import React from 'react';
import { useSelector } from 'react-redux';

import { ContactSupportEmailBtn } from 'components/ContactSupportEmailBtn/ContactSupportEmailBtn';

import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import { isWhatsappAcceleratorTrialEnabled } from 'store/App/User';
import { isEngatiBrandSelector } from 'store/Users/selectors';

import Divider from './Divider';

const ContactSupport = () => {
	const { isSmallDesktopScreen } = useResponsiveSize();

	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const isWhatsappAcceleratorTrial = useSelector(isWhatsappAcceleratorTrialEnabled);

	return isEngatiBrand && isWhatsappAcceleratorTrial ? (
		<>
			<ContactSupportEmailBtn />
			{!isSmallDesktopScreen && <Divider />}
		</>
	) : (
		<></>
	);
};

export default ContactSupport;
