import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';

import { IObjProps } from 'shared/consts/types';
import { BOT_MODE } from 'shared/helpers';
import AddIcon from 'shared/icons/AddIcon';

import { marketingCampaignsAnalyticsSelector } from 'store/App/User/selectors';
import { RootState } from 'store/rootReducer';

interface ICreateClampaignBtn {
	overrides?: IObjProps;
	onClick: () => void;
}

const CreateClampaignBtn = (props: ICreateClampaignBtn) => {
	const { overrides = {}, onClick } = props;

	const [css]: [any, any] = useStyletron();
	const { t } = useTranslation(['pages']);
	const botMode = useSelector((state: RootState) => state.Bot.data.mode);

	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);
	const isLiveDraftEntitlement = useSelector((state: RootState) => state.User.botAdmin.data.is_live_draft_enabled);

	return (
		<ENGTButton
			size={SIZE.compact}
			kind={KIND.primary}
			label={t('pages:broadcast.campaigns.createNewCampaign')}
			startEnhancer={<AddIcon />}
			isDisabled={botMode === BOT_MODE.LIVE && isLiveDraftEntitlement}
			className={css({
				marginTop: marketingCampaignsAnalyticsType ? '0rem !important' : '2rem !important',
				marginBottom: marketingCampaignsAnalyticsType ? '2rem !important' : '0rem !important',
			})}
			onClick={() => {
				onClick();
			}}
		/>
	);
};

export default CreateClampaignBtn;
