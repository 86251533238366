import { formatDate } from 'baseui/datepicker';
import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { IObjProps } from 'shared/consts/types';
import { DAYS_FILTER } from 'shared/enum';
import { isValidResponseObject } from 'shared/helpers';

import { isBroadcastV2EntitlementEnabled } from 'store/App/User/selectors';
import { botRefSelector } from 'store/Bot/selectors';
import { API } from 'store/Broadcast/api';
import { customerIdSelector, userId as userIdSelector } from 'store/Users/selectors';

const useExportEngagementHook = () => {
	const { t } = useTranslation(['common', 'pages']);

	const customerId = useSelector(customerIdSelector);
	const userId = useSelector(userIdSelector);
	const botRef = useSelector(botRefSelector);
	const isBroadcastV2Enabled = useSelector(isBroadcastV2EntitlementEnabled);

	const calculateDates = (filter: string) => {
		const yesterday = new Date();
		yesterday.setDate(new Date().getDate() - 1);

		const fromDate = new Date(yesterday);
		const daysOffset = filter === DAYS_FILTER.DAYS_7 ? 6 : filter === DAYS_FILTER.DAYS_28 ? 27 : 0;
		fromDate.setDate(yesterday.getDate() - daysOffset);

		fromDate.setHours(0, 0, 0);
		yesterday.setHours(23, 59, 59);

		return {
			startDate: formatDate(fromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
			endDate: formatDate(yesterday, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
		};
	};

	const exportEngagementData = (data: IObjProps) => {
		const dates = calculateDates(data.filter);

		const exportData = {
			customerId,
			userId,
			fileName: data?.filename,
			workflow: data?.workflow,
			botRef,
			startDate: dates.startDate,
			endDate: dates.endDate,
			broadcastVersion: isBroadcastV2Enabled ? 'V2' : 'V1',
		};

		API.exportData(exportData)
			.then((resp: IObjProps) => {
				if (isValidResponseObject(resp)) {
					toaster.positive(
						<ENGTToasterContainer
							title={t('pages:marketingCampaigns.downloadToasterInProgress')}
							description={t('pages:marketingCampaigns.downloadToasterDescription')}
						/>,
						{}
					);
				} else {
					toaster.negative(
						<ENGTToasterContainer
							title={t('common:error')}
							description={t('pages:broadcast.analytics.downloadingFailed')}
						/>,
						{}
					);
				}
			})
			.catch(() => {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			});
	};

	return {
		exportEngagementData,
	};
};

export default useExportEngagementHook;
