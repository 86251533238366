const FilledConfigureIcon = ({ width = '13', height = '12', fill = '#403F42' }) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			opacity='0.8'
			d='M10.6429 4.52721L10.5847 4.50644L10.3571 3.96323L10.3818 3.90742C11.0712 2.3439 11.0236 2.29726 10.9093 2.18391L9.98973 1.28683C9.95213 1.24953 9.88611 1.22329 9.8292 1.22329C9.77746 1.22329 9.62075 1.22329 8.26261 1.83842L8.20815 1.86286L7.64781 1.63722L7.62532 1.58144C6.98665 0 6.92122 0 6.76229 0H5.46364C5.3053 0 5.23319 0 4.64096 1.58419L4.61866 1.64186L4.06124 1.86909L4.00794 1.84693C3.08867 1.45937 2.55192 1.26284 2.412 1.26284C2.35522 1.26284 2.28897 1.28832 2.25121 1.32552L1.33078 2.22518C1.21401 2.34036 1.16559 2.38858 1.89108 3.91947L1.91775 3.97625L1.68982 4.51886L1.63403 4.54043C0.015564 5.16589 0.015564 5.22687 0.015564 5.38978V6.66236C0.015564 6.8257 0.015564 6.89354 1.63725 7.47471L1.69522 7.49518L1.92318 8.0362L1.8987 8.09149C1.20931 9.65573 1.25303 9.69807 1.3704 9.8148L2.28858 10.7131C2.32707 10.7501 2.39368 10.7765 2.45049 10.7765C2.5019 10.7765 2.65825 10.7765 4.01729 10.1617L4.07171 10.1362L4.63241 10.363L4.65421 10.4191C5.29328 12 5.35893 12 5.51796 12H6.81701C6.98012 12 7.04776 12 7.64046 10.4148L7.66255 10.357L8.22087 10.1313L8.274 10.153C9.19251 10.5417 9.729 10.7378 9.86803 10.7378C9.9243 10.7378 9.99115 10.7131 10.0295 10.6751L10.9515 9.77346C11.0674 9.65769 11.1158 9.61035 10.389 8.08092L10.3619 8.02349L10.5895 7.48322L10.6438 7.46192C12.2649 6.83318 12.2649 6.7719 12.2649 6.6088V5.33698C12.2648 5.17367 12.2648 5.10623 10.6429 4.52721ZM6.14018 8.0639C4.97956 8.0639 4.03537 7.13776 4.03537 6.00023C4.03537 4.86266 4.97959 3.93776 6.14018 3.93776C7.3003 3.93776 8.24432 4.86286 8.24432 6.00023C8.24436 7.13757 7.30034 8.0639 6.14018 8.0639Z'
			fill={fill}
		/>
	</svg>
);

export default FilledConfigureIcon;
