const LinkedSplitBroadcastIcon = ({ fill = '#403F42' }: { fill?: string }) => (
	<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M4.99463 6.50359C5.21058 6.79258 5.4861 7.03171 5.80249 7.20475C6.11888 7.37778 6.46874 7.48068 6.82835 7.50646C7.18797 7.53224 7.54891 7.4803 7.88671 7.35417C8.2245 7.22803 8.53125 7.03065 8.78613 6.77541L10.2947 5.26531C10.7527 4.79063 11.0061 4.15487 11.0004 3.49496C10.9946 2.83506 10.7302 2.2038 10.2641 1.73716C9.7979 1.27052 9.16729 1.00583 8.50806 1.0001C7.84883 0.994361 7.21372 1.24804 6.73953 1.7065L5.87462 2.56726'
			stroke={fill}
			strokeWidth='1.4'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7.00584 5.49661C6.78989 5.20761 6.51438 4.96849 6.19799 4.79545C5.8816 4.62241 5.53173 4.51952 5.17212 4.49373C4.81251 4.46795 4.45156 4.51989 4.11377 4.64603C3.77597 4.77217 3.46923 4.96955 3.21434 5.22479L1.70578 6.73489C1.24779 7.20957 0.994367 7.84533 1.0001 8.50523C1.00582 9.16514 1.27025 9.79639 1.73641 10.263C2.20257 10.7297 2.83318 10.9944 3.49241 11.0001C4.15164 11.0058 4.78675 10.7522 5.26095 10.2937L6.12082 9.43293'
			stroke={fill}
			strokeWidth='1.4'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default LinkedSplitBroadcastIcon;
