import { createSlice } from '@reduxjs/toolkit';

import { jitsiReducers } from './reducers';
import { jitsiState } from './state';

export const jitsiSlice = createSlice({
	name: 'Jitsi',
	initialState: jitsiState,
	reducers: jitsiReducers,
});

export const { actions, reducer } = jitsiSlice;
