import React, { useEffect, useState } from 'react';
import { StatelessAccordion } from 'baseui/accordion';
import merge from 'lodash/merge';

import { IObjProps } from 'shared/consts/types';
import DropDownToggleDown from 'shared/icons/DropDownToggleDown';
import DropDownToggleUp from 'shared/icons/DropDownToggleUp';

export interface ENGTAccordionType {
	general: 'general';
	block: 'block';
}

export interface IENGTAccordion {
	accordion?: boolean;
	children: React.ReactNode;
	errorId?: any;
	expandedPanel?: any;
	background?: string;
	fieldIds?: Array<any>;
	overrides?: IObjProps;
	usage?: 'primary' | 'carousel';
	type?: ENGTAccordionType[keyof ENGTAccordionType];
	dataQa?: string;
	expandLastPanel?: boolean;

	onChange?: (...args: any) => any;
}

const blockAccordionOverride = (overrides: any, dataQa?: string) => ({
	PanelContainer: {
		style: ({ $expanded }: any) => ({
			marginBottom: '0.5rem',
			borderTopRightRadius: $expanded ? '0rem' : '0.5rem',
			borderTopLeftRadius: $expanded ? '0rem' : '0.5rem',
			...overrides?.panel,
		}),
		props: {
			'data-qa': dataQa,
		},
	},
	Header: {
		style: ({ $expanded }: any) => ({
			borderBottomRightRadius: $expanded ? '0rem' : '0.5rem',
			borderBottomLeftRadius: $expanded ? '0rem' : '0.5rem',
			borderTopLeftRadius: '0.5rem',
			borderTopRightRadius: '0.5rem',
			borderBottom: 'none',
			boxShadow: '0 0.125rem 0.125rem 0 #00000014',
			...overrides?.header,
		}),
	},
	Content: {
		style: ({ $theme }: any) => ({
			backgroundColor: $theme.colors.primaryB,
			borderBottomRightRadius: '0.5rem',
			borderBottomLeftRadius: '0.5rem',
			...overrides?.content,
		}),
	},
	ToggleIcon: {
		component: (props: any) => (
			<div style={{ marginRight: '0.5rem' }}>
				{props.title === 'Collapse' ? <DropDownToggleDown /> : <DropDownToggleUp />}
			</div>
		),
	},
	...overrides?.style,
});

const generalAccordionOverride = (overrides: any, usage: any, dataQa?: string) => ({
	ToggleIcon: {
		component: (props: any) => (
			<div style={{ marginRight: '1rem', ...overrides?.toggleIcon }}>
				{props.title === 'Collapse' ? (
					<DropDownToggleDown size='20' position='6' />
				) : (
					<DropDownToggleUp size='20' position='6' />
				)}
			</div>
		),
	},
	Header: {
		style: ({ $expanded, $theme }: any) => ({
			backgroundColor: 'transparent',
			borderBottomWidth: $expanded ? '0px' : '1px',
			borderBottomColor: $theme.colors.accent100,
			fontWeight: 600,
			fontSize: '0.875rem',
			lineHeight: '1.25rem',
			paddingLeft: '0',
			paddingRight: '0',
			paddingTop: '0.875rem',
			paddingBottom: '0.875rem',
			...overrides?.header,
		}),
	},
	Content: {
		style: ({ $expanded, $theme }: any) =>
			$expanded
				? {
						borderTopLeftRadius: '0',
						borderTopRightRadius: '0',
						borderBottomLeftRadius: '0',
						borderBottomRightRadius: '0',
						paddingBottom: '0rem',
						paddingTop: '0.5rem',
						paddingRight: '0rem',
						paddingLeft: '0rem',
						marginBottom: '0rem',
						overflowY: 'auto',
						backgroundColor: 'transparent',
						borderBottomColor: $theme.colors.horizantalDividerColor,
						...overrides?.expanded?.content,
					}
				: {
						borderTopLeftRadius: '0',
						borderTopRightRadius: '0',
						borderBottomLeftRadius: '0',
						borderBottomRightRadius: '0',
						backgroundColor: $theme.accent300,
						...overrides?.content,
					},
	},
	PanelContainer: {
		style: ({ $theme }: any) => ({
			backgroundColor: 'transparent',
			...overrides?.panel,
		}),
		props: {
			'data-qa': dataQa,
		},
	},
	Root: {
		style: ({ $theme }: any) => ({
			borderTopWidth: '1px',
			borderTopStyle: 'solid',
			borderTopColor: $theme.colors.horizantalDividerColor,
			...overrides?.root,
		}),
	},
	...overrides?.style,
});

function ENGTAccordion({
	type,
	children,
	errorId = '',
	expandedPanel,
	fieldIds = [],
	overrides = {},
	usage = 'primary',
	dataQa,
	accordion = true,
	onChange: onChangeFn,
	expandLastPanel = true,
}: IENGTAccordion) {
	const [allIds, setAllIds] = useState<any[]>([]);
	const [expanded, setExpanded] = useState<any[]>([expandedPanel]);

	/**
	 * Handle Empty Accordion top border
	 */
	if (!children || (children instanceof Array && children.length == 0)) {
		overrides = merge({}, { root: { borderTopWidth: 0 } }, overrides);
	}

	useEffect(() => setExpanded([expandedPanel]), [expandedPanel]);

	useEffect(() => {
		if (allIds.length !== fieldIds.length) {
			setAllIds(fieldIds);
		}
	}, [fieldIds]);

	useEffect(() => {
		expandLastPanel && allIds && allIds.length && setExpanded([allIds[allIds.length - 1]]);
	}, [allIds]);

	useEffect(() => {
		errorId && allIds && allIds.length && allIds[errorId] && setExpanded(allIds[errorId]);
	}, [errorId, allIds]);

	const onChange = ({ expanded }: any) => {
		setExpanded(expanded);
		onChangeFn && onChangeFn({ expanded });
	};

	return (
		<StatelessAccordion
			expanded={expanded}
			onChange={onChange}
			renderAll
			overrides={
				type === 'block'
					? blockAccordionOverride(overrides, dataQa)
					: generalAccordionOverride(overrides, usage, dataQa)
			}
			accordion={accordion}
		>
			{children}
		</StatelessAccordion>
	);
}
export default ENGTAccordion;
