import { useMediaQuery } from 'react-responsive';

const useResponsiveSize = () => {
	const isSmallTabletScreen = useMediaQuery({ query: '(max-width: 700px)' });
	const isSmallDesktopScreen = useMediaQuery({ query: '(max-width: 900px)' });

	return { isSmallDesktopScreen, isSmallTabletScreen };
};

export default useResponsiveSize;
