import { useState } from 'react';
import { useStyletron } from 'baseui';
import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { ENGATI_RESPONSE_CODE } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { CHANNELS } from 'shared/enum';
import { fileDownload, isValidResponseObject, validateFileName, WHATSAPP_PROVIDERS } from 'shared/helpers';
import { errorToaster } from 'shared/helpers/broadcastHelpers';

import { isBroadcastV2EntitlementEnabled, isWhatsAppConfiguredSelector } from 'store/App/User/selectors';
import { botRefSelector } from 'store/Bot/selectors';
import { API } from 'store/Broadcast/api';
import { API as WhatsappConfigAPI } from 'store/Configure/Deployment/api';
import {
	downloadBroadcastTargetUsersDataAction,
	downloadBroadcastUsersDataForV2Action,
	pollForExportBroadcastTargetStatusAction,
} from 'store/Download/actions';
import { openErrorSavingModalAction } from 'store/Modal/actions';
import { usersAPI } from 'store/Users/api';
import { customerIdSelector, userId } from 'store/Users/selectors';

function useBroadcastListHook() {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common', 'pages']);
	const dispatch = useDispatch<any>();

	const [isContactSupportModalOpen, setContactSupportModal] = useState<boolean>(false);
	const [retargetSegmentUsers, setRetargetSegmentUsers] = useState<number>(0);
	const [isRetryModalLoading, setRetryModalLoading] = useState<boolean>(false);
	const [retryBroadcastData, setRetryBroadcastData] = useState<IObjProps>({});
	const [isRetryModalOpen, setRetryModal] = useState<boolean>(false);

	const cid = useSelector(customerIdSelector);
	const uid = useSelector(userId);
	const botRef = useSelector(botRefSelector);
	const isWhatsappConfigured = useSelector(isWhatsAppConfiguredSelector);
	const isBroadcastV2Enabled = useSelector(isBroadcastV2EntitlementEnabled);

	const getTargetUserCount = (id: string, platform: Array<string>, segments: Array<string>) => {
		const params = {
			targetApi: isBroadcastV2Enabled ? 'retargetOptionsV2' : 'retargetOptions',
		};
		const payload = {
			customerId: cid,
			botRef,
			broadcastId: id,
			platform: platform.includes(CHANNELS.DIALOG360) && CHANNELS.DIALOG360,
			...((platform.includes(CHANNELS.DIALOG360) || platform.includes(CHANNELS.WHATSAPP_ALL_CAPS)) && {
				apiKey: '{{apiKey}}',
			}),
		};

		isWhatsappConfigured &&
			API.retargetOptions(payload, params).then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					const treeData = [response.data.responseObject];
					const userCount: IObjProps = {};
					const modifyingResponse = (response: Array<IObjProps>) => {
						response.map((obj) => {
							userCount[obj.value] = obj?.userCount;
							if (obj?.children?.length > 0) {
								modifyingResponse(obj.children);
							}
						});
					};
					modifyingResponse(treeData);
					const targetUsers = segments.reduce((accumulator, segment) => accumulator + userCount[segment], 0);
					setRetargetSegmentUsers(targetUsers);
				} else {
					errorToaster();
				}
			});
	};

	const getWhatsappConfigration = (setHasDialog360Provider: Function) => {
		WhatsappConfigAPI.getWhatsappConfigration().then((response: IObjProps) => {
			if (isValidResponseObject(response)) {
				const overriddenMap = response.data.responseObject.integration_overridden_map;
				if (Object.keys(overriddenMap).includes(WHATSAPP_PROVIDERS.dialog360)) {
					setHasDialog360Provider(true);
				}
			}
		});
	};

	const downloadUsersForExternalBroadcast = (data: any) => {
		const params = {
			broadcast_id: data.broadcastId,
			targetApi: 'getFileToken',
		};
		API.getFileToken(params).then((response: any) => {
			if (response?.data?.responseObject) {
				usersAPI
					.downloadImportHistory({
						token: response.data.responseObject,
						targetApi: 'downloadImportHistory',
					})
					.then((response: any) => {
						if (response.data) {
							fileDownload(response.data, `${data.broadcastId}.csv`);
						}
					});
			} else {
				dispatch(openErrorSavingModalAction());
			}
		});
	};

	const exportData = (data: any) => {
		const exportData = {
			customerId: cid,
			userId: uid,
			broadcastId: data.broadcastId,
			fileName: `${validateFileName(data.broadcastTitle)}.csv`,
			workflow: 'BROADCAST_ANALYTICS',
			botRef,
			rowId: 0,
			broadcastVersion: isBroadcastV2Enabled ? 'V2' : 'V1',
		};

		API.exportData(exportData)
			.then((resp: any) => {
				if (resp?.data?.status) {
					if (resp.data.status.code === ENGATI_RESPONSE_CODE.STATUS_28003) {
						toaster.positive(
							<ENGTToasterContainer
								title={t('pages:broadcast.analytics.toasterAlreadyInProgressHeading')}
								description={t('pages:broadcast.analytics.toasterAlreadyInProgressDescription')}
							/>,
							{}
						);
					} else if (isValidResponseObject(resp)) {
						const fileName = exportData.fileName.concat('.gz');
						const rowId = resp.data.responseObject;
						dispatch(
							pollForExportBroadcastTargetStatusAction(
								exportData.broadcastId,
								botRef,
								rowId,
								fileName,
								0,
								'',
								exportData.broadcastVersion
							)
						);

						toaster.positive(
							<ENGTToasterContainer
								title={t('pages:broadcast.analytics.toasterAlreadyInProgressHeading')}
								description={t('pages:broadcast.analytics.toasterStartingExportBroadcastDescription', {
									time:
										(data.targetUserCount || data.subscribedUserCount) > 25000
											? t('pages:broadcast.analytics.moreThan20')
											: (data.targetUserCount || data.subscribedUserCount) > 20000
												? '20'
												: (data.targetUserCount || data.subscribedUserCount) > 15000
													? '16'
													: (data.targetUserCount || data.subscribedUserCount) > 10000
														? '12'
														: (data.targetUserCount || data.subscribedUserCount) > 5000
															? '8'
															: (data.targetUserCount || data.subscribedUserCount) > 1000
																? '4'
																: '1',
								})}
							/>,
							{}
						);
					} else {
						toaster.negative(
							<ENGTToasterContainer
								title={t('common:error')}
								description={t('common:somethingWentWrong')}
							/>,
							{}
						);
					}
				}
			})
			.catch(() => {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			});
	};

	const getRetryBroadcast = (broadcastId: string) => {
		setRetryModalLoading(true);
		const params = {
			replaceable_params: {
				broadcastId,
			},
			targetApi: 'retryBroadcastData',
		};
		API.getRetryBroadCastDetails(params).then(
			(response: any) => {
				if (response) {
					setRetryModalLoading(false);
					const retryBroadCastDataResponse = response.data;
					setRetryBroadcastData(retryBroadCastDataResponse);
					if (
						retryBroadCastDataResponse?.allowedRetries >
						Object.keys(retryBroadCastDataResponse?.retryMap).length
					) {
						setRetryModal(true);
					} else {
						setContactSupportModal(true);
					}
				} else {
					setRetryModalLoading(false);
					setContactSupportModal(true);
				}
			},
			() => {
				setRetryModalLoading(false);
				setContactSupportModal(true);
			}
		);
	};

	const stopAutoRetryBroadcast = (broadcastId: string) => {
		const params = {
			targetApi: 'stopAutoRetryBroadcast',
			broadcastId,
		};
		API.stopAutoRetryBroadcastForV2({}, params)
			.then((resp: any) => {
				if (resp?.data?.responseObject) {
					toaster.positive(
						<ENGTToasterContainer title={t('common:success')} description='Stopped Auto Retry' />,
						{}
					);
				} else {
					toaster.negative(
						<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
						{}
					);
				}
			})
			.catch(() => {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			})
			.then(() => setRetryModalLoading(false));
	};

	const retryFailedUsers = (broadcastId: string) => {
		const data = {
			customerId: cid,
			userId: uid,
			parentBotRef: botRef,
			broadcastId,
		};
		setRetryModalLoading(true);
		setRetryModal(false);
		setContactSupportModal(false);
		const retryBroadcastAPI = isBroadcastV2Enabled ? API.retryBroadcastForV2(data) : API.retryBroadcast(data);
		retryBroadcastAPI
			.then((resp: any) => {
				if (resp?.data?.status?.code === ENGATI_RESPONSE_CODE.STATUS_1000) {
					toaster.positive(
						<ENGTToasterContainer
							title={t('common:success')}
							description={t('pages:broadcast.basic.retrySuccessMessage')}
						/>,
						{}
					);
				} else {
					toaster.negative(
						<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
						{}
					);
				}
			})
			.catch(() => {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			})
			.then(() => setRetryModalLoading(false));
	};

	const isETAAfterTwoHours = (data: string) => {
		const date = new Date(data);
		date.setHours(date.getHours() + 2);

		return new Date() >= date;
	};

	const downloadTargetUsers = (data: any) => {
		const bodyData = {
			customerId: cid,
			userId: uid,
			botRef,
			broadcastId: data?.broadcastId,
			workflow: 'DOWNLOAD_BROADCAST_TOTAL_USERS',
			fileName: `${validateFileName(data.broadcastTitle)}_broadcast_total_users.csv`,
			broadcastVersion: isBroadcastV2Enabled ? 'V2' : 'V1',
		};
		isBroadcastV2Enabled
			? dispatch(downloadBroadcastUsersDataForV2Action(bodyData))
			: dispatch(downloadBroadcastTargetUsersDataAction(bodyData));
		toaster.positive(
			<ENGTToasterContainer title={t('common:success')} description={t('common:downloadInfo')} />,
			{}
		);
	};

	const downloadFailedUsers = (broadcastData: any) => {
		const data = {
			customerId: cid,
			userId: uid,
			botRef,
			broadcastId: broadcastData.broadcastId,
			workflow: 'DOWNLOAD_BROADCAST_FAILED_USERS',
			fileName: `${validateFileName(broadcastData.broadcastTitle)}_broadcast_failed_users.csv`,
			broadcastVersion: isBroadcastV2Enabled ? 'V2' : 'V1',
		};
		isBroadcastV2Enabled
			? dispatch(downloadBroadcastUsersDataForV2Action(data))
			: dispatch(downloadBroadcastTargetUsersDataAction(data));
		toaster.positive(
			<ENGTToasterContainer title={t('common:success')} description={t('common:downloadInfo')} />,
			{}
		);
	};

	return {
		retargetSegmentUsers,
		retryBroadcastData,
		isRetryModalLoading,
		isRetryModalOpen,
		isContactSupportModalOpen,
		getTargetUserCount,
		exportData,
		getRetryBroadcast,
		setRetryModal,
		retryFailedUsers,
		isETAAfterTwoHours,
		downloadTargetUsers,
		downloadFailedUsers,
		setContactSupportModal,
		downloadUsersForExternalBroadcast,
		getWhatsappConfigration,
		stopAutoRetryBroadcast,
	};
}

export default useBroadcastListHook;
