import { isEmpty, serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const executeIntegrationUrl = '/execute';
const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const getAllColumnsUrl = '/api/v2/columns';
const getAllIntegrationsUrl = '/getIntegrations';
const getWorkFlowUrl = '/getWorkflow';
const getIntegrationAccDetailsUrl = '/getIntegrationAccountDetails';
const getUserAttributesUrl = '/api/v2/user-info-attributes';
const flowAttributesUrl = '/api/v1/user/getFlowAttributes';
const tagsIntentsUrl = '/api/v1/tags-intents';
const additionalAttributesUrl = '/api/v1/user/attribute-values';

const allColumnsApi = new BaseService(getAllColumnsUrl);
const executeIntegrationApi = new BaseService(executeIntegrationUrl, {
	headers: { 'Content-Type': undefined },
});
const getAllIntegrationsApi = new BaseService(getAllIntegrationsUrl);
const getWorkFlowApi = new BaseService(getWorkFlowUrl);
const getIntegrationAccDetailsApi = new BaseService(getIntegrationAccDetailsUrl);
const generalProxyApi = new BaseService(genericProxyRequestUrl);
const flowAttributesApi = new BaseService(flowAttributesUrl);
const additionalAttributesApi = new BaseService(additionalAttributesUrl);

const notesApi = new BaseService(genericProxyRequestUrl, {
	headers: {
		'Content-Type': 'application/json',
		'audit-metadata': '{{audit-metadata}}',
	},
});

const restrictAttributesTo2000Chars = (params) => {
	if (params && params.length) {
		let paramLength = 0;
		const modifiedParamList = [];
		for (let i = 0; i < params.length; i++) {
			paramLength += 9 + params[i].length;
			if (paramLength < 2000) {
				modifiedParamList.push(params[i]);
			} else {
				break;
			}
		}

		return modifiedParamList.map((n) => `columns=${n}`).join('&');
	}

	return '';
};

export const messageDetailsAPI = {
	addNote(data) {
		const url = `${genericProxyRequestUrl}${serialize({ targetApi: 'notes' }, '?')}`;
		const addNoteApi = new BaseService(url);

		return addNoteApi.postRequest(data);
	},
	deleteNote(params) {
		return notesApi.deleteRequest(serialize(params, '?'));
	},
	executeIntegration(params) {
		return executeIntegrationApi.putRequest(params);
	},
	getAllColumns(params) {
		return allColumnsApi.getRequest(serialize(params, '?'));
	},
	getAllIntegrations(params) {
		const { category_list, ...res } = params;
		const param = serialize(res, '?') + category_list.reduce((a, d) => `${a}&category_list=${d}`, '');

		return getAllIntegrationsApi.getRequest(param);
	},
	getConfigForWorkflow(accountDetails, workflowKey, params = {}) {
		const { accountId, integrationKey } = accountDetails;

		const headers = {
			integrationKey,
			workflowKey,
			accountId,
		};

		return new BaseService('/getConfigforWorkflow', {
			headers,
		}).getRequest(isEmpty(params) ? '' : serialize(params, '?'));
	},
	getWorkFlow(integrationKey) {
		const params = {
			integrationKey,
		};

		return getWorkFlowApi.getRequest(serialize(params, '?'));
	},
	getIntegrationAccountDetails(integrationKey) {
		const data = {
			integrationKey: [integrationKey],
			scope: 'PUBLIC',
			platform: 'ENGATI_CONNECT',
		};

		return getIntegrationAccDetailsApi.putRequest(data);
	},
	getBotMappedIntegrationAccount(integrationKey) {
		const params = {
			targetApi: 'getBotMappedIntegrationAccount',
			integrationKey,
			userId: '{{userId}}',
			botRef: '{{botRef}}',
			customerId: '{{customerId}}',
		};

		return generalProxyApi.getRequest(serialize(params, '?'));
	},
	getNotes(params) {
		return notesApi.getRequest(serialize(params, '?'));
	},
	getUserAttributes: (params) => {
		let url = `${getUserAttributesUrl}?${restrictAttributesTo2000Chars(params.columns)}`;
		url = `${url}&id=${params.id}&conversationId=${params.conversationId}`;
		const getUserAttributesApi = new BaseService(url);

		return getUserAttributesApi.getRequest();
	},
	getRecentConversations: (params) => {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const getUserMessagesApi = new BaseService(url);

		return getUserMessagesApi.getRequest();
	},
	updateUserDetails(params, data) {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const updateUserDetailsApi = new BaseService(url, {
			headers: {
				'Content-Type': 'application/json',
				'audit-metadata': '{{audit-metadata}}',
			},
		});

		return updateUserDetailsApi.putRequest(data);
	},
	updateCustomAttribute(data, userId) {
		const params = {
			targetApi: 'updateCustomAttributeValue',
			replaceable_params: {
				userId,
			},
		};

		return generalProxyApi.putRequest(data, serialize(params, '?'));
	},
	getExotelIntegration(agentEmail, ownerEmail) {
		const params = {
			targetApi: 'exotelIntegrationOperations',
			agentEmail,
			ownerEmail,
		};

		return generalProxyApi.getRequest(serialize(params, '?'));
	},
	getGloabalFlowAttributes() {
		const params = {
			is_settable: false,
			user_type: 'GLOBAL',
		};

		return flowAttributesApi.getRequest(serialize(params, '?'));
	},
	updateIntentsPerConversation(data) {
		const params = {
			targetApi: 'updateIntents',
		};

		return generalProxyApi.putRequest(data, serialize(params, '?'));
	},
	getUserDetails(data, cancelToken) {
		const getUserDetailsApi = new BaseService(genericProxyRequestUrl, cancelToken);
		const params = {
			targetApi: 'getUserDetails',
		};

		return getUserDetailsApi.postRequest(data, serialize(params, '?'));
	},
	updateTagsPerConversation(data) {
		const params = {
			targetApi: 'updateTags',
		};

		return generalProxyApi.putRequest(data, serialize(params, '?'));
	},
	getTagsIntents(params, cancelToken) {
		const tagsIntentsApi = new BaseService(tagsIntentsUrl, cancelToken);

		return tagsIntentsApi.getRequest(serialize(params, '?'));
	},
	getLabelAuditData(params) {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const getLabelAuditDataApi = new BaseService(url);

		return getLabelAuditDataApi.getRequest();
	},
	getAdditionalAttributes(params, data) {
		return additionalAttributesApi.postRequest(data, serialize(params, '?'));
	},
};
