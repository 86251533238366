import React from 'react';

import { ENGT_ALERT_NOTIFICATION, ENGT_SAMPLE_NOTIFICATION } from 'shared/consts/consts';

function SoundNotification() {
	return (
		<div style={{ display: 'none' }}>
			<audio id='alert-notification' src={ENGT_ALERT_NOTIFICATION} preload='auto' controls />
			<audio id='sample-notification' src={ENGT_SAMPLE_NOTIFICATION} preload='auto' controls />
		</div>
	);
}

export default React.memo(SoundNotification);
