import { IObjProps } from 'shared/consts/types';

import { IPreferencesDetailStateInterface, IThemeProps } from './state';

export const preferenceReducer = {
	getAgentSetting: (state: IPreferencesDetailStateInterface, action: { payload: IObjProps }) => {
		state.notifications = {
			...action.payload,
		};
	},
	setAgentSetting: (state: IPreferencesDetailStateInterface, action: { payload: IObjProps }) => {
		state.notifications = {
			...action.payload,
		};
	},
	setThemeSettings: (state: IPreferencesDetailStateInterface, action: { payload: IThemeProps }) => {
		state.theme = action.payload;
	},
	setToasterDuration: (state: IPreferencesDetailStateInterface, action: { payload: number }) => {
		state.toaster.toastDuration = action.payload;
	},
};
