import React from 'react';

function ClearTextIcon({ fillColor, size }: { fillColor: string; size?: number }) {
	return (
		<svg width={size || 10} height={size || 10} viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.28762 0.227025L0.226963 1.28769L3.93928 5L0.226964 8.71232L1.28762 9.77298L4.99994 6.06066L8.71224 9.77297L9.7729 8.71231L6.0606 5L9.77291 1.28769L8.71225 0.227034L4.99994 3.93934L1.28762 0.227025Z'
				fill={fillColor}
			/>
		</svg>
	);
}

export default ClearTextIcon;
