import BaseService from 'services/baseService';
/* eslint-disable import/prefer-default-export */
import { REACT_APP_API_GATEWAY_BASE_URL, REACT_APP_BASE_URL } from 'shared/config/config';
import { serialize } from 'shared/helpers';

const botAdminUrl = '/api/v1/bot_admin';
const changeAdminUserUrl = '/updateLoginUserByAdmin';
const changeCustomerToAdminUrl = '/updateLoginUserByCustomer';
const profileApiUrl = '/admin/profile/';
const v1GenericProxyUrl = '/genericProxyRequest/';
const genericProxyUrl = '/v2/genericProxyRequest/';
const getPlatformMapsUrl = '/api/v1/getPlatformMap/customer';
const securityConfigUrl = genericProxyUrl + serialize({ targetApi: 'getSecurityConfig' }, '?');
const getIntegrationAccountDetailsUrl = '/getIntegrationAccountDetails';
const getCustomerEnforcementSettingsUrl = '/api/v1/customerEnforcementsettings';
const getWASupportBotDetailUrl = REACT_APP_BASE_URL + '/webchat_parameters/whatsapp-config/';
const apiGetWayGeneralProxyUrl = REACT_APP_API_GATEWAY_BASE_URL;

const botAdminApi = new BaseService(botAdminUrl);
const changeAdminUserApi = new BaseService(changeAdminUserUrl);
const changeCustomerToAdminApi = new BaseService(changeCustomerToAdminUrl);
const genericProxyRequestApi = new BaseService(genericProxyUrl);
const v1GenericProxyRequestApi = new BaseService(v1GenericProxyUrl);
const profileApi = new BaseService(profileApiUrl);
const securityConfigApi = new BaseService(securityConfigUrl);
const getIntegrationAccountDetailsApi = new BaseService(getIntegrationAccountDetailsUrl);
const getCustomerEnforcementSettingsApi = new BaseService(getCustomerEnforcementSettingsUrl);
const apiGetWayGeneralProxyApi = new BaseService(apiGetWayGeneralProxyUrl);
const getWASupportBotDetailsApi = new BaseService(getWASupportBotDetailUrl);

export const API = {
	changeAdminUser(email) {
		return changeAdminUserApi.postRequest({ email });
	},
	changeCustomerToAdmin() {
		return changeCustomerToAdminApi.postRequest();
	},
	fetchBotAdmin() {
		return botAdminApi.getRequest();
	},
	fetchProfileDetails() {
		return profileApi.getRequest();
	},
	fetchSecurityConfigDetails() {
		return securityConfigApi.getRequest();
	},
	fetchSearchAccountToManage(query) {
		const params = {
			query,
			targetApi: 'searchActiveBotUsers',
			botRef: '{{botRef}}',
		};

		const data = ['GET_LIVE_CHAT'];

		return genericProxyRequestApi.postRequest(data, serialize(params, '?'));
	},
	checkIfCustomerSubscriptionExists() {
		const params = {
			targetApi: 'checkIfCustomerSubscriptionExists',
		};

		return apiGetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	checkIfStripeIdExistsInCustomerDetails() {
		const params = {
			targetApi: 'checkIfStripeIdExistsInCustomerDetails',
		};

		return apiGetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	getJourneyPhaseData(params) {
		return apiGetWayGeneralProxyApi.putRequest(null, serialize(params, '?'));
	},
	updateJourneyPhaseData(data) {
		const params = {
			targetApi: 'updateJourneyPhase',
		};

		return apiGetWayGeneralProxyApi.putRequest(data, serialize(params, '?'));
	},
	updateCustomerOnboardingJourney(data) {
		const params = {
			targetApi: 'updateCustomerOnboardingJourney',
		};

		return apiGetWayGeneralProxyApi.putRequest(data, serialize(params, '?'));
	},
	generateCustomFilter(query) {
		return v1GenericProxyRequestApi.putRequest(query);
	},
	getCategories(params) {
		const url = `${genericProxyUrl}${serialize(params, '?')}`;
		const getCategoriesApi = new BaseService(url);

		return getCategoriesApi.getRequest();
	},
	getPermittedPlatformsForCustomer: () => {
		const getPlatformMapsUrlWithParam = `${getPlatformMapsUrl}?active_module=ENGATI_CONNECT`;
		const getPlatformMapsApi = new BaseService(getPlatformMapsUrlWithParam);

		return getPlatformMapsApi.getRequest();
	},
	getIntegrationAccountDetails(payload) {
		return getIntegrationAccountDetailsApi.putRequest(payload);
	},
	getCustomerEnforcementDetails() {
		return getCustomerEnforcementSettingsApi.getRequest();
	},
	getShopifyAppInstallationStatus(email) {
		const params = {
			targetApi: 'shopifyAppInstallationStatus',
			email,
		};

		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
	fetchCustomersPhoneNumber() {
		const params = {
			targetApi: 'getCustomerContactNo',
		};

		return apiGetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	saveSellersAssistUser(data) {
		const params = {
			...data,
			targetApi: 'saveSellersAssistUser',
		};
		return new BaseService(apiGetWayGeneralProxyUrl + serialize(params, '?')).postRequest();
	},
	saveCustomerFeedback(data) {
		const params = {
			targetApi: 'saveCustomerFeedback',
		};
		return apiGetWayGeneralProxyApi.postRequest(data, serialize(params, '?'));
	},
	getStoreRegistrationStatus(data) {
		const params = {
			...data,
			targetApi: 'getStoreRegistrationStatus',
		};
		return new BaseService(apiGetWayGeneralProxyUrl + serialize(params, '?')).getRequest();
	},
	deleteSellersAssistUser(data) {
		const params = {
			...data,
			targetApi: 'deleteSellersAssistUser',
		};
		return new BaseService(apiGetWayGeneralProxyUrl + serialize(params, '?')).deleteRequest();
	},
	getUsersForPlatform(data) {
		const params = {
			...data,
			targetApi: 'getUsersForPlatform',
		};
		return new BaseService(apiGetWayGeneralProxyUrl + serialize(params, '?')).getRequest();
	},
	getWASupportBotDetails(botIdentifier) {
		return getWASupportBotDetailsApi.getRequest(botIdentifier);
	},
};
