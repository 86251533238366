import { deleteFromLs, getUniqueLSKey, LS_KEYS, readFromLs, writeToLs } from 'shared/consts/localStorageConsts';
import { IObjProps } from 'shared/consts/types';

import { RootState } from 'store/rootReducer';

export const messageLiteListActions: any = {
	getLiteFacetsSortFromLsMyConversation: () => (dispatch: any, getState: () => RootState) =>
		JSON.parse(
			localStorage.getItem(
				`oneviewlite_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}_myconversation`
			) || '{}'
		),
	getLiteFacetsSortFromLs: () => (dispatch: any, getState: () => RootState) =>
		JSON.parse(
			localStorage.getItem(`oneviewlite_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`) ||
				'{}'
		),
	setLiteFacetsSortInLsMyConversation: (filters: IObjProps) => (dispatch: any, getState: () => RootState) => {
		localStorage.setItem(
			`oneviewlite_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}_myconversation`,
			JSON.stringify({ ...filters })
		);
	},
	setLiteFacetsSortInLs: (filters: IObjProps) => (dispatch: any, getState: () => RootState) => {
		localStorage.setItem(
			`oneviewlite_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`,
			JSON.stringify({ ...filters })
		);
	},
	removeLiteFacetsSortFromLsMyConversation: () => (dispatch: any, getState: () => RootState) => {
		localStorage.removeItem(
			`oneviewlite_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}_myconversation`
		);
	},
	removeLiteFacetsSortFromLs: () => (dispatch: any, getState: () => RootState) => {
		localStorage.removeItem(`oneviewlite_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`);
	},
	getLiteQuickFiltersFromLS: () => (dispatch: any, getState: () => RootState) =>
		JSON.parse(
			localStorage.getItem(
				`oneviewlite_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}_quick_filters`
			) || '{}'
		),
	removeLiteQuickFiltersFromLS: () => (dispatch: any, getState: () => RootState) => {
		localStorage.removeItem(
			`oneviewlite_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}_quick_filters`
		);
	},
	setLiteQuickFiltersInLs: (filters: IObjProps) => (dispatch: any, getState: () => RootState) => {
		localStorage.setItem(
			`oneviewlite_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}_quick_filters`,
			JSON.stringify({ ...filters })
		);
	},
	setShortcutQuickFilterLiteToLs: (data: any) => (dispatch: any, getState: () => RootState) =>
		writeToLs(
			getUniqueLSKey(
				LS_KEYS.SHORTCUT_QUICK_FILTERS,
				getState().Bot.data.bot_ref,
				getState().User.botAdmin.data.uid
			),
			data
		),
	getShortcutQuickFilterLiteFromLs: () => (dispatch: any, getState: () => RootState) =>
		readFromLs(
			getUniqueLSKey(
				LS_KEYS.SHORTCUT_QUICK_FILTERS,
				getState().Bot.data.bot_ref,
				getState().User.botAdmin.data.uid
			)
		),
	removeShortcutQuickFilterLiteFromLs: () => (dispatch: any, getState: () => RootState) =>
		deleteFromLs(
			getUniqueLSKey(
				LS_KEYS.SHORTCUT_QUICK_FILTERS,
				getState().Bot.data.bot_ref,
				getState().User.botAdmin.data.uid
			)
		),
	setShortcutQuickFilterLiteToLsMyConversation: (data: any) => (dispatch: any, getState: () => RootState) =>
		writeToLs(
			getUniqueLSKey(
				LS_KEYS.SHORTCUT_MY_CONVERSATION_FILTER,
				getState().Bot.data.bot_ref,
				getState().User.botAdmin.data.uid
			),
			data
		),
	getShortcutQuickFilterLiteFromLsMyConversation: () => (dispatch: any, getState: () => RootState) =>
		readFromLs(
			getUniqueLSKey(
				LS_KEYS.SHORTCUT_MY_CONVERSATION_FILTER,
				getState().Bot.data.bot_ref,
				getState().User.botAdmin.data.uid
			)
		),
	removeShortcutQuickFilterLiteFromLsMyConversation: () => (dispatch: any, getState: () => RootState) =>
		deleteFromLs(
			getUniqueLSKey(
				LS_KEYS.SHORTCUT_MY_CONVERSATION_FILTER,
				getState().Bot.data.bot_ref,
				getState().User.botAdmin.data.uid
			)
		),
};
