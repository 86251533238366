import { ReactNode } from 'react';
import { Block } from 'baseui/block';
import { Heading, HeadingLevel } from 'baseui/heading';
import { Paragraph2 } from 'baseui/typography';
import { useStyletron } from 'styletron-react';

import GotoPreviousScreen from 'components/UI/GotoPreviousScreen/GotoPreviousScreen';

import { BACK_BUTTON_IN_SHOPIFY_INTEGRATION_PAGE } from 'shared/consts/QAConsts';
import { IObjProps } from 'shared/consts/types';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

interface HEADER {
	primary: 'primary';
	secondary: 'secondary';
}

export interface IPageHeaderProps {
	/* Page Heading */
	heading: string | React.ReactNode;

	headingCssOverride?: IObjProps;

	headerWrapperOverrides?: IObjProps;

	/* TODO: Need to remove this in futute: Try not to send this prop */
	marginTop?: string;

	/* Subheading or subtitle of the page */
	subHeading?: string | ReactNode;

	subHeadingCssOverride?: IObjProps;

	/* Backbutton label text showed after the left arrow */
	backButtonLable?: string;

	type?: keyof HEADER;

	dataQa?: string;

	overrides?: IObjProps;

	onBack?: Function;
}

export const HEADER_TYPES: HEADER = {
	primary: 'primary',
	secondary: 'secondary',
};

function PageHeader(props: IPageHeaderProps) {
	const {
		heading,
		headingCssOverride = {},
		headerWrapperOverrides = {},
		marginTop = 0,
		subHeading,
		subHeadingCssOverride = {},
		backButtonLable,
		overrides,
		type = 'primary',
		dataQa,
		onBack,
	} = props;

	const [css] = useStyletron();
	const { isSmallDesktopScreen } = useResponsiveSize();

	const getHeadingMarginBottom = () => {
		if (isSmallDesktopScreen) {
			return '0 !important';
		}
		if (subHeading) {
			return '0.75rem !important';
		}
		if (overrides?.marginBottom) {
			return overrides?.marginBottom;
		}

		return '2rem !important';
	};

	const getWrapperMarginBottom = () => {
		if (type === HEADER_TYPES.secondary) {
			return '0.75rem';
		}
		if (backButtonLable) {
			return '4rem';
		}

		return '6.25rem';
	};

	const fontConfig = {
		fontSize: '2rem !important',
		lineHeight: '2.5rem !important',
	};

	const headingCss: any = {
		marginRight: 0,
		marginLeft: 0,
		fontWeight: 'bold !important',
		marginBlockStart: '0',
		marginBlockEnd: '0',
		fontStyle: 'normal',
		textAlign: 'left',
		...fontConfig,
		...headingCssOverride,
	};

	return (
		<Block className={css(headerWrapperOverrides)} marginTop={[0, 0, 0, getWrapperMarginBottom()]}>
			<HeadingLevel>
				{backButtonLable && !isSmallDesktopScreen && (
					<GotoPreviousScreen
						onBack={onBack}
						heading={backButtonLable}
						dataQa={BACK_BUTTON_IN_SHOPIFY_INTEGRATION_PAGE}
					/>
				)}
				<Heading
					styleLevel={1}
					color='primaryA'
					className={css(headingCss)}
					marginTop={marginTop}
					marginBottom={headingCssOverride?.marginBottom ?? getHeadingMarginBottom()}
				>
					{heading}
				</Heading>
				{subHeading && <PageSubHeading subHeading={subHeading} cssOverrides={subHeadingCssOverride} />}
			</HeadingLevel>
		</Block>
	);
}

export const PageSubHeading = ({
	subHeading,
	cssOverrides,
	marginBottom,
}: {
	subHeading: string | ReactNode;
	cssOverrides?: IObjProps;
	marginBottom?: string | string[];
}) => {
	const [css] = useStyletron();

	const subHeadingCss = {
		opacity: 0.8,
		marginTop: 0,
		marginBottom: 0,
		fontSize: '0.9375rem !important',
		fontWeight: 'normal !important' as any,
		lineHeight: '1.25rem !important',
		...cssOverrides,
	};

	return (
		<Paragraph2 color='primaryA' className={css(subHeadingCss)} marginBottom={marginBottom}>
			{subHeading}
		</Paragraph2>
	);
};

export default PageHeader;
