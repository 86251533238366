import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

const EmptyDataTable = ({ children, overrides }: any) => {
	const [css, theme] = useStyletron();

	return (
		<Block
			display='flex'
			alignItems='center'
			width='100%'
			height='100%'
			justifyContent='center'
			className={css({
				color: theme.colors.primaryA,
				fontSize: '1.25rem!important',
				fontStyle: 'normal',
				fontWeight: 'bold' as const,
				textAlign: 'center' as const,
				overflow: 'hidden',
				marginTop: '3rem',
				textOverflow: 'ellipsis',
				whiteSpace: 'pre-wrap' as const,
				textAlignLast: 'center',
				...overrides,
			})}
		>
			{children}
		</Block>
	);
};

export default EmptyDataTable;
