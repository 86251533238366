import { RootState } from 'store/rootReducer';

import { IErrorSaving, IInfo, ISurveyModal, SurveyModule } from './state';
import { actions } from './store';

const {
	openErrorSavingModal,
	closeErrorSavingModal,
	openInfoModal,
	closeInfoModal,
	openSurveyModal,
	closeSurveyModal,
} = actions;

export const modalActions = {
	openErrorSavingModalAction: (errorCode?: any) => (dispatch: any, getState: () => RootState) => {
		const currentState: IErrorSaving = getState().Modal.errorSavingModal;
		if (!currentState.isOpen) {
			const state: IErrorSaving = {
				isOpen: true,
				errorCode: errorCode || null,
			};
			dispatch(openErrorSavingModal(state));
		}
	},
	closeErrorSavingModalAction: () => (dispatch: any) => {
		const state: IErrorSaving = {
			isOpen: false,
			errorCode: null,
		};
		dispatch(closeErrorSavingModal(state));
	},
	openInfoModalAction: (heading: string, description: any) => (dispatch: any) => {
		const state: IInfo = {
			isOpen: true,
			heading,
			description,
		};
		dispatch(openInfoModal(state));
	},
	closeInfoModalAction: () => (dispatch: any) => {
		const state: IInfo = {
			isOpen: false,
			heading: '',
			description: '',
		};
		dispatch(closeInfoModal(state));
	},
	openSurveyModalAction: (currency?: any) => (dispatch: any) => {
		const state: ISurveyModal = {
			isOpen: true,
			data: {
				module: SurveyModule.PRICING_PAGE_EXIT,
				currency,
			},
		};
		dispatch(openSurveyModal(state));
	},
	closeSurveyModalAction: (currency?: any) => (dispatch: any) => {
		const state: ISurveyModal = {
			isOpen: false,
			data: {
				module: SurveyModule.PRICING_PAGE_EXIT,
				currency,
			},
		};
		dispatch(closeSurveyModal(state));
	},
};

export const {
	openErrorSavingModalAction,
	openInfoModalAction,
	closeInfoModalAction,
	openSurveyModalAction,
	closeSurveyModalAction,
} = modalActions;
