import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { LabelSmall } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';
import ENGTCheckBox from 'components/UI/ENGTCheckBox/ENGTCheckBox';

import { BOT_MODE } from 'shared/helpers';

import { botSelect } from 'store/App/Bot/actions';
import { RootState } from 'store/withReducer';

export interface IModeToggleProps {
	navItem: any;
	setIsModalOpen: any;
	setIsLoading: any;
}

export const ModeToggle: React.FC<React.PropsWithChildren<IModeToggleProps>> = ({
	navItem,
	setIsModalOpen,
	setIsLoading,
	...props
}) => {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch<any>();
	const botMode = useSelector((state: RootState) => state.Bot.data.mode);
	const [isLiveToggle, setIsLiveToggle] = useState<boolean>(botMode === BOT_MODE.LIVE);
	const draftBotRef = useSelector((state: RootState) => state.Bot.data?.draft_bot_ref);
	const liveBotRef = useSelector((state: RootState) => state.Bot.data?.live_bot_ref);
	const currentBotRef = useSelector((state: RootState) => state.Bot.data?.bot_ref);
	const isLiveExist: boolean = liveBotRef && true;

	const botModeHandler = () => {
		isLiveToggle
			? botSelection(draftBotRef, botSelectionSuccessCallback)
			: isLiveExist
				? botSelection(liveBotRef, botSelectionSuccessCallback)
				: setIsModalOpen(true);
	};

	useEffect(() => {
		setIsLiveToggle(botMode === BOT_MODE.LIVE);
	}, [botMode]);

	const botSelection = (botRef: number, successCb: any) => {
		if (currentBotRef === botRef) {
			successCb(botRef);
		} else {
			setIsLoading(true);
			dispatch(botSelect(botRef, () => successCb(botRef)));
		}
	};

	let botSelectionSuccessCallback = (botRef: number) => {
		window.location.href = `${window.location.origin}/admin/bot/${botRef}/overview`;
	};

	return (
		<>
			<HorizontalDivider height='1rem' />
			<LabelSmall
				className={css({
					color: theme.colors.accent50,
					display: 'inline',
					fontSize: '0.7rem',
				})}
			>
				{t('pages:primaryNavigation.botMode')}
			</LabelSmall>
			<LabelSmall
				className={css({
					display: 'inline',
					fontWeight: 'bold',
					fontSize: '0.7rem',
				})}
			>
				{isLiveToggle ? t('pages:primaryNavigation.live') : t('pages:primaryNavigation.draft')}
			</LabelSmall>
			<div
				className={css({
					marginLeft: '1.7rem',
				})}
			>
				<ENGTCheckBox
					key={navItem}
					values={{
						isChecked: isLiveToggle,
						label: '',
					}}
					modeButton
					onChange={(value: any) => {
						isLiveExist && setIsLiveToggle(!value);
						botModeHandler();
					}}
					type='toggle_round'
				/>
			</div>
		</>
	);
};
