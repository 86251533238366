import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const currentPlanUrl = '/getCurrentPlan';
const customerDetails = '/v2/genericProxyRequest/';
const claimWhatsappUrl = '/v2/genericProxyRequest/';

const customerDetailsApi = new BaseService(customerDetails);
const currentPlanApi = new BaseService(currentPlanUrl);

export const API = {
	fetchCurrentPlan() {
		return currentPlanApi.getRequest();
	},
	fetchCustomerDetails() {
		const params = {
			targetApi: 'getCustomerDetail',
		};

		return customerDetailsApi.getRequest(serialize(params, '?'));
	},
	claimFreeWhatsapp(cid, uid) {
		const claimWhatsappApi = new BaseService(claimWhatsappUrl, {
			headers: {
				customerId: cid,
				userId: uid,
				'Content-Type': 'application/json',
			},
		});
		const params = {
			targetApi: 'sendWhatsappPromoMail',
		};

		return claimWhatsappApi.getRequest(serialize(params, '?'));
	},
};
