import { PAGING_DEFAULT_OBJ } from 'shared/consts/consts';
import { SOCIAL_INBOX_MEDIA_TYPES } from 'shared/consts/SocialInbox/constants';
import { IPaginatedApiStateProps, IPaginationState } from 'shared/consts/types';

export interface ICommentFrom {
	userId: string;
	userName: string;
	isPageOwner: boolean;
	profilePicUrl: string;
}

export interface IReplies {
	commentId: string;
	message: string;
	mediaType: SOCIAL_INBOX_MEDIA_TYPES;
	attachments: Array<string>;
	from: ICommentFrom;
	updatedOn: string;
	canReplyPrivately: boolean;
}

export interface IComment {
	commentId: string;
	message: string;
	mediaType: SOCIAL_INBOX_MEDIA_TYPES;
	attachments: Array<string>;
	repliesNextCursor: string;
	from: ICommentFrom;
	replies: IPaginatedApiStateProps<Array<IReplies>, IPaginationState>;
	updatedOn: string;
	canReplyPrivately: boolean;
	repliesSyncStatus: string;
	fetchedRepliesCount?: number;
}

export interface IPostCommentsInterface {
	listOfComments: IPaginatedApiStateProps<Array<IComment>, IPaginationState>;
	postedCommentIds: Array<string>;
}

export const postCommentsState: IPostCommentsInterface = {
	listOfComments: {
		data: [],
		error: false,
		loading: false,
		pagination: PAGING_DEFAULT_OBJ,
	},
	postedCommentIds: [],
};
