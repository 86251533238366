import { Fragment, ReactNode, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Panel } from 'baseui/accordion';
import { KIND, SIZE } from 'baseui/button';
import { TYPE } from 'baseui/select';
import { toaster } from 'baseui/toast';
import parse from 'html-react-parser';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ISegmentRules } from 'components/CustomFilter/CustomFilter';
import ENGTAccordion from 'components/UI/ENGTAccordion/ENGTAccordion';
import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import DefaultDatePicker from 'components/UI/ENGTDatePicker/DefaultDatePicker';
import ENGTRadio from 'components/UI/ENGTRadio/ENGTRadio';
import ENGTSpacer from 'components/UI/ENGTSpacer/ENGTSpacer';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import { FormInput } from 'components/UI/Form/FormInput/FormInput';
import FormLabel from 'components/UI/Form/FormLabel/FormLabel';
import FormSelect from 'components/UI/Form/FormSelect/FormSelect';
import FormTimePicker from 'components/UI/Form/FormTimePicker/FormTimePicker';

import { YES_OR_NO_FIELDS } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { debounce, getTimeFromDate, isValidResponseObject, setTimeToDate } from 'shared/helpers';
import MinusIcon from 'shared/icons/MinusIcon';
import { tagsNotAllowed } from 'shared/pathBuilderHelpers';

import { storeAPI } from 'store/Store/api';

import { CONDITIONS, JOIN_OPERATOR_MAPPINGS, TYPE_OPERATOR_MAPPINGS } from './TypeOperatorMappings';

interface IENGTConditional {
	channels: Array<any>;
	details: IObjProps;
	leftOperands: Array<any>;
	segmentType?: string;
	timerangeDays?: number;
	overrides?: IObjProps;
	customSegment?: boolean;
	setCustomSegment?: Function;
	isUserFilterEditMode?: boolean;
	isEditMode?: boolean;
	setIsUserFilterEditMode?: Function;

	setConditional?: (e: any) => any;
}

enum DATA_TYPE {
	TIME = 'TIME',
	DATE = 'DATE',
	DATETIME = 'DATETIME',
	PRODUCTTYPE = 'PRODUCTTYPE',
}

enum SEGMENT_TYPE {
	CUSTOMER = 'CUSTOMER',
}

const DEFAULT_RULE_OBJECT: ISegmentRules = {
	lhOperand: '',
	rhOperand: '',
	operator: '',
	operatorList: [],
};

const ENGTConditional = ({
	channels = [],
	details = {},
	segmentType,
	timerangeDays,
	isEditMode,
	leftOperands,
	overrides = {},
	customSegment,
	setCustomSegment,
	isUserFilterEditMode,
	setIsUserFilterEditMode,
}: IENGTConditional) => {
	const [css, theme]: any = useStyletron();
	const { inNextXdays, beforeXdays, lastXdays, before, after } = CONDITIONS();

	const { t } = useTranslation(['errors', 'common', 'components']);

	const methods = useFormContext();

	const { setValue, control, getValues, watch, errors, formState } = methods;
	const { submitCount } = formState;
	const {
		fields: segmentFormFields,
		append: addSegmentRule,
		remove: removeSegmentRule,
	} = useFieldArray({
		control,
		name: 'segmentRules',
		keyName: 'fid' as 'id',
	});
	const watchFieldArray = watch('segmentRules');
	const controlledFields = segmentFormFields.map((field, index) => ({
		...field,
		...watchFieldArray[index],
	}));
	const isCustomerSegmentType = () => segmentType === SEGMENT_TYPE.CUSTOMER;
	const timeRangeCheck = (value: number) => {
		if (isCustomerSegmentType() && timerangeDays && value <= timerangeDays && value > 0) {
			return true;
		}

		return t('errors:datePickerError');
	};

	const [formSubmitCount, setFormSubmitCount] = useState<number>(0);
	const [searchOptions, setSearchOptions] = useState<Array<{ id: string; label: string | ReactNode }>>([]);
	const [showLoader, setLoader] = useState<boolean>(false);
	const [joinOperator, setJoinOperator] = useState<any>(
		isUserFilterEditMode || customSegment || isEditMode
			? JOIN_OPERATOR_MAPPINGS[2]?.value
			: JOIN_OPERATOR_MAPPINGS[0]?.value
	);

	const renderRightOperand = (rule: any, index: number) => {
		const { lhOperand, operator } = rule;
		const numberField: Array<string> = [inNextXdays.id, beforeXdays.id, lastXdays.id];
		const dateField: Array<string> = [before.id, after.id];
		const debounceFn = debounce((data: string, callBack: (data: string) => void) => {
			callBack(data);
		}, 500);
		const fetchCustomerSegmentOptions = (query: string) => {
			setLoader(true);
			const data = {
				requestType: lhOperand?.[0]?.id,
				text: query,
			};
			storeAPI
				.getCustomerSegmentOptions(data)
				.then((response: any) => {
					setLoader(false);
					if (isValidResponseObject(response)) {
						const options: Array<any> = response.data.responseObject?.map((response: any) => ({
							id: response,
							label: response,
						}));
						setSearchOptions(options);
					} else {
						toaster.negative(
							<ENGTToasterContainer title={t('common:error')} description={t('errors:productTypes')} />,
							{}
						);
					}
				})
				.catch((error: any) => {
					toaster.negative(
						<ENGTToasterContainer title={t('common:error')} description={t('errors:productTypes')} />,
						{}
					);
					setLoader(false);
				});
		};

		const dataType = lhOperand && lhOperand[0]?.type;
		const operatorId = operator?.[0]?.id;

		const trueOrFalseOptions = [
			{
				id: 'TRUE',
				label: t('common:yes'),
			},
			{ id: 'FALSE', label: t('common:no') },
		];

		if (
			lhOperand?.[0]?.id &&
			(YES_OR_NO_FIELDS.indexOf(lhOperand[0].id) > -1 ||
				(lhOperand[0].id.indexOf('channel') > -1 && lhOperand[0].id !== 'channel_user_id'))
		) {
			return (
				<Controller
					control={control}
					name={`segmentRules[${index}].rhOperand`}
					defaultValue={rule.rhOperand}
					rules={{
						validate: (value) => {
							if (!value) {
								return t('errors:requiredErrorMessage') as string;
							}

							return true;
						},
					}}
					render={({ ref, value, onChange }) => {
						const options =
							lhOperand[0].id?.indexOf('channel') > -1
								? channels?.map((d) => ({ id: d.toUpperCase(), label: d }))
								: trueOrFalseOptions;
						const selectedValue: any = options.filter((options: any) => options?.id === value);

						return (
							<div className={css({ width: '48%' })}>
								<FormSelect
									inputRef={ref}
									name={`segmentRules[${index}].rhOperand`}
									options={options}
									selectedValue={selectedValue}
									onChange={(data: any) => {
										onChange(data?.[0]?.id);
									}}
									marginBottom='0.5rem'
									labelClassName={{ fontSize: '1rem' }}
									placeholder={t('pages:configure.liveChat.routing.value')}
									popupIndex={theme.zIndex400}
								/>
							</div>
						);
					}}
				/>
			);
		}

		return (
			<Controller
				control={control}
				name={`segmentRules[${index}].rhOperand`}
				rules={{
					validate: (value) => {
						if (value && isCustomerSegmentType() && numberField.indexOf(operatorId) > -1) {
							const timeRangeCheckValue = timeRangeCheck(value);
							if (timeRangeCheckValue === true) {
								return tagsNotAllowed(t, value, true);
							}

							return timeRangeCheckValue;
						}
						if (value) {
							return tagsNotAllowed(t, value, true);
						}
						if (!value && rule?.operator?.[0]?.rightOperandRequired) {
							return t('errors:requiredErrorMessage') as string;
						}

						return true;
					},
				}}
				render={({ ref, onChange, value }) =>
					numberField.indexOf(operatorId) > -1 ? (
						<FormInput
							type='number'
							inputRef={ref}
							name={`segmentRules[${index}].rhOperand`}
							width='13.45rem'
							clearable={false}
							min={isCustomerSegmentType() && timerangeDays ? 1 : ''}
							max={isCustomerSegmentType() && timerangeDays ? timerangeDays : ''}
							disabled={!rule?.operator?.[0]?.rightOperandRequired}
							overrides={{
								marginBottom: '1rem',
							}}
							value={value}
							onChange={onChange}
							placeholder={t('components:conditional.placeholders.value')}
						/>
					) : dataType === DATA_TYPE.DATE ? (
						<div
							className={css({
								display: 'flex',
								flexDirection: 'column',
								textAlign: 'left !important',
								marginBottom: '1rem',
								width: '50%',
							})}
						>
							<DefaultDatePicker
								value={value === '' ? undefined : new Date(value)}
								onChange={onChange}
								inputPlaceholder={t('common:date')}
								popupIndex={3}
								isDisabled={!rule?.operator?.[0]?.rightOperandRequired}
								error={{
									message: errors?.segmentRules?.[index]?.rhOperand?.message,
								}}
								isDateField
							/>
						</div>
					) : dataType === DATA_TYPE.DATETIME ? (
						<div
							className={css({
								width: '100%',
							})}
						>
							<div
								className={css({
									marginBottom: '1rem',
									width: '100%',
									textAlign: 'left',
								})}
							>
								<DefaultDatePicker
									value={value === '' ? undefined : new Date(value)}
									onChange={(date: any) => {
										onChange(setTimeToDate(getTimeFromDate(value), new Date(date)));
									}}
									inputPlaceholder={t('common:date')}
									popupIndex={3}
									isDisabled={!rule?.operator?.[0]?.rightOperandRequired}
									error={{
										message: errors?.segmentRules?.[index]?.rhOperand?.message,
									}}
									isDateField
								/>
							</div>
							{dateField.indexOf(operatorId) === -1 && (
								<div
									className={css({
										width: '100%',
									})}
								>
									<FormTimePicker
										name={`segmentRules[${index}].rhOperand`}
										inputRef=''
										value={getTimeFromDate(value)}
										onChange={(time: string) => onChange(setTimeToDate(time, value))}
										placeholder={t('common:selectTime')}
										popupIndex={theme.zIndex300}
										disabled={!rule?.operator?.[0]?.rightOperandRequired}
									/>
								</div>
							)}
						</div>
					) : dataType === DATA_TYPE.TIME ? (
						<div
							className={css({
								display: 'flex',
								flexDirection: 'column',
								textAlign: 'left !important',
								marginBottom: '1rem',
								width: '100%',
							})}
						>
							<FormTimePicker
								name={`segmentRules[${index}].rhOperand`}
								inputRef={ref}
								value={getTimeFromDate(value)}
								onChange={(time: string) => onChange(setTimeToDate(time, value))}
								placeholder={t('common:selectTime')}
								popupIndex={theme.zIndex300}
								disabled={!rule?.operator?.[0]?.rightOperandRequired}
							/>
						</div>
					) : dataType === DATA_TYPE.PRODUCTTYPE ? (
						<FormSelect
							inputRef={ref}
							clearable={false}
							searchable
							labelClassName={{ fontSize: '1rem' }}
							width='13.45rem'
							name={`segmentRules[${index}].rhOperand`}
							options={searchOptions}
							onInputChange={(event) => {
								debounceFn(event.target.value, fetchCustomerSegmentOptions);
							}}
							selectedValue={value}
							multi
							isLoading={showLoader}
							openOnClick={false}
							onChange={(event) => {
								setSearchOptions([]);
								onChange(event);
							}}
							filterOutSelected
							type={TYPE.search}
							marginBottom='0.5rem'
							tooltip={t('components:customFilters.conversations.tooltip.tags')}
							overrides={{
								Tag: {
									props: {
										overrides: {
											Root: {
												style: {
													marginTop: '0.12rem',
													marginBottom: '0.12rem',
													overflow: 'hidden',
												},
											},
										},
									},
								},
							}}
							placeholder={t('common:productTypePlaceholder')}
							popupIndex={theme.zIndex400}
						/>
					) : (
						<FormInput
							inputRef={ref}
							width='13.45rem'
							name={`segmentRules[${index}].rhOperand`}
							type={isCustomerSegmentType() ? 'number' : 'text'}
							disabled={!rule?.operator?.[0]?.rightOperandRequired}
							min={isCustomerSegmentType() ? 0 : ''}
							overrides={{
								marginBottom: '1rem',
								marginTop: '0.625rem',
							}}
							value={parse(value || '')}
							onChange={onChange}
							placeholder={
								isCustomerSegmentType()
									? t('components:conditional.placeholders.enterValue')
									: t('components:conditional.placeholders.value')
							}
						/>
					)
				}
			/>
		);
	};

	useEffect(() => {
		setFormSubmitCount(submitCount);
	}, [submitCount]);

	useEffect(() => {
		if (details?.filterExpression?.indexOf('C') > -1) {
			setValue(
				'expression',
				details.filterExpression?.split('C')?.[0]?.split('')?.slice(0, -4)?.join('') || '' // remove all Converational filters
			);
		} else {
			setValue('expression', details.filterExpression);
		}
	}, [details.filterExpression]);

	useEffect(() => {
		const rules = getValues('segmentRules');
		let expression = getValues('expression') || '';
		if (joinOperator !== 'CUSTOM' && !customSegment && !isUserFilterEditMode) {
			expression = new Array(rules.length)
				.fill(isCustomerSegmentType() ? 'P' : 'A')
				.map((key, idx) => key + (idx + 1))
				.join(joinOperator);
		}
		setValue('expression', expression);
		setCustomSegment?.(false);
		setIsUserFilterEditMode?.(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [joinOperator, getValues('segmentRules')]);

	const noResult = (
		<div
			className={css({
				color: theme.colors.primaryA,
				alignItems: 'centre',
				justifyContent: 'centre',
				marginLeft: '0rem',
				paddingLeft: '0rem',
			})}
		>
			{t('components:customFilters.NoAttributeAvailableMessage')}{' '}
			<NavLink
				to='/configure/attributes'
				className={css({
					textDecoration: 'underline',
					color: theme.colors.primaryA,
					fontWeight: 'bold',
					alignItems: 'centre',
					justifyContent: 'centre',
				})}
			>
				{t('components:customFilters.tabs.Attributes')}
			</NavLink>{' '}
			page.
		</div>
	);

	return (
		<>
			<div className={css({ marginBottom: '0.75rem', textAlign: 'left' })}>
				<FormLabel
					id='attributes-custom-filter'
					label={
						isCustomerSegmentType()
							? t('components:conditional.customerSegmentHeading')
							: t('components:conditional.heading')
					}
				/>
			</div>
			<ENGTAccordion
				overrides={{ root: { width: overrides.width } }}
				errorId={
					errors['segmentRules'] &&
					formState.submitCount > formSubmitCount &&
					Object.keys(errors['segmentRules'])[0]
				}
				key='segmentRules'
				fieldIds={controlledFields.map((rule: any) => rule.fid)}
			>
				{controlledFields.map((rule: any, index: number) => (
					<Panel
						key={rule.fid}
						title={
							<div
								className={css({
									display: 'flex',
									width: '100%',
								})}
							>
								<div>{isCustomerSegmentType() ? `P${index + 1}` : `A${index + 1}`}</div>
								{getValues('segmentRules')?.length > 1 ? (
									<div
										className={css({
											position: 'relative',
											left: 'calc(100% + 2.5rem)',
										})}
										onClick={() => removeSegmentRule(index)}
									>
										<MinusIcon
											fillCircle={theme.colors.backgroundSecondary}
											fillPath={theme.colors.primaryB}
										/>
									</div>
								) : (
									<></>
								)}
							</div>
						}
					>
						<div className={css({ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' })}>
							<Controller
								control={control}
								name={`segmentRules[${index}].lhOperand`}
								defaultValue={rule.leftOperand}
								rules={{
									validate: (value) => {
										if (!value) {
											return t('errors:requiredErrorMessage') as string;
										}

										return true;
									},
								}}
								render={({ ref, value, onChange }) => (
									<FormSelect
										inputRef={ref}
										name={`segmentRules[${index}].lhOperand`}
										popupIndex={3}
										width={isCustomerSegmentType() ? '30.400rem' : '28.375rem'}
										options={leftOperands?.filter(
											(key: any) => key.label !== 'user.channel_user_handle'
										)}
										searchable={!isCustomerSegmentType()}
										noResultsMsg={noResult}
										selectedValue={value}
										onChange={(event) => {
											onChange(event);
											setValue(
												`segmentRules[${index}].operatorList`,
												TYPE_OPERATOR_MAPPINGS()[event[0].type]
											);
											setValue(`segmentRules[${index}].operator`, '');
											setValue(`segmentRules[${index}].rhOperand`, '');
											if (
												isCustomerSegmentType() &&
												TYPE_OPERATOR_MAPPINGS()[event?.[0]?.type]?.length === 1
											) {
												setValue(
													`segmentRules[${index}].operator`,
													TYPE_OPERATOR_MAPPINGS()[event[0].type]
												);
												setValue(`segmentRules[${index}].rhOperand`, '');
												setSearchOptions([]);
											}
										}}
										placeholder={
											isCustomerSegmentType()
												? t('components:conditional.placeholders.parameter')
												: t('components:conditional.placeholders.attribute')
										}
									/>
								)}
							/>
							<Controller
								name={`segmentRules[${index}].operator`}
								control={control}
								rules={{
									validate: (value) => {
										if (!value) {
											return t('errors:requiredErrorMessage') as string;
										}

										return true;
									},
								}}
								render={({ ref, value, onChange }) => (
									<FormSelect
										disabled={getValues(`segmentRules[${index}].lhOperand`)?.length === 0}
										name={`segmentRules[${index}].operator`}
										inputRef={ref}
										popupIndex={3}
										width='13.45rem'
										options={getValues(`segmentRules[${index}].operatorList`) || ''}
										selectedValue={value}
										onChange={(event) => {
											setValue(`segmentRules[${index}].rhOperand`, '');
											onChange(event);
										}}
										placeholder={
											isCustomerSegmentType()
												? t('components:conditional.placeholders.selectOperator')
												: t('components:conditional.placeholders.condition')
										}
									/>
								)}
							/>
							{!(
								getValues(`segmentRules[${index}].operator`)?.[0]?.id === 'IS_NOT_EMPTY' ||
								getValues(`segmentRules[${index}].operator`)?.[0]?.id === 'IS_EMPTY'
							) && renderRightOperand(rule, index)}
						</div>
					</Panel>
				))}
			</ENGTAccordion>

			<ENGTSpacer marginTop='1rem' marginLeft='0' marginBottom='1rem' className={{ textAlign: 'left' }}>
				<ENGTButton
					type='button'
					kind={KIND.primary}
					size={SIZE.compact}
					onClick={(e) => {
						addSegmentRule({
							...DEFAULT_RULE_OBJECT,
						});
						e.preventDefault();
					}}
					label={
						isCustomerSegmentType()
							? t(`components:conditional.addParameter`)
							: t(`components:conditional.addCondition`)
					}
				/>
			</ENGTSpacer>

			<div className={css({ textAlign: 'left', marginBottom: '1rem', width: overrides.width })}>
				<FormLabel
					id='operators'
					label={t('components:conditional.expression.title')}
					tooltip={
						isCustomerSegmentType()
							? t('components:conditional.expression.customerSegmentTooltip')
							: t('components:conditional.expression.tooltip')
					}
				/>
				<Controller
					control={control}
					name='operators'
					render={({ ref, onChange }) => (
						<ENGTRadio
							minWidth='32%'
							group='operators'
							value={joinOperator}
							inputRef={ref}
							buttons={JOIN_OPERATOR_MAPPINGS}
							onChange={(event) => {
								onChange(event);
								setJoinOperator(event);
							}}
						/>
					)}
				/>
			</div>

			<Controller
				control={control}
				name='expression'
				rules={{
					validate: (value) => !!value,
					required: t('errors:requiredErrorMessage') as string,
				}}
				render={({ ref, onChange, value }) => (
					<FormInput
						name='expression'
						inputRef={ref}
						value={value}
						onChange={onChange}
						placeholder={t('components:conditional.expression.expressionValue')}
						disabled={joinOperator === ' AND ' || joinOperator === ' OR '}
					/>
				)}
			/>
		</>
	);
};

export default ENGTConditional;
