import React from 'react';

function CloseIcon({
	className = '',
	fillColor,
	width,
	height,
	viewX,
	viewY,
}: {
	className?: string;
	fillColor?: string;
	width?: number;
	height?: number;
	viewX?: number;
	viewY?: number;
}) {
	const viewBox = viewX && viewY ? `0 0 ${viewX} ${viewY}` : `0 0 ${width} ${height}`;

	return (
		<svg
			width={width || '14'}
			height={height || '14'}
			viewBox={viewBox}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.05024 0.636039L0.636027 2.05025L5.58577 7L0.636027 11.9497L2.05024 13.364L6.99999 8.41421L11.9497 13.364L13.3639 11.9497L8.4142 7L13.3639 2.05025L11.9497 0.636039L6.99999 5.58579L2.05024 0.636039Z'
				fill={fillColor || '#403F42'}
			/>
		</svg>
	);
}

export default CloseIcon;
