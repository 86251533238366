import { IObjProps } from 'shared/consts/types';
import { isValidDate, isValidResponseObject } from 'shared/helpers';

import { RootState } from 'store/rootReducer';

import { i18nHelper } from 'i18nHelper';

import { actions } from '../store';

import { messageDetailsAPI } from './api';

export interface IUserDetailsProps {
	selectedUser: IObjProps;
	sendEmailTranscript: boolean;
	emailTranscriptStartDate: any;
	emailTranscriptEndDate: any;
	editKey: string;
	editValue: string;
}
export type pair = {
	key: string;
	value?: string;
};

const { showRightPanel, shareRecommendedProducts } = actions;

function tagsHistoryLogs(tagsData: IObjProps[]) {
	let tagsHistory: { name: string; value: Array<pair> }[];
	const sortedMessageData = [...tagsData];
	sortedMessageData.sort(function (a: IObjProps, b: IObjProps): any {
		return Date.parse(b.createdOn) - Date.parse(a.createdOn);
	});
	const tagsHistoryLogs = new Map();
	let dateKey;
	let timeKey;
	for (let i = 0; i < sortedMessageData.length; i++) {
		let tagsLogs: Array<pair> = [];
		dateKey = i18nHelper('common:standardDateWithYearFormatter', {
			date: sortedMessageData[i].createdOn,
		});

		timeKey = i18nHelper('common:standardDateTimeFormatter', {
			date: sortedMessageData[i].createdOn,
		});
		const log = { key: timeKey, value: '' };
		switch (sortedMessageData[i].action) {
			case 'UPDATE':
				log.value = `<strong>${sortedMessageData[i].agentName}</strong> updated tag(s) from: ${sortedMessageData[i].fromLabels} to: ${sortedMessageData[i].toLabels}`;
				break;
			case 'DELETE':
				log.value = `<strong>${sortedMessageData[i].agentName}</strong> deleted tag(s): ${sortedMessageData[i].fromLabels}`;
				break;
			case 'ADD':
				log.value = `<strong>${sortedMessageData[i].agentName}</strong> added tag(s): ${sortedMessageData[i].toLabels}`;
				break;
			default:
				break;
		}
		if (tagsHistoryLogs.has(dateKey)) {
			tagsLogs = tagsHistoryLogs.get(dateKey);
		} else {
			tagsHistoryLogs.set(dateKey, tagsLogs);
		}
		tagsLogs.push(log);
	}
	tagsHistory = Array.from(tagsHistoryLogs, ([name, value]) => ({ name, value }));

	return tagsHistory;
}

export const messageDetailsActions = {
	updateUserDetailsAction: (userDetails: IUserDetailsProps, successCb: any, errorCb: any) => (dispatch: any) => {
		const {
			selectedUser,
			sendEmailTranscript,
			emailTranscriptStartDate,
			emailTranscriptEndDate,
			editKey,
			editValue,
		} = userDetails;

		const params = { targetApi: 'updateUserDetails' };
		const userDetailsMap: any = {
			email: 'email',
			username: 'userName',
			phone_no: 'phoneNumber',
			account: 'organization',
			title: 'title',
			custom_variable: 'customVariable',
		};
		const data: any = {
			userId: selectedUser.user_id,
			conversationId: selectedUser.conversation_id,
			sendChatTranscript: sendEmailTranscript,
			endDate: emailTranscriptEndDate
				? isValidDate(emailTranscriptEndDate)
					? emailTranscriptEndDate.toISOString()
					: emailTranscriptEndDate
				: null,
			startDate: emailTranscriptStartDate
				? isValidDate(emailTranscriptStartDate)
					? emailTranscriptStartDate.toISOString()
					: emailTranscriptStartDate
				: null,
		};

		if (editValue) {
			data['editType'] = userDetailsMap[editKey];
			data['value'] = editValue;
		}

		messageDetailsAPI.updateUserDetails(params, data).then((response: any) => {
			if (response.data && response.data.status && response.data.status.code === 1000) {
				successCb();
				// TODO: Call getRecentConversations and getUserAttributes when updating user details
			} else {
				errorCb();
			}
		});
	},
	getLabelsAudit:
		(conversationID: string, successCb: any, errorCb: any) => (dispatch: any, getState: () => RootState) => {
			const params = {
				botRef: '{{botRef}}',
				conversationId: conversationID != '' ? conversationID : getState().Message.selectedUser.conversation_id,
				labelType: 'TAGS',
				targetApi: 'getLabelsAuditData',
			};
			messageDetailsAPI.getLabelAuditData(params).then((response: any) => {
				if (isValidResponseObject(response)) {
					const auditHistory = Array.isArray(response.data.responseObject)
						? successCb(tagsHistoryLogs(response.data.responseObject))
						: successCb([]);
				} else {
					errorCb();
				}
			});
		},
	showRightPanel: (isRightPanelOpen: boolean) => (dispatch: any, getState: () => RootState) => {
		dispatch(showRightPanel(isRightPanelOpen));
	},

	shareRecommendedProducts: (isShareProductsIconClicked: boolean) => (dispatch: any, getState: () => RootState) => {
		dispatch(shareRecommendedProducts(isShareProductsIconClicked));
	},
};
