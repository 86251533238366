import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { IObjProps } from 'shared/consts/types';

import { API } from 'store/Broadcast/api';
import { openErrorSavingModalAction } from 'store/Modal/actions';

function useStopBroadcastHook() {
	const dispatch = useDispatch<any>();
	const { t } = useTranslation(['common', 'pages']);

	const stopBroadcast = (params: IObjProps, callback: Function) => {
		API.stopBroadcast({}, params).then(
			(response: any) => {
				if (response?.data) {
					const result = response.data.responseObject || false;
					if (result) {
						toaster.positive(
							<ENGTToasterContainer
								title={t('components:stopBroadcastToaster.toasterHeading')}
								description={t('components:stopBroadcastToaster.toasterText')}
							/>,
							{}
						);
					}
					callback(result);
				} else {
					dispatch(openErrorSavingModalAction());
				}
			},
			() => {
				dispatch(openErrorSavingModalAction());
			}
		);
	};

	return { stopBroadcast };
}

export default useStopBroadcastHook;
