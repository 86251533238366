import { createSlice } from '@reduxjs/toolkit';

import { planReducer } from './reducers';
import { planState } from './state';

export const planSlice = createSlice({
	name: 'Plan',
	initialState: planState,
	reducers: planReducer,
});

export const { actions, reducer } = planSlice;
