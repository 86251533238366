const BotStoreIcon = ({ width = '16', height = '14', fill = '#767477' }) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.98683 0C3.72262 0 3.50843 0.214189 3.50843 0.478405C3.50843 0.742621 3.72262 0.95681 3.98683 0.95681H4.81075C5.00158 0.95681 5.15627 1.1115 5.15627 1.30233V2.33869H4.09302C1.83251 2.33869 0 4.17119 0 6.43171C0 8.69222 1.83251 10.5247 4.09302 10.5247H4.48119C5.18894 12.1135 6.5117 13.1825 8.02656 13.1825C9.54141 13.1825 10.8642 12.1135 11.5719 10.5247H11.907C14.1675 10.5247 16 8.69222 16 6.43171C16 4.17119 14.1675 2.33869 11.907 2.33869H10.844V1.30233C10.844 1.1115 10.9986 0.95681 11.1895 0.95681H12.0134C12.2776 0.95681 12.4918 0.742621 12.4918 0.478405C12.4918 0.214189 12.2776 0 12.0134 0H11.1895C10.4702 0 9.88714 0.583071 9.88714 1.30233V2.33869H6.11308V1.30233C6.11308 0.583071 5.53001 0 4.81075 0H3.98683ZM11.907 8.82371H4.093C2.77199 8.82371 1.70098 7.75291 1.70098 6.43169C1.70098 5.11067 2.77199 4.03966 4.093 4.03966H11.907C13.228 4.03966 14.299 5.11067 14.299 6.43169C14.299 7.75291 13.228 8.82371 11.907 8.82371ZM5.63447 7.12218C6.01612 7.12218 6.3255 6.81279 6.3255 6.43115C6.3255 6.0495 6.01612 5.74012 5.63447 5.74012C5.25283 5.74012 4.94344 6.0495 4.94344 6.43115C4.94344 6.81279 5.25283 7.12218 5.63447 7.12218ZM5.63447 7.76005C6.36841 7.76005 6.96338 7.16508 6.96338 6.43115C6.96338 5.69722 6.36841 5.10225 5.63447 5.10225C4.90054 5.10225 4.30557 5.69722 4.30557 6.43115C4.30557 7.16508 4.90054 7.76005 5.63447 7.76005ZM10.4186 7.12219C10.8002 7.12219 11.1096 6.81281 11.1096 6.43116C11.1096 6.04952 10.8002 5.74013 10.4186 5.74013C10.0369 5.74013 9.72752 6.04952 9.72752 6.43116C9.72752 6.81281 10.0369 7.12219 10.4186 7.12219ZM10.4186 7.76006C11.1525 7.76006 11.7475 7.16509 11.7475 6.43116C11.7475 5.69723 11.1525 5.10226 10.4186 5.10226C9.68462 5.10226 9.08965 5.69723 9.08965 6.43116C9.08965 7.16509 9.68462 7.76006 10.4186 7.76006Z'
			fill={fill}
		/>
	</svg>
);

export default BotStoreIcon;
