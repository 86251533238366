import { TRANSLATION_STATUS } from 'shared/consts/consts';
import { IApiDataProps, IObjProps, IPaginationPayloadProps } from 'shared/consts/types';

import { ImessageOverviewStateInterface } from './state';

export const messageOverviewReducer = {
	addUserMessage: (state: ImessageOverviewStateInterface, action: { payload: IObjProps }) => {
		let data = [];
		data = state.userMessages.data;
		data.push(action.payload);
		state.userMessages = {
			...state.userMessages,
			data,
		};
	},
	getUserMessages: (state: ImessageOverviewStateInterface, action: { payload: Array<IObjProps> }) => {
		let data = [];
		data = [...action.payload];
		state.userMessages = {
			data,
			error: false,
			loading: false,
		};
	},
	setBringToView: (state: ImessageOverviewStateInterface, action: { payload: any }) => {
		state.bringToView = action.payload;
	},
	getQuickReplies: (
		state: ImessageOverviewStateInterface,
		action: {
			payload: {
				page: number;
				content: Array<any>;
			} & IPaginationPayloadProps;
		}
	) => {
		const { page, content, ...rest } = action.payload;

		state.quickReplies = {
			data:
				page === 0
					? JSON.parse(JSON.stringify(content))
					: [...state.quickReplies.data, ...JSON.parse(JSON.stringify(content))],
			error: false,
			loading: false,
			pagination: rest,
		};
	},
	setStateMessageOverview: (
		// use only for setting loading/error/to reset state variables only
		state: ImessageOverviewStateInterface,
		action: {
			payload: {
				data?: Array<IObjProps>;
				error: boolean;
				loading: boolean;
				key: 'userMessages';
			};
		}
	) => {
		if (action.payload.data) {
			state[action.payload.key].data = action.payload.data;
		}
		state[action.payload.key].loading = action.payload.loading;
		state[action.payload.key].error = action.payload.error;
	},
	getAgentsByCategory: (state: ImessageOverviewStateInterface, action: { payload: Array<IObjProps> }) => {
		state.agentsListByCategory = {
			data: action.payload,
			error: false,
			loading: false,
		};
	},
	getAgentsByCategoryLoading: (state: ImessageOverviewStateInterface) => {
		state.agentsListByCategory = {
			...state.agentsListByCategory,
			loading: true,
			error: false,
		};
	},
	setUserMessages: (state: ImessageOverviewStateInterface, action: { payload: Array<IObjProps> }) => {
		state.userMessages = {
			...state.userMessages,
			data: action.payload,
		};
	},
	setEmailTranscript: (state: ImessageOverviewStateInterface, action: { payload: boolean }) => {
		state.isEmailTranscriptEnabled = action.payload;
	},
	setTranslateIntegration: (state: ImessageOverviewStateInterface, action: { payload: boolean }) => {
		state.autoTranslationConfigs = {
			...state.autoTranslationConfigs,
			isTranslateIntegrationEnabled: action.payload,
			translationStatusOfAgent: true,
			translationStatusOfMessages: TRANSLATION_STATUS.SUCCESS,
		};
	},
	setLastUserMessage: (state: ImessageOverviewStateInterface, action: { payload: IObjProps }) => {
		state.lastMessage = action.payload;
	},
	updateLastUserMessage: (
		state: ImessageOverviewStateInterface,
		action: { payload: { message: string; id: string; lastActiveAt: string } }
	) => {
		state.lastMessage = {
			...state.lastMessage,
			[action.payload.id]: {
				content: action.payload.message,
				showBanner: false,
				showInUI: true,
				unprocessedData: [],
				lastActiveAt: action.payload.lastActiveAt,
			},
		};
	},
	getLiveChatConfig: (state: ImessageOverviewStateInterface, action: { payload: IApiDataProps<IObjProps> }) => {
		state.liveChatConfig = action.payload;
	},
	setPathVariables: (state: ImessageOverviewStateInterface, action: { payload: IObjProps }) => {
		state.pathVariables = action.payload;
	},
	setTranslationStatusOfMessages: (state: ImessageOverviewStateInterface, action: { payload: string }) => {
		state.autoTranslationConfigs = {
			...state.autoTranslationConfigs,
			translationStatusOfMessages: action.payload,
		};
	},
	setTranslationStatusOfAgent: (state: ImessageOverviewStateInterface, action: { payload: boolean }) => {
		state.autoTranslationConfigs = {
			...state.autoTranslationConfigs,
			translationStatusOfAgent: action.payload,
		};
	},
	setEndUserTranslationLanguage: (state: ImessageOverviewStateInterface, action: { payload: string }) => {
		state.autoTranslationConfigs = {
			...state.autoTranslationConfigs,
			endUserLanguage: action.payload,
		};
	},
	setIsAutoTranslateEnabledInLiveChat: (state: ImessageOverviewStateInterface, action: { payload: boolean }) => {
		state.autoTranslationConfigs = {
			...state.autoTranslationConfigs,
			isAutoTranslateEnabledInLiveChat: action.payload,
		};
	},
	setMessagePositionAppend: (state: ImessageOverviewStateInterface, action: { payload: boolean }) => {
		state.messagePosition.append = action.payload;
	},
	updateLastTranslatedMessageState: (state: ImessageOverviewStateInterface, action: { payload: string }) => {
		state.autoTranslationConfigs = {
			...state.autoTranslationConfigs,
			lastTranslatedMessageState: action.payload,
		};
	},
	updatePageNo: (state: ImessageOverviewStateInterface, action: { payload: number }) => {
		state.messagesPagination.pageNo = action.payload;
	},
	updateLastVisitedRow: (state: ImessageOverviewStateInterface, action: { payload: any }) => {
		state.messagesPagination.lastVisitedRow = action.payload;
	},
	updateFirstVisitedRow: (state: ImessageOverviewStateInterface, action: { payload: any }) => {
		state.messagesPagination.firstVisitedRow = action.payload;
	},
	setMessagePositionPrepend: (state: ImessageOverviewStateInterface, action: { payload: boolean }) => {
		state.messagePosition.prepend = action.payload;
	},
	setAgentId: (state: ImessageOverviewStateInterface, action: { payload: boolean }) => {
		state.agentId = action.payload;
	},
	setSmartAssistEnablement: (state: ImessageOverviewStateInterface, action: { payload: boolean }) => {
		state.smartAssistConfig = {
			...state.smartAssistConfig,
			isEnabled: action.payload,
		};
	},
	setSmartAssistOpen: (state: ImessageOverviewStateInterface, action: { payload: boolean }) => {
		state.smartAssistConfig = {
			...state.smartAssistConfig,
			isOpened: action.payload,
		};
	},
	setUserAttributes: (state: ImessageOverviewStateInterface, action: { payload: IObjProps }) => {
		state.userAttributes = {
			data: action.payload,
			error: false,
			loading: false,
		};
	},
	setUserAttributesLoading: (state: ImessageOverviewStateInterface) => {
		state.userAttributes = {
			data: {},
			error: false,
			loading: true,
		};
	},
	setUserAttributesError: (state: ImessageOverviewStateInterface) => {
		state.userAttributes = {
			data: {},
			error: true,
			loading: false,
		};
	},
	setShowPriorityQuickFilter: (state: ImessageOverviewStateInterface, action: { payload: any }) => {
		state.showPriorityQuickFilter = action.payload;
	},
	setIsEmailConfigured: (state: ImessageOverviewStateInterface, action: { payload: boolean }) => {
		state.isEmailConfigured = action.payload;
	},
};
