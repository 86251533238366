import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ENGTInput from 'components/UI/ENGTInput/ENGTInput';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import FormLabel from 'components/UI/Form/FormLabel/FormLabel';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { IObjProps } from 'shared/consts/types';
import { isValidResponseObject } from 'shared/helpers';

import { API as GET_USAGE_API } from 'store/AccountSettings/api';
import { fontStyleSelector } from 'store/App/User';

interface IRequestRechargeModalProps {
	isWalletBanner: boolean;
	isOpen: any;
	setRequestRechargeOpen: Function;
}

function RequestRechargeModal(props: IRequestRechargeModalProps) {
	const { isWalletBanner, isOpen, setRequestRechargeOpen } = props;
	const { t } = useTranslation(['components', 'common']);
	const [css, theme]: [any, any] = useStyletron();
	const [credits, setCredits] = useState<number>(0);
	const [wallets, setWallets] = useState<number>(0);
	const [currency, setCurrency] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<string>('');
	const fontStyle: string = useSelector(fontStyleSelector);

	const onRequestRecharge = () => {
		GET_USAGE_API.getLastRechargeCreditAmount().then((response: IObjProps) => {
			if (isValidResponseObject(response)) {
				setCredits(Math.max(response.data.responseObject, 0));
			}
		});
	};

	const onRequestWalletRecharge = () => {
		GET_USAGE_API.getLastWalletBalanceAdded().then((response: IObjProps) => {
			if (isValidResponseObject(response)) {
				setWallets(response.data.responseObject.lastAmountAdded);
				setCurrency(response.data.responseObject.currency);
			}
		});
	};
	useEffect(() => {
		setErrorMessage('');
		if (isOpen) {
			isWalletBanner ? onRequestWalletRecharge() : onRequestRecharge();
		}
	}, [isOpen]);

	const onEmailResponse = (response: any) => {
		if (isValidResponseObject(response)) {
			toaster.positive(
				<ENGTToasterContainer
					title={t('pages:accountSettings.accountDetailsPage.emailSentTitle')}
					description={t('pages:accountSettings.accountDetailsPage.requestEmailDescription')}
				/>,
				{}
			);
		} else {
			toaster.negative(
				<ENGTToasterContainer
					title={t('common:requestEmailFailed')}
					description={t('pages:accountSettings.accountDetailsPage.emailFailedDescription')}
				/>,
				{}
			);
		}
	};

	const onConfirm = () => {
		const amountRequested = credits || wallets;
		if (amountRequested > 0) {
			setErrorMessage('');
			setRequestRechargeOpen(false);
			if (isWalletBanner) {
				GET_USAGE_API.submitPointRequest(amountRequested, currency).then((response: IObjProps) => {
					onEmailResponse(response);
				});
			} else if (amountRequested >= 1000) {
				GET_USAGE_API.submitCreditRequest(amountRequested).then((response: IObjProps) => {
					onEmailResponse(response);
				});
			} else {
				setRequestRechargeOpen(true);
				setErrorMessage(t('components:node.slider.minCreditAmountMessage'));
			}
		} else {
			setErrorMessage(t('components:node.slider.emptyFieldError'));
		}
	};

	const cssForRequestRecharge = () => (
		<div
			className={css({
				display: 'flex',
				borderTopLeftRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
				borderBottomColor: theme.colors.accent400,
				borderTopColor: theme.colors.accent400,
				borderRightColor: theme.colors.accent400,
				borderLeftColor: theme.colors.accent400,
				borderTopWidth: '0.0625rem',
				borderBottomWidth: '0.0625rem',
				borderRightWidth: '0.0625rem',
				borderLeftWidth: '0.0625rem',
				backgroundColor: theme.colors.backgroundSecondary,
				height: '2.7rem',
				paddingTop: '1rem',
				paddingBottom: '1rem',
				paddingRight: '1rem',
				paddingLeft: '1rem',
			})}
		>
			{isWalletBanner ? t('common:requestRechargeDescriptionWallet') : t('common:requestRechargeDescription')}
		</div>
	);

	return (
		<>
			<ActionModal
				heading={t('common:requestRecharge')}
				isOpen={isOpen}
				onClose={() => {
					setRequestRechargeOpen(false);
				}}
				onConfirm={onConfirm}
				confirmBtnLabel={t('common:send')}
				description={cssForRequestRecharge()}
			>
				<FormLabel
					id='credits-required'
					label={
						isWalletBanner
							? `${t('pages:configure.display.style.pointsRequired')} (${currency})`
							: t('pages:configure.display.style.creditsRequired')
					}
					className={{
						marginBottom: '0.25rem',
						height: '1.5rem',
						fontSize: '1.02rem',
						fontFamily: fontStyle,
					}}
				/>
				<ENGTInput
					type='number'
					min='0'
					clearable={false}
					onChange={(e) => {
						isWalletBanner ? setWallets(e.target.value) : setCredits(e.target.value);
					}}
					value={isWalletBanner ? wallets : credits}
					overrides={{
						Root: {
							style: {
								borderColor: errorMessage ? theme.colors.accent : theme.colors.contentStateDisabled,
							},
						},
					}}
				/>
				{errorMessage && (
					<div
						className={css({
							color: theme.colors.accent,
							fontSize: '0.875rem',
							marginTop: '0.2rem',
							marginRight: '0.3rem',
							float: 'left',
							height: '1rem',
						})}
					>
						{errorMessage}
					</div>
				)}
			</ActionModal>
		</>
	);
}

export default RequestRechargeModal;
