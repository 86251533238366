import { RootState } from 'store/rootReducer';

export const isSocialInboxEnabledSelector = (state: RootState) => state.Bot.configData.enable_social_inbox;

export const currentOneViewScreenSelector = (state: RootState) => state.Message.currentOneViewScreen;

export const selectedPostSelector = (state: RootState) => state.Message.selectedPost;

export const isFbInboxCommentsEnabledSelector = (state: RootState) =>
	state.User.botAdmin.data.is_facebook_comments_enabled;

export const isInstaInboxCommentsEnabledSelector = (state: RootState) =>
	state.User.botAdmin.data.is_instagram_comments_enabled;

export const viewStitchedUserCommentSelector = (state: RootState) => state.Message.viewStitchedUserComment;
