import { COMMENTS_SYNC_STATUS } from 'shared/consts/consts';
import { SYNC_UP_STATUS } from 'shared/consts/facebook/consts';
import { IObjProps } from 'shared/consts/types';
import { isEmpty } from 'shared/helpers';

import {
	getCommentsForSelectedPostAction,
	getRepliesForCommentAction,
	incrementTotalCommentsCountForThePostAction,
	setUnreadPostIdCountMapAction,
	updateCommentListAction,
	updatePostedCommentIdsAction,
	updateSelectedPostInPostListAction,
} from 'store/Message';
import { RootState } from 'store/rootReducer';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

import { SOCKET_EVENTS } from '../consts';

export const notifyCommentsSyncupStatusListener = (socket: any) => (dispatch: any, getState: () => RootState) => {
	socket.removeAllListeners(SOCKET_EVENTS.COMMENT_SYNC_STATUS);

	socket.on(SOCKET_EVENTS.COMMENT_SYNC_STATUS, (socketData: any) => {
		const data: any = JSON.parse(socketData);
		const { postId, commentsNextCursor } = data;
		const { selectedPost } = getState().Message;

		if (!isEmpty(selectedPost)) {
			const updatedPost = { ...selectedPost };
			updatedPost.commentsSyncStatus = COMMENTS_SYNC_STATUS.SUCCESS;
			updatedPost.commentsNextCursor = commentsNextCursor;

			dispatch(updateSelectedPostInPostListAction(updatedPost));

			if (
				selectedPost.postId === postId &&
				COMMENTS_SYNC_STATUS.IN_PROGRESS === selectedPost.commentsSyncStatus
			) {
				dispatch(getCommentsForSelectedPostAction());
			}
		}
	});
};

export const notifyCommentRepliesSyncupStatusListener = (socket: any) => (dispatch: any, getState: () => RootState) => {
	socket.removeAllListeners(SOCKET_EVENTS.COMMENT_REPLIES_SYNC_STATUS);

	socket.on(SOCKET_EVENTS.COMMENT_REPLIES_SYNC_STATUS, (socketData: any) => {
		const data: any = JSON.parse(socketData);

		const { postId, parentCommentId, repliesNextCursor } = data;

		const { selectedPost } = getState().Message;
		const commentListData = getState().Message.listOfComments.data;

		if (!isEmpty(selectedPost) && commentListData.length > 0 && selectedPost.postId === postId) {
			const commentList = [...commentListData];
			let pageNo = -1;

			commentList.every((comment, index) => {
				if (comment.commentId === parentCommentId) {
					const commentToUpdate = { ...commentList[index] };
					commentToUpdate['repliesSyncStatus'] = COMMENTS_SYNC_STATUS.SUCCESS;
					commentToUpdate['repliesNextCursor'] = repliesNextCursor;
					commentList[index] = { ...commentToUpdate };
					pageNo = comment.replies.pagination.page;

					return false;
				}

				return true;
			});

			if (pageNo > 0) {
				dispatch(updateCommentListAction(commentList));
				dispatch(getRepliesForCommentAction(parentCommentId, pageNo));
			}
		}
	});
};

export const notifyNewCommentListener = (socket: any) => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.NOTIFY_NEW_COMMENT);

	socket.on(SOCKET_EVENTS.NOTIFY_NEW_COMMENT, (socketData: any) => {
		const data: any = JSON.parse(socketData);

		const {
			parentCommentId,
			commentMessage,
			commentId,
			mediaType,
			updatedOn,
			userName,
			profilePicUrl,
			postId,
			userId,
			canReplyPrivately,
			isPageOwner,
			profileName,
		} = data;

		const postedCommentIds = getState().Message.postedCommentIds || [];
		if (window.location.href.indexOf(ThreeColumnRouteLinks.socialInboxInstagram) > -1) {
			if (postedCommentIds.indexOf(commentId) > -1) {
				const updatedCommentIds = [...postedCommentIds];
				updatedCommentIds.splice(postedCommentIds.indexOf(commentId), 1);
				dispatch(updatePostedCommentIdsAction(updatedCommentIds));
			} else {
				const { selectedPost, viewStitchedUserComment } = getState().Message;
				const postList = getState().Message.listOfPosts.data;
				const unreadPostIdCountMap = getState().Message.unreadPostIdCountMap || {};
				const updatedPostList = [...postList];
				if (selectedPost.postId === postId) {
					const listOfComments = getState().Message.listOfComments.data;
					const updatedCommentList = [...listOfComments];
					if (isEmpty(parentCommentId)) {
						if (viewStitchedUserComment) {
							return;
						}

						const newComment: IObjProps = {};
						newComment['commentId'] = commentId;
						newComment['message'] = commentMessage;
						newComment['mediaType'] = mediaType;
						newComment['updatedOn'] = updatedOn;
						newComment['replies'] = { data: [] };
						newComment['from'] = {
							userName,
							profilePicUrl,
							userId,
							profileName,
							isPageOwner,
						};
						newComment['canReplyPrivately'] = canReplyPrivately;
						newComment['repliesSyncStatus'] = SYNC_UP_STATUS.SUCCESS;
						updatedCommentList.unshift(newComment);
						dispatch(updateCommentListAction(updatedCommentList));
					} else {
						listOfComments.forEach((commentData: IObjProps, index: number) => {
							if (commentData.commentId === parentCommentId) {
								const commentToUpdate = { ...listOfComments[index] };
								const repliesToUpdate = { ...commentToUpdate['replies'] };
								const newReply: IObjProps = {};
								newReply['commentId'] = commentId;
								newReply['message'] = commentMessage;
								newReply['mediaType'] = mediaType;
								newReply['updatedOn'] = updatedOn;
								newReply['repliesSyncStatus'] = SYNC_UP_STATUS.SUCCESS;
								newReply['canReplyPrivately'] = canReplyPrivately;
								newReply['from'] = {
									userName,
									profilePicUrl,
									userId,
									profileName,
									isPageOwner,
								};
								repliesToUpdate['data'] = [...repliesToUpdate['data'], newReply];
								commentToUpdate['replies'] = repliesToUpdate;
								updatedCommentList[index] = commentToUpdate;
								dispatch(updateCommentListAction(updatedCommentList));
							}
						});
					}
				} else {
					updatedPostList.forEach((postData: IObjProps) => {
						if (postData.post.postId === postId) {
							const updateUnreadPostIdCountMap = { ...unreadPostIdCountMap };
							updateUnreadPostIdCountMap[postId] = updateUnreadPostIdCountMap.hasOwnProperty(postId)
								? updateUnreadPostIdCountMap[postId] + 1
								: 1;
							dispatch(setUnreadPostIdCountMapAction(updateUnreadPostIdCountMap));
						}
					});
				}
				dispatch(incrementTotalCommentsCountForThePostAction(postId));
			}
		}
	});
};
