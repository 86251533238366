import { INTEGRATIONS } from 'shared/consts/QAConsts';

function Integrations({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			width={size || 40}
			height={size || 40}
			viewBox='0 0 40 40'
			fill='none'
			className={className}
			data-qa={INTEGRATIONS}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='inherit'>
				<path
					opacity='0.7'
					d='M19.7083 30.6247V28.1943H19.4028C18.0663 28.1943 16.9791 27.107 16.9791 25.7706C16.9791 24.4341 18.0663 23.3468 19.4028 23.3468H19.7083V20.9164H16.6736C16.3399 20.9164 16.0693 20.6459 16.0693 20.3122V19.4025C16.0693 18.7324 15.5242 18.1873 14.8541 18.1873C14.1841 18.1873 13.6389 18.7324 13.6389 19.4025V20.3122C13.6389 20.6459 13.3684 20.9164 13.0347 20.9164H10V29.4095C10 30.0795 10.5452 30.6247 11.2152 30.6247H19.7083Z'
					fill={fillColor || '#403F42'}
				/>
				<path
					opacity='0.7'
					d='M29.4098 30.625C30.0798 30.625 30.625 30.0798 30.625 29.4098V20.9167H28.1946V21.2222C28.1946 22.5587 27.1073 23.6459 25.7709 23.6459C24.4344 23.6459 23.3472 22.5587 23.3472 21.2222V20.9167H20.9167V23.9514C20.9167 24.2851 20.6462 24.5557 20.3125 24.5557H19.4028C18.7327 24.5557 18.1876 25.1008 18.1876 25.7709C18.1876 26.4409 18.7327 26.9861 19.4028 26.9861H20.3125C20.6462 26.9861 20.9167 27.2566 20.9167 27.5903V30.625H29.4098Z'
					fill={fillColor || '#403F42'}
				/>
				<path
					opacity='0.7'
					d='M25.7709 22.4374C26.4409 22.4374 26.9861 21.8923 26.9861 21.2222V20.3125C26.9861 19.9788 27.2566 19.7083 27.5903 19.7083H30.625V11.2152C30.625 10.5452 30.0798 10 29.4098 10H20.9167V12.4304H21.2222C22.5587 12.4304 23.6459 13.5177 23.6459 14.8541C23.6459 16.1906 22.5587 17.2778 21.2222 17.2778H20.9167V19.7083H23.9514C24.2851 19.7083 24.5557 19.9788 24.5557 20.3125V21.2222C24.5557 21.8923 25.1008 22.4374 25.7709 22.4374Z'
					fill={fillColor || '#403F42'}
				/>
				<path
					d='M11.2152 10C10.5452 10 10 10.5452 10 11.2152V19.7083H12.4304V19.4028C12.4304 18.0663 13.5177 16.9791 14.8541 16.9791C16.1906 16.9791 17.2778 18.0663 17.2778 19.4028V19.7083H19.7083V16.6736C19.7083 16.3399 19.9788 16.0693 20.3125 16.0693H21.2222C21.8923 16.0693 22.4374 15.5242 22.4374 14.8541C22.4374 14.1841 21.8923 13.6389 21.2222 13.6389H20.3125C19.9788 13.6389 19.7083 13.3684 19.7083 13.0347V10H11.2152Z'
					fill={fillColor || '#403F42'}
				/>
			</g>
		</svg>
	);
}

export default Integrations;
