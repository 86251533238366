import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Label1 } from 'baseui/typography';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import Input from 'components/UI/ENGTInput/ENGTInput';
import Form from 'components/UI/Form/Form/Form';

import { tagsNotAllowed } from 'shared/pathBuilderHelpers';

import ActionModal, { IModalProps } from '../ActionModal/ActionModal';

export interface ActionModalWithInputProps {
	isInputRequired?: boolean;
	inputType?: string;
	defaultInput?: string;
	placeholder: string;
	toggleModal: Function;
	onChange?: Function;
	maxLength?: number;
	label?: string;
	usage?: 'bot' | 'default' | 'nodeCreation' | 'campaignCreation';
	dataQa?: string;
	postConfirmation?: (...args: any) => any;
	validateError?: (args: string) => boolean;
	hideCloseIcon?: boolean;
}

function ActionModalWithInput(props: IModalProps & ActionModalWithInputProps) {
	const {
		isInputRequired,
		inputType = 'text',
		maxLength,
		placeholder,
		toggleModal,
		onClose,
		onConfirm,
		onChange,
		postConfirmation,
		defaultInput,
		label,
		usage = 'default',
		dataQa,
		validateError,
		hideCloseIcon = false,
		...restProps
	} = props;

	const [css]: [any, any] = useStyletron();
	const { t } = useTranslation(['errors']);
	const [isConfirmBtnDisabled, setConfirmBtnDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [value, setValue]: any = useState('');
	const [error, setError]: any = useState({});
	const parsedDefaultInput = !defaultInput ? defaultInput : parse(defaultInput);

	const inputHasNoValue = (data: string) => !data || (data && data.trim() === '');
	const inputHasSelectedSpecialChars = (data: string) => new RegExp(/[/#?%\\]/).test(data);

	const onCloseFn = () => {
		onClose();
		setValue(parsedDefaultInput);
		setConfirmBtnDisabled(false);
		setIsLoading(false);
		toggleModal(false);
	};

	const onConfirmFn = () => {
		if (validateError?.(value)) {
			setError({
				message: t('errors:nameAlreadyExistErrorMessage'),
			});

			return;
		}
		setError({});

		if (inputHasNoValue(value)) {
			setError({
				message: t('errors:requiredErrorMessage'),
			});

			return;
		}
		if (usage === 'campaignCreation' && inputHasSelectedSpecialChars(value)) {
			setError({
				message: t('errors:selectedSpecialSymbolsNotAllowed'),
			});

			return;
		}
		if (tagsNotAllowed(t, value, false) !== true) {
			setError({
				message: t('errors:noHtmlTagsAllowed'),
			});

			return;
		}

		setConfirmBtnDisabled(true);
		setIsLoading(true);
		const result = onConfirm(value);
		if (result instanceof Promise) {
			result.then(postConfirmation).then(onCloseFn);
		} else {
			postConfirmation && postConfirmation();
			onCloseFn();
		}
	};

	useEffect(() => {
		setValue(parsedDefaultInput);
		setConfirmBtnDisabled(!parsedDefaultInput);
	}, [parsedDefaultInput]);

	return (
		<ActionModal
			{...restProps}
			onClose={onCloseFn}
			onConfirm={onConfirmFn}
			isLoading={isLoading}
			isConfirmBtnDisabled={isConfirmBtnDisabled}
			usage={usage}
			dataQa={dataQa}
			hideCloseBtn={hideCloseIcon}
		>
			<Form
				onSubmit={(e: any) => {
					e.preventDefault();
					!isConfirmBtnDisabled && onConfirmFn();
				}}
			>
				{label && (
					<Label1
						className={css({
							fontSize: '0.85rem',
						})}
					>
						{label}
					</Label1>
				)}
				<Input
					usage='modal'
					type={inputType}
					inputRef=''
					required={isInputRequired}
					placeholder={placeholder}
					label
					maxLength={maxLength}
					error={error}
					onChange={(event) => {
						let inputValue = event.target.value;
						if (usage === 'nodeCreation') {
							inputValue = inputValue.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
						}
						if (error.message && !inputHasNoValue(inputValue)) {
							setError({});
						}
						if (parseInt(inputValue) < 0) {
							inputValue = '0';
						}
						setValue(inputValue);
						setConfirmBtnDisabled(!inputValue);
						onChange && onChange(inputValue);
					}}
					value={value || ''}
					dataQa={`${dataQa}-input`}
				/>
			</Form>
		</ActionModal>
	);
}
ActionModalWithInput.defaultProps = {
	postConfirmation: () => {},
};
export default ActionModalWithInput;
