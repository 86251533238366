import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const genericProxyUrl = '/v2/genericProxyRequest/';
const getVideoCallTokenUrl = `${genericProxyUrl}${serialize({ targetApi: 'videoConversationsToken' }, '?')}`;
const getInstanceUrl = '/instance';

const getInstanceApi = new BaseService(getInstanceUrl);
const getVideoCallTokenApi = new BaseService(getVideoCallTokenUrl);

export const API = {
	updateVideoCallToAgentLeft(params) {
		const url = `${genericProxyUrl}${serialize(params, '?')}`;
		const closeVideoCallApi = new BaseService(url);

		return closeVideoCallApi.postRequest();
	},
	getVideoCallToken(data) {
		return getVideoCallTokenApi.postRequest(data);
	},
	exitVideoCallFromGroupConversation(params, data) {
		const url = `${genericProxyUrl}${serialize(params, '?')}`;
		const exitVideoCallFromGroupConversationApi = new BaseService(url);

		return exitVideoCallFromGroupConversationApi.postRequest(data);
	},
	getUserInstance() {
		return getInstanceApi.getRequest();
	},
};
