import { useMemo } from 'react';
import { useStyletron } from 'baseui';
import { PLACEMENT } from 'baseui/tooltip';
import { LabelSmall } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Popover from 'components/UI/ENGTTooltip/ENGTTooltip';

import { decodeAndSanitizeInput } from 'shared/helpers';
import ErrorContainer from 'shared/icons/ErrorContainer';

export interface INavItemProps {
	className?: string;
	isSelected: boolean;
	label: string;
	to?: string;
	width?: string;
	fontWeight?: string;
	color?: string;
	settingsError?: boolean;
	dataQa?: string;
	textToHighlight?: string;
	onClick?: () => void;
	showPopover?: boolean;
	popoverContent?: string | React.ReactElement;
}
function NavItem(props: INavItemProps) {
	const {
		className,
		isSelected,
		label,
		fontWeight,
		color,
		to,
		width,
		settingsError,
		dataQa,
		onClick,
		textToHighlight,
		showPopover = false,
		popoverContent,
	} = props;

	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['pages']);
	const navItemCss = {
		color: isSelected ? theme.colors.primaryA : color,
		background: isSelected ? theme.primaryNavBackground : 'transparent',
		borderTopRightRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		borderTopLeftRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		paddingTop: '0.65rem',
		paddingBottom: '0.5rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',
		cursor: 'pointer',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		textAlign: 'left',
		fontSize: '0.875rem',
		fontWeight: `${fontWeight} !important`,
		lineHeight: '1.25rem !important',
		width,
		marginBottom: '0.125rem',

		':hover': {
			background: theme.primaryNavBackground,
			color: theme.colors.primaryA,
		},
	};
	const errorMessageForPath = () => (
		<>
			<div>{t('pages:builder.resolveNotice')}</div>
			<div>{`● ${t('pages:builder.integrationNodes')}`}</div>
			<div>{`● ${t('pages:builder.systemAttributes')}`}</div>
		</>
	);

	const RenderNavLink = ({ children }: { children: JSX.Element }) =>
		to ? (
			<NavLink
				to={to}
				className={css({ textDecoration: 'none' })}
				onClick={onClick}
				ref={(el: any) =>
					isSelected && el && el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
				}
			>
				{children}
			</NavLink>
		) : (
			<span onClick={onClick}>{children}</span>
		);

	const labelHTML = useMemo(() => {
		if (textToHighlight) {
			const regex = new RegExp(textToHighlight, 'gi');

			return (
				<span
					dangerouslySetInnerHTML={{
						__html: decodeAndSanitizeInput(
							label.replace(
								regex,
								`<span style="font-weight: bold; color: ${theme.colors.accent50}">$&</span>`
							)
						),
					}}
				/>
			);
		}

		return label;
	}, [textToHighlight, label]);

	return (
		<RenderNavLink>
			<>
				<LabelSmall className={`${className} ${css(navItemCss)}`} data-qa={dataQa}>
					{settingsError ? <ErrorContainer description={errorMessageForPath()} /> : <></>}
					<span
						className={css({
							marginLeft: '-0.15rem',
							display: 'flex',
							flexDirection: 'row',
						})}
					>
						{showPopover ? (
							<Popover ignoreBoundary content={popoverContent || ''} placement={PLACEMENT.rightTop}>
								{labelHTML || label}
							</Popover>
						) : (
							labelHTML || label
						)}
					</span>
				</LabelSmall>
			</>
		</RenderNavLink>
	);
}

NavItem.defaultProps = {
	className: '',
	isSelected: false,
	fontWeight: 400,
	color: '#767477',
};

export default NavItem;
