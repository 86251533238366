import { matchPath } from 'react-router-dom';

import { IObjProps, IRouteOptionsProps, IRouteProps, ISubRouteProps } from 'shared/consts/types';

import ThreeColumnRoutes from '../ThreeColumnRoutes';
import TwoColumnRoutes from '../TwoColumnRoutes';

type INavigationalRouteProps = {
	primaryNav: boolean;
} & IRouteOptionsProps;

const getRoutes = (routes: Array<IRouteProps>) =>
	routes.reduce((route: IObjProps, { subRoutes, path, options }: IRouteProps) => {
		if (subRoutes) {
			subRoutes.forEach((subRoute: ISubRouteProps) => {
				route[`${path}${path.endsWith('/') ? '' : '/'}${subRoute.path}`] = options;
			});
		}

		return { ...route, [path]: options };
	}, {});

/* eslint-disable no-restricted-syntax */
const getProps = (pathname: string, routes: IObjProps) => {
	let props;

	for (const [path, pathProps] of Object.entries(routes)) {
		if (matchPath(path, pathname)) {
			props = {
				primaryNav: true,
				...pathProps,
			};
			break;
		}
	}

	return props;
};

const navigationalRouteProps = (() => {
	const cache: IObjProps = {};
	const twoColumnRoutes = getRoutes(TwoColumnRoutes);
	const threeColumnRoutes = getRoutes(ThreeColumnRoutes);

	return (pathname: string): INavigationalRouteProps => {
		if (cache[pathname] !== undefined) {
			return cache[pathname];
		}

		let props = getProps(pathname, threeColumnRoutes);
		if (!props) {
			props = getProps(pathname, twoColumnRoutes);
		}

		cache[pathname] = props;

		return props;
	};
})();

export default navigationalRouteProps;
