import { IObjProps, IPaginationStoreData } from 'shared/consts/types';

export interface ICampaignState {
	campaigns: IPaginationStoreData<Array<IObjProps>>;
}

export const campaignState: ICampaignState = {
	campaigns: {
		data: [],
		pagination: {
			size: 10,
			page: 0,
			totalPages: 0,
			totalElements: 0,
		},
		isError: false,
		isLoading: false,
	},
};
