import useResizerHook, { DEVICE_SIZE } from 'shared/hooks/useResizerHook';

export default function useIconSizeHook() {
	const { device, height } = useResizerHook();

	const iconSize = {
		[DEVICE_SIZE.TABLET]: 24,
		[DEVICE_SIZE.SMALL_DESKTOP]: 26,
		[DEVICE_SIZE.DESKTOP]: 30,
	};

	if (device === DEVICE_SIZE.DESKTOP && height < 780) {
		return { iconSize: iconSize[DEVICE_SIZE.SMALL_DESKTOP] };
	}

	return { iconSize: iconSize[device] };
}
