import React, { forwardRef } from 'react';
import { useStyletron } from 'baseui';

import { IObjProps } from 'shared/consts/types';

type ENGTSpacerProps = {
	children?: any;
	className?: IObjProps;
	marginTop?: string;
	marginBottom?: string;
	marginRight?: string;
	marginLeft?: string;
};

const ENGTSpacer = forwardRef(
	(
		{
			children,
			className,
			marginTop = '1.5rem',
			marginBottom = '1.5rem',
			marginLeft = '1.5rem',
			marginRight = '1.5rem',
		}: ENGTSpacerProps,
		ref: any
	) => {
		const [css] = useStyletron();

		return (
			<div
				className={css({
					marginTop,
					marginBottom,
					marginRight,
					marginLeft,
					...className,
				})}
				ref={ref}
			>
				{children}
			</div>
		);
	}
);

export default ENGTSpacer;
