import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { IObjProps } from 'shared/consts/types';

import { API } from 'store/Broadcast/api';
import { openErrorSavingModalAction } from 'store/Modal/actions';

function useRefreshBroadcastHook() {
	const dispatch = useDispatch<any>();
	const { t } = useTranslation(['components']);

	const refreshBroadcast = (callback: Function, params: IObjProps) => {
		API.getExternalBroadcastStatus(params).then((response: any) => {
			if (response?.data) {
				if (response?.data?.responseObject) {
					callback();
				}
				toaster.positive(
					<ENGTToasterContainer
						title={t('components:refreshDataToaster.toasterHeading')}
						description={t('components:refreshDataToaster.toasterText')}
					/>,
					{}
				);
			} else {
				dispatch(openErrorSavingModalAction());
			}
		});
	};

	return { refreshBroadcast };
}

export default useRefreshBroadcastHook;
