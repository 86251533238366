import { IObjProps } from 'shared/consts/types';

import { i18nHelper } from 'i18nHelper';

const JOIN_OPERATOR_MAPPINGS: any = [
	{
		name: 'AND',
		value: ' AND ',
	},
	{
		name: 'OR',
		value: ' OR ',
	},
	{
		name: 'CUSTOM',
		value: 'CUSTOM',
	},
];

const CONDITIONS = () => ({
	isEqualTo: {
		id: 'IS_EQUAL_TO',
		label: i18nHelper('common:condition.isEqualTo'),
		rightOperandRequired: true,
	},
	isNotEqualTo: {
		id: 'IS_NOT_EQUAL_TO',
		label: i18nHelper('common:condition.isNotEqualTo'),
		rightOperandRequired: true,
	},
	isEmpty: {
		id: 'IS_EMPTY',
		label: i18nHelper('common:condition.isEmpty'),
		rightOperandRequired: false,
	},
	isNotEmpty: {
		id: 'IS_NOT_EMPTY',
		label: i18nHelper('common:condition.isNotEmpty'),
		rightOperandRequired: false,
	},
	contains: {
		id: 'CONTAINS',
		label: i18nHelper('common:condition.contains'),
		rightOperandRequired: true,
	},
	doesNotContain: {
		id: 'DOES_NOT_CONTAINS',
		label: i18nHelper('common:condition.doesNotContain'),
		rightOperandRequired: true,
	},
	startsWith: {
		id: 'BEGINS_WITH',
		label: i18nHelper('common:condition.startsWith'),
		rightOperandRequired: true,
	},
	endsWith: {
		id: 'ENDS_WITH',
		label: i18nHelper('common:condition.endsWith'),
		rightOperandRequired: true,
	},
	before: {
		id: 'BEFORE',
		label: i18nHelper('common:condition.before'),
		rightOperandRequired: true,
	},
	after: {
		id: 'AFTER',
		label: i18nHelper('common:condition.after'),
		rightOperandRequired: true,
	},
	inNextXdays: {
		id: 'IN_NEXT_X_DAYS',
		label: i18nHelper('common:condition.inNextXdays'),
		rightOperandRequired: true,
	},
	beforeXdays: {
		id: 'BEFORE_X_DAYS',
		label: i18nHelper('common:condition.beforeXdays'),
		rightOperandRequired: true,
	},
	lastXdays: {
		id: 'LAST_X_DAYS',
		label: i18nHelper('common:condition.lastXdays'),
		rightOperandRequired: true,
	},
	isGreaterThan: {
		id: 'IS_GREATER_THAN',
		label: i18nHelper('common:condition.isGreaterThan'),
		rightOperandRequired: true,
	},
	isLessThan: {
		id: 'IS_LESS_THAN',
		label: i18nHelper('common:condition.isLessThan'),
		rightOperandRequired: true,
	},
	isGreaterThanOrEqualTo: {
		id: 'IS_GREATER_THAN_OR_EQUAL_TO',
		label: i18nHelper('common:condition.isGreaterThanOrEqualTo'),
		rightOperandRequired: true,
	},
	isLessThanOrEqualTo: {
		id: 'IS_LESS_THAN_OR_EQUAL_TO',
		label: i18nHelper('common:condition.isLessThanOrEqualTo'),
		rightOperandRequired: true,
	},
	in: {
		id: 'IN',
		label: i18nHelper('common:condition.in'),
		rightOperandRequired: true,
	},
	notIn: {
		id: 'NOT_IN',
		label: i18nHelper('common:condition.notIn'),
		rightOperandRequired: true,
	},
	notInLastXdays: {
		id: 'NOT_IN_LAST_X_DAYS',
		label: i18nHelper('common:condition.notInLastXdays'),
		rightOperandRequired: true,
	},
});

const TYPE_OPERATOR_MAPPINGS: () => IObjProps = () => ({
	TEXT: [
		CONDITIONS().isEmpty,
		CONDITIONS().isNotEmpty,
		CONDITIONS().isEqualTo,
		CONDITIONS().isNotEqualTo,
		CONDITIONS().contains,
		CONDITIONS().startsWith,
		CONDITIONS().endsWith,
	],
	DATE: [
		CONDITIONS().isEqualTo,
		CONDITIONS().isNotEqualTo,
		CONDITIONS().isEmpty,
		CONDITIONS().isNotEmpty,
		CONDITIONS().before,
		CONDITIONS().after,
		CONDITIONS().inNextXdays,
		CONDITIONS().beforeXdays,
		CONDITIONS().lastXdays,
	],
	DATETIME: [
		CONDITIONS().isEqualTo,
		CONDITIONS().isNotEqualTo,
		CONDITIONS().isEmpty,
		CONDITIONS().isNotEmpty,
		CONDITIONS().before,
		CONDITIONS().after,
		CONDITIONS().inNextXdays,
		CONDITIONS().beforeXdays,
		CONDITIONS().lastXdays,
	],
	TIME: [
		CONDITIONS().isEqualTo,
		CONDITIONS().isNotEqualTo,
		CONDITIONS().isEmpty,
		CONDITIONS().isNotEmpty,
		CONDITIONS().inNextXdays,
		CONDITIONS().beforeXdays,
		CONDITIONS().lastXdays,
	],
	BOOLEAN: [CONDITIONS().isEqualTo, CONDITIONS().isNotEqualTo],
	PRIORITY_NUMBER: [
		CONDITIONS().isEqualTo,
		CONDITIONS().isNotEqualTo,
		CONDITIONS().contains,
		CONDITIONS().doesNotContain,
		CONDITIONS().isLessThan,
		CONDITIONS().isGreaterThan,
	],
	NUMBER: [
		CONDITIONS().isEqualTo,
		CONDITIONS().isNotEqualTo,
		CONDITIONS().isEmpty,
		CONDITIONS().isNotEmpty,
		CONDITIONS().isLessThan,
		CONDITIONS().isGreaterThan,
	],
	CUSTOMERSEGMENT: [
		CONDITIONS().isEqualTo,
		CONDITIONS().isLessThan,
		CONDITIONS().isGreaterThan,
		CONDITIONS().isGreaterThanOrEqualTo,
		CONDITIONS().isLessThanOrEqualTo,
	],
	LASTORDER: [CONDITIONS().lastXdays, CONDITIONS().notInLastXdays],
	PRODUCTTYPE: [CONDITIONS().in, CONDITIONS().notIn],
	LOCATION: [
		CONDITIONS().isEmpty,
		CONDITIONS().isNotEmpty,
		CONDITIONS().isEqualTo,
		CONDITIONS().isNotEqualTo,
		CONDITIONS().contains,
		CONDITIONS().startsWith,
		CONDITIONS().endsWith,
	],
	ARRAY: [CONDITIONS().isEmpty, CONDITIONS().isNotEmpty],
	OBJECT: [CONDITIONS().isEmpty, CONDITIONS().isNotEmpty],
	SELECT: [CONDITIONS().isEqualTo, CONDITIONS().isNotEqualTo],
	ROUTING_TEXT: [
		CONDITIONS().isEqualTo,
		CONDITIONS().isNotEqualTo,
		CONDITIONS().contains,
		CONDITIONS().doesNotContain,
	],
	ROUTING_NUMBER: [
		CONDITIONS().isEqualTo,
		CONDITIONS().isNotEqualTo,
		CONDITIONS().contains,
		CONDITIONS().doesNotContain,
	],
	ROUTING_BOOLEAN: [CONDITIONS().isEqualTo, CONDITIONS().isNotEqualTo],
	ROUTING_SELECT: [CONDITIONS().isEqualTo, CONDITIONS().isNotEqualTo],
	ROUTING_DATE: [
		CONDITIONS().isEqualTo,
		CONDITIONS().isNotEqualTo,
		CONDITIONS().contains,
		CONDITIONS().doesNotContain,
	],
	SINGLE_SELECT: [
		CONDITIONS().isEmpty,
		CONDITIONS().isNotEmpty,
		CONDITIONS().isEqualTo,
		CONDITIONS().isNotEqualTo,
		CONDITIONS().contains,
		CONDITIONS().startsWith,
		CONDITIONS().endsWith,
	],
});

export { CONDITIONS, JOIN_OPERATOR_MAPPINGS, TYPE_OPERATOR_MAPPINGS };
