import { COMMENTS_SYNC_STATUS } from 'shared/consts/consts';
import { ONEVIEW_SCREENS } from 'shared/consts/SocialInbox/constants';
import { IObjProps } from 'shared/consts/types';
import { CHANNELS } from 'shared/enum';
import { isValidResponseObject } from 'shared/helpers';

import { RootState } from 'store/rootReducer';

import {
	appendCommentsForSelectedPost,
	setUnreadPostIdCountMapAction,
	updateCommentListAction,
	updateSelectedPostAction,
} from '../actions';
import { actions } from '../store';

import { postCommentsAPI } from './api';
import { IComment } from './state';

const {
	populateCommentsForSelectedPost,
	discardCurrentListOfComments,
	setCommentsLoading,
	updateCommentList,
	addCommentOrReplyIntoCommentList,
	updatePostedCommentIds,
	disablePrivateReply,
} = actions;

export const postCommentsActions = {
	getCommentsForSelectedPostAction:
		(cancelToken?: string, successCb?: any, errorCb?: any) => (dispatch: any, getState: () => RootState) => {
			const pageNo = getState().Message.listOfComments?.pagination?.page;
			const { selectedPost } = getState().Message;
			const { unreadPostIdCountMap } = getState().Message;
			const { currentOneViewScreen } = getState().Message;

			const payload = {
				platform:
					currentOneViewScreen === ONEVIEW_SCREENS.INSTAGRAM_COMMENTS
						? CHANNELS.INSTAGRAM
						: CHANNELS.FACEBOOK,
				page: pageNo + 1,
				size: 10,
				postId: selectedPost.postId,
				pageId: selectedPost.pageId,
			};

			postCommentsAPI
				.getCommentsForPost(payload, {
					cancelToken,
				})
				.then((resp: IObjProps) => {
					if (isValidResponseObject(resp) || resp?.data?.status?.code === 1002) {
						const response = resp.data.responseObject;
						const commentsList = response.content;
						const updatedCommentsList: Array<IComment> = [];
						commentsList?.forEach((data: IObjProps, index: number) => {
							updatedCommentsList[index] = {
								commentId: data.commentId,
								message: data.message,
								mediaType: data.mediaType,
								attachments: data.attachments,
								repliesNextCursor: data.repliesNextCursor,
								from: data.from,
								replies: {
									data: data?.replies,
									loading: false,
									error: false,
									pagination: {
										page: 0,
										size: 10,
										totalPages: data?.fetchedRepliesCount
											? Math.ceil(data.fetchedRepliesCount / 10)
											: 0,
										totalElements: data?.fetchedRepliesCount ? data.fetchedRepliesCount : 0,
									},
								},
								updatedOn: data.updatedOn,
								canReplyPrivately: data.canReplyPrivately,
								repliesSyncStatus: data.repliesSyncStatus || COMMENTS_SYNC_STATUS.SUCCESS,
							};
						});
						if (unreadPostIdCountMap.hasOwnProperty(selectedPost.postId)) {
							const updateUnreadPostIdCountMap = { ...unreadPostIdCountMap };
							delete updateUnreadPostIdCountMap[selectedPost.postId];
							dispatch(setUnreadPostIdCountMapAction(updateUnreadPostIdCountMap));
						}
						response['content'] = updatedCommentsList;
						dispatch(appendCommentsForSelectedPost(response));
						successCb && successCb();
					} else if (resp?.data?.status?.code === 1001) {
						// sync initiated
						const updatedPost = { ...selectedPost };
						updatedPost.commentsSyncStatus = COMMENTS_SYNC_STATUS.IN_PROGRESS;
						dispatch(updateSelectedPostAction(updatedPost));
						successCb && successCb();
					} else {
						errorCb && errorCb();
					}
				});
		},
	getRepliesForCommentAction:
		(commentId: string, pageNo: number, cancelToken?: string, successCb?: any, errorCb?: any) =>
		(dispatch: any, getState: () => RootState) => {
			const { selectedPost } = getState().Message;
			const payload = {
				platform: CHANNELS.INSTAGRAM,
				page: pageNo + 1,
				size: 10,
				postId: selectedPost.postId,
				pageId: selectedPost.pageId,
				parentCommentId: commentId,
			};

			postCommentsAPI
				.getRepliesForComment(payload, {
					cancelToken,
				})
				.then((resp: IObjProps) => {
					if (isValidResponseObject(resp) || resp?.data?.status?.code === 1002) {
						const response = resp.data.responseObject;
						const repliesList = response.content;
						const commentListData: Array<IObjProps> = getState().Message.listOfComments.data;
						const commentList = [...commentListData];

						commentList.every((comment, index) => {
							if (comment.commentId === commentId) {
								const commentToUpdate = { ...commentList[index] };
								const repliesToUpdate = { ...commentToUpdate['replies'] };
								const repliesPagination = {
									page: response.number,
									size: response.size,
									totalPages: response.totalPages,
									totalElements: response.totalElements,
								};

								repliesToUpdate['data'] = [...repliesToUpdate['data'], ...repliesList];
								repliesToUpdate['pagination'] = repliesPagination;
								commentToUpdate['replies'] = repliesToUpdate;
								commentList[index] = { ...commentToUpdate };

								return false;
							}

							return true;
						});

						dispatch(updateCommentListAction(commentList));
						successCb && successCb();
					} else if (resp?.data?.status?.code === 1001) {
						// sync initiated

						const commentListData: Array<IObjProps> = getState().Message.listOfComments.data;
						const commentList = [...commentListData];

						commentList.every((comment, index) => {
							if (comment.commentId === commentId) {
								const commentToUpdate = { ...commentList[index] };
								commentToUpdate['repliesSyncStatus'] = COMMENTS_SYNC_STATUS.IN_PROGRESS;
								commentList[index] = { ...commentToUpdate };

								return false;
							}

							return true;
						});
						dispatch(updateCommentListAction(commentList));
						successCb && successCb();
					} else {
						errorCb && errorCb();
					}
				});
		},
	appendCommentsForSelectedPost: (response: IObjProps) => (dispatch: any) => {
		dispatch(populateCommentsForSelectedPost(response));
	},
	discardCurrentComments: () => (dispatch: any) => {
		dispatch(discardCurrentListOfComments());
	},
	setCommentsLoadingAction: () => (dispatch: any) => {
		dispatch(setCommentsLoading());
	},
	updateCommentListAction: (commentList: Array<IObjProps>) => (dispatch: any) => {
		dispatch(updateCommentList(commentList));
	},
	addCommentOrReplyIntoCommentListAction:
		(newComment: IComment, currentCommentParentCommentId: string) => (dispatch: any) => {
			dispatch(addCommentOrReplyIntoCommentList({ newComment, currentCommentParentCommentId }));
		},
	updatePostedCommentIdsAction: (commentIds: Array<string>) => (dispatch: any) => {
		dispatch(updatePostedCommentIds(commentIds));
	},
	disablePrivateReplyAction: (parentCommentId: string, childCommentId: string) => (dispatch: any) => {
		dispatch(
			disablePrivateReply({ requiredParentCommentId: parentCommentId, requiredChildCommentId: childCommentId })
		);
	},
};
