import EventEmitter from 'eventemitter3';

import {
	ACCOUNT_TYPES,
	GROUP_CONVERSATION_STATES,
	MESSAGE_STATE_MAP,
	MESSAGE_TAB_HEADERS_MAP,
} from 'shared/consts/consts';
import { CHANNELS } from 'shared/enum';

import { i18nHelper } from 'i18nHelper';

export const channelList = [CHANNELS.FACEBOOK, CHANNELS.DIALOG360, CHANNELS.INSTAGRAM, CHANNELS.WHATSAPP];

export const translation = (key: string) => i18nHelper(`pages:socketNotifications.${key}`);

export enum TAGGING_OPERATION {
	'ADD' = 'ADD',
	'REMOVE' = 'REMOVE',
}

export enum COPY_BOT_STATUS {
	'STARTED' = 'STARTED',
	'COMPLETED' = 'COMPLETED',
	'FAILED' = 'FAILED',
}

export enum COPY_BOT_SUB_TYPE {
	'IMPORT' = 'IMPORT',
	'EXPORT' = 'EXPORT',
}

export enum SOCKET_EVENTS {
	RECONNECT = 'reconnect',
	LIVECHAT_STATUS_CHANGE = 'liveChatStatusChange',
	NOTIFY_NEW_MESSAGES = 'notifyNewMessages',
	SWITCH_TO_AGENT = 'switchToAgent',
	CONVERSATION_READ = 'conversation_read',
	STAR_CONVERSATION = 'star_conversation',
	GENERAL_NOTIFICATION = 'generalNotification',
	VIDEO_STATUS_UPDATE = 'video_status_update',
	NOTIFY_GROUP_CONVERSATION_INVITE = 'notifyGroupConversationInvite',
	NOTIFY_GROUP_VIDEO_CONVERSATION_INVITE = 'notifyGroupVideoConversationInvite',
	NOTIFY_GROUP_CONVERSATION_JOIN = 'notifyGroupConversationJoin',
	NOTIFY_GROUP_CONVERSATION_EXIT = 'notifyGroupConversationExit',
	NOTIFY_GROUP_CONVERSATION_INVITE_EXPIRE = 'notifyGroupConversationInviteExpire',
	NOTIFY_GROUP_CONVERSATION_INVITE_SENT = 'notifyGroupConversationInvitationSent',
	NOTIFY_GROUP_CONVERSATION_PENDING = 'notifyGroupConversationPendingResolution',
	NOTIFY_GROUP_CONVERSATION_RESOLUTION = 'notifyGroupConversationResolution',
	NEW_LIVECHAT_REQUEST = 'newLiveChatRequest',
	NEW_LIVECHAT_ASSIGNED = 'newLiveChatRequestAssigned',
	LIVECHAT_TRANSFER = 'liveChatTransfer',
	NEW_LIVECHAT_REQUEST_ENGAGED = 'newLiveChatRequestEngaged',
	NOTIFY_SENDER_REACTION = 'notifySenderAction',
	NOTIFY_EXIT_LIVECHAT = 'notifyExitLiveChat',
	NOTIFY_CHAT_STATE_UPDATE = 'notifyChatStateUpdate',
	CHAT_STATUS_CHANGE = 'chat_status_change',
	NOTIFY_CONVERSATION_READ_STATUS = 'notify_conversation_read_status',
	MESSAGE_STATUS_UPDATE = 'messageStatusUpdate',
	TEST_BROADCAST_STATUS_UPDATE = 'testBroadcastStatusUpdate',
	COMMENT_SYNC_STATUS = 'commentSyncStatus',
	COMMENT_REPLIES_SYNC_STATUS = 'commentRepliesSyncStatus',
	NOTIFY_NEW_COMMENT = 'notifyNewComment',
	ASK_USER_ID = 'ask_userid',
}

export const stateToTabMapping: { [key: string]: string } = {
	[MESSAGE_STATE_MAP.ASSIGNED]: MESSAGE_TAB_HEADERS_MAP.new,
	[MESSAGE_STATE_MAP.ENGAGING]: MESSAGE_TAB_HEADERS_MAP.active,
	DEFAULT: MESSAGE_TAB_HEADERS_MAP.unassigned,
};

export const groupTabToStateMapping: { [key: string]: string } = {
	[MESSAGE_TAB_HEADERS_MAP.new]: GROUP_CONVERSATION_STATES.invited,
	[MESSAGE_TAB_HEADERS_MAP.active]: GROUP_CONVERSATION_STATES.joined,
	DEFAULT: '',
};

export const generalNotificationTypes = {
	categoryDeletion: 'category_delete_status',
	documentStatusChange: 'DOCUMENT_STATUS_CHANGE',
	slaNotification: 'sla_notification',
	taggedAgents: 'tagged_agents',
	userVideoStatusNotification: 'user_video_conversation_status',
	importExport: 'IMPORT_EXPORT',
	copyBot: 'COPY_BOT',
	templateAccountBotCreation: 'TEMPLATE_ACCOUNT_BOT_CREATION',
	templateStatus: 'TEMPLATE_UPDATE',
	botSetupStatus: 'BOT_SETUP_STATUS',
	storeBotSetupStatus: 'STORE_BOT_SETUP_STATUS',
};

export const notificationActionTitles = {
	newConversationRequest: 'New Conversation Request',
	categoryDeletion: 'Category Deletion',
	conversationUpdate: 'Conversation Update',
	slaNonAdherence: 'SLA non-adherence',
	groupConversationRequest: 'Group Conversation Request',
	newVideoCallRequest: 'New Video Call Request',
	newConversationAssigned: 'New Conversation Assigned',
};

export const isOwner = (role: string) => role === 'ROLE_CONNECT_SUPERVISOR' || role === 'ROLE_CONNECT_OWNER';

export const isMasterAdmin = localStorage.getItem('loggedInUserAccountType') === ACCOUNT_TYPES.MASTER_ADMIN;

export const socketEventEmitter = new EventEmitter();

export const SOCKET_CONNECTED_SUCCESSFULLY = 'Connected Successfully';
