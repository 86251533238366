import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Button, KIND } from 'baseui/button';
import { SIZE } from 'baseui/input';
import { PLACEMENT } from 'baseui/toast';
import { useTranslation } from 'react-i18next';

import ENGTErrorBoundary from 'components/ENGTErrorBoundary/ENGTErrorBoundary';
import FilterSelectionDot from 'components/FilterSelectionDot/FilterSelectionDot';
import ENGTRadio from 'components/UI/ENGTRadio/ENGTRadio';
import FilterDropdown from 'components/UI/FilterDropdown/FilterDropdown';

import FilterSortIcon from 'shared/icons/SortIcon';

export interface ISortPopup {
	initialValue: string;
	onSortValueChange: (value: string) => void;
	sortOptions: any;
}

function BroadcastSort(props: ISortPopup) {
	const { initialValue, onSortValueChange, sortOptions } = props;

	const [css, theme]: [any, any] = useStyletron();
	const [sortApplied, setSortApplied] = useState<string>('');
	const [resetValue, setResetValue] = useState(initialValue);
	const { t } = useTranslation(['components', 'errors']);

	const SortIcon = <FilterSortIcon fillColor={theme.colors.primaryA} />;

	const circularButtonCss: any = {
		':hover': {
			background: theme.colors.contentStateDisabled,
		},
		height: '2.5rem',
		width: '2.5rem',
		position: 'relative',
	};

	useEffect(() => {
		setSortApplied(initialValue);
	}, [initialValue]);

	const sortContent = (
		<ENGTErrorBoundary message={t('errors:errorBoundary.messageFilter')}>
			<FilterDropdown
				headingLabel={t('components:broadcastSort.sortByHeader')}
				popoverMargin={5}
				isOpen
				rootButton={
					<Button
						size={SIZE.default}
						shape='circle'
						kind={KIND.secondary}
						overrides={{
							BaseButton: {
								style: circularButtonCss,
							},
						}}
					>
						<FilterSelectionDot />
						{SortIcon}
					</Button>
				}
				rootButtonCss={{
					height: '2.5rem',
				}}
				openOnClick
				dismissOnClickOutside
				dismissOnEsc={false}
				placement={PLACEMENT.bottomRight}
				onOpen={() => setResetValue(initialValue)}
				onApply={() => {
					if (sortApplied !== initialValue) {
						onSortValueChange(sortApplied);
					}
				}}
				className={css({
					width: '13.625rem',
					zIndex: theme.colors.zIndex200,
				})}
			>
				<div
					className={css({
						marginLeft: '1rem',
						marginRight: '1rem',
						marginTop: '0.5rem',
					})}
				>
					<ENGTRadio
						type='stateless'
						group='usersSortBy'
						buttons={sortOptions}
						value={resetValue}
						onChange={(value) => {
							setResetValue(value);
							setSortApplied(value);
						}}
						overrides={{
							root: {
								marginBottom: '0.825rem',
							},
							label: {
								paddingTop: '0.25rem',
							},
						}}
					/>
				</div>
			</FilterDropdown>
		</ENGTErrorBoundary>
	);

	return sortContent;
}

export default BroadcastSort;
