import React from 'react';
import { useStyletron } from 'baseui';

import { IObjProps } from 'shared/consts/types';
import { borderRadius } from 'shared/helpers';

import ClearTextIcon from './ClearTextIcon';

const CrossButton = (props: { onClose: () => void; size?: number; className?: IObjProps }) => {
	const { onClose, size, className } = props;
	const [css, theme]: any = useStyletron();

	return (
		<div
			className={css({
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: theme.colors.accent100,
				cursor: 'pointer',
				position: 'absolute',
				width: '3rem',
				height: '3rem',
				top: '1rem',
				right: '1rem',
				...borderRadius('50%'),
				...className,
			})}
			onClick={onClose}
		>
			<ClearTextIcon size={size || 16} fillColor={theme.colors.primaryBtnFontColor} />
		</div>
	);
};

export default CrossButton;
