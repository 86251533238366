import { Button, KIND, SIZE } from 'baseui/button';

export interface IENGTButton {
	className?: string;
	endEnhancer?: React.ReactNode;
	isLoading?: boolean;
	isDisabled?: boolean;
	kind?: keyof KIND;
	label?: React.ReactNode;
	size?: keyof SIZE;
	icon?: any;
	type?: 'button' | 'submit' | 'reset';
	startEnhancer?: React.ReactNode;
	[key: string]: any;
	dataQa?: string;
	buttonRef?: React.RefObject<HTMLButtonElement | null>;

	onClick?: (args: any) => any;
}

const buttonKindCss = ($theme: any, kind: IENGTButton['kind'], $isFocusVisible: any) => {
	switch (kind) {
		case KIND.minimal:
			return {
				lineHeight: '1.25rem',
			};
		case KIND.primary:
			return {
				boxShadow: $isFocusVisible ? `1px 2px 2px ${$theme.colors.primaryA}` : '',
				fontWeight: 'bold' as const,
				fontStyle: 'normal',
				lineHeight: '1.25rem',
			};
		case KIND.secondary:
			return {
				fontWeight: 'bold' as const,
				fontStyle: 'normal',
				lineHeight: '1.25rem',
			};
		case KIND.tertiary:
			return {
				boxShadow: '0px 1px 2px rgba(64, 63, 66, 0.11)',
				background: $theme.colors.primaryBtnTextColor,
				color: $theme.colors.primaryBtnFontColor,
				lineHeight: '1.25rem',
			};
		default:
			return {
				fontWeight: 'bold' as const,
				fontStyle: 'normal',
				lineHeight: '1rem',
			};
	}
};

const buttonSizeCss = ($theme: any, size: IENGTButton['size']): any => {
	switch (size) {
		case SIZE.large:
			return {
				borderTopLeftRadius: $theme.borders.radius300,
				borderTopRightRadius: $theme.borders.radius300,
				borderBottomLeftRadius: $theme.borders.radius300,
				borderBottomRightRadius: $theme.borders.radius300,
				paddingRight: $theme.sizing.scale900,
				paddingLeft: $theme.sizing.scale900,
				paddingTop: $theme.sizing.scale550,
				paddingBottom: $theme.sizing.scale500,
				fontSize: $theme.sizing.scale600,
			};
		case SIZE.default:
			return {
				borderTopLeftRadius: $theme.radius250,
				borderTopRightRadius: $theme.radius250,
				borderBottomLeftRadius: $theme.radius250,
				borderBottomRightRadius: $theme.radius250,
				paddingRight: $theme.sizing.scale600,
				paddingLeft: $theme.sizing.scale600,
				paddingTop: $theme.sizing.scale400,
				paddingBottom: $theme.sizing.scale300,
				fontSize: $theme.sizing.scale550,
			};
		case SIZE.compact:
			return {
				borderTopLeftRadius: $theme.borders.radius200,
				borderTopRightRadius: $theme.borders.radius200,
				borderBottomLeftRadius: $theme.borders.radius200,
				borderBottomRightRadius: $theme.borders.radius200,
				paddingRight: $theme.sizing.scale500,
				paddingLeft: $theme.sizing.scale500,
				paddingTop: $theme.sizing.scale200,
				paddingBottom: $theme.sizing.scale100,
				fontSize: $theme.sizing.scale500,
			};
		case SIZE.mini:
			return {
				borderTopLeftRadius: $theme.borders.radius200,
				borderTopRightRadius: $theme.borders.radius200,
				borderBottomLeftRadius: $theme.borders.radius200,
				borderBottomRightRadius: $theme.borders.radius200,
				paddingRight: $theme.sizing.scale200,
				paddingLeft: $theme.sizing.scale200,
				paddingTop: $theme.sizing.scale100,
				paddingBottom: $theme.sizing.scale50,
				fontSize: $theme.sizing.scale400,
			};
		default:
			return {
				borderTopLeftRadius: $theme.borders.radius300,
				borderTopRightRadius: $theme.borders.radius300,
				borderBottomLeftRadius: $theme.borders.radius300,
				borderBottomRightRadius: $theme.borders.radius300,
				paddingRight: $theme.sizing.scale600,
				paddingLeft: $theme.sizing.scale600,
				paddingTop: $theme.sizing.scale400,
				paddingBottom: $theme.sizing.scale300,
				fontSize: $theme.sizing.scale550,
			};
	}
};

const ENGTButton: React.FC<React.PropsWithChildren<IENGTButton>> = ({
	endEnhancer,
	isLoading,
	isDisabled,
	kind,
	label,
	size,
	icon,
	startEnhancer,
	type = 'button',
	dataQa,
	buttonRef,
	...rest
}) => (
	<Button
		disabled={isDisabled}
		isLoading={isLoading}
		kind={kind}
		size={size}
		type={type}
		{...(endEnhancer && { endEnhancer })}
		{...(startEnhancer && { startEnhancer })}
		overrides={{
			BaseButton: {
				style: (props: any) => {
					const { $theme, $isFocusVisible } = props;

					return {
						...buttonSizeCss($theme, size),
						...buttonKindCss($theme, kind, $isFocusVisible),
						alignItems: 'center !important',
						':hover': {
							backgroundColor: 'none',
						},
					};
				},
				props: buttonRef && {
					ref: buttonRef,
				},
			},
			StartEnhancer: {
				style: {
					marginRight: '0px',
					marginBottom: '3px',
				},
			},
		}}
		data-qa={dataQa}
		{...rest}
	>
		{label}
		{icon}
	</Button>
);

export default ENGTButton;
