import { createSlice } from '@reduxjs/toolkit';

import { botReducer } from './reducers';
import { botState } from './state';

const botSlice = createSlice({
	name: 'Bot',
	initialState: botState,
	reducers: botReducer,
});

export const { actions, reducer } = botSlice;
export { botSlice };
