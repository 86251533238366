const FilledUpCaret = ({
	fillColor = '#403F42',
	width = 7,
	height = 5,
	pathToDraw = 'M4.04769 1.03376C3.73794 0.735709 3.24803 0.735709 2.93828 1.03376L0.246909 3.62354C-0.272027 4.12288 0.0814412 5 0.801609 5H6.18436C6.90453 5 7.258 4.12289 6.73906 3.62354L4.04769 1.03376Z',
}: {
	fillColor?: string;
	width?: number;
	height?: number;
	pathToDraw?: string;
}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d={pathToDraw} fill={fillColor} />
	</svg>
);

export default FilledUpCaret;
