import React from 'react';
import { useStyletron } from 'baseui';

import { IObjProps } from 'shared/consts/types';

function DropDownToggleUp({ position = 0, size = 32 }: IObjProps) {
	const [, themes] = useStyletron();
	const box = `${position} ${position} ${size} ${size}`;

	return (
		<svg width={size} height={size} viewBox={box} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 18.3658L11.4142 19.78L16.364 14.8303L21.3137 19.78L22.7279 18.3658L16.3621 12L14.9479 13.4142L14.9497 13.416L10 18.3658Z'
				fill={themes.colors.primaryA}
			/>
		</svg>
	);
}

export default DropDownToggleUp;
