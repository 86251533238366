import { useStyletron } from 'baseui';
import { HeadingLevel } from 'baseui/heading';
import { LabelSmall, ParagraphMedium } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ParentSize from '@visx/responsive/lib/components/ParentSize';

import ENGTBarGroupY from 'components/UI/Graph/ENGTBarGroupY';
import NoDataTemplate from 'components/UI/NoDataTemplate/NoDataTemplate';

import { IObjProps } from 'shared/consts/types';
import { CHANNELS } from 'shared/enum';
import NoGraphIcon from 'shared/icons/NoGraphIcon.svg';

import { profileDataSelector } from 'store/App/User';

const paragraphCss = {
	marginTop: '0.75rem',
	marginBottom: '0.3125rem',
	fontWeight: '700 !important',
};

interface IUserHistory {
	nonUniqueUsers: IObjProps;
}

const UsersHistory = ({ nonUniqueUsers }: IUserHistory) => {
	const { t } = useTranslation(['common', 'pages']);
	const [css, theme]: [any, any] = useStyletron();
	const profileData = useSelector(profileDataSelector);

	const getTotalUsersByChannel = (userHistory: IObjProps) => {
		let facebookTotal = 0;
		let instagramTotal = 0;

		userHistory?.adSourceAndTypeCountDTOList?.forEach((adSourceDTO: IObjProps) => {
			const channel = adSourceDTO.adSource;
			adSourceDTO?.adTypeCountDTOList?.forEach((typeDTO: { count: number }) => {
				const count = typeDTO.count || 0;

				if (channel === CHANNELS.FACEBOOK_ALL_CAPS) {
					facebookTotal += count;
				} else if (channel === CHANNELS.INSTAGRAM_ALL_CAPS) {
					instagramTotal += count;
				}
			});
		});

		return { facebookTotal, instagramTotal };
	};

	const weekDataKey = (userHistory: IObjProps) => {
		const noOfWeeks = 4;

		return nonUniqueUsers?.userHistoryDTOList.length === noOfWeeks ? formatDate(userHistory.key) : userHistory.key;
	};

	const formatDate = (dateString: string) => {
		const [startYear, startMonth, startDay, endYear, endMonth, endDay] = dateString.split('-');
		const formattedStartDate = `${startDay}/${startMonth} - ${endDay}/${endMonth}`;

		return formattedStartDate;
	};

	const formatDataForStackBar = () => {
		const dataForStackBar: { [key: string]: any }[] = [];

		nonUniqueUsers?.userHistoryDTOList?.forEach((userHistory: IObjProps) => {
			const weekData: { [key: string]: any } = {
				text: weekDataKey(userHistory),
			};

			const { facebookTotal, instagramTotal } = getTotalUsersByChannel(userHistory);

			weekData[CHANNELS.FACEBOOK_ALL_CAPS] = facebookTotal;
			weekData[CHANNELS.INSTAGRAM_ALL_CAPS] = instagramTotal;
			dataForStackBar.push(weekData);
		});

		return dataForStackBar;
	};

	const graphHeader = (
		<LabelSmall
			color={theme.colors.primaryA}
			overrides={{
				Block: {
					style: {
						fontWeight: 700,
						marginLeft: '2rem',
						marginTop: '0.5rem',
					},
				},
			}}
		>
			{t('pages:broadcast.clickToWhatsappAds.facebookAndInstagram')}
		</LabelSmall>
	);

	return (
		<div className={css({ height: '19.563rem' })}>
			<div style={{ marginTop: '0.75rem' }}>
				<HeadingLevel>
					<ParagraphMedium
						color={theme.colors.primaryA}
						className={css({
							...paragraphCss,
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						})}
					>
						{t('pages:broadcast.clickToWhatsappAds.usersHistory')}
						{nonUniqueUsers?.lastUpdatedOn && (
							<div className={css({ fontWeight: 400, fontSize: '0.875rem', alignItems: 'center' })}>
								{t('components:conversations.lastUpdatedOn')}
								<span style={{ marginLeft: '0.2rem' }}>{`${t(
									'common:standardDateTimeFormatterWithShortMonthNameAndTimeAheadOfDate',
									{
										date: nonUniqueUsers.lastUpdatedOn,
									}
								)} (${profileData.timezone})`}</span>
							</div>
						)}
					</ParagraphMedium>
				</HeadingLevel>
			</div>
			{nonUniqueUsers?.total ? (
				<ParentSize className={css({ marginTop: '0.75rem', paddingBottom: '8rem' })}>
					{({ width, height }) => (
						<ENGTBarGroupY
							width={width || 917}
							height={400}
							keys={[CHANNELS.FACEBOOK_ALL_CAPS, CHANNELS.INSTAGRAM_ALL_CAPS]}
							barRadius={0}
							header={graphHeader}
							colors={[`${theme.colors.engatiPurple}`, `${theme.colors.engatiYellow}`]}
							textColor={theme.colors.primaryA}
							gridStroke={theme.colors.primaryA}
							background={theme.colors.inputFillPrimary}
							data={formatDataForStackBar().reverse()}
							accessor={(data: IObjProps) => data.text}
							margin={{
								top: 80,
								right: 50,
								bottom: 30,
								left: 70,
							}}
							padding={{
								inner: 0.0,
								outer: 2.5,
							}}
							xFormatter={(data: any) => data}
						/>
					)}
				</ParentSize>
			) : (
				<NoDataTemplate
					heading={t('pages:broadcast.analytics.noData')}
					bannerImage={{
						altText: 'No Graph Available Icon',
						imageUrl: NoGraphIcon,
					}}
				/>
			)}
		</div>
	);
};

export default UsersHistory;
