import { ENGATI_RESPONSE_CODE } from 'shared/consts/consts';

import { API } from './api';
import { actions } from './store';

const { getCurrentPlan, getCurrentPlanError, getCustomerDetails, getCustomerDetailsError } = actions;

export const getCurrentPlanAction = () => (dispatch: any) =>
	API.fetchCurrentPlan().then((resp: any) => {
		if (resp.data) {
			dispatch(getCurrentPlan(resp.data.customer_plan_data));
		} else {
			dispatch(getCurrentPlanError());
		}

		return resp;
	});

export const getCustomerDetailsAction = () => (dispatch: any) =>
	API.fetchCustomerDetails().then((resp: any) => {
		if (resp?.data?.status?.code === ENGATI_RESPONSE_CODE.SUCCESS) {
			dispatch(getCustomerDetails(resp.data.responseObject));
		} else {
			dispatch(getCustomerDetailsError());
		}
	});
