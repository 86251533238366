import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { useTranslation } from 'react-i18next';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';

import { IObjProps } from 'shared/consts/types';
import { GTMTrackingIds } from 'shared/enum/trackingEnums';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import AddIcon from 'shared/icons/AddIcon';
import { sendButtonClickEventToGA4 } from 'shared/trackingHelpers';

const CreateBroadcastBtn = (props: { overrides?: IObjProps; onClick: () => void; label?: string }) => {
	const [css]: [any, any] = useStyletron();
	const { t } = useTranslation(['pages']);
	const { isSmallDesktopScreen } = useResponsiveSize();

	const { overrides = {}, onClick, label = `${t('pages:broadcast.basic.createNewBroadCast')}` } = props;

	return (
		<ENGTButton
			size={SIZE.compact}
			kind={KIND.primary}
			label={label}
			startEnhancer={<AddIcon />}
			className={css({
				marginBottom: `${isSmallDesktopScreen ? '0rem' : '2rem'} !important`,
				...overrides,
			})}
			onClick={() => {
				onClick();
				sendButtonClickEventToGA4({ gtmTrackingId: GTMTrackingIds.CREATE_NEW_BROADCAST_BUTTON });
			}}
		/>
	);
};

export default CreateBroadcastBtn;
