import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const saveSmsProviderUrl = '/api/v1/conf/sms/';

const genericProxyApi = new BaseService(genericProxyRequestUrl);
const saveSmsProviderAPI = new BaseService(saveSmsProviderUrl);

export const JourneyAnalyticsApi = {
	saveGoogleAnalyticsIntegration(platform, data) {
		const param = {
			platform,
			targetApi: 'saveGoogleAnalyticsDetails',
		};

		return genericProxyApi.postRequest(data, serialize(param, '?'));
	},
	updateAnalyticsConfig(data) {
		const param = {
			targetApi: 'updateAnalyticsConfig',
			isAnalyticsEnabled: data,
		};

		return genericProxyApi.postRequest(param, serialize(param, '?'));
	},
	getGoogleAnalyticsIntegration(platform) {
		const param = {
			platform,
			targetApi: 'getGoogleAnalyticsDetails',
		};

		return genericProxyApi.getRequestWithParams(param);
	},
	getAllBotAnalyticsEvents() {
		const param = {
			targetApi: 'getAllBotAnalyticsEvents',
		};

		return genericProxyApi.getRequest(serialize(param, '?'));
	},
	saveBotAnalyticsEvent(data) {
		const param = {
			targetApi: 'saveBotAnalyticsEvent',
		};

		return genericProxyApi.postRequest(data, serialize(param, '?'));
	},
	deleteGoogleAnalyticsIntegration(platform) {
		const param = {
			platform,
			targetApi: 'deleteAnalyticsDetails',
		};

		return genericProxyApi.deleteRequest(serialize(param, '?'));
	},
	getGaCustomDimensions() {
		const param = {
			targetApi: 'customDimensionConfiguration',
		};

		return genericProxyApi.getRequestWithParams(param);
	},
	saveGaCustomDimensions(isConfigured, data) {
		const param = {
			isConfigured,
			targetApi: 'customDimensionConfiguration',
		};

		return genericProxyApi.postRequest(data, serialize(param, '?'));
	},
	getGoogleAnalyticsSettings() {
		const param = {
			targetApi: 'getGoogleAnalyticsSettings',
		};

		return genericProxyApi.getRequest(serialize(param, '?'));
	},
	saveGoogleAnalyticsSettings(data) {
		const param = {
			targetApi: 'saveGoogleAnalyticsSettings',
		};

		return genericProxyApi.postRequest(data, serialize(param, '?'));
	},
};
export const ShiprocketConfigurationApi = {
	isShiprocketConfigured() {
		const param = {
			targetApi: 'getShiprocketBotAccountMapping',
			botRef: '{{botRef}}',
		};

		return genericProxyApi.getRequest(serialize(param, '?'));
	},
	saveShiprocketBotAccountMapping(customerId, channelId, channelName, customerEmail, botKey, botRef) {
		const params = {
			targetApi: 'saveShiprocketBotAccountMapping',
		};
		const payload = {
			customer_id: customerId,
			bot_key: botKey,
			bot_ref: botRef,
			channel_id: channelId,
			channel_name: channelName,
			customer_email: customerEmail,
		};

		return genericProxyApi.postRequest(payload, serialize(params, '?'));
	},
	deleteShiprocketBotAccountMapping() {
		const param = {
			targetApi: 'deleteShiprocketBotAccountMapping',
			botRef: '{{botRef}}',
		};

		return genericProxyApi.deleteRequest(serialize(param, '?'));
	},
};
export const RazorpayConfigurationApi = {
	isRazorpayConfigured() {
		const param = {
			targetApi: 'razorpayAccountBotMappingOperations',
			botRef: '{{botRef}}',
		};

		return genericProxyApi.getRequest(serialize(param, '?'));
	},
	saveRazorpayBotAccountMapping(botKey, botRef, mode, razorpayAccountId) {
		const params = {
			targetApi: 'razorpayAccountBotMappingOperations',
		};
		const payload = {
			botKey,
			botRef,
			mode,
			razorpayAccountId,
		};

		return genericProxyApi.postRequest(payload, serialize(params, '?'));
	},
	deleteRazorpayBotAccountMapping() {
		const param = {
			targetApi: 'razorpayAccountBotMappingOperations',
			botRef: '{{botRef}}',
		};

		return genericProxyApi.deleteRequest(serialize(param, '?'));
	},
};

export const ConfigureIntegratedAccountApi = {
	getIntegratedAccountDetailsForBot(integrationKey) {
		const param = {
			targetApi: 'getIntegratedAccountDetailsForBot',
			botRef: '{{botRef}}',
			integrationKeys: Object.keys(integrationKey) || [],
			customerId: '{{customerId}}',
			scope: 'PUBLIC',
			appName: 'ENGATI',
		};

		return genericProxyApi.getRequestWithParams(param);
	},

	getIntegratedAccountForBot(integrationKey) {
		const param = {
			targetApi: 'getIntegratedAccountForBot',
			botRef: '{{botRef}}',
			integrationKey,
			customerId: '{{customerId}}',
		};

		return genericProxyApi.getRequest(serialize(param, '?'));
	},
	getBotMappedIntegrationAccount(integrationKey) {
		const params = {
			targetApi: 'getBotMappedIntegrationAccount',
			integrationKey,
			userId: '{{userId}}',
			botRef: '{{botRef}}',
			customerId: '{{customerId}}',
		};

		return genericProxyApi.getRequest(serialize(params, '?'));
	},
	getIntegratedAccountsForBot(integrationKey) {
		const param = {
			targetApi: 'getIntegratedAccountsForBot',
			botRef: '{{botRef}}',
			integrationKeys: Object.keys(integrationKey) || [],
			customerId: '{{customerId}}',
		};

		return genericProxyApi.getRequestWithParams(param);
	},
	saveUpdateIntegratedAccountForBot(integrationAccountId, integrationKey, authType = '') {
		const param = {
			targetApi: 'saveUpdateIntegratedAccountForBot',
			botRef: '{{botRef}}',
			customerId: '{{customerId}}',
			integrationAccountId,
			integrationKey,
			authType,
		};

		return genericProxyApi.postRequest({}, serialize(param, '?'));
	},

	saveSmsProviderForBot: (smsProvider) => {
		const formData = new FormData();
		formData.append('sms_provider_configured', smsProvider);

		return saveSmsProviderAPI.postRequest(formData);
	},

	saveOrUpdateIntegratedAccountForBot(data) {
		const param = {
			targetApi: 'saveOrUpdateIntegrationAccountForBot',
			botRef: '{{botRef}}',
			customerId: '{{customerId}}',
		};

		return genericProxyApi.postRequest(data, serialize(param, '?'));
	},
};
