import { SyntheticEvent } from 'react';
import { useStyletron } from 'baseui';
import { Input, SIZE } from 'baseui/input';

import { IObjProps } from 'shared/consts/types';
import { decodeAndSanitizeInput } from 'shared/helpers';

export interface IENGTInputProps {
	autoComplete?: string;
	disabled?: boolean;
	error?: IObjProps;
	inputRef?: any;
	overrides?: IObjProps;
	placeholder?: string;
	required?: boolean;
	size?: keyof SIZE;
	usage?: 'primary' | 'modal' | 'disabled' | 'node';
	width?: string;
	fontFamily?: string;
	[key: string]: any;
	clearable?: boolean;
	type?: string;
	isPasswordWidgetOpen?: boolean;
	dataQa?: string;
	onBlur?: (args: any) => any;
	onChange?: (args: any) => any;
}

function ENGTInput(props: IENGTInputProps) {
	const {
		autoComplete = 'off',
		clearable,
		disabled,
		error,
		inputRef,
		overrides = {},
		placeholder,
		required,
		size,
		usage,
		type,
		value,
		width = '',
		fontFamily,
		onBlur,
		onChange,
		dataQa,
		isPasswordWidgetOpen = false,
		...rest
	} = props;
	const [css, theme]: any = useStyletron();

	const errorMsgCss = {
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		marginTop: '0.5rem',
		color: theme.inputErrorMsgColor,
		textAlign: overrides?.errorMsgCss?.textAlign,
	};
	const overrideProps = {
		Root: {
			props: {
				...overrides?.Root?.props,
			},
			style: ({ $theme, $isFocused }: any) => {
				const { colors, inputSecondaryFillColor, inputFillModal, inputFillPrimary } = $theme;

				const borderDefaultColor = usage === 'node' ? 'transparent' : colors.inputBorderColor;

				const borderColor = error?.message
					? colors.inputBorderError
					: $isFocused
						? colors.focusedInputBorderColor
						: borderDefaultColor;

				let bgColor =
					usage === 'modal'
						? inputFillModal
						: usage === 'primary'
							? inputFillPrimary
							: usage === 'node'
								? colors.primaryB
								: colors.backgroundSecondary;

				bgColor = disabled ? inputSecondaryFillColor : bgColor;

				return {
					backgroundColor: bgColor,
					borderTopRightRadius: $theme.radius350,
					borderTopLeftRadius: $theme.radius350,
					borderBottomLeftRadius: $theme.radius350,
					borderBottomRightRadius: $theme.radius350,
					borderTopWidth: '1px',
					borderRightWidth: '1px',
					borderBottomWidth: '1px',
					borderLeftWidth: '1px',
					borderTopColor: borderColor,
					borderRightColor: borderColor,
					borderBottomColor: borderColor,
					borderLeftColor: borderColor,
					width,
					...overrides?.Root?.style,
				};
			},
		},
		ClearIconContainer: {
			style: ({ $theme }: any) => ({
				backgroundColor: $theme.colors.primaryB,
				paddingLeft: '0px',
				...overrides?.ClearIconContainer?.style,
			}),
		},
		ClearIcon: {
			style: {
				paddingLeft: '0px',
				paddingRight: '0px',
				...overrides?.ClearIcon?.style,
			},
		},
		Input: {
			component: overrides?.Input?.component,
			props: {
				'data-qa': dataQa,
				...overrides?.Input?.props,
			},
			style: ({ $theme }: any) => {
				const { colors, inputCaretColor, inputFillModal, inputFillPrimary, modalTextColor } = $theme;
				const { backgroundSecondary, inputDisabledBgColor, inputPlaceholderColor, primaryA, primaryB } =
					colors || {};

				const bgColor =
					usage === 'modal'
						? inputFillModal
						: usage === 'primary'
							? inputFillPrimary
							: usage === 'node'
								? primaryB
								: backgroundSecondary;

				const color =
					bgColor === inputFillModal || bgColor === inputPlaceholderColor ? modalTextColor : '#FFFFFF';

				return {
					color,
					caretColor: `${inputCaretColor} !important`,
					backgroundColor: `${bgColor} !important`,
					lineHeight: '1.625rem',
					'::placeholder': {
						color: `${inputPlaceholderColor} !important`,
						textAlign: 'left',
					},
					':disabled': {
						backgroundColor: `${inputDisabledBgColor} !important`,
						color: `${primaryA} !important`,
					},
					...overrides?.Input?.style,
				};
			},
		},
		InputContainer: {
			style: {
				...overrides?.InputContainer?.style,
			},
		},
		MaskToggleButton: {
			style: ({ $theme }: any) => ({
				backgroundColor: `${$theme.colors.primaryB} !important`,
				marginRight: '-0.875rem',
				...overrides?.MaskToggleButton?.style,
			}),
		},
		EndEnhancer: {
			style: ({ $theme }: any) => {
				const { inputSecondaryFillColor, inputFillModal, inputFillPrimary, colors } = $theme;
				let bgColor =
					usage === 'modal'
						? inputFillModal
						: usage === 'primary'
							? inputFillPrimary
							: usage === 'node'
								? colors.primaryB
								: colors.backgroundSecondary;

				bgColor = disabled ? inputSecondaryFillColor : bgColor;

				return {
					paddingLeft: '0rem',
					paddingRight: '0rem',
					backgroundColor: bgColor,
					cursor: 'pointer',
					...overrides?.EndEnhancer?.style,
				};
			},
		},
		StartEnhancer: {
			style: ({ $theme }: any) => {
				const { inputFillModal, inputFillPrimary, colors } = $theme;
				const bgColor =
					usage === 'modal'
						? inputFillModal
						: usage === 'primary'
							? inputFillPrimary
							: colors.backgroundSecondary;

				return {
					paddingLeft: '0rem',
					paddingRight: '0rem',
					backgroundColor: bgColor,
					...overrides?.StartEnhancer?.style,
				};
			},
		},
	};

	const handleError = required && {
		error: inputRef.current ? !inputRef.current.value.length : true,
	};

	const sanitizeInput = (event: SyntheticEvent) => {
		const input = event.target as HTMLInputElement;
		input.value = decodeAndSanitizeInput(input.value);
		onChange?.(event);
	};

	return (
		<>
			<Input
				type={type || 'text'}
				onBlur={onBlur}
				disabled={disabled}
				inputRef={inputRef}
				placeholder={placeholder}
				size={size}
				onChange={sanitizeInput}
				overrides={overrideProps}
				clearable={clearable}
				autoComplete={autoComplete}
				value={value}
				{...handleError}
				{...rest}
			/>
			{!isPasswordWidgetOpen && error?.message && <div className={css(errorMsgCss)}>{error.message}</div>}
		</>
	);
}

ENGTInput.defaultProps = {
	required: false,
	size: SIZE.default,
	usage: 'primary',
	disabled: false,
};

export default ENGTInput;
