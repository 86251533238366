import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import NavIcon from 'components/UI/NavIcon/NavIcon';

import { IPrimaryNavItems, IPrimaryShopifyNavItems } from 'shared/consts/types';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import useIconSizeHook from '../hooks/IconSizeHook';

const NavigationButton = ({
	navItem,
	navLink,
	isActive,
}: {
	navItem: IPrimaryNavItems | IPrimaryShopifyNavItems;
	navLink: string;
	isActive: Function;
}) => {
	const [css, theme]: any = useStyletron();
	const { iconSize = 30 } = useIconSizeHook();
	const { t } = useTranslation(['common']);
	const { isSmallDesktopScreen } = useResponsiveSize();

	const styles = {
		wrapper: {
			marginBottom: '1rem',
			height: `${iconSize}px`,
		},
		link: {
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			textDecoration: 'none',
			color: theme.colors.primaryA,
		},
		text: {
			marginLeft: '0.5rem',
			marginRight: '0.5rem',
			fontWeight: isActive(navItem) ? 700 : 400,
		},
	};

	return (
		<Block className={css(styles.wrapper)}>
			<NavLink to={navLink} id={`${navItem}-icon`} className={css(styles.link)}>
				<NavIcon iconSize={iconSize} routeName={navItem} isActive={isActive(navItem)} />
				{isSmallDesktopScreen && <span className={css(styles.text)}>{t(`common:navigation.${navItem}`)}</span>}
			</NavLink>
		</Block>
	);
};

export default NavigationButton;
