import { useStyletron } from 'baseui';

const CheckboxPartialActive = ({ fillColor, size }: { fillColor?: string; size?: number }) => {
	const [, theme]: any = useStyletron();

	return (
		<svg
			height='25'
			viewBox='14 3 1 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			style={{ width: '1.5625rem' }}
		>
			<rect
				x='7'
				y='7'
				height='18'
				rx='6'
				fill={theme.colors.contentStateDisabled}
				style={{ width: '1.125rem' }}
			/>
			<path
				d='M13 15.7475L15.4235 19L19 13'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				transform='translate(0,0)'
			/>
		</svg>
	);
};

export default CheckboxPartialActive;
