import React, { FC } from 'react';
import { useStyletron } from 'baseui';
import { Modal as BaseModal, ModalBody, ModalHeader } from 'baseui/modal';

import Loader from 'components/UI/Loader/Loader';

import { IObjProps } from 'shared/consts/types';

export interface IModalProps {
	/**
	 * closeable represents the modal body closeable
	 */
	closeable?: boolean;
	/**
	 * children represents the modal body children
	 */
	children: any;
	/**
	 * title of the modal
	 */
	heading: string | React.ReactNode;
	/**
	 * height of the modal
	 */
	height?: string;
	/**
	 * whether to show the loader or not
	 */
	isLoading?: boolean;
	/**
	 * represents the state of the modal (open or close)
	 */
	isOpen: boolean;
	/**
	 * subheading or subtitle of the modal and it is under heading inside the modal header
	 */
	modalSubHeading?: any;
	/**
	 * width of the modal
	 */
	width?: string;

	/**
	 * overflow of the modal needed
	 */
	noOverflow?: boolean;

	overrides?: IObjProps;

	/**
	 * function on closing of the modal
	 */
	onClose: () => any;
}

const modalBodyCss = ($theme: any, noOverflow: boolean, modalBodyCss?: IObjProps) => ({
	color: $theme.modalTextColor,
	maxHeight: 'max-content',
	overflow: noOverflow ? 'hidden' : 'auto',
	...modalBodyCss,
});

const modalHeaderCss = (
	$theme: any,
	modalSubHeading: FC<React.PropsWithChildren<any>> | undefined,
	overrides?: IObjProps
) => ({
	color: $theme.modalTextColor,
	fontSize: '1.25rem',
	fontWeight: '700',
	lineHeight: '1.5rem',
	textAlign: 'center',
	borderBottomWidth: '0.063rem',
	borderBottomStyle: 'solid',
	borderBottomColor: $theme.colors.accent100,
	paddingBottom: modalSubHeading ? '0.5rem' : '1rem',
	marginTop: '1.5rem !important',
	marginRight: '0rem !important',
	marginBottom: '0rem !important',
	marginLeft: '0rem !important',
	...overrides?.Header,
});

const modalLoaderCss = ($theme: any) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	background: $theme.colors.primaryA,
	width: '100%',
	opacity: '0.3',
	height: '100%',
	borderRadius: '1rem',
});

const modalOverrideCss = ({
	height,
	width,
	theme,
	overrides,
}: {
	height?: string;
	width?: string;
	theme: any;
	overrides: any;
}) => ({
	Root: {
		style: {
			zIndex: theme.zIndex300,
			...overrides?.Root,
		},
		props: overrides?.RootProps || {},
	},
	Close: {
		style: {
			borderTopRightRadius: '50%',
			borderTopLeftRadius: '50%',
			borderBottomLeftRadius: '50%',
			borderBottomRightRadius: '50%',
			height: '3rem',
			width: '3rem',
			background: `${theme.modalCloseBgColor} !important`,
			stroke: `${theme.modalCloseStrokeColor} !important`,
			color: `${theme.modalCloseStrokeColor} !important`,
			...overrides?.Close,
		},
	},
	Dialog: {
		style: {
			backgroundColor: theme.modalPrimaryBackground,
			borderTopRightRadius: '1rem',
			borderTopLeftRadius: '1rem',
			borderBottomLeftRadius: '1rem',
			borderBottomRightRadius: '1rem',
			paddingRight: '0rem',
			paddingLeft: '0rem',
			width,
			height,
			...overrides?.Dialog,
		},
	},
	Backdrop: {
		style: {
			...(overrides?.Backdrop || {}),
		},
	},
});

const modalSubHeadingCss = ($theme: any, overrides: any) => ({
	lineHeight: '1.4rem',
	fontSize: '1rem',
	fontWeight: '400',
	color: $theme.colors.accent50,
	paddingTop: '0.5rem',
	...overrides?.SubHeading,
});

function ENGTModalWithoutFooter(props: IModalProps) {
	const {
		children,
		closeable = true,
		heading,
		height,
		isOpen,
		isLoading,
		width,
		modalSubHeading,
		noOverflow,
		overrides,
		onClose,
	} = props;
	const [css, theme]: [any, any] = useStyletron();

	const ModalLoader = () =>
		isLoading ? (
			<div className={css(modalLoaderCss(theme))}>
				<Loader loaderBlockHeight='100%' />
			</div>
		) : (
			<></>
		);

	return (
		<>
			<BaseModal
				onClose={onClose}
				isOpen={isOpen}
				overrides={modalOverrideCss({ height, width, theme, overrides })}
				autoFocus={false}
				closeable={closeable}
			>
				<ModalLoader />
				<ModalHeader className={css(modalHeaderCss(theme, modalSubHeading, overrides))}>
					{heading}
					{modalSubHeading && (
						<div className={css(modalSubHeadingCss(theme, overrides))}>{modalSubHeading}</div>
					)}
				</ModalHeader>
				<ModalBody className={css(modalBodyCss(theme, noOverflow || false, overrides?.Body))}>
					{children}
				</ModalBody>
			</BaseModal>
		</>
	);
}

ENGTModalWithoutFooter.defaultProps = {
	isLoading: false,
	isOpen: false,
	width: '40rem',
};

export default ENGTModalWithoutFooter;
