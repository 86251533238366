import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { KIND, SIZE } from 'baseui/button';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { Paragraph2 } from 'baseui/typography';
import { useTranslation } from 'react-i18next';

import Button from 'components/UI/ENGTButton/ENGTButton';

import { WINDOWS_SCROLL_BAR_CSS } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { isWindows } from 'shared/helpers';

export interface IFilterDropdownProps {
	bodyCss?: IObjProps;
	children: any;
	overrides?: any;
	className?: string;
	headingLabel?: string;
	ignoreBoundary?: boolean;
	isOpen?: boolean;
	openOnClick?: boolean;
	popoverMargin?: number;
	placement?: keyof PLACEMENT;
	rootButton: JSX.Element;
	showArrow?: boolean;
	showFooter?: boolean;
	dismissOnClickOutside?: boolean;
	dismissOnEsc?: boolean;
	rootButtonCss?: IObjProps;
	isBlockFilter?: boolean;
	isUsageDetailsPage?: boolean;
	isApplyDisabled?: boolean;

	onApply?: (...args: any) => any;
	onClose?: (...args: any) => any;
	onOpen?: (...args: any) => any;
	onReset?: (...args: any) => any;
}

const headingCss = {
	marginTop: '0',
	marginBottom: '0',
};

const FilterDropdown = ({
	bodyCss = {},
	children,
	className,
	headingLabel,
	openOnClick,
	placement,
	rootButton,
	rootButtonCss = {},
	ignoreBoundary = false,
	isOpen,
	popoverMargin,
	overrides = {},
	showArrow = false,
	showFooter = true,
	dismissOnEsc = true,
	dismissOnClickOutside = true,
	isBlockFilter = false,
	isUsageDetailsPage = false,
	isApplyDisabled = false,
	onOpen,
	onApply,
	onReset,
	...rest
}: IFilterDropdownProps) => {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['components']);

	const bodyWrapperCss = {
		maxHeight: isBlockFilter ? '17.8rem' : '23.435rem',
		overflowX: isBlockFilter ? 'hidden' : 'auto',
		overflowY: 'auto',
		borderBottomWidth: onApply || onReset ? '1px' : '0px',
		borderBottomStyle: 'solid',
		borderBottomColor: `${theme.colors.horizantalDividerColor} !important`,
		'@media screen and (max-height: 650px)': {
			maxHeight: '14rem !important',
		},
		...(isWindows() && WINDOWS_SCROLL_BAR_CSS),
	};

	const footerWrapperCss = {
		textAlign: 'right',
		paddingTop: '1rem',
		paddingBottom: '1rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',
		borderTop: isBlockFilter ? `1px solid ${theme.modalCloseBgColor}` : 'none',
	};

	return (
		<StatefulPopover
			dismissOnEsc={dismissOnEsc}
			dismissOnClickOutside={dismissOnClickOutside}
			showArrow={showArrow}
			popoverMargin={popoverMargin}
			triggerType={openOnClick ? TRIGGER_TYPE.click : TRIGGER_TYPE.hover}
			returnFocus
			autoFocus
			placement={placement || PLACEMENT.bottomLeft}
			onOpen={onOpen}
			ignoreBoundary={ignoreBoundary}
			overrides={{
				Inner: {
					style: () => ({
						borderBottomRightRadius: '0.5rem',
						borderBottomLeftRadius: '0.5rem',
						borderTopRightRadius: '0.5rem',
						borderTopLeftRadius: '0.5rem',
						borderTopColor: theme.colors.horizantalDividerColor,
					}),
				},
				Body: {
					style: ({ $theme }: any) => ({
						borderBottomRightRadius: '0.5rem',
						borderBottomLeftRadius: '0.5rem',
						borderTopRightRadius: '0.5rem',
						borderTopLeftRadius: '0.5rem',
						zIndex: theme.zIndex100,
						borderBottomColor: theme.colors.horizantalDividerColor,
						...bodyCss,
						width: isUsageDetailsPage ? '19.33%' : '',
					}),
				},
			}}
			content={({ close }) => (
				<Block
					width={['80vw', '80vw', '80vw', isUsageDetailsPage ? '100%' : '19.5rem']}
					className={`${css({
						borderBlockStyle: 'solid',
						borderWidth: '0.0625rem',
						borderTopColor: theme.colors.horizantalDividerColor,
						borderLeftColor: theme.colors.horizantalDividerColor,
						borderRightColor: theme.colors.horizantalDividerColor,
						borderBottomColor: theme.colors.horizantalDividerColor,
						borderImageSource:
							'linear-gradient(0deg, rgba(234, 234, 239, 0), rgba(234, 234, 239, 0)), linear-gradient(179.53deg, rgba(177, 177, 177, 0.24) -32.04%, rgba(255, 255, 255, 0) 64.89%)',
						boxShadow: '0px 22px 52px rgba(0, 0, 0, 0.08)',
						borderBottomRightRadius: '0.5rem',
						borderBottomLeftRadius: '0.5rem',
						borderTopRightRadius: isBlockFilter ? '0rem' : '0.5rem',
						borderTopLeftRadius: isBlockFilter ? '0rem' : '0.5rem',
						backgroundColor: theme.colors.inputFillPrimary,
						...overrides.Content,
					})} ${className}`}
					{...rest}
				>
					{headingLabel && (
						<div
							className={css({
								backgroundColor: theme.backgroundSecondaryFaded,
								borderTopLeftRadius: '0.5rem',
								borderTopRightRadius: '0.5rem',
								paddingTop: '1rem',
								paddingLeft: '1rem',
								paddingRight: '1rem',
								paddingBottom: '1rem',
								textAlign: 'center',
							})}
						>
							<Paragraph2 className={css(headingCss)}>{headingLabel}</Paragraph2>
						</div>
					)}
					<div className={css(bodyWrapperCss)}>
						{typeof children === 'function' ? children(close) : children}
					</div>
					{showFooter && (
						<div className={css(footerWrapperCss)}>
							{onReset && !isBlockFilter && (
								<Button
									kind={KIND['secondary']}
									size={SIZE.default}
									label={t('components:filterDropdown.reset')}
									className={css({
										marginRight: '1rem !important',
									})}
									onClick={() => onReset(close)}
								/>
							)}
							{onApply &&
								(isBlockFilter ? (
									<div className={css({ display: 'flex', justifyContent: 'space-between' })}>
										<div
											className={css({
												color: theme.colors.accent,
												fontWeight: 500,
												cursor: 'pointer',
											})}
											onClick={() => {
												onReset?.(close);
												close();
											}}
										>
											{t('components:filterDropdown.reset')}
										</div>
										<div
											className={css({
												color: theme.colors.accent,
												fontWeight: 500,
												cursor: 'pointer',
											})}
											onClick={() => {
												onApply();
												close();
											}}
										>
											{t('common:apply')}
										</div>
									</div>
								) : (
									<Button
										kind={KIND['primary']}
										size={SIZE.default}
										label={t('components:filterDropdown.apply')}
										className={css({ minWidth: '20%' })}
										isDisabled={isApplyDisabled}
										onClick={() => {
											onApply();
											close();
										}}
									/>
								))}
						</div>
					)}
				</Block>
			)}
			{...rest}
		>
			<div className={css({ cursor: 'pointer', ...rootButtonCss })}>{rootButton}</div>
		</StatefulPopover>
	);
};

export default FilterDropdown;
