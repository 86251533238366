import { REACT_APP_API_BASE_URL, REACT_APP_API_GATEWAY_BASE_URL } from 'shared/config/config';
import { API_GATEWAY_URL } from 'shared/consts/consts';
import { serialize, WHATSAPP_PROVIDERS } from 'shared/helpers';

import BaseService from 'services/baseService';

const builderAllFlowsUrl = '/api/v1/getpathList';
const builderFlowDataUrl = '/api/v1/getchatflow';
const flowAttributesUrl = '/api/v1/user/getFlowAttributes';
const pathVariablesUrl = '/api/v1/getPathVariables';
const updateFlowUrl = '/api/v1/updateflowlist';
const v2GeneralProxyReqUrl = '/v2/genericProxyRequest/';
const getbuilderFlowNodeCorrdinateDataUrl = '/api/v1/getPathBuilderLayout/path';
const saveBuilderFlowNodeCorrdinateDataUrl = '/api/v1/savePathBuilderLayout/path';
const uploadAttachmentUrl = '/api/v1/upload/bulk-attachments';
const uploadDocumentsUrl = '/api/v1/upload/attachment';
const setObjectValueUrl = '/getObjectAttributeFields';
const applicationDataUrl = '/getApplications';
const generateZapierKeyUrl = '/addNewApplication';
const uploadOptionsUrl = '/v2/uploadOptions';

const builderAllFlowsApi = new BaseService(builderAllFlowsUrl);
const builderFlowDataApi = new BaseService(builderFlowDataUrl);
const flowAttributesApi = new BaseService(flowAttributesUrl);
const pathVariablesApi = new BaseService(pathVariablesUrl);
const updateFlowUrlApi = new BaseService(updateFlowUrl);
const v2GeneralProxyReqApi = new BaseService(v2GeneralProxyReqUrl);
const apiGateWayRequest = new BaseService(API_GATEWAY_URL);
const generalProxyReqUrl = '/genericProxyRequest/';
const generalProxyReqApi = new BaseService(generalProxyReqUrl);
const applicationDataApi = new BaseService(applicationDataUrl);
const generateZapierKeyApi = new BaseService(generateZapierKeyUrl);
const getNodeSearchResultApi = new BaseService(REACT_APP_API_GATEWAY_BASE_URL);
const uploadDocumentsApi = new BaseService(uploadDocumentsUrl, {
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
	},
});
const setObjectValueApi = new BaseService(setObjectValueUrl);
const uploadOptionsApi = new BaseService(uploadOptionsUrl);

export const API = {
	nodeSearch(query, botRef) {
		const params = {
			targetApi: 'getNodeSearchResult',
			searchTerm: query,
			botRef,
		};

		return getNodeSearchResultApi.getRequest(serialize(params, '?'));
	},
	addFlow(data) {
		// return addFlowUrlApi.postRequest(data);
	},
	getFlowNodeCoordinateData(pathKey) {
		const getBuilderFlowNodeCoordinateDataApi = new BaseService(getbuilderFlowNodeCorrdinateDataUrl);
		getBuilderFlowNodeCoordinateDataApi.url = `${getBuilderFlowNodeCoordinateDataApi.url}/${pathKey}`;

		return getBuilderFlowNodeCoordinateDataApi.getRequest();
	},
	saveFlowNodeCoordinateData(pathKey, data) {
		const saveBuilderFlowNodeCorrdinateDataApi = new BaseService(saveBuilderFlowNodeCorrdinateDataUrl);
		saveBuilderFlowNodeCorrdinateDataApi.url = `${saveBuilderFlowNodeCorrdinateDataApi.url}/${pathKey}`;

		return saveBuilderFlowNodeCorrdinateDataApi.postRequest(data);
	},
	addEditNode(data, isEdit, url) {
		const addEditNodeUrl = url;
		if (isEdit) {
			return new BaseService(`${addEditNodeUrl}/`).postRequest(data);
		}

		return new BaseService(addEditNodeUrl).putRequest(data);
	},
	addNode(data, url) {
		const addEditNodeUrl = url;

		return new BaseService(addEditNodeUrl).putRequest(data);
	},
	editNodeName(data, pathKey, nodeKey) {
		const editNodeUrl = `/api/v1/path/${pathKey}/node/${nodeKey}/`;

		return new BaseService(editNodeUrl).postRequest(data);
	},
	copyFlow(data, pathKey, newPathName) {
		const copyPathUrl = `/api/v1/path/copy?source_path_key=${pathKey}&target_path_name=${newPathName}`;
		const copyPathUrlApi = new BaseService(copyPathUrl);

		return copyPathUrlApi.postRequest(data);
	},
	getAllFlows() {
		return builderAllFlowsApi.getRequest();
	},
	getFlowAttributes(param = {}) {
		const params = { is_settable: false, ...param };

		return flowAttributesApi.getRequest(serialize(params, '?'));
	},
	getAllAttributes() {
		const params = {
			targetApi: 'getAllAttributes',
			is_settable: false,
			is_indexed: false,
			userType: 'NA',
		};
		return v2GeneralProxyReqApi.getRequest(serialize(params, '?'));
	},
	getFlowData(pathKey) {
		const params = {
			query: pathKey,
		};

		return builderFlowDataApi.getRequest(serialize(params, '?'));
	},
	getPathAttributes(pathKey) {
		const params = {
			flowKey: pathKey,
			targetApi: 'getPathAttributes',
			customerId: '{{customerId}}',
			userId: '{{userId}}',
			botRef: '{{botRef}}',
		};

		return v2GeneralProxyReqApi.getRequest(serialize(params, '?'));
	},
	getPathVariables(pathKey) {
		const params = {
			path_key: pathKey,
		};

		return pathVariablesApi.getRequest(serialize(params, '?'));
	},
	markUnmarkPath(data) {
		const params = {
			available_for_livechat: data.available_for_livechat,
			flow_key: data.flow_key,
			targetApi: 'markPathForAgent',
		};

		return v2GeneralProxyReqApi.putRequest(params, serialize(params, '?'));
	},
	postImage(data) {
		const formData = new FormData();
		formData.append('file', data);

		return new BaseService(`${REACT_APP_API_BASE_URL}/upload`).postRequest(formData);
	},
	postAttchment(data, currentWhatsAppProvider = null) {
		const formData = new FormData();
		formData.append('file', data);
		formData.append('workflow', 'BROADCAST_TEMPLATE_ATTACHMENT');
		if (
			currentWhatsAppProvider === WHATSAPP_PROVIDERS.kaleyra ||
			currentWhatsAppProvider === WHATSAPP_PROVIDERS.dialog360
		) {
			formData.append('trim_whitespace', true);
		}

		return new BaseService(`${REACT_APP_API_BASE_URL}/api/v1/upload/attachment`).postRequest(formData);
	},
	updateFlow(data) {
		return updateFlowUrlApi.postRequest(data);
	},

	getAllData(data) {
		const params = {
			target_url: data,
		};

		return generalProxyReqApi.getRequest(serialize(params, '?'));
	},
	postScript(data) {
		return new BaseService(`${REACT_APP_API_BASE_URL}/api/execute`).postRequest(data);
	},

	postCurl(data) {
		return new BaseService(`${REACT_APP_API_BASE_URL}/api/execute/curl-to-json`).postRequest(data);
	},

	postHttpRequest(data) {
		return new BaseService('/advancedJsonApi/execute/', {
			headers: {
				'Content-Type': 'application/json',
			},
		}).postRequest(data);
	},
	addCategory(data) {
		return new BaseService('/v2/genericProxyRequest/?targetApi=addCategory').postRequest(data);
	},
	getCategories() {
		const params = {
			page: 0,
			size: 200,
			sort: 'updatedOn,desc',
			targetApi: 'getCategory',
		};

		return v2GeneralProxyReqApi.getRequest(serialize(params, '?'));
	},
	getAllTypes() {
		const params = {
			targetApi: 'getAllDatatypes',
		};

		return v2GeneralProxyReqApi.getRequest(serialize(params, '?'));
	},
	deleteNode(pathKey, nodeKey) {
		const url = `/api/v1/path/${pathKey}/node/${nodeKey}/`;
		const api = new BaseService(url);

		return api.deleteRequest();
	},
	deleteNodeConnections(pathKey, nodeKey) {
		const url = `/api/v1/path/${pathKey}/delete_node_connections/${nodeKey}/`;
		const api = new BaseService(url);

		return api.getRequest();
	},
	deleteNodeLayout(pathKey, nodeKey) {
		const url = `/api/v1/deletePathLayoutNode/path/${pathKey}/node/${nodeKey}`;
		const api = new BaseService(url);

		return api.deleteRequest();
	},
	changeRootNode(newRootNodeKey, pathKey) {
		const url = `/api/v1/path/${pathKey}/root/${newRootNodeKey}/`;
		const api = new BaseService(url);

		return api.getRequest();
	},
	deleteConnection(nodeKey, pathKey) {
		const params = {
			nodeKey,
			nextNodeKey: '',
		};

		return new BaseService(`/api/v1/path/${pathKey}/connection/delete/`).getRequest(serialize(params, '?'));
	},
	setConnection(nodeKey, nextNodeKey, pathKey) {
		const params = {
			nodeKey,
			nextNodeKey,
		};

		return new BaseService(`/api/v1/path/${pathKey}/connection/create/`).getRequest(serialize(params, '?'));
	},
	uploadAttachment(data, workflow, headers) {
		const params = {
			application: 'engati-portal',
			workflow: 'EMAIL_ATTACHMENT',
		};
		const formData = new FormData();
		data.map((file, index) => {
			formData.append(`files[${index}]`, file);
		});

		return new BaseService(uploadAttachmentUrl, { headers }).postRequest(formData, serialize(params, '?'));
	},
	getBotMailTemplate(templateId) {
		const params = {
			templateId,
			targetApi: 'getBotMailTemplate',
		};

		return v2GeneralProxyReqApi.getRequest(serialize(params, '?'));
	},
	saveOrUpdateBotMailTemplate(data, headers) {
		const saveOrUpdateBotMailTemplateApi = new BaseService(
			`${v2GeneralProxyReqUrl}?targetApi=saveOrUpdateBotMailTemplate`,
			{ headers }
		);

		return saveOrUpdateBotMailTemplateApi.putRequest(data);
	},
	uploadDocument(file) {
		const data = new FormData();
		data.append('file', file);
		data.append('workflow', 'SEND_DOCUMENT_ATTACHMENT');

		return uploadDocumentsApi.postRequest(data).then(
			(response) => response,
			() => data
		);
	},
	getObjectAttributeFields(params) {
		return setObjectValueApi.getRequest(serialize(params, '?'));
	},
	getApplicationData() {
		return applicationDataApi.getRequest();
	},
	getZapierKey() {
		return generateZapierKeyApi.postRequest({
			appName: 'default',
		});
	},
	uploadOptions(file) {
		const formData = new FormData();
		formData.append('file', file);

		return uploadOptionsApi.postRequest(formData);
	},
	getFileOptions(pagination, uploadId) {
		const paramData = {
			params: { page: pagination.page, size: pagination.size, upload_batch_id: uploadId },
			target_url: 'getUploadedOptions',
		};

		return generalProxyReqApi.getRequest(serialize(paramData, '?'));
	},
	deleteUploadOptionsById(ids) {
		const paramData = {
			params: { ids },
			target_url: 'deleteOptionsByIds',
		};

		return generalProxyReqApi.deleteRequest(serialize(paramData, '?'));
	},
	updateUploadOptions(updatedIds, updatedOptions, batchId) {
		const data = {
			ids: updatedIds,
			target_url: 'updateUploadedOptions',
			updated_options: updatedOptions,
			upload_batch_id: batchId,
		};

		return generalProxyReqApi.postRequest(data);
	},
	copyPathToDifferentBot(data, pathkey) {
		const apiData = {
			target_cid: data.target_cid,
			target_uid: data.target_uid,
			target_bot_ref: data.target_botRef,
			source_path_key: pathkey,
			target_path_name: data.pathName,
		};
		const copyPathUrl = '/copy/path';
		const copyPathUrlApi = new BaseService(copyPathUrl);

		return copyPathUrlApi.postRequest(apiData);
	},
	compareAndValidateSchema(base_attribute_name, new_attribute_id) {
		const apiData = {
			baseAttribute: base_attribute_name,
			newAttribute: new_attribute_id,
		};
		const params = {
			targetApi: 'compareAndValidateSchema',
		};
		const compareAndValidateSchemaApi = new BaseService(v2GeneralProxyReqUrl);

		return compareAndValidateSchemaApi.postRequest(apiData, serialize(params, '?'));
	},
	uploadPythonScript(data) {
		const params = {
			targetApi: 'uploadPythonScriptToLambda',
		};

		return v2GeneralProxyReqApi.postRequest(data, serialize(params, '?'));
	},
	invokeFunction(data) {
		const params = {
			targetApi: 'invokeFunction',
		};

		return v2GeneralProxyReqApi.postRequest(data, serialize(params, '?'));
	},
	jsonApiNodeTest(data) {
		const params = {
			targetApi: 'jsonApiNodeTest',
		};

		return apiGateWayRequest.postRequest(data, serialize(params, '?'));
	},
	getWaFlows() {
		const params = {
			targetApi: 'getWaFlows',
		};

		return v2GeneralProxyReqApi.getRequest(serialize(params, '?'));
	},
	getWaFlowAssets(flowId) {
		const params = {
			targetApi: 'getWaFlowAssets',
			flowId,
		};

		return v2GeneralProxyReqApi.getRequest(serialize(params, '?'));
	},
};
