import { REACT_APP_API_BASE_URL } from 'shared/config/config';
import { ENGATI_PLATFORM_CONNECT } from 'shared/consts/consts';
import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const accountsDataUrl = '/api/v1/getCustomersForLoggedInAccount';
const accountsDataApi = new BaseService(accountsDataUrl);

const createNewTemplateUrl = `${REACT_APP_API_BASE_URL}/genericProxyRequest/?target_url=createNewTemplate`;
const createNewTemplateApi = new BaseService(createNewTemplateUrl, {});

const editTemplateUrl = `${REACT_APP_API_BASE_URL}/genericProxyRequest/?target_url=updateExistingTemplate`;
const editTemplateApi = new BaseService(editTemplateUrl, {});

const templateUrl = '/v2/genericProxyRequest/';
const templateApi = new BaseService(templateUrl);

export const API = {
	saveAsTemplate(data, botRef) {
		const channels = data.channels.map((channel) => channel.id);
		const tags = data.tags && data.tags.map((tag) => tag.id);
		const params = {
			available_for: data.availableFor[0].id,
			template_offering: data.offering[0].id,
			channels_supported: channels,
			domain: window.location.host,
			image_url: data.thumbnail,
			plan_supported: data.plans[0].id,
			price: 0,
			source_bot_ref: botRef,
			template_tags: tags,
			target_url: 'createNewTemplate',
			template_category_id: data.category[0].id,
			template_description: data.description,
			template_name: data.name,
			template_status: 'NEEDS_REVIEW',
			video_url: data.videoUrl,
			module: ENGATI_PLATFORM_CONNECT,
		};

		if (data.availableFor[0].id === 'SUBSET_OF_ACCOUNT') {
			const customer_list = data.accounts.map((customer_list) => customer_list.id);
			params.customer_list = customer_list;
		}

		return createNewTemplateApi.postRequest(params);
	},
	editExistingTemplate(data, payload, botRef) {
		const channels = data.channels.map((channel) => channel.id);
		const tags = data.tags && data.tags.map((tag) => tag.id);
		const params = {
			template_id: payload.template_id,
			template_name: data.name,
			template_description: data.description,
			channels_supported: channels,
			template_offering: data.offering[0].id,
			plan_supported: data.plans[0].id,
			available_for: data.availableFor[0].id,
			source_bot_ref: botRef,
			demo_url: payload.demo_url,
			image_url: data.thumbnail,
			video_url: data.videoUrl,
			template_tags: tags || [],
			template_category_id: data.category[0].id,
			template_status: 'NEEDS_REVIEW',
			price: payload.price,
			created_by: payload.created_by,
			domain: window.location.host,
			update_template: false,
			target_url: 'updateExistingTemplate',
			module: ENGATI_PLATFORM_CONNECT,
		};

		if (data.availableFor[0].id === 'SUBSET_OF_ACCOUNT') {
			const customer_list = data.accounts.map((customer_list) => customer_list.id);
			params.customer_list = customer_list;
		}

		return editTemplateApi.putRequest(params);
	},
	updateExistingTemplate(data, payload, botRef, upgradeDescription) {
		const channels = data.channels.map((channel) => channel.id);
		const tags = data.tags && data.tags.map((tag) => tag.id);
		const params = {
			template_id: payload.template_id,
			template_name: payload.template_name,
			template_description: data.description,
			upgrade_description: upgradeDescription,
			channels_supported: channels,
			template_offering: data.offering[0].id,
			plan_supported: data.plans[0].id,
			available_for: data.availableFor[0].id,
			source_bot_ref: botRef,
			demo_url: payload.demo_url,
			image_url: data.thumbnail,
			video_url: data.videoUrl,
			template_tags: tags || [],
			template_category_id: data.category[0].id,
			template_status: 'NEEDS_REVIEW',
			price: payload.price,
			created_by: payload.created_by,
			domain: window.location.host,
			update_template: true,
			target_url: 'updateExistingTemplate',
			module: ENGATI_PLATFORM_CONNECT,
		};

		if (data.availableFor[0].id === 'SUBSET_OF_ACCOUNT') {
			const customer_list = data.accounts.map((customer_list) => customer_list.id);
			params.customer_list = customer_list;
		}

		return editTemplateApi.putRequest(params);
	},
	getAccountsData(input, include_parent = false, page = 0, size = 10) {
		const data = {
			account_name_or_email: input,
			include_parent_customer: include_parent,
			active_user_filter: true,
		};

		const params = {
			params: {
				page,
				size,
			},
		};

		return accountsDataApi.postRequest(data, serialize(params, '?'));
	},
	validatingTemplate(data) {
		const params = {
			targetApi: 'validateTemplateName',
			replaceable_params: {
				templateName: data,
			},
		};

		return templateApi.getRequest(serialize(params, '?'));
	},
	getTemplatesForBot(botRef) {
		const params = {
			targetApi: 'getTemplatesForBot',
			replaceable_params: {
				bot_ref: botRef,
			},
		};

		return templateApi.getRequest(serialize(params, '?'));
	},
	upgradeBotTemplate(data) {
		const params = {
			targetApi: 'upgradeBotTemplate',
			customerId: '{{customerId}}',
			userId: '{{userId}}',
		};

		return templateApi.postRequest(data, serialize(params, '?'));
	},
};
