import axios from 'axios';

import { REACT_APP_API_BASE_URL, REACT_APP_BASE_URL } from 'shared/config/config';
import { REFRESH_TOKEN_INTERVAL } from 'shared/consts/consts';
import { clearLsOnLogout, debounce } from 'shared/helpers';

// sets the function to be invoked when timer hits
let tokenExpiryHandlerDebounce: any;

// pushes an alert to other tabs open in the browser to notify that the timer has to be reset as other tab  is serving activelty
const tokenExpirySetterDebounce = debounce((callBack: () => void) => {
	callBack();
}, 1500);

const onTokenExpiry = () => {
	axios.get('/logout?isPortalV2=true').then((response) => {});
	clearLsOnLogout();
	tokenExpiryHandlerDebounce && tokenExpiryHandlerDebounce(() => {});
	window.location.replace(`${REACT_APP_BASE_URL}/admin/login`);
};

const notifyTabsToUpdateExpiryTime = () => {
	localStorage.setItem('t_exp', `${Math.random() * 100000}`);
};

window.addEventListener('storage', (evt: StorageEvent) => {
	if (evt?.key === 'csrfToken' && evt.oldValue && !evt?.newValue) {
		onTokenExpiry();
	}
	if (evt?.key === 't_exp' && evt?.newValue !== evt?.oldValue) {
		tokenExpiryHandlerDebounce && tokenExpiryHandlerDebounce(onTokenExpiry);
	}
});

let timeoutId: any;

export default class apiConfig {
	public baseApiUrl: string;

	constructor() {
		this.baseApiUrl = REACT_APP_API_BASE_URL || '';
	}

	public initAxiosInterceptors() {
		axios.defaults.withCredentials = true;

		if (axios.interceptors) {
			axios.interceptors.request.use(this.onRequestSuccess);
			axios.interceptors.response.use(this.onResponseSuccess, this.onResponseError);
		}
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		if (REFRESH_TOKEN_INTERVAL.timer) {
			timeoutId = setTimeout(
				() => {
					axios.get('/logout?isPortalV2=true').then((response) => {});
					clearLsOnLogout();
					window.location.replace(`${REACT_APP_BASE_URL}/admin/login`);
				},
				(REFRESH_TOKEN_INTERVAL.timer + 15) * 1000
			);
		}

		axios.defaults.baseURL = REACT_APP_API_BASE_URL;
	}

	public onRequestSuccess(config: any) {
		return config;
	}

	public onResponseSuccess(response: any) {
		if (response?.config?.headers?.['X-CSRFToken']) {
			tokenExpirySetterDebounce(notifyTabsToUpdateExpiryTime);

			if (REFRESH_TOKEN_INTERVAL.timer && !tokenExpiryHandlerDebounce) {
				tokenExpiryHandlerDebounce = debounce(
					(callBack: () => void) => {
						callBack();
					},
					(REFRESH_TOKEN_INTERVAL.timer - 60) * 1000
				);
			}

			tokenExpiryHandlerDebounce && tokenExpiryHandlerDebounce(onTokenExpiry);
		}
		if (
			typeof response?.data === 'string' &&
			response?.data?.startsWith('<!DOCTYPE html>') &&
			(response?.data?.includes('portal/login') || response?.data?.includes('portal/v2/login'))
		) {
			console.log('on api success | login-redirect: ', response);
			window.location.replace(`${REACT_APP_BASE_URL}/admin/login`);
		}

		return response;
	}

	public onResponseError(err: any) {
		console.log('on api response error', err, err.response?.request, err.response?.request?.responseURL);
		if (err.response?.request?.responseURL) {
			const url = err.response?.request?.responseURL;
			if (url.indexOf('portal/login') > -1 || url.indexOf('portal/v2/login') > -1) {
				window.location.replace(`${REACT_APP_BASE_URL}/admin/login`);
			}
		}

		const status = err && err.status ? err.status : err && err.response ? err.response.status : undefined;
		if (!status) {
			return;
		}
		console.log(status);
		if (status === 400) {
			// handle 400 status -> bad request
		} else if (status === 401) {
			if (err?.response?.data?.tokenExpired) {
				onTokenExpiry();

				return;
			}
			// handle 401 status -> Unauthorized
		} else if (status === 403) {
			// handle 403 status -> Forbidden
		} else if (status === 404) {
			// handle 404 status -> Not Found
		} else if (status === 405) {
			// handle 405 status -> Method Not Allowed
		} else if (status === 408) {
			// handle 408 status -> Request Timeout
		} else if (status === 409) {
			// handle 409 status -> Conflict
		} else if (status === 500) {
			// handle 500 status -> Internal Server error
		} else if (status === 501) {
			// handle 501 status -> Not Implemented
		} else if (status === 502) {
			// handle 502 status -> Bad Gateway
		} else if (status === 504) {
			// handle 504 status -> Gateway Timeout
		}

		return err;
	}
}
