import { IObjProps } from 'shared/consts/types';
import { CHANNELS } from 'shared/enum';
import { isValidResponseObject } from 'shared/helpers';

import { RootState } from 'store/rootReducer';

import { setPostsListAndOverviewData, setPostsLoadingAction } from '../actions';
import { actions } from '../store';

import { postListAndOverviewAPI } from './api';
import { ISelectedPost } from './state';

const {
	setCurrentOneviewScreenAs,
	populatePostListAndOverviewData,
	setSelectedPost,
	populatePostList,
	updateSelectedPost,
	setPostsLoading,
	updateUnreadPostIdCountMap,
	incrementTotalCommentsCountForThePost,
	setViewStitchedUserComment,
	setInstanceBotRefOfThePage,
} = actions;

export const postListAndOverviewActions = {
	changeCurrentOneviewScreenTo: (screen: string) => (dispatch: any) => {
		dispatch(setCurrentOneviewScreenAs(screen));
	},
	setPostsListAndOverviewData: (response: IObjProps) => (dispatch: any) => {
		dispatch(populatePostListAndOverviewData(response));
	},
	setSelectedPostAction: (selectedPost: IObjProps) => (dispatch: any) => {
		dispatch(setSelectedPost(selectedPost));
	},
	updateSelectedPostAction: (selectedPost: IObjProps) => (dispatch: any) => {
		dispatch(updateSelectedPost(selectedPost));
	},
	getPaginatedPostsAction: (pageNo: number, cancelToken: string, errorCb?: any) => (dispatch: any) => {
		dispatch(setPostsLoadingAction(true));
		postListAndOverviewAPI
			.getPaginatedPosts(CHANNELS.INSTAGRAM, pageNo, 10, {
				cancelToken,
			})
			.then((resp: IObjProps) => {
				if (isValidResponseObject(resp)) {
					dispatch(setPostsListAndOverviewData(resp.data.responseObject));
				} else {
					errorCb && errorCb();
				}
			});
	},
	getPaginatedPostsBySearchTermAction:
		(pageNo: number, searchTerm: string, searchPosts: boolean, cancelToken: string, errorCb?: any) =>
		(dispatch: any) => {
			dispatch(setPostsLoadingAction(true));
			postListAndOverviewAPI
				.getPaginatedPostsBySearchTerm(CHANNELS.INSTAGRAM, searchTerm, searchPosts, pageNo, 10, {
					cancelToken,
				})
				.then((resp: IObjProps) => {
					if (isValidResponseObject(resp)) {
						dispatch(setPostsListAndOverviewData(resp.data.responseObject));
					} else {
						errorCb && errorCb();
					}
				});
		},
	updateSelectedPostInPostListAction: (selectedPost: ISelectedPost) => (dispatch: any, getState: () => RootState) => {
		const postListData: Array<IObjProps> = getState().Message.listOfPosts.data;
		const postList = [...postListData];

		postList.map((postData, index) => {
			if (postData.post.postId === selectedPost.postId) {
				const postToUpdate = { ...postList[index] };
				postToUpdate['post'] = { ...selectedPost };
				postList[index] = { ...postToUpdate };
				dispatch(updateSelectedPost(selectedPost));
			}
		});
		postList?.length > 0 && dispatch(populatePostList(postList));
	},
	setPostsLoadingAction: (isLoading: boolean) => (dispatch: any) => {
		dispatch(setPostsLoading(isLoading));
	},
	setPostList: (postList: Array<IObjProps>) => (dispatch: any) => {
		dispatch(populatePostList(postList));
	},
	setUnreadPostIdCountMapAction: (postIds: IObjProps) => (dispatch: any) => {
		dispatch(updateUnreadPostIdCountMap(postIds));
	},
	incrementTotalCommentsCountForThePostAction: (postId: string) => (dispatch: any) => {
		dispatch(incrementTotalCommentsCountForThePost(postId));
	},
	setViewStitchedUserCommentAction: (shouldView: boolean) => (dispatch: any) => {
		dispatch(setViewStitchedUserComment(shouldView));
	},
	setInstanceBotRefOfThePageAction: (instanceBotRef: string) => (dispatch: any) => {
		dispatch(setInstanceBotRefOfThePage(instanceBotRef));
	},
};
