function TickIcon({
	className = '',
	fillColor,
	width = 14,
	height = 14,
	viewX,
	viewY,
}: {
	className?: string;
	fillColor?: string;
	width?: number;
	height?: number;
	viewX?: number;
	viewY?: number;
}) {
	const viewBox = viewX && viewY ? `${viewX} ${viewY} 14 14` : `0 0 14 14`;

	return (
		<svg
			width={width || '14'}
			height={height || '14'}
			viewBox={viewBox}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<path
				strokeWidth='2.33333'
				strokeLinecap='round'
				d='M1.16602 4.85645L3.4934 8.35645L7.66602 1.35645'
				stroke={fillColor || '#AE1536'}
			/>
		</svg>
	);
}

export default TickIcon;
