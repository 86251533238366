import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';

import { IObjProps } from 'shared/consts/types';
import { findEnv } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import AddIcon from 'shared/icons/AddIcon';

import { marketingCampaignsAnalyticsSelector } from 'store/App/User';

const CreateClickToWAAdBtn = (props: { overrides?: IObjProps; label?: string }) => {
	const [css]: [any, any] = useStyletron();
	const { t } = useTranslation(['pages']);
	const { isSmallDesktopScreen } = useResponsiveSize();
	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);
	const { overrides = {}, label = `${t('pages:broadcast.basic.createCtwaAd')}` } = props;

	const ADS_MANAGER_URL = 'https://adsmanager.facebook.com/adsmanager/manage/ads';

	return (
		<ENGTButton
			size={SIZE.compact}
			kind={KIND.primary}
			label={label}
			startEnhancer={<AddIcon />}
			className={css({
				width: '9.5rem',
				marginBottom: `${isSmallDesktopScreen ? '0rem' : marketingCampaignsAnalyticsType && '2rem'} !important`,
				...overrides,
			})}
			onClick={() => window.open(`${ADS_MANAGER_URL}&env=${findEnv()}`, '_blank')}
		/>
	);
};

export default CreateClickToWAAdBtn;
