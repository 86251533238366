import { IApiDataProps, IObjProps } from 'shared/consts/types';

export const botListState: IApiDataProps<IObjProps[]> & ISearchProps = {
	data: [],
	search: {
		list: [],
		query: '',
	},
	error: false,
	loading: true,
};

export type IBotListStateInterface = typeof botListState;

export type ISearchProps = {
	search: {
		list: IObjProps[];
		query: string;
	};
};
