import { CONFIGURE } from 'shared/consts/QAConsts';

function Configure({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			width={size || 40}
			height={size || 40}
			viewBox='0 0 40 40'
			fill='none'
			className={className}
			data-qa={CONFIGURE}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='inherit'>
				<path
					d='M27.7382 17.5454L27.6411 17.5107L27.2619 16.6054L27.303 16.5124C28.452 13.9065 28.3727 13.8288 28.1822 13.6398L26.6495 12.1447C26.5869 12.0825 26.4769 12.0388 26.382 12.0388C26.2958 12.0388 26.0346 12.0388 23.771 13.064L23.6803 13.1048L22.7464 12.7287L22.7089 12.6357C21.6444 10 21.5354 10 21.2705 10H19.1061C18.8422 10 18.722 10 17.7349 12.6403L17.6978 12.7364L16.7687 13.1152L16.6799 13.0782C15.1478 12.4323 14.2532 12.1047 14.02 12.1047C13.9254 12.1047 13.815 12.1472 13.752 12.2092L12.218 13.7086C12.0234 13.9006 11.9426 13.981 13.1518 16.5325L13.1962 16.6271L12.8164 17.5314L12.7234 17.5674C10.0259 18.6098 10.0259 18.7115 10.0259 18.983V21.1039C10.0259 21.3762 10.0259 21.4892 12.7287 22.4578L12.8254 22.492L13.2053 23.3937L13.1645 23.4858C12.0155 26.0929 12.0884 26.1634 12.284 26.358L13.8143 27.8552C13.8784 27.9168 13.9895 27.9609 14.0842 27.9609C14.1698 27.9609 14.4304 27.9609 16.6955 26.9362L16.7862 26.8937L17.7207 27.2717L17.757 27.3652C18.8221 30 18.9316 30 19.1966 30H21.3617C21.6335 30 21.7463 30 22.7341 27.3579L22.7709 27.2618L23.7015 26.8856L23.79 26.9217C25.3209 27.5694 26.215 27.8963 26.4467 27.8963C26.5405 27.8963 26.6519 27.8552 26.7159 27.7918L28.2524 26.2891C28.4457 26.0961 28.5263 26.0173 27.315 23.4682L27.2699 23.3725L27.6491 22.472L27.7397 22.4365C30.4414 21.3886 30.4414 21.2865 30.4414 21.0147V18.895C30.4414 18.6228 30.4414 18.5104 27.7382 17.5454ZM20.2336 23.4398C18.2993 23.4398 16.7256 21.8963 16.7256 20.0004C16.7256 18.1044 18.2993 16.5629 20.2336 16.5629C22.1672 16.5629 23.7405 18.1048 23.7405 20.0004C23.7406 21.8959 22.1672 23.4398 20.2336 23.4398Z'
					fill={fillColor || '#403F42'}
				/>
			</g>
		</svg>
	);
}

export default Configure;
