import { IObjProps } from 'shared/consts/types';

import { IMessageDetailsStateInterface } from './state';

export const messageDetailsReducer = {
	getAllColumns: (state: IMessageDetailsStateInterface, action: { payload: IObjProps }) => {
		state.columns = {
			data: action.payload,
			error: false,
			loading: false,
		};
	},
	getAllColumnsError: (state: IMessageDetailsStateInterface) => {
		state.columns = {
			data: {},
			error: true,
			loading: false,
		};
	},
	showRightPanel: (state: IMessageDetailsStateInterface, action: { payload: boolean }) => {
		state.isRightPanelOpen = action.payload;
	},
	shareRecommendedProducts: (state: IMessageDetailsStateInterface, action: { payload: boolean }) => {
		state.isShareProductsIconClicked = action.payload;
	},
};
