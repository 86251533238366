import { useEffect } from 'react';
import { Location, useLocation } from 'react-router-dom';

function useLocationEffect(callback: (location: Location) => any) {
	const location = useLocation();

	useEffect(() => {
		callback(location);
	}, [location, callback]);
}

export default useLocationEffect;
