import { MessageScreens } from 'shared/enum';

import { actions } from '../store';

const { openMessagesScreen } = actions;

const messagesCommon = {
	openMessagesLeftNav: () => (dispatch: any) => {
		dispatch(openMessagesScreen(MessageScreens.LEFT));
	},
	openMessagesContent: () => (dispatch: any) => {
		dispatch(openMessagesScreen(MessageScreens.CONTENT));
	},
	openMessageDetails: () => (dispatch: any) => {
		dispatch(openMessagesScreen(MessageScreens.RIGHT));
	},
};

export default messagesCommon;
