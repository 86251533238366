import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const botListUrl = '/v2/genericProxyRequest/';
const genericProxyRequest = '/genericProxyRequest/';
const botListApi = new BaseService(botListUrl);
const genericProxyRequestApi = new BaseService(genericProxyRequest);

export const API = {
	fetchBotList(activeModule, customerId, userId, isOnlyMetadataRequired = false) {
		const params = {
			customer_id: customerId || '{{customerId}}',
			user_id: userId || '{{userId}}',
			module_name: activeModule,
			is_only_metadata_required: isOnlyMetadataRequired,
			targetApi: 'getAllActiveBots',
		};

		return botListApi.getRequest(serialize(params, '?'));
	},
	fetchBotDetails() {
		const requestObj = {
			target_url: 'getBotDetails',
			params: {
				page: 0,
				size: 100,
			},
		};

		return genericProxyRequestApi.getRequest(serialize(requestObj, '?'));
	},
};
