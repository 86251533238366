import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { useTranslation } from 'react-i18next';

import ENGTMenuList from 'components/UI/ENGTMenuList/ENGTMenuList';

import { MAX_PAGINATION_NUMBERS } from 'shared/consts/consts';
import { IObjProps, IPaginationState } from 'shared/consts/types';
import LeftArrowIcon from 'shared/icons/LeftArrowIcon';
import RightArrowIcon from 'shared/icons/RightArrowIcon';

interface IENGTPaginationProps extends IPaginationState {
	maxPageNumberInBlock?: number;
	showPageNumbers?: boolean;
	showPageSize?: boolean;
	backgroundColor?: string;
	customSizeOptions?: Array<number>;

	onPageChange: Function;
	onSizeChange: Function;
}

interface IENGTPaginationExtraProps {
	wrapperCss?: IObjProps;
}

const ENGTPagination = forwardRef((props: IENGTPaginationProps & IENGTPaginationExtraProps, ref) => {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['common']);
	const {
		maxPageNumberInBlock = MAX_PAGINATION_NUMBERS,
		totalPages = 1,
		size = 10,
		totalElements,
		showPageNumbers = true,
		showPageSize = true,
		page = 1,
		wrapperCss,
		onPageChange,
		onSizeChange,
		backgroundColor,
		customSizeOptions = [],
	} = props;
	const [isSizePopoverOpen, setSizePopoverVisibility] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(page);

	const sizeOptions = customSizeOptions?.length ? customSizeOptions : [10, 25, 50, 100];
	const firstEntryOfPage = (page - 1) * size + 1;
	const LEFT_ARROW = '\u2190';
	const RIGHT_ARROW = '\u2192';
	let disableRightArrow = false;
	let disableLeftArrow = false;

	type IPageBlocks = Array<number | typeof RIGHT_ARROW | typeof LEFT_ARROW>;

	useEffect(() => {
		setCurrentPage(page);
	}, [page]);

	const pageNumbers: IPageBlocks = useMemo(() => {
		if (totalPages <= maxPageNumberInBlock) {
			const blocks = Array(totalPages)
				.fill(1)
				.map((_, index: number) => index + 1);

			return [...blocks];
		}

		if (currentPage < maxPageNumberInBlock) {
			let blocks: IPageBlocks = [];

			if (currentPage !== page && currentPage > 2) {
				blocks = Array(maxPageNumberInBlock)
					.fill(1)
					.map((_, index: number) => currentPage + index);
				blocks = [1, LEFT_ARROW, ...blocks];
			} else {
				blocks = Array(maxPageNumberInBlock)
					.fill(1)
					.map((_, index: number) => index + 1);
			}

			if (totalPages > maxPageNumberInBlock) {
				blocks.push(RIGHT_ARROW);
				blocks.push(totalPages);
			}

			return [...blocks];
		}

		if (currentPage > totalPages - maxPageNumberInBlock + 1) {
			const blocks: IPageBlocks = [1, LEFT_ARROW];

			Array(maxPageNumberInBlock)
				.fill(1)
				.map((_, index: number) => totalPages - index)
				.reverse()
				.map((element) => blocks.push(element));

			return [...blocks];
		}

		const finalBlock = (): IPageBlocks => {
			let blocks: IPageBlocks = [];
			const pivot = Math.floor((maxPageNumberInBlock - 1) / 2);

			for (let i = pivot; i > 0; i--) {
				blocks.push(currentPage - i);
			}

			blocks.push(currentPage);

			for (let i = 1; i <= pivot; i++) {
				blocks.push(currentPage + i);
			}

			if (currentPage !== page) {
				let lastElement = 0;

				blocks = Array(maxPageNumberInBlock)
					.fill(1)
					.map((_, index: number) => {
						if (index === maxPageNumberInBlock - 1) {
							lastElement = currentPage + index;
						}

						return currentPage + index;
					});

				if (lastElement >= totalPages) {
					blocks = blocks.slice(0, -1);

					return [1, LEFT_ARROW, ...blocks, totalPages];
				}
			}

			return [1, LEFT_ARROW, ...blocks, RIGHT_ARROW, totalPages];
		};

		return finalBlock();
	}, [currentPage, maxPageNumberInBlock, totalPages]);

	if (totalPages === 1) {
		disableRightArrow = true;
		disableLeftArrow = true;
	} else {
		switch (page) {
			case 1:
				disableLeftArrow = true;
				break;
			case totalPages:
				disableRightArrow = true;
				break;
		}
	}

	const handlePrevClick = (id: number) => {
		setCurrentPage((pageNumbers[id + 1] as number) - maxPageNumberInBlock);
	};

	const handleNextClick = (id: number) => {
		setCurrentPage((pageNumbers[id - 1] as number) + 1);
	};

	const textCss: IObjProps = {
		marginLeft: '.625rem',
		marginTop: '.25rem',
		marginRight: '0.4375rem',
		marginBottom: '.1875rem',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '0.75rem',
		lineHeight: '1.0625rem',
		minWidth: '.8125rem',
		color: theme.colors.accent50,
	};

	const resultsTextCss: IObjProps = {
		marginLeft: '1.5rem',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '0.75rem',
		lineHeight: '1rem',
		minWidth: '1.25rem',
		color: theme.colors.accent50,
	};

	const borderRadius = (radius: string) => ({
		borderTopLeftRadius: radius,
		borderTopRightRadius: radius,
		borderBottomLeftRadius: radius,
		borderBottomRightRadius: radius,
	});

	const arrowWrapperCss = (disabled = false): IObjProps => ({
		display: 'flex',
		alignItems: 'center',
		...borderRadius('50%'),
		...(disabled
			? {
					cursor: 'not-allowed',
					opacity: 0.5,
				}
			: {
					cursor: 'pointer',
					':hover': {
						backgroundColor: theme.colors.backgroundSecondary,
					},
				}),
	});

	const arrowSideCss = (disabled = false): IObjProps => ({
		...arrowWrapperCss(disabled),
		marginLeft: '0.25rem',
		paddingTop: '0.625rem',
		paddingBottom: '0.625rem',
		paddingRight: '0.725rem',
		paddingLeft: '0.725rem',
	});

	const arrowDownCss: IObjProps = {
		...arrowWrapperCss(false),
		cursor: 'pointer',
		paddingTop: '0.4375rem',
		paddingBottom: '0.4375rem',
		paddingRight: '0.3125rem',
		paddingLeft: '0.3125rem',
		...(isSizePopoverOpen && { backgroundColor: theme.colors.backgroundSecondary }),
	};

	const listItems = sizeOptions.map((value: number) => ({
		label: value,
		onClick: onSizeChange,
	}));

	const getLastEntryOfPage = () => (totalElements < size * page ? totalElements : size * page);

	const renderPageSizes = () => (
		<div
			className={css({
				display: 'flex',
				alignItems: 'center',
			})}
		>
			<div
				className={css({
					backgroundColor: 'transparent',
					display: 'flex',
					height: '1.5rem',
					width: '3.5625rem',
					border: `0.03125rem solid ${theme.colors.accent400}`,
					borderRadius: '.25rem',
					alignItems: 'center',
					textAlign: 'center',
				})}
			>
				<div
					className={css({
						...textCss,
						color: theme.colors.primaryA,
						fontWeight: 700,
					})}
				>
					{size}
				</div>
				<StatefulPopover
					overrides={{
						Inner: {
							style: {
								backgroundColor: 'transparent',
							},
						},
						Body: {
							style: ({ $theme }: any) => ({
								display: 'inline-block',
								backgroundColor: 'transparent',
								zIndex: $theme.zIndex300,
								boxShadow: 'none',
							}),
						},
					}}
					placement={PLACEMENT.top}
					triggerType={TRIGGER_TYPE.click}
					content={({ close }) => (
						<ENGTMenuList
							Items={listItems.map((item) => ({
								...item,
								onClick: () => {
									item.onClick(item.label);
									close();
								},
							}))}
							width='3rem'
							isOpen
							position='relative'
							marginTop='unset'
							marginBottom='unset'
						/>
					)}
					onOpen={() => setSizePopoverVisibility(true)}
					onClose={() => setSizePopoverVisibility(false)}
				>
					<Block className={css(arrowDownCss)}>
						<svg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M0.5 1.22572L1.56066 0.165061L5.27287 3.87727L8.98511 0.165039L10.0458 1.2257L5.27138 6.00008L4.21072 4.93942L4.21221 4.93793L0.5 1.22572Z'
								fill={theme.colors.primaryA}
							/>
						</svg>
					</Block>
				</StatefulPopover>
			</div>
			{totalElements > 0 ? (
				<div className={css(resultsTextCss)}>
					{t('common:results')}: {firstEntryOfPage} - {getLastEntryOfPage()} {t('common:of')} {totalElements}
				</div>
			) : null}
		</div>
	);

	const rightBlockNavigator = (index: number) => (
		<div
			className={css({
				marginLeft: '0.25rem',
				paddingTop: '0.625rem',
				paddingBottom: '0.625rem',
				paddingRight: '0.725rem',
				paddingLeft: '0.725rem',
				cursor: 'pointer',
				':hover': {
					textDecoration: 'underline',
				},
			})}
			onClick={() => handleNextClick(index)}
		>
			{RIGHT_ARROW}
		</div>
	);

	const leftBlockNavigator = (index: number) => (
		<div
			className={css({
				marginLeft: '0.25rem',
				paddingTop: '0.625rem',
				paddingBottom: '0.625rem',
				paddingRight: '0.725rem',
				paddingLeft: '0.725rem',
				cursor: 'pointer',
				':hover': {
					textDecoration: 'underline',
				},
			})}
			onClick={() => handlePrevClick(index)}
		>
			{LEFT_ARROW}
		</div>
	);

	const renderPageNumbers = () => (
		<div
			className={css({
				display: 'flex',
				alignItems: 'center',
				overflow: 'hidden',
				flexFlow: 'wrap',
			})}
		>
			<div
				onClick={() => !disableLeftArrow && onPageChange(page - 1)}
				className={css(arrowSideCss(disableLeftArrow))}
			>
				<LeftArrowIcon
					width='0.375rem'
					height='0.625rem'
					fillColor={disableLeftArrow ? theme.colors.accent50 : theme.colors.primaryA}
				/>
			</div>
			{pageNumbers.map((pageNo: number | typeof RIGHT_ARROW | typeof LEFT_ARROW, index: number) => {
				if (pageNo === RIGHT_ARROW) {
					return rightBlockNavigator(index);
				}

				if (pageNo === LEFT_ARROW) {
					return leftBlockNavigator(index);
				}

				return (
					<div
						key={`page-no-${index}`}
						className={css({
							marginLeft: '0.25rem',
							cursor: 'pointer',
							fontStyle: 'normal',
							fontWeight: 700,
							fontSize: '0.75rem',
							lineHeight: '1rem',
							textAlign: 'center',
							paddingTop: '0.25rem',
							paddingBottom: '0.1875rem',
							paddingRight: '0.625rem',
							paddingLeft: '0.625rem',
							borderWidth: '0.03125rem',
							borderStyle: 'solid',
							borderColor: pageNo === page ? theme.colors.accent : theme.colors.accent400,
							color: pageNo === page ? theme.colors.accent : theme.colors.primaryA,
							backgroundColor: pageNo === page ? theme.colors.primaryBtnTextColor : 'transparent',
							...borderRadius('0.25rem'),
							':hover': {
								borderColor: theme.colors.accent,
								color: theme.colors.accent,
							},
						})}
						onClick={() => onPageChange(pageNo)}
					>
						{pageNo}
					</div>
				);
			})}

			<div
				onClick={() => !disableRightArrow && onPageChange(page + 1)}
				className={css(arrowSideCss(disableRightArrow))}
			>
				<RightArrowIcon
					width='0.375rem'
					height='0.625rem'
					fillColor={disableRightArrow ? theme.colors.accent50 : theme.colors.primaryA}
				/>
			</div>
		</div>
	);

	return totalPages && page ? (
		<Block
			minHeight='4rem'
			display='flex'
			position={['sticky', 'sticky', 'sticky', 'sticky']}
			justifyContent={['flex-start', 'flex-start', 'flex-start', 'space-between']}
			alignItems='center'
			width={['-webkit-fill-available', '-webkit-fill-available', '-webkit-fill-available', 'unset']}
			className={css({
				backgroundColor: backgroundColor || 'transparent',
				flexFlow: 'wrap',
				bottom: 0,
				overflow: 'hidden',
				...wrapperCss,
			})}
			ref={ref}
		>
			{showPageSize && renderPageSizes()}
			{showPageNumbers && renderPageNumbers()}
		</Block>
	) : (
		<></>
	);
});

export default ENGTPagination;
