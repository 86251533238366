import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Label4 } from 'baseui/typography';
import { useTranslation } from 'react-i18next';

import ENGTImage from 'components/UI/ENGTImage/ENGTImage';

import { PASSWORD_MAXIMUM_CHARACTERS } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import right from 'shared/icons/ic_password_right.svg';
import wrong from 'shared/icons/ic_password_wrong.svg';

function PasswordWidget({ value, wrapperCss }: { value: string; wrapperCss?: IObjProps }) {
	const { t } = useTranslation(['common', 'components', 'errors']);

	const [css, theme] = useStyletron();

	const [passwordRules, setPasswordRules] = useState({
		isValidLength: false,
		hasNumber: false,
		hasLetter: false,
		hasUpperCaseLetter: false,
		specialChar: false,
		maxLength: false,
	});

	useEffect(() => {
		const tempPasswordRules: any = {
			isValidLength: false,
			hasNumber: false,
			hasLetter: false,
			hasUpperCaseLetter: false,
			specialChar: false,
			maxLength: false,
		};

		const password = value;
		if (password) {
			tempPasswordRules.hasNumber = !!password.match(/\d/);
			tempPasswordRules.hasUpperCaseLetter = !!password.match(/[A-Z]/);
			tempPasswordRules.hasLetter = !!password.match(/[a-z]/);
			tempPasswordRules.isValidLength = !!password.match(/^.{8,}$/);
			tempPasswordRules.specialChar = !!password.match(/\W|_/);
			tempPasswordRules.maxLength = password.length <= PASSWORD_MAXIMUM_CHARACTERS;
		} else {
			tempPasswordRules.hasNumber = false;
			tempPasswordRules.hasUpperCaseLetter = false;
			tempPasswordRules.hasLetter = false;
			tempPasswordRules.isValidLength = false;
			tempPasswordRules.specialChar = false;
			tempPasswordRules.maxLength = false;
		}
		setPasswordRules(tempPasswordRules);
	}, [value]);

	const wrapperCssObj: any = {
		borderTopWidth: '1px',
		borderTopStyle: 'solid',
		borderTopColor: theme.colors.accent100,
		borderLeftWidth: '1px',
		borderLeftStyle: 'solid',
		borderLeftColor: theme.colors.accent100,
		borderRightWidth: '1px',
		borderRightStyle: 'solid',
		borderRightColor: theme.colors.accent100,
		borderBottomWidth: '1px',
		borderBottomStyle: 'solid',
		borderBottomColor: theme.colors.accent100,
		borderRadiusBottom: '0.5rem',
		backgroundColor: theme.colors.primaryB,
		marginBottom: '1rem',
		paddingTop: '0.875rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',
		borderBottomRightRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		...wrapperCss,
	};

	const labelCss = {
		marginLeft: '1rem',
		fontSize: '1rem !important',
		lineHeight: '1.25rem !important',
	};

	const labelWrapperCss: any = {
		display: 'flex',
		marginBottom: '1rem',
	};

	const renderChecks = (condition: boolean, label: string) => (
		<div className={css(labelWrapperCss)}>
			{condition ? <ENGTImage lazy src={right} /> : <ENGTImage lazy src={wrong} />}
			<Label4
				className={css({
					color: condition ? theme.colors.primaryA : theme.colors.accent50,
					...labelCss,
				})}
			>
				{label}
			</Label4>
		</div>
	);

	return (
		<div className={css(wrapperCssObj)}>
			{renderChecks(
				passwordRules.isValidLength,
				t('shopify:onboarding.registration.passwordRules.isValidLength')
			)}
			{renderChecks(
				passwordRules.hasUpperCaseLetter,
				t('shopify:onboarding.registration.passwordRules.hasUpperCaseLetter')
			)}
			{renderChecks(passwordRules.hasLetter, t('shopify:onboarding.registration.passwordRules.hasLetter'))}
			{renderChecks(passwordRules.hasNumber, t('shopify:onboarding.registration.passwordRules.hasNumber'))}
			{renderChecks(passwordRules.specialChar, t('shopify:onboarding.registration.passwordRules.specialChar'))}
			{renderChecks(passwordRules.maxLength, t('shopify:onboarding.registration.passwordRules.maxLength'))}
		</div>
	);
}
export default PasswordWidget;
