import { createSlice } from '@reduxjs/toolkit';

import { botListReducers } from './reducers';
import { botListState } from './state';

export const botListSlice = createSlice({
	name: 'BotList',
	initialState: botListState,
	reducers: botListReducers,
});

export const { actions, reducer } = botListSlice;
