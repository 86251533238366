import { useState } from 'react';
import { useStyletron } from 'baseui';
import { KIND } from 'baseui/button';
import { PLACEMENT, Popover } from 'baseui/popover';
import { toaster } from 'baseui/toast';
import { LabelLarge, LabelXSmall } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { ENGATI_ROLES } from 'shared/consts/consts';
import NavigationalHelperBotSetup from 'shared/icons/onboarding/NavigationalHelperBotSetup';
import NavigationalHelperLiveChatActions from 'shared/icons/onboarding/NavigationalHelperLiveChatActions';
import NavigationIndicator from 'shared/icons/onboarding/NavigationIndicator';

import { API } from 'store/App/Bot/api';
import { getBotAdminAction, isShopifyEnabledAcc, isSupervisorSelector } from 'store/App/User';
import { RootState } from 'store/withReducer';

import useIconSizeHook from '../hooks/IconSizeHook';

const NavigationHelperToolTips = () => {
	const [css, theme]: any = useStyletron();
	const dispatch = useDispatch<any>();
	const { iconSize = 30 } = useIconSizeHook();
	const { t } = useTranslation(['pages', 'common']);

	const contentCx = css({
		padding: theme.sizing.scale800,
		width: '88%',
		backgroundColor: theme.colors.inputFillPrimary,
		borderBottomLeftRadius: '1rem',
		borderBottomRightRadius: '1rem',
	});

	const themeColor: string = useSelector((state: RootState) => state.User.botAdmin.data.theme_color);

	const isNewCustomer = useSelector(
		(state: RootState) =>
			state.User.botAdmin.data.welcome_flags.PORTAL_V2_TEAM_NAVIGATION_MIGRATION?.is_new_customer
	);

	const showWelcome = useSelector(
		(state: RootState) => state.User.botAdmin.data.welcome_flags.PORTAL_V2_TEAM_NAVIGATION_MIGRATION?.show_welcome
	);

	const isOwner =
		useSelector((state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_OWNER) ||
		false;

	const isSupervisor = useSelector(isSupervisorSelector);

	const isOnboardingShown = showWelcome && !isNewCustomer && (isOwner || isSupervisor);
	const [showOnboarding, setShowOnboarding] = useState<boolean>(isOnboardingShown);
	const [showOnboardingStep2, setShowOnboardingStep2] = useState<boolean>(false);
	const [showOnboardingStep3, setShowOnboardingStep3] = useState<boolean>(false);
	const [showOnboardingStep4, setShowOnboardingStep4] = useState<boolean>(false);

	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);

	const setFlagToSkip = () => {
		API.changeWelcomeFlag('PORTAL_V2_TEAM_NAVIGATION_MIGRATION')
			.then(() => {
				dispatch(getBotAdminAction());
			})
			.catch(() => {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			});
	};

	const elements = {
		team: document.getElementById('team-icon'),
		users: document.getElementById('users-icon'),
	};

	const defaultPos = {
		team: {
			top: isShopifyEnabled ? '29.125rem' : '31.7125rem',
			left: '25.3125rem',
		},
		users: {
			top: isShopifyEnabled ? '15.125rem' : '17.78875rem',
			left: '25.3125rem',
		},
		conversation: {
			top: isShopifyEnabled ? '3.85rem' : '9.5625rem',
			left: '25.3125rem',
		},
		builder: {
			top: isShopifyEnabled ? '20.65rem' : '23.3125rem',
			left: '25.3125rem',
		},
	};

	const elementsPosition = () => {
		if (!elements.team && !elements.users) {
			return defaultPos;
		}

		const teamBoundary: any = elements.team?.getBoundingClientRect();
		const usersBoundary: any = elements.users?.getBoundingClientRect();

		return {
			team: {
				top: elements.team ? `${teamBoundary.top - iconSize - 10}px` : defaultPos.team.top,
				left: elements.team ? `${teamBoundary.width + teamBoundary.left}px` : defaultPos.team.left,
			},
			users: {
				top: elements.users ? `${usersBoundary.top - iconSize - 10}px` : defaultPos.users.top,
				left: elements.users ? `${usersBoundary.width + usersBoundary.left}px` : defaultPos.users.left,
			},
			conversation: defaultPos.conversation,
			builder: defaultPos.builder,
		};
	};

	return (
		<>
			<Popover
				popoverMargin={10}
				showArrow
				placement={PLACEMENT.leftTop}
				ignoreBoundary
				content={() => (
					<div className={css({ display: 'flex' })}>
						<div
							className={css({
								alignItems: 'flex-start',
								paddingLeft: '0.5rem',
								paddingRight: '1rem',
								paddingTop: '1rem',
							})}
						>
							<NavigationIndicator
								fillColor={themeColor}
								className={css({
									borderTopLeftRadius: '1rem',
									borderTopRightRadius: '1rem',
								})}
							/>
						</div>
						<div
							className={css({
								width: '20rem',
								paddingRight: '2.5rem',
								paddingTop: '1.5rem',
								paddingBottom: '1.5rem',
							})}
						>
							<LabelXSmall color={theme.colors.primaryNavText}>
								{' '}
								{t('pages:primaryNavigation.tooltip.step1.label')}
							</LabelXSmall>
							<LabelLarge
								color={theme.colors.inputPrimary}
								overrides={{
									Block: {
										style: () => ({
											fontWeight: 700,
											fontSize: '1rem',
											marginBottom: '1rem',
										}),
									},
								}}
							>
								{t('pages:primaryNavigation.tooltip.step1.heading')}
							</LabelLarge>
							<ENGTButton
								label={t('common:next')}
								kind={KIND.primary}
								onClick={() => {
									setShowOnboarding(false);
									setShowOnboardingStep2(true);
								}}
							/>
							<ENGTButton
								onClick={() => {
									setShowOnboarding(false);
									setFlagToSkip();
								}}
								label={t('common:skipAll')}
								kind={KIND.minimal}
								className={css({
									fontWeight: '700',
									marginLeft: '0.5rem !important',
								})}
							/>
						</div>
					</div>
				)}
				isOpen={!!elements.team && showOnboarding}
				overrides={{
					Body: {
						style: ({ $theme }) => ({
							borderColor: theme.navigationBorderColor,
							borderWidth: '0.0625rem',
							borderStyle: 'solid',
							borderTopLeftRadius: '1rem',
							borderTopRightRadius: '1rem',
							borderBottomLeftRadius: '1rem',
							borderBottomRightRadius: '1rem',
							left: elementsPosition().team.left,
							top: elementsPosition().team.top,
						}),
					},
					Inner: {
						style: ({ $theme }) => ({
							borderTopLeftRadius: '1rem',
							borderTopRightRadius: '1rem',
							borderBottomLeftRadius: '1rem',
							borderBottomRightRadius: '1rem',
							backgroundColor: theme.colors.inputFillPrimary,
							padding: '0px',
						}),
					},
					Arrow: {
						style: ({ $theme }) => ({
							borderColor: theme.navigationBorderColor,
							borderWidth: '0.0625rem',
							borderStyle: 'solid',
							backgroundColor: theme.colors.inputFillPrimary,
							top: '15%',
							height: '1.5rem',
							width: '1.5rem',
						}),
					},
				}}
			>
				<div
					className={css({
						position: 'absolute',
						top: '0px',
						left: '0px',
					})}
				/>
			</Popover>

			<Popover
				popoverMargin={10}
				showArrow
				placement={PLACEMENT.leftTop}
				ignoreBoundary={!!elements.users}
				content={() => (
					<div className={css({ display: 'flex' })}>
						<div
							className={css({
								alignItems: 'flex-start',
								paddingLeft: '0.5rem',
								paddingRight: '1rem',
								paddingTop: '1rem',
							})}
						>
							<NavigationIndicator
								fillColor={themeColor}
								className={css({
									borderTopLeftRadius: '1rem',
									borderTopRightRadius: '1rem',
								})}
							/>
						</div>
						<div
							className={css({
								width: '20rem',
								paddingRight: '2.5rem',
								paddingTop: '1.5rem',
								paddingBottom: '1.5rem',
							})}
						>
							<LabelXSmall color={theme.colors.primaryNavText}>
								{' '}
								{t('pages:primaryNavigation.tooltip.step2.label')}
							</LabelXSmall>
							<LabelLarge
								color={theme.colors.inputPrimary}
								overrides={{
									Block: {
										style: () => ({
											fontWeight: 700,
											fontSize: '1rem',
											marginBottom: '1rem',
										}),
									},
								}}
							>
								{t('pages:primaryNavigation.tooltip.step2.heading')}
							</LabelLarge>
							<ENGTButton
								label={t('common:next')}
								kind={KIND.primary}
								onClick={() => {
									setShowOnboardingStep2(false);
									setShowOnboardingStep3(true);
								}}
							/>
							<ENGTButton
								onClick={() => {
									setShowOnboardingStep2(false);
									setFlagToSkip();
								}}
								label={t('common:skipAll')}
								kind={KIND.minimal}
								className={css({
									fontWeight: '700',
									marginLeft: '0.5rem !important',
								})}
							/>
						</div>
					</div>
				)}
				isOpen={showOnboardingStep2}
				overrides={{
					Body: {
						style: ({ $theme }) => ({
							borderColor: theme.navigationBorderColor,
							borderWidth: '0.0625rem',
							borderStyle: 'solid',
							borderTopLeftRadius: '1rem',
							borderTopRightRadius: '1rem',
							borderBottomLeftRadius: '1rem',
							borderBottomRightRadius: '1rem',
							left: elementsPosition().users.left,
							top: elementsPosition().users.top,
						}),
					},
					Inner: {
						style: ({ $theme }) => ({
							borderTopLeftRadius: '1rem',
							borderTopRightRadius: '1rem',
							borderBottomLeftRadius: '1rem',
							borderBottomRightRadius: '1rem',
							backgroundColor: theme.colors.inputFillPrimary,
							padding: '0px',
						}),
					},
					Arrow: {
						style: ({ $theme }) => ({
							borderColor: theme.navigationBorderColor,
							borderWidth: '0.0625rem',
							borderStyle: 'solid',
							backgroundColor: theme.colors.inputFillPrimary,
							top: '15%',
							height: '1.5rem',
							width: '1.5rem',
						}),
					},
				}}
			>
				<div
					className={css({
						position: 'absolute',
						top: '0px',
						left: '0px',
					})}
				/>
			</Popover>

			<Popover
				popoverMargin={10}
				showArrow
				placement={PLACEMENT.leftTop}
				ignoreBoundary={false}
				content={() => (
					<div className={css({ display: 'flex', flexDirection: 'column' })}>
						<div className={css({ display: 'flex', flexDirection: 'row' })}>
							<div
								className={css({
									alignItems: 'flex-start',
									paddingLeft: '0.5rem',
									paddingRight: '1rem',
									paddingTop: '1rem',
								})}
							>
								<NavigationIndicator
									fillColor={themeColor}
									className={css({
										borderTopLeftRadius: '1rem',
										borderTopRightRadius: '1rem',
									})}
								/>
							</div>
							<div>
								<NavigationalHelperBotSetup
									fillColor={themeColor}
									className={css({
										borderTopLeftRadius: '1rem',
										borderTopRightRadius: '1rem',
										width: '20rem',
										paddingRight: '2.5rem',
										paddingTop: '1.5rem',
										paddingBottom: '1.5rem',
									})}
								/>
							</div>
						</div>
						<div className={contentCx}>
							<LabelXSmall color={theme.colors.primaryNavText}>
								{t('pages:primaryNavigation.tooltip.step3.label')}
							</LabelXSmall>
							<LabelLarge
								color={theme.colors.inputPrimary}
								overrides={{
									Block: {
										style: () => ({
											fontWeight: 700,
											fontSize: '1rem',
											marginBottom: '1rem',
										}),
									},
								}}
							>
								{t('pages:primaryNavigation.tooltip.step3.heading')}
							</LabelLarge>
							<ENGTButton
								label={t('common:next')}
								kind={KIND.primary}
								onClick={() => {
									setShowOnboardingStep3(false);
									setShowOnboardingStep4(true);
								}}
							/>
							<ENGTButton
								onClick={() => {
									setShowOnboardingStep3(false);
									setFlagToSkip();
								}}
								label={t('common:skipAll')}
								kind={KIND.minimal}
								className={css({
									fontWeight: '700',
									marginLeft: '0.5rem !important',
								})}
							/>
						</div>
					</div>
				)}
				isOpen={showOnboardingStep3}
				overrides={{
					Body: {
						style: ({ $theme }) => ({
							borderColor: theme.navigationBorderColor,
							borderWidth: '0.0625rem',
							borderStyle: 'solid',
							borderTopLeftRadius: '1rem',
							borderTopRightRadius: '1rem',
							borderBottomLeftRadius: '1rem',
							borderBottomRightRadius: '1rem',
							left: elementsPosition().conversation.left,
							top: elementsPosition().conversation.top,
						}),
					},
					Inner: {
						style: ({ $theme }) => ({
							borderTopLeftRadius: '1rem',
							borderTopRightRadius: '1rem',
							borderBottomLeftRadius: '1rem',
							borderBottomRightRadius: '1rem',
							backgroundColor: theme.colors.backgroundPrimary,
							padding: '0px',
						}),
					},
					Arrow: {
						style: ({ $theme }) => ({
							borderColor: theme.navigationBorderColor,
							borderWidth: '0.0625rem',
							borderStyle: 'solid',
							backgroundColor: theme.colors.backgroundPrimary,
							top: '8%',
							height: '1.5rem',
							width: '1.5rem',
						}),
					},
				}}
			>
				<div
					className={css({
						position: 'absolute',
						top: '0px',
						left: '0px',
					})}
				/>
			</Popover>

			<Popover
				popoverMargin={10}
				showArrow
				placement={PLACEMENT.leftTop}
				ignoreBoundary={false}
				content={() => (
					<div className={css({ display: 'flex', flexDirection: 'column' })}>
						<div className={css({ display: 'flex', flexDirection: 'row' })}>
							<div
								className={css({
									alignItems: 'flex-start',
									paddingLeft: '0.5rem',
									paddingRight: '1rem',
									paddingTop: '1rem',
								})}
							>
								<NavigationIndicator
									fillColor={themeColor}
									className={css({
										borderTopLeftRadius: '1rem',
										borderTopRightRadius: '1rem',
									})}
								/>
							</div>

							<div className={css({ display: 'flex' })}>
								<NavigationalHelperLiveChatActions
									fillColor={themeColor}
									className={css({
										borderTopLeftRadius: '1rem',
										borderTopRightRadius: '1rem',
										width: '20rem',
										paddingRight: '2.5rem',
										paddingTop: '1.5rem',
									})}
								/>
							</div>
						</div>

						<div className={contentCx}>
							<LabelXSmall color={theme.colors.primaryNavText}>
								{' '}
								{t('pages:primaryNavigation.tooltip.step4.label')}
							</LabelXSmall>
							<LabelLarge
								color={theme.colors.inputPrimary}
								overrides={{
									Block: {
										style: () => ({
											fontWeight: 700,
											fontSize: '1rem',
											marginBottom: '1rem',
										}),
									},
								}}
							>
								{t('pages:primaryNavigation.tooltip.step4.heading')}
							</LabelLarge>
							<ENGTButton
								label={t('common:getStarted')}
								kind={KIND.primary}
								onClick={() => {
									setShowOnboardingStep4(false);
									setFlagToSkip();
								}}
							/>
						</div>
					</div>
				)}
				isOpen={showOnboardingStep4}
				overrides={{
					Body: {
						style: ({ $theme }) => ({
							borderColor: theme.navigationBorderColor,
							borderWidth: '0.0625rem',
							borderStyle: 'solid',
							borderTopLeftRadius: '1rem',
							borderTopRightRadius: '1rem',
							borderBottomLeftRadius: '1rem',
							borderBottomRightRadius: '1rem',
							left: elementsPosition().builder.left,
							top: elementsPosition().builder.top,
						}),
					},
					Inner: {
						style: ({ $theme }) => ({
							borderTopLeftRadius: '1rem',
							borderTopRightRadius: '1rem',
							borderBottomLeftRadius: '1rem',
							borderBottomRightRadius: '1rem',
							backgroundColor: theme.colors.backgroundPrimary,
							padding: '0px',
						}),
					},
					Arrow: {
						style: ({ $theme }) => ({
							borderColor: theme.navigationBorderColor,
							borderWidth: '0.0625rem',
							borderStyle: 'solid',
							backgroundColor: theme.colors.backgroundPrimary,
							top: '8%',
							height: '1.5rem',
							width: '1.5rem',
						}),
					},
				}}
			>
				<div
					className={css({
						position: 'absolute',
						top: '0px',
						left: '0px',
					})}
				/>
			</Popover>
		</>
	);
};

export default NavigationHelperToolTips;
