import React from 'react';

function CopyIcon({
	className = '',
	fillColor,
	width = 12,
	height = 16,
}: {
	className?: string;
	fillColor?: string;
	width?: number;
	height?: number;
}) {
	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M10.1958 0.761384H4.59414C3.72524 0.761384 3.01845 1.46817 3.01845 2.33708V3.22854H2.14244C1.27353 3.22854 0.566748 3.93533 0.566748 4.80424V13.5294C0.566748 14.3984 1.27353 15.1051 2.14244 15.1051H7.74409C8.61301 15.1051 9.31973 14.3983 9.31973 13.5294V13.4483H10.1957C11.0646 13.4483 11.7714 12.7415 11.7714 11.8726V2.33708C11.7715 1.46817 11.0647 0.761384 10.1958 0.761384ZM8.16789 13.5294C8.16789 13.763 7.97766 13.9532 7.74416 13.9532H2.14244C1.90886 13.9532 1.71864 13.763 1.71864 13.5294V4.80417C1.71864 4.5706 1.90886 4.38038 2.14244 4.38038H7.74409C7.97766 4.38038 8.16783 4.57058 8.16783 4.80417L8.16789 13.5294ZM10.6196 11.8726C10.6196 12.1062 10.4294 12.2964 10.1958 12.2964H9.31979V4.80417C9.31979 3.93527 8.61301 3.22848 7.74416 3.22848H4.17035V2.33701C4.17035 2.10344 4.36057 1.91322 4.59414 1.91322H10.1958C10.4294 1.91322 10.6196 2.10344 10.6196 2.33701V11.8726Z'
				fill={fillColor || '#403F42'}
				stroke={fillColor || '#403F42'}
				strokeWidth='0.2'
			/>
		</svg>
	);
}

export default CopyIcon;
