import axios from 'axios';
import qs from 'qs';

import { IObjProps } from 'shared/consts/types';

import ApiConfig from './api.config';

type ResponseType = 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';

interface IOptionalProps {
	headers?: IObjProps;
	responseType?: ResponseType;
	cancelToken?: IObjProps;
}

export default abstract class BaseService<T> {
	protected headers: IObjProps;

	protected url: string;

	protected responseType: ResponseType;

	private cancelToken: IObjProps;

	private apiConfig: ApiConfig = new ApiConfig();

	protected constructor(url: string, options: IOptionalProps = {}) {
		this.url = url;
		this.headers = options.headers ? options.headers : {};
		this.responseType = options.responseType ? options.responseType : 'text';
		this.apiConfig.initAxiosInterceptors();
		this.cancelToken = options.cancelToken
			? {
					cancelToken: options.cancelToken,
				}
			: {};
	}

	protected setCommonHeaders() {
		const csrfToken: string = localStorage.getItem('csrfToken') || '';

		axios.defaults.headers.common = {
			'Cache-Control': 'no-store',
			'Content-Type': 'application/json',
			'X-CSRFToken': csrfToken,
			...this.headers,
		};
		axios.defaults.responseType = this.responseType || 'text';
	}

	public async getRequest(id?: string): Promise<any> {
		this.setCommonHeaders();

		return new Promise<any>((resolve, reject) => {
			axios
				.get(this.url + (id || ''), {
					...this.cancelToken,
				})
				.then((resp) => {
					resp ? resolve(resp) : reject(resp);
				});
		});
	}

	public async postRequest(entity: T, id?: string, config?: any): Promise<any> {
		this.setCommonHeaders();

		return new Promise<any>((resolve, reject) => {
			axios
				.post(this.url + (id || ''), entity, {
					...config,
					...this.cancelToken,
				})
				.then((resp) => {
					resp ? resolve(resp) : reject(resp);
				});
		});
	}

	public async patchRequest(entity: T, id?: string): Promise<any> {
		this.setCommonHeaders();

		return new Promise<any>((resolve, reject) => {
			axios
				.patch(this.url + (id || ''), entity, {
					...this.cancelToken,
				})
				.then((resp) => {
					resp ? resolve(resp) : reject(resp);
				});
		});
	}

	public async putRequest(entity: T, id?: string): Promise<any> {
		this.setCommonHeaders();

		return new Promise<any>((resolve, reject) => {
			axios
				.put(this.url + (id || ''), entity, {
					...this.cancelToken,
				})
				.then((resp) => {
					resp ? resolve(resp) : reject(resp);
				});
		});
	}

	public async deleteRequest(id?: string): Promise<any> {
		this.setCommonHeaders();

		return new Promise<any>((resolve, reject) => {
			axios
				.delete(this.url + (id || ''), {
					...this.cancelToken,
				})
				.then((resp) => {
					resp ? resolve(resp) : reject(resp);
				});
		});
	}

	public async getRequestWithParams(params: IObjProps): Promise<any> {
		this.setCommonHeaders();

		return new Promise<any>((resolve, reject) => {
			axios
				.get(this.url, {
					params,
					paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
					...this.cancelToken,
				})
				.then((resp) => {
					resp ? resolve(resp) : reject(resp);
				});
		});
	}

	public async postRequestWithParams(entity: T, params: IObjProps, id?: string, config?: any): Promise<any> {
		this.setCommonHeaders();

		return new Promise<any>((resolve, reject) => {
			axios
				.post(this.url + (id || ''), entity, {
					...config,
					params,
					paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
					...this.cancelToken,
				})
				.then((resp) => {
					resp ? resolve(resp) : reject(resp);
				});
		});
	}

	public async postRequestWithNoBody(id: string): Promise<any> {
		this.setCommonHeaders();

		return new Promise<any>((resolve, reject) => {
			axios.post(this.url + (id || '')).then((resp) => {
				resp ? resolve(resp) : reject(resp);
			});
		});
	}

	public async deleteRequestWithBody(entity: T): Promise<any> {
		this.setCommonHeaders();

		return new Promise<any>((resolve, reject) => {
			axios.delete(this.url, { data: entity }).then((resp) => {
				resp ? resolve(resp) : reject(resp);
			});
		});
	}
}
