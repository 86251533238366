import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useSelector } from 'react-redux';

import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import LabelWithTooltip from 'components/UI/LabelWithToolTip/LabelWithTooltip';

import { IObjProps } from 'shared/consts/types';
import BotIconDefaultLight from 'shared/icons/BotIconDefaultLight.svg';

import { RootState } from 'store/rootReducer';

interface INavBotInfo {
	isMobileScreen?: boolean;
	isBotNameVisible?: boolean;
	overrides?: IObjProps;
}

function NavBotInfo(props: INavBotInfo) {
	const { isMobileScreen = false, isBotNameVisible = true, overrides } = props;

	const [css, theme] = useStyletron();

	const [isBotAvatarBroken, setBotAvatarBroken] = useState(false);

	const botIcon: string = useSelector((state: RootState) => state.Bot.data.icon_url);
	const botName: string = useSelector((state: RootState) => state.Bot.data.bot_name || '');

	useEffect(() => {
		setBotAvatarBroken(false);
	}, [botIcon]);

	const botAvatar = isBotAvatarBroken || !botIcon ? BotIconDefaultLight : botIcon;

	return (
		<Block
			display={isMobileScreen ? 'flex' : ['none', 'none', 'none', 'flex']}
			alignItems='center'
			justifyContent='left'
		>
			<ENGTImage
				lazy
				src={botAvatar}
				alt='Bot Avatar'
				className={css({
					width: '2.5rem',
					height: '2.5rem',
					borderTopRightRadius: '8px',
					borderTopLeftRadius: '8px',
					borderBottomRightRadius: '8px',
					borderBottomLeftRadius: '8px',
					marginRight: '1rem',
					...overrides?.botImage,
				})}
				onError={() => setBotAvatarBroken(true)}
			/>
			{isBotNameVisible && (
				<Block display='flex' alignItems='start' justifyContent='center' flexDirection='column'>
					<LabelWithTooltip
						fontSize='1rem'
						fontWeight='500'
						lineHeight='1.25rem'
						color={theme.colors.primaryA}
						characterLimit={25}
					>
						{botName}
					</LabelWithTooltip>
				</Block>
			)}
		</Block>
	);
}

export default NavBotInfo;
