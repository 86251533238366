import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { Panel } from 'baseui/accordion';
import { Block } from 'baseui/block';
import { KIND, SIZE } from 'baseui/button';
import { PLACEMENT } from 'baseui/popover';
import { toaster } from 'baseui/toast';
import { MonoLabelXSmall } from 'baseui/typography';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import CustomFilter from 'components/CustomFilter/CustomFilter';
import MessagePageHeader from 'components/shared/MessagePageHeader/MessagePageHeader';
import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';
import Accordion from 'components/UI/ENGTAccordion/ENGTAccordion';
import Button from 'components/UI/ENGTButton/ENGTButton';
import Checkbox from 'components/UI/ENGTCheckBox/ENGTCheckBox';
import DefaultDatePicker from 'components/UI/ENGTDatePicker/DefaultDatePicker';
import ENGTRadio from 'components/UI/ENGTRadio/ENGTRadio';
import ENGTSelect from 'components/UI/ENGTSelect/ENGTSelect';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';

import {
	CHANNEL_TITLE_MAPPING,
	ENGATI_ROLES,
	MESSAGE_TAB_HEADERS_MAP,
	ROLE_SCOPE,
	SENTIMENT_ATTRIBUTE_LABEL,
	WhatsAppQuickFilter,
} from 'shared/consts/consts';
import { IMessageFacets, IObjProps, ISelectValue } from 'shared/consts/types';
import { CHANNELS, SORTING } from 'shared/enum';
import { DEFAULT_LAST_MESSAGE_FILTER, isEmpty, isValidResponseObject } from 'shared/helpers';
import { areEqual, getDate, getFormattedDate } from 'shared/helpers/messagesHelper';
import CloseIconLite from 'shared/icons/CloseIconLite';

import { getConfiguredChannelsAction } from 'store/App/Bot/actions';
import { messageListAPI } from 'store/Message';
import {
	getShortcutQuickFilterFromLs,
	getShortcutQuickFilterLiteFromLs,
	getShortcutQuickFilterLiteFromLsMyConversation,
	removeShortcutQuickFilterFromLs,
	removeShortcutQuickFilterLiteFromLs,
	removeShortcutQuickFilterLiteFromLsMyConversation,
	setSentimentAttributeDataAction,
	setShortcutQuickFilterLiteToLs,
	setShortcutQuickFilterLiteToLsMyConversation,
	setShortcutQuickFilterToLs,
} from 'store/Message/actions';
import { messageListActions } from 'store/Message/messageList/actions';
import { API as pathBuilderApi } from 'store/PathBuilder/api';
import { RootState } from 'store/rootReducer';
import { isRecentMessageFirstSort } from 'store/Users/selectors';

import { i18nHelper } from 'i18nHelper';

import AppliedFilter from './AppliedFilter';
import DeleteSelectedFilter from './DeleteSelectedFilter';
import SaveSelectedFilters from './SaveSelectedFilter';

export enum FILTER_TYPES {
	date = 'date',
	select = 'select',
}
interface IMessageFilterLiteProps {
	onClose: Function;
	onApply: (filter: IMessageFilters) => any;
	initialValue: IMessageFilters;
	isMyConversationFilterApplied: boolean;
	tab?: string;
}

export interface IMessageFilters {
	facets: IMessageFacets;
	sort: string;
}

export const allTabStatusFields = () => [
	{
		label: i18nHelper('components:messageFilter.New'),
		id: 'livechat_state:"ASSIGNED"',
	},
	{
		label: i18nHelper('components:messageFilter.Active with Bot'),
		id: '(is_enable:"true" AND is_live_chat_enable:"false")',
	},
	{
		label: i18nHelper('components:messageFilter.Active with Agent'),
		id: 'livechat_state:"ENGAGING"',
	},
	{
		label: i18nHelper('components:messageFilter.Resolved'),
		id: 'livechat_state:"HANDLED"',
	},
	{
		label: i18nHelper('components:messageFilter.Pending Resolution'),
		id: 'livechat_state:"PENDING_RESOLUTION"',
	},
	{
		label: i18nHelper('components:messageFilter.Unassigned'),
		id: 'livechat_state:"REQUESTED"',
	},
];

export const lastMessageFields = () => [
	{
		label: i18nHelper('components:messageFilter.bot'),
		id: 'BOT',
	},
	{
		label: i18nHelper('components:messageFilter.agent'),
		id: 'AGENT',
	},
	{
		label: i18nHelper('components:messageFilter.broadcast'),
		id: 'BROADCAST',
	},
	{
		label: i18nHelper('components:messageFilter.user'),
		id: 'USER',
	},
];

const MessageFilterLite = ({
	onClose,
	onApply,
	initialValue,
	isMyConversationFilterApplied,
	tab,
}: IMessageFilterLiteProps) => {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['components']);
	const dispatch = useDispatch<any>();
	const { tab: messageTab }: IObjProps = useParams();
	const isAllTab = messageTab && messageTab === MESSAGE_TAB_HEADERS_MAP.all;

	const [height, setHeight] = useState('15rem');
	const [attributes, setAttributes] = React.useState<Array<any>>([]);
	const [availableFilters, setAvailableFilters] = React.useState<any>({});
	const [sortApplied, setSortApplied] = useState<string>('');
	const [channelsSelected, setChannelsSelected] = React.useState<Array<ISelectValue>>([]);
	const [conversationClosingTime, setConversationClosingTime] = React.useState<string>('');
	const [slaFilterSelected, setSlaFilterSelected] = React.useState<Array<ISelectValue>>([]);
	const [managedByFilter, setManagedByFilter] = React.useState<Array<ISelectValue>>([]);
	const [savedCustomFilters, setSavedCustomFilters] = React.useState<Array<ISelectValue>>([]);
	const [agentConversationsFilter, setAgentConversationsFilter] = React.useState<Array<ISelectValue>>([]);
	const [lastMessageFilter, setLastMessageFilter] = React.useState<Array<ISelectValue>>([]);
	const [dateSelected, setDateSelected] = React.useState<any>([null, null]);
	const [statusSelected, setStatusSelected] = useState<Array<ISelectValue>>([]);
	const [intentsSelected, setIntentsSelected] = useState<Array<ISelectValue>>([]);
	const [tagsSelected, setTagsSelected] = useState<Array<ISelectValue>>([]);
	const [sentimentsSelected, setSentimentsSelected] = useState<Array<ISelectValue>>([]);
	const [isCustomFilterOpen, toggleCustomFilter] = React.useState<boolean>(false);
	const [customFilterSelected, setCustomFilterSelected] = React.useState<IObjProps>({ isChecked: false });
	const [tags, setTags] = useState<Array<{ id: string; label: string }>>([]);
	const [allAppliedFilters, setAllAppliedFilters] = useState<Array<ReactElement>>([]);
	const [allAppliedFiltersUpdated, setAllAppliedFiltersUpdated] = useState<Array<ReactElement>>([]);
	const [value, setValue] = useState<string>('');
	const [customFilterGroupSelected, setCustomFilterGroupSelected] = useState<string>('');
	const filterHeaderRef = useRef<any>(null);

	const isSimplifiedOneViewEnabled = useSelector(
		(state: RootState) => state.User.botAdmin.data.is_simplified_one_view_enabled
	);
	const isDefaultLastMessageFilterEnabled = useSelector(
		(state: RootState) => state.User.botAdmin.data.is_default_last_message_filter_enabled
	);
	const isExternalLivechatEnabled = useSelector(
		(state: RootState) => state.Bot.configData.is_external_livechat_enabled
	);
	const isAgent = useSelector(
		(state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT
	);
	const isShopifyEnabled = useSelector(
		(state: RootState) => state.User.botAdmin.data.enable_shopify_shopper_assitance
	);
	const enableSentimentTagging: any = useSelector(
		(state: RootState) => state.User.botAdmin.data.enable_sentiment_tagging
	);
	const enableRecentMessageFirstSort = useSelector(isRecentMessageFirstSort);
	const isAgentAllowedToSaveCustomFilters = useSelector(
		(state: RootState) => state.User.security.data.allow_scope_on_advanced_filters
	);
	const enableIntentTagging: any = useSelector((state: RootState) => state.User.botAdmin.data.enable_intent_tagging);
	const availableAgents = useSelector((state: RootState) => state.Message.allTabAgents.data);
	const configuredChannels = useSelector((state: RootState) => state.Bot.configuredChannels);
	const botLevelTagsList = useSelector((state: RootState) => state.Message.botLevelTagsList || null);
	const sentimentData = useSelector((state: RootState) => state.Message.sentiment);
	const agentChannels = useSelector((state: RootState) => state.Message.agent.channels);
	const tabDetail: any = {
		isAgentUser: isAgent,
		isIntentTaggingEnabled: enableIntentTagging && isShopifyEnabled,
		isSentimentsEnabled: enableSentimentTagging && isShopifyEnabled,
	};
	const { isAgentUser, isIntentTaggingEnabled, isSentimentsEnabled } = tabDetail;
	const { roleScope, isSupervisor } = dispatch(messageListActions.getAgentRoleInformation());

	const isAgentAndCategoryConversationCheck =
		(isAgent || isSupervisor) && roleScope === ROLE_SCOPE.CATEGORY_CONVERSATIONS;

	const agentConfiguredChannels = isAgentAndCategoryConversationCheck ? agentChannels : configuredChannels;

	const FILTERS = {
		'Sort Messages': t('components:messageFilter.filters.sortMessages'),
		Channel: t('components:messageFilter.filters.channel'),
		'SLA non-adherence': t('components:messageFilter.filters.sla-non-adherence'),
		'Agent Conversations': t('components:messageFilter.filters.agentConversation'),
		'Last Message': t('components:messageFilter.filters.lastMessage'),
		'Managed by': t('components:messageFilter.filters.managedBy'),
		'Joined On': t('components:messageFilter.filters.joinedOn'),
		Status: t('components:messageFilter.filters.status'),
		Intents: t('components:messageFilter.filters.intents'),
		Tags: t('components:messageFilter.filters.tags'),
		Sentiments: t('components:messageFilter.filters.sentiments'),
		'Saved filter group': t('components:messageFilter.filters.savedFilterGroup'),
		'Conversation Closing Time': t('components:messageFilter.filters.conversationClosingTime'),
	};

	const description = (
		<span className={css({ maxWidth: '50%', textAlign: 'center' })}>
			<span>{t(`components:customFilters.description`)}</span>
			<NavLink to='/configure/attributes' className={css({ color: theme.colors.primaryA })}>
				{t('components:customFilters.tabs.Attributes')}
			</NavLink>
		</span>
	);

	const attributeIdList = [
		'user.is_banned',
		'bot.after_tomorrow',
		'bot.feedback_on_resolution',
		'bot.today',
		'bot.tomorrow',
		'conversation.due_by',
		'conversation.last_agent',
		'conversation.resolved_on',
		'conversation.topic',
		'conversation.user_feedback',
		'conversation.user_feedback_capture',
		'user.account',
		'user.channel_user_id',
		'user.gorgias.verified',
		'user.gorgias.email',
		'user.gorgias',
		'user.last_query',
		'user.location',
		'user.priority',
		'user.title',
		'user.zendesk.verified',
		'user.zendesk.email',
		'user.zendesk',
		'user_web.utm_term',
		'user_web.utm_campaign',
		'user_web.utm_medium',
		'user_web.page',
		'user_web.url',
		'user_web.utm_content',
		'user_web.utm_source',
		'user_web',
	];

	const footerButtonCss: any = {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingTop: '0.8rem',
		paddingBottom: '0.8rem',
	};

	const slaFilterFields = [
		{
			label: t('components:messageFilter.First Response'),
			id: 'sla_first_response_breach:"true"',
			disabled: tab === MESSAGE_TAB_HEADERS_MAP.active,
		},
		{
			label: t('components:messageFilter.Resolution'),
			id: 'sla_resolution_breach:"true"',
		},
	];

	const conversationClosingTimeOptns = [
		{
			name: t('components:messageFilter.filters.quickFilters.oneHrWALimit'),
			value: '1_hrs_wa_limit',
		},
		{
			name: t('components:messageFilter.filters.quickFilters.threeHrWALimit'),
			value: '3_hrs_wa_limit',
		},
		{
			name: t('components:messageFilter.filters.quickFilters.sixHrWALimit'),
			value: '6_hrs_wa_limit',
		},
	];
	const agentsStatusFields = [
		{
			label: t('components:messageFilter.New'),
			id: 'livechat_state:"ASSIGNED"',
		},
		{
			label: t('components:messageFilter.Active'),
			id: 'livechat_state:"ENGAGING"',
		},
		{
			label: t('components:messageFilter.Pending Resolution'),
			id: 'livechat_state:"PENDING_RESOLUTION"',
		},
		{
			label: t('components:messageFilter.Resolved'),
			id: 'livechat_state:"HANDLED"',
		},
	];

	const activeTabStatusFields = [
		{
			label: t('components:messageFilter.Active'),
			id: 'ENGAGING',
		},
		{
			label: t('components:messageFilter.Pending Resolution'),
			id: 'PENDING_RESOLUTION',
		},
	];

	const INTENTS_FILTER_LIST: Array<any> = [
		{
			id: 'PAYMENT_ENQUIRY',
			label: t('components:addEditIntentModal.paymentEnquiry'),
		},
		{
			id: 'ORDER_ENQUIRY',
			label: t('components:addEditIntentModal.orderEnquiry'),
		},
		{
			id: 'PRICE_ENQUIRY',
			label: t('components:addEditIntentModal.priceEnquiry'),
		},
		{
			id: 'PROMOTIONS_ENQUIRY',
			label: t('components:addEditIntentModal.promotionsEnquiry'),
		},
		{
			id: 'DELIVERY_ENQUIRY',
			label: t('components:addEditIntentModal.deliveryEnquiry'),
		},
		{
			id: 'OUT_OF_STOCK_ENQUIRY',
			label: t('components:addEditIntentModal.outOfStockEnquiry'),
		},
		{
			id: 'CANCEL_AND_REFUND',
			label: t('components:addEditIntentModal.cancelAndRefund'),
		},
		{
			id: 'PURCHASE_ENQUIRY',
			label: t('components:addEditIntentModal.purchaseEnquiry'),
		},
		{
			id: 'RETURN_AND_EXCHANGE',
			label: t('components:addEditIntentModal.returnAndExchange'),
		},
	];

	const SENTIMENTS_FILTER_LIST = [
		{
			id: 'Negative',
			label: t('components:messageFilter.SentimentNegative'),
		},
		{
			id: 'Neutral',
			label: t('components:messageFilter.SentimentNeutral'),
		},
		{
			id: 'Positive',
			label: t('components:messageFilter.SentimentPositive'),
		},
	];

	const isDisableFl = () => {
		const waFlArray = conversationClosingTimeOptns.map((option) => option.value);

		return !waFlArray.includes(conversationClosingTime);
	};

	const sortOptions: any = [
		{
			name: t('components:messagesListSort.recentFirstDefault'),
			value: SORTING.recentFirst,
		},
		{
			name: t('components:messagesListSort.recentUserFirst'),
			value: SORTING.recentUserFirst,
		},
		{
			name: t('components:messagesListSort.newestFirst'),
			value: SORTING.createdAtDesc,
		},
		{
			name: t('components:messagesListSort.oldestFirst'),
			value: SORTING.createdAtAsc,
		},
		{
			name: t('components:messagesListSort.conversationClosingTimeLTH'),
			value: SORTING.conversationClosingTimeLTH,
			isDisabled: isDisableFl(),
		},
		{
			name: t('components:messagesListSort.conversationClosingTimeHTL'),
			value: SORTING.conversationClosingTimeHTL,
			isDisabled: isDisableFl(),
		},
	];

	if (!isSimplifiedOneViewEnabled && tab !== MESSAGE_TAB_HEADERS_MAP.all) {
		sortOptions.splice(2, 0, {
			name: t('components:messagesListSort.unRead'),
			value: SORTING.unreadDesc,
		});
	}
	if (enableSentimentTagging && isShopifyEnabled) {
		sortOptions.push({
			name: t('components:messagesListSort.negativeSentimentFirst'),
			value: `attributes_text_${sentimentData.attributeId},asc`,
		});
	}
	if (!enableRecentMessageFirstSort) {
		sortOptions.splice(0, 1);
	}
	const applyFacetsFromParent = () => {
		if (!isEmpty(initialValue.facets)) {
			const {
				channelsSelected = [],
				slaFilterSelected = [],
				dateSelected = [],
				savedCustomFilters = [],
				managedByFilter = [],
				agentConversationsFilter = [],
				lastMessageFilter = [],
				statusSelected = [],
				intentsSelected = [],
				sentimentsSelected = [],
				customFilterSelected = { isChecked: false },
				customFilterGroupSelected = '',
				tagsSelected = [],
				conversationClosingTime = '',
			} = initialValue.facets;
			channelsSelected && setChannelsSelected(channelsSelected);
			conversationClosingTime && setConversationClosingTime(conversationClosingTime);
			slaFilterSelected && setSlaFilterSelected(slaFilterSelected);
			customFilterGroupSelected && setCustomFilterGroupSelected(customFilterGroupSelected);
			dateSelected && dateSelected[0] && dateSelected[1]
				? setDateSelected([new Date(dateSelected[0]), new Date(dateSelected[1])])
				: setDateSelected([null, null]);
			managedByFilter && setManagedByFilter(managedByFilter);
			savedCustomFilters && setSavedCustomFilters(savedCustomFilters);
			agentConversationsFilter && setAgentConversationsFilter(agentConversationsFilter);
			lastMessageFilter && setLastMessageFilter(lastMessageFilter);
			statusSelected && setStatusSelected(statusSelected);
			intentsSelected && setIntentsSelected(intentsSelected);
			sentimentsSelected && setSentimentsSelected(sentimentsSelected);
			customFilterSelected && setCustomFilterSelected(customFilterSelected);
			tagsSelected && setTagsSelected(tagsSelected);
		}
	};

	const removeQuickFilterIfAny = () => {
		if (isSimplifiedOneViewEnabled) {
			if (isMyConversationFilterApplied) {
				dispatch(removeShortcutQuickFilterLiteFromLsMyConversation());
			} else {
				dispatch(removeShortcutQuickFilterLiteFromLs());
			}
		} else {
			dispatch(removeShortcutQuickFilterFromLs(messageTab));
		}
	};

	const areOtherFiltersEmpty = (filter: any, listToRemove: any) => {
		if (isSimplifiedOneViewEnabled) {
			if (isMyConversationFilterApplied) {
				listToRemove.push('lastMessageFilter');
			}
		} else if (messageTab !== 'all') {
			listToRemove.push('lastMessageFilter');
		}
		const currentFilter = filter;
		listToRemove.forEach((key: any) => delete currentFilter.facets[key]);
		const valuesArray = Object.values(filter.facets);

		let flag = true;
		valuesArray.forEach((data: any) => {
			if (
				data === null ||
				data?.length === 0 ||
				(data?.length === 2 && data[0] === null && data[1] === null) ||
				data === ''
			) {
			} else {
				flag = false;
			}
		});

		return flag;
	};

	const setShortcutQuickFilter = (data: any) => {
		!isSimplifiedOneViewEnabled
			? dispatch(setShortcutQuickFilterToLs(messageTab, data))
			: isMyConversationFilterApplied
				? dispatch(setShortcutQuickFilterLiteToLsMyConversation(data))
				: dispatch(setShortcutQuickFilterLiteToLs(data));
	};

	const checkAndResetSort = () => {
		if (conversationClosingTime === '') {
			if (sortApplied?.includes('windowClosingTime')) {
				return enableRecentMessageFirstSort ? SORTING.recentFirst : SORTING.recentUserFirst;
			}
		} else if (!sortApplied?.includes('windowClosingTime')) {
			return SORTING.conversationClosingTimeLTH;
		}

		return sortApplied;
	};

	const setQuickFilter = (filter: any) => {
		let isFilterApplied = false;

		if (
			channelsSelected?.length > 1 ||
			managedByFilter?.length > 0 ||
			agentConversationsFilter?.length > 0 ||
			lastMessageFilter?.length > 0 ||
			slaFilterSelected?.length > 1 ||
			dateSelected?.[0] ||
			dateSelected?.[1] ||
			statusSelected?.length > 0 ||
			tagsSelected?.length > 0 ||
			sentimentsSelected?.length > 0 ||
			intentsSelected?.length > 0 ||
			areOtherFiltersEmpty(JSON.parse(JSON.stringify(filter)), ['customFilterSelected'])
		) {
			removeQuickFilterIfAny();
		}
		if (
			WhatsAppQuickFilter.includes(conversationClosingTime) &&
			channelsSelected?.length === 1 &&
			channelsSelected?.[0]?.id === CHANNELS.WHATSAPP &&
			areOtherFiltersEmpty(JSON.parse(JSON.stringify(filter)), [
				'channelsSelected',
				'conversationClosingTime',
				'customFilterSelected',
			])
		) {
			setShortcutQuickFilter(conversationClosingTime);
			isFilterApplied = true;
		}
		if (
			slaFilterSelected?.length === 1 &&
			areOtherFiltersEmpty(JSON.parse(JSON.stringify(filter)), ['slaFilterSelected', 'customFilterSelected'])
		) {
			if (isFilterApplied) {
				removeQuickFilterIfAny();
			} else {
				const slaValue = filter.facets.slaFilterSelected?.[0]?.id?.split(':')?.[0];
				setShortcutQuickFilter(slaValue);
				isFilterApplied = true;
			}
		}

		if (filter.facets.customFilterGroupSelected !== '') {
			savedCustomFilters.forEach((data: any) => {
				if (data.filter_name === filter.facets.customFilterGroupSelected) {
					const {
						agent_conversations_user_ids,
						managed_by_agent_ids,
						channels,
						sla_non_adherence_types,
						joined_on_from_date,
						joined_on_to_date,
						conversation_status,
						tags,
						custom_filter,
						last_message_filter,
						sentiments,
						intents,
						window_closing_time,
					} = data;
					let isMainFlMatchesQuickFilter = true;

					Object.keys(data).forEach((key) => {
						if (key === 'agent_conversations_user_ids' && isMainFlMatchesQuickFilter) {
							const idArray = agentConversationsFilter.map((item) => item.id);
							isMainFlMatchesQuickFilter = areEqual(idArray, agent_conversations_user_ids || []);
						}
						if (key === 'managed_by_agent_ids' && isMainFlMatchesQuickFilter) {
							const idArray = managedByFilter.map((item) => item.id);
							isMainFlMatchesQuickFilter = areEqual(idArray, managed_by_agent_ids || []);
						}
						if (key === 'channels' && isMainFlMatchesQuickFilter) {
							const idArray = channelsSelected.map((item) => item.id);
							isMainFlMatchesQuickFilter = areEqual(idArray, channels || []);
						}
						if (key === 'sla_non_adherence_types' && isMainFlMatchesQuickFilter) {
							const idArray = slaFilterSelected.map((item) => item.label);
							isMainFlMatchesQuickFilter = areEqual(idArray, sla_non_adherence_types || []);
						}
						if (
							(key === 'joined_on_from_date' || value === 'joined_on_to_date') &&
							isMainFlMatchesQuickFilter
						) {
							if (
								getFormattedDate(dateSelected[0]) !== getDate(joined_on_from_date) ||
								getFormattedDate(dateSelected[1]) !== getDate(joined_on_to_date)
							) {
								isMainFlMatchesQuickFilter = false;
							}
						}
						if (key === 'conversation_status' && isMainFlMatchesQuickFilter) {
							const idArray = statusSelected.map((item) => item.label);
							isMainFlMatchesQuickFilter = areEqual(idArray, conversation_status || []);
						}
						if (key === 'tags' && isMainFlMatchesQuickFilter) {
							const idArray = tagsSelected.map((item) => item.label);
							isMainFlMatchesQuickFilter = areEqual(idArray, tags || []);
						}
						if (key === 'last_message_filter' && isMainFlMatchesQuickFilter) {
							const idArray = lastMessageFilter.map((item) => item.id);
							isMainFlMatchesQuickFilter = areEqual(idArray, last_message_filter || []);
						}
						if (key === 'sentiments' && isMainFlMatchesQuickFilter) {
							const idArray = sentimentsSelected.map((item) => item.label);
							isMainFlMatchesQuickFilter = areEqual(idArray, sentiments || []);
						}
						if (key === 'intents' && isMainFlMatchesQuickFilter) {
							const idArray = intentsSelected.map((item) => item.id);
							isMainFlMatchesQuickFilter = areEqual(idArray, intents || []);
						}
						if (key === 'window_closing_time' && isMainFlMatchesQuickFilter) {
							if (conversationClosingTime !== window_closing_time) {
								isMainFlMatchesQuickFilter = false;
							}
						}
						if (
							key === 'custom_filter' &&
							filter.facets.customFilterSelected.isChecked &&
							isMainFlMatchesQuickFilter
						) {
							const customFilter = filter.facets.customFilterSelected.solrQuery;
							if (customFilter !== custom_filter.solrQuery) {
								isMainFlMatchesQuickFilter = false;
							}
						}
					});
					if (isMainFlMatchesQuickFilter) {
						setShortcutQuickFilter(data.id);
						isFilterApplied = true;
					}
				}
			});
		}
		if (!isFilterApplied) {
			removeQuickFilterIfAny();
		}
	};

	const applyFilter = () => {
		const facets = getFacetsData();

		if (isFacetsChanged(facets)) {
			if (sentimentData.attributeId) {
				facets['sentimentAttributeId'] = sentimentData.attributeId;
			}
		}
		const filter: IMessageFilters = {
			facets: { ...JSON.parse(JSON.stringify(facets)) },
			sort: checkAndResetSort(),
		};
		const currentFilter = JSON.parse(JSON.stringify(filter));
		setQuickFilter(currentFilter);
		onApply(filter);
		onClose(false);

		toaster.positive(
			<ENGTToasterContainer
				title={t('common:success')}
				description={t('components:messageFilter.successToaster')}
			/>,
			{}
		);
	};

	const customFilter = () => (
		<Block>
			<Block paddingTop='0.78rem' paddingBottom='0.78rem' display='flex' justifyContent='space-between'>
				<MonoLabelXSmall
					className={css({
						fontWeight: 'normal',
						fontSize: '0.75rem',
						lineHeight: '1rem',
					})}
				>
					{t('components:customFilters.heading')}
				</MonoLabelXSmall>

				<span onClick={() => toggleCustomFilter(true)}>
					<MonoLabelXSmall
						className={css({
							cursor: 'pointer',
							textDecoration: 'underline',
							fontWeight: 500,
							fontSize: '0.75rem',
							lineHeight: '1rem',
						})}
					>
						{t('components:customFilters.addCustomFilter')}
					</MonoLabelXSmall>
				</span>
			</Block>
			{customFilterSelected.expression && (
				<Block paddingTop='0.22rem' paddingBottom='0.5rem' display='flex' justifyContent='flex-start'>
					<Checkbox
						values={{
							isChecked: customFilterSelected.isChecked,
							label: customFilterSelected.expression,
						}}
						onChange={(value) => {
							setCustomFilterSelected({ ...customFilterSelected, isChecked: value });
						}}
						style={{
							marginLeft: '0.5rem',
							minWidth: '0',
						}}
					/>
				</Block>
			)}
		</Block>
	);

	const filtersApplied = () => {
		const facets: IMessageFacets = getFacetsData();
		const allFilters: Array<ReactElement> = [];
		if (facets.channelsSelected?.length) {
			allFilters.push(
				...facets.channelsSelected.map((channel: ISelectValue) => (
					<AppliedFilter
						setState={setChannelsSelected}
						state={channelsSelected}
						id={channel.id}
						value={channel.label}
						type={FILTER_TYPES.select}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.slaFilterSelected?.length) {
			allFilters.push(
				...facets.slaFilterSelected.map((sla: ISelectValue) => (
					<AppliedFilter
						setState={setSlaFilterSelected}
						state={slaFilterSelected}
						id={sla.id}
						value={sla.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.dateSelected && facets.dateSelected[0] && facets.dateSelected[1]) {
			allFilters.push(
				<AppliedFilter
					setState={setDateSelected}
					state={dateSelected}
					value={`${format(new Date(facets.dateSelected[0]), 'PP')} - ${format(
						new Date(facets.dateSelected[1]),
						'PP'
					)}`}
					type={FILTER_TYPES.date}
					disabled={isMyConversationFilterApplied}
					enableCloseIcon={false}
					style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
				/>
			);
		}
		if (facets.managedByFilter?.length) {
			allFilters.push(
				...facets.managedByFilter.map((managedBy: ISelectValue) => (
					<AppliedFilter
						setState={setManagedByFilter}
						state={managedByFilter}
						id={managedBy.id}
						value={managedBy.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.agentConversationsFilter?.length) {
			allFilters.push(
				...facets.agentConversationsFilter.map((agent: ISelectValue) => (
					<AppliedFilter
						setState={setAgentConversationsFilter}
						state={agentConversationsFilter}
						id={agent.id}
						value={agent.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.lastMessageFilter?.length) {
			allFilters.push(
				...facets.lastMessageFilter.map((data: ISelectValue) => (
					<AppliedFilter
						setState={setLastMessageFilter}
						state={lastMessageFilter}
						id={data.id}
						value={data.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.statusSelected?.length) {
			allFilters.push(
				...facets.statusSelected.map((status: ISelectValue) => (
					<AppliedFilter
						setState={setStatusSelected}
						state={statusSelected}
						id={status.id}
						value={status.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.tagsSelected?.length) {
			allFilters.push(
				...facets.tagsSelected.map((tag: ISelectValue) => (
					<AppliedFilter
						setState={setTagsSelected}
						state={tagsSelected}
						id={tag.id}
						value={tag.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.intentsSelected?.length) {
			allFilters.push(
				...facets.intentsSelected.map((intent: ISelectValue) => (
					<AppliedFilter
						setState={setIntentsSelected}
						state={intentsSelected}
						id={intent.id}
						value={intent.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.sentimentsSelected?.length) {
			allFilters.push(
				...facets.sentimentsSelected.map((sentiment: ISelectValue) => (
					<AppliedFilter
						setState={setSentimentsSelected}
						state={sentimentsSelected}
						id={sentiment.id}
						value={sentiment.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		setAllAppliedFiltersUpdated(allFilters);
	};
	const filtersAppliedForModel = () => {
		const facets: IMessageFacets = getFacetsData();
		const allFilters: Array<ReactElement> = [];
		if (facets.channelsSelected?.length) {
			allFilters.push(
				...facets.channelsSelected.map((channel: ISelectValue) => (
					<AppliedFilter
						setState={setChannelsSelected}
						state={channelsSelected}
						id={channel.id}
						value={channel.label}
						type={FILTER_TYPES.select}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.slaFilterSelected?.length) {
			allFilters.push(
				...facets.slaFilterSelected.map((sla: ISelectValue) => (
					<AppliedFilter
						setState={setSlaFilterSelected}
						state={slaFilterSelected}
						id={sla.id}
						value={sla.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.dateSelected && facets.dateSelected[0] && facets.dateSelected[1]) {
			allFilters.push(
				<AppliedFilter
					setState={setDateSelected}
					state={dateSelected}
					value={`${format(new Date(facets.dateSelected[0]), 'PP')} - ${format(
						new Date(facets.dateSelected[1]),
						'PP'
					)}`}
					type={FILTER_TYPES.date}
					disabled={isMyConversationFilterApplied}
					enableCloseIcon={false}
					style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
				/>
			);
		}
		if (facets.managedByFilter?.length) {
			allFilters.push(
				...facets.managedByFilter.map((managedBy: ISelectValue) => (
					<AppliedFilter
						setState={setManagedByFilter}
						state={managedByFilter}
						id={managedBy.id}
						value={managedBy.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.agentConversationsFilter?.length) {
			allFilters.push(
				...facets.agentConversationsFilter.map((agent: ISelectValue) => (
					<AppliedFilter
						setState={setAgentConversationsFilter}
						state={agentConversationsFilter}
						id={agent.id}
						value={agent.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.lastMessageFilter?.length) {
			allFilters.push(
				...facets.lastMessageFilter.map((data: ISelectValue) => (
					<AppliedFilter
						setState={setLastMessageFilter}
						state={lastMessageFilter}
						id={data.id}
						value={data.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.statusSelected?.length) {
			allFilters.push(
				...facets.statusSelected.map((status: ISelectValue) => (
					<AppliedFilter
						setState={setStatusSelected}
						state={statusSelected}
						id={status.id}
						value={status.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.tagsSelected?.length) {
			allFilters.push(
				...facets.tagsSelected.map((tag: ISelectValue) => (
					<AppliedFilter
						setState={setTagsSelected}
						state={tagsSelected}
						id={tag.id}
						value={tag.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.intentsSelected?.length) {
			allFilters.push(
				...facets.intentsSelected.map((intent: ISelectValue) => (
					<AppliedFilter
						setState={setIntentsSelected}
						state={intentsSelected}
						id={intent.id}
						value={intent.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		if (facets.sentimentsSelected?.length) {
			allFilters.push(
				...facets.sentimentsSelected.map((sentiment: ISelectValue) => (
					<AppliedFilter
						setState={setSentimentsSelected}
						state={sentimentsSelected}
						id={sentiment.id}
						value={sentiment.label}
						type={FILTER_TYPES.select}
						disabled={isMyConversationFilterApplied}
						enableCloseIcon={false}
						style={{ marginRight: '0rem', paddingTop: '0.125rem', paddingLeft: '0.375rem' }}
					/>
				))
			);
		}
		setAllAppliedFiltersUpdated(allFilters);
	};

	const displayFilterContent = (filter: string, isDisabled: boolean) => (
		<Block display='flex' width='100%' alignItems='center'>
			<Checkbox
				values={{
					isChecked: availableFilters[filter],
					isDisabled,
					label: '',
				}}
				onChange={(value) => {
					onCheckBoxUncheck(filter, value);
				}}
				style={{
					marginLeft: '0.5rem',
					minWidth: '0',
				}}
				checkmarkCss={
					isDisabled
						? {
								backgroundColor: theme.videoLayoutColours.agentNameBackgroundColor,
								boxShadow: 'none',
							}
						: {}
				}
			/>
			<span
				className={css({
					lineHeight: '1.25rem',
					fontSize: '0.875rem',
					fontWeight: 'normal',
					marginTop: '0.25rem',
				})}
			>
				{filter}
			</span>
		</Block>
	);

	const disabledOptionChecks = (filter: any) => {
		if (isSimplifiedOneViewEnabled) {
			if (filter === FILTERS['Conversation Closing Time']) {
				return !agentChannels?.includes(CHANNELS.WHATSAPP);
			}

			return (
				isMyConversationFilterApplied &&
				filter !== FILTERS['Sort Messages'] &&
				filter !== FILTERS.Channel &&
				filter !== FILTERS['SLA non-adherence']
			);
		}
		if (tab === MESSAGE_TAB_HEADERS_MAP.unassigned && filter === FILTERS['SLA non-adherence']) {
			return true;
		}
		if (filter === FILTERS['Conversation Closing Time']) {
			return !agentChannels?.includes(CHANNELS.WHATSAPP);
		}

		return (
			isMyConversationFilterApplied &&
			(tab === MESSAGE_TAB_HEADERS_MAP.active ? filter !== FILTERS['Status'] : true) &&
			filter !== FILTERS['Sort Messages'] &&
			filter !== FILTERS.Channel &&
			filter !== FILTERS['Joined On']
		);
	};
	const filterContent = useCallback(
		() => (
			<div>
				<Accordion
					overrides={{
						header: {
							paddingTop: '0.75rem',
							paddingBottom: '0.75rem',
							borderBottomWidth: '0px',
						},
						root: {
							borderTopWidth: '0px',
						},
					}}
				>
					{availableFilters && Object.keys(availableFilters).length ? (
						Object.keys(availableFilters).map((filter: string, id: number) => {
							const isDisabled = disabledOptionChecks(filter);

							return isFilterAllowed(filter) ? (
								<Panel
									key={id}
									disabled={isDisabled}
									title={
										isDisabled &&
										isSimplifiedOneViewEnabled &&
										filter !== FILTERS['Conversation Closing Time'] ? (
											<ENGTTooltip
												placement={PLACEMENT.left}
												content={t('components:messageFiltersLite.filterCannotBeApplied')}
												ignoreBoundary={false}
											>
												{displayFilterContent(filter, isDisabled)}
											</ENGTTooltip>
										) : (
											<>{displayFilterContent(filter, isDisabled)}</>
										)
									}
								>
									<div
										className={css({
											marginLeft: '1rem',
											marginRight: '1rem',
											marginBottom: '1rem',
											backgroundColor:
												filter === FILTERS['Saved filter group']
													? theme.colors.backgroundSecondary
													: '',
											maxHeight: filter === FILTERS['Saved filter group'] ? '12.5rem' : '',
											overflow: filter === FILTERS['Saved filter group'] ? 'auto' : '',
										})}
									>
										{messageFilters(filter)}
									</div>
								</Panel>
							) : (
								<></>
							);
						})
					) : (
						<></>
					)}
				</Accordion>
				{(isAgent
					? isAgentAllowedToSaveCustomFilters
						? isAllTab || isSimplifiedOneViewEnabled
							? !isMyConversationFilterApplied
							: false
						: false
					: isAllTab || isSimplifiedOneViewEnabled
						? !isMyConversationFilterApplied
						: false) && (
					<>
						<HorizontalDivider height={0} />

						{customFilter()}
					</>
				)}
			</div>
		),
		[availableFilters, customFilterSelected]
	);

	const getAllTagAttributes = () => {
		const updatedTagsList = (botLevelTagsList || []).map((tag: any, index: number) => {
			const updatedTag = { id: '', label: '' };
			updatedTag.id = `tag${index}`;
			updatedTag.label = tag;

			return updatedTag;
		});
		setTags(updatedTagsList);
	};

	const getAllFlowAttributes = () => {
		pathBuilderApi.getFlowAttributes({ type: 'USER' }).then((resp: any) => {
			if (resp.data) {
				let attrs = [];
				if (Array.isArray(resp.data)) {
					attrs = resp.data.map((attribute: IObjProps) => {
						if (attribute.name === SENTIMENT_ATTRIBUTE_LABEL) {
							dispatch(setSentimentAttributeDataAction(attribute.id.slice(5)));
						}
						if (attributeIdList.lastIndexOf(attribute.name) === -1) {
							return {
								id: attribute.id,
								type: attribute.type,
								label: attribute.name,
							};
						}
					});
				}
				const updateAttributeList = attrs.filter((el: any) => el);
				setAttributes(updateAttributeList);
			} else {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			}
		});
	};

	const getFacetsData = useCallback((): IMessageFacets => {
		const date = dateSelected && dateSelected[0] && !dateSelected[1] ? [dateSelected[0], new Date()] : dateSelected;

		return {
			channelsSelected,
			slaFilterSelected,
			managedByFilter,
			agentConversationsFilter,
			lastMessageFilter,
			dateSelected: date,
			statusSelected,
			customFilterGroupSelected,
			intentsSelected,
			tagsSelected,
			sentimentsSelected,
			customFilterSelected,
			conversationClosingTime,
		};
	}, [
		channelsSelected,
		slaFilterSelected,
		managedByFilter,
		agentConversationsFilter,
		lastMessageFilter,
		dateSelected,
		statusSelected,
		customFilterGroupSelected,
		intentsSelected,
		tagsSelected,
		sentimentsSelected,
		customFilterSelected,
		conversationClosingTime,
	]);

	const isFacetsChanged = (facets: IMessageFacets) => JSON.stringify(facets) !== JSON.stringify(initialValue.facets);

	const isFilterAllowed = (filter: any) => {
		const filtersToBeRestrictedWhenExternalLivechatConfigured = [
			FILTERS['SLA non-adherence'],
			FILTERS['Agent Conversations'],
			FILTERS['Managed by'],
			FILTERS.Status,
			FILTERS['Saved filter group'],
		];

		return !(isExternalLivechatEnabled && filtersToBeRestrictedWhenExternalLivechatConfigured.includes(filter));
	};

	const saveCustomFilter = useCallback(() => {
		const facets: IMessageFacets = getFacetsData();
		const params: any = {
			bot_ref: '{{botRef}}',
			created_by_user_id: '{{userId}}',
			filter_name: value,
			joined_on_from_date: facets.dateSelected?.[0],
			joined_on_to_date: facets.dateSelected?.[1],
		};
		if (facets.channelsSelected?.length) {
			params.channels = facets.channelsSelected.map((channel: any) => channel.id);
		}
		if (facets.agentConversationsFilter?.length) {
			params.agent_conversations_user_ids = facets.agentConversationsFilter.map((acf: any) => acf.id);
		}
		if (facets.managedByFilter?.length) {
			params.managed_by_agent_ids = facets.managedByFilter.map((mbf: any) => mbf.id);
		}
		if (facets.slaFilterSelected?.length) {
			params.sla_non_adherence_types = facets.slaFilterSelected.map((sla: any) => sla.label);
		}
		if (facets.statusSelected?.length) {
			params.conversation_status = facets.statusSelected.map((status: any) => status.label);
		}
		if (facets.tagsSelected?.length) {
			params.tags = facets.tagsSelected.map((tag: any) => tag.label);
		}
		if (facets.sentimentsSelected?.length) {
			params.sentiments = facets.sentimentsSelected.map((sentiment: any) => sentiment.label);
		}
		if (facets.lastMessageFilter?.length) {
			params.last_message_filter = facets.lastMessageFilter.map((lmf: any) => lmf.id);
		}
		if (facets.intentsSelected?.length) {
			params.intents = facets.intentsSelected.map((intent: any) => intent.id);
		}
		if (facets.customFilterSelected) {
			params.custom_filter = customFilterSelected;
		}
		if (facets.conversationClosingTime) {
			params.window_closing_time = facets.conversationClosingTime;
		}

		messageListAPI.addCustomFilterGroup(params).then((resp: any) => {
			if (isValidResponseObject(resp)) {
				setAvailableFilters({ ...availableFilters });
				setCustomFilterGroupSelected(resp.data.responseObject.filter_name);
			}
		});
		getCustomFilterGroupApi();
	}, [getFacetsData, value, customFilterSelected, availableFilters]);

	useEffect(() => {
		const shortcutfilter = !isSimplifiedOneViewEnabled
			? dispatch(getShortcutQuickFilterFromLs(messageTab))
			: isMyConversationFilterApplied
				? dispatch(getShortcutQuickFilterLiteFromLsMyConversation())
				: dispatch(getShortcutQuickFilterLiteFromLs());

		if (shortcutfilter) {
			const matchingFilter: any = savedCustomFilters.find((data: any) => data.id === shortcutfilter);
			if (matchingFilter) {
				setCustomFilterGroupSelected(matchingFilter.filter_name);
				resetSavedCustomFilters(matchingFilter);
			}
		}
	}, [savedCustomFilters?.length]);

	const messageFilters = (filter: string) => {
		switch (filter) {
			case FILTERS['Sort Messages']:
				return (
					<div
						className={css({
							marginLeft: '1rem',
							marginRight: '1rem',
						})}
					>
						<ENGTRadio
							type='stateless'
							group='usersSortBy'
							buttons={sortOptions}
							value={sortApplied}
							onChange={(value) => {
								setSortApplied(value);
							}}
							overrides={{
								root: {
									marginBottom: '0.825rem',
								},
								label: {
									paddingTop: '0.25rem',
								},
							}}
						/>
					</div>
				);
			case FILTERS['Saved filter group']:
				return (
					<>
						<Block>
							{savedCustomFilters?.map((customFilters: IObjProps, index: number) => (
								<Block
									paddingTop='0.5rem'
									paddingRight='1rem'
									paddingBottom='0.5rem'
									display='flex'
									justifyContent='flex-start'
									alignContent='start'
									key={`custom-filter-${index}`}
								>
									<Checkbox
										values={{
											isChecked: customFilterGroupSelected === customFilters?.filter_name,
											label: customFilters?.filter_name,
										}}
										onChange={(value) => {
											if (value) {
												setCustomFilterGroupSelected(customFilters?.filter_name);
												resetSavedCustomFilters(customFilters);
											} else {
												setCustomFilterGroupSelected('');
												resetFacets();
											}
										}}
										style={{
											marginLeft: '1rem',
											minWidth: '0',
											width: '100%',
										}}
										labelCss={{
											width: '90%',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									/>
									<DeleteSelectedFilter
										customFilters={customFilters}
										deleteCustomFilterAPI={deleteCustomFilterAPI}
									/>
								</Block>
							))}
						</Block>
					</>
				);
			case FILTERS.Channel:
				return (
					<ENGTSelect
						inputRef=''
						multi
						onChange={(value: any) => {
							const allowedChannels = [CHANNELS.WHATSAPP, CHANNELS.FACEBOOK, CHANNELS.INSTAGRAM];
							const matchingIds = allowedChannels.filter((channel) =>
								value.some((data: any) => data.id.includes(channel))
							);
							const hasAllowedChannel = value.some((item: { id: any }) =>
								allowedChannels.includes(item.id)
							);
							if (
								conversationClosingTime !== '' &&
								(matchingIds.length !== value?.length || !hasAllowedChannel)
							) {
								setConversationClosingTime('');
								setSortApplied(
									enableRecentMessageFirstSort ? SORTING.recentFirst : SORTING.recentUserFirst
								);
							}
							setChannelsSelected(value);
						}}
						options={
							Array.isArray(agentConfiguredChannels)
								? agentConfiguredChannels.map((channel) => ({
										id: channel,
										label: CHANNEL_TITLE_MAPPING[channel.toLowerCase()],
									}))
								: []
						}
						placeholder={t('components:messageFilter.placeholder.choose channels')}
						popupIndex={theme.zIndex100}
						selectedValue={channelsSelected}
					/>
				);
			case FILTERS['SLA non-adherence']:
				return (
					<ENGTSelect
						inputRef=''
						multi
						onChange={setSlaFilterSelected}
						options={slaFilterFields}
						placeholder={t('components:messageFilter.placeholder.choose sla filter')}
						popupIndex={theme.zIndex100}
						selectedValue={slaFilterSelected}
					/>
				);
			case FILTERS['Managed by']:
				return (
					<ENGTSelect
						inputRef=''
						multi
						onChange={setManagedByFilter}
						options={availableAgents.map((filter: any) => ({
							id: `${filter.uid}`,
							label: filter.user_name,
						}))}
						placeholder={t('components:messageFilter.placeholder.choose agents')}
						popupIndex={theme.zIndex100}
						selectedValue={managedByFilter}
					/>
				);
			case FILTERS['Agent Conversations']:
				return (
					<ENGTSelect
						inputRef=''
						multi
						onChange={setAgentConversationsFilter}
						options={availableAgents.map((filter: any) => ({
							id: `${filter.uid}`,
							label: filter.user_name,
						}))}
						placeholder={t('components:messageFilter.placeholder.choose agents')}
						popupIndex={theme.zIndex100}
						selectedValue={agentConversationsFilter}
					/>
				);
			case FILTERS['Last Message']:
				return (
					<ENGTSelect
						inputRef=''
						multi
						onChange={setLastMessageFilter}
						options={lastMessageFields()}
						placeholder={t('components:messageFilter.placeholder.chooseLastMessage')}
						popupIndex={theme.zIndex100}
						selectedValue={lastMessageFilter}
					/>
				);
			case FILTERS['Joined On']:
				return (
					<Block display='flex' justifyContent='space-between'>
						<DefaultDatePicker
							value={dateSelected[0]}
							startDate={dateSelected[0]}
							endDate={dateSelected[1]}
							isPickerRanged
							onChange={(dates: any) => {
								setDateSelected(dates);
							}}
							inputWidth='90%'
							maxDate={new Date()}
						/>
					</Block>
				);
			case FILTERS.Status:
				return (
					<ENGTSelect
						inputRef=''
						multi
						onChange={setStatusSelected}
						options={
							!isSimplifiedOneViewEnabled && tab === MESSAGE_TAB_HEADERS_MAP.active
								? activeTabStatusFields
								: isAgentUser
									? agentsStatusFields
									: allTabStatusFields()
						}
						placeholder={t('components:messageFilter.placeholder.choose status')}
						popupIndex={theme.zIndex100}
						selectedValue={statusSelected}
					/>
				);
			case FILTERS.Intents:
				return (
					<ENGTSelect
						inputRef=''
						multi
						onChange={setIntentsSelected}
						options={INTENTS_FILTER_LIST}
						placeholder={t('components:messageFilter.placeholder.chooseIntents')}
						popupIndex={theme.zIndex100}
						selectedValue={intentsSelected}
					/>
				);
			case FILTERS.Tags:
				return (
					<ENGTSelect
						inputRef=''
						multi
						onChange={setTagsSelected}
						options={tags}
						placeholder={t('components:messageFilter.placeholder.chooseTags')}
						popupIndex={theme.zIndex100}
						selectedValue={tagsSelected}
					/>
				);
			case FILTERS.Sentiments:
				return (
					<ENGTSelect
						inputRef=''
						multi
						onChange={setSentimentsSelected}
						options={SENTIMENTS_FILTER_LIST}
						placeholder={t('components:messageFilter.placeholder.chooseSentiment')}
						popupIndex={theme.zIndex100}
						selectedValue={sentimentsSelected}
					/>
				);
			case FILTERS['Conversation Closing Time']:
				return (
					<ENGTRadio
						type='stateless'
						group='predefined'
						buttons={conversationClosingTimeOptns}
						value={conversationClosingTime}
						onChange={(value) => {
							setChannelsSelected([{ id: CHANNELS.WHATSAPP, label: CHANNELS.WHATSAPP_TITLE_CASE }]);
							setConversationClosingTime(value);
							setSortApplied(SORTING.conversationClosingTimeLTH);
						}}
						overrides={{
							root: {
								marginBottom: '0.825rem',
							},
							label: {
								paddingTop: '0.25rem',
							},
						}}
					/>
				);
			default:
				return <></>;
		}
	};

	const onCheckBoxUncheck = (filter: string, value: boolean) => {
		if (!availableFilters[filter]) {
			return;
		}

		if (filter === FILTERS.Channel) {
			setChannelsSelected([]);
			setConversationClosingTime('');
			setSortApplied(enableRecentMessageFirstSort ? SORTING.recentFirst : SORTING.recentUserFirst);
		}
		if (filter === FILTERS['SLA non-adherence']) {
			setSlaFilterSelected([]);
		}
		if (filter === FILTERS['Managed by']) {
			setManagedByFilter([]);
		}
		if (filter === FILTERS['Saved filter group']) {
			setCustomFilterGroupSelected('');
		}
		if (filter === FILTERS['Agent Conversations']) {
			setAgentConversationsFilter([]);
		}
		if (filter === FILTERS['Last Message']) {
			setLastMessageFilter([]);
		}
		if (filter === FILTERS['Joined On']) {
			setDateSelected([null, null]);
		}
		if (filter === FILTERS.Status) {
			setStatusSelected([]);
		}
		if (filter === FILTERS.Intents) {
			setIntentsSelected([]);
		}
		if (filter === FILTERS.Tags) {
			setTagsSelected([]);
		}
		if (filter === FILTERS.Sentiments) {
			setSentimentsSelected([]);
		}
		if (filter === FILTERS['Conversation Closing Time']) {
			setConversationClosingTime('');
			setSortApplied(enableRecentMessageFirstSort ? SORTING.recentFirst : SORTING.recentUserFirst);
		}

		setAvailableFilters({
			...availableFilters,
			[filter]: value,
		});
	};

	const deleteCustomFilterAPI = useCallback((id: any) => {
		messageListAPI.deleteCustomFilterGroup(id).then((resp: any) => {
			if (isValidResponseObject(resp)) {
				getCustomFilterGroupApi();
			}
		});
	}, []);

	const resetSavedCustomFilters = (customFilters: any) => {
		const channelsSelected: any = [];
		const agentConversationsFilter: any = [];
		const managedByFilter: any = [];
		const slaNonAdherenceTypes: any = [];
		const conversationstatus: any = [];
		const lastMessageFilter: any = [];
		const tagsG: any = [];
		const sentiments: any = [];
		const intents: any = [];

		setConversationClosingTime(customFilters?.window_closing_time);
		customFilters?.channels?.map((filter: any) => {
			const tempChannels = { id: filter, label: CHANNEL_TITLE_MAPPING[filter.toLowerCase()] };
			channelsSelected.push(tempChannels);
		});
		setChannelsSelected(channelsSelected);
		const hashMapAvailableAgents = new Map<number, string>();
		availableAgents.map((availableAgent: any) => {
			hashMapAvailableAgents.set(availableAgent.uid, availableAgent.user_name);
		});
		customFilters?.managed_by_agent_ids?.map((managedByAgentId: any) => {
			let tempManagedBy;
			if (hashMapAvailableAgents.get(managedByAgentId)) {
				tempManagedBy = { id: managedByAgentId, label: hashMapAvailableAgents.get(managedByAgentId) };
				managedByFilter.push(tempManagedBy);
			}
		});
		setManagedByFilter(managedByFilter);
		customFilters?.agent_conversations_user_ids?.map((agentUserIds: any) => {
			let tempAgentConversations;
			if (hashMapAvailableAgents.get(agentUserIds)) {
				tempAgentConversations = { id: agentUserIds, label: hashMapAvailableAgents.get(agentUserIds) };
				agentConversationsFilter.push(tempAgentConversations);
			}
		});
		setAgentConversationsFilter(agentConversationsFilter);
		const hashMapSla = new Map<string, string>();
		slaFilterFields.map((sla: any) => {
			hashMapSla.set(sla.label, sla.id);
		});
		customFilters?.sla_non_adherence_types?.map((sla: any) => {
			let tempSlaNonAdherenceTypes;
			if (hashMapSla.get(sla)) {
				tempSlaNonAdherenceTypes = { id: hashMapSla.get(sla), label: sla };
				slaNonAdherenceTypes.push(tempSlaNonAdherenceTypes);
			}
		});
		setSlaFilterSelected(slaNonAdherenceTypes);
		const hashMapConvStatus = new Map<string, string>();
		allTabStatusFields().map((conv_status: any) => {
			hashMapConvStatus.set(conv_status.label, conv_status.id);
		});
		customFilters?.conversation_status?.map((conv_status: any) => {
			let tempConversationStatus;
			if (hashMapConvStatus.get(conv_status)) {
				tempConversationStatus = { label: conv_status, id: hashMapConvStatus.get(conv_status) };
				conversationstatus.push(tempConversationStatus);
			}
		});
		setStatusSelected(conversationstatus);

		const hashMapLastMessageFilter = new Map<string, string>();
		lastMessageFields().map((lastmessagefilterId: any) => {
			hashMapLastMessageFilter.set(lastmessagefilterId.id, lastmessagefilterId.label);
		});
		customFilters?.last_message_filter?.map((lastmessagefilterId: any) => {
			let tempLastMessageFilter;
			if (hashMapLastMessageFilter.get(lastmessagefilterId)) {
				tempLastMessageFilter = {
					label: hashMapLastMessageFilter.get(lastmessagefilterId),
					id: lastmessagefilterId,
				};
				lastMessageFilter.push(tempLastMessageFilter);
			}
		});
		setLastMessageFilter(lastMessageFilter);
		setDateSelected([
			customFilters.joined_on_from_date ? new Date(customFilters.joined_on_from_date) : null,
			customFilters.joined_on_from_date ? new Date(customFilters.joined_on_to_date) : null,
		]);

		const hashMapTags = new Map<string, string>();
		tags.map((tag: any) => {
			hashMapTags.set(tag.label, tag.id);
		});
		customFilters?.tags?.map((tag: any) => {
			let tempTags;
			if (hashMapTags.get(tag)) {
				tempTags = {
					id: hashMapTags.get(tag),
					label: tag,
				};
				tagsG.push(tempTags);
			}
		});
		setTagsSelected(tagsG);
		const hashMapSentiments = new Map<string, string>();
		SENTIMENTS_FILTER_LIST.map((sentiment: any) => {
			hashMapSentiments.set(sentiment.label, sentiment.id);
		});
		customFilters?.sentiments?.map((sentiment: any) => {
			let tempSentiments;
			if (hashMapSentiments.get(sentiment)) {
				tempSentiments = {
					id: hashMapSentiments.get(sentiment),
					label: sentiment,
				};
				sentiments.push(tempSentiments);
			}
		});
		setSentimentsSelected(sentiments);
		const hashMapIntents = new Map<string, string>();
		INTENTS_FILTER_LIST.map((intent: any) => {
			hashMapIntents.set(intent.id, intent.label);
		});
		customFilters?.intents?.map((intent: any) => {
			let tempIntents;
			if (hashMapIntents.get(intent)) {
				tempIntents = {
					id: intent,
					label: hashMapIntents.get(intent),
				};
				intents.push(tempIntents);
			}
		});
		setIntentsSelected(intents);

		customFilters?.custom_filter && customFilters?.custom_filter.expression
			? setCustomFilterSelected({ ...customFilters.custom_filter, isChecked: true })
			: setCustomFilterSelected({ isChecked: false });
	};

	const resetDefaultFilters = () => {
		setChannelsSelected([]);
		setSlaFilterSelected([]);
		setDateSelected([null, null]);
		setAgentConversationsFilter([]);
		setLastMessageFilter([]);
		setManagedByFilter([]);
		setStatusSelected([]);
		setIntentsSelected([]);
		setTagsSelected([]);
		setSentimentsSelected([]);
		setConversationClosingTime('');
		setSortApplied(enableRecentMessageFirstSort ? SORTING.recentFirst : SORTING.recentUserFirst);
		setCustomFilterGroupSelected('');
		if (!isMyConversationFilterApplied && isDefaultLastMessageFilterEnabled) {
			setLastMessageFilter(DEFAULT_LAST_MESSAGE_FILTER(t));
		}
		removeQuickFilterIfAny();
	};

	const resetFacets = () => {
		resetDefaultFilters();
		setCustomFilterSelected({ isChecked: false });
	};

	const updateFiltersHeight = () => {
		setHeight(`calc(100% - ${filterHeaderRef.current?.clientHeight}px - 1px)`);
	};

	useEffect(() => {
		const filters: any = {
			[FILTERS['Sort Messages']]: sortApplied?.length > 0,
			[FILTERS['Saved filter group']]: customFilterGroupSelected?.length > 0,
			[FILTERS.Channel]: channelsSelected?.length > 0,
			[FILTERS['Managed by']]: managedByFilter?.length > 0,
			[FILTERS['Agent Conversations']]: agentConversationsFilter?.length > 0,
			[FILTERS['Last Message']]: lastMessageFilter?.length > 0,
			[FILTERS['Conversation Closing Time']]: conversationClosingTime?.length > 0,
			[FILTERS['SLA non-adherence']]: slaFilterSelected?.length > 0,
			[FILTERS['Joined On']]: dateSelected[0],
			[FILTERS.Status]: statusSelected?.length > 0,
			[FILTERS.Intents]: intentsSelected?.length > 0,
			[FILTERS.Tags]: tagsSelected?.length > 0,
			[FILTERS.Sentiments]: sentimentsSelected?.length > 0,
		};
		if (
			!(isAgent
				? isAgentAllowedToSaveCustomFilters
					? isAllTab || isSimplifiedOneViewEnabled
						? !isMyConversationFilterApplied
						: false
					: false
				: isAllTab || isSimplifiedOneViewEnabled
					? !isMyConversationFilterApplied
					: false)
		) {
			delete filters[FILTERS['Saved filter group']];
		}
		if (!isSimplifiedOneViewEnabled && tab != MESSAGE_TAB_HEADERS_MAP.all) {
			delete filters[FILTERS['Last Message']];
			delete filters[FILTERS['Managed by']];
			delete filters[FILTERS['Agent Conversations']];
			delete filters[FILTERS.Intents];
			delete filters[FILTERS.Tags];
			delete filters[FILTERS.Sentiments];

			if (tab != MESSAGE_TAB_HEADERS_MAP.active) {
				delete filters[FILTERS.Status];
			}
		}

		if (isAgentUser) {
			delete filters[FILTERS['SLA non-adherence']];
			delete filters[FILTERS['Managed by']];
			delete filters[FILTERS['Agent Conversations']];
		}
		if (!isIntentTaggingEnabled) {
			delete filters[FILTERS.Intents];
		}
		if (!isSentimentsEnabled) {
			delete filters[FILTERS.Sentiments];
		}
		setAvailableFilters(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		sortApplied,
		channelsSelected,
		slaFilterSelected,
		dateSelected,
		managedByFilter,
		customFilterGroupSelected,
		savedCustomFilters,
		agentConversationsFilter,
		lastMessageFilter,
		statusSelected,
		intentsSelected,
		tagsSelected,
		sentimentsSelected,
		conversationClosingTime,
	]);

	const getCustomFilterGroupApi = useCallback(() => {
		messageListAPI.getCustomFilterGroup().then((response: any) => {
			if (isValidResponseObject(response)) {
				setSavedCustomFilters(response.data.responseObject.slice().reverse());
			}
		});
	}, []);

	useEffect(() => {
		filtersAppliedForModel();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		channelsSelected,
		slaFilterSelected,
		dateSelected,
		managedByFilter,
		savedCustomFilters,
		agentConversationsFilter,
		lastMessageFilter,
		statusSelected,
		intentsSelected,
		tagsSelected,
		sentimentsSelected,
	]);
	useEffect(() => {
		filtersApplied();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		channelsSelected,
		slaFilterSelected,
		dateSelected,
		managedByFilter,
		savedCustomFilters,
		agentConversationsFilter,
		lastMessageFilter,
		statusSelected,
		intentsSelected,
		tagsSelected,
		sentimentsSelected,
	]);

	useEffect(() => {
		getAllTagAttributes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [botLevelTagsList]);

	useEffect(() => {
		setSortApplied(initialValue.sort);
		applyFacetsFromParent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValue]);

	useEffect(() => {
		updateFiltersHeight();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allAppliedFilters]);

	useEffect(() => {
		isCustomFilterOpen && !attributes.length && getAllFlowAttributes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCustomFilterOpen]);

	useEffect(() => {
		if (!configuredChannels?.length) {
			dispatch(getConfiguredChannelsAction());
		}
	}, []);

	const saveSelectedFilterDescription = useCallback(
		() => (
			<div>
				{allAppliedFilters.length ? (
					<div
						className={css({
							marginRight: '1rem !important',
							display: 'flex',
							flexWrap: 'wrap',
							marginBottom: '1rem',
						})}
					>
						{allAppliedFiltersUpdated}
					</div>
				) : (
					<></>
				)}
				<div
					className={css({
						fontSize: '1rem',
					})}
				>
					{t('components:messageFiltersLite.header')}
				</div>
			</div>
		),
		[allAppliedFilters]
	);

	const onFilterSave = useCallback(
		(value: string) => {
			setValue(value);
		},
		[setValue]
	);

	return (
		<div
			className={css({
				height: '100%',
			})}
		>
			<div ref={filterHeaderRef}>
				<Block display='flex' justifyContent='space-between' alignItems='center' marginBottom='0.5rem'>
					<MessagePageHeader title={t('components:messageFiltersLite.heading')} />
					<div
						className={css({ cursor: 'pointer' })}
						onClick={() => {
							onClose(false);
						}}
					>
						<CloseIconLite fillColor={theme.colors.primaryA} height='40' width='40' />
					</div>
				</Block>
				{allAppliedFilters.length ? (
					<div
						className={css({
							marginRight: '1rem !important',
							display: 'flex',
							flexWrap: 'wrap',
							marginBottom: '1rem',
						})}
					>
						{allAppliedFilters}
					</div>
				) : (
					<></>
				)}
				{(
					isAgent
						? isAgentAllowedToSaveCustomFilters
							? isAllTab || isSimplifiedOneViewEnabled
								? !isMyConversationFilterApplied
								: false
							: false
						: isAllTab || isSimplifiedOneViewEnabled
							? !isMyConversationFilterApplied
							: false
				) ? (
					<SaveSelectedFilters
						savedFilters={savedCustomFilters}
						onChange={onFilterSave}
						saveSelectedFilterDescription={saveSelectedFilterDescription}
						onSave={saveCustomFilter}
						getCustomFilterGroupApi={getCustomFilterGroupApi}
					/>
				) : (
					<></>
				)}
				<HorizontalDivider height={0} />
			</div>
			<div
				className={css({
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: 'column',
					height,
				})}
			>
				<div className={css({ flex: 1, overflow: 'auto' })}>{filterContent()}</div>
				<div className={css({ paddingBottom: '1rem' })}>
					<HorizontalDivider height={0} />
					<div className={css(footerButtonCss)}>
						<Button
							kind={KIND['secondary']}
							size={SIZE.default}
							label={t('components:filterDropdown.clearall')}
							className={css({
								marginRight: '1rem !important',
							})}
							onClick={resetFacets}
						/>
						<Button
							kind={KIND['primary']}
							size={SIZE.default}
							label={t('components:filterDropdown.apply')}
							className={css({ minWidth: '20%' })}
							onClick={applyFilter}
						/>
					</div>
				</div>
			</div>
			{isCustomFilterOpen && (
				<CustomFilter
					isOpen={isCustomFilterOpen}
					isReadOnly
					description={description}
					heading={t('components:customFilters.popupHeading')}
					onClose={() => toggleCustomFilter(false)}
					onApply={(filter: any) => {
						setCustomFilterSelected({ ...filter, isChecked: true });
						toggleCustomFilter(false);
					}}
					attributes={attributes}
					showConversationFilter
					channels={configuredChannels}
					type='USER'
				/>
			)}
		</div>
	);
};

export default MessageFilterLite;
