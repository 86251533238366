import { D360_CLOUD_BASE_URL, LLM_FEATURE_TYPES } from 'shared/consts/consts';

import { RootState } from 'store/rootReducer';

export const botRefSelector = (state: RootState) => state.Bot.data.bot_ref;

export const configuredChannelsForBot = (state: RootState) => state.Bot.configuredChannels;

export const providerConfigured = (state: RootState) => state.Bot.data.provider_configured;

export const isLLMEntitlementEnabled = (state: RootState) =>
	[LLM_FEATURE_TYPES.BASIC, LLM_FEATURE_TYPES.ADVANCE, LLM_FEATURE_TYPES.CUSTOM].includes(
		state.Bot.configData.enable_smart_assist
	);

export const LLMFeatureName = (state: RootState) => state.Bot.configData.enable_smart_assist;

export const whatsAppClientUrlConfigured = (state: RootState) => state.Bot.data.whatsapp_client_url;

export const botKeyData = (state: RootState) => state.Bot.data.bot_key;

export const botNameSelector = (state: RootState) => state.Bot.data.bot_name;

export const nexmoContactSelector = (state: RootState) => state.Bot.data.nexmo_contact;

export const appIdSelector = (state: RootState) => state.Bot.data.app_id;

export const whatsAppClientUrl = (state: RootState) => state.Bot.data.whatsapp_client_url;

export const isWACloudNumberSelector = (state: RootState) => state.Bot.data.whatsapp_client_url === D360_CLOUD_BASE_URL;
