import { IApiDataProps, IObjProps } from 'shared/consts/types';

export interface IMessageDetailsStateInterface {
	columns: IApiDataProps<IObjProps>;
	isRightPanelOpen: boolean;
	isShareProductsIconClicked: boolean;
}

export const messageDetailsState: IMessageDetailsStateInterface = {
	columns: {
		data: {},
		error: false,
		loading: true,
	},
	isRightPanelOpen: false,
	isShareProductsIconClicked: false,
};
