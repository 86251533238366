import BaseService from 'services/baseService';
import { serialize } from 'shared/helpers';

const genericProxyRequestUrl = '/genericProxyRequest/';
const v2genricProxyUrl = '/v2/genericProxyRequest/';
const getColumnsUrl = '/api/v2/columns';

const genericProxyApi = new BaseService(genericProxyRequestUrl);
const v2GenricProxyApi = new BaseService(v2genricProxyUrl);
const getColumnsApi = new BaseService(getColumnsUrl);

export const API = {
	getCampaigns(params) {
		return genericProxyApi.getRequest(serialize(params, '?'));
	},
	campaignAnalytics(params) {
		return v2GenricProxyApi.getRequestWithParams(params);
	},
	deleteCampaign(campaignId) {
		const params = {
			target_url: 'campaign',
			params: { campaign_id: campaignId },
		};

		return genericProxyApi.deleteRequest(serialize(params, '?'));
	},
	editCampaign(botTimezone, campaignId, campaignName) {
		const data = {
			bot_timezone: botTimezone,
			target_url: 'campaign',
			campaign_name: campaignName,
			campaign_id: campaignId,
		};

		return genericProxyApi.putRequest(data);
	},
	saveCampaign(botTimezone, campaignName) {
		const data = {
			bot_timezone: botTimezone,
			target_url: 'campaign',
			campaign_name: campaignName,
		};

		return genericProxyApi.postRequest(data);
	},
	deleteMessage(campaignMessageId) {
		const params = {
			target_url: 'campaignMessage',
			params: { campaign_message_id: campaignMessageId },
		};

		return genericProxyApi.deleteRequest(serialize(params, '?'));
	},
	getCampaignMessages(data) {
		return genericProxyApi.putRequest(data);
	},
	exportCampaignData(data) {
		const params = {
			targetApi: 'exportCampaignData',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	getColumns(params) {
		return getColumnsApi.getRequestWithParams(params);
	},
};
