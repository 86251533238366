import React from 'react';

function Contacts({
	className,
	size,
	fillColor = '#403F42',
}: {
	className?: string;
	size?: number;
	fillColor?: string;
}) {
	return (
		<svg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				opacity='0.7'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.49959 0.833252H9.83293L15.3329 6.33325V17.3333C15.3329 17.8195 15.1398 18.2858 14.796 18.6296C14.4521 18.9734 13.9858 19.1666 13.4996 19.1666H2.49959C2.01336 19.1666 1.54705 18.9734 1.20323 18.6296C0.859414 18.2858 0.66626 17.8195 0.66626 17.3333V2.66659C0.66626 2.18036 0.859414 1.71404 1.20323 1.37022C1.54705 1.02641 2.01336 0.833252 2.49959 0.833252ZM4.33364 10.1167C3.89182 10.1167 3.53364 10.4748 3.53364 10.9167C3.53364 11.3585 3.89182 11.7167 4.33364 11.7167H11.667C12.1088 11.7167 12.467 11.3585 12.467 10.9167C12.467 10.4748 12.1088 10.1167 11.667 10.1167H4.33364ZM3.53364 14.5833C3.53364 14.1414 3.89182 13.7833 4.33364 13.7833H11.667C12.1088 13.7833 12.467 14.1414 12.467 14.5833C12.467 15.0251 12.1088 15.3833 11.667 15.3833H4.33364C3.89182 15.3833 3.53364 15.0251 3.53364 14.5833ZM4.33364 6.44996C3.89182 6.44996 3.53364 6.80814 3.53364 7.24996C3.53364 7.69179 3.89182 8.04996 4.33364 8.04996H5.25031H6.16698C6.6088 8.04996 6.96698 7.69179 6.96698 7.24996C6.96698 6.80814 6.6088 6.44996 6.16698 6.44996H5.25031H4.33364Z'
				fill={fillColor}
			/>
		</svg>
	);
}
export default Contacts;
