import React, { useState } from 'react';
import { useStyletron } from 'baseui';

import { copyToClipboard } from 'shared/helpers';
import CopyIcon from 'shared/icons/CopyIcon';

import { i18nHelper } from 'i18nHelper';

export interface IENGTToasterContainerProps {
	title?: string | React.ReactNode;
	description: string | React.ReactNode;
	showCopyBtn?: boolean;
	copyText?: string;
}

const ENGTToasterContainer = ({ copyText, title, description, showCopyBtn }: IENGTToasterContainerProps) => {
	const [css, theme] = useStyletron();
	const [isCopied, setValueCopied] = useState<boolean>(false);

	const titleCss = ($theme: any) => ({
		color: $theme.toasterTextColor,
		fontSize: '0.75rem',
		lineHeight: '1rem',
		fontWeight: 700,
		marginBottom: '0rem',
		marginTop: '0rem',
	});

	const descriptionCss = ($theme: any) => ({
		color: $theme.toasterTextColor,
		fontSize: '0.75rem',
		lineHeight: '1rem',
		marginBottom: '0rem',
		marginTop: title ? '0.2rem' : '0rem',
	});

	const renderCopyButton = () => (
		<span
			className={css({
				alignSelf: 'flex-end',
				justifySelf: 'right',
				cursor: 'pointer',
				marginLeft: 'auto',
			})}
			onClick={() => {
				copyToClipboard(copyText || '');
				setValueCopied(true);
				setTimeout(() => {
					setValueCopied(false);
				}, 3000);
			}}
		>
			{isCopied ? (
				<span
					className={css({
						paddingLeft: '0.5rem',
						paddingRight: '0.5rem',
						paddingTop: '0.25rem',
						paddingBottom: '0.25rem',
						backgroundColor: theme.colors.accent100,
						fontSize: '0.625rem',
						lineHeight: '0.75rem',
						borderTopRightRadius: '0.25rem',
						borderBottomRightRadius: '0.25rem',
						borderTopLeftRadius: '0.25rem',
						borderBottomLeftRadius: '0.25rem',
						color: theme.colors.primaryA,
					})}
				>
					{i18nHelper('common:copied')}
				</span>
			) : (
				<CopyIcon fillColor={theme.colors.primaryA} className={css({ verticalAlign: 'middle' })} />
			)}
		</span>
	);

	return (
		<div className={css({ display: 'flex', justifyContent: 'space-between' })}>
			<div>
				<h3 className={css(titleCss(theme))}>{title}</h3>
				<p className={css(descriptionCss(theme))}>{description}</p>
			</div>
			{showCopyBtn && renderCopyButton()}
		</div>
	);
};

export default ENGTToasterContainer;
