import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { KIND } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { Overflow } from 'baseui-new/icon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BouncingEffect from 'components/BouncingEffect/BouncingEffect';
import { LayoutContent, LayoutFooter } from 'components/Common/Layout';
import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import usePaginationHook from 'components/UI/ENGTPagination/PaginationHook';
import ENGTRadio from 'components/UI/ENGTRadio/ENGTRadio';
import ENGTTable from 'components/UI/ENGTTable/ENGTTable';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';
import FormLabel from 'components/UI/Form/FormLabel/FormLabel';
import Loader from 'components/UI/Loader/Loader';
import NoDataTemplate from 'components/UI/NoDataTemplate/NoDataTemplate';

import { AD_DETAILS_CONNECT, AD_DETAILS_LOGOUT, AD_DETAILS_NEXT, AD_DETAILS_PREVIOUS } from 'shared/consts/QAConsts';
import { IObjProps } from 'shared/consts/types';
import { isValidResponseObject } from 'shared/helpers';
import NoDataEmoji from 'shared/icons/NoDataEmoji.svg';
import InfoIcon from 'shared/icons/PathBuilder/InfoIcon';

import { API as FB_API } from 'store/App/Configure/API/Deployment/Facebook/api';
import { API } from 'store/Broadcast/api';
import { RootState } from 'store/rootReducer';

import { TwoColumnRouteLinks } from 'router/links/TwoColumnRouteLinks';

import LoginComponent from '../../../Configure/Deployment/helpers/helpers';

import AdsSorting, { SORTING_OPTIONS } from './AdsSorting';
import ClickToWARetargetModal from './ClickToWARetargetModal';

const AdDetailsTab = () => {
	const { t } = useTranslation(['common', 'pages']);
	const [css, theme]: [any, any] = useStyletron();
	const navigate = useNavigate();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [isRetargetModalOpen, setRetargetModalOpen] = useState<boolean>(false);
	const [adDetails, setAdDetails] = useState<Array<IObjProps>>([]);
	const [segmentData, setSegmentData] = useState<IObjProps>();
	const [selectedAdId, setSelectedAdId] = useState<string>('');
	const [isAdAuthTokenPresent, setIsAdAuthTokenPresent] = useState<boolean>(false);
	const [fbmSelection, setFbmSelection] = useState<boolean>(false);
	const [fbmAccounts, setFbmAccounts] = useState<Array<IObjProps>>([]);
	const [selectedFbm, setSelectedFbm] = useState<string>('');
	const [adsSelection, setAdsSelection] = useState<boolean>(false);
	const [adAccounts, setAdAccounts] = useState<Array<IObjProps>>([]);
	const [selectedAdAccountId, setSelectedAdAccountId] = useState<string>('');
	const [selectedAdAccountName, setSelectedAdAccountName] = useState<string>('');
	const [lastUpdatedOn, setLastUpdatedOn] = useState<string>('');
	const [adsAnalytics, displayAdsAnalytics] = useState<boolean>(false);
	const [syncing, setSyncing] = useState<boolean>(false);
	const [sort, setSorting] = useState<string>(SORTING_OPTIONS.LEADS_CAPTURED_WA_DESC);

	const { usersListLink } = TwoColumnRouteLinks;

	const tableOverrides = {
		wrapper: {
			marginLeft: 0,
			marginRight: 0,
			marginTop: '1rem',
			marginBottom: '3rem',
		},
	};

	const botKey = useSelector((state: RootState) => state.Bot.data.bot_key);
	const appId = useSelector((state: RootState) => state.Bot.data.app_id);

	const sortOptions = [
		{
			name: t('pages:broadcast.clickToWhatsappAds.adDetailsTab.sorting.engagedUsersDesc'),
			value: SORTING_OPTIONS.LEADS_CAPTURED_WA_DESC,
		},
		{
			name: t('pages:broadcast.clickToWhatsappAds.adDetailsTab.sorting.engagedUsersAsc'),
			value: SORTING_OPTIONS.LEADS_CAPTURED_WA_ASC,
		},
		{
			name: t('pages:broadcast.clickToWhatsappAds.adDetailsTab.sorting.adName'),
			value: SORTING_OPTIONS.AD_NAME,
		},
	];

	const getBotMetaConfig = () => {
		setLoading(true);
		try {
			FB_API.getBotMetaConfig().then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					if (response.data.responseObject.adAuthToken) {
						setIsAdAuthTokenPresent(true);
						if (response.data.responseObject.adAccountId) {
							setSelectedAdAccountName(response.data.responseObject.adAccountName);
							displayAdsAnalytics(true);
							getAdAnalyticsData(0, pageDefaultSize);
						} else {
							getBusinessAccounts();
						}
					}
				} else if (response?.data?.status?.code === 10025) {
					setIsAdAuthTokenPresent(false);
				} else {
					toaster.negative(
						<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
						{}
					);
				}
				setLoading(false);
			});
		} catch (exception) {
			setLoading(false);
		}
	};

	const deleteFbAdToken = () => {
		try {
			FB_API.deleteAdAuthToken().then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					toaster.positive(
						<ENGTToasterContainer
							description={t('common:changesSuccessfulSaved')}
							title={t('pages:configure.deployment.facebookDeployment.connected.removeAccountHeading')}
						/>,
						{}
					);
					setIsAdAuthTokenPresent(false);
				} else {
					setIsAdAuthTokenPresent(true);
					toaster.negative(
						<ENGTToasterContainer
							title={t('pages:configure.deployment.facebookDeployment.authFailed')}
							description={t('common:somethingWentWrong')}
						/>,
						{}
					);
				}
			});
		} catch (e) {
			setLoading(false);
		}
	};

	const getBusinessAccounts = () => {
		setLoading(true);
		setAdAccounts([]);
		try {
			FB_API.getBusinessAccounts().then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					setFbmAccounts(response.data.responseObject?.data);
				} else {
					toaster.negative(
						<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
						{}
					);
				}
				setFbmSelection(true);
				setLoading(false);
			});
		} catch (e) {
			setLoading(false);
		}
	};

	const getAdAccounts = () => {
		setLoading(true);
		try {
			FB_API.getAdAccounts(selectedFbm).then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					setAdAccounts(response.data.responseObject?.data);
				}
				setAdsSelection(true);
				setLoading(false);
			});
		} catch (e) {
			setLoading(false);
		}
	};

	const saveBotMetaConfig = () => {
		const selectedAdAccountData: IObjProps = adAccounts.filter(
			(account) => account.account_id === selectedAdAccountId
		);

		const payload = {
			adAccountId: selectedAdAccountData?.[0]?.account_id,
			adAccountName: selectedAdAccountData?.[0]?.name,
			botKey,
		};
		try {
			FB_API.saveBotMetaConfig(payload).then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					setSelectedAdAccountName(payload.adAccountName);
					setSyncing(true);
					getAdAnalyticsDataAfterDelay();
				} else {
					toaster.negative(
						<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
						{}
					);
				}
			});
		} catch (e) {
			setLoading(false);
		}
	};

	const callOnSignIn = () => {
		getBusinessAccounts();
	};

	useEffect(() => {
		getBotMetaConfig();
	}, []);

	const adDetailsColumns = [
		{
			id: 'ad_name',
			span: 2.6,
			name: t('pages:broadcast.clickToWhatsappAds.adName'),
			processor: (data: any, row: any) => row.ad_name ?? '-',
		},
		{
			id: 'impressions',
			span: 1.8,
			name: t('pages:broadcast.clickToWhatsappAds.impressions'),
			tooltip: t('pages:broadcast.clickToWhatsappAds.impressionsTooltip'),
			processor: (data: any, row: any) => row.impressions ?? '-',
		},
		{
			id: 'reach',
			span: 1.8,
			name: t('pages:broadcast.clickToWhatsappAds.reach'),
			tooltip: t('pages:broadcast.clickToWhatsappAds.reachTooltip'),
			processor: (data: any, row: any) => row.reach ?? '-',
		},
		{
			id: 'clicks',
			span: 1.8,
			name: t('pages:broadcast.clickToWhatsappAds.clicksOnAd'),
			tooltip: t('pages:broadcast.clickToWhatsappAds.clicksOnAdTooltip'),
			processor: (data: any, row: any) => row.clicks ?? '-',
		},
		{
			id: 'ctr',
			span: 1.6,
			name: t('pages:broadcast.clickToWhatsappAds.ctr'),
			tooltip: t('pages:broadcast.clickToWhatsappAds.ctrTooltip'),
			processor: (data: any, row: any) => row.ctr ?? '-',
		},
		{
			id: 'leads_captured_wa',
			span: 2.0,
			name: t('pages:broadcast.clickToWhatsappAds.usersEngagedOnWhatsApp'),
			tooltip: t('pages:broadcast.clickToWhatsappAds.usersEngagedOnWhatsAppTooltip'),
			processor: (data: any, row: any) => row.leads_captured_wa ?? '-',
		},
	];

	const rowActions = [
		{
			items: [
				{
					label: t('pages:broadcast.clickToWhatsappAds.retarget'),
					onClick: (data: any) => {
						setSelectedAdId(data.ad_id);
						onRetargetSegment(data.ad_id);
					},
				},
				{
					label: t('pages:broadcast.clickToWhatsappAds.viewUsers'),
					onClick: (data: any) => {
						const filter = 'filter=fromCTWA';
						const solrQuery = `source_ids:"${data.ad_id}"`;
						const query = `query=${encodeURIComponent(solrQuery)}`;
						navigate(`${usersListLink}?${filter}&${query}`);
					},
				},
			],
			onClick: () => {},
			icon: <Overflow size={24} />,
		},
	];

	const pageDefaultSize = 10;

	const loadMoreAdsFn = (pageNo = 1, size: number = pageDefaultSize) => {
		loadMoreAds({
			page: pageNo,
			size,
		});
	};

	const paginationMethods = usePaginationHook({
		onChange: loadMoreAdsFn,
	});

	const { setPagination } = paginationMethods;

	const loadMoreAds = (params: IObjProps) => {
		const { page, size } = params;
		getAdAnalyticsData(page - 1, size);
	};

	const getAdAnalyticsDataAfterDelay = () => {
		setTimeout(() => {
			setSyncing(false);
			displayAdsAnalytics(true);
			getAdAnalyticsData(0, pageDefaultSize);
		}, 30000);
	};

	const onSortingBroadcast = (data: any) => {
		setSorting(data);
		getAdAnalyticsData(0, pageDefaultSize, data);
	};

	const getAdAnalyticsData = (page: number, size: number, sortOption?: string) => {
		setLoading(true);
		const params = {
			page,
			size,
			sortOption: sortOption || sort,
			targetApi: 'getAdLevelAnalytics',
		};
		API.getAdLevelAnalytics(params).then((response: IObjProps) => {
			if (isValidResponseObject(response)) {
				const { responseObject } = response.data;
				const adLevelAnalyticsResponse = responseObject?.adLevelAnalyticsResponsePage;

				const paging = {
					size: adLevelAnalyticsResponse?.size,
					page: adLevelAnalyticsResponse?.pageable?.pageNumber + 1,
					totalPages: adLevelAnalyticsResponse?.totalPages,
					totalElements: adLevelAnalyticsResponse?.totalElements,
				};
				setPagination(paging);
				setLastUpdatedOn(responseObject?.lastUpdatedOn);
				setAdDetails(adLevelAnalyticsResponse?.content);
			} else {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			}
			setLoading(false);
		});
	};

	const onRetargetSegment = (adId: string) => {
		API.retargetForAd(adId).then((response: IObjProps) => {
			if (isValidResponseObject(response)) {
				const data = response.data.responseObject;
				setRetargetModalOpen(true);
				setSegmentData(data);
			} else {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			}
		});
	};

	const showFbmSelection = !isLoading && isAdAuthTokenPresent && fbmSelection && !adsSelection;
	const showAdsSelection = !isLoading && isAdAuthTokenPresent && !fbmSelection && adsSelection;
	const showAdsAnalytics = !isLoading && isAdAuthTokenPresent && adsAnalytics;
	const showSyncingScreen = !isLoading && isAdAuthTokenPresent && syncing;

	const renderBusinessAccounts = () =>
		fbmAccounts?.length > 0 ? (
			<div className={css({ marginTop: '0.875rem' })}>
				<FormLabel
					id='upload-files'
					label={t('pages:broadcast.clickToWhatsappAds.adDetailsTab.fbmHeading')}
					className={{ marginTop: '1rem', marginBottom: '0.5rem', fontSize: '1rem', fontWeight: 500 }}
					tooltip={t('pages:broadcast.clickToWhatsappAds.adDetailsTab.fbmTooltip')}
				/>
				<ENGTRadio
					group='fbmAccounts'
					buttons={fbmAccounts.map((option) => ({
						name: `${option.name} | ${t(
							'pages:configure.deployment.whatsappDeployment.waCommerce.businessId'
						)}${option.id}`,
						value: option.id,
					}))}
					value={selectedFbm}
					onChange={(value: string) => setSelectedFbm(value)}
				/>
			</div>
		) : (
			renderNoData('fbm')
		);

	const renderAdsAccounts = () =>
		selectedFbm && adAccounts?.length > 0 ? (
			<div className={css({ marginTop: '0.875rem' })}>
				<FormLabel
					id='upload-files'
					label={t('pages:broadcast.clickToWhatsappAds.adDetailsTab.adHeading')}
					className={{ marginTop: '1rem', marginBottom: '0.5rem', fontSize: '1rem', fontWeight: 500 }}
					tooltip={t('pages:broadcast.clickToWhatsappAds.adDetailsTab.adTooltip')}
				/>
				<ENGTRadio
					group='adAccounts'
					buttons={adAccounts.map((option) => ({
						name: `${option.name} | ${t('pages:broadcast.clickToWhatsappAds.adDetailsTab.adAccountId')}${
							option.account_id
						}`,
						value: option.account_id,
					}))}
					value={selectedAdAccountId}
					onChange={(value: string) => setSelectedAdAccountId(value)}
				/>
			</div>
		) : (
			renderNoData('ads')
		);

	const renderNoData = (value: string) => (
		<NoDataTemplate
			bannerImage={{
				altText: t('pages:broadcast.clickToWhatsappAds.adDetailsTab.noData'),
				imageUrl: NoDataEmoji,
			}}
			heading={
				value === 'fbm'
					? t('pages:broadcast.clickToWhatsappAds.adDetailsTab.noFbmData')
					: t('pages:broadcast.clickToWhatsappAds.adDetailsTab.noAdAccountsData')
			}
			headingCss={{ marginTop: '1rem' }}
		/>
	);

	const displayLogin = () => (
		<div
			className={css({
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				flexWrap: 'wrap',
				alignItems: 'center',
				height: '100%',
			})}
		>
			<div
				className={css({
					fontWeight: '700',
					fontSize: '1.25rem',
					marginBottom: '0.6rem',
					color: theme.colors.primaryA,
				})}
			>
				{' '}
				{t('pages:broadcast.clickToWhatsappAds.adDetailsTab.loginHeading1')}{' '}
			</div>
			<div
				className={css({
					fontWeight: '400',
					fontSize: '1rem',
					marginBottom: '0.6rem',
					color: theme.colors.primaryA,
				})}
			>
				{' '}
				{t('pages:broadcast.clickToWhatsappAds.adDetailsTab.loginHeading2')}{' '}
			</div>

			<LoginComponent
				botKey={botKey}
				appId={appId}
				appName='ads'
				label={t('pages:broadcast.clickToWhatsappAds.adDetailsTab.signIn')}
				setReauthorized={setLoading}
				css={css}
				theme={theme}
				setIsAdAuthTokenPresent={setIsAdAuthTokenPresent}
				callOnSignIn={callOnSignIn}
			/>
		</div>
	);

	const renderSyncingScreen = () => (
		<div
			className={css({
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'row',
				flexWrap: 'wrap',
				alignItems: 'center',
				height: '100%',
			})}
		>
			<div
				className={css({
					fontWeight: '700',
					fontSize: '1.25rem',
					marginRight: '0.6rem',
					color: theme.colors.primaryA,
				})}
			>
				{t('pages:broadcast.clickToWhatsappAds.adDetailsTab.syncing')}
			</div>
			<BouncingEffect backgroundColor={theme.failure} />
		</div>
	);

	const renderAdsAnalytics = () => (
		<>
			<div
				className={css({
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				})}
			>
				<div
					className={css({
						paddingTop: '10px',
						paddingRight: '10px',
						fontWeight: 500,
						fontSize: '16px',
						lineHeight: '20px',
						color: theme.colors.primaryA,
					})}
				>
					{t('pages:broadcast.clickToWhatsappAds.adDetailsTab.connectedTo')}
					<span
						className={css({
							fontWeight: 600,
							paddingRight: '0.625rem',
						})}
					>
						{selectedAdAccountName}
					</span>

					<ENGTButton
						kind={KIND.secondary}
						label={t('common:logout')}
						onClick={deleteFbAdToken}
						dataQa={AD_DETAILS_LOGOUT}
					/>
				</div>

				<div
					className={css({
						display: 'flex',
					})}
				>
					{lastUpdatedOn && (
						<div
							className={css({
								fontWeight: 400,
								fontSize: '0.875rem',
								alignItems: 'center',
								color: theme.colors.primaryA,
								display: 'flex',
								marginRight: '1rem',
							})}
						>
							{t('components:conversations.lastUpdatedOn')}
							<span style={{ marginLeft: '0.2rem' }}>{`${t(
								'common:standardDateTimeFormatterWithShortMonthNameAndTimeAheadOfDate',
								{
									date: lastUpdatedOn,
								}
							)}`}</span>
							{tableInfoIcon()}
						</div>
					)}
					<AdsSorting initialValue={sort} sortOptions={sortOptions} onSortValueChange={onSortingBroadcast} />
				</div>
			</div>

			<ENGTTable
				columns={adDetailsColumns}
				rows={adDetails}
				pagination={paginationMethods}
				paginationType='footer'
				overrides={tableOverrides}
				rowActions={rowActions}
				loader={isLoading}
			/>
		</>
	);

	const tableInfoIcon = () => (
		<ENGTTooltip content={t('pages:broadcast.clickToWhatsappAds.adDetailsTab.tableMetricsInfo')}>
			<label
				style={{
					cursor: 'pointer',
				}}
			>
				<InfoIcon fill={theme.colors.accent50} />
			</label>
		</ENGTTooltip>
	);

	return (
		<div
			className={css({
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			})}
		>
			<LayoutContent>
				{isLoading && <Loader loaderBlockHeight='50%' />}
				{!isLoading && !isAdAuthTokenPresent && displayLogin()}
				{showFbmSelection && renderBusinessAccounts()}
				{showAdsSelection && renderAdsAccounts()}
				{showAdsAnalytics && renderAdsAnalytics()}
				{showSyncingScreen && renderSyncingScreen()}
			</LayoutContent>
			<LayoutFooter>
				<div className={css({ display: 'flex', columnGap: '0.5rem' })}>
					{showFbmSelection && (
						<>
							<ENGTButton
								type='submit'
								label={t('common:next')}
								isDisabled={!selectedFbm}
								className={css({
									marginTop: '0.5rem',
								})}
								onClick={() => {
									getAdAccounts();
									setFbmSelection(false);
								}}
								dataQa={AD_DETAILS_NEXT}
							/>
							<ENGTButton
								kind={KIND.secondary}
								label={t('common:logout')}
								onClick={deleteFbAdToken}
								className={css({
									marginTop: '0.5rem',
								})}
								dataQa={AD_DETAILS_LOGOUT}
							/>
						</>
					)}

					{showAdsSelection && (
						<>
							<ENGTButton
								type='submit'
								label={t('pages:broadcast.clickToWhatsappAds.adDetailsTab.connect')}
								isDisabled={!selectedAdAccountId}
								onClick={() => {
									saveBotMetaConfig();
									setAdsSelection(false);
								}}
								dataQa={AD_DETAILS_CONNECT}
							/>

							<ENGTButton
								type='submit'
								label={t('common:previous')}
								kind={KIND.secondary}
								onClick={() => {
									setAdsSelection(false);
									setFbmSelection(true);
									setSelectedFbm('');
									getBusinessAccounts();
								}}
								dataQa={AD_DETAILS_PREVIOUS}
							/>
							<ENGTButton
								kind={KIND.secondary}
								label={t('common:logout')}
								onClick={deleteFbAdToken}
								dataQa={AD_DETAILS_LOGOUT}
							/>
						</>
					)}
				</div>
			</LayoutFooter>
			{isRetargetModalOpen && segmentData && (
				<ClickToWARetargetModal
					isModalOpen={setRetargetModalOpen}
					adId={selectedAdId}
					segmentData={segmentData}
				/>
			)}
		</div>
	);
};

export default AdDetailsTab;
