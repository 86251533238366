/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { LabelSmall } from 'baseui/typography';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { IConversataionFormFields } from 'components/CustomFilter/CustomFilter';
import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import DefaultDatePicker from 'components/UI/ENGTDatePicker/DefaultDatePicker';
import FormSelect from 'components/UI/Form/FormSelect/FormSelect';

import { MESSAGE_TAB_HEADERS_MAP } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { debounce } from 'shared/helpers';

import { getCategoriesAction } from 'store/App/User';
import { API } from 'store/App/User/api';
import { RootState } from 'store/rootReducer';

import { allTabStatusMap, conversationFormNameMap, dateMap, generalStatusMap } from './consts';

const debounceFn = debounce((data: string, init: boolean, callBack: (data: string, init: boolean) => void) => {
	callBack(data, init);
}, 500);

const ConversationFilter = ({
	conversationDetails,
	tagDetails = {
		tagOptions: [{ id: '', label: '' }],
		showTags: false,
	},
}: {
	conversationDetails: Array<IObjProps> | [];
	tagDetails?: {
		tagOptions: Array<{ id: string; label: string | React.ReactNode }>;
		showTags: boolean;
	};
}) => {
	const { t } = useTranslation(['common', 'components']);
	const { tab: messageTab }: IObjProps = useParams();
	const [css, theme]: any = useStyletron();
	const dispatch: any = useDispatch<any>();
	const isInitAccountsFetched = useRef(false);

	const categories = useSelector((state: RootState) => state.User.categoriesMap) || [];
	const categoriesMap = categories.map((c: any) => ({ id: c.id, label: c.name }));

	const [allAccountsToManage, setAllAccounts] = useState<any>([]);
	const [isAccountsFetchLoading, setAccountsFetchLoader] = useState<boolean>(false);

	const isAllTab = messageTab && messageTab === MESSAGE_TAB_HEADERS_MAP.all;

	const fetchAccounts = (event: any, init = false) => {
		isInitAccountsFetched.current = init;
		debounceFn(event, init, fetchAccountsFn);
	};

	const fetchAccountsFn = (event: any, init = false) => {
		const query = event?.target?.value || '';

		if (query.length > 0 || init) {
			setAccountsFetchLoader(true);
			API.fetchSearchAccountToManage(query).then((response: any) => {
				if (response.data && response.data.responseObject) {
					const liveChatAccounts: any = response.data.responseObject.map((account: any) => ({
						id: account.userId,
						label: account.email,
					}));

					setAccountsFetchLoader(false);
					setAllAccounts(liveChatAccounts);
				}
			});
		} else {
			setAccountsFetchLoader(false);
			setAllAccounts([]);
		}
	};

	const updateForm = () => {
		conversationDetails.forEach((filter: any) => {
			let value: any = [];

			switch (filter['lhOperand']) {
				case conversationFormNameMap['liveChatState']:
					value = statusMap.filter((d: any) => d.id === filter['rhOperand']);
					break;
				case conversationFormNameMap['categoryId']:
					value = categoriesMap.filter((d: any) => d.id === parseInt(filter['rhOperand'] || '')) || [];
					break;
				case conversationFormNameMap['lastActiveAt']:
					if (filter['operator'] === 'CUSTOM_RANGE') {
						const [from, to] = filter['rhOperand']?.split(',') || [];
						setValue('custom_date', [new Date(from), new Date(to)]);

						value = dateMap().filter((d: any) => d.id === 'custom_date') || [];
					} else {
						value = dateMap().filter((d: any) => d.id === parseInt(filter['rhOperand'] || '')) || [];
					}
					break;
				case conversationFormNameMap['livechatEngagedBy']:
					value = filter['rhOperand'].split(' OR ').map((e: any) => ({
						id: allAccountsToManage?.filter((d: any) => d.id === parseInt(e || '')) || [],
					}));
					reset({
						...getValues(),
						[conversationFormNameMap['livechatEngagedBy']]: value,
					});

					return;
				case conversationFormNameMap['livechatResolvedBy']:
					value = allAccountsToManage?.filter((d: any) => d.id === parseInt(filter['rhOperand'] || '')) || [];
					break;
				case conversationFormNameMap['livechatTransfferedBy']:
					value = allAccountsToManage?.filter((d: any) => d.id === parseInt(filter['rhOperand'] || '')) || [];
					break;
				case conversationFormNameMap['tagId']:
					const rhOperands = filter['rhOperand'].split(' OR ');
					value = tagDetails?.tagOptions?.filter((tag: any) => rhOperands.includes(tag.label)) || [];
					break;
				default:
			}
			setValue(filter['lhOperand'], value);
		});
	};

	const statusMap: any = isAllTab ? allTabStatusMap() : generalStatusMap();

	const methods = useFormContext<IConversataionFormFields>();

	const { control, getValues, setValue, reset } = methods;

	useEffect(() => {
		!categories?.length && dispatch(getCategoriesAction());
		fetchAccounts('', true);
	}, []);

	useEffect(() => {
		updateForm();
	}, [conversationDetails, tagDetails]);

	useEffect(() => {
		if (isInitAccountsFetched.current && allAccountsToManage?.length) {
			isInitAccountsFetched.current = false;
			updateForm();
		}
	}, [allAccountsToManage]);

	useWatch({
		control,
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: conversationFormNameMap['livechatEngagedBy'],
		keyName: 'fid' as 'id',
	});

	const CrudBtn = ({ symbol, onClickFn }: { symbol: string; onClickFn: any }) => (
		<div
			onClick={onClickFn}
			className={css({
				width: '1.5rem',
				height: '1.5rem',
				backgroundColor: theme.colors.backgroundPrimary,
				cursor: 'pointer',
				position: 'relative',
				marginLeft: '0.5rem',
				borderTopRightRadius: '50%',
				borderBottomRightRadius: '50%',
				borderTopLeftRadius: '50%',
				borderBottomLeftRadius: '50%',
			})}
		>
			<div
				className={css({
					position: 'absolute',
					left: '50%',
					top: '50%',
					fontSize: '1.125rem',
					transform: 'translate(-50%,-45%)',
				})}
			>
				{symbol}
			</div>
		</div>
	);

	const RemoveBtn = ({ onClickFn }: { onClickFn: any }) => <CrudBtn symbol='-' onClickFn={onClickFn} />;

	const renderStatusFilter = (
		<Controller
			control={control}
			name={conversationFormNameMap['liveChatState']}
			render={({ ref, value, onChange }) => (
				<FormSelect
					name={conversationFormNameMap['liveChatState']}
					clearable={false}
					searchable={false}
					inputRef={ref}
					placeholder={t('common:select')}
					onChange={onChange}
					options={statusMap}
					label={t('components:customFilters.conversations.status')}
					tooltip={t('components:customFilters.conversations.tooltip.status')}
					selectedValue={value}
					width='100%'
					popupIndex={theme.zIndex400}
				/>
			)}
		/>
	);

	const renderCategoryFitler = (
		<Controller
			control={control}
			name={conversationFormNameMap['categoryId']}
			render={({ ref, value, onChange }) => (
				<FormSelect
					name={conversationFormNameMap['categoryId']}
					clearable={false}
					searchable={false}
					inputRef={ref}
					placeholder={t('common:select')}
					onChange={onChange}
					options={categoriesMap}
					label={t('components:customFilters.conversations.category')}
					tooltip={t('components:customFilters.conversations.tooltip.category')}
					selectedValue={value}
					width='100%'
					popupIndex={theme.zIndex400}
				/>
			)}
		/>
	);
	const renderTagsFilter = (
		<Controller
			control={control}
			name={conversationFormNameMap['tagId']}
			render={({ ref, value, onChange }) => (
				<FormSelect
					name={conversationFormNameMap['tagId']}
					clearable={false}
					searchable={false}
					inputRef={ref}
					placeholder={t('components:messageFilter.placeholder.chooseTags')}
					onChange={onChange}
					multi
					options={tagDetails?.tagOptions}
					label={t('components:customFilters.conversations.tags')}
					tooltip={t('components:customFilters.conversations.tooltip.tags')}
					selectedValue={value}
					width='100%'
					popupIndex={theme.zIndex400}
				/>
			)}
		/>
	);
	const renderDateFilter = (
		<Controller
			control={control}
			name={conversationFormNameMap['lastActiveAt']}
			render={({ ref, value, onChange }) => (
				<FormSelect
					name={conversationFormNameMap['lastActiveAt']}
					clearable={false}
					searchable={false}
					inputRef={ref}
					placeholder={t('common:select')}
					onChange={onChange}
					options={dateMap()}
					label={t('components:customFilters.conversations.date')}
					tooltip={t('components:customFilters.conversations.tooltip.date')}
					selectedValue={value}
					width='100%'
					popupIndex={theme.zIndex400}
				/>
			)}
		/>
	);

	const lastActiveFilterValue: any = getValues(conversationFormNameMap['lastActiveAt']);
	const isCustomFilter =
		lastActiveFilterValue && lastActiveFilterValue[0]?.id === conversationFormNameMap['customDate'];

	const renderCustomDateFilter = () => (
		<Controller
			control={control}
			name={conversationFormNameMap['customDate']}
			render={({ ref, value, onChange }) => (
				<Block display='flex' justifyContent='space-between' marginBottom='1.5rem'>
					<div
						className={css({
							textAlign: 'left',
							marginRight: '1rem',
							width: '100%',
						})}
					>
						<LabelSmall>{t('common:labels.from')}</LabelSmall>

						<DefaultDatePicker
							value={value[0]}
							onChange={(date: any) => onChange([date, value[1]])}
							inputPlaceholder={t('components:DateFilter.custom.AddDate')}
							inputWidth='90%'
							popupIndex={3}
							maxDate={new Date()}
						/>
					</div>
					<div
						className={css({
							textAlign: 'left',
							width: '100%',
						})}
					>
						<LabelSmall>{t('common:labels.to')}</LabelSmall>
						<DefaultDatePicker
							value={value[1]}
							onChange={(date: any) => onChange([value[0], date])}
							inputPlaceholder={t('components:DateFilter.custom.AddDate')}
							inputWidth='90%'
							popupIndex={3}
							maxDate={new Date()}
						/>
					</div>
				</Block>
			)}
		/>
	);

	const renderHandledBy = (
		<div className={css({ textAlign: 'left' })}>
			{fields.map((fieldData: any, id: number) => (
				<Block display='flex' justifyContent='space-between' alignItems='center' key={fieldData.fid}>
					<div className={css({ flexGrow: 1 })}>
						<Controller
							control={control}
							name={`${conversationFormNameMap['livechatEngagedBy']}[${id}].id`}
							render={({ ref, value, onChange }) => (
								<FormSelect
									name={`${conversationFormNameMap['livechatEngagedBy']}[${id}].id`}
									inputRef={ref}
									label={!id ? t('components:customFilters.conversations.handledBy') : ''}
									placeholder={t('components:customFilters.conversations.inputPlaceHolder')}
									tooltip={!id ? t('components:customFilters.conversations.tooltip.handledBy') : ''}
									isLoading={isAccountsFetchLoading}
									disabled={isAccountsFetchLoading}
									options={allAccountsToManage}
									searchable
									onChange={onChange}
									onInputChange={fetchAccounts}
									selectedValue={value}
									popupIndex={theme.zIndex400}
								/>
							)}
						/>
					</div>
					<div className={css({ marginBottom: id > 0 ? '1.5rem' : '0rem' })}>
						{fields.length > 0 ? (
							<RemoveBtn
								onClickFn={() => {
									remove(id);
									fields.length === 1 && append({ id: '' }, true);
								}}
							/>
						) : (
							''
						)}
					</div>
				</Block>
			))}
			<ENGTButton
				type='button'
				onClick={() => append({ id: '' }, true)}
				className={css({ marginBottom: '1rem !important' })}
				size='mini'
				label={t('components:customFilters.conversations.addHandledBy')}
			/>
		</div>
	);

	const renderResolvedBy = (
		<Controller
			control={control}
			name={conversationFormNameMap['livechatResolvedBy']}
			render={({ value, onChange }) => (
				<FormSelect
					name={conversationFormNameMap['livechatResolvedBy']}
					label={t('components:customFilters.conversations.resolvedBy')}
					placeholder={t('components:customFilters.conversations.inputPlaceHolder')}
					tooltip={t('components:customFilters.conversations.tooltip.resolvedBy')}
					inputRef=''
					isLoading={isAccountsFetchLoading}
					disabled={isAccountsFetchLoading}
					options={allAccountsToManage}
					searchable
					onChange={onChange}
					onInputChange={fetchAccounts}
					selectedValue={value}
					popupIndex={theme.zIndex400}
				/>
			)}
		/>
	);

	const renderTransferredBy = (
		<Controller
			control={control}
			name={conversationFormNameMap['livechatTransfferedBy']}
			render={({ value, onChange }) => (
				<FormSelect
					inputRef=''
					name={conversationFormNameMap['livechatTransfferedBy']}
					isLoading={isAccountsFetchLoading}
					disabled={isAccountsFetchLoading}
					options={allAccountsToManage}
					searchable
					onChange={onChange}
					onInputChange={fetchAccounts}
					placeholder={t('components:customFilters.conversations.inputPlaceHolder')}
					label={t('components:customFilters.conversations.transferredBy')}
					tooltip={t('components:customFilters.conversations.tooltip.transferredBy')}
					selectedValue={value}
					popupIndex={theme.zIndex400}
				/>
			)}
		/>
	);

	return (
		<div>
			{renderStatusFilter}
			{renderCategoryFitler}
			{tagDetails?.showTags && renderTagsFilter}
			{renderDateFilter}
			{isCustomFilter && renderCustomDateFilter()}
			{renderHandledBy}
			{renderResolvedBy}
			{renderTransferredBy}
		</div>
	);
};

export default ConversationFilter;
