import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DOC_LINKS_V2 } from 'shared/consts/DockLinks';

import { RootState } from 'store/rootReducer';

const PrivacyLink = () => {
	const { t }: any = useTranslation(['common']);

	const privacyLink = useSelector((state: RootState) => state.User.botAdmin.data.privacy_policy);

	return (
		<li>
			<a
				className='account-dropdown-menu__link-item'
				href={privacyLink && privacyLink !== 'NA' ? privacyLink : DOC_LINKS_V2.privacyLink}
				target='_blank'
				rel='noreferrer'
			>
				{t('common:privacy')}
			</a>
		</li>
	);
};

export default PrivacyLink;
