import React from 'react';
import { useStyletron } from 'baseui';
import { Label2, Label4 } from 'baseui/typography';

import { VerticalDivider } from 'components/UI/Dividers/VerticalDivider';

import { IObjProps } from 'shared/consts/types';

interface GraphToolTipProps {
	top: number;
	left: number;

	data?: IObjProps;
	overrides?: any;
	leftAxisTooltip?: boolean;
	leftAxisData?: string;

	label: (key: string) => any;
}

const ENGTGraphToolTip = ({
	top = 0,
	left = 0,
	data,
	label,
	overrides,
	leftAxisTooltip,
	leftAxisData,
}: GraphToolTipProps) => {
	const [css, theme] = useStyletron();

	return (
		<div
			style={{
				top: top - 8,
				left: left > 300 ? left - 120 : left + 16,
				height: '2rem',
				minWidth: '2rem',
				display: 'flex',
				position: 'absolute',
				pointerEvents: 'none',
				alignItems: 'center',
				justifyContent: 'center',
				paddingLeft: '0.3rem',
				paddingRight: '0.3rem',
				borderTopLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
				background: theme.colors.primaryA,

				...overrides,
			}}
		>
			<Label4 color={theme.colors.primaryB}>{label(data?.key || data?.id)}</Label4>
			<VerticalDivider
				color={theme.colors.primaryB}
				height='0.875rem'
				overrides={{
					marginLeft: '0.2rem',
					marginRight: '0.2rem',
				}}
			/>
			<Label2 color={theme.colors.primaryB}>{data?.value || data?.bar?.data?.weight}</Label2>
			{leftAxisTooltip && (
				<>
					<VerticalDivider
						color={theme.colors.primaryB}
						height='0.875rem'
						overrides={{
							marginLeft: '0.2rem',
							marginRight: '0.2rem',
						}}
					/>
					<Label2 color={theme.colors.primaryB}>{leftAxisData}</Label2>
				</>
			)}
		</div>
	);
};

export default ENGTGraphToolTip;
