import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';
import { API_GATEWAY_URL } from 'shared/consts/consts';

const instaDetails = '/instagram/list_of_connected_fb_pages';
const connectInstaApi = '/add_instagram_pages';
const disablePageApi = '/api/v1/disable_instagram_bot';
const enablePageApi = '/api/v1/enable_instagram_bot';
const removePageApi = '/api/v1/delete_instagram_bot';
const logoutApi = '/instagram/logout_fb';
const loginApi = '/instagram/fb_oauth/';
const genericProxyRequest = '/v2/genericProxyRequest/';

const instaDetailsAPI = new BaseService(instaDetails);
const instaConnectPage = new BaseService(connectInstaApi);
const enablePageAPI = new BaseService(enablePageApi);
const disablePageAPI = new BaseService(disablePageApi);
const removePageAPI = new BaseService(removePageApi);
const logoutAPI = new BaseService(logoutApi);
const loginAPI = new BaseService(loginApi);
const genericProxyRequestAPI = new BaseService(genericProxyRequest);
const apiGatewayRequest = new BaseService(API_GATEWAY_URL);

export const INSTA_API = {
	fetchAvailablePages() {
		return instaDetailsAPI.getRequest();
	},

	connectInstaPage: (payload) => instaConnectPage.postRequest(payload),

	enablePage: (payload) => enablePageAPI.postRequest(payload),

	disablePage: (payload) => disablePageAPI.postRequest(payload),

	deletePage: (payload) => removePageAPI.postRequest(payload),

	logout: (params) => logoutAPI.getRequest(serialize(params, '?')),

	login: (payload) => loginAPI.postRequest(payload),

	updateMediaSettings: (payload, pageId) => {
		const params = {
			pageId,
			targetApi: 'updateInstanceBotConfig',
		};

		return genericProxyRequestAPI.putRequest(payload, serialize(params, '?'));
	},

	getMediaSettings: (pageId) => {
		const params = {
			targetApi: 'getInstanceBotConfig',
			pageId,
		};

		return genericProxyRequestAPI.getRequest(serialize(params, '?'));
	},

	saveSocialInboxConfig: (socialInboxConfig, platform) => {
		const params = {
			targetApi: 'saveSocialInboxConfig',
			platform,
			socialInboxConfig,
		};

		return genericProxyRequestAPI.putRequest(params, serialize(params, '?'));
	},

	getTokenStatus: (platform) => {
		const params = {
			targetApi: 'getTokenStatus',
			platform,
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
};
