import { OVERVIEW } from 'shared/consts/QAConsts';

function Home({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			width={size || 40}
			height={size || 40}
			viewBox='0 0 40 40'
			fill='none'
			className={className}
			data-qa={OVERVIEW}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='inherit'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M22.4 30L25.6 30C26.8282 30 27.8286 28.99 27.8286 27.7499V21.4997H29.5619C30.2443 21.4997 30.8 20.9387 30.8 20.2496C30.8 19.9016 30.6534 19.5676 30.4107 19.3456L27.8286 16.8575V11.7493C27.8286 11.3353 27.4958 10.9993 27.0857 10.9993H24.1143C23.7042 10.9993 23.3714 11.3353 23.3714 11.7493V12.5693L20.9121 10.2063C20.6258 9.93125 20.1742 9.93125 19.8869 10.2063L10.4021 19.3336C10.1466 19.5676 10 19.9016 10 20.2496C10 20.9387 10.5557 21.4997 11.2381 21.4997H12.9714V27.7499C12.9714 28.99 13.9718 30 15.2 30L18.4 30V26C18.4 25.4477 18.8477 25 19.4 25H21.4C21.9523 25 22.4 25.4477 22.4 26V30Z'
					fill={fillColor || '#403F42'}
				/>
			</g>
		</svg>
	);
}

export default Home;
