import { createSlice } from '@reduxjs/toolkit';

import { notificationReducers } from './reducers';
import { notificationTrayState } from './state';

export const notificationSlice = createSlice({
	name: 'Notifications',
	initialState: notificationTrayState,
	reducers: notificationReducers,
});

export const { actions, reducer } = notificationSlice;
