import { MESSAGE_SENDER_MAP } from 'shared/consts/consts';
import { isValidJson } from 'shared/helpers';
import { linkify } from 'shared/helpers/quillHelper';

export const getLocationPacketDetails = (
	message: any,
	sender: any
): { isLocationPacket: boolean; parsedPacket: any } => {
	try {
		const messageDocument = new DOMParser().parseFromString(linkify(message), 'text/html');
		const messageContent = messageDocument.documentElement.textContent;
		if (isValidJson(messageContent)) {
			const messageObtained = JSON.parse(messageContent || '{}');
			if (messageObtained?.type === 'LocationPacket' && sender === MESSAGE_SENDER_MAP.user) {
				return {
					isLocationPacket: true,
					parsedPacket: messageObtained,
				};
			}
		}
	} catch (e) {
		console.error('error parsing the message', e, message);
	}

	return {
		isLocationPacket: false,
		parsedPacket: null,
	};
};

export const getLocationMessageHTML = (message: any, sender: any): string => {
	const locationPacketDetails = getLocationPacketDetails(message, sender);
	if (locationPacketDetails.isLocationPacket) {
		return getGoogleMapsLink(locationPacketDetails.parsedPacket);
	}

	return '';
};

const getGoogleMapsLink = (message: any): string => {
	const { latitude, longitude } = message?.location;
	if (latitude && longitude) {
		return `<a class="clickableLinksA" href="https://maps.google.com/?q=${latitude},${longitude}"" target="_blank">https://maps.google.com/?q=${latitude},${longitude}</a>`;
	}

	return '';
};

export const getIsValidLocationMessage = (localtionMessageHTML: string): boolean =>
	localtionMessageHTML?.length > 0 || false;
