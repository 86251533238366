import { useStyletron } from 'baseui';
import { HeadingLevel } from 'baseui/heading';
import { ParagraphMedium } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ParentSize } from '@visx/responsive';

import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';
import ENGTBarGroupY from 'components/UI/Graph/ENGTBarGroupY';
import ENGTLinePath from 'components/UI/Graph/ENGTLinePath';
import ENGTPieChart from 'components/UI/Graph/ENGTPieChart';
import NoDataTemplate from 'components/UI/NoDataTemplate/NoDataTemplate';

import { IObjProps } from 'shared/consts/types';
import { DAYS_FILTER, MARKETING_CAMPAIGNS_ENTITLEMENT_VALUES } from 'shared/enum';
import { isValidPercentIncrease } from 'shared/helpers/broadcastHelpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import FilledDownCaret from 'shared/icons/FilledDownCaret';
import FilledUpCaret from 'shared/icons/FilledUpCaret';
import NoGraphIcon from 'shared/icons/NoGraphIcon.svg';

import {
	isCTWAEntitlementEnabledSelector,
	marketingCampaignsAnalyticsSelector,
	profileDataSelector,
} from 'store/App/User';

import InfoCardWithIcon from './InfoCardWithIcon';

interface ICtwaEngagement {
	nonUniqueUsers: IObjProps;
	filterOn: string;
}

const CTWAEngagement = ({ nonUniqueUsers, filterOn }: ICtwaEngagement) => {
	const { t } = useTranslation(['common', 'pages', 'components']);
	const [css, theme]: [any, any] = useStyletron();

	const { isSmallDesktopScreen } = useResponsiveSize();
	const profileData = useSelector(profileDataSelector);
	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);
	const isCTWAEntitlementEnabled = useSelector(isCTWAEntitlementEnabledSelector);
	const isPartnerCustomer =
		marketingCampaignsAnalyticsType === MARKETING_CAMPAIGNS_ENTITLEMENT_VALUES.PARTNER || !isCTWAEntitlementEnabled;

	const styles = {
		paragraphCss: {
			marginTop: '0.75rem',
			marginBottom: '0.3125rem',
			fontWeight: '700 !important',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},

		subParagraphCss: {
			marginTop: '0rem',
			lineHeight: '1.25rem',
			width: '100%',
			fontSize: '0.875rem !important',
			fontWeight: '400 !important',
		},

		cardWrapperCss: {
			display: 'grid',
			gridTemplateColumns: isSmallDesktopScreen ? '1fr' : '0.65fr 0.5fr',
			gap: '2rem',
		},

		infoCardCss: {
			display: 'inline-flex',
			marginTop: '4rem',
			gap: '0.2rem',
			color: `${theme.colors.primaryA}`,
		},
	};

	const adTypesData = (data: IObjProps) => {
		if (!data?.totalBySourceAndType) {
			return [];
		}

		return data.totalBySourceAndType.map(
			(channelData: { adSource: string; adTypeCountDTOList: { adType: string; count: number }[] }) => {
				const totalCount = channelData.adTypeCountDTOList.reduce(
					(sum, adTypeData) => sum + adTypeData.count,
					0
				);

				return {
					key: channelData.adSource,
					value: totalCount,
				};
			}
		);
	};

	const showEngagedUsersPercentIncrease = isValidPercentIncrease(
		nonUniqueUsers?.adEngagement?.engagedPercentIncrease
	);

	const showReachPercentIncrease = isValidPercentIncrease(nonUniqueUsers?.adEngagement?.reachPercentIncrease);

	const leadSource = (
		<div className={css(styles.cardWrapperCss)}>
			<InfoCardWithIcon
				title={<strong>{t('pages:marketingCampaigns.engagement.engagedUsers')}</strong>}
				value={nonUniqueUsers?.adEngagement?.engagedCount || 0}
				marginTop={nonUniqueUsers?.adEngagement?.engagedCount ? '' : '2.5rem'}
			>
				<div style={{ display: 'flex' }}>
					{showEngagedUsersPercentIncrease ? (
						<div className={css(styles.infoCardCss)}>
							{nonUniqueUsers.adEngagement.engagedPercentIncrease > 0 ? (
								<FilledUpCaret
									fillColor={theme.colors.dotStatusFill}
									height={12}
									width={14}
									pathToDraw='M8.0752 1.24658C7.55424 0.497235 6.44576 0.497235 5.92481 1.24658L0.236574 9.42852C-0.36707 10.2968 0.254272 11.4855 1.31177 11.4855L12.6882 11.4855C13.7457 11.4855 14.3671 10.2968 13.7634 9.42852L8.0752 1.24658Z'
								/>
							) : (
								<FilledDownCaret
									fillColor={theme.colors.dotPendingFill}
									height={12}
									width={14}
									pathToDraw='M8.0752 10.7362C7.55424 11.4855 6.44576 11.4855 5.92481 10.7362L0.236574 2.55426C-0.36707 1.68598 0.254272 0.497235 1.31177 0.497235L12.6882 0.497235C13.7457 0.497235 14.3671 1.68598 13.7634 2.55426L8.0752 10.7362Z'
								/>
							)}

							<span style={{ fontWeight: 700 }}>
								{nonUniqueUsers.adEngagement.engagedPercentIncrease}
							</span>
							{t('% ') +
								t('pages:marketingCampaigns.engagement.percentIncrease', {
									calenderTime:
										filterOn === DAYS_FILTER.DAYS_7
											? t('pages:marketingCampaigns.week')
											: t('pages:marketingCampaigns.month'),
								})}
						</div>
					) : (
						<></>
					)}
					<ENGTPieChart
						width={180}
						height={90}
						colors={[`${theme.colors.engatiYellow}`, `${theme.colors.engatiOrange2}`]}
						data={adTypesData(nonUniqueUsers)}
						accessor={(data: IObjProps) => data.key}
						margin={{
							top: 10,
							right: 20,
							bottom: 10,
							left: 15,
						}}
						textColor={theme.colors.primaryA}
						background={theme.colors.inputFillPrimary}
					/>
				</div>
			</InfoCardWithIcon>

			{!isPartnerCustomer && (
				<InfoCardWithIcon
					title={<strong>{t('pages:marketingCampaigns.engagement.reach')}</strong>}
					value={nonUniqueUsers?.adEngagement?.reachCount || 0}
					marginTop={showReachPercentIncrease ? '' : '2rem'}
				>
					{showReachPercentIncrease ? (
						<div className={css(styles.infoCardCss)}>
							{nonUniqueUsers.adEngagement.reachPercentIncrease > 0 ? (
								<FilledUpCaret
									fillColor={theme.colors.dotStatusFill}
									height={12}
									width={14}
									pathToDraw='M8.0752 1.24658C7.55424 0.497235 6.44576 0.497235 5.92481 1.24658L0.236574 9.42852C-0.36707 10.2968 0.254272 11.4855 1.31177 11.4855L12.6882 11.4855C13.7457 11.4855 14.3671 10.2968 13.7634 9.42852L8.0752 1.24658Z'
								/>
							) : (
								<FilledDownCaret
									fillColor={theme.colors.dotPendingFill}
									height={12}
									width={14}
									pathToDraw='M8.0752 10.7362C7.55424 11.4855 6.44576 11.4855 5.92481 10.7362L0.236574 2.55426C-0.36707 1.68598 0.254272 0.497235 1.31177 0.497235L12.6882 0.497235C13.7457 0.497235 14.3671 1.68598 13.7634 2.55426L8.0752 10.7362Z'
								/>
							)}

							<span style={{ fontWeight: 700 }}>{nonUniqueUsers.adEngagement.reachPercentIncrease}</span>
							{t('% ') +
								t('pages:marketingCampaigns.engagement.percentIncrease', {
									calenderTime:
										filterOn === DAYS_FILTER.DAYS_7
											? t('pages:marketingCampaigns.week')
											: t('pages:marketingCampaigns.month'),
								})}
						</div>
					) : (
						<div style={{ color: `${theme.colors.primaryA}`, marginTop: '4rem' }}>
							{t('pages:marketingCampaigns.engagement.noTrendAvailable')}
						</div>
					)}
				</InfoCardWithIcon>
			)}
		</div>
	);

	const showUserHistory = () => {
		const formatDate = (dateString: string) => {
			const [startYear, startMonth, startDay, endYear, endMonth, endDay] = dateString.split('-');

			return `${startDay}/${startMonth} - ${endDay}/${endMonth}`;
		};

		const weekDataKey = (users: string) => {
			const noOfWeeks = 4;

			return Object.keys(nonUniqueUsers?.adEngagementMap).length === noOfWeeks ? formatDate(users) : users;
		};

		const formatDataForStackBar = () =>
			Object.entries(nonUniqueUsers.adEngagementMap).map(([key, userHistory]: any) => {
				const data = {
					text: weekDataKey(key),
					[t('pages:marketingCampaigns.engagement.engaged')]: userHistory?.engagedCount,
				};

				if (!isPartnerCustomer) {
					data[t('pages:broadcast.clickToWhatsappAds.reach')] = userHistory?.totalCount;
				}

				return data;
			});

		const formatDataForLineGraph = () =>
			Object.entries(nonUniqueUsers?.adEngagementMap).map(([key, value]: any) => ({
				date: key,
				du: value.engagementRate,
			}));

		return (
			<div className={css({ height: '19.563rem' })}>
				<div style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
					<HeadingLevel>
						<ParagraphMedium
							color={theme.colors.primaryA}
							className={css({
								...styles.paragraphCss,
								display: 'flex',
							})}
						>
							{t('pages:broadcast.clickToWhatsappAds.usersHistory')}
							{nonUniqueUsers?.lastUpdatedOn && (
								<div className={css({ fontWeight: 400, fontSize: '0.875rem', alignItems: 'center' })}>
									{t('components:conversations.lastUpdatedOn')}
									<span style={{ marginLeft: '0.2rem' }}>
										{`${t('common:standardDateTimeFormatterWithShortMonthNameAndTimeAheadOfDate', {
											date: nonUniqueUsers.lastUpdatedOn,
										})} (${profileData.timezone})`}
									</span>
								</div>
							)}
						</ParagraphMedium>
					</HeadingLevel>
				</div>

				<ParentSize className={css({ marginTop: '0.75rem', paddingBottom: '7rem' })}>
					{({ width, height }) => {
						const keys = [t('pages:marketingCampaigns.engagement.engaged')];
						if (!isPartnerCustomer) {
							keys.push(t('pages:broadcast.clickToWhatsappAds.reach'));
						}

						return (
							<ENGTBarGroupY
								width={width || 917}
								height={400}
								keys={keys}
								barRadius={0}
								colors={[`${theme.colors.royalBlue}`, `${theme.colors.raspberry}`]}
								textColor={theme.colors.primaryA}
								background={theme.colors.inputFillPrimary}
								axisBottomLeft={-40}
								data={formatDataForStackBar().reverse()}
								accessor={(data: IObjProps) => data.text}
								margin={{
									top: 80,
									right: 50,
									bottom: 30,
									left: 70,
								}}
								padding={{
									inner: 0.2,
									outer: 2.5,
								}}
								xFormatter={(data: IObjProps) => data}
							/>
						);
					}}
				</ParentSize>

				{!isPartnerCustomer && (
					<>
						<ParagraphMedium
							color={theme.colors.primaryA}
							className={css({ marginTop: '0.5rem', marginBottom: '0.3125rem', fontWeight: '700' })}
						>
							{t('pages:marketingCampaigns.engagement.engagementRate')}
						</ParagraphMedium>
						<ParentSize
							className={css({
								marginTop: isSmallDesktopScreen ? '2rem' : '0.5rem',
								paddingBottom: '8rem',
							})}
						>
							{({ width, height }) => (
								<ENGTLinePath
									width={width}
									height={400}
									data={formatDataForLineGraph().reverse()}
									xAccessor={(data: IObjProps) => weekDataKey(data.date)}
									background={theme.colors.inputFillPrimary}
									axisBottomLeft={-40}
									margin={{
										top: 80,
										left: 70,
										bottom: 30,
										right: 70,
									}}
									paths={[
										{
											stroke: 4,
											color: `${theme.colors.engatiYellow}`,
											key: 'engagementRate',
											yAccessor: (data: IObjProps) => data.du,
											labelFormat: t('pages:marketingCampaigns.engagement.engagementRate'),
										},
									]}
								/>
							)}
						</ParentSize>
					</>
				)}
			</div>
		);
	};

	return (
		<>
			{leadSource}
			<HorizontalDivider
				height='0.5px'
				overrides={{ width: '100%', borderTop: '1.5px solid', marginTop: '1.5rem' }}
				color={theme.colors.accent100}
				borderColor={theme.colors.accent100}
			/>

			{nonUniqueUsers?.adEngagement?.engagedCount ? (
				showUserHistory()
			) : (
				<NoDataTemplate
					heading={t('pages:broadcast.analytics.noData')}
					bannerImage={{
						altText: 'No Graph Available Icon',
						imageUrl: NoGraphIcon,
					}}
				/>
			)}
		</>
	);
};

export default CTWAEngagement;
