import { useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useBannerVisiblityFinder } from 'components/UI/ENGTBanner/ENGTBannerContext';
import Loader from 'components/UI/Loader/Loader';

import { getBannerExcludedPageHeight } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import { isShopifyEnabledAcc } from 'store/App/User/selectors';
import { botRefSelector } from 'store/Bot/selectors';

import BackToBots from './components/BackToBots';
import CommonNavigation from './module/Common';
import ShopifyNavigation from './module/Shopify';

const PrimaryNavigation = () => {
	const [css, theme]: any = useStyletron();
	const location = useLocation();
	const { isSmallDesktopScreen } = useResponsiveSize();

	const bannerHeight = useBannerVisiblityFinder();
	const path = location.pathname.split('/')[1];
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const botRef = useSelector(botRefSelector);

	const blockBorderCss = css({
		textAlign: isSmallDesktopScreen ? 'left' : 'center',
		height: isSmallDesktopScreen ? 'unset' : getBannerExcludedPageHeight(bannerHeight),
		borderRightWidth: isSmallDesktopScreen ? 0 : '1px',
		borderTopWidth: '0',
		borderRightStyle: 'solid',
		borderBottomWidth: '0',
		borderLeftWidth: '0',
		borderColor: theme.navigationBorderColor,
		borderOpacity: 0.4,
		overflow: 'auto',
		paddingTop: '1rem',
	});

	const renderPrimaryNav = () =>
		isShopifyEnabled ? (
			<ShopifyNavigation path={path} />
		) : (
			<CommonNavigation path={path} setIsLoading={setIsLoading} />
		);

	if (!botRef) {
		return <></>;
	}

	return (
		<Block className={blockBorderCss} margin={isSmallDesktopScreen ? '0 1rem' : '0'}>
			{isLoading ? (
				<Loader loaderBlockHeight='100%' />
			) : (
				<>
					<BackToBots />
					{renderPrimaryNav()}
				</>
			)}
		</Block>
	);
};

export default PrimaryNavigation;
