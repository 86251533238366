import { useStyletron } from 'baseui';

import { QUICK_START_GUIDE } from 'shared/consts/QAConsts';
import { WebStorageKeys } from 'shared/enum';
import { deleteKeyFromSessionStorage } from 'shared/helpers';

function Home({ size, fillColor }: { size?: number; fillColor?: string; strokeColor?: string }) {
	const [theme]: [any, any] = useStyletron();

	return (
		<svg
			width={size || 32}
			height={size || 32}
			data-qa={QUICK_START_GUIDE}
			viewBox='0 0 33 32'
			fill='none'
			onClick={() => deleteKeyFromSessionStorage(WebStorageKeys.QUICK_NAV_NAVIGATION_FROM)}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='0.8'>
				<path
					d='M16.7414 8.01756C14.5008 8.2304 12.5815 9.02748 10.9584 10.4297C9.11839 12.0155 7.97097 14.1063 7.57459 16.5977C7.47445 17.2529 7.47445 18.7552 7.57876 19.4188C7.88752 21.3885 8.65942 23.087 9.9195 24.5518C11.5134 26.4089 13.7415 27.6025 16.2407 27.9488C16.754 28.0198 18.2352 28.0156 18.7442 27.9447C21.6566 27.544 24.1308 26.0292 25.7497 23.6629C27.6899 20.8252 28.0404 17.2278 26.6844 14.0479C25.9292 12.2742 24.6357 10.7343 22.9792 9.64095C21.6065 8.73536 20.0377 8.18449 18.3896 8.03426C17.964 7.9967 17.0836 7.98835 16.7414 8.01756ZM22.5703 12.75C22.7581 12.8627 22.8457 13.0546 22.8207 13.2883C22.804 13.4302 22.0947 14.966 20.2004 18.9681C17.868 23.8799 17.5801 24.4642 17.4549 24.5477C17.1795 24.7313 16.7456 24.6353 16.6204 24.364C16.4953 24.1011 15.2894 21.2759 15.2602 21.1882C15.2352 21.1006 15.3478 20.9712 16.2533 20.0615C17.3256 18.9806 17.3673 18.9222 17.3047 18.63C17.2672 18.4673 17.0335 18.2336 16.8708 18.196C16.5787 18.1334 16.5203 18.1752 15.4396 19.2477C14.53 20.1533 14.4007 20.2659 14.3131 20.2409C14.2255 20.2117 11.4007 19.0056 11.1379 18.8804C10.8666 18.7552 10.7707 18.3212 10.9543 18.0458C11.0377 17.9206 11.6511 17.6201 16.5745 15.2873C22.5036 12.4787 22.2783 12.5705 22.5703 12.75Z'
					fill={fillColor || theme.colors.primaryA}
				/>
			</g>
		</svg>
	);
}

export default Home;
