import { useSelector } from 'react-redux';

import { readFromLs, writeToLs } from 'shared/consts/localStorageConsts';
import { getDefaultBroadcastColumnKey } from 'shared/helpers/campaignHelpers';

import { botRefSelector } from 'store/Bot/selectors';
import { API } from 'store/Campaign/api';
import { customerIdSelector, userId } from 'store/Users/selectors';

interface IDefaultColumns {
	setColumns: Function;
	setDefaultColumns: Function;
}

function useDefaultColumnsHook({ setColumns, setDefaultColumns }: IDefaultColumns) {
	const uid = useSelector(userId);
	const botRef = useSelector(botRefSelector);
	const cid = useSelector(customerIdSelector);

	const getDefaultColumns = () => {
		const columnConfigKey = getDefaultBroadcastColumnKey(cid, uid, botRef);
		const localStorageResult = readFromLs(columnConfigKey);

		API.getColumns({ workflow: 'broadcast_analytics', include_deleted_attributes: false }).then(
			(response: any) => {
				if (response.data) {
					if (localStorageResult) {
						setDefaultColumns(JSON.parse(localStorageResult));
					} else {
						setDefaultColumns(response.data.default_columns);
						writeToLs(columnConfigKey, JSON.stringify(response.data.default_columns));
					}
					setColumns(response.data.columns[0]);
				} else {
					// TODO: error message
				}
			},
			function errorCallback(response: any) {
				// TODO: error message
			}
		);
	};

	return { getDefaultColumns };
}

export default useDefaultColumnsHook;
