import { useSelector } from 'react-redux';

import {
	ENGATI_ROLES,
	NAVIGATION,
	NAVIGATION_ACCESS_MAP,
	QUICK_GUIDE_ACCESS_ROLES,
	SHOPIFY_NAVIGATION,
	SHOPIFY_NAVIGATION_ACCESS_MAP,
	SHOPIFY_ROUTES,
} from 'shared/consts/consts';
import { IObjProps, IPrimaryNavItems, IPrimaryShopifyNavItems } from 'shared/consts/types';

import {
	isShopifyAcceleratorEnabled,
	isShopifyEnabledAcc,
	isWhatsappAcceleratorEnabled,
	isWooCommerceAcceleratorEnabled,
	quickGuideUpsellEnabled,
} from 'store/App/User';
import { RootState } from 'store/rootReducer';

const useNavigationHook = (path: string) => {
	const leftPanelsV2Data: any = useSelector((state: RootState) => state.User.botAdmin.data.left_panels_v2_keys) || [];
	const isEngatiUser = useSelector((state: RootState) => state.User.botAdmin.data.is_engati_brand);
	const roleName = useSelector((state: RootState) => state.User.profile.data.roleName);
	const isQuickGuideAccess = QUICK_GUIDE_ACCESS_ROLES.includes(roleName);
	const isWhatsappAccelerator = useSelector(isWhatsappAcceleratorEnabled);
	const isWooCommerceAccelerator = useSelector(isWooCommerceAcceleratorEnabled);
	const isShopifyAccelerator = useSelector(isShopifyAcceleratorEnabled);
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const isQuickGuideUpsellEnabled = useSelector(quickGuideUpsellEnabled);
	const isAgent = useSelector(
		(state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT
	);

	const aliasPath: IObjProps = {
		home: 'bot',
		messages: 'allmessages',
	};

	const isActive = (item: string) => {
		const hasMessagesAliasPath = path === 'allmessages' || path === 'social-inbox';
		const hasHomeAliasPath = path === 'wa-quick-guide';

		return (
			aliasPath[item] === path ||
			(item === 'messages' && hasMessagesAliasPath) ||
			(item === 'home' && hasHomeAliasPath && (isWhatsappAccelerator || !isQuickGuideUpsellEnabled)) ||
			(item === 'waQuickNavigator' && hasHomeAliasPath)
		);
	};

	const isActiveCommonPath = (item: string) => isActive(item) || item === path;

	const isActiveShopifyPath = (item: string) => isActive(item) || (SHOPIFY_ROUTES as any)[item] === path;

	const isQuickGuideRoute = (navItem: IPrimaryNavItems | IPrimaryShopifyNavItems) => {
		const isQuickGuide = navItem === 'quickNavigator' || navItem === 'quickGuide';

		const isRestrictedPlan = isWhatsappAccelerator || isWooCommerceAccelerator || isShopifyAccelerator;

		return isQuickGuide && isQuickGuideAccess && isEngatiUser && !isRestrictedPlan;
	};

	const commonLeftNavAccess = (navItem: IPrimaryNavItems) => {
		const isWaQuickNavigator = navItem === 'waQuickNavigator';
		const hasAccess = leftPanelsV2Data.indexOf(NAVIGATION_ACCESS_MAP[navItem]) > -1;

		return isQuickGuideRoute(navItem) || (isWaQuickNavigator ? !isWhatsappAccelerator && hasAccess : hasAccess);
	};

	const shopifyLeftNavAccess = (navItem: IPrimaryShopifyNavItems) =>
		isQuickGuideRoute(navItem) || leftPanelsV2Data.indexOf(SHOPIFY_NAVIGATION_ACCESS_MAP[navItem]) > -1;

	const hasLeftNavAccess = (navItem: IPrimaryNavItems | IPrimaryShopifyNavItems) =>
		isShopifyEnabled
			? shopifyLeftNavAccess(navItem as IPrimaryShopifyNavItems)
			: commonLeftNavAccess(navItem as IPrimaryNavItems);

	const navigationFiltered: Array<any> = [];

	(isShopifyEnabled ? SHOPIFY_NAVIGATION : NAVIGATION).forEach((navArr: any) => {
		let showDivider = false;

		navArr.forEach((navItem: any) => {
			if (hasLeftNavAccess(navItem)) {
				if (isAgent && navItem === 'home') {
					return;
				}
				showDivider = true;
				navigationFiltered.push(navItem);
			}
		});

		if (showDivider) {
			navigationFiltered.push('|');
		}
	});

	if (navigationFiltered.lastIndexOf('|') === navigationFiltered.length - 1) {
		navigationFiltered.splice(-1);
	}

	return { isActiveCommonPath, isActiveShopifyPath, navigations: navigationFiltered };
};

export default useNavigationHook;
