import React, { useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useFormContext, useWatch } from 'react-hook-form';

import ENGTTimePicker, { IENGTTimePickerProps } from 'components/UI/ENGTTimePicker/ENGTTimePicker';

import { IObjProps } from 'shared/consts/types';
import { getErrorMessage } from 'shared/helpers';

import FormLabel from '../FormLabel/FormLabel';

interface IFormTimePickerProps {
	defaultValue?: string;
	inputRef: any;
	label?: string;
	tooltip?: string;
	name: string;
	labelClassName?: IObjProps;
	modal?: boolean;
	customError?: IObjProps;
	marginBottom?: string;
}

const FormTimePicker = (props: IENGTTimePickerProps & IFormTimePickerProps) => {
	const {
		customError,
		defaultValue,
		label,
		tooltip,
		labelClassName,
		marginBottom = '1.25rem',
		modal,
		name,
		...inputProps
	} = props;

	const [css] = useStyletron();

	const LabelWrapper = () =>
		label && (
			<FormLabel id={label} label={label} tooltip={tooltip} className={labelClassName} modal={modal || false} />
		);

	const { control, errors, setValue } = useFormContext();

	useEffect(() => {
		defaultValue && setValue && setValue(name, defaultValue);
	}, [defaultValue]);

	useWatch({
		control,
		name,
	});

	const errorMsg = getErrorMessage(name, errors, true) ? getErrorMessage(name, errors, true) : errors[name];

	return (
		<div
			className={css({
				width: '100%',
				textAlign: 'left',
				marginBottom,
			})}
		>
			{LabelWrapper()}
			<ENGTTimePicker error={customError?.message ? customError?.message : errorMsg} {...inputProps} />
		</div>
	);
};

export default FormTimePicker;
