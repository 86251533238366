import React from 'react';
import { useStyletron } from 'baseui';
import { ARTWORK_SIZES, ListItem } from 'baseui/list';
import { NavLink } from 'react-router-dom';

import { LabelWithTooltip } from 'components/UI/LabelWithToolTip/LabelWithTooltip';

interface IListItemWrapperProps {
	linkTo?: string;
	children?: React.ReactNode;
	handleClick?: () => any;
}

interface IListItemWithIconProps {
	iconElement: any;
	iconHeight?: string;
	iconWidth?: string;
	itemLabel: string;
	dataQa?: string;
}

const listItemOverrideProps = ({
	iconHeight,
	iconWidth,
	theme,
}: {
	iconHeight: string;
	iconWidth: string;
	theme: any;
}) => ({
	Root: {
		style: {
			background: theme.colors.backgroundPrimary,
			paddingTop: '0.25rem',
			paddingBottom: '0.25rem',
			minHeight: iconHeight,
			color: theme.colors.primaryA,
		},
	},
	Content: {
		style: {
			borderBottomColor: 'transparent',
			height: 0,
		},
	},
	ArtworkContainer: {
		style: {
			width: iconWidth,
			height: iconHeight,
			marginRight: '0.5rem',
			borderRadius: '8rem',
			backgroundColor: 'rgba(232, 230, 231, 0.4)',
		},
	},
});

const ListItemWrapper = ({ children, linkTo = '', handleClick }: IListItemWrapperProps) => {
	const [css] = useStyletron();
	if (linkTo?.includes('http')) {
		return (
			<a
				href={linkTo}
				onClick={handleClick}
				target='_blank'
				className={css({ textDecoration: 'none' })}
				rel='noreferrer'
			>
				{children}
			</a>
		);
	}

	return linkTo ? (
		<NavLink to={linkTo || '/bots'} onClick={handleClick} className={css({ textDecoration: 'none' })}>
			{children}
		</NavLink>
	) : (
		<div className={css({ cursor: 'pointer' })} onClick={handleClick}>
			{children}
		</div>
	);
};

export const ListItemWithIcon = ({
	iconElement: IconElement,
	iconHeight = '2rem',
	iconWidth = '2rem',
	itemLabel,
	dataQa = 'fgh',
	...props
}: IListItemWithIconProps & IListItemWrapperProps) => {
	const [, theme]: [any, any] = useStyletron();

	return (
		<ListItemWrapper {...props}>
			<ListItem
				artwork={IconElement}
				artworkSize={ARTWORK_SIZES.SMALL}
				overrides={listItemOverrideProps({
					iconHeight,
					iconWidth,
					theme,
				})}
			>
				<LabelWithTooltip fontWeight='normal' fontSize='14px' color={theme.colors.primaryA} dataQa={dataQa}>
					{itemLabel}
				</LabelWithTooltip>
			</ListItem>
		</ListItemWrapper>
	);
};
