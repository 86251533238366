import { IObjProps } from 'shared/consts/types';

import { IBotsDetailStateInterface } from './state';

export const botReducer = {
	getBotConfigDetails: (state: IBotsDetailStateInterface, action: { payload: IObjProps }) => {
		state.configData = action.payload;
	},
	getBotConfiguredChannels: (state: IBotsDetailStateInterface, action: { payload: Array<string> }) => {
		state.configuredChannels = action.payload;
	},
	getBotDetails: (state: IBotsDetailStateInterface, action: { payload: IObjProps }) => {
		state.data = action.payload;
		state.error = false;
		state.loading = false;
	},
	getBotDetailsError: (state: IBotsDetailStateInterface) => {
		state.data = [];
		state.error = false;
		state.loading = false;
	},
	updateBotDetailsLoading: (state: IBotsDetailStateInterface) => {
		state.loading = true;
	},
	updateOnlineStatus: (state: IBotsDetailStateInterface, action: { payload: boolean }) => {
		state.configData.livechat_agent_online = action.payload;
	},
	getGeneralSettings: (state: IBotsDetailStateInterface, action: { payload: IObjProps }) => {
		state.generalBotConfigData = { ...action.payload };
		state.error = false;
		state.loading = false;
	},
	updateGeneralSettings: (state: IBotsDetailStateInterface, action: { payload: IObjProps }) => {
		state.generalBotConfigData = { ...action.payload };
		state.error = false;
		state.loading = false;
	},
	getVideoConfigurations: (state: IBotsDetailStateInterface, action: { payload: IObjProps }) => {
		state.videoConfigData = { ...action.payload };
	},
	setEmailStatus: (state: IBotsDetailStateInterface, action: { payload: boolean }) => {
		state.data.testEmailStatus = action.payload;
	},
	setGoogleAnalyticsSettingsEnabled: (state: IBotsDetailStateInterface, action: { payload: boolean }) => {
		state.googleAnalyticsSettingsEnabled = action.payload;
	},
	setAgentOnlineStatus: (state: IBotsDetailStateInterface, action: { payload: boolean }) => {
		state.agentOnlineStatus = action.payload;
	},
	updateDebugLogCaptureEndTime: (state: IBotsDetailStateInterface, action: { payload: IObjProps }) => {
		state.data = { ...action.payload };
	},
};
