import { useStyletron } from 'baseui';
import { Label4 } from 'baseui/typography';

import RegistrationPasswordErrorIcon from 'shared/icons/RegistrationPasswordErrorIcon.svg';

export interface ILoginInputErrorProps {
	text: any;
}

function LoginInputError({ text }: ILoginInputErrorProps) {
	const [css, theme] = useStyletron();

	const wrapperCss: any = {
		display: 'flex',
		marginLeft: '0.5rem',
		alignItems: 'center',
		justifyContent: 'flex-start',
		width: '14rem',
		maxWidth: '16rem',
	};

	const labelCss: any = {
		marginLeft: '0.563rem',
		fontSize: '1rem !important',
		color: theme.colors.accent,
		textAlign: 'left',
	};

	return (
		<div className={css(wrapperCss)}>
			<img src={RegistrationPasswordErrorIcon} width={21} alt='registration-passowrd-error' />
			<Label4 className={css(labelCss)}>{text}</Label4>
		</div>
	);
}
export default LoginInputError;
