import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionModalWithInput from 'components/UI/Modal/ActionModalWithInput/ActionModalWithInput';

import { IObjProps } from 'shared/consts/types';

import useEditCampaignHook from '../hooks/useEditCampaignHook';
import useIsCampaignAnalyticsEnabledHook from '../hooks/useIsCampaignAnalyticsEnabledHook';
import useSaveCampaignHook from '../hooks/useSaveCampaignHook';

interface ICampaignDetailsModal {
	isEdit: boolean;
	selectedCampaign: IObjProps;
	toggleCampaignModal: Function;
	setSelectedCampaign: Function;
}

function CampaignDetailsModal({
	isEdit,
	toggleCampaignModal,
	selectedCampaign,
	setSelectedCampaign,
}: ICampaignDetailsModal) {
	const { t } = useTranslation(['common', 'pages']);

	const [isLoading, setLoading] = useState<boolean>(false);
	const [editCampaign] = useEditCampaignHook({ selectedCampaign, setSelectedCampaign, setLoading });
	const [saveCampaign] = useSaveCampaignHook({ setLoading });
	const showWhatsappCampaignAnalytics = useIsCampaignAnalyticsEnabledHook();

	return (
		<ActionModalWithInput
			closeBtnLabel={t('common:cancel')}
			confirmBtnLabel={t('common:save')}
			description=''
			isLoading={isLoading}
			heading={
				isEdit ? t('pages:broadcast.campaigns.editCampaign') : t('pages:broadcast.campaigns.createNewCampaign')
			}
			isOpen
			isInputRequired
			toggleModal={() => toggleCampaignModal(false)}
			placeholder={t('pages:broadcast.campaigns.enterName')}
			onClose={() => toggleCampaignModal(false)}
			maxLength={50}
			onConfirm={(data) => {
				if (selectedCampaign && Object.keys(selectedCampaign).length === 0) {
					saveCampaign(data);
				} else {
					editCampaign(data);
				}
			}}
			usage='campaignCreation'
			defaultInput={
				showWhatsappCampaignAnalytics ? selectedCampaign?.campaignName : selectedCampaign?.campaign_name
			}
		/>
	);
}

export default CampaignDetailsModal;
