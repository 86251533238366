import React, { useEffect } from 'react';
import { mergeOverrides, useStyletron } from 'baseui';
import { FILL, Tabs } from 'baseui/tabs-motion';

import { IObjProps } from 'shared/consts/types';

export interface IENGTTabsProps {
	activeTab: string;
	children: Array<JSX.Element | any>;
	fill?: FILL.fixed | FILL.intrinsic;
	renderAll?: boolean;
	tabOverrides?: IObjProps;
	isFullWidth?: boolean;
	onChange?: Function;
}

export const tabsDefaultOverride = (custom: IObjProps = {}) =>
	mergeOverrides(
		{
			Root: {
				style: {
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					overflow: 'hidden',
				},
			},
			TabPanel: {
				style: ({ hidden }: any) => ({
					display: 'flex',
					flexDirection: 'column',
					paddingTop: hidden ? '0rem' : '1rem',
					paddingRight: '0rem',
					paddingLeft: '0rem',
					paddingBottom: '0rem',
					overflowY: 'auto',
					overflowX: 'hidden',
					flexGrow: hidden ? 0 : 1,
				}),
			},
			Tab: {
				style: {
					paddingRight: '0rem',
					paddingLeft: '0rem',
					flex: '1 1 0rem',
					':hover': {
						background: 'none !important',
					},
					':focus': {
						fontWeight: 'bold !important' as any,
					},
					lineHeight: '1.25rem',
				},
			},
			TabList: {
				style: {
					minHeight: '2rem',
				},
			},
		},
		custom
	);

function ENGTTabs(props: IENGTTabsProps) {
	const { activeTab, onChange, fill = FILL.fixed, renderAll, tabOverrides = {}, isFullWidth } = props;
	const [, theme]: any = useStyletron();

	const tabsOverrideProps = {
		Root: {
			style: () => ({
				...tabOverrides?.Root?.style,
			}),
		},
		TabBorder: {
			style: {
				height: '0px',
				...tabOverrides?.TabBorder?.style,
			},
		},
		TabHighlight: {
			style: {
				height: '2px',
				bottom: '0px',
			},
		},
		TabList: {
			style: {
				borderBottomWidth: '1px',
				borderBottomStyle: 'solid',
				borderColor: theme.navigationBorderColor,
				borderOpacity: 0.4,
				width: isFullWidth ? '100%' : '50%',
				...tabOverrides?.TabList?.style,
			},
		},
	};

	const tabList = props.children.map((element) =>
		element
			? React.cloneElement(element, {
					overrides: {
						...tabOverrides,
						Tab: {
							style: ({ $isActive, $theme }: { $isActive: boolean; $theme: any }) => ({
								':hover': {
									background: 'none',
								},
								fontWeight: $isActive ? 'bold' : 'normal',
								color: $isActive ? $theme.colors.primaryA : $theme.colors.accent50,
								height: '0.1rem',
								...tabOverrides?.Tab?.style,
							}),
							props: {
								'data-qa': element.key,
							},
						},
					},
				})
			: ''
	);

	const [activeKey, setActiveKey] = React.useState(activeTab);

	useEffect(() => {
		setActiveKey(activeTab);
	}, [activeTab]);

	return (
		<>
			<Tabs
				activeKey={activeKey}
				onChange={({ activeKey }) => {
					const response = onChange && onChange(activeKey);
					if (!response) {
						setActiveKey(activeKey.toString());
					}
				}}
				renderAll={renderAll}
				fill={fill}
				overrides={tabsOverrideProps}
			>
				{tabList}
			</Tabs>
		</>
	);
}

ENGTTabs.defaultProps = {
	isFullWidth: true,
};
export default ENGTTabs;
