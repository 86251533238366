import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { TriangleLeft } from 'baseui/icon';
import { Label4 } from 'baseui/typography';
import { useTranslation } from 'react-i18next';

import { validPassword } from 'shared/helpers';
import wrong from 'shared/icons/ic_password_wrong.svg';
import RegistrationPasswordErrorIcon from 'shared/icons/RegistrationPasswordErrorIcon.svg';

export interface INewPasswordWidgetProps {
	value: any;
}
function NewPasswordWidget({ value }: INewPasswordWidgetProps) {
	const { t } = useTranslation(['common', 'components', 'errors']);

	const [css, theme] = useStyletron();

	const [isAllChecksValid, setAllChecksValid] = useState<boolean>(false);
	const [passwordRules, setPasswordRules] = useState({
		isValidLength: false,
		hasNumber: false,
		hasLetter: false,
		hasUpperCaseLetter: false,
		specialChar: false,
	});

	useEffect(() => {
		const tempPasswordRules: any = {
			isValidLength: false,
			hasNumber: false,
			hasLetter: false,
			hasUpperCaseLetter: false,
			specialChar: false,
		};

		value && setAllChecksValid(validPassword(value));
		if (value) {
			tempPasswordRules.hasNumber = !!value.match(/\d/);
			tempPasswordRules.hasUpperCaseLetter = !!value.match(/[A-Z]/);
			tempPasswordRules.hasLetter = !!value.match(/[a-z]/);
			tempPasswordRules.isValidLength = !!value.match(/^.{8,}$/);
			tempPasswordRules.specialChar = !!value.match(/\W|_/);
		} else {
			tempPasswordRules.hasNumber = false;
			tempPasswordRules.hasUpperCaseLetter = false;
			tempPasswordRules.hasLetter = false;
			tempPasswordRules.isValidLength = false;
			tempPasswordRules.specialChar = false;
		}
		setPasswordRules(tempPasswordRules);
	}, [value]);

	const wrapperCss: any = {
		display: 'flex',
		marginLeft: '0.25rem',
	};

	const ruleWrapperCss: any = {
		paddingLeft: '0.5rem',
		paddingTop: '0.5rem',
		paddingRight: '0.5rem',
		paddingBottom: '0.5rem',
		width: '8.813rem',
		backgroundColor: theme.colors.primaryB,
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: !isAllChecksValid ? theme.colors.inputBorderError : 'rgba(64, 63, 66, 0.4)',
		borderRadius: '0.375rem',
	};

	const labelCss = {
		fontSize: '0.75rem !important',
	};

	const labelWrapperCss: any = {
		display: 'flex',
		lineHeight: '1rem',
	};

	const iconCss: any = {
		height: '1.313rem',
	};

	const blockCss: any = {
		display: 'flex',
	};

	const imageWrapperCss: any = {
		width: '1rem',
	};

	const renderChecks = (condition: boolean, label: string) => (
		<div className={css(labelWrapperCss)}>
			<div className={css(imageWrapperCss)}>{condition && <img src={wrong} />}</div>

			<Label4
				className={css({
					color: condition ? theme.colors.primaryA : theme.colors.accent,
					...labelCss,
				})}
			>
				{label}
			</Label4>
		</div>
	);

	return (
		<div className={css(wrapperCss)}>
			{!isAllChecksValid && (
				<>
					<div className={css(iconCss)}>
						{!isAllChecksValid && <img src={RegistrationPasswordErrorIcon} width={21} />}
					</div>
					<div className={css(blockCss)}>
						<TriangleLeft
							color={!isAllChecksValid ? theme.colors.inputBorderError : 'rgba(64, 63, 66, 0.4)'}
							size={24}
							overrides={{
								Svg: {
									style: ({ $theme }) => ({
										position: 'relative',
										left: '0.625rem',
									}),
								},
							}}
						/>
						<div className={css(ruleWrapperCss)}>
							{renderChecks(
								passwordRules.isValidLength,
								t('shopify:onboarding.registration.passwordRules.minLength')
							)}
							{renderChecks(
								passwordRules.hasUpperCaseLetter,
								t('shopify:onboarding.registration.passwordRules.hasUpperCaseLetter')
							)}
							{renderChecks(
								passwordRules.hasLetter,
								t('shopify:onboarding.registration.passwordRules.hasLetter')
							)}
							{renderChecks(
								passwordRules.hasNumber,
								t('shopify:onboarding.registration.passwordRules.hasNumber')
							)}
							{renderChecks(
								passwordRules.specialChar,
								t('shopify:onboarding.registration.passwordRules.specialChar')
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
}
export default NewPasswordWidget;
