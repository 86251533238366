import {
	GROUP_CONVERSATION_STATES,
	MESSAGE_STATE_MAP,
	MESSAGE_TAB_HEADERS_MAP,
	ONE_VIEW_LITE,
} from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';

import { TwoColumnRouteLinks } from 'router/links/TwoColumnRouteLinks';

export const getTaggingRoute = (activeState: any) => {
	if (activeState === MESSAGE_STATE_MAP.ENGAGING) {
		return MESSAGE_TAB_HEADERS_MAP.active;
	}
	if (activeState === MESSAGE_STATE_MAP.ASSIGNED) {
		return MESSAGE_TAB_HEADERS_MAP.new;
	}

	return MESSAGE_TAB_HEADERS_MAP.all;
};

export const getUserFromList = (users: Array<IObjProps>, conversationId: string) => {
	for (let i = 0; i < users.length; i++) {
		if (users[i].conversation_id === conversationId) {
			return users[i];
		}
	}

	return {};
};

export const filterUsersByConversationId = (conversationId: string, getState: any) => {
	const users = Array.isArray(getState().Message.userList.data)
		? JSON.parse(JSON.stringify(getState().Message.userList.data))
		: [];

	return users.filter((user: IObjProps) => user.conversation_id !== conversationId);
};

export function currentTab() {
	const location = window.location.pathname;

	if (location.indexOf('/messages/active') !== -1) {
		return MESSAGE_TAB_HEADERS_MAP.active;
	}

	if (location.indexOf('/messages/unassigned') !== -1) {
		return MESSAGE_TAB_HEADERS_MAP.unassigned;
	}

	if (location.indexOf('/messages/new') !== -1) {
		return MESSAGE_TAB_HEADERS_MAP.new;
	}

	if (location.indexOf('/messages/all') !== -1) {
		return MESSAGE_TAB_HEADERS_MAP.all;
	}

	if (location.indexOf('/allmessages') !== -1) {
		return ONE_VIEW_LITE;
	}

	return null;
}

export function constructMessageUrl(agent: IObjProps, conversationId: string, isSimplifiedOneViewEnabled: boolean) {
	if (agent.state === GROUP_CONVERSATION_STATES.invited.toLowerCase()) {
		return `${
			isSimplifiedOneViewEnabled
				? TwoColumnRouteLinks.simplifiedMessages
				: `/messages/${MESSAGE_TAB_HEADERS_MAP.new}/`
		}${conversationId}`;
	}
	if (agent.state === GROUP_CONVERSATION_STATES.joined.toLowerCase()) {
		return `${
			isSimplifiedOneViewEnabled
				? TwoColumnRouteLinks.simplifiedMessages
				: `/messages/${MESSAGE_TAB_HEADERS_MAP.active}/`
		}${conversationId}`;
	}

	return '';
}
