import { useEffect, useMemo, useState } from 'react';
import { useStyletron } from 'baseui';
import { Panel } from 'baseui/accordion';
import { PLACEMENT } from 'baseui/tooltip';
import { LabelSmall } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ENGTAccordion from 'components/UI/ENGTAccordion/ENGTAccordion';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import FilterDropdown from 'components/UI/FilterDropdown/FilterDropdown';

import { CHANNEL_ICON_MAPPING, CHANNEL_NAME_ICON_MAPPING, CHANNEL_TITLE_MAPPING } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { CHANNELS } from 'shared/enum';
import { channelLabelCss, containerCss, filterAccordionCss } from 'shared/helpers/broadcastHelpers';

import { broadcastFilterActions, IFilterAndSort, setBroadcastDataFilterAction } from 'store/Broadcast';
import { publishedFilters, publishedSort } from 'store/Broadcast/selectors';
import { RootState } from 'store/rootReducer';

interface IChannelFilter {
	applyFilters: Function;
}

const ChannelFilter = ({ applyFilters }: IChannelFilter) => {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['pages', 'components']);
	const dispatch = useDispatch<any>();

	const allConfiguredChannels = useSelector((state: RootState) => state.Bot.configuredChannels);
	const sort = useSelector(publishedSort);
	const filters = useSelector(publishedFilters);

	const currentChannelInfo = (channel: IObjProps) => (
		<div
			className={css({
				display: 'flex',
				justifyContent: 'start',
				alignItems: 'center',
			})}
		>
			<ENGTImage
				lazy
				src={CHANNEL_NAME_ICON_MAPPING[channel.id]['icon']}
				alt={t('components:addAdmin.channelUrl')}
				className={css({
					height: '1.5rem',
					width: '1.5rem',
					marginRight: '1rem',
				})}
			/>
			{channel.imageURL}
			{t(`common:platform.${channel.id}`)}
		</div>
	);

	const allChannels = {
		id: CHANNELS.ALL_CHANNELS,
		displayName: t('pages:broadcast.broadcastHistory.allChannels'),
		imageUrl: CHANNEL_ICON_MAPPING.allChannels,
	};

	const preFilterOption = currentChannelInfo(allChannels);

	const [currentChannelDetails, setCurrentChannelDetails] = useState<string | JSX.Element>(preFilterOption);
	const [expand, setExpand] = useState<boolean>(false);
	const [currentChannel, setCurrentChannel] = useState<string>('');

	const configuredChannels = useMemo(
		() => allConfiguredChannels?.filter((channel) => channel !== CHANNELS.EMAIL),
		[allConfiguredChannels]
	);

	useEffect(() => {
		setDefaultChannel();
	}, []);

	useEffect(() => {
		const { filters }: IObjProps = dispatch(broadcastFilterActions.getFiltersFromLs());
		const storedChannel = filters?.channels || CHANNELS.ALL_CHANNELS;
		!configuredChannels.includes(storedChannel) &&
			dispatch(
				setBroadcastDataFilterAction({
					filters: {
						...filters,
						channels: CHANNELS.ALL_CHANNELS,
					},
					sort,
				})
			);
	}, [configuredChannels]);

	const modifiedChannels = useMemo(() => {
		const channels: string[] = configuredChannels.map((item) => item);
		const { filters }: IObjProps = dispatch(broadcastFilterActions.getFiltersFromLs());
		const storedChannel = filters.channels;

		!channels.includes(CHANNELS.ALL_CHANNELS) &&
			storedChannel !== CHANNELS.ALL_CHANNELS &&
			channels.push(CHANNELS.ALL_CHANNELS);

		const data = Array.isArray(channels)
			? channels.map((channel: string) => ({
					id: channel,
					displayName: CHANNEL_TITLE_MAPPING[channel],
					imageUrl: CHANNEL_ICON_MAPPING.channel,
				}))
			: [];

		return data;
	}, [configuredChannels]);

	const setDefaultChannel = () => {
		const { filters }: IObjProps = dispatch(broadcastFilterActions.getFiltersFromLs());
		const storedChannelFilter = filters.channels;
		if (storedChannelFilter === CHANNELS.ALL_CHANNELS) {
			setCurrentChannelDetails(currentChannelInfo(allChannels));
		} else if (storedChannelFilter) {
			const channelStored = modifiedChannels.filter(({ id }) => id === storedChannelFilter);
			setCurrentChannelDetails(
				channelStored.length ? currentChannelInfo(channelStored[0]) : currentChannelInfo(allChannels)
			);
		} else {
			setCurrentChannelDetails(preFilterOption);
			setCurrentChannel('');
		}
	};

	const onApply = () => {
		const channelSelected = currentChannelDetails === preFilterOption ? '' : currentChannel;
		dispatch(
			setBroadcastDataFilterAction({
				filters: {
					...filters,
					channels: channelSelected,
				},
				sort,
			})
		);
		setExpand(false);
		applyFilters(channelSelected);
	};

	const onReset = () => {
		const { filters, sort }: IObjProps = dispatch(broadcastFilterActions.getFiltersFromLs());
		const filterData: IFilterAndSort = {
			filters: {
				...filters,
				channels: '',
			},
			sort,
		};
		dispatch(setBroadcastDataFilterAction(filterData));
		setCurrentChannelDetails(preFilterOption);
		setCurrentChannel('');
		setExpand(false);
		applyFilters(CHANNELS.ALL_CHANNELS);
	};

	const renderChannels = () => (
		<div className={css(containerCss(theme))}>
			{modifiedChannels.map((channel: IObjProps, index: number) => (
				<div
					key={`channel-filter-${index}`}
					onClick={() => {
						setCurrentChannelDetails(currentChannelInfo(channel));
						setCurrentChannel(channel.id);
					}}
				>
					<LabelSmall className={css(channelLabelCss(currentChannel === channel.id, theme))}>
						{currentChannelInfo(channel)}
					</LabelSmall>
				</div>
			))}
		</div>
	);

	const renderFilters = () => (
		<div className={css({ width: '48%', float: 'left' })}>
			<FilterDropdown
				rootButton={
					<ENGTAccordion
						overrides={filterAccordionCss(theme, false)}
						expandedPanel={!expand ? undefined : '0'}
					>
						<Panel title={currentChannelDetails} onClick={() => setExpand(!expand)}>
							<></>
						</Panel>
					</ENGTAccordion>
				}
				openOnClick
				onClose={() => {
					setDefaultChannel();
					setCurrentChannel('');
					setExpand(false);
				}}
				showFooter={configuredChannels.length > 0}
				ignoreBoundary
				onApply={onApply}
				bodyCss={{ zIndex: '', marginTop: '0.1rem' }}
				popoverMargin={-7}
				placement={PLACEMENT.bottomLeft}
				isBlockFilter
				onReset={onReset}
			>
				{renderChannels()}
			</FilterDropdown>
		</div>
	);

	return (
		<div className={css({ display: 'flex' })}>
			<div className={css({ width: '83.4%' })}>{renderFilters()}</div>
		</div>
	);
};

export default ChannelFilter;
