import React from 'react';
import { useStyletron } from 'baseui';
import { Paragraph3, Paragraph4 } from 'baseui/typography';

import { IObjProps } from 'shared/consts/types';

export interface IENGTNoteProps {
	/**
	 * description for note
	 */
	description: string;
	/**
	 * heading for note, ! - "Note:" label added in common.js
	 */
	heading: string;
	/**
	 * additonal CSS for wrapper of the component
	 */
	wrapperCss?: IObjProps;

	headingMarginTop?: string;

	headerFontWeight?: string;
}

const noteDescriptionCss: any = {
	Block: {
		style: {
			opacity: 0.8,
			whiteSpace: 'pre-wrap',
		},
	},
};

function ENGTNote({ heading, description, wrapperCss, headingMarginTop, headerFontWeight }: IENGTNoteProps) {
	const [css, theme]: any = useStyletron();
	const noteWrapperCss: any = {
		textAlign: 'left',
		...wrapperCss,
	};

	return (
		<div className={css(noteWrapperCss)}>
			<Paragraph3
				marginTop={headingMarginTop || '2rem'}
				className={css({ fontWeight: headerFontWeight || 'normal' })}
				marginBottom='0.125rem'
			>
				{heading}
			</Paragraph3>
			<Paragraph4 marginTop='0rem' color={theme.colors.primaryA} overrides={noteDescriptionCss}>
				{description}
			</Paragraph4>
		</div>
	);
}

export default ENGTNote;
