import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { RootState } from 'store/rootReducer';

const voidFunction = () => {};

function BotChangeDetector() {
	const { t }: any = useTranslation(['components']);

	const [botChangedWarning, setBotChangedWarning]: any = useState(false);

	const botRef = useSelector((state: RootState) => state.Bot.data.bot_ref);

	useEffect(() => {
		const onBotRefChange = (evt: StorageEvent) => {
			if (
				evt?.key === 'b_ref' &&
				evt?.oldValue &&
				evt?.newValue &&
				evt.oldValue !== evt.newValue &&
				evt.newValue !== 'undefined'
			) {
				setBotChangedWarning(botRef && parseInt(evt.newValue) !== parseInt(botRef));
			}
		};
		window.addEventListener('storage', onBotRefChange);

		return () => window.removeEventListener('storage', onBotRefChange);
	}, []);

	useEffect(() => {
		localStorage.setItem('b_ref', botRef);
	}, [botRef]);

	return botChangedWarning ? (
		<ActionModal
			isOpen={botChangedWarning}
			heading={t('components:botChangeDetector.heading')}
			description={t('components:botChangeDetector.description')}
			confirmBtnLabel={t('common:reload')}
			isCancelBtnDisabled
			onConfirm={() => window.location.reload()}
			onClose={voidFunction}
			hideCloseBtn
		/>
	) : (
		<></>
	);
}

export default BotChangeDetector;
