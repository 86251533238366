import { useState } from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';

import DeleteModal from 'components/UI/Modal/DeleteModal/DeleteModal';

import { IObjProps } from 'shared/consts/types';
import { decodeAndSanitizeInput } from 'shared/helpers';
import DeleteIconResizable from 'shared/icons/DeleteIconResizable';

function DeleteSelectedFilter({
	customFilters,
	deleteCustomFilterAPI,
}: {
	customFilters: IObjProps;
	deleteCustomFilterAPI: Function;
}) {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['components', 'pages']);

	const [isDeleteCustomFilterOpen, setDeleteCustomFilterModal] = useState<boolean>(false);
	const [customId, setCustomId] = useState<string>('');
	const [customFilterName, setCustomFilterName] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const customDescriptionForDeleting = (name: any) => (
		<p
			dangerouslySetInnerHTML={{
				__html: decodeAndSanitizeInput(
					t('pages:users.customFilters.description', {
						custom_name: name,
					})
				),
			}}
		/>
	);

	const onClickDeleteIcon = () => {
		setDeleteCustomFilterModal(true);
		setCustomId(customFilters?.id);
		setCustomFilterName(customFilters?.filter_name);
	};

	const onCloseDeleteModal = () => {
		setDeleteCustomFilterModal(false);
	};

	const onConfirmDeleteModal = () => {
		setLoading(true);
		deleteCustomFilterAPI(customId);
		setDeleteCustomFilterModal(false);
		setLoading(false);
	};

	return (
		<>
			<div className={css({ cursor: 'pointer', height: '27px' })} onClick={onClickDeleteIcon}>
				<DeleteIconResizable fill={theme.colors.primaryA} height={25} width={10} />
			</div>
			{isDeleteCustomFilterOpen && (
				<DeleteModal
					isLoading={loading}
					closeBtnLabel={t('components:messageFiltersLite.cancel')}
					isConfirmBtnDisabled={false}
					isOpen={isDeleteCustomFilterOpen}
					onClose={onCloseDeleteModal}
					onConfirm={onConfirmDeleteModal}
					confirmBtnLabel={t('common:Delete')}
					heading={t('pages:users.customFilters.header')}
					description={customDescriptionForDeleting(customFilterName)}
					hideCloseBtn
					descriptionCss={{ fontSize: '1rem' }}
				/>
			)}
		</>
	);
}
export default DeleteSelectedFilter;
