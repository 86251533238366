import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ContactSalesModal from 'components/ContactModal/ContactModal';
import ModalAlert from 'components/UI/Modal/ModalAlert/ModalAlert';

import { WA_EXPERIMENT_URL } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { BROADCAST_USAGE } from 'shared/enum';

import { RootState } from 'store/rootReducer';

export interface IBroadcastHelpers {
	isContactSupportModalOpen: boolean;
	isRetryModalOpen: boolean;
	isRetryModalLoading: boolean;
	retryBroadcastData: IObjProps;
	setRetryModal: Function;
	setContactSupportModal: Function;
	retryFailedUsers: Function;
}
interface IRetryBroadcastModal {
	broadcastId: string;
	broadcastHelpers: IBroadcastHelpers;
}

const RetryBroadcastModal = ({ broadcastId, broadcastHelpers }: IRetryBroadcastModal) => {
	const {
		isContactSupportModalOpen,
		isRetryModalOpen,
		isRetryModalLoading,
		retryBroadcastData,
		setRetryModal,
		setContactSupportModal,
		retryFailedUsers,
	} = broadcastHelpers;
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common', 'pages']);

	const [supportEmail, setSupportEmail] = useState<string>('');
	const [contactSales, setContactSales] = useState<boolean>(false);
	const supportEmailOrLink = useSelector((state: RootState) => state.User.botAdmin.data.support_email);

	const localiseRetryBroadcastModal = 'pages:broadcast.basic.retryBroadcastModal';

	const retryInfoText = () => (
		<>
			{isContactSupportModalOpen
				? t('pages:broadcast.basic.retryBroadcastDescription2')
				: t('pages:broadcast.basic.retryBroadcastDescription1')}
			{retryBroadcastData && Object.keys(retryBroadcastData?.retryMap || {}).length > 0 && (
				<div
					className={css({
						marginBottom: '0.7rem',
					})}
				/>
			)}

			<div className={css({ fontSize: '0.875rem' })}>
				{retryBroadcastData &&
					Object.keys(retryBroadcastData?.retryMap || {}).map((retryMap, index) => (
						<div
							className={css({
								marginBottom: '0.6rem',
							})}
						>
							{t('pages:broadcast.basic.retryBroadcastDescription3', {
								retryCount: retryMap,
							})}
							<b>
								{t('pages:broadcast.basic.retryBroadcastDescription4', {
									number: retryBroadcastData.retryMap[retryMap].toString(),
								})}{' '}
							</b>
						</div>
					))}
				<div className={css({ marginTop: '1rem', lineHeight: '1.125rem' })}>
					<b>{t(`${localiseRetryBroadcastModal}.pleaseNote`)}</b>
					<ul
						className={css({
							paddingLeft: '1.25rem',
							marginTop: '0rem',
						})}
					>
						<li>
							{t(`${localiseRetryBroadcastModal}.retryNote1Part1`)}
							<a
								className={css({
									cursor: 'pointer',
									color: theme.colors.primaryA,
									textDecoration: 'underline',
								})}
								href={WA_EXPERIMENT_URL}
								target='_blank'
								rel='noreferrer'
							>
								{t(`${localiseRetryBroadcastModal}.retryNote1Part2`)}
							</a>
							{t(`${localiseRetryBroadcastModal}.retryNote1Part3`)}
						</li>
						<li>{t(`${localiseRetryBroadcastModal}.retryNote2`)}</li>
					</ul>
				</div>
			</div>
		</>
	);

	const contactSupportForRetryBroadcast = () => {
		setRetryModal(false);
		setContactSupportModal(false);
		if (supportEmailOrLink?.type === 'link') {
			window.open(supportEmailOrLink.value, '_blank')?.focus();
		} else if (supportEmailOrLink?.type === 'email') {
			setSupportEmail(supportEmailOrLink.value);
			setContactSales(true);
		}
	};

	return (
		<>
			<ModalAlert
				closeBtnLabel={t('common:cancel')}
				width='25rem'
				confirmBtnLabel={isContactSupportModalOpen ? t('common:contactSupport') : t('common:confirm')}
				description={retryInfoText()}
				heading={t('pages:broadcast.basic.retryBroadcast')}
				isConfirmBtnDisabled={false}
				isLoading={isRetryModalLoading}
				isOpen={isRetryModalOpen || isContactSupportModalOpen}
				onClose={() => {
					setRetryModal(false);
					setContactSupportModal(false);
				}}
				onConfirm={() =>
					isContactSupportModalOpen ? contactSupportForRetryBroadcast() : retryFailedUsers(broadcastId)
				}
			/>
			<ContactSalesModal
				isModalOpen={contactSales}
				toggleModal={setContactSales}
				options={{ preSubject: t('pages:broadcast.basic.retryBroadcast'), contactMode: 'contact' }}
				heading={t('common:contactSupport')}
				usage={BROADCAST_USAGE.RETRY_BROADCAST}
				email={supportEmail}
			/>
		</>
	);
};

export default RetryBroadcastModal;
