import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import ResetAccountPasswordForm, { PASSWORD_FORM_USAGES } from './ResetAccountPasswordForm';

const emptyFunction = () => {};

function ResetAccountPasswordModal() {
	const { t } = useTranslation(['components', 'common']);
	const [isDisabled, setDisabled] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(true);

	return (
		<ActionModal
			isOpen={isOpen}
			heading={t('components:resetAccountPassword.heading')}
			description={<div style={{ width: '75%' }}>{t('components:resetAccountPassword.description')}</div>}
			confirmBtnLabel={t('common:save')}
			isConfirmBtnDisabled={isDisabled}
			onConfirm={emptyFunction}
			onClose={emptyFunction}
			isFooterReq={false}
			hideCloseBtn
		>
			<ResetAccountPasswordForm
				usage={PASSWORD_FORM_USAGES.WIDGET}
				setDisabled={setDisabled}
				onSuccess={() => {
					setIsOpen(false);
				}}
			/>
		</ActionModal>
	);
}

export default ResetAccountPasswordModal;
