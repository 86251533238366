import React from 'react';
import { Block } from 'baseui/block';

import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';

function NavigationDivider() {
	return (
		<Block marginTop='0.75rem' marginBottom='0.75rem' display={['none', 'none', 'none', 'block']}>
			<HorizontalDivider
				height='0.5px'
				overrides={{
					width: '70%',
				}}
			/>
		</Block>
	);
}

export default NavigationDivider;
