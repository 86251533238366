import { IObjProps, IPaginationStoreData } from 'shared/consts/types';

export interface IFilterAndSort {
	filters: {
		startDate: string;
		endDate: string;
		channels: string;
	};
	sort: string;
}
export interface IBroadcastState {
	campaigns: Array<IObjProps>;
	messageTemplates: Array<IObjProps>;
	messageLimit: string;
	statistics: IObjProps;
	published: IPaginationStoreData<Array<IObjProps>> & IFilterAndSort;
	scheduled: IPaginationStoreData<Array<IObjProps>>;
	draft: IPaginationStoreData<Array<IObjProps>>;
}

export const broadcastState: IBroadcastState = {
	campaigns: [],
	messageTemplates: [],
	messageLimit: 'unlimited',
	statistics: {
		data: {},
		isError: false,
		isLoading: false,
	},
	published: {
		data: [],
		sort: '',
		filters: {
			startDate: '',
			endDate: '',
			channels: '',
		},
		pagination: {
			size: 10,
			page: 0,
			totalPages: 0,
			totalElements: 0,
		},
		isError: false,
		isLoading: false,
	},
	scheduled: {
		data: [],
		pagination: {
			size: 10,
			page: 0,
			totalPages: 0,
			totalElements: 0,
		},
		isError: false,
		isLoading: false,
	},
	draft: {
		data: [],
		pagination: {
			size: 10,
			page: 0,
			totalPages: 0,
			totalElements: 0,
		},
		isError: false,
		isLoading: false,
	},
};
