import { matchPath } from 'react-router-dom';

import { IObjProps } from 'shared/consts/types';

import { EmptyPageRoutesLinks } from 'router/EmptyPageRoutes';
import { NoColumnRouteLinks } from 'router/NoColumnRoutes';

const { whatsappSetup, verify, verifyAccount } = NoColumnRouteLinks;

const isMobileMenuHidden = (() => {
	const cache: IObjProps = {};
	const hideForRoutes: Array<string> = [whatsappSetup, verify, verifyAccount, ...Object.values(EmptyPageRoutesLinks)];

	return (pathname: string) => {
		if (cache[pathname] !== undefined) {
			return cache[pathname];
		}

		let hideMenu = false;

		for (let i = 0; i < hideForRoutes.length; i++) {
			if (matchPath(hideForRoutes[i], pathname)) {
				hideMenu = true;
				break;
			}
		}

		cache[pathname] = hideMenu;

		return hideMenu;
	};
})();

export default isMobileMenuHidden;
