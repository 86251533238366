import { IENGTToasterProps } from 'components/UI/ENGTToaster/ENGTToaster';

import { IObjProps } from 'shared/consts/types';
import { THEME_COLOR_OPTIONS, THEME_VALUES } from 'shared/enum';

export type IThemeProps = {
	contrastMode: THEME_VALUES;
	themePack: THEME_COLOR_OPTIONS;
};

export const preferenceState: {
	theme: IThemeProps;
	notifications: IObjProps;
	toaster: IENGTToasterProps;
} = {
	theme: {
		contrastMode: THEME_VALUES.LIGHT,
		themePack: THEME_COLOR_OPTIONS.DEFAULT,
	},
	notifications: {},
	toaster: { toastDuration: 3000 },
};

export type IPreferencesDetailStateInterface = typeof preferenceState;
