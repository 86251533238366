import React from 'react';
import { useStyletron } from 'baseui';
import { Paragraph1 } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DOC_LINKS_V2 } from 'shared/consts/DockLinks';

import { RootState } from 'store/rootReducer';

import Divider from './Divider';

const Resources = () => {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['pages', 'common']);

	const isWhiteLabelPartner = useSelector((state: RootState) => !state.User.botAdmin.data.is_engati_brand);
	const knowledgeBasedLink = useSelector((state: RootState) => state.User.botAdmin.data.knowledge_base_link);

	const showKnowledgeBasedLink = knowledgeBasedLink !== 'NA';

	return showKnowledgeBasedLink ? (
		<>
			<Paragraph1
				marginTop='0'
				marginBottom='0'
				className={css({
					fontSize: '0.875rem !important',
					lineHeight: '1.25rem !important',
				})}
			>
				<a
					href={isWhiteLabelPartner ? knowledgeBasedLink : DOC_LINKS_V2.home}
					className={css({
						textDecoration: 'none',
						color: theme.colors.primaryA,
						letterSpacing: '0.0015em',
						fontWeight: '500',
					})}
					target='_blank'
					rel='noreferrer'
				>
					{t('common:resources')}
				</a>
			</Paragraph1>
			<Divider />
		</>
	) : (
		<></>
	);
};

export default Resources;
