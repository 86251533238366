import { actions } from './store';

const { openMobileMenu, closeMobileMenu, enablePostHog, disablePostHog } = actions;

const postHogConfigsActions = {
	enablePostHogAction: () => (dispatch: any) => {
		dispatch(enablePostHog());
	},
	disablePostHogAction: () => (dispatch: any) => {
		dispatch(disablePostHog());
	},
};

export const common = {
	openMobileMenu: () => (dispatch: any) => {
		dispatch(openMobileMenu());
	},
	closeMobileMenu: () => (dispatch: any) => {
		dispatch(closeMobileMenu());
	},
	...postHogConfigsActions,
};

export default common;
