import React from 'react';

import ContactSupport from './component/ContactSupport';
import Resources from './component/Resources';
import SwitchAccount from './component/SwitchAccount';

const UserProfileMobileHeader = (): React.ReactElement => (
	<>
		<SwitchAccount />
		<Resources />
		<ContactSupport />
	</>
);

export default UserProfileMobileHeader;
