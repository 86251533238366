import React from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ListItemWithIcon } from 'components/UI/ListItemWithIcon/ListItemWithIcon';

import { ENGATI_ROLES } from 'shared/consts/consts';
import BotStoreIcon from 'shared/icons/BotStoreIcon';

import { isShopifyEnabledAcc, isWhatsappAcceleratorEnabled } from 'store/App/User/selectors';
import { RootState } from 'store/rootReducer';

const MyBots = () => {
	const [, theme]: [any, any] = useStyletron();
	const { t }: any = useTranslation(['common']);

	const isAgent = useSelector(
		(state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT
	);
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const isWhatsappAccelerator = useSelector(isWhatsappAcceleratorEnabled);

	const myBotsIcon = () => <BotStoreIcon fill={theme.colors.navIconFillColor} />;

	return isShopifyEnabled && !isAgent && !isWhatsappAccelerator ? (
		<ListItemWithIcon iconElement={myBotsIcon} itemLabel={t('common:myBots')} linkTo='/bots' />
	) : (
		<></>
	);
};

export default MyBots;
