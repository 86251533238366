import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { KIND } from 'baseui/button';
import { SIZE } from 'baseui/input';
import { toaster } from 'baseui/toast';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import Form from 'components/UI/Form/Form/Form';
import { FormInput } from 'components/UI/Form/FormInput/FormInput';
import { FormPassword } from 'components/UI/Form/FormPassword/FormPassword';

import { ENGATI_RESPONSE_CODE, PASSWORD_MAXIMUM_CHARACTERS } from 'shared/consts/consts';
import { validPassword } from 'shared/helpers';

import { API } from 'store/AccountSettings/api';
import { redirectToLoginAction } from 'store/App/Token';
import { fontStyleSelector } from 'store/App/User';
import { openErrorSavingModalAction } from 'store/Modal/actions';
import { RootState } from 'store/rootReducer';

interface PasswordFormInputs {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
}

export enum PASSWORD_FORM_USAGES {
	PAGE = 'page',
	WIDGET = 'widget',
}

function ResetAccountPasswordForm({
	usage,
	onSuccess,
	setDisabled,
}: {
	usage: PASSWORD_FORM_USAGES.PAGE | PASSWORD_FORM_USAGES.WIDGET;
	onSuccess?: Function;
	setDisabled: Function;
}) {
	const dispatch = useDispatch<any>();
	const [css] = useStyletron();
	const { t } = useTranslation(['pages', 'common']);

	const userName = useSelector((state: RootState) => state.User.profile.data.email);
	const { STATUS_1000, STATUS_13, STATUS_2020, STATUS_2021 } = ENGATI_RESPONSE_CODE;
	const [isVisible, setIsVisible] = useState(false);

	const fontStyle: string = useSelector(fontStyleSelector);

	const onSuccessfulChange = () => {
		if (usage === PASSWORD_FORM_USAGES.WIDGET) {
			dispatch(redirectToLoginAction());
			onSuccess?.();
		}
	};

	const onSuccessfulChangeInPage = () => {
		if (usage === PASSWORD_FORM_USAGES.PAGE) {
			window.location.reload();
		}
	};

	const changePasswordSuccessHandler = (statusCode: any, message: string) => {
		switch (statusCode) {
			case STATUS_1000:
				toaster.positive(
					<ENGTToasterContainer
						title={t('pages:accountSettings.passwordSettings.toasterHeading')}
						description={t('common:changesSuccessfulSaved')}
					/>,
					{}
				);

				onSuccessfulChange();
				onSuccessfulChangeInPage();
				break;
			case STATUS_13:
				toaster.negative(
					<ENGTToasterContainer
						description={t('pages:accountSettings.passwordSettings.accountLockedErrorMsg')}
						title={t('common:error')}
					/>,
					{}
				);

				onSuccessfulChange();
				break;
			case STATUS_2020:
				toaster.negative(
					<ENGTToasterContainer
						description={t('pages:accountSettings.passwordSettings.oldPasswordErrorMsg')}
						title={t('common:error')}
					/>,
					{}
				);
				break;
			case STATUS_2021:
				toaster.negative(<ENGTToasterContainer description={message} title={t('common:error')} />, {});
				break;
			default:
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
				break;
		}
	};

	const updatePasswordSettings = () => {
		if (getValues('confirmPassword') === getValues('newPassword')) {
			API.updatePassword(getValues('oldPassword'), getValues('newPassword'), userName)
				.then((resp: any) => {
					changePasswordSuccessHandler(
						resp?.data?.response_obj?.status?.code,
						resp?.data?.response_obj?.developerMessage
					);
				})
				.catch((e: any) => {
					dispatch(openErrorSavingModalAction());
				});
		} else {
			toaster.negative(
				<ENGTToasterContainer
					title={t('common:changesNotSaved')}
					description={t('pages:accountSettings.passwordSettings.passwordNotMatching')}
				/>,
				{}
			);
		}
	};

	const defaultValues = {
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',
	};
	const methods = useForm<PasswordFormInputs>({
		defaultValues,
		mode: 'onChange',
	});

	const { getValues, handleSubmit, formState } = methods;
	const { isDirty } = formState;

	useEffect(() => {
		setDisabled(!formState.isValid);
	}, [formState, setDisabled]);

	useEffect(() => {
		setDisabled(!isDirty);
	}, []);

	return (
		<FormProvider {...methods}>
			<Form id='reset-password-form' onSubmit={handleSubmit(updatePasswordSettings)}>
				<Controller
					name='oldPassword'
					rules={{
						validate: (value) => {
							if (!value) {
								return t('errors:requiredErrorMessage') as string;
							}

							return true;
						},
						required: t('errors:requiredErrorMessage') as string,
					}}
					render={({ ref, value, onChange }) => (
						<FormInput
							id='oldPassword'
							name='oldPassword'
							label={t('pages:accountSettings.passwordSettings.oldPassword')}
							placeholder={t('pages:accountSettings.passwordSettings.enterOldPassword')}
							autoComplete='new-password'
							type='password'
							onChange={onChange}
							value={value}
							inputRef={ref}
							labelClassName={{
								fontFamily: fontStyle,
							}}
						/>
					)}
				/>

				<Controller
					name='newPassword'
					rules={{
						validate: (value) => {
							if (!value) {
								return t('errors:requiredErrorMessage') as string;
							}
							if (!validPassword(value)) {
								return t('errors:validPassword') as string;
							}

							return true;
						},
						required: (t('errors:requiredErrorMessage') as string) || '',
					}}
					render={({ ref, value, onChange }) => (
						<FormPassword
							id='newPassword'
							name='newPassword'
							label={t('pages:accountSettings.passwordSettings.newPassword')}
							placeholder={t('pages:accountSettings.passwordSettings.enterNewPassword')}
							autoComplete='new-password'
							onChange={(value: string) => {
								onChange(value);
								setIsVisible(true);
							}}
							onBlur={() => setIsVisible(false)}
							marginBottom={isVisible ? '0rem' : '1rem'}
							isPasswordWidgetOpen={isVisible}
							password={getValues('newPassword') || ''}
							value={value}
							inputRef={ref}
							maxLength={PASSWORD_MAXIMUM_CHARACTERS}
							labelClassName={{
								fontFamily: fontStyle,
							}}
						/>
					)}
				/>
				<Controller
					name='confirmPassword'
					rules={{
						validate: (value) => {
							if (!value) {
								return t('errors:requiredErrorMessage') as string;
							}

							return true;
						},
						required: (t('errors:requiredErrorMessage') as string) || '',
					}}
					render={({ ref, value, onChange }) => (
						<FormInput
							id='confirmPassword'
							name='confirmPassword'
							label={t('pages:accountSettings.passwordSettings.confirmPassword')}
							placeholder={t('pages:accountSettings.passwordSettings.confirmPasswordPlaceholder')}
							autoComplete='new-password'
							type='password'
							onChange={onChange}
							value={value}
							inputRef={ref}
							labelClassName={{
								fontFamily: fontStyle,
							}}
						/>
					)}
				/>
				{usage === PASSWORD_FORM_USAGES.WIDGET && (
					<ENGTButton
						className={css({
							float: 'right',
						})}
						kind={KIND['primary']}
						size={SIZE.large}
						type='submit'
						isDisabled={!isDirty}
						label={t('common:save')}
						dataQa='secondary-reset-password-form'
					/>
				)}
			</Form>
		</FormProvider>
	);
}

export default ResetAccountPasswordForm;
