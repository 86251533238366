import { TRAIN } from 'shared/consts/QAConsts';

function Train({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			width={size || 40}
			height={size || 40}
			viewBox='0 0 40 40'
			fill='none'
			className={className}
			data-qa={TRAIN}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='inherit'>
				<path
					d='M24.0228 26.8869C23.9853 27.0994 23.9603 27.3119 23.9603 27.5369V30.3121C23.9603 31.5122 22.9727 32.4998 21.7726 32.4998H18.6474C17.5973 32.4998 16.4598 31.6997 16.4598 29.9496V27.737C16.4598 27.4494 16.4348 27.1619 16.3723 26.8869L16.4598 26.8744H23.9603L24.0228 26.8869Z'
					fill={fillColor || '#403F42'}
				/>
				<path
					opacity='0.7'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M25.623 24.3745C27.7356 22.6993 28.9607 20.1992 28.9607 17.499C28.9607 14.8488 27.7856 12.3736 25.723 10.6985C23.6728 9.03583 20.9726 8.38578 18.335 8.94832C15.0222 9.63588 12.3345 12.3361 11.647 15.6488C10.9469 19.0491 12.222 22.4243 14.9472 24.487C15.6848 25.037 16.1973 25.8996 16.3723 26.8871L16.4598 26.8746H23.9604L24.0229 26.8871C24.1979 25.9371 24.7604 25.0495 25.623 24.3745ZM23.9604 17.1867C23.9604 17.7042 24.3804 18.1242 24.8979 18.1242C25.4155 18.1242 25.8355 17.7042 25.8355 17.1867C25.8355 14.2577 23.4516 11.8738 20.5226 11.8738C20.0051 11.8738 19.585 12.2938 19.585 12.8113C19.585 13.3289 20.0051 13.7489 20.5226 13.7489C22.419 13.7489 23.9604 15.2903 23.9604 17.1867Z'
					fill={fillColor || '#403F42'}
				/>
			</g>
		</svg>
	);
}

export default Train;
