import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { TYPE } from 'baseui/select';
import { useTranslation } from 'react-i18next';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ENGTInput from 'components/UI/ENGTInput/ENGTInput';
import ENGTSelect from 'components/UI/ENGTSelect/ENGTSelect';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { IObjProps } from 'shared/consts/types';
import SearchIcon from 'shared/icons/SearchIcon';
import TickIcon from 'shared/icons/TickIcon';

import { API as adminAPI } from 'store/App/User/api';
import { API } from 'store/SaveAsTemplate/api';

function ChangeAccountModal({ toggle }: { toggle: (...args: any) => any }) {
	const { t } = useTranslation(['components']);
	const [css, theme]: any = useStyletron();

	const [query, setQuery] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showModalLoader, setModalLoader] = useState<boolean>(false);
	const [value, selectedValue] = useState<Array<{ id: string; label: string }>>([]);
	const [options, setOptionsArray] = useState<Array<any>>([]);

	const onConfirmFn = () => {
		setModalLoader(true);
		value?.[0]?.id &&
			adminAPI.changeAdminUser(value[0].id).then(
				(response: any) => {
					if (response?.data?.message === 'success') {
						localStorage.removeItem('storeDataVersion');
						localStorage.removeItem('storeData');
						window.location.href = '/';
					}
					setModalLoader(false);
				},
				() => {
					setModalLoader(false);
				}
			);
	};
	const onCloseFn = () => toggle(false);

	const getUsers = (query: string) => {
		setIsLoading(true);
		selectedValue([]);

		if (!query) {
			setOptionsArray([]);

			return;
		}

		const queryString = query?.split(' ')[0] || query;

		API.getAccountsData(queryString).then((response: IObjProps) => {
			if (response?.data?.response_obj?.content) {
				const objMappingArray: Array<IObjProps> = [];

				response?.data?.response_obj?.content?.forEach((data: any) => {
					const customer = `${data.email} (${data.account_name})`;

					objMappingArray.push({ id: data.email, label: customer });
				});
				setOptionsArray(objMappingArray);
			}
			setIsLoading(false);
		});
	};

	const getLabel = (props: any) => {
		const { option, optionState } = props;
		const { $isHighlighted } = optionState;

		return (
			<Block
				className={css({
					display: 'flex',
					overflow: 'hidden',
					width: '100%',
					alignItems: 'center',
				})}
			>
				<span
					className={css({
						display: 'flex',
						paddingRight: '0.5rem',
						paddingLeft: '0.25rem',
						verticalAlign: 'center',
						minHeight: '0.875rem',
						minWidth: '0.875rem',
					})}
				>
					{$isHighlighted && <TickIcon fillColor={theme.colors.primaryA} />}
				</span>
				<span
					className={css({
						width: `19rem`,
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
					})}
				>
					{option.label}
				</span>
			</Block>
		);
	};

	return (
		<ActionModal
			heading={t('components:accChangeModal.heading')}
			isOpen
			onClose={onCloseFn}
			onConfirm={onConfirmFn}
			confirmBtnLabel={t('common:continue')}
			isLoading={showModalLoader}
			isConfirmBtnDisabled={!value?.[0]?.id}
		>
			<span
				className={css({
					fontSize: '1rem',
					lineHeight: '1.25rem',
					color: theme.colors.primaryA,
				})}
			>
				{t('components:accChangeModal.description')}
				<form
					className={css({ marginTop: '1rem' })}
					onSubmit={(e: any) => {
						e.preventDefault();
						if (query) {
							getUsers(query);
						}
					}}
				>
					<div
						className={css({
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: '1rem',
						})}
					>
						<ENGTInput
							value={query}
							onChange={(e) => setQuery(e.target.value)}
							placeholder={t('components:accChangeModal.placeholder')}
							width='100%'
						/>
						<ENGTButton
							className={css({
								marginLeft: '1rem !important',
							})}
							type='button'
							startEnhancer={<SearchIcon strokeColor={theme.colors.primaryB} />}
							onClick={() => getUsers(query)}
							isLoading={isLoading}
							isDisabled={isLoading}
						/>
					</div>
					<ENGTSelect
						isLoading={isLoading}
						options={options}
						selectedValue={
							value?.length
								? [
										{
											id: '',
											label: value?.[0]?.label,
										},
									]
								: []
						}
						clearable={false}
						searchable={false}
						openOnClick={false}
						popupIndex={theme.zIndex300}
						dropDownListItemHeight={40}
						type={TYPE.select}
						placeholder={t('components:accChangeModal.selectPlaceholder')}
						onChange={(data) => {
							selectedValue(
								data?.length
									? [
											{
												id: data?.[0]?.id,
												label: data?.[0]?.label?.split('(')[0],
											},
										]
									: []
							);
						}}
						overrides={{
							ControlContainer: {
								style: {
									fontSize: '1rem',
								},
							},
						}}
						getOptionLabel={getLabel}
					/>
				</form>
			</span>
		</ActionModal>
	);
}

export default ChangeAccountModal;
