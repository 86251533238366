import { IObjProps } from 'shared/consts/types';

import { API } from './api';
import { actions } from './store';

const {
	setIntegratedAccount,
	setIntegratedAccountError,
	setOnboardingStatus,
	setOnboardingStatusError,
	setProductInitialLoadData,
	setSegmentStatus,
	setProductDiscoveryConfig,
	resetShopifyData,
} = actions;

export const shopifyActions = {
	fetchIntegratedAccountAction: (successCallback?: Function, errorCallback?: Function) => (dispatch: any) =>
		API.fetchIntegratedAccount().then(
			(resp: any) => {
				if (resp.data && resp.data.responseObject) {
					Object.keys(resp.data.responseObject).length
						? dispatch(setIntegratedAccount(resp.data.responseObject))
						: dispatch(setIntegratedAccount({ accountConnected: false }));
					successCallback && successCallback(resp.data.responseObject?.shopifyStoreDomain);
				} else {
					errorCallback && errorCallback(resp);
					dispatch(setIntegratedAccountError());
				}
			},
			(e: any) => {
				errorCallback && errorCallback(e);
				dispatch(setIntegratedAccountError());
			}
		),
	updateShopifyAccountDetails: (storeData: IObjProps) => (dispatch: any) => {
		dispatch(setIntegratedAccount(storeData));
	},
	updateOnboardingStatusAction: (statusData: IObjProps) => (dispatch: any) => {
		if (statusData.status) {
			dispatch(setOnboardingStatus(statusData));
		} else {
			dispatch(setOnboardingStatusError());
		}
	},
	updateProductLoadDataAction: (statusData: IObjProps) => (dispatch: any) => {
		dispatch(setProductInitialLoadData(statusData));
	},
	updateSegmentStatusAction: (status: boolean) => (dispatch: any) => {
		dispatch(setSegmentStatus(status));
	},
	updateProductDiscoveryConfigAction: (configData: IObjProps) => (dispatch: any) => {
		dispatch(setProductDiscoveryConfig(configData));
	},
};

export const resetShopifyDataAction = () => (dispatch: any) => {
	dispatch(resetShopifyData());
};
