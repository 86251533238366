import BaseService from 'services/baseService';
import { serialize } from 'shared/helpers';

const botConfigChannelsUrl = '/v2/genericProxyRequest/?targetApi=getConfiguredChannels';
const botConfigDetailsUrl = '/api/v1/bot_config';
const botCopyUrl = '/v2/genericProxyRequest/';
const botDetailsUrl = '/api/v1/bot/';
const botDeleteUrl = '/api/v1/deleteBot';
const setBotStatusUrl = '/toggle_status';
const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const templateUrl = 'integrated/create-bot-from-template';

const fetchVideoConfigurationsApi = new BaseService(genericProxyRequestUrl);
const getGeneralSettingsApi = new BaseService(genericProxyRequestUrl);
const botConfigChannelsApi = new BaseService(botConfigChannelsUrl);
const botConfigDetailsApi = new BaseService(botConfigDetailsUrl);
const botCopyApi = new BaseService(botCopyUrl);
const botDetailsApi = new BaseService(botDetailsUrl);
const botDeleteApi = new BaseService(botDeleteUrl, {
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
	},
});
const setBotStatusApi = new BaseService(setBotStatusUrl);
const templateApi = new BaseService(templateUrl);

export const API = {
	botSelect(botRef) {
		const botSelectUrl = `/select/${botRef}`;
		const botSelectApi = new BaseService(botSelectUrl, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});

		return botSelectApi.postRequest({});
	},
	copyBot: (name, botRef, category) => {
		const params = {
			targetApi: 'copyBot',
			customerId: '{{customerId}}',
			userId: '{{userId}}',
			botName: name,
			botCategory: category,
			replaceable_params: {
				botRef,
			},
		};

		return botCopyApi.postRequest({}, serialize(params, '?'));
	},
	createBotFromTemplateAccountBot: (name, botRef, category) => {
		const params = {
			targetApi: 'createBotFromTemplateAccountBot',
			customerId: '{{customerId}}',
			userId: '{{userId}}',
			replaceable_params: {
				botRef,
			},
		};

		const data = {
			botName: name,
			botCategory: category || 'default',
		};

		return botCopyApi.postRequest(data, serialize(params, '?'));
	},
	createBot(data) {
		return botDetailsApi.postRequest(data);
	},
	createTemplateBot(data) {
		return templateApi.postRequest(data);
	},
	deleteBot(data) {
		return botDeleteApi.postRequest(data);
	},
	fetchBotConfigDetails() {
		return botConfigDetailsApi.getRequest();
	},
	fetchBotDetails() {
		return botDetailsApi.getRequest();
	},
	fetchConfiguredChannels() {
		return botConfigChannelsApi.getRequest();
	},
	fetchVideoConfigurations(params) {
		return fetchVideoConfigurationsApi.getRequest(serialize(params, '?'));
	},
	setBotStatus() {
		return setBotStatusApi.getRequest();
	},
	getGeneralSettings() {
		const params = {
			targetApi: 'connectBotConfig',
			platforms: ['web', 'mobile'],
		};

		return getGeneralSettingsApi.getRequest(serialize(params, '?'));
	},

	changeWelcomeFlag(data) {
		const params = {
			targetApi: 'updateWelcomeMessageFlags',
			featureName: data,
		};

		return getGeneralSettingsApi.putRequest(params, serialize(params, '?'));
	},
	getAgentOnlineStatus: () => {
		const params = {
			targetApi: 'getAgentOnlineStatus',
			agentId: '{{userId}}',
			botRef: '{{botRef}}',
		};

		return getGeneralSettingsApi.getRequest(serialize(params, '?'));
	},
	getBrandingDetailsForBrandingElement(cid, brandingElement) {
		const params = {
			targetApi: 'getBrandingDetailsByCustomerId',
			replaceable_params: {
				cid,
				brandingElementName: brandingElement,
			},
		};

		return getGeneralSettingsApi.getRequest(serialize(params, '?'));
	},
};
