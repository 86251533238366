import { MESSAGE_STATE_MAP } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';

import { i18nHelper as t } from 'i18nHelper';

const { ASSIGNED, AUTOMATIC_HANDLED, ENGAGING, HANDLED, PENDING_RESOLUTION, REQUESTED } = MESSAGE_STATE_MAP;

export const conversationFormNameMap = {
	liveChatState: 'livechat_state',
	categoryId: 'category_id',
	tagId: 'tags',
	lastActiveAt: 'last_active_at',
	customDate: 'custom_date',
	livechatEngagedBy: 'livechat_engaged_by',
	livechatResolvedBy: 'livechat_resolved_by',
	livechatTransfferedBy: 'livechat_transffered_by',
};

export const conversationKeys = [
	conversationFormNameMap['liveChatState'],
	conversationFormNameMap['categoryId'],
	conversationFormNameMap['tagId'],
	conversationFormNameMap['lastActiveAt'],
	conversationFormNameMap['customDate'],
	conversationFormNameMap['livechatEngagedBy'],
	conversationFormNameMap['livechatResolvedBy'],
	conversationFormNameMap['livechatTransfferedBy'],
];

export const allTabStatusMap: () => Array<IObjProps> = () => [
	{ id: ASSIGNED, label: t('components:customFilters.conversations.statusOptions.new') },
	{ id: ENGAGING, label: t('components:customFilters.conversations.statusOptions.agents') },
	{ id: HANDLED, label: t('components:customFilters.conversations.statusOptions.resolved') },
	{
		id: PENDING_RESOLUTION,
		label: t('components:customFilters.conversations.statusOptions.pendingResolution'),
	},
	{ id: REQUESTED, label: t('components:customFilters.conversations.statusOptions.unassigned') },
];

export const generalStatusMap: () => Array<IObjProps> = () => [
	{ id: ENGAGING, label: t('components:customFilters.conversations.statusOptions.open') },
	{ id: HANDLED, label: t('components:customFilters.conversations.statusOptions.resolvedByAgent') },
	{ id: AUTOMATIC_HANDLED, label: t('components:customFilters.conversations.statusOptions.closed') },
];

export const dateMap: () => any = () => [
	{
		id: 24,
		label: t('components:customFilters.conversations.dateOptions.24hours'),
	},
	{
		id: 30,
		label: t('components:customFilters.conversations.dateOptions.lastnDays', { days: 30 }),
	},
	{
		id: 60,
		label: t('components:customFilters.conversations.dateOptions.lastnDays', { days: 60 }),
	},
	{
		id: 90,
		label: t('components:customFilters.conversations.dateOptions.lastnDays', { days: 90 }),
	},
	{
		id: 'custom_date',
		label: t('components:customFilters.conversations.dateOptions.custom'),
	},
];
