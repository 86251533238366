import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { SIZE } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ENGTSelect from 'components/UI/ENGTSelect/ENGTSelect';
import ENGTTextarea from 'components/UI/ENGTTextarea/ENGTTextarea';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import FormLabel from 'components/UI/Form/FormLabel/FormLabel';
import FormRadio from 'components/UI/Form/FormRadio/FormRadio';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { ALLOWED_ATTACHMENT_SIZE, MAX_FILE_NAME_LENGTH } from 'shared/consts/consts';
import { getEllipsisedText, getSizeInBytes } from 'shared/helpers';
import ClearTextIcon from 'shared/icons/ClearTextIcon';
import PlusIcon from 'shared/icons/PlusIcon';

import { API } from 'store/AccountSettings/api';
import { customerPlanData } from 'store/App/Plan/selectors';
import { isShopifyEnabledAcc, isWhatsappAcceleratorEnabled } from 'store/App/User/selectors';
import { API as pathBuilderApi } from 'store/PathBuilder/api';
import { RootState } from 'store/withReducer';

interface IFeedbackFormProps {
	isOpen: boolean;
	toggleModal: Function;
}

const FeedBackForm = ({ isOpen, toggleModal }: IFeedbackFormProps) => {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['components', 'pages', 'errors', 'common']);

	const botAdminEmail = useSelector((state: RootState) => state.User.botAdmin.data.email);
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const isWhatsappAcceleratorCustomer = useSelector(isWhatsappAcceleratorEnabled);
	const { displayName: customerPlan } = useSelector(customerPlanData);

	const [isFeedBackon, setisFeedBackon] = useState<boolean>(false);
	const [fileNotUploadState, setFileNotUploadState] = useState<string>('');
	const [descriptionState, setDescriptionState] = useState<string>('');
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isFileUploading, setFileUploading] = useState<boolean>(false);

	const MAX_FILES = 3;

	enum FEEDBACK_FORM {
		NEWFEATUREREQEUST = 'New Feature Request',
		ISSUES = 'Issues/bugs',
	}

	const errorMsgCss = {
		color: theme.inputErrorMsgColor,
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		textAlign: 'left',
		marginBottom: '0.5rem',
		marginTop: '0.5rem',
	};
	const uploadFileCss = {
		position: 'absolute',
		display: 'flex',
		marginLeft: '0.25rem',
		marginTop: '0.5rem',
		marginBottom: '0.5rem',
		paddingTop: '0.3rem',
		paddingBottom: '0.1rem',
		paddingLeft: '0.5rem',
		fontSize: '0.875rem',
		alignItems: 'center',
		width: 'fit-content',
		color: theme.colors.contentStateDisabled,
	};
	const fileCss = {
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 'fit-content',
		marginLeft: '0.5rem',
		marginTop: '0.5rem',
		marginBottom: '0.5rem',
		paddingTop: '0.25rem',
		paddingBottom: '0.25rem',
		paddingRight: '0.5rem',
		paddingLeft: '0.5rem',
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		overflow: 'hidden',
		color: theme.colors.goToSettingsBtnColor,
		backgroundColor: theme.colors.accent100,
		borderRadius: '0.25rem',
	};

	const uploadButtonContainerCss = (theme: any): any => ({
		backgroundColor: !isUploadDisable ? theme.colors.primaryB : theme.colors.accent100,
		color: !isUploadDisable ? theme.colors.primaryA : theme.colors.goToSettingsBtnColor,
		height: '2rem',
		width: '4rem',
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		backdropFilter: 'blur(0.25rem)',
		borderTopLeftRadius: '0.375rem',
		borderBottomLeftRadius: '0.375rem',
		borderTopRightRadius: '0.375rem',
		borderBottomRightRadius: '0.375rem',
		boxShadow: ' 0 0 0.125rem 0.125rem rgba(0, 0, 0, 0.08)',
		fontSize: '0.875rem',
		paddingRight: '0.625rem',
		paddingLeft: '0.325rem',
		marginLeft: '0.1rem',
		cursor: !isUploadDisable ? 'pointer' : 'no-drop',
		marginTop: '0.5rem',
	});
	const clearAllCss = (theme: any): any => ({
		cursor: 'pointer',
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
		marginLeft: '1rem',
		marginTop: '0.6rem',
		marginBottom: '0.5rem',
		paddingTop: '0.25rem',
		paddingBottom: '0.25rem',
		paddingRight: '0.5rem',
		paddingLeft: '0.5rem',
		fontSize: '1rem',
		color: theme.colors.accent50,
		right: '0',
	});

	const defaultValues: any = {
		feedBackType: FEEDBACK_FORM.NEWFEATUREREQEUST,
		description: '',
	};
	const methods = useForm<any>({
		defaultValues,
	});

	const { control, getValues, setValue, reset } = methods;
	useWatch({
		control,
		name: ['feedBackType'],
	});
	const [feedBackOn, setFeedBackOn] = useState<Array<{ id: string; label: string; disabled?: boolean }>>([]);
	const [messageTypeOptions, setMessageTypeOptions] = useState<Array<{ id: string; label: string }>>([
		{
			id: 'messageAndLiveChat',
			label: 'Messages & LiveChat',
		},
		{
			id: 'broadcast',
			label: 'Broadcasts',
		},
		{
			id: 'contacts',
			label: 'Contacts',
		},
		{
			id: 'users',
			label: 'Users',
		},
		{
			id: 'train',
			label: 'Training - FAQs & NLP',
		},
		{
			id: 'configurations',
			label: 'Configurations',
		},
		{
			id: 'integrations',
			label: 'Integrations',
		},
		{
			id: 'analytics',
			label: 'Analytics',
		},
	]);

	const botBuilderOption = {
		id: 'botBuilder',
		label: 'Bot Builder',
	};
	const storeOption = {
		id: 'store',
		label: 'Connected Shopify Store',
	};

	const convBuilderOption = {
		id: 'conversationBuilder',
		label: 'Conversation Builder',
	};

	const settingsOption = {
		id: 'settings',
		label: 'Settings',
	};
	const analyticsOption = {
		id: 'analytics',
		label: 'Analytics',
	};
	const trainingModulesOption = {
		id: 'trainingModules',
		label: 'Training Modules',
	};
	const othersOption = {
		id: 'others',
		label: 'Others',
	};

	useEffect(() => {
		if (isShopifyEnabled) {
			setMessageTypeOptions((options) => {
				options.splice(4, 0, storeOption);
				options.splice(6, 0, convBuilderOption);

				return [...options];
			});
		} else if (isWhatsappAcceleratorCustomer) {
			setMessageTypeOptions((options) => {
				options.splice(4, 4, settingsOption, analyticsOption, trainingModulesOption, othersOption);

				return [...options];
			});
		} else {
			setMessageTypeOptions((options) => {
				options.splice(2, 0, botBuilderOption);

				return [...options];
			});
		}
	}, []);

	const feedBackTypeOptions = [
		{
			name: t('components:feedBackForm.feedBackTypeOptions.newFeatureRequest'),
			value: FEEDBACK_FORM.NEWFEATUREREQEUST,
		},
		{
			name: t('components:feedBackForm.feedBackTypeOptions.issues'),
			value: FEEDBACK_FORM.ISSUES,
		},
	];

	const [feedBackTypeButtonName, setfeedBackTypeButtonName] = useState<any>(FEEDBACK_FORM.NEWFEATUREREQEUST);

	const [files, setFiles] = useState<File[]>([]);
	const [attachmentFiles, setAttachmentFiles] = useState<Array<{ attachmentName: string; attachmentURL: any }>>([]);
	const isUploadDisable = !(files.length < MAX_FILES && !isFileUploading);
	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = event.target?.files;
		if (!selectedFile || selectedFile.length === 0) {
			return;
		}
		if (selectedFile?.[0]?.type === 'application/pdf') {
			if (selectedFile[0]?.size > ALLOWED_ATTACHMENT_SIZE) {
				setFileNotUploadState(
					t('errors:fileSizeTooLarge', {
						maxSize: 25,
					}).toString()
				);

				return;
			}
			if (selectedFile[0]?.name?.length >= MAX_FILE_NAME_LENGTH) {
				setFileNotUploadState(
					t('errors:fileNameIsTooLong', {
						maxLength: MAX_FILE_NAME_LENGTH,
					}).toString()
				);

				return;
			}
		} else {
			if (selectedFile?.[0]?.size > getSizeInBytes(1)) {
				setFileNotUploadState(
					t('errors:fileSizeTooLarge', {
						maxSize: 1,
					}).toString()
				);

				return;
			}
			if (selectedFile?.[0]?.name?.length >= MAX_FILE_NAME_LENGTH) {
				setFileNotUploadState(
					t('errors:fileNameIsTooLong', {
						maxLength: MAX_FILE_NAME_LENGTH,
					}).toString()
				);

				return;
			}
		}

		if (selectedFile.length + files.length > MAX_FILES) {
			return;
		}

		const updatedFiles = [...files];
		const updatedFiles_attachmentFiles = [...attachmentFiles];
		if (selectedFile?.[0]?.type === 'application/pdf') {
			setFileUploading(true);
			pathBuilderApi.uploadDocument(selectedFile[0]).then((resp: any) => {
				if (resp.data) {
					updatedFiles_attachmentFiles.push({
						attachmentName: selectedFile[0].name,
						attachmentURL: resp.data.url,
					});
					updatedFiles.push(selectedFile[0]);
					setAttachmentFiles(updatedFiles_attachmentFiles);
					setFiles(updatedFiles);
					event.target.value = '';
					setFileUploading(false);
				} else {
					toaster.negative(
						<ENGTToasterContainer
							title={t('common:error')}
							description={t('components:feedBackForm.failToUpload')}
						/>,
						{}
					);
					event.target.value = '';
					setFileUploading(false);
				}
			});
		} else {
			setFileUploading(true);
			pathBuilderApi.postImage(selectedFile[0]).then((resp: any) => {
				if (resp.data) {
					updatedFiles_attachmentFiles.push({
						attachmentName: selectedFile[0].name,
						attachmentURL: resp.data,
					});
					updatedFiles.push(selectedFile[0]);
					setAttachmentFiles(updatedFiles_attachmentFiles);
					setFiles(updatedFiles);
					event.target.value = '';
					setFileUploading(false);
				} else {
					toaster.negative(
						<ENGTToasterContainer
							title={t('common:error')}
							description={t('components:feedBackForm.failToUpload')}
						/>,
						{}
					);
					event.target.value = '';
					setFileUploading(false);
				}
			});
		}

		setFileNotUploadState('');
	};
	function removeFile(index: number) {
		const updatedFiles = [...files];
		updatedFiles.splice(index, 1);
		setFiles(updatedFiles);

		const updatedFiles_fileforback = [...attachmentFiles];
		updatedFiles_fileforback.splice(index, 1);
		setAttachmentFiles(updatedFiles_fileforback);
	}
	function clearFiles() {
		setAttachmentFiles([]);
		setFiles([]);
	}
	const onConfirm = (e: any) => {
		e.preventDefault();
		setLoading(true);
		if (getValues('description').trim() !== '') {
			const data = {
				topic: feedBackOn[0].label,
				type: feedBackTypeButtonName,
				description: getValues('description').trim(),
				attachmentList: attachmentFiles,
				fromEmailId: botAdminEmail,
				customerPlan,
			};

			API.submitFeedbackRequest(data).then((response: any) => {
				if (response.data) {
					toaster.positive(
						<ENGTToasterContainer
							title={t('components:feedBackForm.feedbackSubmitted')}
							description={t('components:feedBackForm.feedbackSaved')}
						/>,
						{}
					);
					toggleModal(false);
				} else {
					toaster.negative(
						<ENGTToasterContainer
							title={t('pages:accountSettings.accountDetailsPage.feedBack.title')}
							description={t('pages:accountSettings.accountDetailsPage.feedBack.description')}
						/>,
						{}
					);
				}
				setFileNotUploadState('');
				setFeedBackOn([]);
				setfeedBackTypeButtonName(FEEDBACK_FORM.NEWFEATUREREQEUST);
				setAttachmentFiles([]);
				setFiles([]);
				setisFeedBackon(false);
				setDescriptionState('');
				setValue('description', '');
				setLoading(false);
			});
		} else {
			setDescriptionState(t('components:feedBackForm.descriptionNeed').toString());
			setLoading(false);
			setValue('description', '');
		}
	};

	return (
		<ActionModal
			heading={t('pages:banner.modal.heading')}
			isFooterReq
			isOpen={isOpen}
			onClose={() => {
				toggleModal(false);
				setAttachmentFiles([]);
				setFiles([]);
				setFeedBackOn([]);
				setDescriptionState('');
				setisFeedBackon(false);
				setFileNotUploadState('');
			}}
			onConfirm={onConfirm}
			autoFocus={false}
			confirmBtnLabel={t('components:feedBackForm.continue').toString()}
			isConfirmBtnDisabled={!isFeedBackon || isFileUploading}
			isLoading={isLoading}
			data-posthog-element-id='feedback'
		>
			<FormProvider {...methods}>
				<FormLabel
					id='feedback-on'
					label={t('components:feedBackForm.feedBackOn')}
					className={{ marginBottom: '0.5rem', fontSize: '1rem', fontWeight: 500 }}
				/>
				<ENGTSelect
					onChange={(value: any) => {
						setFeedBackOn(value);
						setisFeedBackon(true);
					}}
					overrides={{
						ControlContainer: {
							style: {
								fontSize: '1rem',
								fontWeight: 400,
							},
						},
					}}
					options={messageTypeOptions}
					clearable={false}
					popupIndex={100}
					dataQa=''
					selectedValue={feedBackOn}
				/>
				<FormLabel
					id='feedback-type'
					label={t('components:feedBackForm.feedBackType')}
					className={{ marginTop: '1rem', marginBottom: '0.5rem', fontSize: '1rem', fontWeight: 500 }}
				/>
				<FormRadio
					name='feedBackType'
					radioButtons={feedBackTypeOptions}
					type='stateless'
					align='horizontal'
					onChange={(val: any) => {
						setfeedBackTypeButtonName(val);
					}}
				/>
				<FormLabel
					id={t('components:feedBackForm.description')}
					label={t('components:feedBackForm.description')}
					tooltip={t('components:feedBackForm.tooltips.description')}
					className={{
						marginTop: '1rem',
						marginBottom: '0.5rem',
						fontSize: '1rem',
						fontWeight: 500,
					}}
				/>
				<Controller
					name='description'
					render={({ register, value, onChange }: any) => (
						<ENGTTextarea
							inputRef={register}
							value={value}
							onChange={(e) => {
								onChange(e);
								setDescriptionState('');
							}}
							name='description'
							placeholder={t('components:feedBackForm.descriptionPlaceholder')}
							size={SIZE.large}
							height='4rem'
							resize
							maxLength={1000}
						/>
					)}
				/>
				{descriptionState.length > 0 && <div className={css(errorMsgCss)}>{descriptionState}</div>}
				<FormLabel
					id='upload-files'
					label={t('components:feedBackForm.uploadFiles')}
					className={{ marginTop: '1rem', marginBottom: '0.5rem', fontSize: '1rem', fontWeight: 500 }}
					tooltip={t('components:feedBackForm.tooltips.uploadFiles')}
				/>

				<div
					className={css({
						display: 'flex',
						borderWidth: files.length ? '0.063rem ' : '0.031rem ',
						borderStyle: 'solid',
						borderColor: files.length
							? theme.colors.focusedInputBorderColor
							: theme.colors.inputDisabledBgColor,
						borderRadius: '0.5rem',
						backgroundColor: theme.colors.inputFillPrimary,
						position: 'relative',
						height: '3rem',
					})}
				>
					{files.length ? (
						<>
							{files.map((file, index) => (
								<div className={css(fileCss)}>
									<span
										className={css({
											marginRight: '0.35rem',
										})}
									>
										{getEllipsisedText(file.name, 6)}
									</span>

									<div
										onClick={() => removeFile(index)}
										className={css({
											cursor: 'pointer',
										})}
									>
										<ClearTextIcon size={10} fillColor={theme.colors.defaultPrimaryAColor} />
									</div>
								</div>
							))}
							<div onClick={() => clearFiles()} className={css(clearAllCss(theme))}>
								<span>{t('components:feedBackForm.clearall')}</span>
							</div>
						</>
					) : (
						<span className={css(uploadFileCss)}>{t('components:feedBackForm.uploadFiles')}</span>
					)}
				</div>
				{fileNotUploadState.length > 0 && <div className={css(errorMsgCss)}>{fileNotUploadState}</div>}
				<div
					className={css(uploadButtonContainerCss(theme))}
					onClick={() => {
						if (files.length === MAX_FILES || isFileUploading) {
						} else {
							document.getElementById('file-input')?.click();
						}
					}}
				>
					<PlusIcon fill={!isUploadDisable ? theme.colors.primaryA : theme.colors.goToSettingsBtnColor} />
					<span
						className={css({
							paddingTop: '0.188rem',
						})}
					>
						{t('components:feedBackForm.upload')}
						<input
							type='file'
							id='file-input'
							accept='.pdf, .jpg, .jpeg, .png'
							multiple={false}
							style={{ display: 'none' }}
							onChange={handleFileUpload}
						/>
					</span>
				</div>
			</FormProvider>
		</ActionModal>
	);
};

export default FeedBackForm;
