import BaseService from 'services/baseService';
import { serialize } from 'shared/helpers';

const WHATSAPP_BASE_URL = '/whatsApp';
const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const getWhatsAppQrCode = '/api/v1/get_whatsapp_qr_code/';
const waCommerceSignInUrl = `${WHATSAPP_BASE_URL}/fb_oath/`;
const getFBMAccountsUrl = `${WHATSAPP_BASE_URL}/get_fbm/`;
const getWaCatalogsUrl = `${WHATSAPP_BASE_URL}/get_catalogs/`;
const linkWaCatalogUrl = `${WHATSAPP_BASE_URL}/link_catalog/`;
const logoutWaCommerceUrl = 'whatsApp/logout_fb';
const getWaProductsUrl = 'whatsApp/get_catalog_products/';
const loginWhatsAppCloudUrl = '/whatsAppCloud/tsp/fb_oauth/';
const loginWhatsAppMetaCloudUrl = '/whatsAppCloud/fb_oauth/';
const fetchAvailableNumberUrl = '/list_of_available_numbers/';
const logoutWhatsAppCloudUrl = '/whatsAppCloud/logout_fb/';
const connectNumberUrl = '/whatsAppCloud/connect_fbm_wa_number';
const saveAdditionalWaCloudSettingsUrl = '/whatsAppCloud/save_additional_settings/';

const genericProxyRequestAPI = new BaseService(genericProxyRequestUrl);
const getWhatsAppQrCodeAPI = new BaseService(getWhatsAppQrCode);
const waCommerceSignInAPI = new BaseService(waCommerceSignInUrl);
const getFBMAccountsAPI = new BaseService(getFBMAccountsUrl);
const getWaCatalogsAPI = new BaseService(getWaCatalogsUrl);
const linkWaCatalogAPI = new BaseService(linkWaCatalogUrl);
const logoutWaCommerceAPI = new BaseService(logoutWaCommerceUrl);
const getWaProductsAPI = new BaseService(getWaProductsUrl);
const loginWhatsAppCloudAPI = new BaseService(loginWhatsAppCloudUrl);
const loginWhatsAppCloudMetaAPI = new BaseService(loginWhatsAppMetaCloudUrl);
const fetchAvailableNumberAPI = new BaseService(fetchAvailableNumberUrl);
const logoutWhatsAppCloudAPI = new BaseService(logoutWhatsAppCloudUrl);
const connectNumberAPI = new BaseService(connectNumberUrl);
const saveAdditionalWaCloudSettingsAPI = new BaseService(saveAdditionalWaCloudSettingsUrl);

export const whatsAppAPI = {
	getWhatsAppQrCode(phoneNumber, prefilledMsg) {
		const params = { phone_number: phoneNumber, prefilled_msg: prefilledMsg };

		return getWhatsAppQrCodeAPI.getRequest(serialize(params, '?'));
	},

	waCommerceSignIn(data) {
		return waCommerceSignInAPI.postRequest(data);
	},

	getWhatsAppCommerceDetails() {
		const params = {
			targetApi: 'whatsAppCommerceDetails',
		};

		return genericProxyRequestAPI.getRequestWithParams(params);
	},

	getAccountDetails(botRef, provider) {
		const params = {
			targetApi: 'getAccountDetails',
			botRef,
			provider,
		};

		return genericProxyRequestAPI.getRequestWithParams(params);
	},

	getCloudApiDetails(botRef) {
		const params = {
			targetApi: 'getCloudApiDetails',
			botRef,
		};

		return genericProxyRequestAPI.getRequestWithParams(params);
	},

	getFBMAccounts(afterCursor) {
		const params = {
			after_cursor: afterCursor,
		};

		return getFBMAccountsAPI.getRequest(serialize(params, '?'));
	},

	getWaCatalogs(businessId, afterCursor) {
		const params = {
			businessId,
			after_cursor: afterCursor,
		};

		return getWaCatalogsAPI.getRequest(serialize(params, '?'));
	},

	linkWaCatalog(data) {
		return linkWaCatalogAPI.postRequest(data);
	},

	logoutWaCommerce() {
		return logoutWaCommerceAPI.getRequest();
	},

	getWaProducts(catalogId) {
		const params = {
			catalogId,
		};

		return getWaProductsAPI.getRequest(serialize(params, '?'));
	},

	updateWhatsAppCommerceSettingsDetails(catalogId, payload) {
		const params = {
			targetApi: 'whatsAppCommerceDetails',
			catalogId,
		};

		return genericProxyRequestAPI.putRequest(payload, serialize(params, '?'));
	},

	loginWaCloud(data) {
		return loginWhatsAppCloudAPI.postRequest(data);
	},

	loginWaCloudMetaTSP(data) {
		return loginWhatsAppCloudMetaAPI.postRequest(data);
	},

	fetchAvailableNumber(afterCursor) {
		const params = {
			after_cursor: afterCursor,
		};

		return fetchAvailableNumberAPI.getRequest(serialize(params, '?'));
	},

	connectWaNumber(payload) {
		return connectNumberAPI.postRequest(payload);
	},

	logoutWaCloud() {
		return logoutWhatsAppCloudAPI.getRequest();
	},

	saveAdditionalWaCloudSettings(payload) {
		return saveAdditionalWaCloudSettingsAPI.postRequest(payload);
	},

	getWhatsAppCommerceComplianceInfo() {
		const params = {
			targetApi: 'whatsappCommerceComplianceInfo',
		};

		return genericProxyRequestAPI.getRequest(serialize(params, '?'));
	},

	postWhatsAppCommerceComplianceInfo(data) {
		const params = {
			targetApi: 'whatsappCommerceComplianceInfo',
		};

		return genericProxyRequestAPI.postRequest(data, serialize(params, '?'));
	},

	getWAClientWebhookUrlAndHeaders(botRef){
		const params = {
			targetApi: 'getWAClientWebhookUrlAndHeaders',
			botRef,
		};

		return genericProxyRequestAPI.getRequestWithParams(params);
	},
};
