import React from 'react';
import { useStyletron } from 'baseui';
import { useDispatch } from 'react-redux';

import CrossButton from 'shared/icons/CrossBtn';

import { common } from 'store/Common';

const Close = () => {
	const [css, theme]: any = useStyletron();
	const dispatch = useDispatch<any>();

	return (
		<div
			className={css({
				position: 'absolute',
				top: '1rem',
				right: '1rem',
				zIndex: theme.zIndex100,
			})}
		>
			<CrossButton
				onClose={() => {
					dispatch(common.closeMobileMenu());
				}}
			/>
		</div>
	);
};

export default Close;
