import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';
import { API_GATEWAY_URL } from 'shared/consts/consts';

const downloadDataUrl = '/api/v1/export/save/';
const downloadExportStatusUrl = '/api/v1/export/status/';
const downloadDataCSVUrl = '/api/v1/download/fileuploadnodefile/';
const downloadCSVUrl = '/api/v1/download/fileuploadnode/file';
const downloadUploadedData = '/v2/download_file';
const v2genricProxyUrl = '/v2/genericProxyRequest/';

const downloadDataApi = new BaseService(downloadDataUrl);
const downloadExportStatusApi = new BaseService(downloadExportStatusUrl);
const downloadDataCSVApi = new BaseService(downloadDataCSVUrl, {
	responseType: 'arraybuffer',
});
const downloadUploadedDataApi = new BaseService(downloadUploadedData);
const v2GenricProxyApi = new BaseService(v2genricProxyUrl);
const downloadCSVApi = new BaseService(downloadCSVUrl, {
	responseType: 'arraybuffer',
});
const getNodeSearchResultApi = new BaseService(API_GATEWAY_URL);

export const DownloadAPI = {
	downloadData(data) {
		return downloadDataApi.postRequest(data);
	},
	downloadExportStatus(params) {
		return downloadExportStatusApi.getRequest(serialize(params, '?'));
	},
	downloadAgentProductivityStatus(params) {
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	downloadDataCSV(params) {
		return downloadDataCSVApi.getRequest(serialize(params, '?'));
	},
	downloadUploadedData(params) {
		return downloadUploadedDataApi.getRequest(serialize(params, '?'));
	},
	downloadCampaignUsersDataCsv(params) {
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	downloadCampaignExportStatus(params) {
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	downloadBroadcastExportStatus(params) {
		return v2GenricProxyApi.getRequest(serialize(params, '?'));
	},
	downloadBroadcastUsersDataV2(data) {
		const params = {
			targetApi: 'exportCampaignData',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	downloadBroadcastTargetUsers(data) {
		const params = {
			targetApi: 'exportTotalBroadcastUsers',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	downloadBroadcastFailedUsers(data) {
		const params = {
			targetApi: 'exportFailedBroadcastUsers',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	exportCampaignUsersData(data) {
		const params = {
			targetApi: 'exportCampaignUsersData',
		};

		return v2GenricProxyApi.postRequest(data, serialize(params, '?'));
	},
	exportCamapigns(data) {},
	getCreditExportHistoryStatus(data) {
		return v2GenricProxyApi.getRequestWithParams(data);
	},
	downloadCSVFile(params) {
		return downloadCSVApi.getRequest(serialize(params, '?'));
	},
	getConversationReportHistoryStatus: (data) => v2GenricProxyApi.getRequestWithParams(data),

	getAggregatedAnalyticsExport: (data) => v2GenricProxyApi.getRequest(serialize(data, '?')),
};
