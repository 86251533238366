import { IObjProps } from 'shared/consts/types';

import { IBotListStateInterface } from './state';

interface IBotListContentInterface {
	content: IObjProps[];
	query: string;
}

export const botListReducers = {
	getBotList: (state: IBotListStateInterface, action: { payload: IObjProps[] }) => {
		const botList = [...action?.payload];
		state.data = botList?.sort((currentBot, nextBot) => currentBot?.bot_name?.localeCompare(nextBot?.bot_name));
		state.search.list = [];
		state.search.query = '';
		state.error = false;
		state.loading = false;
		state.botRefList = botList?.map((bot: any) => bot?.bot_ref);
	},
	getBotListError: (state: IBotListStateInterface) => {
		state.data = [];
		state.search.list = [];
		state.search.query = '';
		state.error = true;
		state.loading = false;
	},
	getBotsByQuery: (state: IBotListStateInterface, action: { payload: IBotListContentInterface }) => {
		const { content, query } = action.payload;

		state.search.list = [...content];
		state.search.query = query;
	},
	resetBotList: (state: IBotListStateInterface) => {
		state.data = [];
		state.search.list = [];
		state.search.query = '';
		state.error = false;
		state.loading = true;
	},
	updateBotList: (state: IBotListStateInterface, action: { payload: IObjProps[] }) => {
		state.data = [...action.payload];
	},
};
