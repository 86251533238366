import { RootState } from 'store/rootReducer';

export const offeringKey = (state: RootState) => state.Plan.current.data.offeringKey;

export const customerPlanData = (state: RootState) => state.Plan.current.data;

export const currentPlanStatusSelector = (state: RootState) => state.Plan.current.data.status;

export const customerPlanDetailsSelector = (state: RootState) => state.Plan.current.data;

export const userListSelector = (state: RootState) => state.Message.userList;
