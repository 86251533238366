import { ACCOUNT_TYPES } from 'shared/consts/consts';

export const setAdminModeInLs = () => {
	localStorage.removeItem('storeDataVersion');
	localStorage.removeItem('storeData');
	localStorage.setItem('adminMode', 'false');
	window.location.href = '/';
};

export const isCurrentUserNotAdmin = (email: string) =>
	localStorage.getItem('loggedInUsercontext') && localStorage.getItem('loggedInUsercontext') !== email;

export const getAdminPartnerMode = (isReseller: boolean, accountType: string, email: string) => {
	const { MASTER_ADMIN, RESELLER, AFFILIATE } = ACCOUNT_TYPES;
	let adminMode = false;
	let isPartner = !!isCurrentUserNotAdmin(email);

	if (isReseller && [MASTER_ADMIN, RESELLER, AFFILIATE].indexOf(accountType) > -1) {
		isPartner = true;

		if (localStorage.getItem('adminMode') !== null) {
			adminMode = localStorage.getItem('adminMode') === 'true';
		} else {
			adminMode = true;
		}
		localStorage.setItem('adminMode', `${adminMode}`);
	}

	return [adminMode, isPartner];
};
