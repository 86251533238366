import { useMemo } from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { milliSecondsToDays } from 'shared/helpers';

import { RootState } from 'store/withReducer';

interface ItrialDaysLeftProps {
	trialMessage?: string;
}

const TrialDaysLeft = ({ trialMessage }: ItrialDaysLeftProps) => {
	const [css]: [any, any] = useStyletron();
	const { t } = useTranslation(['pages']);

	const customerTrialPlanEndDate = useSelector((state: RootState) => state.Plan.current.data.trialPlanEndDate);

	const daysLeft: number | null = useMemo(() => {
		if (customerTrialPlanEndDate) {
			const timeleft = new Date(customerTrialPlanEndDate).getTime() - new Date().getTime();
			const days = Math.ceil(milliSecondsToDays(timeleft));

			return days;
		}

		return null;
	}, [customerTrialPlanEndDate]);

	if (customerTrialPlanEndDate && daysLeft !== null) {
		if (daysLeft <= 0) {
			return (
				<span
					className={css({
						cursor: 'default',
					})}
				>
					{t('common:trialEndsToday')}
					<span
						className={css({
							fontWeight: 600,
							textTransform: 'capitalize',
						})}
					>
						{t('common:today')}
					</span>
				</span>
			);
		}

		return (
			<span
				className={css({
					cursor: 'default',
				})}
			>
				{trialMessage || t('common:trialEnds')}{' '}
				<span
					className={css({
						fontWeight: 600,
					})}
				>
					{t('common:xDays', { noOfDays: daysLeft })}
				</span>
			</span>
		);
	}

	return <></>;
};
export default TrialDaysLeft;
