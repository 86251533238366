import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const getAgentSettingUrl = genericProxyRequestUrl + serialize({ targetApi: 'getAgentSetting' }, '?');
const setAgentSettingUrl = genericProxyRequestUrl + serialize({ targetApi: 'upsertAgentSetting' }, '?');

const headers = {
	'Content-Type': 'application/json',
	'audit-metadata': '{{audit-metadata}}',
};

const getGeneralSettingsApi = new BaseService(genericProxyRequestUrl);
const getAgentSettingApi = new BaseService(getAgentSettingUrl);
const setAgentSettingApi = new BaseService(setAgentSettingUrl, { headers });

export const API = {
	getAgentSetting() {
		return getAgentSettingApi.getRequest();
	},
	setAgentSetting(payload) {
		return setAgentSettingApi.putRequest(payload);
	},
	getThemeSettings() {
		const params = {
			targetApi: 'themeSettings',
		};

		return getGeneralSettingsApi.getRequest(serialize(params, '?'));
	},
};
