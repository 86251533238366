import { createSlice } from '@reduxjs/toolkit';

import { builderReducer } from './reducers';
import { builderState } from './state';

export const builderSlice = createSlice({
	name: 'PathBuilder',
	initialState: builderState,
	reducers: builderReducer,
});

export const { actions, reducer } = builderSlice;
