import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Button, KIND, SIZE } from 'baseui/button';
import { Overflow } from 'baseui/icon';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ENGTMenuItem from 'components/UI/ENGTMenuItem/ENGTMenuItem';

import { circularButtonCss } from 'shared/consts/consts';
import { writeToLs } from 'shared/consts/localStorageConsts';
import { IObjProps } from 'shared/consts/types';
import { CHANNELS } from 'shared/enum';
import { getDefaultBroadcastColumnKey } from 'shared/helpers/campaignHelpers';

import { botRefSelector } from 'store/Bot/selectors';
import { broadcastFilterActions } from 'store/Broadcast';
import { publishedBroadcastList } from 'store/Broadcast/selectors';
import { customerIdSelector, exportbroadcastLevelAnalytics, userId } from 'store/Users/selectors';

import ConfigureBroadcastColumns from './ConfigureBroadcastColumns';

interface IBroadcastMenu {
	columns: IObjProps;
	defaultColumns: IObjProps;
	setDefaultColumns: Function;
}

function BroadcastMenu({ columns, defaultColumns, setDefaultColumns }: IBroadcastMenu) {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common', 'pages']);
	const dispatch = useDispatch<any>();

	const [isModalOpen, toggleModal] = useState(false);
	const [checkBoxesState, setCheckBoxesState] = useState<IObjProps>({});
	const [initialCheckBoxesState, setInitialCheckBoxesState] = useState<any>({});

	const { data } = useSelector(publishedBroadcastList);
	const cid = useSelector(customerIdSelector);
	const uid = useSelector(userId);
	const botRef = useSelector(botRefSelector);
	const isExportbroadcastLevelAnalyticsEnabled = useSelector(exportbroadcastLevelAnalytics);
	const s3DownloadLinkForPW =
		'https://file-upload-private.s3.ap-south-1.amazonaws.com/prod/72499/EXPORT_BROADCAST/all-broadcast-summary-report-83679.csv';

	useEffect(() => {
		const initialState: any = {};
		Object.keys(columns).forEach((key: any) => {
			initialState[key] = !!defaultColumns[key];
		});
		setCheckBoxesState(initialState);
		setInitialCheckBoxesState(initialState);
	}, [defaultColumns, columns]);

	const configureColumnApply = (close?: any) => {
		const newDefaultColumns: any = {};
		Object.entries(checkBoxesState).forEach(([key, data]) => {
			if (data) {
				newDefaultColumns[key] = columns[key];
			}
		});

		const statusValue = newDefaultColumns.status;
		delete newDefaultColumns.status;
		newDefaultColumns.status = statusValue;

		const columnConfigKey = getDefaultBroadcastColumnKey(cid, uid, botRef);
		writeToLs(columnConfigKey, JSON.stringify(newDefaultColumns));
		setDefaultColumns(newDefaultColumns);

		toggleModal(false);
		close && close();
	};

	const checkIfFilterIsNotApplied = () => {
		const { filters }: IObjProps = dispatch(broadcastFilterActions.getFiltersFromLs());

		return (
			filters.startDate === '' &&
			filters.endDate === '' &&
			(filters.channels === '' || filters.channels === CHANNELS.ALL_CHANNELS)
		);
	};

	return (
		<StatefulPopover
			overrides={{
				Inner: {
					style: {
						backgroundColor: 'transparent',
					},
				},
				Body: {
					style: ({ $theme }: any) => ({
						marginTop: '1rem',
						paddingTop: '0rem',
						paddingBottom: '0rem',
						paddingRight: '0rem',
						paddingLeft: '0rem',
						zIndex: $theme.zIndex300,
						backgroundColor: $theme.colors.backgroundPrimary,
					}),
				},
			}}
			accessibilityType='none'
			onOpen={() => {}}
			onClose={() => {}}
			placement={PLACEMENT.bottomRight}
			triggerType={TRIGGER_TYPE.click}
			content={({ close }) => (
				<div>
					<ul
						className={css({
							paddingTop: '0.2rem',
							paddingBottom: '0.2rem',
							paddingRight: '0.2rem',
							paddingLeft: '0.2rem',
							cursor: 'pointer',
							marginTop: '0rem',
							marginBottom: '0rem',
							marginLeft: '0rem',
							marginRight: '0rem',
							minWidth: '10rem',
							width: 'fit-content',
							listStyleType: 'none',
							boxShadow: theme.lighting.shadow400,
							borderRadius: theme.borders.radius300,
							background: theme.colors.buttonPrimaryText,
						})}
					>
						{data.length > 0 && (
							<>
								<li key='configureColumns' onClick={() => toggleModal(true)}>
									<ENGTMenuItem label={t('pages:users.configureColumns')} />
								</li>
								{checkIfFilterIsNotApplied() && isExportbroadcastLevelAnalyticsEnabled && (
									<li key='downloadAnalytics'>
										<a
											href={s3DownloadLinkForPW}
											rel='noreferrer'
											className={css({ textDecoration: 'none', cursor: 'pointer' })}
										>
											<ENGTMenuItem label={t('pages:users.downloadAnalytics')} />
										</a>
									</li>
								)}
							</>
						)}
					</ul>
					<ConfigureBroadcastColumns
						close={() => {
							setCheckBoxesState(initialCheckBoxesState);
							close && close();
						}}
						checkBoxesState={checkBoxesState}
						setCheckBoxesState={setCheckBoxesState}
						columns={columns}
						isModalOpen={isModalOpen}
						toggleModal={toggleModal}
						configureColumnApply={configureColumnApply}
					/>
				</div>
			)}
		>
			<Button
				size={SIZE.default}
				shape='circle'
				kind={KIND.secondary}
				overrides={{
					BaseButton: {
						style: circularButtonCss,
					},
				}}
			>
				<Overflow size={24} title='' />
			</Button>
		</StatefulPopover>
	);
}

export default BroadcastMenu;
