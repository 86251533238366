import { BROADCAST } from 'shared/consts/QAConsts';

function Broadcast({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			width={size || 40}
			height={size || 40}
			viewBox='0 0 40 40'
			fill='none'
			className={className}
			data-qa={BROADCAST}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='inherit'>
				<path
					d='M19.9992 22.5628V14.9359C19.783 14.9559 19.5692 14.9897 19.3517 14.9997H13.1247C12.7798 14.9997 12.4998 15.2796 12.4998 15.6246V16.2496H10.6249C10.28 16.2496 10 16.5295 10 16.8745V20.6242C10 20.9692 10.28 21.2492 10.6249 21.2492H12.4998V21.8741C12.4998 22.2191 12.7798 22.4991 13.1247 22.4991H13.5035L16.0158 29.196C16.1695 29.5972 16.5132 29.8885 16.9357 29.9734C17.0194 29.9909 17.1032 29.9997 17.1857 29.9997C17.5194 29.9997 17.8369 29.8672 18.0643 29.6297L19.5655 28.1411C19.9492 27.7574 20.0404 27.1587 19.7955 26.695L17.6981 22.4991H19.358C19.573 22.509 19.7855 22.5428 19.9992 22.5628Z'
					fill={fillColor || '#403F42'}
				/>
				<path
					opacity='0.7'
					d='M29.8958 10.3025C29.8833 10.2812 29.8721 10.2637 29.8558 10.2437C29.7871 10.1562 29.7008 10.085 29.5946 10.0437C29.5908 10.0425 29.5883 10.0375 29.5846 10.0362C29.5721 10.0325 29.5609 10.0387 29.5484 10.035C29.4921 10.0187 29.4359 10 29.3734 10C29.3309 10 29.2934 10.0162 29.2521 10.025C29.2284 10.03 29.2059 10.0312 29.1821 10.0387C29.0746 10.0737 28.9821 10.1337 28.9084 10.2162C28.9034 10.2212 28.8959 10.2225 28.8909 10.2275C26.9423 12.5935 24.225 14.1759 21.249 14.7559V22.744C24.225 23.3239 26.9423 24.905 28.8909 27.2711C28.8959 27.2774 28.9047 27.2799 28.9109 27.2874C28.9547 27.3361 29.0084 27.3748 29.0659 27.4073C29.0846 27.4186 29.0996 27.4348 29.1184 27.4436C29.1971 27.4773 29.2821 27.4986 29.3734 27.4986C29.4446 27.4986 29.5146 27.4861 29.5846 27.4623C29.5883 27.4611 29.5908 27.4561 29.5946 27.4548C29.7008 27.4136 29.7871 27.3436 29.8558 27.2549C29.8708 27.2349 29.8821 27.2174 29.8958 27.1961C29.9558 27.0999 29.9983 26.9936 29.9983 26.8736V10.6249C29.9983 10.505 29.9558 10.3987 29.8958 10.3025Z'
					fill={fillColor || '#403F42'}
				/>
			</g>
		</svg>
	);
}

export default Broadcast;
