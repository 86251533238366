import { IApiDataProps, IObjProps } from 'shared/consts/types';

export const botState: IApiDataProps<IObjProps> & {
	configData: IObjProps;
	configuredChannels: Array<string>;
	generalBotConfigData: IObjProps;
	videoConfigData: IObjProps;
	googleAnalyticsSettingsEnabled: boolean;
	agentOnlineStatus: boolean;
} = {
	configData: {},
	configuredChannels: [],
	data: {},
	error: false,
	loading: true,
	generalBotConfigData: {},
	videoConfigData: {},
	googleAnalyticsSettingsEnabled: false,
	agentOnlineStatus: false,
};

export type IBotsDetailStateInterface = typeof botState;
