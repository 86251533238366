import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useSelector } from 'react-redux';

import { ProfileAvatar } from 'components/UI/ProfileAvatar/ProfileAvatar';

import { RootState } from 'store/rootReducer';

function ProfileHeader() {
	const [, theme]: any = useStyletron();

	const userName = useSelector((state: RootState) => state.User.profile.data.name);
	const userAvatar = useSelector((state: RootState) => state.User.profile.data.profileImg);
	const userEmail = useSelector((state: RootState) => state.User.botAdmin.data.email);

	return (
		<Block display='flex' justifyContent='flex-start' alignItems='center' margin='1rem'>
			<ProfileAvatar
				bgColor={theme.colors.defaultPrimaryAColor}
				userEmail={userEmail}
				initialsFontSize='1.5rem'
				userAvatar={userAvatar}
				userName={userName}
				userAvatarSize='3.5rem'
			/>
		</Block>
	);
}

export default ProfileHeader;
