import { MessageScreens } from 'shared/enum';

import { IMessageCommonInterface } from './state';

const messageCommonReducer = {
	openMessagesScreen: (state: IMessageCommonInterface, action: { payload: MessageScreens }) => {
		state.mobileVisibleScreen = action.payload;
	},
};

export default messageCommonReducer;
