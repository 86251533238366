import { useSelector } from 'react-redux';

import loadable from '@loadable/component';

import { RootState } from 'store/rootReducer';

const Jitsi = loadable(() => import('components/Jitsi/JitsiComponent' /* webpackChunkName: "JitsiComponent" */));

function JitsiApp() {
	const isVideoConversationEnabled = useSelector(
		(state: RootState) => state.Bot.configData.enable_video_conversation || false
	);

	return isVideoConversationEnabled ? <Jitsi /> : <></>;
}

export default JitsiApp;
