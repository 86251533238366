export enum ONEVIEW_SORT_FILTER_OPTIONS {
	SOCIAL_INBOX_SORT = 'socialInboxSort',
	SOCIAL_INBOX_FILTER = 'socialInboxFilter',
	DIRECT_MESSAGES_SORT = 'directMessagesSort',
	DIRECT_MESSAGES_FILTER = 'directMessagesFilter',
}

export enum SORTING {
	unreadDesc = 'unread,desc',
	createdAtAsc = 'createdAt,asc',
	createdAtDesc = 'createdAt,desc',
	recentFirst = 'lastMessageTime,desc',
	recentUserFirst = 'lastActiveAt,desc',
	negativeSentimentFirst = 'negativeSentimentFirst',
	conversationClosingTimeLTH = 'windowClosingTime,asc',
	conversationClosingTimeHTL = 'windowClosingTime,desc',
}

export enum messageSort {
	unreadDesc = 'unread,desc',
	createdAtAsc = 'createdAt,asc',
	createdAtDesc = 'createdAt,desc',
	recentFirst = 'lastMessageTime,desc',
	recentUserFirst = 'lastActiveAt,desc',
	negativeSentimentFirst = 'negativeSentimentFirst',
}

export enum messageSortAllScreen {
	createdAtAsc = 'created_at,asc',
	createdAtDesc = 'created_at,desc',
	recentFirst = 'last_message_time,desc',
	recentUserFirst = 'last_active_at,desc',
	conversationClosingTimeLTH = 'windowClosingTime,asc',
	conversationClosingTimeHTL = 'windowClosingTime,desc',
}

export enum FLOW_CONVERSATION {
	'CONVERSATIONS' = 'CONVERSATIONS',
	'RECENT_CONVERSATIONS' = 'RECENT_CONVERSATIONS',
	'USER_CONVERSATIONS' = 'USER_CONVERSATIONS',
}

export enum AVAILABLE_BANNERS {
	AGENT_NO_CATEGORY_CHANNEL = 'AGENT_NO_CATEGORY_CHANNEL',
	CHAT_WINDOW_LIMIT_24_HOURS = 'CHAT_WINDOW_LIMIT_24_HOURS',
	CHAT_WINDOW_LIMIT_7_DAYS = 'CHAT_WINDOW_LIMIT_7_DAYS',
	NO_CATEGORY_CHANNEL = 'NO_CATEGORY_CHANNEL',
	WEB_USER_CLOSED_CHAT = 'WEB_USER_CLOSED_CHAT',
	CONVERSATION_ASSIGNED_TO = 'CONVERSATION_ASSIGNED_TO',
	USER_NOT_INITIATED = 'USER_NOT_INITIATED',
	SWITCH_TO_BOT = 'SWITCH_TO_BOT',
}

export enum DEFAULT_LAST_MESSAGE_FILTER_IDS {
	BOT = 'BOT',
	AGENT = 'AGENT',
	USER = 'USER',
}

export enum MessageScreens {
	LEFT = 'LEFT',
	CONTENT = 'CONTENT',
	RIGHT = 'RIGHT',
}

export enum LiveChatJourney {
	ALL_MESSAGES = 'welcomeJourneyMessageList',
	BASE_PARAM = 'welcomeJourneyLivechat',
	START_CONVERSATION = 'startConversation',
	SEND_MESSAGE = 'sendMessage',
	TAG = 'tag',
	RESOLVE = 'resolve',
	FIRST_MESSAGE_SENT = 'firstMessageSent',
	ADVANCED_FILTERS = 'advancedFilters',
	QUICK_FILTERS = 'quickFilters',
	ONLINE_STATUS = 'onlineStatus',
	VIEW_CONVERSATION = 'viewConversation',
}

export enum SHORTCUT_QUICK_FILTER {
	NONE = 'none',
	ONE_HOUR_WA_LIMIT = '1_hrs_wa_limit',
	THREE_HOURS_WA_LIMIT = '3_hrs_wa_limit',
	SIX_HOURS_WA_LIMIT = '6_hrs_wa_limit',
	FIRST_RESPONSE_BREACH = 'sla_first_response_breach',
	FIRST_RESOLUTION = 'sla_resolution_breach',
}

export enum SLA_FILTER {
	FIRST_RESPONSE = 'First Response',
}
