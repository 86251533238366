import { IObjProps } from 'shared/consts/types';
import { isValidNestedResponseObj, isValidResponseObject } from 'shared/helpers';

import { API } from 'store/Campaign/api';

import { actions } from './store';

const { setCampaignsLoading, setCampaignData, setCampaignDataError } = actions;

export const getCampaignAction = (params: IObjProps) => (dispatch: any) => {
	dispatch(setCampaignsLoading(true));
	API.getCampaigns(params).then(
		(resp: any) => {
			if (isValidNestedResponseObj(resp)) {
				dispatch(setCampaignData(getPaginationPayload(resp.data.response_obj.responseObject)));
			} else {
				dispatch(
					setCampaignDataError({
						isError: true,
						isLoading: false,
					})
				);
			}
		},
		() => {
			dispatch(
				setCampaignDataError({
					isError: true,
					isLoading: false,
				})
			);
		}
	);
};
export const getAnalyticsCampaignAction = (params: IObjProps) => (dispatch: any) => {
	dispatch(setCampaignsLoading(true));
	API.campaignAnalytics(params).then(
		(resp: IObjProps) => {
			if (isValidResponseObject(resp)) {
				dispatch(setCampaignData(getPaginationPayload(resp.data.responseObject)));
			} else {
				dispatch(
					setCampaignDataError({
						isError: true,
						isLoading: false,
					})
				);
			}
		},
		() => {
			dispatch(
				setCampaignDataError({
					isError: true,
					isLoading: false,
				})
			);
		}
	);
};
const getPaginationPayload = (result: IObjProps) => ({
	data: result?.content,
	pagination: {
		size: result.size,
		page: result.pageable.pageNumber + 1,
		totalPages: result.totalPages,
		totalElements: result.totalElements,
	},
	isError: false,
	isLoading: false,
});
