import React from 'react';

import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';

function ErrorContainer({ description, position }: { description?: any; position?: any }) {
	return (
		<ENGTTooltip content={description} ignoreBoundary>
			<svg
				width='12'
				height='12'
				viewBox='0 0 12 12'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				x={
					position &&
					position.x +
						(position.name.length > 13 ? 2 * position.name.length : position.name.length) +
						(position.type === 'INTEGRATION' && position.name.length > 15 ? 120 : 100)
				}
				y={position && position.y + 40}
			>
				<path
					d='M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z'
					fill='#AE1536'
				/>
				<path
					d='M6 3.42139V6.42139'
					stroke='white'
					strokeWidth='1.2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M6 8.57922H6.012'
					stroke='white'
					strokeWidth='1.2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</ENGTTooltip>
	);
}

export default ErrorContainer;
