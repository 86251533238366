import { useStyletron } from 'baseui';

import { IObjProps } from 'shared/consts/types';

interface IENGTLinkProps {
	link: string;
	url?: string;
	openInNewTab?: boolean;
	overrides?: IObjProps;

	onClick?: () => void;
}

const ENGTLink = (props: IENGTLinkProps) => {
	const { link, url, openInNewTab = true, overrides = {}, onClick } = props;
	const [css, theme]: any = useStyletron();

	return (
		<a
			className={css({
				textDecoration: 'underline',
				textUnderlinePosition: 'from-font',
				color: theme.primaryNavText,
				cursor: 'pointer',
				...overrides,
			})}
			href={url}
			target={openInNewTab ? '_blank' : '_self'}
			rel='help noreferrer'
			onClick={onClick}
		>
			{link}
		</a>
	);
};

export default ENGTLink;
