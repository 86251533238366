import { STORE } from 'shared/consts/QAConsts';

function Store({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			className={className}
			data-qa={STORE}
			width={size || 30}
			height={size || 28}
			viewBox='-6 -6 30 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				opacity='0.7'
				d='M7 11V7H11V11H7Z'
				fill={fillColor || '#403F42'}
				stroke='#403F42'
				strokeWidth='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M5.122 5.24296L6.35147 2.5852L6.58564 0L4.55066 0.000338645C2.89314 0.000614474 1.40751 1.02313 0.815324 2.57125L0.0798022 4.49408C0.0264234 4.65521 -0.000522392 4.82389 7.67037e-06 4.9936C7.67037e-06 6.09621 1.14984 6.99088 2.57098 6.99088C3.13333 7.0396 3.6952 6.8921 4.16082 6.57351C4.62643 6.25492 4.96697 5.78497 5.1244 5.24376L5.122 5.24296ZM8.99921 6.99008C10.4195 6.99008 11.5702 6.09541 11.5702 4.9928C11.5702 4.95217 11.5702 4.91313 11.5654 4.87171L11.6465 2.58326L11.4128 0H6.58564L6.35147 2.5852L6.43222 4.86772C6.43222 4.90835 6.42823 4.94739 6.42823 4.9928C6.42823 6.09541 7.57967 6.99008 8.99921 6.99008ZM17.9202 4.49408L17.183 2.56951C16.5903 1.0223 15.1052 0.000614558 13.4483 0.000338796L11.4128 0L11.6465 2.58326L12.8756 5.23499C13.031 5.77781 13.3709 6.24966 13.8368 6.5695C14.3028 6.88933 14.8658 7.0372 15.429 6.98769C16.8494 6.98769 18 6.09302 18 4.99041C18 4.82074 17.9731 4.65214 17.9202 4.49089V4.49408Z'
				fill={fillColor || '#403F42'}
			/>
		</svg>
	);
}

export default Store;
