function NotificationIcon({
	width = '14',
	height = '16',
	fillColor,
}: {
	width?: string;
	height?: string;
	fillColor?: string;
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M12.9193 11.2753C11.9113 10.4233 11.3333 9.178 11.3333 7.85867V6C11.3333 3.654 9.59067 1.712 7.33333 1.38667V0.666667C7.33333 0.298 7.03467 0 6.66667 0C6.29867 0 6 0.298 6 0.666667V1.38667C3.742 1.712 2 3.654 2 6V7.85867C2 9.178 1.422 10.4233 0.408 11.2807C0.148667 11.5027 0 11.8253 0 12.1667C0 12.81 0.523333 13.3333 1.16667 13.3333H12.1667C12.81 13.3333 13.3333 12.81 13.3333 12.1667C13.3333 11.8253 13.1847 11.5027 12.9193 11.2753Z'
				fill={fillColor || '#403F42'}
			/>
			<path
				d='M6.66662 16C7.87395 16 8.88395 15.1393 9.11595 14H4.21729C4.44929 15.1393 5.45929 16 6.66662 16Z'
				fill={fillColor || '#403F42'}
			/>
		</svg>
	);
}

export default NotificationIcon;
