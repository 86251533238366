import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { IObjProps } from 'shared/consts/types';
import { isValidNestedResponseObj } from 'shared/helpers';

import { getAnalyticsCampaignAction, getCampaignAction } from 'store/Campaign';
import { API } from 'store/Campaign/api';
import { botTimezoneData, campaignsData } from 'store/Campaign/selectors';
import { openErrorSavingModalAction, openInfoModalAction } from 'store/Modal/actions';

import { RESPONSE_STATUS_DESC } from '../constants';

import useIsCampaignAnalyticsEnabledHook from './useIsCampaignAnalyticsEnabledHook';

interface IEditCampaignHook {
	selectedCampaign: IObjProps;
	setSelectedCampaign: Function;
	setLoading: Function;
}

function useEditCampaignHook({ selectedCampaign, setSelectedCampaign, setLoading }: IEditCampaignHook) {
	const { t } = useTranslation(['common', 'pages']);
	const dispatch = useDispatch<any>();

	const campaigns = useSelector(campaignsData);
	const { size, page } = campaigns?.pagination;

	const botTimezone: string = useSelector(botTimezoneData);
	const showWhatsappCampaignAnalytics = useIsCampaignAnalyticsEnabledHook();

	const editCampaign = (data: any) => {
		setLoading(true);
		API.editCampaign(botTimezone, selectedCampaign?.campaign_id, data).then((response: any) => {
			if (isValidNestedResponseObj(response)) {
				toaster.positive(
					<ENGTToasterContainer
						title={t('pages:broadcast.campaigns.editSuccessful')}
						description={t('common:changesSuccessfulSaved')}
					/>,
					{}
				);

				const params = {
					page: (page || 1) - 1,
					size,
					...(showWhatsappCampaignAnalytics
						? { targetApi: 'campaignAnalytics' }
						: { target_url: 'campaign' }),
				};
				showWhatsappCampaignAnalytics
					? dispatch(getAnalyticsCampaignAction(params))
					: dispatch(getCampaignAction(params));
			} else {
				response?.data?.response_obj?.status?.desc === RESPONSE_STATUS_DESC.DESC
					? dispatch(
							openInfoModalAction(
								t('common:errorModalHeading'),
								t('pages:broadcast.campaigns.campaignNameAlreadyExists', { campaignName: data })
							)
						)
					: dispatch(openErrorSavingModalAction());
			}
			setLoading(false);
			setSelectedCampaign({});
		});
	};

	return [editCampaign];
}

export default useEditCampaignHook;
