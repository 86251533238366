import { useStyletron } from 'baseui';
import { ArrowLeft } from 'baseui/icon';

import { hexToRgb } from 'shared/helpers';

function RightPanelArrow({ setOpenRightPanel }: { setOpenRightPanel: any }) {
	const [css, theme]: any = useStyletron();

	return (
		<div
			onClick={() => setOpenRightPanel(true)}
			className={css({
				position: 'absolute',
				right: '-0.25rem',
				top: '3rem',
				cursor: 'pointer',
				zIndex: theme.zIndex500,
				boxShadow: `${hexToRgb(theme.colors.primaryA, 0.7)} 0px 2px 10px`,
				borderTopRightRadius: '0.5rem',
				borderTopLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				backgroundColor: theme.colors.accent,
				height: '2rem',
			})}
		>
			<ArrowLeft color={theme.colors.primaryA} size={30} />
		</div>
	);
}

export default RightPanelArrow;
