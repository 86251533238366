import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useLocation } from 'react-router-dom';

import { SecondaryNavigationLinks } from 'components/UI/Navigation/SecondaryNavigation';

import AccountSettings from './AccountSettings';

function AccountSettingsWithOptions() {
	const [css, theme]: any = useStyletron();
	const location = useLocation();

	const isAccountSettingsOpen = location.pathname.includes('/account-settings');

	return (
		<>
			<AccountSettings />
			{isAccountSettingsOpen && (
				<Block
					className={css({
						paddingLeft: '2rem',
						width: 'calc(100% - 4rem)',
						marginBottom: '1rem',
					})}
				>
					<SecondaryNavigationLinks page='accountSettings' />
				</Block>
			)}
		</>
	);
}

export default AccountSettingsWithOptions;
