import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Tab } from 'baseui/tabs';
import { FILL } from 'baseui/tabs-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Layout, { LayoutContent, LayoutHeader } from 'components/Common/Layout';
import ENGTHeader from 'components/UI/ENGTHeader/ENGTHeader';
import ENGTTabs, { tabsDefaultOverride } from 'components/UI/ENGTTabs/ENGTTabs';
import PageHeader, { PageSubHeading } from 'components/UI/PageHeader/PageHeader';

import { ENGAGEMENT_TAB } from 'shared/enum';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

import BroadcastEngagement from './components/BroadcastEngagement';
import CtwaEngagement from './components/CtwaEngagement';
import EngagementSummary from './components/EngagementSummary';

const Engagement = ({ tab }: { tab: ENGAGEMENT_TAB }) => {
	const { t } = useTranslation(['common', 'pages']);
	const { isSmallDesktopScreen } = useResponsiveSize();
	const [css, theme]: [any, any] = useStyletron();

	const navigate = useNavigate();

	const tabOptions = [
		t(`pages:marketingCampaigns.engagement.summary`),
		t(`pages:marketingCampaigns.engagement.broadcast`),
		t(`pages:marketingCampaigns.engagement.CTWA`),
	];

	const summaryTab = (
		<Tab title={tabOptions[0]} key={ENGAGEMENT_TAB.SUMMARY}>
			<EngagementSummary />
		</Tab>
	);

	const broadcastTab = (
		<Tab title={tabOptions[1]} key={ENGAGEMENT_TAB.BROADCAST}>
			<BroadcastEngagement />
		</Tab>
	);

	const ctwaTab = (
		<Tab title={tabOptions[2]} key={ENGAGEMENT_TAB.CTWA}>
			<CtwaEngagement />
		</Tab>
	);

	const tabElements = [summaryTab, broadcastTab, ctwaTab];

	const tabChangedHandler = (selectedTab: string) => {
		if (selectedTab !== tab) {
			navigate(`${ThreeColumnRouteLinks.engagementLink}/${selectedTab}`);
		}
	};

	const engagementPageHeader = (
		<>
			<Block
				display='flex'
				justifyContent={['flex-end', 'flex-end', 'space-between']}
				alignItems='flex-end'
				flexWrap
				gridGap={['0rem', '0rem', '0rem', '2rem']}
			>
				<ENGTHeader>
					<PageHeader
						heading={t('pages:marketingCampaigns.engagement.engagement')}
						overrides={{
							marginBottom: '1rem !important',
						}}
					/>
				</ENGTHeader>
			</Block>
			<PageSubHeading
				subHeading={t('pages:marketingCampaigns.engagement.engagementSubHeading')}
				marginBottom={['1rem', '1rem', '1rem', '2rem']}
			/>
		</>
	);

	return (
		<Layout>
			<LayoutHeader>{engagementPageHeader}</LayoutHeader>
			<LayoutContent>
				<ENGTTabs
					activeTab={tab}
					fill={FILL.intrinsic}
					onChange={tabChangedHandler}
					tabOverrides={tabsDefaultOverride({
						Tab: {
							style: {
								maxWidth: '13rem',
							},
						},
						TabPanel: {
							style: {
								width: '100%',
							},
						},
						...(isSmallDesktopScreen && {
							TabList: {
								style: {
									marginBottom: '0.5rem',
								},
							},
						}),
					})}
				>
					{tabElements.map((tab: any) => tab)}
				</ENGTTabs>
			</LayoutContent>
		</Layout>
	);
};

export default Engagement;
