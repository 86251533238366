export const ISO_CERTIFIED_IMG =
	'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/landing-page/ISO-certified.svg';
export const GDPR_IMAGE = 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/landing-page/GDPR.svg';

export enum GA_EVENTS {
	USER_ID_TRACK = 'userIdTrack',
	REGISTRATION_PAGE_OPENED = 'registration_page_opened',
	SIGNUP_INITIATED = 'signup_initiated',
	SIGNUP_COMPLETED = 'signup_complete',
	REGISTRATION_FORM_SUBMITTED = 'registration_form_submitted',
	GOOGLE_ADS_REG_COMPLETED = 'google-ads-registration-completed',
}

export enum MIXPANEL_EVENTS {
	PAGE_VIEW_LOGIN = 'Page view: /login',
}

export enum PIXEL_EVENTS {
	LEAD = 'Lead',
}

export const MIXPANEL_TOKEN = 'ca75e29a68e1fcb30b297e5950d48999';
export const GTM_ID = 'GTM-PNH9HK5';
export const FB_PIXEL_ID = '2182768075200656';
