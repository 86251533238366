import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Button, KIND, SHAPE, SIZE } from 'baseui/button';
import { HeadingLevel } from 'baseui/heading';
import { toaster } from 'baseui/toast';
import { ParagraphMedium } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import Loader from 'components/UI/Loader/Loader';

import { circularButtonCss } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { isValidResponseObject } from 'shared/helpers';
import ArrowIcon from 'shared/icons/Broadcast/ArrowIcon';

import { marketingCampaignsAnalyticsSelector } from 'store/App/User/selectors';
import { botNameSelector } from 'store/Bot/selectors';
import { API } from 'store/Broadcast/api';
import { RootState } from 'store/withReducer';

import DaysFilter from '../Engagement/components/DaysFilter';
import useExportEngagementHook from '../Engagement/hooks/useExportEngagementHook';

import CTWAEngagement from './components/CTWAEngagement';
import LeadSource from './components/LeadSource';
import UsersHistory from './components/UsersHistory';

function ClickToWhatsappAdsSummary({ daysFilter, showUniqueUsers }: { daysFilter: string; showUniqueUsers?: boolean }) {
	const { t } = useTranslation(['common', 'pages', 'components']);
	const [css, theme]: [any, any] = useStyletron();

	const [filterOn, setFilterOn] = useState<string>(daysFilter);
	const [uniqueUsersData, setUniqueUsersData] = useState<IObjProps>([]);
	const [isLoading, setLoading] = useState<boolean>(true);
	const [nonUniqueUsersData, setNonUniqueUsersData] = useState<IObjProps>([]);

	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);
	const profileData = useSelector((state: RootState) => state.User.profile.data);
	const botName = useSelector(botNameSelector);
	const { exportEngagementData } = useExportEngagementHook();

	const styles = {
		headingCss: {
			marginTop: marketingCampaignsAnalyticsType ? '0.5rem' : '0.75rem',
			marginBottom: '0.3125rem',
			fontSize: '1.125rem',
			fontWeight: '700',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},

		subHeadingCss: {
			marginTop: '0.75rem',
			lineHeight: '1.25rem',
			width: '100%',
			fontSize: '0.875rem !important',
			fontWeight: '400',
		},
	};

	const handleExportClick = () => {
		const data = {
			filename: `ctwa_engagement_${botName}`,
			workflow: 'META_ADS_ENGAGEMENT_USERS_EXPORT',
			filter: filterOn,
		};
		exportEngagementData(data);
	};

	const allUsersHeader = () => (
		<div>
			<HeadingLevel>
				<ParagraphMedium color={theme.colors.primaryA} className={css(styles.headingCss)}>
					{marketingCampaignsAnalyticsType ? (
						<>
							<div className={css({ display: 'flex', alignItems: 'center' })}>
								{t('pages:marketingCampaigns.engagement.ctwaEngagement')}
								<Button
									size={SIZE.default}
									shape={SHAPE.circle}
									kind={KIND.secondary}
									overrides={{
										BaseButton: {
											style: {
												...circularButtonCss,
												marginLeft: '0.5rem',
												height: '1.7rem',
												width: '1.7rem',
											},
										},
									}}
									onClick={handleExportClick}
								>
									<ArrowIcon width={22} height={22} />
								</Button>
							</div>
							<DaysFilter filterOn={setFilterOn} />
						</>
					) : (
						t('pages:broadcast.clickToWhatsappAds.allUsers')
					)}
					{!marketingCampaignsAnalyticsType && nonUniqueUsersData?.lastUpdatedOn && (
						<div className={css({ fontWeight: 400, fontSize: '0.875rem', alignItems: 'center' })}>
							{t('components:conversations.lastUpdatedOn')}
							<span style={{ marginLeft: '0.2rem' }}>
								{`${t('common:standardDateTimeFormatterWithShortMonthNameAndTimeAheadOfDate', {
									date: nonUniqueUsersData.lastUpdatedOn,
								})} (${profileData.timezone})`}
							</span>
						</div>
					)}
				</ParagraphMedium>

				<ParagraphMedium color={theme.colors.primaryA} className={css(styles.subHeadingCss)}>
					{t('pages:broadcast.clickToWhatsappAds.allUsersDescription')}
				</ParagraphMedium>
			</HeadingLevel>
		</div>
	);

	useEffect(() => {
		setFilterOn(daysFilter);
	}, [daysFilter]);

	const getNonUniqueUsersApiBasedOnEntitlement = () =>
		marketingCampaignsAnalyticsType
			? API.getEngagedNonUniqueUsers(filterOn)
			: API.getMetaAdNonUniqueUsers(filterOn);

	useEffect(() => {
		setLoading(true);
		showUniqueUsers &&
			API.getMetaAdUniqueUsers(filterOn)
				.then((response: IObjProps) => {
					if (isValidResponseObject(response)) {
						setUniqueUsersData(response.data.responseObject);
					} else {
						setUniqueUsersData([]);
					}
				})
				.catch(() => {
					toaster.negative(
						<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
						{}
					);
				});

		getNonUniqueUsersApiBasedOnEntitlement()
			.then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					const data = response.data.responseObject;
					setNonUniqueUsersData(data);
				} else {
					setNonUniqueUsersData([]);
				}
			})
			.catch(() => {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [filterOn]);

	return isLoading ? (
		<Loader loaderBlockHeight='100%' />
	) : (
		<>
			{allUsersHeader()}
			{marketingCampaignsAnalyticsType ? (
				<CTWAEngagement nonUniqueUsers={nonUniqueUsersData} filterOn={filterOn} />
			) : (
				<>
					<LeadSource uniqueUsers={uniqueUsersData} nonUniqueUsers={nonUniqueUsersData} filterOn={filterOn} />
					<UsersHistory nonUniqueUsers={nonUniqueUsersData} />
				</>
			)}
		</>
	);
}

export default ClickToWhatsappAdsSummary;
