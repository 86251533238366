import React, { useEffect } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { KIND, SIZE } from 'baseui/button';
import { Paragraph1 } from 'baseui/typography';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';
import NoDataTemplate from 'components/UI/NoDataTemplate/NoDataTemplate';

import { deleteFromLs } from 'shared/consts/localStorageConsts';
import { IObjProps } from 'shared/consts/types';
import NoDataEmoji from 'shared/icons/NoDataEmoji.svg';

import { RootState } from 'store/rootReducer';

interface IENGTErrorBoundaryProps {
	children: React.ReactNode;
	height?: string;
	message: string | React.ReactNode;
	retryBtnLabel?: string;
	showIcon?: boolean;
	showRetryBtn?: boolean;
	type?: 'primary' | 'modal';

	onRetry?: Function;
}
type ErrorBoundaryModalProps =
	| {
			type?: 'primary';
	  }
	| {
			type?: 'modal';
			onClose: Function;
	  };

export function setWithExpiry(key: string, value: string, ttl: number) {
	const item = {
		value,
		expiry: new Date().getTime() + ttl,
	};
	localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
	const itemString = window.localStorage.getItem(key);
	if (!itemString) {
		return null;
	}

	const item = JSON.parse(itemString);
	const isExpired = new Date().getTime() > item?.expiry;

	if (isExpired) {
		localStorage.removeItem(key);

		return null;
	}

	return item.value;
}

const ErrorCallBack = (props: IObjProps & IENGTErrorBoundaryProps & ErrorBoundaryModalProps) => {
	const {
		error,
		message = '',
		onRetry,
		resetErrorBoundary,
		retryBtnLabel,
		showIcon = false,
		showRetryBtn = true,
		height = '100%',
		type = 'primary',
		onClose,
	} = props;
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['common', 'errors']);
	const showBackToHomeBtn: any = window.location.pathname.includes('messages' || 'allmessages');
	const navigate = useNavigate();
	const botRef = useSelector((state: RootState) => state.Bot.data.bot_ref);
	const uid = useSelector((state: RootState) => state.User.botAdmin.data.uid);

	const retryBtnCss = {
		marginLeft: '1rem',
		paddingTop: '0.75rem',
		paddingBottom: '0.75rem',
		paddingLeft: '3rem',
		paddingRight: '3rem',
		backgroundColor: theme.colors.accent100,
		color: theme.colors.primaryBtnFontColor,
	};

	const backBtnCss = {
		marginTop: '1rem',
		marginLeft: '1rem',
		paddingTop: '0.75rem',
		paddingBottom: '0.75rem,',
		paddingLeft: '2.8rem',
		paddingRight: '2.8rem',
	};

	useEffect(() => {
		const jsChunkFailed = /Loading chunk [\d]+ failed/;
		const cssChunkFailed = /Loading CSS chunk [\d]+ failed/;

		if (error?.message && (jsChunkFailed.test(error.message) || cssChunkFailed.test(error.message))) {
			if (!getWithExpiry('chunk_failed')) {
				setWithExpiry('chunk_failed', 'true', 10000);
				window.location.reload();
			}
		}
	}, [error]);

	const onRetryFn = () => {
		onRetry ? onRetry(resetErrorBoundary) : resetErrorBoundary();
	};

	const resetOneViewFilters = () => {
		if (botRef && uid) {
			deleteFromLs(`all_${botRef}_${uid}`);
			deleteFromLs(`active_${botRef}_${uid}`);
			deleteFromLs(`new_${botRef}_${uid}`);
			deleteFromLs(`unassigned_${botRef}_${uid}`);
			deleteFromLs(`oneviewlite_${botRef}_${uid}`);
			deleteFromLs(`oneviewlite_${botRef}_${uid}_quick_filters`);
			deleteFromLs(`oneviewlite_${botRef}_${uid}_myconversation`);
		}
	};

	const onBackToHomeFn = () => {
		resetOneViewFilters();
		onRetryFn();
		navigate('/home');
	};

	const renderButtons = () => (
		<div
			className={css({
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			})}
		>
			{showRetryBtn && (
				<ENGTButton
					kind={showBackToHomeBtn ? KIND.secondary : KIND.primary}
					onClick={onRetryFn}
					label={retryBtnLabel || t('common:retry')}
					className={css({
						marginTop: '1rem !important',
						...(showBackToHomeBtn && { ...retryBtnCss }),
					})}
				/>
			)}
			{showBackToHomeBtn && (
				<ENGTButton
					kind={KIND.primary}
					onClick={onBackToHomeFn}
					label={t('errors:errorBackToHome.title')}
					className={css({ ...backBtnCss })}
				/>
			)}
		</div>
	);

	return type === 'modal' ? (
		<ActionModal
			isOpen
			heading={t('common:somethingWentWrong')}
			description={<div style={{ width: '75%' }}>{message || t('common:somethingWentWrong')}</div>}
			confirmBtnLabel={t('common:retry')}
			onConfirm={onRetryFn}
			onClose={onClose}
			isFooterReq={false}
		>
			<ENGTButton
				className={css({
					float: 'right',
				})}
				kind={KIND.primary}
				size={SIZE.large}
				label={t('common:retry')}
				dataQa='retry-component-render'
				onClick={onRetryFn}
			/>
		</ActionModal>
	) : (
		<Block display='flex' alignItems='center' justifyContent='center' height={height}>
			<NoDataTemplate
				mainStyle={{
					width: '90%',
				}}
				contentCss={{
					opacity: 1,
				}}
				bannerImage={{
					altText: 'ErroredOutIcon',
					imageUrl: showIcon ? NoDataEmoji : '',
				}}
				heading={message || (t('common:somethingWentWrong') as string)}
			>
				<>
					{showBackToHomeBtn && (
						<Paragraph1 className={css({ color: theme.colors.accent50 })}>
							<span className={css({ fontWeight: 600 })}>{t('errors:errorBackToHome.note')}</span>
							{t('errors:errorBackToHome.description')}
						</Paragraph1>
					)}
					{renderButtons()}
				</>
			</NoDataTemplate>
		</Block>
	);
};

function ENGTErrorBoundary(props: IObjProps & IENGTErrorBoundaryProps & ErrorBoundaryModalProps) {
	return (
		<ErrorBoundary {...props} FallbackComponent={(errorProps: any) => <ErrorCallBack {...errorProps} {...props} />}>
			{props.children}
		</ErrorBoundary>
	);
}

export default ENGTErrorBoundary;
