import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useFormContext, useWatch } from 'react-hook-form';

import Password from 'components/UI/ENGTPassword/ENGTPassword';
import { IFormInputProps } from 'components/UI/Form/FormInput/FormInput';
import FormLabel from 'components/UI/Form/FormLabel/FormLabel';

import { MOBILE_VIEW_BREAKPOINT } from 'shared/consts/consts';
import { getErrorMessage } from 'shared/helpers';

export interface IFormPasswordProps {
	isPasswordWidgetOpen?: boolean;
	isNewPasswordWidgetOpen?: boolean;
	showErrorMessage?: boolean;
	validPassword?: boolean;
	errorText?: string;
	password?: any;
	maxLength?: number;
	dataQa?: string;
	isRequired?: boolean;
	showShopifyErrormsg?: boolean;
}

export const FormPassword = ({
	defaultValue,
	disabled,
	errorCondition = {},
	label,
	inputRef,
	labelClassName,
	name,
	placeholder,
	tooltip,
	width = '',
	marginBottom = '1rem',
	isIndex,
	onChange = (e: any) => {},
	overrides = {},
	maxLength,
	isPopverWidget,
	isPasswordWidgetOpen,
	isNewPasswordWidgetOpen,
	showErrorMessage,
	validPassword,
	errorText,
	password = '',
	dataQa,
	isRequired = false,
	showShopifyErrormsg = false,
	...inputProps
}: IFormPasswordProps & IFormInputProps) => {
	const [css, theme]: any = useStyletron();
	const { control, errors, register, setValue } = useFormContext();

	const [disableErrorMessage, setDisableErrorMessage] = useState<boolean>(window.innerWidth > MOBILE_VIEW_BREAKPOINT);

	useEffect(() => {
		const handleWindowResize = () => {
			setDisableErrorMessage(window.innerWidth > MOBILE_VIEW_BREAKPOINT);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	useEffect(() => {
		defaultValue && setValue && setValue(name, defaultValue);
	}, [defaultValue]);

	useWatch({
		control,
		name,
	});

	const errorMsg = getErrorMessage(name, errors, isIndex !== undefined ? isIndex : true)
		? getErrorMessage(name, errors, isIndex !== undefined ? isIndex : true)
		: errors[name];

	return (
		<div className={css({ position: 'relative', textAlign: 'left', marginBottom, ...overrides })}>
			{label && (
				<FormLabel
					id={name}
					label={label}
					tooltip={tooltip}
					className={labelClassName}
					isRequired={isRequired}
				/>
			)}
			<Password
				width={width}
				id={name}
				inputRef={inputRef || register(errorCondition)}
				name={name}
				placeholder={placeholder}
				autoComplete='off'
				error={disableErrorMessage ? (showShopifyErrormsg ? errorMsg : {}) : errorMsg}
				className={css({ marginBottom: '1rem' })}
				disabled={disabled || false}
				overrides={overrides}
				{...inputProps}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				maxLength={maxLength}
				isPasswordWidgetOpen={isPasswordWidgetOpen}
				isNewPasswordWidgetOpen={disableErrorMessage && isNewPasswordWidgetOpen}
				password={password}
				dataQa={dataQa}
				showErrorMessage={disableErrorMessage && showErrorMessage}
				errorText={errorText}
				validPassword={validPassword}
				isPopverWidget={isPopverWidget}
			/>
		</div>
	);
};

FormPassword.defaultProps = {
	disabled: false,
	isIndex: true,
	maxLength: 1000000,
	isPasswordWidgetOpen: false,
};
