import { IObjProps } from 'shared/consts/types';

import { IComment, IPostCommentsInterface, IReplies } from './state';

export const postCommentsReducer = {
	updateCommentList: (state: IPostCommentsInterface, action: { payload: Array<IObjProps> }) => {
		const existingComments = JSON.parse(JSON.stringify(state.listOfComments));
		state.listOfComments = {
			...existingComments,
			data: action.payload,
			loading: false,
			error: false,
		};
	},
	populateCommentsForSelectedPost: (state: IPostCommentsInterface, action: { payload: IObjProps }) => {
		const existingComments = JSON.parse(JSON.stringify(state.listOfComments));
		state.listOfComments = {
			data: [...existingComments?.data, ...action.payload?.content],
			loading: false,
			error: false,
			pagination: {
				page: action.payload?.number,
				size: action.payload?.size,
				totalPages: action.payload?.totalPages,
				totalElements: action.payload?.totalElements,
			},
		};
	},
	discardCurrentListOfComments: (state: IPostCommentsInterface) => {
		state.listOfComments = {
			data: [],
			error: false,
			loading: false,
			pagination: {
				page: -1,
				size: 10,
				totalPages: 1,
				totalElements: 1,
			},
		};
	},

	setCommentsLoading: (state: IPostCommentsInterface) => {
		state.listOfComments = {
			data: [],
			error: false,
			loading: true,
			pagination: {
				page: -1,
				size: 10,
				totalPages: 1,
				totalElements: 1,
			},
		};
	},
	addCommentOrReplyIntoCommentList: (state: IPostCommentsInterface, action: { payload: IObjProps }) => {
		const appendReplyToRespectiveParentComment = (reply: IComment, parentCommentId: string) => {
			state.listOfComments.data.every((comment: IComment) => {
				if (comment.commentId === parentCommentId) {
					comment.replies.data.push(reply);

					return false;
				}

				return true;
			});
		};

		const prependCommentToListOfCurrentComments = (newComment: IComment) => {
			state.listOfComments.data.unshift(newComment);
		};

		const { newComment, currentCommentParentCommentId } = action.payload;

		if (currentCommentParentCommentId) {
			appendReplyToRespectiveParentComment(newComment, currentCommentParentCommentId);
		} else {
			prependCommentToListOfCurrentComments(newComment);
		}
	},
	updatePostedCommentIds: (state: IPostCommentsInterface, action: { payload: Array<string> }) => {
		state.postedCommentIds = action.payload;
	},
	disablePrivateReply: (state: IPostCommentsInterface, action: { payload: IObjProps }) => {
		const { requiredParentCommentId, requiredChildCommentId } = action.payload;

		const iterateThroughChildComments = (childComments: Array<IReplies>) => {
			for (const childComment of childComments) {
				if (childComment.commentId === requiredChildCommentId) {
					childComment.canReplyPrivately = false;
					break;
				}
			}
		};

		const iterateThroughParentComments = (parentComments: Array<IComment>) => {
			for (const parentComment of parentComments) {
				if (parentComment.commentId === requiredParentCommentId) {
					if (!requiredChildCommentId) {
						parentComment.canReplyPrivately = false;
					} else {
						iterateThroughChildComments(parentComment.replies.data);
					}
					break;
				}
			}
		};

		iterateThroughParentComments(state.listOfComments.data);
	},
};
