import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Loader from 'components/UI/Loader/Loader';

import { RootState } from 'store/rootReducer';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';
import { LEFT_PANEL_ACCESS_ROUTES } from 'router/ProtectedRouter/protectedRoute';

function WhatsAppManager() {
	const navigate = useNavigate();
	const { whatsAppNumbersLink, whatsAppFlowsLink } = ThreeColumnRouteLinks;
	const leftPanelsV2Data: any = useSelector((state: RootState) => state.User.botAdmin.data.left_panels_v2_keys || []);

	const hasWhatsAppNumbersAccess = leftPanelsV2Data.indexOf(LEFT_PANEL_ACCESS_ROUTES[whatsAppNumbersLink]) > -1;

	useEffect(() => {
		if (leftPanelsV2Data.length) {
			hasWhatsAppNumbersAccess
				? navigate(whatsAppNumbersLink, { replace: true })
				: navigate(whatsAppFlowsLink, { replace: true });
		}
	}, [leftPanelsV2Data]);

	return (
		<div>
			<Loader loaderBlockHeight='100%' />
		</div>
	);
}

export default WhatsAppManager;
