import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { Modal as BaseModal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/UI/ENGTButton/ENGTButton';

import { ENGATI_SUPPORT_MAIL } from 'shared/consts/consts';

import { API, shopifyActions } from 'store/App/Shopify';
import { isShopifyEnabledAcc } from 'store/App/User';
import { modalActions } from 'store/Modal';
import { RootState } from 'store/rootReducer';

const width = '26.375rem';

const { closeErrorSavingModalAction } = modalActions;

const modalOverrideProps = ({ theme }: { theme: any }) => ({
	Root: {
		style: {
			zIndex: theme.zIndex300,
		},
	},
	Close: {
		style: {
			display: 'none',
		},
	},
	Dialog: {
		style: {
			backgroundColor: theme.modalPrimaryBackground,
			borderTopRightRadius: '1rem',
			borderTopLeftRadius: '1rem',
			borderBottomLeftRadius: '1rem',
			borderBottomRightRadius: '1rem',
			paddingRight: '2rem',
			paddingLeft: '2rem',
			width,
		},
	},
});

const modalHeaderCss = ($theme: any) => ({
	color: $theme.modalTextColor,
	fontWeight: 'bold !important',
	fontSize: '1.25rem !important',
	lineHeight: '1.625rem !important',
	marginLeft: '0rem !important',
	marginRight: '0rem !important',
	marginBottom: '0.3125rem !important',
});

const modalBodyCss: any = {
	marginRight: '0 !important',
	marginLeft: '0 !important',
	marginTop: '0.3125rem !important',
};

const modalFooterCss = {
	display: 'flex',
	marginBottom: '2rem',
	textAlign: 'center !important' as 'center',
	marginLeft: '0rem !important',
	marginRight: '0rem !important',
	paddingBottom: '0rem !important',
	paddingTop: '0rem !important',
};

function ErrorSavingModal() {
	const isModalOpen = useSelector((state: RootState) => state.Modal.errorSavingModal.isOpen);
	const errorCode = useSelector((state: RootState) => state.Modal.errorSavingModal.errorCode);
	const botName: string = useSelector((state: RootState) => state.Bot.data.bot_name);
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const supportEmailOrLink = useSelector((state: RootState) => state.User.botAdmin.data.support_email);

	const [shopifyDataError, setShopifyDataError] = useState(false);
	const [isShopifyDataLoading, setShopifyDataLoader] = useState(false);
	const [shopifyStoreName, setStoreName] = useState('');

	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['components']);
	const dispatch = useDispatch<any>();

	const onCloseFn = () => {
		dispatch(closeErrorSavingModalAction());
	};

	const errorSavingHandler = () => {
		dispatch(closeErrorSavingModalAction());
	};

	useEffect(() => {
		if (isModalOpen && isShopifyEnabled && !shopifyStoreName) {
			setShopifyDataLoader(true);

			API.fetchIntegratedAccount().then(
				(resp: any) => {
					setShopifyDataLoader(false);

					if (resp.data && resp.data.responseObject) {
						if (Object.keys(resp.data.responseObject).length) {
							setStoreName(resp.data.responseObject.displayName);
							dispatch(shopifyActions.updateShopifyAccountDetails(resp.data.responseObject));
						}
					} else {
						setShopifyDataError(true);
					}
				},
				(e: any) => {
					setShopifyDataLoader(false);
					setShopifyDataError(true);
				}
			);
		}
	}, [isModalOpen]);

	const supportMailId =
		supportEmailOrLink?.type === 'email' && supportEmailOrLink?.value
			? supportEmailOrLink.value
			: ENGATI_SUPPORT_MAIL;

	const getGeneratedMailTo = () => {
		if (isShopifyEnabled && shopifyStoreName) {
			return `mailto: ${supportMailId}${isShopifyEnabled ? `?subject=shopify-${shopifyStoreName}` : ''}`;
		}
		if (isShopifyEnabled && shopifyDataError) {
			return `mailto: ${supportMailId}${isShopifyEnabled ? `?subject=shopify-${botName}` : ''}`;
		}

		return `mailto: ${supportMailId}`;
	};

	const isLoading = () => isShopifyEnabled && isShopifyDataLoading;

	return (
		<>
			<BaseModal onClose={onCloseFn} isOpen={isModalOpen} overrides={modalOverrideProps({ theme })}>
				<ModalHeader className={css(modalHeaderCss(theme))}>
					{t('components:errorSavingModal.heading')}
				</ModalHeader>
				<ModalBody className={css(modalBodyCss)}>
					<div
						className={css({
							fontSize: '1rem',
							lineHeight: '1.25rem',
						})}
					>
						{t('components:errorSavingModal.description')}
					</div>
					{errorCode && (
						<div
							className={css({
								color: theme.colors.accent50,
								marginTop: '0.5rem',
								lineHeight: '1.25rem',
							})}
						>
							{`${t('components:errorSavingModal.errorCode')} ${errorCode}`}
						</div>
					)}
				</ModalBody>
				<ModalFooter className={css(modalFooterCss)}>
					<Button
						kind={KIND['secondary']}
						size={SIZE.large}
						onClick={onCloseFn}
						label={t('components:errorSavingModal.cancel')}
						className={css({
							marginRight: '0.25rem !important',
							width: '100%',
						})}
					/>
					<Button
						kind={KIND['primary']}
						size={SIZE.large}
						label={
							<a
								href={getGeneratedMailTo()}
								target='blank'
								className={css({
									color: 'inherit',
									textDecoration: 'none',
								})}
							>
								{t('components:errorSavingModal.confirm')}
							</a>
						}
						className={css({
							marginLeft: '0.25rem !important',
							width: '100%',
						})}
						isLoading={isLoading()}
						isDisabled={isLoading()}
						onClick={errorSavingHandler}
					/>
				</ModalFooter>
			</BaseModal>
		</>
	);
}

export default ErrorSavingModal;
