import { Suspense } from 'react';
import { useSelector } from 'react-redux';

import { lazy } from '@loadable/component';

import { isWhatsappAcceleratorTrialEnabled } from 'store/App/User';

let ExitIntentModal: any;

const PricingExitIntentHOC = () => {
	const isWAAccTrial = useSelector(isWhatsappAcceleratorTrialEnabled);

	if (isWAAccTrial) {
		if (!ExitIntentModal) {
			ExitIntentModal = lazy(() => import(/* webpackChunkName: "PricingExitIntent" */ './PricingExitIntent'));
		}

		return (
			<Suspense>
				<ExitIntentModal />
			</Suspense>
		);
	}

	return <></>;
};

export default PricingExitIntentHOC;
