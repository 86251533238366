import { IObjProps } from 'shared/consts/types';

import { IJItsiInterface } from './state';

export const jitsiReducers = {
	toggleLaunchScreen: (state: IJItsiInterface, action: { payload: boolean }) => {
		state.isLaunchScreenOpen = action.payload;
	},
	getVideCallInitialisationDetails: (state: IJItsiInterface, action: { payload: IObjProps }) => {
		state.videoCallInitialisationDetails = action.payload;
	},
	setSelectedVideoUserAction: (state: IJItsiInterface, action: { payload: IObjProps }) => {
		state.selectedVideoUser = action.payload;
	},
	setVideoUserMessages: (state: IJItsiInterface, action: { payload: Array<IObjProps> }) => {
		state.videoUserMessages = {
			data: action.payload,
			error: false,
			loading: false,
		};
	},
	setParticipantsCount: (state: IJItsiInterface, action: { payload: number }) => {
		state.participantsCount = action.payload;
	},
	setGroupConversationForVideo: (state: IJItsiInterface, action: { payload: boolean }) => {
		state.isGroupConversationAllowedForVideo = action.payload;
	},
	setVideoConversationIdForUser: (state: IJItsiInterface, action: { payload: { cid: string; videoId: any } }) => {
		state.videoConversationIdForUserMap = {
			...state.videoConversationIdForUserMap,
			[action.payload.cid]: action.payload.videoId,
		};
	},
	removeVideoConversationIdForUser: (state: IJItsiInterface, action: { payload: IObjProps }) => {
		state.videoConversationIdForUserMap = action.payload;
	},
	setUserInstance: (
		state: IJItsiInterface,
		action: { payload: { instance_domain_url: any; instance_meet_url: any } }
	) => {
		state.userInstanceUrl = action.payload;
		state.userInstanceUrl = {
			...state.userInstanceUrl,
			instance_domain_url: action.payload.instance_domain_url,
			instance_meet_url: action.payload.instance_meet_url,
		};
	},
};
