import { FC, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { mergeOverrides, useStyletron } from 'baseui';
import { PLACEMENT, Popover } from 'baseui/popover';

import { IObjProps } from 'shared/consts/types';
import useResizerHook from 'shared/hooks/useResizerHook';

import { MaskVisibilityContext } from '../ENGTMask/ENGTMask';

interface IDefaultposition {
	top?: string;
	left?: string;
	bottom?: string;
	right?: string;
}

const NUDGE_STYLES = (theme: any) => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: 'min-content',
	},
	data: {
		padding: '1rem 1.5rem',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
	},
	description: {
		fontWeight: 700,
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		color: theme.colors.primaryA,
	},
	button: {
		maxWidth: 'max-content',
	},
});

const ENGTNudge: FC<
	PropsWithChildren<{
		backdrop?: boolean;
		isOpen: boolean;
		targetId: string;
		targetRef?: React.RefObject<HTMLElement | null>;
		defaultPosition: IDefaultposition;
		overrides?: IObjProps;
		placement?: keyof PLACEMENT;
		overridePopoverStyle?: IObjProps;
		onContentClick?: (args: Event) => any;
	}>
> = ({
	backdrop,
	isOpen,
	targetId,
	targetRef,
	defaultPosition,
	overrides,
	children,
	placement = PLACEMENT.leftTop,
	overridePopoverStyle = {},
	onContentClick,
}) => {
	const [css, theme]: [any, any] = useStyletron();
	const contextData = useContext(MaskVisibilityContext) as Array<any>;
	const [, setMaskSizes] = contextData;
	const { width, height } = useResizerHook();

	const contentRef = useRef<HTMLDivElement | null>(null);

	const [targetBoundary, setTargetBoundary] = useState<IObjProps>({});

	useEffect(() => {
		const targetElement = (document.querySelector(`#${targetId}`) ||
			targetRef?.current ||
			document.querySelector(`[data-qa=${targetId}]`)) as HTMLElement;

		let resizeObserver: any;

		if (!targetElement || !isOpen) {
			return () => resizeObserver?.disconnect?.();
		}

		resizeObserver = new ResizeObserver(() => {
			setTargetBoundary(targetElement.getBoundingClientRect());
		});
		resizeObserver.observe(targetElement);

		return () => resizeObserver.disconnect();
	}, [isOpen, targetId]);

	const elementsPosition = (): IDefaultposition => {
		const targetElement = (document.querySelector(`#${targetId}`) ||
			targetRef?.current ||
			document.querySelector(`[data-qa=${targetId}]`)) as HTMLElement;

		const contentBoundary = contentRef.current?.getBoundingClientRect();

		if (!targetElement) {
			return {};
		}

		switch (placement) {
			case PLACEMENT.top:
				return {
					top: `${
						targetBoundary.top -
						(contentBoundary?.height || 0) -
						(overridePopoverStyle?.arrowDistanceFromTop || 0) -
						30
					}px`,
					left: `${
						targetBoundary.left +
						targetBoundary.width / 2 -
						(contentBoundary?.width || 0) / 2 -
						(overridePopoverStyle?.arrowDistanceFromLeft || 0)
					}px`,
				};
			case PLACEMENT.right:
				return {
					top: `${targetBoundary.top + targetBoundary.height / 10 - 75}px`,
					left: `${targetBoundary.width + targetBoundary.left + 8 + (backdrop ? 20 : 0)}px`,
				};
			case PLACEMENT.left:
				return {
					top: `${targetBoundary.top - targetBoundary.height / 2}px`,
					left: `${targetBoundary.left - (contentBoundary?.width || 0) - 8 - (backdrop ? 20 : 0)}px`,
				};
			case PLACEMENT.leftTop:
				return {
					top: `${targetBoundary.top + targetBoundary.height / 2 - 32}px`,
					left: `${targetBoundary.width + targetBoundary.left + 8 + (backdrop ? 20 : 0)}px`,
				};
			default:
				return defaultPosition;
		}
	};

	const arrowPosition = () => {
		const contentBoundary = contentRef.current?.getBoundingClientRect();

		if (!contentBoundary) {
			return {};
		}

		switch (placement) {
			case PLACEMENT.left:
				return {
					top: `${(contentBoundary.height || 0) / 2}px`,
					left: `${(contentBoundary?.width || 0) - 6}px`,
				};
			case PLACEMENT.top:
				return { top: '3rem' };
			case PLACEMENT.right:
				return { top: `${(contentBoundary.height || 0) / 2}px` };
			default:
				if (!backdrop) {
					return { top: '8%', height: '1.5rem', width: '1.5rem' };
				}

				return {};
		}
	};

	const overrideStyles = mergeOverrides(
		{
			Body: {
				style: {
					borderColor: theme.navigationBorderColor,
					borderWidth: '0.0625rem',
					borderStyle: 'solid',
					borderTopLeftRadius: '1rem',
					borderTopRightRadius: '1rem',
					borderBottomLeftRadius: '1rem',
					borderBottomRightRadius: '1rem',
					marginLeft: 0,
					transform: 'unset',
					zIndex: theme.zIndexToolTip,
					...elementsPosition(),
				},
			},
			Inner: {
				style: {
					borderTopLeftRadius: '1rem',
					borderTopRightRadius: '1rem',
					borderBottomLeftRadius: '1rem',
					borderBottomRightRadius: '1rem',
					backgroundColor: theme.colors.primaryB,
					padding: '0rem',
				},
			},
			Arrow: {
				style: {
					borderColor: theme.navigationBorderColor,
					borderWidth: '0.0625rem',
					borderStyle: 'solid',
					backgroundColor: theme.colors.primaryB,
					...arrowPosition(),
				},
			},
		},
		overrides
	);

	useEffect(() => () => backdrop && setMaskSizes(null), []);

	useEffect(() => {
		const targetElement = (document.querySelector(`#${targetId}`) ||
			targetRef?.current ||
			document.querySelector(`[data-qa=${targetId}]`)) as HTMLElement;

		if (isOpen && backdrop && targetElement) {
			setMaskSizes &&
				setMaskSizes({
					width: targetElement.clientWidth,
					height: targetElement.clientHeight + (overridePopoverStyle.height || 0),
					top: targetBoundary.top - (overridePopoverStyle.top || 0),
					left: targetBoundary.left,
				});
			if (onContentClick) {
				targetElement.addEventListener('click', onContentClick);
			}
		} else if (backdrop) {
			setMaskSizes(null);
		}

		return () => {
			if (!isOpen && backdrop) {
				setMaskSizes(null);
				if (onContentClick) {
					targetElement.removeEventListener('click', onContentClick);
				}
			}
		};
	}, [isOpen, width, height, targetBoundary, targetRef]);

	return (
		<Popover
			showArrow
			placement={placement}
			ignoreBoundary
			content={<div ref={contentRef}>{children}</div>}
			isOpen={isOpen}
			overrides={overrideStyles}
		>
			<div
				className={css({
					position: 'absolute',
					top: '0rem',
					left: '0rem',
				})}
			/>
		</Popover>
	);
};

export default ENGTNudge;
export { NUDGE_STYLES };
