import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const assignConversationUrl = `${genericProxyRequestUrl}${serialize({ targetApi: 'assignChat' }, '?')}`;
const startConversationForAllUrl = `${genericProxyRequestUrl}${serialize({ targetApi: 'startConversation' }, '?')}`;
const startConversationForNewUrl = `${genericProxyRequestUrl}${serialize({ targetApi: 'updateConversation' }, '?')}`;
const uploadAttachmentsUrl = '/api/v1/upload/attachment';
const quickReplySearch = '/quick-reply/search';
const liveChatConfigUrl = `${genericProxyRequestUrl}${serialize(
	{ targetApi: 'liveChatConfig', botRef: '{{botRef}}' },
	'?'
)}`;
const exitConversationUrl = `${genericProxyRequestUrl}${serialize({ targetApi: 'exitGroupConversation' }, '?')}`;
const joinConversationUrl = `${genericProxyRequestUrl}${serialize({ targetApi: 'joinGroupConversation' }, '?')}`;
const inviteToGroupConversationUrl = `${genericProxyRequestUrl}${serialize(
	{ targetApi: 'inviteToGroupConversation' },
	'?'
)}`;
const uploadEmailAttachmentUrl = '/api/v1/upload/bulk-attachments';
const getEmailConfigUrl = `${genericProxyRequestUrl}${serialize({ targetApi: 'getEmailConfig' }, '?')}`;
const getUserInfoAttributesUrl = `${genericProxyRequestUrl}${serialize({ targetApi: 'getUserInfoAttributes' }, '?')}`;
const getSmartResponseUrl = `${genericProxyRequestUrl}${serialize({ targetApi: 'getSmartResponse' }, '?')}`;
const getAutoTranslationMessagesUrl = `${genericProxyRequestUrl}${serialize(
	{ targetApi: 'getAutoTranslationMessages' },
	'?'
)}`;
const generateConversationSummaryUrl = `${genericProxyRequestUrl}${serialize(
	{ targetApi: 'generateConversationSummary' },
	'?'
)}`;
const getExplainedQueryUrl = `${genericProxyRequestUrl}${serialize({ targetApi: 'getExplainedQuery' }, '?')}`;

const assignConversationApi = new BaseService(assignConversationUrl);
const startConversationForAllApi = new BaseService(startConversationForAllUrl);
const startConversationForNewApi = new BaseService(startConversationForNewUrl);
const uploadAttachmentsApi = new BaseService(uploadAttachmentsUrl, {
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
	},
});
const liveChatConfigApi = new BaseService(liveChatConfigUrl);
const exitConversationApi = new BaseService(exitConversationUrl);
const joinConversationApi = new BaseService(joinConversationUrl);
const quickRepliesApi = new BaseService(genericProxyRequestUrl);
const inviteToGroupConversationApi = new BaseService(inviteToGroupConversationUrl);
const getEmailConfigApi = new BaseService(getEmailConfigUrl);
const getUserInfoAttributesApi = new BaseService(getUserInfoAttributesUrl);
const getSmartResponseApi = new BaseService(getSmartResponseUrl);
const getAutoTranslationMessagesApi = new BaseService(getAutoTranslationMessagesUrl);
const generateConversationSummaryApi = new BaseService(generateConversationSummaryUrl);
const fetchConversationSummaryApi = new BaseService(genericProxyRequestUrl);
const getExplainedQueryApi = new BaseService(getExplainedQueryUrl);

export const messageOverviewAPI = {
	addQuickReply(role, text, title) {
		const params = { targetApi: 'quickReply' };
		const data = {
			bot_ref: '{{botRef}}',
			customer_id: '{{customerId}}',
			user_id: '{{userId}}',
			available_for: role === 'ROLE_CONNECT_SUPERVISOR' || role === 'ROLE_CONNECT_OWNER' ? 'ALL' : 'AGENT',
			user_role: role,
			locale: 'en',
			channel: 'Text',
			reply_text: text,
			display_name: text,
			title,
		};
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const addReplyApi = new BaseService(url, {
			headers: {
				'Content-Type': 'application/json',
				'audit-metadata': '{{audit-metadata}}',
			},
		});

		return addReplyApi.postRequest(data);
	},
	assignConversation(data) {
		return assignConversationApi.postRequest(data);
	},
	getUserMessages(params, data, cancelSignToken) {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const getUserMessagesApi = new BaseService(url, cancelSignToken);

		return getUserMessagesApi.postRequest(data);
	},
	resolveConversation(params, data) {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const resolveConversationApi = new BaseService(url);

		return resolveConversationApi.postRequest(data);
	},
	pendingResolution(params, data) {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const pendingResolutionApi = new BaseService(url);

		return pendingResolutionApi.postRequest(data);
	},
	sendMessage(params, data) {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const sendMessageApi = new BaseService(url);

		return sendMessageApi.postRequest(data);
	},
	getIdFromUserId(params) {
		const queryParams = {
			targetApi: 'getIdFromUserId',
			...params,
		};
		const url = `${genericProxyRequestUrl}${serialize(queryParams, '?')}`;

		return new BaseService(url).getRequest();
	},

	startConversation(tab, data) {
		return tab === 'all'
			? startConversationForAllApi.postRequest(data)
			: startConversationForNewApi.putRequest(data);
	},
	switchToAgent(params) {
		const url = `/genericProxyRequest/${serialize(params, '?')}`;
		const switchToAgentApi = new BaseService(url);

		return switchToAgentApi.getRequest();
	},
	switchToBotOrAgent(params, data) {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const switchToAgentApi = new BaseService(url, {
			headers: {
				'Content-Type': 'application/json',
				'audit-metadata': '{{audit-metadata}}',
			},
		});

		return switchToAgentApi.postRequest(data);
	},
	transferChat(params, data) {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const transferChatApi = new BaseService(url);

		return transferChatApi.putRequest(data);
	},
	getAgentsByCategory(params, data) {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const getAgentsByCategoryApi = new BaseService(url);

		return getAgentsByCategoryApi.postRequest(data);
	},
	blockUser(params) {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const blockUserApi = new BaseService(url);

		return blockUserApi.putRequest();
	},
	getQuickReplies(role, pageNo) {
		const params = {
			bot_ref: '{{botRef}}',
			user_id: '{{userId}}',
			targetApi:
				role === 'ROLE_CONNECT_SUPERVISOR' || role === 'ROLE_CONNECT_OWNER' ? 'teamsQuickReply' : 'quickReply',
			channel: 'Text',
			user_role: role,
			page: pageNo,
			size: 10,
			sort: 'updatedOn,desc',
		};

		return quickRepliesApi.getRequest(serialize(params, '?'));
	},
	markToTrain(params, body) {
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;
		const markToTrainApi = new BaseService(url);

		return markToTrainApi.postRequest(body);
	},
	uploadAttachments(file) {
		const data = new FormData();
		data.append('file', file);
		data.append('workflow', 'LIVE_CHAT_AGENT_ATTACHMENT');

		return uploadAttachmentsApi.postRequest(data).then(
			(response) => response,
			() => {
				console.log('errored');

				return data;
			}
		);
	},
	getLiveChatConfig: () => liveChatConfigApi.getRequest(),
	exitConversation: (body) => exitConversationApi.postRequest(body),
	joinConversation: (body) => joinConversationApi.postRequest(body),
	inviteToGroupConversation: (body) => inviteToGroupConversationApi.postRequest(body),
	uploadEmailAttachment(data, params, headers, config) {
		return new BaseService(uploadEmailAttachmentUrl, { headers }).postRequest(data, serialize(params, '?'), config);
	},
	getEmailContext(cid, htmlToken) {
		const url = `/v2/${cid}/attachment/?token=${htmlToken}`;

		return new BaseService(url).getRequest();
	},
	getEmailConfig() {
		return getEmailConfigApi.getRequest();
	},
	getCannedResponses(page, size) {
		const params = {
			targetApi: 'getAllCannedResponses',
			page,
			size,
			sort: 'updatedAt,desc',
		};

		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;

		return new BaseService(url, {
			headers: {
				customerId: '{{customerId}}',
				userId: '{{userId}}',
				'Content-Type': 'application/json',
			},
		}).getRequest();
	},
	getBotMailTemplate(id) {
		const params = {
			templateId: id,
			targetApi: 'getBotMailTemplate',
		};
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;

		return new BaseService(url).getRequest();
	},
	getUserInfoAttributes(body) {
		return getUserInfoAttributesApi.postRequest(body);
	},
	getSmartResponse(body) {
		return getSmartResponseApi.postRequest(body);
	},
	getAgentCategories(body) {
		const params = {
			targetApi: 'getAgentCategories',
			agentId: body,
		};
		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;

		return new BaseService(url).getRequest();
	},
	getTranslatedMessages(body) {
		return getAutoTranslationMessagesApi.postRequest(body);
	},
	searchQuickReply(data) {
		const params = {
			targetApi: 'quickReplySearch',
			...data,
		};

		const url = `${genericProxyRequestUrl}${serialize(params, '?')}`;

		return new BaseService(url).postRequest();
	},
	generateConversationSummary(data) {
		return generateConversationSummaryApi.postRequest(data);
	},
	fetchConversationSummary(params) {
		return fetchConversationSummaryApi.getRequest(serialize(params, '?'));
	},
	getExplainedQuery(data) {
		return getExplainedQueryApi.postRequest(data);
	},
};
