import loadable from '@loadable/component';

import { IRouteProps } from 'shared/consts/types';

const GetStartedSalesInfo = loadable(
	() => import('pages/OnBoarding/GetStartedSalesInfo' /* webpackChunkName: "GetStartedSalesInfo" */)
);
const shopifyOnboardingWhatsappAccountSetupPage = loadable(
	() =>
		import(
			'pages/ShopifyOnboardingV2/WhatsappAccountSetup/WhatsappAccountSetup' /* webpackChunkName: "ShopifyWhatsappAccountSetup" */
		)
);
const shopifyOnboardingStoreSetupPage = loadable(
	() => import('pages/ShopifyOnboardingV2/StoreSetup/StoreSetup' /* webpackChunkName: "ShopifyStoreSetup" */)
);
const shopifyOnboardingErrorPage = loadable(
	() => import('pages/ShopifyOnboardingV2/Error/Error' /* webpackChunkName: "ShopifyErrorPage" */)
);
export const ShopifyOnboardingRoutes: { [key: string]: string } = {
	shopifyOnboardingWhatsappAccountSetup: '/store/whatsapp-setup',
	shopifyOnboardingStoreSetup: '/store/store-setup',
	shopifyOnboardingError: '/store/error',
};

export const EmptyPageRoutesLinks: { [key: string]: string } = {
	portalGetStartedSalesInfoLink: '/get-started/usage-info',
	...ShopifyOnboardingRoutes,
};

const {
	portalGetStartedSalesInfoLink,
	shopifyOnboardingWhatsappAccountSetup,
	shopifyOnboardingStoreSetup,
	shopifyOnboardingError,
} = EmptyPageRoutesLinks;

export const EmptyPageRoutes: Array<IRouteProps> = [
	{
		path: shopifyOnboardingStoreSetup,
		component: shopifyOnboardingStoreSetupPage,
	},
	{
		path: portalGetStartedSalesInfoLink,
		component: GetStartedSalesInfo,
	},
	{
		path: shopifyOnboardingWhatsappAccountSetup,
		component: shopifyOnboardingWhatsappAccountSetupPage,
	},
	{
		path: shopifyOnboardingError,
		component: shopifyOnboardingErrorPage,
	},
];
