import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from 'store/Modal';
import { RootState } from 'store/rootReducer';

import InfoModalBase from './InfoModalBase';

const { closeInfoModalAction } = modalActions;

function InfoModal() {
	const isModalOpen = useSelector((state: RootState) => state?.Modal?.infoModal?.isOpen);
	const heading = useSelector((state: RootState) => state?.Modal?.infoModal?.heading);
	const description = useSelector((state: RootState) => state?.Modal?.infoModal?.description);

	const dispatch = useDispatch<any>();

	const onCloseFn = () => {
		dispatch(closeInfoModalAction());
	};

	return (
		<InfoModalBase onCloseFn={onCloseFn} heading={heading} isModalOpen={isModalOpen} description={description} />
	);
}

export default InfoModal;
