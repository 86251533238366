import React from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ListItemWithIcon } from 'components/UI/ListItemWithIcon/ListItemWithIcon';

import IntegrationsIcon from 'shared/icons/IntegrationsIcon';

import { isWhatsappAcceleratorEnabled } from 'store/App/User/selectors';
import { RootState } from 'store/rootReducer';

import { LEFT_PANEL_ACCESS_ROUTES } from 'router/ProtectedRouter/protectedRoute';

const Integrations = () => {
	const [, theme]: [any, any] = useStyletron();
	const { t }: any = useTranslation(['common']);

	const leftPanelsV2Data = useSelector((state: RootState) => state.User.botAdmin.data.left_panels_v2_keys || []);
	const botsAvailable = useSelector((state: RootState) => state.BotList.data?.length);
	const isWhatsappAccelerator = useSelector(isWhatsappAcceleratorEnabled);

	const hasIntegrationsEntitlement = (): boolean =>
		leftPanelsV2Data.indexOf(LEFT_PANEL_ACCESS_ROUTES['/integrations']) > -1;

	const integrationsIcon = () => <IntegrationsIcon fill={theme.colors.navIconFillColor} />;

	return hasIntegrationsEntitlement() && botsAvailable > 0 && !isWhatsappAccelerator ? (
		<ListItemWithIcon
			iconElement={integrationsIcon}
			itemLabel={t('common:integration_plural')}
			linkTo='/integrations'
		/>
	) : (
		<></>
	);
};

export default Integrations;
