import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { toaster } from 'baseui/toast';
import { LabelMedium } from 'baseui/typography';
import { addMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DefaultDatePicker from 'components/UI/ENGTDatePicker/DefaultDatePicker';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ENGTTimePicker from 'components/UI/ENGTTimePicker/ENGTTimePicker';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import { ListItemWithIcon } from 'components/UI/ListItemWithIcon/ListItemWithIcon';
import Loader from 'components/UI/Loader/Loader';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { ACCOUNT_TYPES, ENGATI_ROLES, PLAN_OFFERING_KEYS, TIME_SLOTS } from 'shared/consts/consts';
import { isValidResponseObject } from 'shared/helpers';
import datePickerIcon from 'shared/icons/DatePickerIcon.svg';
import InformationIcon from 'shared/icons/InformationIcon';

import { API } from 'store/AccountSettings/api';
import { isShopifyPrivateAcc, isWhatsappAcceleratorEnabled } from 'store/App/User/selectors';
import { RootState } from 'store/rootReducer';
import { isEngatiBrandSelector } from 'store/Users/selectors';

/**
 * @author khushboo
 * Created on: 29/07/2021
 */

interface IGetHelpProps {
	isOpen: boolean;
	toggleModal: Function;
}

const GENIUS_BOOKING_AMOUNT: any = {
	USD: 42,
	INR: 3360,
};

const GetProfessionalHelpModal = ({ isOpen, toggleModal }: IGetHelpProps) => {
	const { t }: any = useTranslation(['common', 'components']);
	const isOwnerAccount =
		useSelector((state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_OWNER) ||
		false;
	const currencyName: string = useSelector((state: RootState) => state.Plan.details.data?.currencyName);

	const [isLoading, setLoading] = useState<boolean>(false);
	const [openDateTime, toggelDateTime] = useState<boolean>(false);
	const [dateValue, setDateValue] = useState<Date>(new Date());
	const [minDateValue, setMinDateVal] = useState<Date>(new Date());
	const [isStripeCustomer, setIsStripeCustomer] = useState<boolean>(false);
	const [description, setDescription] = useState<string>('');
	const [css, theme]: any = useStyletron();
	const [paymentStatus, setPaymentStatus] = useState<boolean | null>(null);
	const [contentLoader, setContentLoader] = useState<boolean>(false);

	const [timeValue, setTime] = useState<string>(TIME_SLOTS[0]?.id);

	const fetchDetails = () => {
		setLoading(true);
		Promise.all([API.checkStripeExist()]).then(([stripeDetails]) => {
			if (stripeDetails?.data?.responseObject) {
				const customer_exists = stripeDetails?.data?.responseObject;
				setIsStripeCustomer(customer_exists);
				if (!customer_exists && !isOwnerAccount) {
					setDescription(t('components:getProfessionalHelpModal.contactOwner'));
				}
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		fetchDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const today = new Date();
		today.setTime(today.getTime() + 24 * 60 * 60 * 1000);
		today.setHours(5, 30, 0, 0);
		setDateValue(today);
		setMinDateVal(today);
	}, []);

	const proceedToCheckout = () => {
		const startDate: Date = addMinutes(dateValue, 330);
		const endDate = addMinutes(startDate, 30);
		const data = {
			slotStartTime: startDate?.toISOString(),
			slotEndTime: endDate?.toISOString(),
			currentUrl: `${window.location.origin}/admin/bots`,
		};

		setLoading(true);
		API.startStripeSessionForGenius(data)
			.then((response: any) => {
				if (isValidResponseObject(response)) {
					const { responseObject: stripeSession } = response.data;
					if (!isOwnerAccount) {
						setPaymentStatus(false);
						setDescription(t('components:getProfessionalHelpModal.nonOwnerBookingFailed'));
					} else {
						toggleModal(false);
						window.location.replace(stripeSession);
					}
				} else {
					toaster.negative(
						<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
						{}
					);
				}
				setLoading(false);
			})
			.catch((error: any) => {
				toggleModal(false);
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			});
	};

	const handleConfirm = async () => {
		if (isStripeCustomer) {
			paymentStatus !== null ? toggleModal(false) : proceedToCheckout();
		} else if (isOwnerAccount) {
			proceedToCheckout();
		} else {
			setDescription(t('components:getProfessionalHelpModal.contactOwner'));
		}
	};

	return (
		<>
			{contentLoader ? (
				<Loader overrides={css({ display: 'none' })} loaderBlockHeight='50%' />
			) : (
				<ActionModal
					onClose={() => {
						toggleModal(false);
					}}
					isFooterReq
					isOpen={isOpen}
					confirmBtnLabel={
						(!isStripeCustomer && !isOwnerAccount) || (isStripeCustomer && paymentStatus !== null)
							? t('components:getProfessionalHelpModal.done')
							: t('components:getProfessionalHelpModal.proceed')
					}
					heading={t('common:getProfessionalHelp')}
					onConfirm={handleConfirm}
					isConfirmBtnDisabled={isLoading}
				>
					<>
						{isLoading ? (
							<Loader loaderBlockHeight='100%' />
						) : description ? (
							<LabelMedium marginBottom='1rem'>{description}</LabelMedium>
						) : (
							<>
								<LabelMedium marginBottom='1rem'>
									{t('components:getProfessionalHelpModal.description', {
										amount: `${GENIUS_BOOKING_AMOUNT[currencyName]} ${currencyName}`,
									})}
								</LabelMedium>
								<LabelMedium
									marginBottom='0.5rem'
									color={theme.colors.modalTextColor}
									className={css({ fontWeight: '500' })}
								>
									{t('components:getProfessionalHelpModal.chooseSlot')}
								</LabelMedium>
								<div
									onClick={() => toggelDateTime(true)}
									className={css({
										display: 'flex',
										justifyContent: 'space-between',
										paddingLeft: '1rem',
										paddingRight: '1rem',
										paddingTop: '1rem',
										paddingBottom: '1rem',
										borderTopWidth: '1px',
										borderLeftWidth: '1px',
										borderRightWidth: '1px',
										borderBottomWidth: '1px',
										borderStyle: 'solid',
										borderTopColor: theme.colors.horizantalDividerColor,
										borderBottomColor: theme.colors.horizantalDividerColor,
										borderLeftColor: theme.colors.horizantalDividerColor,
										borderRightColor: theme.colors.horizantalDividerColor,
										borderTopRightRadius: '0.5rem!important',
										borderTopLeftRadius: '0.5rem!important',
										borderBottomRightRadius: '0.5rem!important',
										borderBottomLeftRadius: '0.5rem!important',
										cursor: 'pointer',
									})}
								>
									<LabelMedium
										className={css({
											color: theme.colors.accent50,
										})}
									>
										{`${dateValue?.toLocaleDateString?.()} ${dateValue?.toLocaleTimeString?.(
											'en-US',
											{
												hour: '2-digit',
												minute: '2-digit',
												hour12: true,
											}
										)}` || t('components:getProfessionalHelpModal.selectDateTime')}
									</LabelMedium>
									<ENGTImage lazy src={datePickerIcon} alt='date-picker' />
								</div>
							</>
						)}
					</>
				</ActionModal>
			)}
			{openDateTime && (
				<ActionModal
					isOpen={openDateTime}
					onClose={() => toggelDateTime(false)}
					onConfirm={() => toggelDateTime(false)}
					isConfirmBtnDisabled={isLoading}
					heading={t('components:getProfessionalHelpModal.chooseSlot')}
					overridHeaderCss={{
						color: theme.colors.modalTextColor,
						fontWeight: '400 !important',
						fontSize: '1rem !important',
						lineHeight: '1.25rem !important',
						marginLeft: '0rem !important',
						marginRight: '0rem!important',
						marginTop: '0rem !important',
						marginBottom: '0.5rem!important',
					}}
				>
					<div
						className={css({
							display: 'flex',
							alignContent: 'space-around!important',
							flexDirection: 'column',
							zindex: '100',
						})}
					>
						<DefaultDatePicker
							value={dateValue}
							onChange={(val: any) => {
								const valDate = val;
								if (timeValue) {
									const time = timeValue.split('_');
									valDate.setHours(time[0], time[1], 0, 0);
								} else {
									valDate.setHours(5, 30, 0, 0);
								}
								setDateValue(val);
							}}
							minDate={minDateValue}
							isNewFilter
							professionalHelpFlow
						/>
						<ENGTTimePicker
							value={timeValue}
							options={TIME_SLOTS}
							onChange={(val) => {
								if (val) {
									const selectedDate = dateValue;
									const timeValue = val.split('_');
									selectedDate?.setHours(timeValue[0], timeValue[1], 0, 0);
									setDateValue(selectedDate);
									setTime(val);
								}
							}}
							popupIndex={theme.zIndex400}
							placeholder={t('common:selectTime')}
						/>
					</div>
				</ActionModal>
			)}
		</>
	);
};

const GetProfessionalHelp = () => {
	const [, theme]: [any, any] = useStyletron();
	const { t }: any = useTranslation(['common']);

	const [getHelpModal, showGetHelpModal] = useState<boolean>(false);

	const isWhatsappAccelerator = useSelector(isWhatsappAcceleratorEnabled);
	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const currentPlanOffering = useSelector((state: RootState) => state.Plan.details.data?.planOfferingName);
	const isGeniusEnabled = useSelector((state: RootState) => state.User.botAdmin.data.isGeniusEnabled);
	const accountType = useSelector((state: RootState) => state.User.botAdmin.data.customer_account_type);
	const isNotReseller = useSelector((state: RootState) => !state.User.botAdmin.data.reseller_login);
	const isShopifyOffering = () =>
		currentPlanOffering === PLAN_OFFERING_KEYS['SHOPIFY'] ||
		currentPlanOffering === PLAN_OFFERING_KEYS['SHOPIFY_PUBLIC'];
	const isShopifyPrivateAccount = useSelector(isShopifyPrivateAcc);

	const shopifyProfessionalHelpCheck = isShopifyOffering() ? isShopifyPrivateAccount : true;
	const showGetHelpOption =
		isNotReseller &&
		accountType === ACCOUNT_TYPES.CUSTOMER &&
		isGeniusEnabled &&
		isEngatiBrand &&
		shopifyProfessionalHelpCheck;

	const informationIcon = () => <InformationIcon fill={theme.colors.navIconFillColor} />;

	return showGetHelpOption && !isWhatsappAccelerator ? (
		<>
			<ListItemWithIcon
				iconElement={informationIcon}
				itemLabel={t('common:getProfessionalHelp')}
				handleClick={() => showGetHelpModal(true)}
			/>
			{getHelpModal && <GetProfessionalHelpModal isOpen={getHelpModal} toggleModal={showGetHelpModal} />}
		</>
	) : (
		<></>
	);
};

export default GetProfessionalHelp;
