import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Cell, Grid } from 'baseui/layout-grid';

import { useBannerVisiblityFinder } from 'components/UI/ENGTBanner/ENGTBannerContext';

import { LAYOUT_CONTENT } from 'shared/consts/QAConsts';
import { IObjProps } from 'shared/consts/types';
import { LAYOUT_COMPONENTS } from 'shared/enum';
import { getBannerExcludedPageHeight } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import LayoutRightPanelMobile from './LayoutRightPanelMobile';

export const cellCss: any = {
	Cell: {
		style: () => ({
			paddingLeft: '0rem !important',
			paddingRight: '0rem !important',
		}),
	},
};
interface ILayoutProps {
	/* - 12 Grid Layout
	   - Page content takes 7 grid columns
	   - Leaves 1 grid for the right side spacing of right panel
	   - Right side panel takes 4 grid columns. 
	      - By default the page with right side panel (like Help & Widget) takes 3 grid columns and leaves 1 grid right side spacing
		  - TODO - Pages like Web Display have to take entire 4 grids with no spacing

	/* Utilizing the 8 grid incase of no right side widget .ie. Help and support
	   Keeping 1 grid as gutter incase of the rightpanel exist
	*/

	/* Page content - Takes 7 grid columns to fit the content */
	children: React.ReactNode;
	overrides?: IObjProps;
	content?: {
		skip?: number | number[];
		span?: number | number[];
	};
}

const LayoutHeader = ({ children }: any) => <div>{children}</div>;

const LayoutContent = ({ children, overrides }: any) => {
	const [css, theme]: any = useStyletron();

	if (children?.props?.tabOverrides || children?.type?.name === 'ENGTTabs') {
		children.props.tabOverrides['TabList'] = {
			style: {
				position: 'sticky',
				top: '0',
				zIndex: theme.zIndex300,
				backgroundColor: theme.colors.backgroundPrimary,
				...children.props.tabOverrides?.TabList?.style,
			},
		};
	}

	return (
		<Block
			data-qa={LAYOUT_CONTENT}
			className={css({
				minHeight: '0px',
				overflowY: 'auto',
				overflowX: 'hidden',
				zIndex: 0,
				flexGrow: 1,
				...overrides,
			})}
			padding={['1rem', '1rem', '1rem', 0]}
		>
			{children}
		</Block>
	);
};

const LayoutFooter = ({ children }: any) => {
	const [css, theme]: any = useStyletron();

	return (
		<div
			className={css({
				alignSelf: 'flex-end',
				width: '100%',
				paddingTop: '1rem',
				paddingBottom: '1rem',
				marginTop: 'auto',
				borderTopWidth: '1px',
				borderTopStyle: 'solid',
				borderColor: theme.navigationBorderColor,
				borderOpacity: 0.4,
			})}
		>
			{children}
		</div>
	);
};

const LayoutRightPanel = ({
	children,
	isHelpSection,
	overrides = {},
}: {
	children: any;
	isHelpSection: boolean;
	overrides?: IObjProps;
}) => {
	const [css]: [any, any] = useStyletron();
	const bannerHeight = useBannerVisiblityFinder();

	return (
		<Block
			display='flex'
			flexDirection='row'
			justifyContent={['center', 'center', 'flex-start']}
			height={getBannerExcludedPageHeight(bannerHeight)}
			width='100%'
			paddingTop={['4rem', '4rem', '4rem', isHelpSection ? '10.5rem' : 0]}
			className={css(overrides)}
		>
			{children}
		</Block>
	);
};

LayoutFooter.displayName = LAYOUT_COMPONENTS.LAYOUT_FOOTER;
LayoutContent.displayName = LAYOUT_COMPONENTS.LAYOUT_CONTENT;
LayoutHeader.displayName = LAYOUT_COMPONENTS.LAYOUT_HEADER;
LayoutRightPanel.displayName = LAYOUT_COMPONENTS.LAYOUT_RIGHT_PANEL;

const Layout = ({ children, content, overrides }: ILayoutProps): JSX.Element => {
	const [css]: [any, any] = useStyletron();
	const { isSmallDesktopScreen } = useResponsiveSize();

	const wrapperCss = {
		width: '100%',
		height: '100%',
		...overrides?.wrapperCss,
	};

	const bannerHeight = useBannerVisiblityFinder();

	const renderContent = (children: any): JSX.Element => {
		const mainContent = Array.isArray(children)
			? children.filter((element: any) => element?.type?.displayName !== LAYOUT_COMPONENTS.LAYOUT_RIGHT_PANEL)
			: children;

		const rightPanel = Array.isArray(children)
			? children.filter((element: any) => element?.type?.displayName === LAYOUT_COMPONENTS.LAYOUT_RIGHT_PANEL)
			: undefined;

		const hasRightPanelContent = Array.isArray(rightPanel) && rightPanel.length > 0;
		const isHelpSection = hasRightPanelContent && rightPanel[0]?.props?.isHelpSection;

		const calculateSpan = () => {
			if (hasRightPanelContent) {
				return [12, 12, 7];
			}
			if (content?.span) {
				return content.span;
			}

			return [12, 12, 10];
		};

		const getSpan = () => {
			if (Array.isArray(content?.skip)) {
				return content?.skip.map((skip) => 4 - (skip || 0));
			}

			return 4 - (content?.skip || 0);
		};

		return (
			<>
				<Grid gridUnit='rem' gridColumns={12} gridGutters={[1]}>
					<Cell span={calculateSpan()} overrides={cellCss} skip={content?.skip}>
						<div
							className={css({
								display: 'flex',
								flexDirection: 'column',
								height: getBannerExcludedPageHeight(bannerHeight),
								maxHeight: getBannerExcludedPageHeight(bannerHeight),
								minHeight: '0px',
								textAlign: 'left',
								overflow: 'hidden',
								width: '100%',
							})}
						>
							{mainContent}
						</div>
					</Cell>
					{!isSmallDesktopScreen && (
						<Cell skip={hasRightPanelContent ? 1 : 0} overrides={cellCss} span={getSpan()}>
							{hasRightPanelContent && rightPanel}
						</Cell>
					)}
				</Grid>
				{isSmallDesktopScreen && hasRightPanelContent && isHelpSection && (
					<LayoutRightPanelMobile rightPanel={rightPanel} />
				)}
			</>
		);
	};

	return <Block className={css(wrapperCss)}>{renderContent(children)}</Block>;
};

export default Layout;

export { Layout, LayoutContent, LayoutFooter, LayoutHeader, LayoutRightPanel };

/* USAGE */
/*
	<Layout>
		<LayoutHeader>
			<PageHeader
				heading={'Mobile App'}
				subHeading={'We offer mobile-ready Web view of our chatbot as well as Mobile SDKs for Apple iOS and Android.'}
				backButtonLable={'Deployment'}
			/>
		</LayoutHeader>
		<LayoutContent>
			.......
		</LayoutContent>
		<LayoutRightPanel>
			<Block className={css({ marginTop: '0px', paddingTop: '10.5rem' })}>
				<HelpSection
					description={helpSection.headingDescription}
					heading={helpSection.heading}
					helpLinksArray={helpSection.links}
					footerButtonAction={() => {
						window.open(ENGATI_LINK.viewResources, '_blank');
					}}
				/>
			</Block>
		</LayoutRightPanel>
		<LayoutFooter>
			<ENGTButton label={t('common:save changes')} onClick={saveLineBot} isDisabled={buttonDisabled} />
		</LayoutFooter>
		<UnSavedChangesPrompt when={isFormDirty} />
	</Layout>
*/
