import React from 'react';

function UploadIcon({ fillColor, size }: { fillColor?: string; size?: number }) {
	return (
		<svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				stroke={fillColor}
				strokeWidth='1.5'
				strokeLinecap='square'
				strokeLinejoin='bevel'
				d='M17.668 14.1162V16.4495C17.668 16.759 17.5451 17.0557 17.3263 17.2745C17.1075 17.4933 16.8107 17.6162 16.5013 17.6162H8.33464C8.02522 17.6162 7.72847 17.4933 7.50968 17.2745C7.29089 17.0557 7.16797 16.759 7.16797 16.4495V14.1162'
			/>
			<path
				stroke={fillColor}
				strokeWidth='1.5'
				strokeLinecap='square'
				d='M15.334 10.0327L12.4173 7.11605L9.50065 10.0327'
			/>
			<path
				stroke={fillColor}
				strokeWidth='1.5'
				strokeLinecap='square'
				strokeLinejoin='bevel'
				d='M12.417 8.19445L12.417 14.1162'
			/>
		</svg>
	);
}

UploadIcon.defaultProps = {
	fillColor: 'white',
	size: 24,
};

export default UploadIcon;
