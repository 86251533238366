import { useStyletron } from 'baseui';
import { Button, KIND, SIZE } from 'baseui/button';
import { HeadingLevel } from 'baseui/heading';
import { toaster } from 'baseui/toast';
import { ParagraphMedium } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ENGTPieChart from 'components/UI/Graph/ENGTPieChart';

import { circularButtonCss } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { CHANNELS } from 'shared/enum';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import ArrowIcon from 'shared/icons/Broadcast/ArrowIcon';
import FacebookFullIcon from 'shared/icons/Messages/SocialInbox/FacebookFullIcon';
import InstagramIcon from 'shared/icons/Messages/SocialInbox/InstagramIcon';

import { profileDataSelector } from 'store/App/User';
import { downloadDataAction, IDownloadDataProps } from 'store/Download/actions';
import { RootState } from 'store/withReducer';

import InfoCardWithIcon from './InfoCardWithIcon';

interface ILeadSource {
	uniqueUsers?: IObjProps;
	nonUniqueUsers: IObjProps;
	filterOn: string;
}

const LeadSource = ({ uniqueUsers, nonUniqueUsers, filterOn }: ILeadSource) => {
	const { t } = useTranslation(['common', 'pages', 'components']);
	const [css, theme]: [any, any] = useStyletron();

	const { isSmallDesktopScreen } = useResponsiveSize();

	const dispatch = useDispatch<any>();

	const uid = useSelector((state: RootState) => state.User.botAdmin.data.uid);
	const botId = useSelector((state: RootState) => state.Bot.data.bot_ref);
	const profileData = useSelector(profileDataSelector);

	const noOfDays = parseInt(filterOn.substring(5));

	const styles = {
		paragraphCss: {
			marginTop: '0.75rem',
			marginBottom: '0.3125rem',
			fontWeight: '700 !important',
		},

		subParagraphCss: {
			marginTop: '0rem',
			lineHeight: '1.25rem',
			width: '100%',
			fontSize: '0.875rem !important',
			fontWeight: '400 !important',
		},

		cardWrapperCss: {
			display: 'grid',
			gridTemplateColumns: isSmallDesktopScreen ? '1fr' : '1.5fr 2.25fr 2.25fr',
			gap: '2rem',
		},
	};

	const calculateTotal = (data: IObjProps, channel: CHANNELS) =>
		data?.totalBySourceAndTypeDTO
			?.filter((item: { adSource: string }) => item.adSource === channel)
			?.flatMap((item: { adTypeCountDTOList: any }) => item.adTypeCountDTOList)
			?.reduce((total: number, type: { count: number }) => total + type.count, 0);

	const adTypesData = (data: IObjProps, channel: CHANNELS) => {
		const filteredChannelData = data?.totalBySourceAndTypeDTO?.filter(
			(item: { adSource: string }) => item.adSource === channel
		);

		const filteredAdData = filteredChannelData?.flatMap(
			(item: { adTypeCountDTOList: any }) => item.adTypeCountDTOList
		);

		if (!filteredAdData || filteredAdData.some((item: { count: number }) => item.count === 0)) {
			return [];
		}

		return filteredAdData.map((item: { adType: string; count: number }) => ({
			key: item.adType,
			value: item.count,
		}));
	};

	const downLoadFile = (fileName: string, workFlow: string, noOfDays?: number) => {
		const payLoad: IDownloadDataProps = {
			fileName,
			workFlow,
			userBotRef: botId,
		};
		payLoad['botUserId'] = uid;
		payLoad['columns'] = ['contact_number', 'username', 'joined_on', 'last_active_at', 'channels'];
		payLoad['searchTerm'] = '';
		payLoad['solrQuery'] =
			`(source_ids: [* TO *] AND bot_ref:${botId}) AND last_active_at:[NOW-${noOfDays}DAY/DAY TO NOW]`;
		dispatch(downloadDataAction(payLoad));
		downloadFileToaster();
	};

	const downloadFileToaster = () => {
		toaster.info(<ENGTToasterContainer title={t('common:success')} description={t('common:downloadInfo')} />, {});
	};

	return (
		<>
			<div className={css(styles.cardWrapperCss)}>
				<InfoCardWithIcon
					title={<strong>{t('pages:broadcast.clickToWhatsappAds.totalCTWAUsers')}</strong>}
					value={nonUniqueUsers?.total || 0}
					marginTop='2rem'
				>
					<div
						className={css({
							display: 'flex',
							alignItems: 'center',
							marginTop: '2rem',
							gap: '0.2rem',
						})}
					>
						<FacebookFullIcon />
						<span style={{ color: `${theme.colors.primaryA}` }}>+</span>
						<InstagramIcon size={18} />
					</div>
				</InfoCardWithIcon>
				<InfoCardWithIcon
					title={<strong>{t('pages:broadcast.clickToWhatsappAds.graph.throughFacebook')}</strong>}
					appIcon={<FacebookFullIcon size={14} />}
					value={calculateTotal(nonUniqueUsers, CHANNELS.FACEBOOK_ALL_CAPS) || 0}
				>
					<ENGTPieChart
						width={150}
						height={90}
						colors={[`${theme.colors.engatiYellow}`, `${theme.colors.engatiOrange2}`]}
						data={adTypesData(nonUniqueUsers, CHANNELS.FACEBOOK_ALL_CAPS)}
						accessor={(data: IObjProps) => data.key}
						margin={{
							top: 10,
							right: 0,
							bottom: 10,
							left: 10,
						}}
						textColor={theme.colors.primaryA}
						background={theme.colors.inputFillPrimary}
					/>
				</InfoCardWithIcon>
				<InfoCardWithIcon
					title={<strong>{t('pages:broadcast.clickToWhatsappAds.graph.throughInstagram')}</strong>}
					appIcon={<InstagramIcon size={14} />}
					value={calculateTotal(nonUniqueUsers, CHANNELS.INSTAGRAM_ALL_CAPS) || 0}
				>
					<ENGTPieChart
						width={150}
						height={90}
						colors={[`${theme.colors.engatiBlue}`, `${theme.colors.engatiOrange2}`]}
						data={adTypesData(nonUniqueUsers, CHANNELS.INSTAGRAM_ALL_CAPS)}
						accessor={(data: IObjProps) => data.key}
						margin={{
							top: 10,
							right: 0,
							bottom: 10,
							left: 10,
						}}
						textColor={theme.colors.primaryA}
						background={theme.colors.inputFillPrimary}
					/>
				</InfoCardWithIcon>
			</div>
			{uniqueUsers && (
				<>
					<HorizontalDivider
						height='0.5px'
						overrides={{ width: '100%', borderTop: '1.5px solid', marginTop: '1.5rem' }}
						color={theme.colors.accent100}
						borderColor={theme.colors.accent100}
					/>
					<div>
						<HeadingLevel>
							<ParagraphMedium
								color={theme.colors.primaryA}
								className={css({
									...styles.paragraphCss,
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								})}
							>
								<div
									className={css({
										display: 'flex',
										alignItems: 'center',
									})}
								>
									{t('pages:broadcast.clickToWhatsappAds.uniqueUsers')}
									<Button
										size={SIZE.default}
										shape='circle'
										kind={KIND.secondary}
										overrides={{
											BaseButton: {
												style: {
													...circularButtonCss,
													marginLeft: '0.5rem',
													height: '1.7rem',
													width: '1.7rem',
												},
											},
										}}
										onClick={() => {
											downLoadFile(
												`${botId}_unique_users_${noOfDays}days.csv`,
												'CONNECT_USERS',
												noOfDays
											);
										}}
									>
										<ArrowIcon width={22} height={22} />
									</Button>
								</div>
								{uniqueUsers?.lastUpdatedOn && !!uniqueUsers?.total && (
									<div
										className={css({ fontWeight: 400, fontSize: '0.875rem', alignItems: 'center' })}
									>
										{t('components:conversations.lastUpdatedOn')}
										<span style={{ marginLeft: '0.2rem' }}>{`${t(
											'common:standardDateTimeFormatterWithShortMonthNameAndTimeAheadOfDate',
											{
												date: uniqueUsers.lastUpdatedOn,
											}
										)} (${profileData.timezone})`}</span>
									</div>
								)}
							</ParagraphMedium>
							<ParagraphMedium color={theme.colors.primaryA} className={css(styles.subParagraphCss)}>
								{t('pages:broadcast.clickToWhatsappAds.uniqueUsersDescription')}
							</ParagraphMedium>
						</HeadingLevel>
					</div>
					<div className={css(styles.cardWrapperCss)}>
						<InfoCardWithIcon
							title={<strong>{t('pages:broadcast.clickToWhatsappAds.uniqueCTWAUsers')}</strong>}
							value={uniqueUsers?.total || 0}
							marginTop='2rem'
						>
							<div
								className={css({
									display: 'flex',
									alignItems: 'center',
									marginTop: '2rem',
									gap: '0.2rem',
								})}
							>
								<FacebookFullIcon />
								<span style={{ color: `${theme.colors.primaryA}` }}>+</span>
								<InstagramIcon size={18} />
							</div>
						</InfoCardWithIcon>
						<InfoCardWithIcon
							title={<strong>{t('pages:broadcast.clickToWhatsappAds.graph.throughFacebook')}</strong>}
							appIcon={<FacebookFullIcon size={14} />}
							value={calculateTotal(uniqueUsers, CHANNELS.FACEBOOK_ALL_CAPS) || 0}
						>
							<ENGTPieChart
								width={150}
								height={90}
								colors={[`${theme.colors.engatiYellow}`, `${theme.colors.engatiOrange2}`]}
								data={adTypesData(uniqueUsers, CHANNELS.FACEBOOK_ALL_CAPS)}
								accessor={(data: IObjProps) => data.key}
								margin={{
									top: 10,
									right: 0,
									bottom: 10,
									left: 10,
								}}
								textColor={theme.colors.primaryA}
								background={theme.colors.inputFillPrimary}
							/>
						</InfoCardWithIcon>
						<InfoCardWithIcon
							title={<strong>{t('pages:broadcast.clickToWhatsappAds.graph.throughInstagram')}</strong>}
							appIcon={<InstagramIcon size={14} />}
							value={calculateTotal(uniqueUsers, CHANNELS.INSTAGRAM_ALL_CAPS) || 0}
						>
							<ENGTPieChart
								width={150}
								height={90}
								colors={[`${theme.colors.engatiBlue}`, `${theme.colors.engatiOrange2}`]}
								data={adTypesData(uniqueUsers, CHANNELS.INSTAGRAM_ALL_CAPS)}
								accessor={(data: IObjProps) => data.key}
								margin={{
									top: 10,
									right: 0,
									bottom: 10,
									left: 10,
								}}
								textColor={theme.colors.primaryA}
								background={theme.colors.inputFillPrimary}
							/>
						</InfoCardWithIcon>
					</div>
				</>
			)}
			<HorizontalDivider
				height='0.5px'
				overrides={{ width: '100%', borderTop: '1.5px solid', marginTop: '1.5rem' }}
				color={theme.colors.accent100}
				borderColor={theme.colors.accent100}
			/>
		</>
	);
};

export default LeadSource;
