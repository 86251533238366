import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';

import ENGTSelect, { SelectOptionsType } from 'components/UI/ENGTSelect/ENGTSelect';

import { LS_KEYS, readFromLs, writeToLs } from 'shared/consts/localStorageConsts';
import { IObjProps } from 'shared/consts/types';
import { DAYS_FILTER } from 'shared/enum';

const DaysFilter = ({ filterOn }: { filterOn: Function }) => {
	const [css]: [any, any] = useStyletron();
	const { t } = useTranslation(['common', 'pages']);

	const [filter, setFilter] = useState<string>(() => {
		const savedFilter = readFromLs(LS_KEYS.ENGAGEMENT_FILTER);

		return savedFilter !== null ? savedFilter : DAYS_FILTER.DAYS_7;
	});

	const [filterTypeOptions, setFilterTypeOptions] = useState<SelectOptionsType>([
		{
			id: DAYS_FILTER.DAYS_7,
			label: t('components:exportCustomAudienceModal.1Week'),
		},
		{
			id: DAYS_FILTER.DAYS_28,
			label: t('components:exportCustomAudienceModal.4Week'),
		},
	]);

	useEffect(() => {
		const savedFilter = readFromLs(LS_KEYS.ENGAGEMENT_FILTER);
		if (savedFilter) {
			setFilter(savedFilter);
		}
	}, []);

	return (
		<div className={css({ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' })}>
			<ENGTSelect
				onChange={(value: IObjProps) => {
					const selectedFilter = value[0].id;
					filterOn(selectedFilter);
					setFilter(selectedFilter);
					writeToLs(LS_KEYS.ENGAGEMENT_FILTER, selectedFilter);
				}}
				overrides={{
					ControlContainer: {
						style: {
							fontSize: '0.875rem',
							fontWeight: 400,
						},
					},
				}}
				searchable={false}
				options={filterTypeOptions}
				clearable={false}
				selectedValue={[filterTypeOptions.find((option) => option.id === filter) || filterTypeOptions[0]]}
				width='17.125rem'
			/>
		</div>
	);
};

export default DaysFilter;
