import { NODES } from 'shared/consts/Nodes';
import { IObjProps } from 'shared/consts/types';

export interface IBuilderStateInterface {
	flows: {
		data: Array<IObjProps>;
		isLoading: boolean;
		isError: boolean;
	};
	flowAttributes: Array<IObjProps>;
	allAttributes: Array<IObjProps>;
	flowData: {
		data: IFlowDataInterface;
		isLoading: boolean;
		isError: boolean;
	};
	botRef: string;
	nodeEntitlements: IObjProps;
	nodesList: INodesListContentInterface;
	nodesAttrList: {
		data: IObjProps;
		lastNodeToRender: string;
	};
	pathAttributeToCopyLists: Array<string>;
	pathForAgents: {
		data: IObjProps;
		isLoading: boolean;
	};
	pathVariables: IObjProps;
	selectedNode: IObjProps;
	highlightedNode: IObjProps;
	isFormSubmitting: boolean;
	externalIntegrationKeys: { app_key: string; hash: string };
	externalIntegrations: IObjProps;
	connectedUsers: IObjProps;
	globalZoomScale: globalZoomScaleInterface;
}
export interface globalZoomScaleInterface {
	scaleX: number;
	scaleY: number;
	translateX: number;
	translateY: number;
	skewX: number;
	skewY: number;
}

export interface INodesListContentInterface {
	data: any;
	loading: boolean;
	search: any;
	query: string;
}

export interface IFlowDataInterface {
	path_key: string;
	nodes: Array<IObjProps>;
	display_name: string;
	settings_required: boolean;
	available_for_livechat: boolean;
	root_node: string;
}

export const builderState: IBuilderStateInterface = {
	flows: {
		data: [],
		isLoading: true,
		isError: false,
	},
	flowAttributes: [],
	allAttributes: [],
	flowData: {
		data: {
			path_key: '',
			nodes: [],
			display_name: '',
			settings_required: false,
			available_for_livechat: false,
			root_node: '',
		},
		isLoading: true,
		isError: false,
	},
	nodesAttrList: {
		data: {},
		lastNodeToRender: '',
	},
	botRef: '',
	nodeEntitlements: [],
	nodesList: {
		data: NODES(),
		loading: true,
		search: {},
		query: '',
	},
	pathAttributeToCopyLists: [],
	pathForAgents: {
		data: {},
		isLoading: true,
	},
	pathVariables: {},
	selectedNode: {},
	highlightedNode: {},
	isFormSubmitting: false,
	externalIntegrationKeys: { app_key: '', hash: '' },
	externalIntegrations: {},
	connectedUsers: [],
	globalZoomScale: { scaleX: 1, scaleY: 1, translateX: 0, translateY: 0, skewX: 0, skewY: 0 },
};
