import { LS_KEYS, readFromLs } from 'shared/consts/localStorageConsts';
import { DAYS_FILTER } from 'shared/enum';

import ClickToWhatsappAdsSummary from 'pages/BroadcastRevamp/ClickToWhatsappAds/ClickToWhatsappAds';

const CtwaEngagement = () => {
	const filterOn = readFromLs(LS_KEYS.ENGAGEMENT_FILTER) || DAYS_FILTER.DAYS_7;

	return <ClickToWhatsappAdsSummary daysFilter={filterOn} />;
};

export default CtwaEngagement;
