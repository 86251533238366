import { Navigate } from 'react-router-dom';

import loadable from '@loadable/component';

import { IRouteProps } from 'shared/consts/types';
import { CONTACT_TAB, QUICK_NAVIGATOR_TAB, SHOPIFY_DASHBOARD_TAB, USERS_TAB } from 'shared/enum';

import MessagesRedirection from 'pages/Messages/MessagesRedirection';

import { TwoColumnRouteLinks } from './links/TwoColumnRouteLinks';

const PathBuilder = loadable(() => import('pages/PathBuilder' /* webpackChunkName: "PathBuilder" */));
const BuilderNavigation = loadable(
	() => import('pages/PathBuilder/components/BuilderNavigation' /* webpackChunkName: "BuilderNavigation" */)
);
const Users = loadable(() => import('pages/Users' /* webpackChunkName: "Users" */));
const UsersPage = loadable(() => import('pages/Users/Users' /* webpackChunkName: "UsersPage" */));
const UserDetails = loadable(() => import('pages/UserDetails' /* webpackChunkName: "UserDetails" */));
const LandingPage = loadable(() => import('pages/Bots/Overview' /* webpackChunkName: "LandingPage" */));
const AgentProductivityDetails = loadable(
	() =>
		import(
			'pages/Analytics/LiveChat/AgentProductivity/AgentProductivityDetails' /* webpackChunkName: "AgentProductivityDetails" */
		)
);
const QuickStartGuide = loadable(
	() => import('pages/QuickStartGuide/QuickStartGuide' /* webpackChunkName: "QuickStartGuide" */)
);
const QuickNavigator = loadable(() => import('pages/QuickStartGuideShopify'));
const QuickNavigatorPage = loadable(() => import('pages/QuickStartGuideShopify/QuickNavigator'));
const WAQuickNavigator = loadable(() => import('pages/WAQuickNavigator/WAQuickNavigator'));

const Messages = loadable(() => import('pages/Messages' /* webpackChunkName: "MessagesOneView" */));
const MessagesContent = loadable(
	() => import('pages/Messages/MessagesContent' /* webpackChunkName: "MessagesContent" */)
);

const Team = loadable(() => import('pages/Team' /* webpackChunkName: "Team" */));
const Contacts = loadable(() => import('pages/Users/Contacts' /* webpackChunkName: "ContactsPage" */));

const {
	builderLink,
	landingPageLink,
	userDetailsLink,
	usersLink,
	usersHomeLink,
	usersContactsLink,
	agentDetailsLink,
	quickStartGuide,
	quickNavigator,
	acceleratorQuickNavigator,
	teamLink,
	waQuickNavigator,
	messagingHome,
	messagingTab,
	messagingConverastionTab,
	simplifiedMessages,
	simplifiedMessagesConversation,
} = TwoColumnRouteLinks;

const TwoColumnRoutes: Array<IRouteProps> = [
	{
		path: landingPageLink,
		component: LandingPage,
		subRoutes: [
			{
				path: SHOPIFY_DASHBOARD_TAB.REVENUE_DASHBOARD,
				element: <QuickNavigatorPage tab={SHOPIFY_DASHBOARD_TAB.REVENUE_DASHBOARD} />,
			},
			{
				path: SHOPIFY_DASHBOARD_TAB.PERFORMANCE_DASHBOARD,
				element: <QuickNavigatorPage tab={SHOPIFY_DASHBOARD_TAB.PERFORMANCE_DASHBOARD} />,
			},
			{
				path: SHOPIFY_DASHBOARD_TAB.SELLERS_ASSIST,
				element: <QuickNavigatorPage tab={SHOPIFY_DASHBOARD_TAB.SELLERS_ASSIST} />,
			},
		],
	},
	{
		path: userDetailsLink,
		component: UserDetails,
	},
	{
		path: usersLink,
		component: () => <Navigate to='/users/home' />,
	},
	{
		path: usersHomeLink,
		component: Users,
		subRoutes: [
			{ path: USERS_TAB.LIST, element: <UsersPage tab={USERS_TAB.LIST} /> },
			{ path: USERS_TAB.SEGMENT, element: <UsersPage tab={USERS_TAB.SEGMENT} /> },
			{ path: USERS_TAB.EXPORT, element: <UsersPage tab={USERS_TAB.EXPORT} /> },
			{ path: USERS_TAB.IMPORT, element: <UsersPage tab={USERS_TAB.IMPORT} /> },
		],
	},
	{
		path: usersContactsLink,
		component: Users,
		subRoutes: [
			{ path: CONTACT_TAB.LIST, element: <Contacts tab={CONTACT_TAB.LIST} /> },
			{ path: CONTACT_TAB.EXPORT, element: <Contacts tab={CONTACT_TAB.EXPORT} /> },
			{ path: CONTACT_TAB.IMPORT, element: <Contacts tab={CONTACT_TAB.IMPORT} /> },
		],
	},
	{
		path: agentDetailsLink,
		component: AgentProductivityDetails,
	},
	{
		path: quickStartGuide,
		component: QuickStartGuide,
	},
	{
		path: quickNavigator,
		component: QuickNavigator,
		subRoutes: [
			{
				path: QUICK_NAVIGATOR_TAB.BASIC_SETUP,
				element: <QuickNavigatorPage tab={QUICK_NAVIGATOR_TAB.BASIC_SETUP} />,
			},
			{
				path: QUICK_NAVIGATOR_TAB.FEATURE_EXPLORER,
				element: <QuickNavigatorPage tab={QUICK_NAVIGATOR_TAB.FEATURE_EXPLORER} />,
			},
		],
	},
	{
		path: acceleratorQuickNavigator,
		component: QuickNavigator,
		subRoutes: [
			{
				path: QUICK_NAVIGATOR_TAB.BASIC_SETUP,
				element: <QuickNavigatorPage tab={QUICK_NAVIGATOR_TAB.BASIC_SETUP} />,
			},
			{
				path: QUICK_NAVIGATOR_TAB.FEATURE_EXPLORER,
				element: <QuickNavigatorPage tab={QUICK_NAVIGATOR_TAB.FEATURE_EXPLORER} />,
			},
		],
	},
	{
		path: teamLink,
		component: Team,
	},
	{
		path: waQuickNavigator,
		component: WAQuickNavigator,
	},
	{
		path: messagingHome,
		component: MessagesRedirection,
	},
	{
		path: messagingTab,
		component: Messages,
		subRoutes: [
			{
				path: messagingConverastionTab,
				element: <MessagesContent />,
			},
		],
	},
	{
		path: simplifiedMessages,
		component: Messages,
		subRoutes: [
			{
				path: simplifiedMessagesConversation,
				element: <MessagesContent />,
			},
		],
	},
	{
		path: builderLink,
		component: BuilderNavigation,
		subRoutes: [
			{
				path: ':flowKey',
				element: <PathBuilder />,
			},
		],
	},
];

export default TwoColumnRoutes;
