import React, { useCallback } from 'react';

import { SHOPIFY_ROUTES } from 'shared/consts/consts';
import { IPrimaryShopifyNavItems } from 'shared/consts/types';

import NavigationHelperToolTips from '../components/NavigationHelperToolTips';
import NavigationLinks from '../components/NavigationLinks';
import useNavigationHook from '../hooks/useNavigationHook';

const Navigations = ({ path }: { path: string }) => {
	const { navigations, isActiveShopifyPath } = useNavigationHook(path);

	const getNavLink = useCallback((navItem: IPrimaryShopifyNavItems) => `/${SHOPIFY_ROUTES[navItem]}`, []);

	return <NavigationLinks navigations={navigations} isActive={isActiveShopifyPath} navLink={getNavLink} />;
};

function ShopifyNavigation(props: { path: string }) {
	const { path } = props;

	return (
		<>
			<Navigations path={path} />
			<NavigationHelperToolTips />
		</>
	);
}

export default ShopifyNavigation;
