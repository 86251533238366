import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { ParagraphLarge } from 'baseui/typography';
import { useSelector } from 'react-redux';

import AccountDropdownAvatar from 'components/AccountDropdownAvatar/AccountDropdownAvatar';
import Notifications from 'components/NotificationsCenter/Notifications';
import WatchTutorialBtn from 'components/PageHeader/WatchTutorialBtn';

import { isWhatsappAcceleratorPaid } from 'store/App/User';
import { isEngatiBrandSelector } from 'store/Users/selectors';
import { RootState } from 'store/withReducer';

import ContactSupport from './component/ContactSupport';
import NeedHelp from './component/NeedHelp';
import SwitchAccount from './component/SwitchAccount';

const DISABLE_USER_NAME_ON = ['/messages/', '/builder/', '/allmessages'];

const UserProfileHeader = (props?: any) => {
	const [css, theme]: any = useStyletron();

	const userName = useSelector((state: RootState) => state.User.profile.data.name);
	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const isWhatsappAcceleratorPaidPlan = useSelector(isWhatsappAcceleratorPaid);

	const [disableUserNameOnPaths, setDisableUserName] = useState<boolean>(false);

	useEffect(() => {
		DISABLE_USER_NAME_ON.forEach((path) => {
			if (window.location.pathname.indexOf(path) > -1) {
				setDisableUserName(true);
			}
		});
	});

	return (
		<div
			className={css({
				position: 'absolute',
				top: '0rem',
				right: 0,
				zIndex: theme.zIndex200,
				...(props?.height && {
					height: props.height,
				}),
			})}
		>
			<Block display='flex' alignItems='center' justifyContent='flex-end' height='100%'>
				<SwitchAccount />
				{/* <Resources /> */}
				<ContactSupport />
				{/* <SendFeedback /> */}
				<NeedHelp />
				{isWhatsappAcceleratorPaidPlan && isEngatiBrand && <WatchTutorialBtn />}
				{!disableUserNameOnPaths && (
					<ParagraphLarge
						marginRight='0.5rem'
						marginTop='0'
						marginBottom='0'
						className={css({
							maxWidth: '7rem',
							whiteSpace: 'nowrap',
							fontSize: '0.875rem !important',
							lineHeight: '1.25rem !important',
							letterSpacing: '0.0015em',
							wordBreak: 'break-word',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							fontWeight: '500 !important',
						})}
					>
						{userName}
					</ParagraphLarge>
				)}
				<>
					<Notifications />
					<AccountDropdownAvatar />
				</>
			</Block>
		</div>
	);
};

export default UserProfileHeader;
