import { cloneElement } from 'react';
import { useStyletron } from 'baseui';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';

import { ENGTMenuItem } from 'components/UI/ENGTMenuItem/ENGTMenuItem';

import { IObjProps } from 'shared/consts/types';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import ENGTTooltip from '../ENGTTooltip/ENGTTooltip';

export interface ITableRowAction {
	icon?: any;
	label?: any;
	items?: Array<ITableRowAction>;
	showTooltip?: any;

	onClick: (e: any) => any;
	visible?: (row: IObjProps) => boolean;
	disable?: (row: IObjProps) => boolean;
}

const TableRowAction = ({
	action,
	isActiveRow,
	setActiveRow,
	setActionsActive,
	visibility = true,
	rowId,
	setAccordionDisabled,
	accordionRow = false,
}: any) => {
	const [css, theme]: any = useStyletron();
	const { isSmallDesktopScreen } = useResponsiveSize();

	const handleActionOpen = () => {
		setActionsActive(true);
		accordionRow && setAccordionDisabled(true);
	};

	const handleActionClose = () => {
		accordionRow && setAccordionDisabled(false);
		setActiveRow(false);
		setActionsActive(false);
	};

	const processIcon = () =>
		cloneElement(action.icon, {
			title: '',
		});

	return (
		<StatefulPopover
			overrides={{
				Inner: {
					style: {
						backgroundColor: 'transparent',
					},
				},
				Body: {
					style: ({ $theme }: any) => ({
						paddingTop: '0',
						paddingBottom: '0',
						paddingLeft: '0',
						paddingRight: '0',
						marginTop: '0',
						marginBottom: '0',
						marginRight: '0',
						marginLeft: '0',
						borderTopLeftRadius: '0.5rem',
						borderTopRightRadius: '0.5rem',
						borderBottomLeftRadius: '0.5rem',
						borderBottomRightRadius: '0.5rem',
						zIndex: $theme.zIndex300,
						backgroundColor: $theme.colors.backgroundPrimary,
					}),
				},
			}}
			accessibilityType='none'
			onOpen={handleActionOpen}
			onClose={handleActionClose}
			placement={PLACEMENT.bottomRight}
			triggerType={TRIGGER_TYPE.click}
			content={({ close }) => (
				<ul
					className={css({
						visibility: action.items.length ? 'visible' : 'hidden',
						cursor: 'pointer',
						minWidth: '160px',
						width: 'fit-content',
						listStyleType: 'none',
						marginTop: '-0.5rem',
						marginBottom: '-0.5rem',
						marginRight: '0',
						marginLeft: '0',
						paddingTop: '0.375rem',
						paddingBottom: '0.375rem',
						paddingLeft: '0.375rem',
						paddingRight: '0.375rem',
						boxShadow: theme.lighting.shadow400,
						borderTopLeftRadius: theme.borders.radius300,
						borderTopRightRadius: theme.borders.radius300,
						borderBottomLeftRadius: theme.borders.radius300,
						borderBottomRightRadius: theme.borders.radius300,
						background: theme.colors.buttonPrimaryText,
					})}
				>
					{action.items
						.filter((item: ITableRowAction) => item.visible && item.visible({}))
						.map((item: ITableRowAction, index: number) =>
							item.showTooltip && item.disable && item.disable({}) ? (
								<ENGTTooltip content={item.showTooltip}>
									<li
										key={`table-action-item-btn-${Math.random() * 100}-${index}`}
										onClick={(event: any) => {
											close();
											!(item.disable && item.disable({})) && item.onClick(event);
										}}
										className={css({
											cursor: item.disable && item.disable({}) ? 'not-allowed' : 'pointer',
										})}
									>
										<ENGTMenuItem
											label={item.label}
											Icon={item.icon ? item.icon : false}
											dataQa={`${rowId}-more-action-item-${index}`}
											isDisabled={(item.disable && item.disable({})) || false}
										/>
									</li>
								</ENGTTooltip>
							) : (
								<li
									key={`table-action-item-btn-${Math.random() * 100}-${index}`}
									onClick={(event: any) => {
										close();
										!(item.disable && item.disable({})) && item.onClick(event);
									}}
									className={css({
										cursor: item.disable && item.disable({}) ? 'not-allowed' : 'pointer',
									})}
								>
									<ENGTMenuItem
										label={item.label}
										Icon={item.icon ? item.icon : false}
										dataQa={`${rowId}-more-action-item-${index}`}
										isDisabled={(item.disable && item.disable({})) || false}
									/>
								</li>
							)
						)}
				</ul>
			)}
		>
			<div
				className={css({
					float: 'right',
					display: 'flex',
					cursor: 'pointer',
					alignItems: 'center',
					visibility: (isActiveRow || isSmallDesktopScreen) && visibility ? 'visible' : 'hidden',
				})}
				onClickCapture={(e) => {
					action.onClick();
					accordionRow && setAccordionDisabled(true);
				}}
				data-qa={`${rowId}-more-action`}
			>
				{action.icon && processIcon()}
			</div>
		</StatefulPopover>
	);
};

export default TableRowAction;
