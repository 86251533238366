import React from 'react';
import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { Modal as BaseModal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';

import Button from 'components/UI/ENGTButton/ENGTButton';
import Loader from 'components/UI/Loader/Loader';

export interface IModalProps {
	autoFocus?: boolean;
	children?: any;
	closeBtnLabel?: string;
	confirmBtnLabel: string;
	description: any;
	descriptionCss?: any;
	heading: string;
	height?: string;
	isConfirmBtnDisabled?: boolean;
	isLoading?: boolean;
	isOpen: boolean;
	width?: string;
	hideCloseBtn?: boolean;

	onClose: (args?: any) => any;
	onConfirm: (args?: any) => any;
	onModalClose?: () => any | undefined;
}

const modalHeaderCss = ($theme: any) => ({
	color: $theme.modalTextColor,
	fontWeight: 700,
	marginTop: '0',
	fontSize: '1.25rem !important',
	lineHeight: '1.625rem !important',
	margin: '0 !important',
});

const modalFooterCss = () => ({
	display: 'flex',
	textAlign: 'center !important' as 'center',
	marginLeft: '0 !important',
	marginRight: '0 !important',
	paddingBottom: '0 !important',
});

const modalLoaderCss = (theme: any) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	background: theme.colors.primaryA,
	width: '100%',
	opacity: '0.3',
	height: '100%',
	borderRadius: '1rem',
	zIndex: theme.zIndex400,
});

const modalOverrideProps = ({
	height,
	width,
	theme,
	hideCloseBtn,
}: {
	height?: string;
	width?: string;
	theme: any;
	hideCloseBtn: boolean;
}) => ({
	Root: {
		style: {
			zIndex: theme.zIndex300,
		},
	},
	Close: {
		style: {
			display: hideCloseBtn ? 'none' : 'block',
			borderRadius: '50%',
			height: '48px',
			width: '48px',
			background: `${theme.modalCloseBgColor} !important`,
			stroke: `${theme.modalCloseStrokeColor} !important`,
			color: `${theme.modalCloseStrokeColor} !important`,
		},
	},
	Dialog: {
		style: {
			backgroundColor: theme.modalPrimaryBackground,
			borderTopRightRadius: '1rem',
			borderTopLeftRadius: '1rem',
			borderBottomLeftRadius: '1rem',
			borderBottomRightRadius: '1rem',
			paddingRight: '2rem',
			paddingLeft: '2rem',
			paddingTop: '2rem',
			paddingBottom: '2rem',
			width,
			height,
		},
	},
});

function DeleteModal(props: IModalProps) {
	const {
		autoFocus = true,
		children,
		closeBtnLabel,
		confirmBtnLabel,
		description,
		descriptionCss = {},
		heading,
		height,
		isOpen,
		isConfirmBtnDisabled,
		hideCloseBtn = false,
		isLoading,
		width,
		onClose,
		onConfirm,
		onModalClose,
	} = props;
	const [css, theme]: [any, any] = useStyletron();

	const ModalLoader = () =>
		isLoading ? (
			<div className={css(modalLoaderCss(theme))}>
				<Loader loaderBlockHeight='100%' />
			</div>
		) : (
			<></>
		);

	return (
		<>
			<BaseModal
				unstable_ModalBackdropScroll
				autoFocus={autoFocus}
				onClose={onModalClose || onClose}
				isOpen={isOpen}
				overrides={modalOverrideProps({ height, width, theme, hideCloseBtn })}
			>
				<ModalLoader />
				<ModalHeader className={css(modalHeaderCss(theme))}>{heading}</ModalHeader>
				<ModalBody
					className={css({
						marginLeft: '0 !important',
						marginRight: '0 !important',
					})}
				>
					{description && <p className={css(descriptionCss)}>{description}</p>}

					{children}
				</ModalBody>

				<ModalFooter className={css(modalFooterCss())}>
					<>
						<Button
							kind={KIND['secondary']}
							size={SIZE.large}
							onClick={onClose}
							label={closeBtnLabel}
							className={css({
								marginRight: '15px !important',
								width: '100%',
							})}
						/>
						<Button
							kind={KIND['primary']}
							size={SIZE.large}
							label={confirmBtnLabel}
							className={css({ width: '100%' })}
							isDisabled={isConfirmBtnDisabled}
							onClick={onConfirm}
						/>
					</>
				</ModalFooter>
			</BaseModal>
		</>
	);
}

DeleteModal.defaultProps = {
	autoFocus: true,
	confirmBtnLabel: 'Confirm',
	closeBtnLabel: 'Cancel',
	descriptionCss: {},
	isLoading: false,
	width: '23rem',
};

export default DeleteModal;
