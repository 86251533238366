import { TEAM } from 'shared/consts/QAConsts';

function Team({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	return (
		<svg
			width={size || 30}
			height={size || 30}
			viewBox='-4.5 -3 30 30'
			className={className}
			data-qa={TEAM}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.69873 3C8.69873 1.8972 9.59593 1 10.6987 1C11.8015 1 12.6987 1.8972 12.6987 3C12.6987 4.1028 11.8015 5 10.6987 5C9.59593 5 8.69873 4.1028 8.69873 3ZM11.0602 5.5415C12.0314 5.5415 12.9462 5.92739 13.6364 6.62812C14.3231 7.32542 14.7013 8.24584 14.7013 9.21992C14.7013 9.3746 14.5759 9.5 14.4212 9.5H6.57891C6.42423 9.5 6.29883 9.3746 6.29883 9.21992C6.29883 8.24584 6.67703 7.32542 7.36378 6.62812C8.0539 5.92739 8.9688 5.5415 9.93991 5.5415H11.0602Z'
				fill={fillColor || '#403F42'}
			/>
			<path
				d='M13.6364 6.62812L13.2801 6.97897L13.2801 6.97897L13.6364 6.62812ZM7.36378 6.62812L7.72002 6.97897L7.72002 6.97897L7.36378 6.62812ZM10.6987 0.5C9.31979 0.5 8.19873 1.62106 8.19873 3H9.19873C9.19873 2.17334 9.87207 1.5 10.6987 1.5V0.5ZM13.1987 3C13.1987 1.62106 12.0777 0.5 10.6987 0.5V1.5C11.5254 1.5 12.1987 2.17334 12.1987 3H13.1987ZM10.6987 5.5C12.0777 5.5 13.1987 4.37894 13.1987 3H12.1987C12.1987 3.82666 11.5254 4.5 10.6987 4.5V5.5ZM8.19873 3C8.19873 4.37894 9.31979 5.5 10.6987 5.5V4.5C9.87207 4.5 9.19873 3.82666 9.19873 3H8.19873ZM13.9926 6.27728C13.2099 5.48249 12.1672 5.0415 11.0602 5.0415V6.0415C11.8955 6.0415 12.6826 6.37229 13.2801 6.97897L13.9926 6.27728ZM15.2013 9.21992C15.2013 8.11385 14.771 7.06761 13.9926 6.27728L13.2801 6.97897C13.8752 7.58323 14.2013 8.37783 14.2013 9.21992H15.2013ZM14.4212 10C14.8521 10 15.2013 9.65074 15.2013 9.21992H14.2013C14.2013 9.09846 14.2998 9 14.4212 9V10ZM6.57891 10H14.4212V9H6.57891V10ZM5.79883 9.21992C5.79883 9.65074 6.14809 10 6.57891 10V9C6.70037 9 6.79883 9.09845 6.79883 9.21992H5.79883ZM7.00754 6.27728C6.22917 7.06761 5.79883 8.11385 5.79883 9.21992H6.79883C6.79883 8.37784 7.1249 7.58323 7.72002 6.97897L7.00754 6.27728ZM9.93991 5.0415C8.83297 5.0415 7.7903 5.48249 7.00754 6.27728L7.72002 6.97897C8.31751 6.37229 9.10464 6.0415 9.93991 6.0415V5.0415ZM11.0602 5.0415H9.93991V6.0415H11.0602V5.0415Z'
				fill={fillColor || '#403F42'}
			/>
			<rect x='10' y='9.5' width='1' height='1.5' fill={fillColor || '#403F42'} />
			<rect x='15.5' y='12' width='1' height='2' fill={fillColor || '#403F42'} />
			<rect width='1' height='2' transform='matrix(1 0 1.11209e-09 -1 4.5 14)' fill={fillColor || '#403F42'} />
			<path d='M10.5 11L15.5 11C16.0523 11 16.5 11.4477 16.5 12L10.5 12L10.5 11Z' fill={fillColor || '#403F42'} />
			<path d='M10.5 11L5.5 11C4.94772 11 4.5 11.4477 4.5 12L10.5 12L10.5 11Z' fill={fillColor || '#403F42'} />
			<g opacity='0.7'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M14 16C14 14.8972 14.8972 14 16 14C17.1028 14 18 14.8972 18 16C18 16.6347 17.7028 17.2013 17.2402 17.568C17.8961 17.6961 18.4997 18.0021 18.9861 18.4608C19.6399 19.0773 20 19.8911 20 20.7524C20 20.8891 19.8806 21 19.7333 21H12.2667C12.1194 21 12 20.8891 12 20.7524C12 19.8911 12.3601 19.0773 13.0139 18.4608C13.5003 18.0021 14.104 17.6961 14.7598 17.568C14.2972 17.2013 14 16.6347 14 16Z'
					fill={fillColor || '#403F42'}
				/>
				<path
					d='M17.2402 17.568L17.0539 17.3329L16.5434 17.7375L17.1827 17.8624L17.2402 17.568ZM18.9861 18.4608L18.7803 18.679L18.7803 18.679L18.9861 18.4608ZM13.0139 18.4608L13.2197 18.679L13.2197 18.679L13.0139 18.4608ZM14.7598 17.568L14.8173 17.8624L15.4566 17.7375L14.9461 17.3329L14.7598 17.568ZM16 13.7C14.7315 13.7 13.7 14.7315 13.7 16H14.3C14.3 15.0629 15.0629 14.3 16 14.3V13.7ZM18.3 16C18.3 14.7315 17.2685 13.7 16 13.7V14.3C16.9371 14.3 17.7 15.0629 17.7 16H18.3ZM17.4266 17.8031C17.9576 17.3822 18.3 16.7305 18.3 16H17.7C17.7 16.5389 17.448 17.0205 17.0539 17.3329L17.4266 17.8031ZM19.1919 18.2425C18.6635 17.7442 18.0081 17.4123 17.2977 17.2735L17.1827 17.8624C17.784 17.9798 18.3359 18.2601 18.7803 18.679L19.1919 18.2425ZM20.3 20.7524C20.3 19.8045 19.9029 18.9129 19.1919 18.2425L18.7803 18.679C19.3769 19.2417 19.7 19.9777 19.7 20.7524H20.3ZM19.7333 21.3C20.025 21.3 20.3 21.0753 20.3 20.7524H19.7C19.7 20.7304 19.7102 20.7147 19.7177 20.7076C19.725 20.7009 19.7312 20.7 19.7333 20.7V21.3ZM12.2667 21.3H19.7333V20.7H12.2667V21.3ZM11.7 20.7524C11.7 21.0753 11.975 21.3 12.2667 21.3V20.7C12.2688 20.7 12.275 20.7009 12.2823 20.7076C12.2898 20.7147 12.3 20.7304 12.3 20.7524H11.7ZM12.8081 18.2425C12.0971 18.9129 11.7 19.8045 11.7 20.7524H12.3C12.3 19.9777 12.6231 19.2417 13.2197 18.679L12.8081 18.2425ZM14.7023 17.2735C13.9919 17.4123 13.3365 17.7442 12.8081 18.2425L13.2197 18.679C13.6641 18.2601 14.216 17.9798 14.8173 17.8624L14.7023 17.2735ZM13.7 16C13.7 16.7305 14.0424 17.3822 14.5734 17.8031L14.9461 17.3329C14.552 17.0205 14.3 16.5389 14.3 16H13.7Z'
					fill={fillColor || '#403F42'}
				/>
			</g>
			<g opacity='0.7'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M3 16C3 14.8972 3.8972 14 5 14C6.1028 14 7 14.8972 7 16C7 16.6347 6.7028 17.2013 6.24024 17.568C6.89605 17.6961 7.49968 18.0021 7.98606 18.4608C8.63991 19.0773 9 19.8911 9 20.7524C9 20.8891 8.8806 21 8.73333 21H1.26667C1.1194 21 1 20.8891 1 20.7524C1 19.8911 1.36009 19.0773 2.01394 18.4608C2.50031 18.0021 3.10395 17.6961 3.75976 17.568C3.29721 17.2013 3 16.6347 3 16Z'
					fill={fillColor || '#403F42'}
				/>
				<path
					d='M6.24024 17.568L6.05389 17.3329L5.54337 17.7375L6.18274 17.8624L6.24024 17.568ZM7.98606 18.4608L7.78025 18.679L7.78025 18.679L7.98606 18.4608ZM2.01394 18.4608L2.21975 18.679L2.21975 18.679L2.01394 18.4608ZM3.75976 17.568L3.81726 17.8624L4.45663 17.7375L3.94611 17.3329L3.75976 17.568ZM5 13.7C3.73151 13.7 2.7 14.7315 2.7 16H3.3C3.3 15.0629 4.06289 14.3 5 14.3V13.7ZM7.3 16C7.3 14.7315 6.26849 13.7 5 13.7V14.3C5.93711 14.3 6.7 15.0629 6.7 16H7.3ZM6.4266 17.8031C6.9576 17.3822 7.3 16.7305 7.3 16H6.7C6.7 16.5389 6.44799 17.0205 6.05389 17.3329L6.4266 17.8031ZM8.19187 18.2425C7.66346 17.7442 7.00812 17.4123 6.29775 17.2735L6.18274 17.8624C6.78398 17.9798 7.33591 18.2601 7.78025 18.679L8.19187 18.2425ZM9.3 20.7524C9.3 19.8045 8.90289 18.9129 8.19187 18.2425L7.78025 18.679C8.37693 19.2417 8.7 19.9777 8.7 20.7524H9.3ZM8.73333 21.3C9.02499 21.3 9.3 21.0753 9.3 20.7524H8.7C8.7 20.7304 8.71016 20.7147 8.71775 20.7076C8.72496 20.7009 8.73121 20.7 8.73333 20.7V21.3ZM1.26667 21.3H8.73333V20.7H1.26667V21.3ZM0.7 20.7524C0.7 21.0753 0.975008 21.3 1.26667 21.3V20.7C1.26879 20.7 1.27504 20.7009 1.28225 20.7076C1.28984 20.7147 1.3 20.7304 1.3 20.7524H0.7ZM1.80813 18.2425C1.09711 18.9129 0.7 19.8045 0.7 20.7524H1.3C1.3 19.9777 1.62307 19.2417 2.21975 18.679L1.80813 18.2425ZM3.70225 17.2735C2.99188 17.4123 2.33654 17.7442 1.80813 18.2425L2.21975 18.679C2.66409 18.2601 3.21602 17.9798 3.81726 17.8624L3.70225 17.2735ZM2.7 16C2.7 16.7305 3.0424 17.3822 3.5734 17.8031L3.94611 17.3329C3.55201 17.0205 3.3 16.5389 3.3 16H2.7Z'
					fill={fillColor || '#403F42'}
				/>
			</g>
		</svg>
	);
}
export default Team;
