import React, { useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useFormContext, useWatch } from 'react-hook-form';

import Input from 'components/UI/ENGTInput/ENGTInput';

import { IObjProps } from 'shared/consts/types';
import { getErrorMessage } from 'shared/helpers';

import FormLabel from '../FormLabel/FormLabel';

export interface IFormInputProps {
	autoComplete?: string;
	clearable?: boolean;
	defaultValue?: any;
	errorCondition?: IObjProps;
	inputRef?: any;
	labelClassName?: IObjProps;
	noteCssOverrides?: IObjProps;
	label?: string;
	note?: string;
	name: string;
	tooltip?: string | React.ReactElement;
	[key: string]: any;
	disabled?: boolean;
	usageType?: 'primary' | 'node';
	width?: string;
	fontFamily?: string;
	type?: string;
	marginBottom?: string;
	isIndex?: boolean;
	modal?: boolean;
	overrides?: IObjProps;
	maxLength?: number;
	dataQa?: string;
	wrapperCss?: IObjProps;
	disableErrorMessage?: boolean;
	isRequired?: boolean;
	placeholder?: string;

	onChange?: Function;
}

export const FormInput = ({
	autoComplete = 'off',
	clearable,
	disabled = false,
	defaultValue,
	errorCondition = {},
	label,
	inputRef,
	labelClassName,
	name,
	note,
	noteCssOverrides,
	placeholder,
	tooltip,
	usageType = 'primary',
	width = '',
	fontFamily,
	marginBottom = '1rem',
	type = 'text',
	isIndex = true,
	onChange = (e: any) => {},
	overrides = {},
	markOptional,
	maxLength = 1000000,
	modal,
	dataQa,
	wrapperCss = {},
	isRequired = false,
	disableErrorMessage = false,
	...inputProps
}: IFormInputProps) => {
	const [css] = useStyletron();
	const { control, errors, register, setValue } = useFormContext();

	useEffect(() => {
		defaultValue && setValue && setValue(name, defaultValue);
	}, [defaultValue]);

	useWatch({
		control,
		name,
	});

	const errorMsg =
		!disabled && getErrorMessage(name, errors, isIndex !== undefined ? isIndex : true)
			? getErrorMessage(name, errors, isIndex !== undefined ? isIndex : true)
			: errors[name];

	return (
		<div className={css({ textAlign: 'left', marginBottom, ...wrapperCss })}>
			{label && (
				<FormLabel
					id={name}
					label={label}
					tooltip={tooltip}
					className={labelClassName}
					modal={modal || false}
					markOptional={markOptional}
					isRequired={isRequired}
				/>
			)}

			{note && <FormLabel id={note} label={note} className={noteCssOverrides} />}

			<Input
				type={type}
				width={width}
				id={name}
				inputRef={inputRef || register(errorCondition)}
				name={name}
				placeholder={placeholder}
				autoComplete={autoComplete}
				usage={usageType}
				error={disableErrorMessage ? {} : errorMsg}
				className={css({ marginBottom: '1rem' })}
				clearable={clearable}
				disabled={disabled || false}
				overrides={overrides}
				{...inputProps}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				maxLength={maxLength}
				dataQa={dataQa}
			/>
		</div>
	);
};
