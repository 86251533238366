import { useStyletron } from 'baseui';
import { Radio, RadioGroup, StatefulRadioGroup } from 'baseui/radio';
import merge from 'lodash/merge';
import { useSelector } from 'react-redux';

import { IObjProps } from 'shared/consts/types';
import { BOT_MODE } from 'shared/helpers';

import { RootState } from 'store/rootReducer';

export interface IENGTRadioButtons<T> {
	isDisabled?: boolean;
	name: string;
	nameAsNode?: React.ReactNode;
	value: T;
	checked?: boolean;
	dataQa?: string;
}

export interface IRadioProps<T> {
	align?: 'horizontal' | 'vertical';
	group: string;
	inputRef?: any;
	value: string | number;
	buttons: Array<IENGTRadioButtons<T>>;
	botMode?: string;
	type?: 'stateful' | 'stateless';
	usage?: 'primary' | 'modal';
	overrides?: IObjProps;
	minWidth?: string;
	onChange: (value: string) => any;
	dataQa?: string;
}

const defaults = {
	root: {},
	label: {},
	group: {},
};

const ENGTRadio = ({
	inputRef,
	value,
	group,
	buttons,
	botMode,
	align = 'vertical',
	type = 'stateful',
	usage = 'primary',
	minWidth = '48%',
	overrides,
	onChange,
	dataQa,
}: IRadioProps<string | number | boolean>) => {
	const [css, theme]: [any, any] = useStyletron();
	const isLiveDraftEntitlement = useSelector((state: RootState) => state.User.botAdmin.data.is_live_draft_enabled);

	overrides = merge({}, defaults, overrides);

	const overriddenRadioProps = (overrides: any, button: any) => ({
		Root: {
			style: {
				minWidth,
				fontSize: '0.875rem',
				lineHeight: '1.25rem',
				...overrides.root,
			},
		},
		Input: {
			props: {
				'data-qa': button.dataQa,
			},
		},
		Label: {
			style: ({ $disabled }: any) => ({
				fontSize: '0.875rem',
				lineHeight: '1.25rem',
				color: $disabled
					? theme.colors.contentStateDisabled
					: usage === 'modal'
						? theme.colors.modalTextColor
						: theme.colors.primaryA,
				...overrides.label,
			}),
			props: {
				'data-qa': dataQa,
			},
		},

		RadioMarkInner: {
			style: ({ $disabled, ...rest }: any) => ({
				height: $disabled ? '16px' : '10px',
				width: $disabled ? '16px' : '10px',
			}),
		},

		RadioMarkOuter: {
			style: ({ $disabled, $theme, $checked, ...res }: any) => ({
				boxShadow: !$disabled ? `0px 1px 2px rgba(64, 63, 66, 0.36)` : '0px',
				border: $disabled ? `1px solid ${theme.colors.contentStateDisabled}` : '',
				height: $disabled ? '16px' : '18px',
				width: $disabled ? '16px' : '18px',
			}),
		},
	});
	const renderRadioComponent = (overrides: IObjProps) => {
		if (type === 'stateful') {
			return (
				<StatefulRadioGroup
					align={align}
					name={group}
					initialState={{ value: value?.toString() }}
					onChange={(e) => {
						onChange(e.target.value);
					}}
					overrides={{
						RadioGroupRoot: {
							style: {
								...overrides.group,
							},
						},
					}}
				>
					{buttons.map((button) => (
						<Radio
							key={button.name}
							name={button.name}
							inputRef={inputRef}
							disabled={button.isDisabled || (botMode === BOT_MODE.LIVE && isLiveDraftEntitlement)}
							value={button.value.toString()}
							overrides={overriddenRadioProps(overrides, button)}
							onChange={(e) => {
								onChange(e.target.value);
							}}
						>
							{button.nameAsNode || button.name}
						</Radio>
					))}
				</StatefulRadioGroup>
			);
		}

		return (
			<RadioGroup
				align={align}
				name={group}
				value={value?.toString()}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				overrides={{
					RadioGroupRoot: {
						style: {
							position: 'relative',
							...overrides.group,
						},
					},
				}}
			>
				{buttons.map((button) => (
					<Radio
						key={button.name}
						name={button.name}
						inputRef={inputRef}
						disabled={button.isDisabled}
						checked={button.checked}
						value={button.value.toString()}
						overrides={overriddenRadioProps(overrides, button)}
						onChange={(e) => {
							onChange(e.target.value);
						}}
					>
						{button.nameAsNode || button.name}
					</Radio>
				))}
			</RadioGroup>
		);
	};

	return renderRadioComponent(overrides);
};

export default ENGTRadio;
