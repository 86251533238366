import { IObjProps, IPaginationStoreData } from 'shared/consts/types';

import { broadcastState, IBroadcastState, IFilterAndSort } from './state';

export const broadcastReducer = {
	setCampaigns: (state: IBroadcastState, action: { payload: any }) => {
		state.campaigns = action.payload;
	},

	resetBroadcastData: (state: IBroadcastState) => {
		Object.keys(broadcastState).forEach((key) => {
			(state as IObjProps)[key] = (broadcastState as IObjProps)[key];
		});
	},

	setMessageLimitTier: (state: IBroadcastState, action: { payload: string }) => {
		state.messageLimit = action.payload;
	},

	setBroadcastStatisticsData: (state: IBroadcastState, action: { payload: IObjProps }) => {
		state.statistics = action.payload;
	},

	setBroadcastStatisticsLoading: (state: IBroadcastState, action: { payload: boolean }) => {
		state.statistics.isLoading = action.payload;
	},

	setBroadcastStatisticsError: (state: IBroadcastState, action: { payload: boolean }) => {
		state.statistics.isError = action.payload;
	},

	setPublishedBroadcastData: (
		state: IBroadcastState,
		action: { payload: IPaginationStoreData<Array<IObjProps>> & IFilterAndSort }
	) => {
		state.published = action.payload;
	},

	modifyPublishedBroadcastData: (state: IBroadcastState, action: { payload: Array<IObjProps> }) => {
		state.published.data = action.payload;
	},

	setPublishedBroadcastSort: (state: IBroadcastState, action: { payload: string }) => {
		state.published.sort = action.payload;
	},

	setPublishedBroadcastFilter: (state: IBroadcastState, action: { payload: IFilterAndSort }) => {
		state.published.filters = action.payload.filters;
		state.published.sort = action.payload.sort;
	},

	setBroadcastDataLoading: (state: IBroadcastState, action: { payload: boolean }) => {
		state.published.isLoading = action.payload;
	},

	setBroadcastDataError: (state: IBroadcastState, action: { payload: IObjProps }) => {
		state.published.isError = action.payload.isError;
		state.published.isLoading = action.payload.isLoading;
	},

	setScheduledBroadcastData: (
		state: IBroadcastState,
		action: { payload: IPaginationStoreData<Array<IObjProps>> & IFilterAndSort }
	) => {
		state.scheduled = action.payload;
	},

	setScheduledDataLoading: (state: IBroadcastState, action: { payload: boolean }) => {
		state.scheduled.isLoading = action.payload;
	},

	setScheduledDataError: (state: IBroadcastState, action: { payload: IObjProps }) => {
		state.scheduled.isError = action.payload.isError;
		state.scheduled.isLoading = action.payload.isLoading;
	},

	setDraftBroadcastData: (
		state: IBroadcastState,
		action: { payload: IPaginationStoreData<Array<IObjProps>> & IFilterAndSort }
	) => {
		state.draft = action.payload;
	},

	setDraftDataLoading: (state: IBroadcastState, action: { payload: boolean }) => {
		state.draft.isLoading = action.payload;
	},

	setDraftDataError: (state: IBroadcastState, action: { payload: IObjProps }) => {
		state.draft.isError = action.payload.isError;
		state.draft.isLoading = action.payload.isLoading;
	},

	setJourneyTemplates: (state: IBroadcastState, action: { payload: IObjProps }) => {
		state.messageTemplates = action.payload.templates;
	},
};
