import { DOWNLOAD_ERROR_STATUS } from 'shared/consts/consts';
import { getFilterKeys } from 'shared/consts/localStorageConsts';
import { IObjProps } from 'shared/consts/types';
import { downloadCSVFile, isValidNestedResponseObj, isValidResponseObject } from 'shared/helpers';

import { API } from 'store/Broadcast/api';
import { API as CampaignsAPI } from 'store/Campaign/api';
import { DownloadAPI } from 'store/Download/api';
import { RootState } from 'store/rootReducer';

import { IFilterAndSort } from './state';
import { actions } from './store';

const {
	setCampaigns,
	resetBroadcastData,
	setMessageLimitTier,
	setBroadcastStatisticsData,
	setBroadcastStatisticsLoading,
	setBroadcastStatisticsError,
	setPublishedBroadcastData,
	modifyPublishedBroadcastData,
	setPublishedBroadcastSort,
	setPublishedBroadcastFilter,
	setBroadcastDataLoading,
	setBroadcastDataError,
	setScheduledBroadcastData,
	setScheduledDataLoading,
	setScheduledDataError,
	setDraftBroadcastData,
	setDraftDataLoading,
	setDraftDataError,
	setJourneyTemplates,
} = actions;

export const getCampaignsAction = (params: IObjProps) => (dispatch: any) => {
	CampaignsAPI.getCampaigns(params).then((resp: any) => {
		if (isValidNestedResponseObj(resp)) {
			dispatch(setCampaigns(resp.data.response_obj.responseObject.content));
		}
	});
};

export const resetBroadcstDataAction = () => (dispatch: any) => {
	dispatch(resetBroadcastData());
};

export const getMessageLimitTier = (messageLimit: string) => (dispatch: any) => {
	dispatch(setMessageLimitTier(messageLimit));
};

// export const getBroadcastColumnAction = (data: IObjProps, sort: String, defaultColumns: IObjProps) => (dispatch: any) => {
// 	dispatch(setBroadcastDataLoading(true));
// 	API.getBroadcastColumns().then(
// 		(response: any) => {
// 			if (response.data) {
// 				if (!Object.keys(defaultColumns).length) {
// 					const payload = {
// 						defaultColumns: response.data.default_columns,
// 						columns: response.data.columns
// 					}
// 					dispatch(setBroadcastColumns(payload))
// 				}

// 				data.columns = Object.keys(defaultColumns).length ? defaultColumns : response.data.default_columns;

// 				dispatch(getPublishedBroadcastAction(data, sort))
// 			} else {
// dispatch(setBroadcastDataError({
// 	isError: true,
// 	isLoading: false
// }));
// 			}
// 		},
// 		() => {
// dispatch(setBroadcastDataError({
// 	isError: true,
// 	isLoading: false
// }));
// 		}
// 	);
// };

export const getPublishedBroadcastAction = (data: IObjProps, sort: string) => (dispatch: any) => {
	dispatch(setBroadcastDataLoading(true));
	API.broadcastHistory(data).then(
		(response: any) => {
			if (isValidResponseObject(response)) {
				const result = response.data.responseObject;

				let payload: any = getPaginationPayload(result, data, sort);

				if (result?.content.length) {
					payload = {
						...payload,
						data: result.content,
					};
					dispatch(setPublishedBroadcastData(payload));
				} else {
					dispatch(setPublishedBroadcastData(payload));
				}
			} else {
				onError(dispatch);
			}
		},
		() => {
			onError(dispatch);
		}
	);
};

export const getBroadcastStatisticsAction = () => (dispatch: any) => {
	dispatch(setBroadcastStatisticsLoading(true));
	API.getBroadCastHighlights().then(
		(response: any) => {
			if (response?.data?.responseObject) {
				const payload = {
					data: response?.data?.responseObject,
					isError: false,
					isLoading: false,
				};
				dispatch(setBroadcastStatisticsData(payload));
			} else {
				onStatisticsError(dispatch);
			}
		},
		() => {
			onStatisticsError(dispatch);
		}
	);
};

export const setBroadcastDataSortAction = (data: string) => (dispatch: any) => {
	dispatch(setPublishedBroadcastSort(data));
};

export const getJourneyTemplates = () => (dispatch: any) =>
	API.getJourneyTemplates().then((resp: IObjProps) => {
		if (isValidResponseObject(resp)) {
			dispatch(setJourneyTemplates(resp.data.responseObject));
		}
	});

export const setBroadcastDataFilterAction = (data: IFilterAndSort) => (dispatch: any) => {
	dispatch(broadcastFilterActions.setFiltersToLS(data));
	dispatch(setPublishedBroadcastFilter(data));
};

export const modifyBroadcastDataAction =
	(broadcastId: string, data: IObjProps) => (dispatch: any, getState: () => RootState) => {
		const modififedBroadcast = getState().Broadcast.published.data.map((broadcast) =>
			broadcast.broadcastId !== broadcastId ? broadcast : data
		);

		dispatch(modifyPublishedBroadcastData(modififedBroadcast));
	};

export const getScheduledBroadcastAction = (data: IObjProps) => (dispatch: any) => {
	dispatch(setScheduledDataLoading(true));
	API.broadcastHistory(data).then(
		(response: any) => {
			if (isValidResponseObject(response)) {
				const result = response.data.responseObject;

				const payload: any = getPaginationPayload(result);

				dispatch(setScheduledBroadcastData(payload));
			} else {
				dispatch(
					setScheduledDataError({
						isError: true,
						isLoading: false,
					})
				);
			}
		},
		() => {
			dispatch(
				setScheduledDataError({
					isError: true,
					isLoading: false,
				})
			);
		}
	);
};

export const getDraftBroadcastAction = (data: IObjProps) => (dispatch: any) => {
	dispatch(setDraftDataLoading(true));
	API.broadcastHistory(data).then(
		(response: any) => {
			if (isValidResponseObject(response)) {
				const result = response.data.responseObject;

				const payload: any = getPaginationPayload(result);

				dispatch(setDraftBroadcastData(payload));
			} else {
				dispatch(
					setDraftDataError({
						isError: true,
						isLoading: false,
					})
				);
			}
		},
		() => {
			dispatch(
				setDraftDataError({
					isError: true,
					isLoading: false,
				})
			);
		}
	);
};

export const broadcastFilterActions = {
	setFiltersToLS: (filters: IObjProps) => (dispatch: any, getState: () => RootState) => {
		`${getState().Bot.data.bot_ref}` &&
			`${getState().User.botAdmin.data.uid}` &&
			localStorage.setItem(getFilterKeys(getState), JSON.stringify({ ...filters }));
	},
	getFiltersFromLs: () => (dispatch: any, getState: () => RootState) =>
		`${getState().Bot.data.bot_ref}` &&
		`${getState().User.botAdmin.data.uid}` &&
		JSON.parse(
			localStorage.getItem(getFilterKeys(getState)) ||
				`{
						"filters": {
							"startDate": "",
							"endDate": "",
							"channels": ""
						},
						"sort": ""
					}`
		),
};

export const broadcastAnalyticsActions = {
	setFacetsSortInLs: (filters: IObjProps) => (dispatch: any, getState: () => RootState) => {
		`${getState().Bot.data.bot_ref}` &&
			`${getState().User.botAdmin.data.uid}` &&
			localStorage.setItem(
				`broadcast_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`,
				JSON.stringify({ ...filters })
			);
	},
	getFacetsSortFromLs: () => (dispatch: any, getState: () => RootState) =>
		`${getState().Bot.data.bot_ref}` &&
		`${getState().User.botAdmin.data.uid}` &&
		JSON.parse(
			localStorage.getItem(`broadcast_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`) ||
				'{}'
		),
	setFacetsSortInLsForCampaigns: (filters: IObjProps) => (dispatch: any, getState: () => RootState) => {
		localStorage.setItem(
			`campaign_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`,
			JSON.stringify({ ...filters })
		);
	},
	getFacetsSortFromLsForCampaigns: () => (dispatch: any, getState: () => RootState) =>
		JSON.parse(
			localStorage.getItem(`campaign_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`) || '{}'
		),
};

// not used for now
export const pollForExportBroadcastListStatusAction =
	(
		// broadcastId: string,
		botRef: number,
		rowId: number,
		fileName: string
	) =>
	(dispatch: any) => {
		let count = 1;
		let isDownloadTriggered = false;
		const params: IObjProps = {
			targetApi: 'getUsageHistoryExportStatus',
			rowId,
			// replaceable_params: {
			// 	broadcastId: broadcastId,
			// },
		};
		const interval = setInterval(() => {
			if (count > 10) {
				clearInterval(interval);
			}

			DownloadAPI.downloadBroadcastExportStatus(params).then((response: any) => {
				if (isValidResponseObject(response) && response.data.responseObject === 'SUCCESS') {
					clearInterval(interval);
					if (!isDownloadTriggered) {
						isDownloadTriggered = true;
						dispatch(downloadBroadcastListCsvAction(botRef, rowId, fileName));
					}
				} else if (DOWNLOAD_ERROR_STATUS.indexOf(response.data.responseObject) > -1) {
					clearInterval(interval);
				}
				count++;
			});
		}, 5000);
	};

// not used for now
export const downloadBroadcastListCsvAction =
	(
		// broadcastId: string,
		botRef: number,
		rowId: number,
		fileName: string
	) =>
	() => {
		const params: IObjProps = {
			row_key: rowId,
			// broadcast_id: broadcastId,
			bot_ref: botRef,
		};
		DownloadAPI.downloadDataCSV(params).then((response: any) => {
			const csv = response.data;
			if (csv) {
				downloadCSVFile(csv, fileName);
			}
		});
	};

const getPaginationPayload = (result: IObjProps, data?: IObjProps, sort?: string) =>
	sort && data
		? {
				data: [],
				filters: {
					startDate: data.startDate,
					endDate: data.endDate,
					channels: data.channels,
				},
				pagination: {
					size: result.size,
					page: result.pageable.pageNumber + 1,
					totalPages: result.totalPages,
					totalElements: result.totalElements,
				},
				sort,
				isError: false,
				isLoading: false,
			}
		: {
				data: result?.content,
				pagination: {
					size: result.size,
					page: result.pageable.pageNumber + 1,
					totalPages: result.totalPages,
					totalElements: result.totalElements,
				},
				isError: false,
				isLoading: false,
			};

const onError = (dispatch: any) => {
	dispatch(
		setBroadcastDataError({
			isError: true,
			isLoading: false,
		})
	);
};

const onStatisticsError = (dispatch: any) => {
	dispatch(setBroadcastStatisticsLoading(false));
	dispatch(setBroadcastStatisticsError(true));
};
