import { toaster } from 'baseui/toast';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { BROADCAST_TYPE } from 'shared/enum';
import { TemplateStatus } from 'shared/enum/templatesEnum';

import { getBotListAction } from 'store/App/BotList';
import { addNotificationAction } from 'store/App/Notifications';
import { playSoundNotification } from 'store/App/Preferences';
import { shopifyActions } from 'store/App/Shopify';
import { modalActions } from 'store/Modal';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';
import { TwoColumnRouteLinks } from 'router/links/TwoColumnRouteLinks';

import { i18nHelper } from 'i18nHelper';

import { removeSelectedUserFromListAction, updateSelectedUserInUserListAction } from '../../Message/actions';
import {
	COPY_BOT_STATUS,
	COPY_BOT_SUB_TYPE,
	generalNotificationTypes,
	notificationActionTitles,
	SOCKET_EVENTS,
	socketEventEmitter,
	TAGGING_OPERATION,
	translation,
} from '../consts';
import { getTaggingRoute, getUserFromList } from '../helper';

const { openInfoModalAction } = modalActions;

const { templateAccountBotCreation, copyBot } = generalNotificationTypes;

export const generalNotificationListener = (socket: any) => (dispatch: any, getState: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.GENERAL_NOTIFICATION);

	socket.on(SOCKET_EVENTS.GENERAL_NOTIFICATION, (response: any) => {
		const data: any = JSON.parse(response);
		const advancedLiveChatFeatures: any = getState().Bot.configData.enable_livechat_advanced_features;
		const { active_module: activeModule, is_simplified_one_view_enabled: isSimplifiedOneViewEnabled } =
			getState().User.botAdmin.data;
		const { selectedUser } = getState().Message;
		const notifySettings: any = getState().Preferences.notifications;
		const userListData = getState().Message.userList.data;
		const { isSlaNonAdherenceNotificationEnabled } = notifySettings;
		const {
			active_state,
			agent_name,
			conversation_id,
			message,
			sla_type,
			tagging_operation,
			type,
			user_id,
			user_name,
			bot_ref,
			templateName,
			user_in_video_conversation: userInVideoConversation,
		} = data;

		if (type === generalNotificationTypes.categoryDeletion) {
			dispatch(playSoundNotification({ sound: 'request' }));
			dispatch(
				addNotificationAction({
					title: notificationActionTitles.categoryDeletion,
					description: message,
					url: ThreeColumnRouteLinks.configureLcSetupLink,
					type: SOCKET_EVENTS.GENERAL_NOTIFICATION,
					timestamp: new Date().toISOString(),
					userId: user_id,
					notificationId: `category_${new Date().toISOString()}`,
					notificationActionLabel: i18nHelper('common:notificationsLabels.categoryDelete'),
				})
			);
		} else if (type === generalNotificationTypes.taggedAgents) {
			if (tagging_operation === TAGGING_OPERATION.ADD) {
				setTimeout(() => {
					dispatch(playSoundNotification({ sound: 'request' }));
					dispatch(
						addNotificationAction({
							title: notificationActionTitles.conversationUpdate,
							description: `${agent_name} has tagged you in a conversation within`,
							url: `${
								isSimplifiedOneViewEnabled
									? TwoColumnRouteLinks.simplifiedMessages
									: `/messages/${getTaggingRoute(active_state)}/`
							}${conversation_id}?open=notes`,
							type: SOCKET_EVENTS.GENERAL_NOTIFICATION,
							timestamp: new Date().toISOString(),
							conversationId: conversation_id,
							userId: user_id,
							bot_ref,
						})
					);
				}, 10000);
			} else if (tagging_operation === TAGGING_OPERATION.REMOVE) {
				if (data && selectedUser && conversation_id === selectedUser.conversation_id) {
					dispatch(removeSelectedUserFromListAction(selectedUser));
					socketEventEmitter.emit(SOCKET_EVENTS.GENERAL_NOTIFICATION, { ...data });

					const heading = translation('generalNotification.taggingOperationRemove.heading');
					const description = translation('generalNotification.taggingOperationRemove.description');
					dispatch(openInfoModalAction(heading, description));
				}
			}
		} else if (
			type === generalNotificationTypes.slaNotification &&
			isSlaNonAdherenceNotificationEnabled &&
			advancedLiveChatFeatures
		) {
			const slaType = sla_type === 'SLA_FIRST_RESPONSE' ? 'first response' : 'resolution';

			dispatch(playSoundNotification({ sound: 'request' }));
			dispatch(
				addNotificationAction({
					title: notificationActionTitles.slaNonAdherence,
					description: `Conversation with ${user_name} is past SLA for ${slaType}`,
					url: `${
						isSimplifiedOneViewEnabled ? TwoColumnRouteLinks.simplifiedMessages : '/messages/all/'
					}${user_id}`,
					type: SOCKET_EVENTS.GENERAL_NOTIFICATION,
					timestamp: new Date().toISOString(),
					userId: user_id,
					conversationId: user_id,
					bot_ref,
				})
			);
		} else if (type === copyBot || type === templateAccountBotCreation) {
			const isTemplateAccountBotCreationFlow = type === templateAccountBotCreation;
			const title = isTemplateAccountBotCreationFlow
				? i18nHelper('common:notificationsLabels.templateBotStatus')
				: i18nHelper('common:notificationsLabels.copyBotStatus');
			const botFlow = isTemplateAccountBotCreationFlow
				? i18nHelper('common:notificationsLabels.botCreation')
				: i18nHelper('common:notificationsLabels.copyBot');

			if (data.sub_type === COPY_BOT_SUB_TYPE.IMPORT && data.status === COPY_BOT_STATUS.STARTED) {
				dispatch(getBotListAction(activeModule));
			} else if (data.sub_type === COPY_BOT_SUB_TYPE.IMPORT && data.status === COPY_BOT_STATUS.COMPLETED) {
				dispatch(getBotListAction(activeModule));
				const description = isTemplateAccountBotCreationFlow
					? i18nHelper('common:notificationsLabels.templateBotCompleted')
					: i18nHelper('common:notificationsLabels.copyBotCompleted');
				dispatch(
					addNotificationAction({
						title,
						description,
						url: '',
						type: SOCKET_EVENTS.GENERAL_NOTIFICATION,
						timestamp: new Date().toISOString(),
						userId: bot_ref,
						conversationId: bot_ref,
						bot_ref,
						notificationActionLabel: ' ',
					})
				);
			} else if (data.sub_type === COPY_BOT_SUB_TYPE.IMPORT && data.status === COPY_BOT_STATUS.FAILED) {
				dispatch(getBotListAction(activeModule));
				const description = data.workflow
					? `${botFlow} ${i18nHelper('common:notificationsLabels.importStageFailed')} ${i18nHelper(
							'common:condition.in'
						)} ${data.workflow} ${i18nHelper('common:notificationsLabels.workflow')}`
					: `${botFlow} ${i18nHelper('common:notificationsLabels.importStageFailed')}`;
				dispatch(
					addNotificationAction({
						title,
						description,
						url: '',
						type: SOCKET_EVENTS.GENERAL_NOTIFICATION,
						timestamp: new Date().toISOString(),
						userId: bot_ref,
						conversationId: bot_ref,
						bot_ref,
						notificationActionLabel: ' ',
					})
				);
			} else if (data.sub_type === COPY_BOT_SUB_TYPE.EXPORT && data.status === COPY_BOT_STATUS.FAILED) {
				const description = data.workflow
					? `${botFlow} ${i18nHelper('common:notificationsLabels.exportStageFailed')} ${i18nHelper(
							'common:condition.in'
						)} ${data.workflow} ${i18nHelper('common:notificationsLabels.workflow')}`
					: `${botFlow} ${i18nHelper('common:notificationsLabels.importStageFailed')}`;
				dispatch(
					addNotificationAction({
						title,
						description,
						url: '',
						type: SOCKET_EVENTS.GENERAL_NOTIFICATION,
						timestamp: new Date().toISOString(),
						userId: bot_ref,
						conversationId: bot_ref,
						bot_ref,
						notificationActionLabel: ' ',
					})
				);
			}
		} else if (type === generalNotificationTypes.importExport) {
			dispatch(getBotListAction(activeModule));
			dispatch(
				addNotificationAction({
					title: i18nHelper('common:notificationsLabels.exportBotStatus'),
					description:
						data.workflow && data.status === COPY_BOT_STATUS.FAILED
							? `Export Bot Failed in ${data.workflow} workflow`
							: `Export Bot ${data.status.toLowerCase()}`,
					url: '',
					type: SOCKET_EVENTS.GENERAL_NOTIFICATION,
					timestamp: new Date().toISOString(),
					userId: bot_ref,
					conversationId: bot_ref,
					bot_ref,
					notificationActionLabel: ' ',
				})
			);
		} else if (type === generalNotificationTypes.documentStatusChange) {
			// TO-BE-ADDED
		} else if (type === generalNotificationTypes.userVideoStatusNotification) {
			const users = Array.isArray(userListData) ? JSON.parse(JSON.stringify(userListData)) : [];
			const user = getUserFromList(users, conversation_id);
			user.in_video_conversation = userInVideoConversation;
			dispatch(updateSelectedUserInUserListAction(user));
		} else if (type === generalNotificationTypes.botSetupStatus) {
			dispatch(shopifyActions.updateOnboardingStatusAction(data));
		} else if (type === generalNotificationTypes.storeBotSetupStatus) {
			const commonNotificationBody = {
				url: '',
				type: SOCKET_EVENTS.GENERAL_NOTIFICATION,
				timestamp: new Date().toISOString(),
				userId: bot_ref,
				conversationId: bot_ref,
				bot_ref,
				notificationActionLabel: ' ',
			};

			if (data.status === COPY_BOT_STATUS.COMPLETED) {
				dispatch(getBotListAction(activeModule));
				dispatch(
					addNotificationAction({
						title: i18nHelper('common:notificationsLabels.botCreationStatus'),
						description: i18nHelper('common:notificationsLabels.botCreationCompleted'),
						...commonNotificationBody,
					})
				);
			} else if (data.status === COPY_BOT_STATUS.FAILED) {
				dispatch(getBotListAction(activeModule));
				dispatch(
					addNotificationAction({
						title: i18nHelper('common:notificationsLabels.botCreationStatus'),
						description: i18nHelper('common:notificationsLabels.botCreationFailed'),
						...commonNotificationBody,
					})
				);
			}
		} else if (type === generalNotificationTypes.templateStatus) {
			let packet = {};
			if ((data.status || '') === TemplateStatus.APPROVED) {
				packet = {
					title: i18nHelper('common:notificationsLabels.templateCreationApproved'),
					description: i18nHelper('common:notificationMessage.templateAccepted', {
						templateName,
					}),
					url: `${ThreeColumnRouteLinks.createBroadcast}?mode=${
						BROADCAST_TYPE.TEMPLATE
					}&payload=${encodeURIComponent(JSON.stringify({ templateId: templateName }))}&path=published`,
					type: SOCKET_EVENTS.GENERAL_NOTIFICATION,
					timestamp: new Date().toISOString(),
					userId: user_id,
					conversationId: bot_ref,
					bot_ref,
					notificationId: `template_${new Date().toISOString()}`,
					notificationActionLabel: i18nHelper('common:goTo', {
						component: i18nHelper('common:navigation.broadcast'),
					}),
				};
			} else {
				packet = {
					title: i18nHelper('common:notificationsLabels.templateCreationFailed'),
					description: i18nHelper('common:notificationMessage.templateRejected', {
						templateName,
					}),
					url: ThreeColumnRouteLinks.broadcastMessageTemplates,
					type: SOCKET_EVENTS.GENERAL_NOTIFICATION,
					timestamp: new Date().toISOString(),
					userId: user_id,
					conversationId: bot_ref,
					bot_ref,
					notificationId: `template_${new Date().toISOString()}`,
					notificationActionLabel: i18nHelper('common:goTo', {
						component: i18nHelper('common:locations.navigation.messageTemplates'),
					}),
				};
			}

			dispatch(playSoundNotification({ sound: 'request' }));
			dispatch(addNotificationAction(packet));
		}
	});
};

export const notifyVideoStatusListener = (socket: any) => () => {
	socket.removeAllListeners(SOCKET_EVENTS.VIDEO_STATUS_UPDATE);

	socket.on(SOCKET_EVENTS.VIDEO_STATUS_UPDATE, (d: any) => {
		const data = JSON.parse(d);
		const userId = Object.keys(data)[0];
		const message = data[userId].message + i18nHelper(`components:videoNotificationsTexts.rejectVideoCall`);
		const title = i18nHelper(`components:videoNotificationsTexts.participantReject`);
		toaster.positive(<ENGTToasterContainer title={title} description={message} />, {});
	});
};
