import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import { Paragraph1 } from 'baseui/typography';
import { useTranslation } from 'react-i18next';

import useIsAdminModeHook from '../hooks/useIsAdminModeHook';

import ChangeAccountModal from './ChangeAccountModal';
import Divider from './Divider';

const SwitchAccount = () => {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['pages', 'common']);

	const [isAccChangeModalOpen, openAccountChangeModal] = useState(false);

	const { isAdminMode } = useIsAdminModeHook();

	if (isAdminMode) {
		return (
			<>
				<Paragraph1 marginTop='0' marginBottom='0'>
					<div
						className={css({
							textDecoration: 'none',
							color: theme.colors.primaryA,
							fontSize: '0.875rem !important',
							lineHeight: '1.25rem !important',
							letterSpacing: '0.0015em',
							fontWeight: '500',
							cursor: 'pointer',
						})}
						onClick={() => {
							openAccountChangeModal(true);
						}}
					>
						{t('common:switchAccount')}
					</div>
				</Paragraph1>
				<Divider />
				{isAccChangeModalOpen && <ChangeAccountModal toggle={openAccountChangeModal} />}
			</>
		);
	}

	return <></>;
};

export default SwitchAccount;
