import { Fragment, useLayoutEffect, useRef } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { BEHAVIOR, Cell, Grid } from 'baseui/layout-grid';
import merge from 'lodash/merge';
import { useTranslation } from 'react-i18next';

import ENGTPagination from 'components/UI/ENGTPagination/ENGTPagination';
import SkeletonSquareView from 'components/UI/ENGTSkeleton/ENGTSkeletonSquareView';
import ENGTTableRow from 'components/UI/ENGTTable/ENGTTableRow';
import { ITableRowAction } from 'components/UI/ENGTTable/ENGTTableRowActions';
import { default as ENGTTooltip, default as Popover } from 'components/UI/ENGTTooltip/ENGTTooltip';

import { ROW_ACTION_SPAN_SIZE } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { getEllipsisedText } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import TooltipIcon from 'shared/icons/node/Tooltip.svg';

import EmptyDataTable from './EmptyDataTable';
import ENGTTableAccordionRow from './ENGTTableAccordionRow';

export interface ITableColumnProps {
	id: string;
	name: string;
	span?: number;
	overrides?: any;
	tooltip?: string | React.ReactElement;
	processor?: (e: any, f?: any) => any;
}

enum PAGINATION_TYPE {
	FOOTER = 'footer',
	SCROLL = 'scroll',
}

export interface IENGTTableProps {
	botMode?: string;
	columns: Array<ITableColumnProps>;
	emptyDataView?: any;
	loader?: boolean;
	showHeader?: boolean;
	loaderBlockHeight?: string;
	overrides?: any;
	pagination?: IObjProps;
	paginationType?: PAGINATION_TYPE[keyof PAGINATION_TYPE];
	rows: Array<IObjProps>;
	rowActions?: Array<ITableRowAction> | { (rowData: any): Array<ITableRowAction> };
	boldWords?: Array<string>;
	customColumns?: boolean;
	customSizeOptions?: Array<number>;
	rowToBeBold?: string;
	customHeaderHeight?: string;
	accordionRow?: boolean;
}

const defaults = {
	table: {
		overflow: 'auto',
		borderBlockWidth: '1px',
	},
};

const squareOverrideCss = {
	height: '2.5rem',
	width: '100%',
	override: {
		Root: {
			style: {
				marginBottom: '0.25rem',
				opacity: 0.09,
			},
		},
	},
};

const emptySkeletonArray = Array.from({ length: 10 }); // Creates an array with 10 undefined elements

const ENGTTable = ({
	botMode = '',
	columns,
	emptyDataView,
	loader = false,
	showHeader = true,
	overrides = {},
	pagination = {},
	paginationType = PAGINATION_TYPE.SCROLL,
	rows,
	rowActions = [],
	customColumns = false,
	boldWords,
	customSizeOptions = [],
	rowToBeBold,
	customHeaderHeight = '',
	accordionRow = false,
}: IENGTTableProps) => {
	const [css, theme]: any = useStyletron();

	const { t } = useTranslation(['common', 'components']);

	const footerRef: any = useRef(null);

	const tableWrapper: any = useRef(null);

	const { pagination: paginationState, changePageNumber, changePaginationSize } = pagination; // FOOTER-TYPE

	const { totalPages, page, size, totalElements } = paginationState || {};

	const { isSmallDesktopScreen } = useResponsiveSize();

	overrides = merge({}, defaults, overrides);

	emptyDataView = emptyDataView || t('components:table.data.empty');

	const wrapperCss = {
		...(isSmallDesktopScreen && { overflowX: 'auto' }),
		marginLeft: '-0.5rem',
		marginRight: '-0.5rem',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	};

	useLayoutEffect(() => {
		if (tableWrapper.current) {
			tableWrapper.current.scrollTop = 0;
		}
	}, [page, size]);

	const autoAdjustColumnSpan = () => {
		let toBeAutoAdjusted = 12;
		let fixedColumnCount = 0;

		if (rowActions && (rowActions.length > 0 || typeof rowActions === 'function')) {
			toBeAutoAdjusted -= ROW_ACTION_SPAN_SIZE;
		}

		for (const column of columns) {
			if (column.span) {
				fixedColumnCount++;
				toBeAutoAdjusted -= column.span;
			}
		}
		const adjustedColumnSpan = toBeAutoAdjusted / (columns.length - fixedColumnCount);

		columns.map((column) => (column.span = column.span || adjustedColumnSpan));
	};

	const renderTableHeader = (columns: Array<any>) => (
		<Block
			key='table-head'
			className={css({
				width: '100%',
				position: 'sticky',
				top: 0,
				zIndex: theme.zIndex100,
				background: theme.colors.backgroundPrimary,
				...overrides.Block,
			})}
		>
			<Grid
				behavior={BEHAVIOR.fluid}
				overrides={{
					Grid: {
						style: {
							display: 'flex',
							alignItems: 'center',
							height: loader ? '4rem' : customHeaderHeight || '2.5rem',
							fontWeight: 500,
							fontStyle: 'normal',
							fontSize: '0.75rem',
							textAlign: 'left',
							lineHeight: '1rem',
							borderBottomStyle: 'solid',
							color: `${theme.colors.accent50}`,
							borderBlockWidth: overrides.table.borderBlockWidth,
							borderBottomColor: `${theme.colors.backgroundSecondary}`,
							width: '100%',
							flexWrap: 'nowrap',
							paddingLeft: '0.5rem',
							paddingRight: '0.5rem',
							...overrides.Grid,
						},
					},
				}}
			>
				{columns.map((column, idx) => {
					const spannablePart: any =
						column?.hasOwnProperty('span') && (column.span as number) >= 0 ? column.span : 1;
					const width = `${(spannablePart / 12) * 100}%`;

					return (
						<Cell
							key={`table-header-${idx}`}
							overrides={{
								Cell: {
									style: () => ({
										fontWeight: '500 !important',
										paddingTop: '0.375rem',
										paddingBottom: '0.375rem',
										paddingRight: '0.5rem !important',
										width,
										minWidth: width,
										maxWidth: width,
										...column?.overrides?.header,
										...column.overrides,
									}),
								},
							}}
						>
							{loader ? (
								<SkeletonSquareView
									height={squareOverrideCss.height}
									width={squareOverrideCss.width}
									override={squareOverrideCss.override}
								/>
							) : customColumns && column?.name.length > 20 ? (
								<ENGTTooltip content={column.name} ignoreBoundary={false}>
									{getEllipsisedText(column.name, 20)}
								</ENGTTooltip>
							) : (
								column?.name
							)}
							{column?.tooltip && !loader && (
								<Popover content={column?.tooltip} ignoreBoundary>
									<img
										alt='tooltip'
										src={TooltipIcon}
										className={css({ cursor: 'pointer', marginLeft: '0.2rem' })}
									/>
								</Popover>
							)}
						</Cell>
					);
				})}
			</Grid>
		</Block>
	);

	const renderTableContent = () => (
		<div
			className={css({
				width: '100%',
				height: 'inherit',
				flexGrow: 1,
				borderSpacing: '0px',
				display: 'flex',
				flexDirection: 'column',
				overflow: rows.length ? 'auto' : 'hidden',
				...overrides.table,
				...(isSmallDesktopScreen &&
					rows.length > 0 && {
						minWidth: 'max-content',
					}),
			})}
			ref={tableWrapper}
			key='table-data-main'
		>
			{((rows.length > 0 && showHeader) || loader) && renderTableHeader(columns)}
			<div
				className={css({
					flexGrow: rows.length === 0 ? 1 : 0,
					overflow: rowToBeBold ? '' : 'auto',
					...(overrides?.rowWrapper || {}),
				})}
			>
				{loader ? (
					emptySkeletonArray.map((row, idx) => (
						<ENGTTableRow
							key={`table-row-wrap-${idx}`}
							id={`table-row-${idx}`}
							row={row}
							columns={columns}
							isLastRow={rows.length === idx + 1}
							loader
						/>
					))
				) : rows.length > 0 ? (
					accordionRow ? (
						<ENGTTableAccordionRow
							rows={rows}
							columns={columns}
							botMode={botMode}
							overrides={overrides.row}
							boldWords={boldWords || []}
							rowToBeBold={rowToBeBold}
							rowActions={rowActions}
						/>
					) : (
						rows.map((row, idx) => (
							<ENGTTableRow
								key={`table-row-wrap-${idx}`}
								id={`table-row-${idx}`}
								row={row}
								columns={columns}
								botMode={botMode}
								actions={typeof rowActions === 'function' ? rowActions(row) : rowActions}
								overrides={overrides.row}
								isLastRow={rows.length === idx + 1}
								boldWords={boldWords || []}
								rowToBeBold={rowToBeBold}
							/>
						))
					)
				) : (
					<EmptyDataTable key='table-row-empty'>{emptyDataView}</EmptyDataTable>
				)}
			</div>
			{!loader && paginationType === PAGINATION_TYPE.FOOTER && rows.length > 0 && (
				<ENGTPagination
					key='table-pagination-footer'
					totalPages={totalPages}
					size={size}
					customSizeOptions={customSizeOptions}
					page={page}
					totalElements={totalElements}
					onPageChange={changePageNumber}
					onSizeChange={changePaginationSize}
					ref={footerRef}
					wrapperCss={{
						position: 'relative',
						...overrides?.pagination,
					}}
				/>
			)}
		</div>
	);

	return (
		<>
			<Block
				className={css({
					...wrapperCss,
					...overrides?.wrapper,
				})}
			>
				<>{(rows.length > 0 || loader) && autoAdjustColumnSpan()}</>
				{renderTableContent()}
			</Block>
		</>
	);
};

export default ENGTTable;
