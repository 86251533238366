import { IObjProps } from 'shared/consts/types';

import { IPlanStateInterface } from './state';

export const planReducer = {
	getCurrentPlan: (state: IPlanStateInterface, action: { payload: IObjProps }) => {
		state.current = {
			data: action.payload,
			error: false,
			loading: false,
		};
	},
	getCurrentPlanError: (state: IPlanStateInterface) => {
		state.current = {
			data: {},
			error: true,
			loading: false,
		};
	},
	getCustomerDetails: (state: IPlanStateInterface, action: { payload: IObjProps }) => {
		state.details = {
			data: action.payload,
			error: false,
			loading: false,
		};
	},
	getCustomerDetailsError: (state: IPlanStateInterface) => {
		state.details = {
			data: {},
			error: true,
			loading: false,
		};
	},
};
