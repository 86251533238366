import React from 'react';
import { Block } from 'baseui/block';

import { IPrimaryNavItems, IPrimaryShopifyNavItems } from 'shared/consts/types';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import { SecondaryNavigationLinks } from '../../SecondaryNavigation';

import NavigationButton from './NavigationButton';
import NavigationDivider from './NavigationDivider';

interface INavigationLinksProps<T> {
	navigations: Array<T>;
	isActive: (_: string) => boolean;
	navLink: (_: any) => string;
}

type INavItemProps = IPrimaryNavItems | IPrimaryShopifyNavItems;

const SubNavigations = ({ navItem }: { navItem: INavItemProps }) => {
	const { isSmallDesktopScreen } = useResponsiveSize();

	if (!isSmallDesktopScreen) {
		return <></>;
	}

	return (
		<Block paddingLeft='2rem' marginBottom='1rem' width='calc(100% - 4rem)'>
			<SecondaryNavigationLinks page={navItem} />
		</Block>
	);
};

const NavigationLinks = ({ navigations, isActive, navLink }: INavigationLinksProps<INavItemProps>) => (
	<>
		{navigations.map((navItem: INavItemProps | '|', index: number) => {
			switch (navItem) {
				case '|':
					return <NavigationDivider key={`primary-navigation-${navItem}-${index}`} />;
				default:
					return (
						<React.Fragment key={`primary-navigation-${navItem}`}>
							<NavigationButton navItem={navItem} navLink={navLink(navItem)} isActive={isActive} />
							{isActive(navItem) && <SubNavigations navItem={navItem} />}
						</React.Fragment>
					);
			}
		})}
	</>
);

export default NavigationLinks;
