import React from 'react';
import { useStyletron } from 'baseui';

import { IObjProps } from 'shared/consts/types';

function DropDownToggleUp({ position = 0, size = 32 }: IObjProps) {
	const [, themes] = useStyletron();
	const box = `${position} ${position} ${size} ${size}`;

	return (
		<svg width={size} height={size} viewBox={box} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 13.6342L11.4142 12.22L16.364 17.1697L21.3137 12.22L22.7279 13.6342L16.3621 20L14.9479 18.5858L14.9497 18.584L10 13.6342Z'
				fill={themes.colors.primaryA}
			/>
		</svg>
	);
}

export default DropDownToggleUp;
