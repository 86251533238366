import { MESSAGE_TAB_HEADERS_MAP, WhatsAppQuickFilter } from 'shared/consts/consts';
import { SORTING } from 'shared/enum';

import { RootState } from 'store/rootReducer';

import {
	getShortcutQuickFilterFromLs,
	getShortcutQuickFilterLiteFromLs,
	getShortcutQuickFilterLiteFromLsMyConversation,
} from '..';

export const isWhatsAppFilterEnabled =
	(messageTab: string, isMyConversationApplied?: boolean) => (dispatch: any, getState: () => RootState) => {
		let shortcutQuickFilterData = '';
		if (messageTab === MESSAGE_TAB_HEADERS_MAP.oneviewlite) {
			shortcutQuickFilterData = isMyConversationApplied
				? dispatch(getShortcutQuickFilterLiteFromLsMyConversation())
				: dispatch(getShortcutQuickFilterLiteFromLs());
		} else {
			shortcutQuickFilterData = dispatch(getShortcutQuickFilterFromLs(messageTab));
		}

		return WhatsAppQuickFilter.includes(shortcutQuickFilterData || '');
	};

export const messageHelperActions = {
	getDefaultSort: (messageTab: any) => (dispatch: any, getState: () => RootState) => {
		const isRecentMessageFirstSort = getState().User.botAdmin.data.enable_recent_message_first_sort;
		if (dispatch(isWhatsAppFilterEnabled(messageTab))) {
			return SORTING.conversationClosingTimeLTH;
		}

		return isRecentMessageFirstSort ? SORTING.recentFirst : SORTING.recentUserFirst;
	},
};
