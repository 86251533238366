import React, { useMemo } from 'react';
import { useStyletron } from 'baseui';
import { differenceInCalendarDays } from 'date-fns';

import { showAllMinuteSlotsData } from 'shared/consts/consts';
import { getTimeFromDate } from 'shared/helpers';
import ClockIcon from 'shared/icons/ClockIcon';

import ENGTSelect, { ISelectProps, SelectOptionsType } from '../ENGTSelect/ENGTSelect';

interface IENGTTimePickerLocalProps extends Omit<ISelectProps<string>, 'options' | 'selectedValue'> {
	minTime?: Date;
	maxTime?: Date;
	options?: SelectOptionsType;
	value: string;
	showAllMinuteSlots?: boolean;
}

export type IENGTTimePickerProps = IENGTTimePickerLocalProps;

let options: Array<any> = [];
const minuteSlots = ['00', 15, 30, 45];

const getTimeParsedToInt = (time: string) => parseInt(time?.split(':')?.join(''));

for (let i = 0; i < 24; i++) {
	options = [
		...options,
		...minuteSlots.map((minute) => {
			const option = `${i < 10 ? `0${i}` : i}:${minute}`;

			return { id: option, label: option };
		}),
	];
}

const ENGTTimePicker = (props: IENGTTimePickerProps) => {
	const { maxTime, minTime, options: optionProps, value, onChange, showAllMinuteSlots, ...rest } = props;
	const [, theme]: any = useStyletron();

	const selectedValue = useMemo(
		() => (optionProps || options).filter((d) => d.id === value) || [],
		[optionProps, value]
	);

	const filteredOptions = useMemo(() => {
		if (showAllMinuteSlots) {
			options = [];
			for (let i = 0; i < 24; i++) {
				options = [
					...options,
					...showAllMinuteSlotsData.map((minute) => {
						const option = `${i < 10 ? `0${i}` : i}:${minute}`;

						return { id: option, label: option };
					}),
				];
			}
		}
		let optionsFiltered = optionProps || options;

		if (
			(minTime && differenceInCalendarDays(minTime, new Date()) === 0) ||
			(maxTime && differenceInCalendarDays(maxTime, new Date()) === 0)
		) {
			const date = getTimeFromDate(new Date());

			if (minTime) {
				optionsFiltered =
					optionsFiltered.filter((d) => getTimeParsedToInt(d.id) >= getTimeParsedToInt(date)) || [];
			}

			if (maxTime) {
				optionsFiltered =
					optionsFiltered.filter((d) => getTimeParsedToInt(d.id) <= getTimeParsedToInt(date)) || [];
			}
		}

		return optionsFiltered;
	}, [optionProps, minTime, maxTime]);

	return (
		<ENGTSelect
			options={filteredOptions}
			searchable
			clearable={false}
			selectedValue={selectedValue}
			onChange={(d: any) => onChange(d?.[0]?.id || '')}
			customComponentPassed={<ClockIcon fillColor={theme.colors.accent50} size={18} />}
			{...rest}
		/>
	);
};

export default ENGTTimePicker;
