import { ITokenInterface } from './state';

export const tokenReducer = {
	setToken: (state: ITokenInterface, action: { payload: string }) => {
		state.token = action.payload;
	},
	setPublicKey: (
		state: ITokenInterface,
		action: {
			payload: {
				publicKey: string | null;
				isEncryptionEnabled: boolean;
			};
		}
	) => {
		state.publicKey = action.payload.publicKey;
		state.isEncryptionEnabled = action.payload.isEncryptionEnabled;
	},
};
