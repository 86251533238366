import { IObjProps } from 'shared/consts/types';

import { messageDetailsReducer } from './messageDetails/reducers';
import { messageListReducer } from './messageList/reducers';
import { messageOverviewReducer } from './messageOverview/reducers';
import messagesCommonReducer from './messagesCommon/reducers';
import { postCommentsReducer } from './PostComments/reducers';
import { postListAndOverviewReducer } from './PostListAndOverview/reducers';
import { IMessageStateInterface, messageState } from './state';

export const messageCommonReducer = {
	resetAllMessagesData: (state: IMessageStateInterface) => {
		Object.keys(messageState).forEach((key) => {
			(state as IObjProps)[key] = (messageState as IObjProps)[key];
		});
	},
};

export const messageReducer = {
	...messageDetailsReducer,
	...messageListReducer,
	...messageOverviewReducer,
	...messageCommonReducer,
	...postListAndOverviewReducer,
	...postCommentsReducer,
	...messagesCommonReducer,
};
