import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { BEHAVIOR, Grid } from 'baseui/layout-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import AccountMenuForMobile from 'components/AccountDropdownMenu/AccountMenuForMobile';
import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';
import ENGTBackdrop from 'components/UI/ENGTBackdrop';
import UserProfileMobileHeader from 'components/UserProfileHeader/UserProfileMobileHeader';

import { getResponsiveVH } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import { common } from 'store/Common';
import { mobileMenuOpenSelector } from 'store/Common/selector';

import isMobileMenuHidden from '../helpers/isMobileMenuHidden';
import PrimaryNavigation from '../PrimaryNavigation';

import BotInfoHeader from './components/BotInfoHeader';
import Close from './components/Close';
import HamburgerMenu from './components/HamburgerMenu';
import ProfileHeader from './components/ProfileHeader';

const AccountMenus = () => (
	<Block
		display='flex'
		justifyContent='center'
		alignItems='flex-start'
		flexDirection='column'
		margin='1rem'
		gridGap='0.25rem'
	>
		<AccountMenuForMobile />
	</Block>
);

const ProfileMenu = () => (
	<Block display='flex' justifyContent='center' alignItems='flex-start' margin='1rem' gridGap='0.25rem'>
		<UserProfileMobileHeader />
	</Block>
);

const Divider = () => (
	<HorizontalDivider
		height={0}
		overrides={{
			width: '90%',
		}}
	/>
);

function MobileNavigation() {
	const [, theme]: any = useStyletron();
	const location = useLocation();
	const dispatch: any = useDispatch();

	const isMobileMenuOpen = useSelector(mobileMenuOpenSelector);

	const [showMobileMenu, setShowMobileMenu] = useState(false);

	const { isSmallTabletScreen } = useResponsiveSize();

	const { pathname } = location;

	const cellCss: any = {
		background: theme.colors.backgroundPrimary,
		height: 'inherit',
		overflowY: 'auto',
	};

	const gridCss: any = {
		Grid: {
			style: {
				flexWrap: 'nowrap',
				zIndex: theme.zIndex300,
				position: 'fixed',
				left: 0,
				top: 0,
				width: '100%',
				height: getResponsiveVH(),
			},
		},
	};

	const width: string = isSmallTabletScreen ? '70%' : '40%';

	const animationProps = useSpring({
		...cellCss,
		opacity: isMobileMenuOpen ? 1 : 0,
		width: isMobileMenuOpen ? width : '0%',
		config: { tension: 100, friction: 20 },
	});

	useLayoutEffect(() => {
		if (isMobileMenuOpen) {
			setShowMobileMenu(true);
		} else {
			setTimeout(() => {
				setShowMobileMenu(false);
			}, 300);
		}
	}, [isMobileMenuOpen]);

	useEffect(() => {
		if (isMobileMenuOpen) {
			dispatch(common.closeMobileMenu());
		}
	}, [location]);

	if (isMobileMenuHidden(pathname)) {
		return <></>;
	}

	return (
		<>
			{!showMobileMenu ? (
				<HamburgerMenu />
			) : (
				<Grid gridColumns={12} behavior={BEHAVIOR.fixed} overrides={gridCss}>
					<animated.div style={animationProps}>
						<ProfileHeader />
						<BotInfoHeader />
						<PrimaryNavigation />
						<Divider />
						<ProfileMenu />
						<Divider />
						<AccountMenus />
					</animated.div>
					<ENGTBackdrop onClick={() => dispatch(common.closeMobileMenu())} />
					<Close />
				</Grid>
			)}
		</>
	);
}

export default MobileNavigation;
