import { RootState } from 'store/withReducer';

export const shopifyStoreName = (state: RootState): boolean => state.Shopify?.account?.data?.displayName;

export const isWooCommerceEnabledSelector = (state: RootState) =>
	state.User.botAdmin.data.enable_woocommerce_shopper_assitance;
export const engagementTriggersEntilement = (state: RootState) => state.User.botAdmin.data.engagement_triggers;
export const environment = (state: RootState) => state.User.botAdmin.data.portal_base_url || 'app.engati.com';
export const integratedShopifyAccountState = (state: RootState): any => state.Shopify?.account?.data;
export const storeNameState = (state: RootState): any => state.Shopify?.account?.data?.displayName;
export const customerStoreDomain = (state: RootState): any => state.Shopify.account.data.shopifyStoreDomain;
