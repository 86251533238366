export const tokenState: ITokenInterface = {
	token: '',
	publicKey: '',
	isEncryptionEnabled: false,
};

export interface ITokenInterface {
	token: string;
	publicKey: string | null;
	isEncryptionEnabled: boolean;
}
