function CheckboxInactive({ fillColor, size }: { fillColor?: string; size?: number }) {
	return (
		<svg
			height='25'
			viewBox='14 3 1 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			style={{ width: '1.5625rem' }}
		>
			<g filter='url(#filter0_d_29664_17206)'>
				<rect x='7' y='7' height='18' rx='6' fill='white' style={{ width: '1.125rem' }} />
				<rect
					x='6.5'
					y='6.5'
					height='18'
					rx='6.5'
					stroke='url(#paint0_linear_29664_17206)'
					strokeOpacity='0.7'
					style={{ width: '1.125rem' }}
				/>
				<rect
					x='6.5'
					y='6.5'
					height='18'
					rx='6.5'
					stroke='url(#paint1_linear_29664_17206)'
					style={{ width: '1.125rem' }}
				/>
			</g>
			<defs>
				<filter
					id='filter0_d_29664_17206'
					x='4'
					y='5'
					width='24'
					height='24'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='1' />
					<feGaussianBlur stdDeviation='1' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0.25098 0 0 0 0 0.247059 0 0 0 0 0.258824 0 0 0 0.11 0'
					/>
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_29664_17206' />
					<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_29664_17206' result='shape' />
				</filter>
				<linearGradient
					id='paint0_linear_29664_17206'
					x1='16'
					y1='7'
					x2='16'
					y2='25'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#DADADA' stopOpacity='0.48' />
					<stop offset='1' stopColor='#DADADA' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_29664_17206'
					x1='16'
					y1='7'
					x2='16'
					y2='25'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#DADADA' stopOpacity='0.16' />
					<stop offset='1' stopColor='#DADADE' />
				</linearGradient>
			</defs>
		</svg>
	);
}

export default CheckboxInactive;
