import { createSlice } from '@reduxjs/toolkit';

import { userDetailsReducer } from './reducers';
import { userDetailsState } from './state';

export const userDetailsSlice = createSlice({
	name: 'User',
	initialState: userDetailsState,
	reducers: userDetailsReducer,
});

export const { actions, reducer } = userDetailsSlice;
