const FilledDownCaret = ({
	fillColor = '#403F42',
	width = 7,
	height = 5,
	pathToDraw = 'M4.04769 3.97601C3.73794 4.27406 3.24803 4.27406 2.93828 3.97601L0.246909 1.38623C-0.272027 0.886881 0.0814412 0.00976562 0.801609 0.00976562H6.18436C6.90453 0.00976562 7.258 0.886881 6.73906 1.38623L4.04769 3.97601Z',
}: {
	fillColor?: string;
	width?: number;
	height?: number;
	pathToDraw?: string;
}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d={pathToDraw} fill={fillColor} />
	</svg>
);

export default FilledDownCaret;
