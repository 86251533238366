import React from 'react';
import { ProgressBar, SIZE } from 'baseui/progress-bar';
import { useTranslation } from 'react-i18next';

export interface IProgressProps {
	borderRadius?: string;
	color: string;
	fillColor?: string;
	maxValue?: number;
	size: keyof SIZE;
	value: number;
}
export const ENGTProgress: React.FC<React.PropsWithChildren<IProgressProps>> = ({
	borderRadius,
	color,
	fillColor,
	maxValue,
	size,
	value,
}) => {
	const { t } = useTranslation(['common']);

	return (
		<ProgressBar
			size={size}
			value={value}
			successValue={maxValue}
			overrides={{
				BarProgress: {
					style: ({ $theme, $value }) => ({
						...$theme.typography.LabelMedium,
						backgroundColor: color,
						':after': {
							position: 'absolute',
							content: maxValue
								? `"${t('common:numberFormatter', { number: $value })}/"` +
									`"${t('common:numberFormatter', { number: maxValue })}"`
								: `"${t('common:numberFormatter', { number: $value })}"`,
							left: '0.1rem',
							marginTop: '0.45rem',
							marginLeft: '0.625rem',
						},
					}),
				},
				Bar: {
					style: ({ $theme }) => ({
						height: $theme.sizing.scale900,
						backgroundColor: fillColor,
						borderRadius: `${borderRadius}!important`,
					}),
				},
				BarContainer: {
					style: ({ $theme }) => ({
						backgroundColor: fillColor,
						borderRadius: `${borderRadius}!important`,
						marginBottom: '0rem',
						marginTop: '0rem',
						marginLeft: '0rem',
						marginRight: '0rem',
					}),
				},
			}}
		/>
	);
};
