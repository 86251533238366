import React from 'react';

const ClockIcon = ({
	className = '',
	fillColor = '#EE5D0B',
	size = 14,
}: {
	className?: string;
	fillColor?: string;
	size?: number;
}) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 14 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className={className}
	>
		<path
			d='M7.00033 12.8327C10.222 12.8327 12.8337 10.221 12.8337 6.99935C12.8337 3.77769 10.222 1.16602 7.00033 1.16602C3.77866 1.16602 1.16699 3.77769 1.16699 6.99935C1.16699 10.221 3.77866 12.8327 7.00033 12.8327Z'
			stroke={fillColor}
			strokeWidth='1.33'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7 3.5V7L9.33333 8.16667'
			stroke={fillColor}
			strokeWidth='1.33'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default ClockIcon;
