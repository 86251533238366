import React from 'react';
import { Checkbox as BaseUICheckbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';

import { IObjProps } from 'shared/consts/types';

import ENGTTooltip from '../ENGTTooltip/ENGTTooltip';

export interface ICheckboxProps {
	inputRef?: any;
	labelCss?: IObjProps;
	size?: number;
	style?: IObjProps;
	modeButton?: boolean;
	values: {
		isChecked: boolean;
		isDisabled?: boolean;
		isRequired?: boolean;
		label: string | React.ReactNode;
	};
	type?: keyof STYLE_TYPE;
	/**
	  Adjust width of the wrapper. default: 48%
	*/
	minWidth?: string;
	checkmarkCss?: IObjProps;
	dataQa?: string;
	onChange: (value: boolean) => any;
	height?: any;
	width?: any;
	toggleTrackCss?: IObjProps;
	tooltip?: string;
}

const ENGTCheckBox = ({
	inputRef,
	labelCss = {},
	size,
	modeButton,
	style,
	type = STYLE_TYPE.default,
	values,
	minWidth = '48%',
	checkmarkCss = {},
	dataQa,
	onChange,
	height,
	width,
	toggleTrackCss = {},
	tooltip = '',
}: ICheckboxProps) => {
	const { isChecked, isDisabled, isRequired, label } = values;

	const getRootStyles = (): IObjProps =>
		type === STYLE_TYPE.default
			? {
					width: 'fit-content',
				}
			: {
					minWidth,
				};

	const checkbox = (
		<BaseUICheckbox
			inputRef={inputRef}
			checked={isChecked}
			checkmarkType={STYLE_TYPE[type]}
			disabled={isDisabled}
			labelPlacement={LABEL_PLACEMENT.right}
			required={isRequired}
			onChange={(e) => {
				onChange(e.currentTarget.checked);
			}}
			overrides={{
				Root: {
					style: {
						position: 'relative',
						...getRootStyles(),
						...style,
					},
				},
				Input: {
					props: {
						'data-qa': dataQa,
					},
				},
				Label: {
					style: {
						fontSize: '0.875rem',
						lineHeight: '1.67rem',
						marginTop: '0.001rem',
						textAlign: 'start',
						...labelCss,
					},
				},
				Checkmark: {
					style: ({ $theme }) => ({
						boxShadow: '0rem 0.0625rem 0.125rem rgba(64, 63, 66, 0.50)',
						borderTopRightRadius: '0.375rem',
						borderTopLeftRadius: '0.375rem',
						borderBottomRightRadius: '0.375rem',
						borderBottomLeftRadius: '0.375rem',
						height: height || '1.125rem',
						width: width || '1.125rem',
						...checkmarkCss,
					}),
				},
				Toggle: {
					style: ({ $theme, $checked }: any) => ({
						backgroundColor: $theme.colors.primaryBtnTextColor,
						borderTopRightRadius: '50%',
						borderTopLeftRadius: '50%',
						borderBottomRightRadius: '50%',
						borderBottomLeftRadius: '50%',
						height: size ? `${size}rem` : '1.25rem',
						transform: $checked
							? `translateX(${size ? `${size - 0.25 * size}rem` : `1.125rem`})`
							: `translateX(0.125rem)`,
						width: size ? `${size}rem` : '1.25rem',
					}),
				},
				ToggleTrack: {
					style: ({ $theme, $checked }) => ({
						backgroundColor: modeButton
							? $checked
								? '#A1D946'
								: $theme.colors.accent
							: $checked
								? $theme.colors.accent
								: $theme.colors.contentStateDisabled,
						borderTopRightRadius: '62.5rem',
						borderBottomRightRadius: '62.5rem',
						borderTopLeftRadius: '62.5rem',
						borderBottomLeftRadius: '62.5rem',
						marginTop: '0rem',
						marginBottom: '0rem',
						height: height || (size ? `${size + 0.25}rem` : '1.5rem'),
						width: width || (size ? `${size + 0.75}rem` : '2.5rem'),
						...toggleTrackCss,
					}),
				},
			}}
		>
			{label}
		</BaseUICheckbox>
	);

	return isDisabled && tooltip ? (
		<ENGTTooltip content={tooltip} ignoreBoundary>
			<div>{checkbox}</div>
		</ENGTTooltip>
	) : (
		checkbox
	);
};

export default ENGTCheckBox;
