import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { SIZE } from 'baseui/input';
import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ENGTInput from 'components/UI/ENGTInput/ENGTInput';
import ENGTTextarea from 'components/UI/ENGTTextarea/ENGTTextarea';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { OFFERING_TO_PLAN_NAME_MAP, PLG_PLAN_EXPIRED } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { ACCOUNT_ENFORCED_USAGE, BROADCAST_USAGE } from 'shared/enum';

import { API } from 'store/AccountSettings/api';
import { offeringKey } from 'store/App/Plan/selectors';
import { isWhatsappAcceleratorTrialEnabled } from 'store/App/User';
import { openErrorSavingModalAction } from 'store/Modal';
import { RootState } from 'store/rootReducer';

enum ModalType {
	CONTACT_SALES = 'CONTACT_SALES',
	CONTACT_SUPPORT = 'CONTACT_SUPPORT',
}

interface IContactSalesProps {
	isModalOpen: boolean;
	options: IObjProps;
	toggleModal: any;
	heading?: string | React.ReactNode;
	usage?: string;
	email?: string;
	sendPageData?: boolean;
	disablePreSubject?: boolean;
	isLoading?: boolean;
	onSuccess?: Function;
	sendPlanData?: boolean;
	featureData?: string;
	description?: string | React.ReactNode;
	modalType?: ModalType;
}

enum SALESQUERY_WORKFLOW {
	WHATSAPP_ACCELERATOR = 'WHATSAPP_ACCELERATOR',
	PLATFORM = 'PLATFORM',
}

enum SupportQueryWorkflow {
	WHATSAPP_PAID_PLANS = 'WHATSAPP_PAID_PLANS',
}

function ContactSalesModal(props: IContactSalesProps) {
	const {
		isModalOpen,
		options,
		toggleModal,
		heading,
		usage,
		email,
		sendPageData,
		sendPlanData = false,
		disablePreSubject = false,
		isLoading = false,
		onSuccess,
		featureData = '',
		description = '',
		modalType = ModalType.CONTACT_SALES,
	} = props;
	const { preSubject, contactMode, defaultBody = '' } = options;
	const { t } = useTranslation(['pages', 'common']);
	const [css] = useStyletron();
	const dispatch = useDispatch<any>();
	const [showLoader, setLoader] = useState<boolean>(isLoading);
	const [isDisabled, setDisabled] = useState<boolean>(!preSubject);
	const [subject, setSubject] = useState<string>(preSubject);
	const [details, setDetails] = useState<string>(defaultBody || '');
	const offeringKeyValue = useSelector(offeringKey);
	const isWhatsappAcceleratorTrial = useSelector(isWhatsappAcceleratorTrialEnabled);
	const customerPlanDetails: any = useSelector((state: RootState) => state.Plan.current.data);

	const modalDetails = {
		confirmBtnLabel: t('components:contactSales.send'),
		cancelBtnLabel: t('common:cancel'),
		details: description || '',
		heading: heading || t('components:contactSales.contactSales'),
	};

	useEffect(() => {
		setSubject(preSubject);
		setDetails(defaultBody);
		setDisabled(!preSubject);
	}, [options]);

	const onClose = () => {
		toggleModal(false);
		setLoader(false);
		setDisabled(true);
		setDetails('');
	};

	const successCallback = (response: any) => {
		onClose();
		if (response.data.responseObject) {
			onSuccess && onSuccess();
			showToaster(contactMode);
		} else {
			dispatch(openErrorSavingModalAction());
		}
	};

	const contactSalesConfirmAction = () => {
		const dataSend: any = {
			subject,
			additionalDetails:
				details +
				(sendPageData ? `. Page URL: ${window.location.href}` : '') +
				(sendPlanData ? `. Current Plan: ${offeringKeyValue}` : '') +
				(featureData ? `. Feature Request : ${featureData}` : ''),
		};
		if (usage === BROADCAST_USAGE.RETRY_BROADCAST || usage === ACCOUNT_ENFORCED_USAGE.ACCOUNT_ENFORCED) {
			dataSend.queryType =
				usage === BROADCAST_USAGE.RETRY_BROADCAST
					? BROADCAST_USAGE.QUERY_TYPE
					: ACCOUNT_ENFORCED_USAGE.QUERY_TYPE;
			dataSend.toAddress = email;
			API.contactUs(dataSend).then((response: any) => {
				successCallback(response);
			});
		} else if (usage !== 'campaign') {
			const salesQueryWorkflow =
				isWhatsappAcceleratorTrial || usage === PLG_PLAN_EXPIRED
					? SALESQUERY_WORKFLOW.WHATSAPP_ACCELERATOR
					: SALESQUERY_WORKFLOW.PLATFORM;

			dataSend.starterPlan = !customerPlanDetails.planName;
			API.submitSalesQuery(dataSend, salesQueryWorkflow).then((response: IObjProps) => {
				successCallback(response);
			});
		} else {
			dataSend.queryType = 'CAMPAIGNS_QUERY';
			API.contactUs(dataSend).then((response: any) => {
				successCallback(response);
			});
		}
	};

	const contactSupportConfirmAction = () => {
		const payload = {
			subject,
			additionalDetails:
				details +
				(sendPageData ? `. Page URL: ${window.location.href}` : '') +
				(sendPlanData ? `. Current Plan: ${offeringKeyValue}` : '') +
				(featureData ? `. Feature Request : ${featureData}` : ''),
			customerPlan: OFFERING_TO_PLAN_NAME_MAP[offeringKeyValue],
			supportQueryWorkFlow: SupportQueryWorkflow.WHATSAPP_PAID_PLANS,
		};

		API.contactSupport(payload)
			.then(successCallback)
			.catch((err: any) => console.error(err));
	};

	const onConfirm = () => {
		setLoader(true);
		modalType === ModalType.CONTACT_SALES ? contactSalesConfirmAction() : contactSupportConfirmAction();
	};

	const modalBody = () => (
		<div>
			<p
				className={css({
					marginBottom: '0.5rem',
					fontSize: '1rem',
					...(description ? { fontWeight: 600 } : {}),
				})}
			>
				{t('components:contactSales.subject')}
			</p>
			<ENGTInput
				inputRef=''
				required
				placeholder={t('components:contactSales.enterSubject')}
				onChange={(e) => {
					setDisabled(!e.target.value);
					setSubject(e.target.value);
				}}
				value={subject}
				disabled={disablePreSubject}
			/>
			<p
				className={css({
					marginBottom: '0.5rem',
					fontSize: '1rem',
					...(description ? { fontWeight: 600 } : {}),
				})}
			>
				{t('components:contactSales.additionalDetails')}
			</p>
			<ENGTTextarea
				inputRef=''
				value={details}
				placeholder={t('components:contactSales.enterDetails')}
				size={SIZE.large}
				height='9.625rem'
				onChange={(e) => {
					setDetails(e.target.value);
				}}
				clearOnEscape
			/>
		</div>
	);

	const showToaster = (contactMode: any) => {
		switch (contactMode) {
			case 'downgrade':
				toaster.positive(
					<ENGTToasterContainer
						title={t('components:contactSales.downgradeToasterHeading')}
						description={t('components:contactSales.downgradeToasterMessage')}
					/>,
					{}
				);
				break;
			default:
				toaster.positive(
					<ENGTToasterContainer
						title={t('components:contactSales.toasterHeading')}
						description={t('components:contactSales.successToasterMessage')}
					/>,
					{}
				);
		}
	};

	return (
		<>
			{isModalOpen && (
				<ActionModal
					closeBtnLabel={modalDetails.cancelBtnLabel}
					confirmBtnLabel={modalDetails.confirmBtnLabel}
					description={modalDetails.details}
					heading={modalDetails.heading}
					width='26.375rem'
					isConfirmBtnDisabled={isDisabled}
					isLoading={showLoader}
					isOpen={isModalOpen}
					onClose={onClose}
					onConfirm={onConfirm}
					children={modalBody()}
				/>
			)}
		</>
	);
}

const withContactSalesModal = (WrappedComponent: any) => {
	const ModifiedContactSalesModal = (props: IContactSalesProps) => {
		const { t } = useTranslation(['common']);

		const modifiedProps = {
			...props,
			modalType: ModalType.CONTACT_SUPPORT,
			heading: t('common:contactSupport'),
		};

		return <WrappedComponent {...modifiedProps} />;
	};

	return ModifiedContactSalesModal;
};

const ContactSupportModal = withContactSalesModal(ContactSalesModal);

export default ContactSalesModal;
export { ContactSupportModal };
