import { IObjProps } from 'shared/consts/types';

import { IShopifyAccount, shopifyState } from './state';

export const shopifyReducer = {
	setIntegratedAccount: (state: IShopifyAccount, action: { payload: IObjProps }) => {
		state.account = {
			data: action.payload,
			error: false,
			loading: false,
		};
	},
	setIntegratedAccountError: (state: IShopifyAccount) => {
		state.account = {
			data: {},
			error: true,
			loading: false,
		};
	},
	setOnboardingStatus: (state: IShopifyAccount, action: { payload: IObjProps }) => {
		state.onboardingStatus = {
			data: action.payload,
			error: false,
		};
	},
	setOnboardingStatusError: (state: IShopifyAccount) => {
		state.onboardingStatus = {
			data: {},
			error: true,
		};
	},
	setProductInitialLoadData: (state: IShopifyAccount, action: { payload: IObjProps }) => {
		state.productInitialLoadData = action.payload;
	},
	setSegmentStatus: (state: IShopifyAccount, action: { payload: boolean }) => {
		state.segmentsStatus = action.payload;
	},
	setProductDiscoveryConfig: (state: IShopifyAccount, action: { payload: IObjProps }) => {
		state.productDiscoveryConfig = action.payload;
	},
	resetShopifyData: (state: IShopifyAccount) => {
		Object.keys(shopifyState).forEach((key) => {
			(state as IObjProps)[key] = (shopifyState as IObjProps)[key];
		});
	},
};
