import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';

import { isDevEnvironment } from 'shared/helpers';

import { disableReactDevTools } from 'services/devTools.config';

import App from './app/App';
import store from './store/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.scss';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './i18n';

!isDevEnvironment() && disableReactDevTools();

const engine = new Styletron({ prefix: 'e-' });
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<Provider store={store}>
		<BrowserRouter basename='/admin'>
			<StyletronProvider value={engine}>
				<App />
			</StyletronProvider>
		</BrowserRouter>
	</Provider>
);

reportWebVitals();
if (!isDevEnvironment()) {
	serviceWorkerRegistration.register();
}
