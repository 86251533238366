interface IPostHogConfigs {
	isPostHogEnabled: boolean;
	isSessionRecordingEnabled: boolean;
}

export interface ICommonStateInterface {
	isMobileMenuOpen: boolean;
	postHogConfigs: IPostHogConfigs;
}

export const commonState: ICommonStateInterface = {
	isMobileMenuOpen: false,
	postHogConfigs: {
		isPostHogEnabled: false,
		isSessionRecordingEnabled: true,
	},
};
