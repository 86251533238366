import React from 'react';
import { useStyletron } from 'baseui';

import { IObjProps } from 'shared/consts/types';

export interface IHorizontalDividerProps {
	height: number | string;
	color?: string;
	borderColor?: string;

	overrides?: IObjProps;
}

export const HorizontalDivider: React.FC<React.PropsWithChildren<IHorizontalDividerProps>> = ({
	color,
	height,
	borderColor,
	overrides,
	...props
}) => {
	const [css, theme]: [any, any] = useStyletron();

	return (
		<hr
			className={css({
				height,
				marginTop: '0',
				marginBottom: '0',
				borderTop: '1px solid',
				borderBottom: '0',
				borderLeft: '0',
				borderRight: '0',
				borderWidth: 'thin',
				borderColor: borderColor || theme.colors.horizantalDividerColor,
				color: color || theme.colors.horizantalDividerColor,
				...overrides,
			})}
		/>
	);
};
