import React from 'react';
import { useStyletron } from 'baseui';

import Popover from 'components/UI/ENGTTooltip/ENGTTooltip';

import { IObjProps } from 'shared/consts/types';
import { CARD_CSS } from 'shared/helpers';
import InfoIcon from 'shared/icons/PathBuilder/InfoIcon';

interface IInfoCardWithIconProps {
	title?: string | React.ReactNode;
	infoIcon?: string;
	appIcon?: JSX.Element;
	value: number | React.ReactNode;
	children?: React.ReactNode;
	overrides?: IObjProps;
	marginTop?: string;
}

const InfoCardWithIcon = (props: IInfoCardWithIconProps) => {
	const [css, theme]: [any, any] = useStyletron();
	const { title, infoIcon, appIcon, value, children, overrides, marginTop } = props;

	const infoIconCss = {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		height: '1.25rem',
		justifyContent: 'center',
		marginLeft: '0rem',
		marginRight: '0rem',
		width: '1.25rem',
	};

	return (
		<div
			className={css({
				...CARD_CSS,
				background: theme.colors.inputFillPrimary,
				flex: '1 1 0',
				justifyContent: 'center',
				height: '8.75rem',
				paddingRight: '0.75rem',
				paddingLeft: '0.75rem',
				...overrides?.cardWrapper,
			})}
		>
			<div
				className={css({
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				})}
			>
				<div
					className={css({
						display: 'flex',
						fontWeight: 500,
						paddingTop: appIcon ? '0rem' : '0.2rem',
						paddingLeft: '2rem',
						alignItems: 'center',
						color: theme.colors.primaryA,
						...overrides?.title,
					})}
				>
					{title}
					{infoIcon && (
						<Popover content={infoIcon}>
							<span aria-label={infoIcon} className={css(infoIconCss)}>
								<InfoIcon fill={theme.colors.accent50} />
							</span>
						</Popover>
					)}
					{appIcon && (
						<div
							className={css({
								alignItems: 'center',
								display: 'flex',
								height: '1.25rem',
								justifyContent: 'center',
								marginLeft: '0.2rem',
								marginRight: '0rem',
								width: '1.25rem',
							})}
						>
							{appIcon}
						</div>
					)}
				</div>
				<div
					className={css({
						display: 'flex',
						justifyContent: 'space-evenly',
						flexDirection: 'row',
						alignItems: 'center',
					})}
				>
					<div
						className={css({
							fontSize: '3rem',
							marginTop: marginTop || '1rem',
							padding: '0.5rem',
							fontWeight: 700,
							color: `${theme.colors.primaryA}`,
							alignItems: 'center',
							justifyContent: 'center',
						})}
					>
						{value}
					</div>
					<div style={{ marginLeft: '1rem', justifyContent: 'center', alignItems: 'center' }}>{children}</div>
				</div>
			</div>
		</div>
	);
};

export default InfoCardWithIcon;
