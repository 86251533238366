import React from 'react';

export interface IENGTRightArrowIcon {
	fillColor: string;
	height: string;
	width: string;
	viewX?: number;
	viewY?: number;
	viewBoxWidth?: number;
	viewBoxHeight?: number;
}

function RightArrowIcon(props: IENGTRightArrowIcon) {
	const { fillColor = '#403F42', height, width, viewBoxWidth = 6, viewBoxHeight = 10, viewX = 0, viewY = 0 } = props;
	const viewBox = `${viewX} ${viewY} ${viewBoxWidth} ${viewBoxHeight}`;

	return (
		<svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.16404 9.71191L0.103385 8.65125L3.81574 4.9389L0.103271 1.22644L1.16393 0.16578L5.93832 4.94016L4.87766 6.00082L4.8764 5.99956L1.16404 9.71191Z'
				fill={fillColor}
			/>
		</svg>
	);
}

export default RightArrowIcon;
