import { ONEVIEW_SCREENS, SOCIAL_INBOX_MEDIA_TYPES } from 'shared/consts/SocialInbox/constants';
import { IObjProps, IPaginatedApiStateProps, IPaginationState } from 'shared/consts/types';

export interface ISelectedPost {
	postId: string;
	pageId: string;
	caption: string;
	likesOrReactionsCount: number;
	previewMediaUrl: string;
	attachmentMediaType: SOCIAL_INBOX_MEDIA_TYPES;
	attachments: Array<string>;
	totalCommentsCount: number;
	updatedOn: string;
	createdOn: string;
	pageName: string;
	pageProfilePic: string;
	commentsNextCursor: string;
	commentsSyncStatus: string;
}

export interface IPostListAndOverviewInterface {
	currentOneViewScreen: string;
	listOfPosts: IPaginatedApiStateProps<Array<IObjProps>, IPaginationState>;
	selectedPost: ISelectedPost;
	unreadPostIdCountMap: IObjProps;
	viewStitchedUserComment: boolean;
	instanceBotRef: string;
}

export const postListAndOverviewState: IPostListAndOverviewInterface = {
	currentOneViewScreen: ONEVIEW_SCREENS.MESSAGES,
	listOfPosts: {
		data: [],
		error: false,
		loading: false,
		pagination: {
			page: 1,
			size: 10,
			totalPages: 1,
			totalElements: 1,
		},
	},
	selectedPost: {
		postId: '',
		pageId: '0',
		caption: '',
		updatedOn: '',
		createdOn: '',
		likesOrReactionsCount: 0,
		previewMediaUrl: '',
		attachmentMediaType: SOCIAL_INBOX_MEDIA_TYPES.TEXT,
		attachments: [],
		totalCommentsCount: 0,
		pageName: '',
		pageProfilePic: '',
		commentsNextCursor: '',
		commentsSyncStatus: '',
	},
	unreadPostIdCountMap: {},
	viewStitchedUserComment: false,
	instanceBotRef: '',
};
