import React, { createContext, useState } from 'react';
import { useStyletron } from 'baseui';

import { Mask } from '@reactour/mask';

import { IObjProps } from 'shared/consts/types';

export const MaskVisibilityContext = createContext({});

const ENGTMask: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
	const [, theme]: any = useStyletron();
	const maskState = useState<IObjProps | null>(null);
	const [maskSizes] = maskState;

	return (
		<MaskVisibilityContext.Provider value={maskState}>
			{children}
			{maskSizes && (
				<Mask
					sizes={maskSizes as any}
					styles={{
						maskRect: (base) => ({ ...base, zIndex: theme.zIndex600 }),
						maskArea: (base) => ({
							...base,
							zIndex: theme.zIndex600,
							clipPath: 'inset(2px round 8px 8px 8px 8px)',
						}),
						highlightedArea: (base) => ({ ...base, zIndex: theme.zIndex600 }),
						clickArea: (base) => ({ ...base, zIndex: theme.zIndex600 }),
						svgWrapper: (base) => ({ ...base, zIndex: theme.zIndex600 }),
						maskWrapper: (base) => ({ ...base, zIndex: theme.zIndex600 }),
					}}
				/>
			)}
		</MaskVisibilityContext.Provider>
	);
};

export default ENGTMask;
