import { useEffect, useMemo, useState } from 'react';
import { useStyletron } from 'baseui';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import ExpertAssistanceForm from 'components/AccountDropdownMenu/components/ExpertAssistanceForm';
import FeedBackForm from 'components/AccountDropdownMenu/components/FeedBackForm';
import PaymentFailureModalExpertAssistance from 'components/AccountDropdownMenu/components/PaymentFailureModalExpertAssistance';
import PaymentSuccessModalExpertAssistance from 'components/AccountDropdownMenu/components/PaymentSuccessModalExpertAssistance';
import ENGTMenuList from 'components/UI/ENGTMenuList/ENGTMenuList';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import Divider from 'components/UserProfileHeader/component/Divider';
import useIsAdminModeHook from 'components/UserProfileHeader/hooks/useIsAdminModeHook';

import { ENGATI_ROLES, EXPERT_ASSISTANCE_PARAM, SUPPORT_BOT_KEY } from 'shared/consts/consts';
import { DOC_LINKS_V2 } from 'shared/consts/DockLinks';
import { IObjProps } from 'shared/consts/types';

import { API } from 'store/App/User/api';
import { isShopifyEnabledAcc, isWhatsappAcceleratorEnabled } from 'store/App/User/selectors';
import { isEngatiBrandSelector } from 'store/Users/selectors';
import { RootState } from 'store/withReducer';

import { TwoColumnRouteLinks } from 'router/links/TwoColumnRouteLinks';
import { NoColumnRouteLinks } from 'router/NoColumnRoutes';

const NeedHelp = () => {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['pages', 'components', 'common']);
	const { pathname } = useLocation();

	const [waSupportBotDetails, setWASupportBotDetails] = useState<IObjProps>({});
	const [isBannerModelOpen, setBannerModelOpen] = useState<boolean>(false);
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	const [dateValue, setDateValue] = useState<Date>(new Date());
	const [isErrorModalOpen, setErrorModalOpen] = useState<boolean>(false);
	const [isExpertAssistanceFormOpen, setExpertAssistanceFormOpen] = useState<boolean>(false);

	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const { isAdminMode } = useIsAdminModeHook();

	const userRole: string = useSelector((state: RootState) => state.User.profile.data.roleName);
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const isWhatsappAcceleratorAccount = useSelector(isWhatsappAcceleratorEnabled);

	const isAgentsHome: boolean = useMemo(() => {
		const { messagingHome, simplifiedMessages } = TwoColumnRouteLinks;

		return (
			(pathname.indexOf(messagingHome) > -1 || pathname.indexOf(simplifiedMessages) > -1) &&
			userRole === ENGATI_ROLES.ROLE_CONNECT_AGENT
		);
	}, [pathname, userRole]);

	const hasPathAccess: any =
		matchPath(NoColumnRouteLinks.bots, pathname) ||
		matchPath(TwoColumnRouteLinks.landingPageLink, pathname) ||
		isAgentsHome ||
		isShopifyEnabled ||
		isWhatsappAcceleratorAccount;

	const listItems = [
		{
			label: t('pages:banner.sendFeedback'),
			onClick: () => setBannerModelOpen(true),
		},
		{
			label: t('components:needHelp.helpDocs'),
			onClick: () => window.open(DOC_LINKS_V2.home, '_blank'),
		},
		{
			label: t('components:needHelp.connectOnWhatsApp'),
			onClick: () =>
				window.open(
					`https://wa.me/${waSupportBotDetails?.phoneNumber}?text=${waSupportBotDetails?.prefilledMsg}`,
					'_blank'
				),
		},
		{
			label: t('components:needHelp.askAQuery'),
			onClick: () => {
				const windowObject: any = window;
				windowObject?.EngtChat?.open();
			},
		},
		{
			label: t('components:needHelp.reportAnIssue'),
			onClick: () => window.open(DOC_LINKS_V2.jiraCustomerIssueLink, '_blank'),
		},
		{
			label: t('components:needHelp.expertAssistance'),
			onClick: () => setExpertAssistanceFormOpen(true),
		},
	];

	const showNegativeToaster = () =>
		toaster.negative(
			<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
			{}
		);

	useEffect(() => {
		const originLocation = window.location.origin;
		let waSupportBot = SUPPORT_BOT_KEY.PROD;
		if (originLocation.includes('qa') && originLocation.includes('engati')) {
			waSupportBot = SUPPORT_BOT_KEY.QA;
		}
		if (originLocation.includes('dev') && originLocation.includes('engati')) {
			waSupportBot = SUPPORT_BOT_KEY.DEV;
		}
		API.getWASupportBotDetails(waSupportBot)
			.then((resp: any) => {
				setWASupportBotDetails(resp?.data);
			})
			.catch(() => {
				showNegativeToaster();
			});
	}, []);

	useEffect(() => {
		if (searchParams.get(EXPERT_ASSISTANCE_PARAM) === 'true') {
			navigate('', { replace: true });
			searchParams.delete(EXPERT_ASSISTANCE_PARAM);
			setSearchParams(searchParams);
			setModalOpen(true);
		} else if (searchParams.get(EXPERT_ASSISTANCE_PARAM) === 'false') {
			navigate('', { replace: true });
			searchParams.delete(EXPERT_ASSISTANCE_PARAM);
			setSearchParams(searchParams);
			setErrorModalOpen(true);
		}
	}, [searchParams]);

	return (isEngatiBrand || isAdminMode) && hasPathAccess ? (
		<>
			<div
				className={css({
					fontWeight: 500,
					color: theme.colors.primaryA,
					borderRadius: '0.25rem',
					cursor: 'pointer',
					fontSize: '0.875rem !important',
					lineHeight: '1.25rem !important',
					letterSpacing: '0.0015em',
				})}
				data-posthog-element-id='need-help'
			>
				<StatefulPopover
					overrides={{
						Inner: {
							style: {
								backgroundColor: 'transparent',
							},
						},
						Body: {
							style: ({ $theme }: any) => ({
								backgroundColor: 'transparent',
								zIndex: $theme.zIndex300,
							}),
						},
					}}
					placement={PLACEMENT.bottomLeft}
					triggerType={TRIGGER_TYPE.click}
					content={({ close }) => (
						<ENGTMenuList
							Items={listItems.map((item) => ({
								...item,
								onClick: (e: any) => {
									item.onClick();
									close();
								},
							}))}
							isOpen
							top='0'
							right='0'
							marginBottom='0'
							marginTop='0'
							position='relative'
						/>
					)}
				>
					{t('components:needHelp.needHelp')}
				</StatefulPopover>
			</div>
			<Divider />
			{isBannerModelOpen && <FeedBackForm isOpen toggleModal={setBannerModelOpen} />}
			{isExpertAssistanceFormOpen && (
				<ExpertAssistanceForm
					dateValue={dateValue}
					setDateValue={setDateValue}
					toggleModal={setExpertAssistanceFormOpen}
				/>
			)}
			{isModalOpen && (
				<PaymentSuccessModalExpertAssistance
					dateValue={dateValue}
					toggleModal={setModalOpen}
					isModalOpen={isModalOpen}
				/>
			)}
			{isErrorModalOpen && (
				<PaymentFailureModalExpertAssistance
					toggleModal={setErrorModalOpen}
					isModalOpen={isErrorModalOpen}
					setOpenConfirmationModal={setExpertAssistanceFormOpen}
				/>
			)}
		</>
	) : (
		<></>
	);
};

export default NeedHelp;
