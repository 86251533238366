import { IObjProps } from 'shared/consts/types';

import { INotificationInterface } from './state';

export const notificationReducers = {
	addNotification: (state: INotificationInterface, action: { payload: IObjProps }) => {
		state.data = action.payload.data;
		state.isOpen = true;
	},
	updateNotifications: (state: INotificationInterface, action: { payload: IObjProps[] }) => {
		state.data = [...action.payload];
		state.isOpen = false;
	},
	clearNotifications: (state: INotificationInterface, action: {}) => {
		state.data = [];
		state.isOpen = false;
	},
	toggleNotificaitonTray: (state: INotificationInterface, action: { payload: IObjProps }) => {
		const isOpen = typeof action.payload?.isOpen === 'boolean' ? action?.payload.isOpen : !state.isOpen;

		state.isOpen = isOpen;
	},
};
