import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { IObjProps } from 'shared/consts/types';
import { isValidNestedResponseObj } from 'shared/helpers';

import { getAnalyticsCampaignAction, getCampaignAction } from 'store/Campaign';
import { API } from 'store/Campaign/api';
import { campaignsData } from 'store/Campaign/selectors';
import { openErrorSavingModalAction } from 'store/Modal/actions';

import useIsCampaignAnalyticsEnabledHook from './useIsCampaignAnalyticsEnabledHook';

interface IDeleteCampaignHook {
	selectedCampaign: IObjProps;
	toggleDeleteModal: Function;
	setLoading: Function;
}

function useDeleteCampaignHook({ selectedCampaign, toggleDeleteModal, setLoading }: IDeleteCampaignHook) {
	const dispatch = useDispatch<any>();
	const { t } = useTranslation(['common', 'pages']);

	const campaigns = useSelector(campaignsData);
	const { size, page } = campaigns?.pagination;
	const showWhatsappCampaignAnalytics = useIsCampaignAnalyticsEnabledHook();

	const deleteCampaign = () => {
		setLoading(true);
		API.deleteCampaign(selectedCampaign?.campaign_id).then((response: any) => {
			if (isValidNestedResponseObj(response)) {
				toaster.positive(
					<ENGTToasterContainer
						title={t('pages:broadcast.campaigns.deleteSuccessful')}
						description={t('common:changesSuccessfulSaved')}
					/>,
					{}
				);

				const params = {
					page: (page || 1) - 1,
					size,
					...(showWhatsappCampaignAnalytics
						? { targetApi: 'campaignAnalytics' }
						: { target_url: 'campaign' }),
				};
				showWhatsappCampaignAnalytics
					? dispatch(getAnalyticsCampaignAction(params))
					: dispatch(getCampaignAction(params));
			} else {
				dispatch(openErrorSavingModalAction());
			}
			setLoading(false);
			toggleDeleteModal(false);
		});
	};

	return [deleteCampaign];
}

export default useDeleteCampaignHook;
