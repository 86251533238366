import React from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';

import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { GridColumns, GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { LegendOrdinal } from '@visx/legend';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Bar, BarGroup } from '@visx/shape';
import { useTooltip } from '@visx/tooltip';

import { IObjProps } from 'shared/consts/types';
import { borderRadius } from 'shared/helpers';
import NoBarGraphDataIcon from 'shared/icons/NoBarGraphDataIcon.svg';

import { IBarGraphProps } from './ENGTGraphProps';
import ENGTGraphToolTip from './ENGTGraphToolTip';

const ENGTBarGroupY = ({ width, height, accessor, colors, keys, data, ...additionals }: IBarGraphProps) => {
	const { t } = useTranslation();
	const [css, theme] = useStyletron();

	const {
		header,
		radius = 8,
		background,
		barRadius = 4,
		leftNumTicks = 4,
		bottomNumTicks = 7,
		barOpacity = 100,
		yDomainMax = 8,
		axisBottomLeft,
		hideRowGrid = false,
		hideLegend = false,
		showTooltipForBars = true,
		distinctBarColor = false,
		hideColumnGrid = false,
		textColor = theme.colors.white,
		gridStroke = theme.colors.accent100,
		labelFormat = (value: any) => value,
		xFormatter = (value: any) => value,
		yFormatter = (value: any) => value,
		padding = { inner: 0.5, outer: 0.5 },
		margin = { top: 40, right: 32, bottom: 24, left: 32 },
		labelHeight,
		leftLabel,
		bottomLabel,
	} = additionals;

	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;

	const xAxis = scaleBand<string>({
		domain: data.map(accessor),
		padding: 0.0,
	});

	const yAxis = scaleLinear<number>({
		domain: [
			0,
			Math.max(
				(data.length && yDomainMax) || 0,
				...data.map((d) => Math.max(...keys.map((key) => Number(d[key]))))
			),
		],
		nice: true,
	});

	const dataScale = scaleBand<string>({
		domain: keys,
		paddingInner: padding.inner,
		paddingOuter: padding.outer,
	});

	const labelScale = scaleOrdinal<string, string>({
		domain: keys,
		range: colors,
	});

	xAxis.rangeRound([0, xMax]);
	yAxis.range([yMax, 0]);
	dataScale.rangeRound([0, xAxis.bandwidth()]);

	let tooltipTimeout: any;
	const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<IObjProps>();

	return (
		<div style={{ position: 'relative' }}>
			{!hideLegend && (
				<div
					style={{
						position: 'absolute',
						fontSize: '0.875rem',
						display: 'flex',
						width: '100%',
						marginTop: '1rem',
						justifyContent: 'space-between',
					}}
				>
					{header}
					<LegendOrdinal
						scale={labelScale}
						direction='column'
						itemMargin={2}
						labelAlign='flex-start'
						labelMargin='0 30px 0 0'
						className={css({
							color: textColor,
							fontSize: '0.75rem',
							fontWeight: 500,
							lineHeight: '1rem',
							overflow: labelHeight ? 'scroll' : 'hidden',
							height: labelHeight || 'none',
							marginLeft: header ? '' : '1rem',
						})}
						shapeStyle={() => ({
							width: '0.75rem',
							height: '0.75rem',
							marginBottom: '0.125rem',
							...borderRadius('0.25rem'),
						})}
						labelFormat={labelFormat}
					/>
				</div>
			)}
			{data.length > 0 ? (
				<svg width={width} height={height}>
					<rect x={0} y={0} width={width} height={height} fill={background} rx={radius} />
					<Group left={margin.left} top={margin.top}>
						{!hideRowGrid && (
							<GridRows
								scale={yAxis}
								width={xMax}
								numTicks={leftNumTicks}
								stroke={gridStroke}
								opacity={0.2}
							/>
						)}
						{!hideColumnGrid && (
							<GridColumns
								scale={xAxis}
								height={yMax}
								numTicks={bottomNumTicks}
								stroke={gridStroke}
								opacity={0.2}
							/>
						)}
						<AxisLeft
							labelClassName={css({
								fill: textColor,
							})}
							label={leftLabel}
							scale={yAxis}
							hideTicks
							numTicks={leftNumTicks}
							tickFormat={yFormatter}
							stroke={theme.colors.accent100}
							tickLabelProps={() => ({
								fontSize: 10,
								fill: textColor,
								textAnchor: 'end',
							})}
						/>
						<AxisBottom
							labelClassName={css({
								fill: textColor,
							})}
							label={bottomLabel}
							top={yMax}
							scale={xAxis}
							left={axisBottomLeft}
							hideTicks
							numTicks={bottomNumTicks}
							tickFormat={xFormatter}
							stroke={theme.colors.accent100}
							tickLabelProps={() => ({
								fontSize: 10,
								fill: textColor,
								textAnchor: 'middle',
							})}
						/>
						<BarGroup
							data={data}
							keys={keys}
							height={yMax}
							x0={accessor}
							yScale={yAxis}
							x0Scale={xAxis}
							x1Scale={dataScale}
							color={labelScale}
							alignmentBaseline='middle'
						>
							{(barGroups) =>
								barGroups.map((barGroup) => (
									<Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
										{barGroup.bars.map((bar) => (
											<Bar
												key={`bar-group-bar-${barGroup.index}-${bar.key}-${bar.value}`}
												x={bar.x}
												y={bar.y}
												width={bar.width}
												height={bar.height}
												fill={distinctBarColor ? colors[barGroup.index] : bar.color}
												rx={barRadius}
												opacity={barOpacity}
												onMouseMove={(event) => {
													if (tooltipTimeout) {
														clearTimeout(tooltipTimeout);
													}
													const point = localPoint(event);
													showTooltip({
														tooltipData: bar,
														tooltipLeft: point?.x,
														tooltipTop: point?.y,
													});
												}}
												onMouseLeave={() => {
													tooltipTimeout = window.setTimeout(() => hideTooltip(), 200);
												}}
											/>
										))}
									</Group>
								))
							}
						</BarGroup>
					</Group>
				</svg>
			) : (
				<div
					className={css({
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						paddingTop: '4rem',
					})}
				>
					<img height={yMax} src={NoBarGraphDataIcon} alt='no-bot-queries' />
					<span
						className={css({
							fontWeight: 600,
							fontSize: '0.875rem',
							lineHeight: '1.25rem',
							marginLeft: '1rem',
							color: theme.colors.primaryA,
						})}
					>
						{t('common:noDataAvailable')}
					</span>
				</div>
			)}
			{showTooltipForBars && tooltipOpen && tooltipTop && tooltipLeft && (
				<ENGTGraphToolTip top={tooltipTop} left={tooltipLeft} data={tooltipData} label={labelFormat} />
			)}
		</div>
	);
};

export default ENGTBarGroupY;
