import BaseService from 'services/baseService';
import { serialize } from 'shared/helpers';

const getBrandingDetailsUrl = '/on-boarding/branding-details';
const getUserIpUrl = 'https://api.ipify.org/?format=json';
const getCityCountryUrl = 'https://api.ipbase.com/v1/json/';
const loginUrl = '/v2/login';
const registerUrl = '/getstarted-v2';
const registerWhatsappPlgUrl = 'wa-accelerator/get-started';
const resendOtpUrl = '/resend-otp';
const forgotPasswordUrl = '/v2/input_email';
const resetPasswordUrl = '/reset-password';
const verifyCodeUrl = '/validate/verify-code';
const verifyCustomerUrl = '/verify-account';
const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const loginRedirectUrl = '/v2/redirect/login';
const send2faOtp = '/v2/generate/2fa-otp';
const validate2faOtp = '/v2/validate/2fa-otp';

const getBrandingDetailsApi = new BaseService(getBrandingDetailsUrl);
const loginAPi = new BaseService(loginUrl);
const loginRedirectApi = new BaseService(loginRedirectUrl);
const mergedRegistrationSecondStep = '/merged-registration/choose-product-vertical';
const registerApi = new BaseService(registerUrl);
const registerWhatsappPlgApi = new BaseService(registerWhatsappPlgUrl);
const resendOtpApi = new BaseService(resendOtpUrl);
const forgotPasswordApi = new BaseService(forgotPasswordUrl);
const resetPasswordApi = new BaseService(resetPasswordUrl);
const verifyCodeApi = new BaseService(verifyCodeUrl);
const verifyCustomerApi = new BaseService(verifyCustomerUrl);
const genericProxyRequestApi = new BaseService(genericProxyRequestUrl);
const send2faOtpApi = new BaseService(send2faOtp);
const validate2faOtpApi = new BaseService(validate2faOtp);
const mergedRegistrationSecondStepApi = new BaseService(mergedRegistrationSecondStep);

export const API = {
	getBrandingDetails() {
		return getBrandingDetailsApi.getRequest();
	},
	getUserIp() {
		return fetch(getUserIpUrl);
	},
	getCityCountry() {
		return fetch(getCityCountryUrl);
	},
	login(data) {
		return loginAPi.postRequest(data);
	},
	loginRedirect(data) {
		const params = {
			config: data,
		};

		return loginRedirectApi.getRequest(serialize(params, '?'));
	},
	register(data, params) {
		return registerApi.postRequest(data, serialize(params, '?'));
	},
	registerWhatsappPlg(data, params) {
		return registerWhatsappPlgApi.postRequest(data, serialize(params, '?'));
	},
	resendOtp(email) {
		return resendOtpApi.postRequest({ email, moduleName: '' });
	},
	forgotPassword(data) {
		return forgotPasswordApi.postRequest(data);
	},
	resetPassword(data) {
		return resetPasswordApi.postRequest(data);
	},
	updateProductVertical(data) {
		const params = {
			targetApi: 'updateProductVertical',
		};

		return genericProxyRequestApi.putRequest(data, serialize(params, '?'));
	},
	validateVerificationToken(token) {
		const params = {
			code: token,
		};

		return verifyCodeApi.getRequest(serialize(params, '?'));
	},
	verifyCustomer(token) {
		const params = {
			token,
			isPortalV2: true,
		};

		return verifyCustomerApi.getRequest(serialize(params, '?'));
	},
	send2faOtpMail(email) {
		const params = {
			email,
		};

		return send2faOtpApi.getRequest(serialize(params, '?'));
	},
	validate2faOtp(data) {
		const params = {
			...data,
		};

		return validate2faOtpApi.getRequest(serialize(params, '?'));
	},
	createSalesforceObject(data) {
		const params = {
			targetApi: 'createSalesforceObject',
		};

		return genericProxyRequestApi.postRequest(data, serialize(params, '?'));
	},
	updateSalesforceObject(data, email) {
		const params = {
			targetApi: 'updateSalesforceObject',
			email,
		};

		return genericProxyRequestApi.putRequest(data, serialize(params, '?'));
	},
	mergedRegistrationSecondStep(data, params) {
		return mergedRegistrationSecondStepApi.postRequest(data, serialize(params, '?'));
	},
};
