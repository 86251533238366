import { REACT_APP_API_GATEWAY_BASE_URL } from 'shared/config/config';
import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const genericProxyUrl = '/v2/genericProxyRequest/';
const apiGetWayGeneralProxyUrl = REACT_APP_API_GATEWAY_BASE_URL;
const uploadApi = new BaseService('/v2/importContactList/', {
	headers: {
		'Content-Type': 'multipart/form-data',
	},
});
const genericProxyRequestApi = new BaseService(genericProxyUrl);
const apiGetWayGeneralProxyApi = new BaseService(apiGetWayGeneralProxyUrl);

export const contactsAPI = {
	importContactList(file, params) {
		const formData = new FormData();
		formData.append('file', file);

		return uploadApi.postRequest(formData, serialize(params, '?'));
	},
	convertNumbersToCSV(params, body) {
		return apiGetWayGeneralProxyApi.postRequest(body, serialize(params, '?'));
	},
	sendEmailWithDummyData(params, body) {
		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	getContacts(params) {
		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
	getImportContactHistory(params) {
		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
	getExportContactHistory(params) {
		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
	getScheduledBroadcastStats(params) {
		return apiGetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	deleteContactList(params) {
		return genericProxyRequestApi.deleteRequest(serialize(params, '?'));
	},
	saveExportHistory(params) {
		return genericProxyRequestApi.postRequest({}, serialize(params, '?'));
	},
	getAudienceCountForContactList(params) {
		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
	getProgressBarStatus(contactListId) {
		const params = {
			contactListId,
			targetApi: 'getProgressBarStatus',
		};

		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
	getContactById(params) {
		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
	renameContactList(params) {
		return genericProxyRequestApi.postRequest({}, serialize(params, '?'));
	},
	getContactMetadataById(contactMetadataId) {
		const params = {
			targetApi: 'getContactMetadataById',
			contactMetadataId,
		};

		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
};
