import { ICommonStateInterface } from './state';

const postHogConfigsReducer = {
	enablePostHog: (state: ICommonStateInterface) => {
		state.postHogConfigs.isPostHogEnabled = true;
	},
	disablePostHog: (state: ICommonStateInterface) => {
		state.postHogConfigs.isPostHogEnabled = false;
	},
};

const commonReducer = {
	openMobileMenu: (state: ICommonStateInterface) => {
		state.isMobileMenuOpen = true;
	},
	closeMobileMenu: (state: ICommonStateInterface) => {
		state.isMobileMenuOpen = false;
	},

	...postHogConfigsReducer,
};

export { commonReducer };
