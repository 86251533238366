import loadable from '@loadable/component';

import { IRouteProps } from 'shared/consts/types';

import { NoAuthenticationRouteLinks } from './links/NoAuthenticationRouteLinks';

const shopifyV2RegistrationPage = loadable(
	() => import('pages/ShopifyOnboardingV2/Registration/Registration' /* webpackChunkName: "ShopifyV2Registration" */)
);

const LoginPage = loadable(() => import('pages/OnBoarding/Login' /* webpackChunkName: "LoginPage" */));

const InstanceLoginRedirectPage = loadable(
	() => import('pages/OnBoarding/LoginInstance' /* webpackChunkName: "InstanceLoginRedirection" */)
);

const GetStartedPage = loadable(() => import('pages/OnBoarding/GetStarted' /* webpackChunkName: "GetStarted" */));

const WhatsappGetStarted = loadable(
	() => import('pages/OnBoarding/Whatsapp/WAGetStarted' /* webpackChunkName: "WhatsappGetStarted" */)
);

const ForgotPasswordPage = loadable(
	() => import('pages/OnBoarding/ForgotPassword' /* webpackChunkName: "ForgotPassword" */)
);

const resetLinkSentPage = loadable(
	() => import('pages/OnBoarding/ResetPasswordLinkSent' /* webpackChunkName: "ResetPasswordLinkSent" */)
);

const resetPasswordPage = loadable(
	() => import('pages/OnBoarding/ResetPassword' /* webpackChunkName: "ResetPassword" */)
);

const verificationLinkExpiredPage = loadable(
	() => import('pages/OnBoarding/VerificationLinkExpired' /* webpackChunkName: "VerifyAccountExpired" */)
);

const whiteLabelBrandingErrorPage = loadable(
	() => import('pages/OnBoarding/WhiteLabelBrandingError' /* webpackChunkName: "whiteLabelBrandingErrorPage" */)
);

const {
	shopifyV2Registration,
	wooCommerceV2Registration,
	portalLogin,
	portalLoginInstance,
	portalGetStartedLink,
	preferredPartnerLink,
	portalForgotPwdLink,
	portalResetLink,
	portalResetPassword,
	verificationLinkExpired,
	whatsappGetStarted,
	whiteLabelBrandingError,
} = NoAuthenticationRouteLinks;

export const NoAuthenticationRoutes: Array<IRouteProps> = [
	{
		path: shopifyV2Registration,
		component: shopifyV2RegistrationPage,
	},
	{
		path: wooCommerceV2Registration,
		component: shopifyV2RegistrationPage,
	},
	{
		path: portalLogin,
		component: LoginPage,
	},
	{
		path: portalLoginInstance,
		component: InstanceLoginRedirectPage,
	},
	{
		path: preferredPartnerLink,
		component: GetStartedPage,
	},
	{
		path: portalGetStartedLink,
		component: GetStartedPage,
	},
	{
		path: whatsappGetStarted,
		component: WhatsappGetStarted,
	},
	{
		path: portalForgotPwdLink,
		component: ForgotPasswordPage,
	},
	{
		path: portalResetLink,
		component: resetLinkSentPage,
	},
	{
		path: portalResetPassword,
		component: resetPasswordPage,
	},
	{
		path: verificationLinkExpired,
		component: verificationLinkExpiredPage,
	},
	{
		path: whiteLabelBrandingError,
		component: whiteLabelBrandingErrorPage,
	},
];
