import { useEffect } from 'react';
import { useStyletron } from 'baseui';
import { SIZE, Value } from 'baseui/select';
import { useFormContext, useWatch } from 'react-hook-form';

import ENGTSelect, { SelectOptionsType } from 'components/UI/ENGTSelect/ENGTSelect';

import { IObjProps } from 'shared/consts/types';
import { getErrorMessage } from 'shared/helpers';

import FormLabel from '../FormLabel/FormLabel';

interface IFormSelectProps {
	clearable?: boolean;
	createable?: boolean;
	disabled?: boolean;
	defaultValue?: string;
	dropDownListItemHeight?: number;
	errorCondition?: any;
	inputRef: any;
	isLoading?: boolean;
	label?: string;
	multi?: boolean;
	options: SelectOptionsType;
	overrides?: IObjProps;
	placeholder?: string;
	searchable?: boolean;
	selectedValue: Value;
	tooltip?: string;
	width?: string;
	popupIndex?: number;
	numberOfDropDownItems?: number;
	name: string;
	required?: boolean;
	marginBottom?: string;
	marginLeft?: string;
	fontSize?: string;
	labelClassName?: IObjProps;
	size?: keyof SIZE;
	type?: any;
	openOnClick?: boolean;
	filterOptions?: (value: any) => any;
	filterOutSelected?: boolean;
	modal?: boolean;
	showDropdownIcons?: boolean;
	noResultsMsg?: any;
	customComponent?: any;
	customError?: IObjProps;
	dataQa?: string;
	isRequired?: boolean;
	autoFocus?: boolean;
	onChange: (...args: any[]) => any;
	onInputChange?: (...args: any[]) => any;
	getOptionLabel?: (value: any) => any;
}

function FormSelect({
	clearable,
	createable,
	disabled,
	defaultValue,
	dropDownListItemHeight,
	errorCondition,
	fontSize,
	inputRef,
	label,
	multi,
	openOnClick,
	name,
	onChange,
	onInputChange,
	options,
	overrides,
	placeholder,
	searchable,
	tooltip,
	width,
	type,
	filterOptions,
	filterOutSelected,
	popupIndex,
	marginBottom = '1.25rem',
	marginLeft,
	required,
	selectedValue,
	size,
	modal,
	numberOfDropDownItems = 5,
	labelClassName,
	showDropdownIcons,
	noResultsMsg,
	customComponent,
	customError,
	dataQa,
	isRequired = false,
	autoFocus = false,
	...inputProps
}: IFormSelectProps) {
	const [css] = useStyletron();

	const LabelWrapper = () =>
		label && (
			<FormLabel
				id={label}
				label={label}
				tooltip={tooltip}
				className={labelClassName}
				modal={modal || false}
				isRequired={isRequired}
			/>
		);

	const { control, errors, setValue } = useFormContext();

	useEffect(() => {
		defaultValue && setValue && setValue(name, defaultValue);
	}, [defaultValue]);

	useWatch({
		control,
		name,
	});

	const errorMsg = getErrorMessage(name, errors, true) ? getErrorMessage(name, errors, true) : errors[name];

	return (
		<div
			className={css({
				textAlign: 'left',
				marginBottom,
			})}
		>
			{LabelWrapper()}
			<ENGTSelect
				inputRef={inputRef}
				onChange={onChange}
				onInputChange={onInputChange}
				options={options}
				placeholder={placeholder || ''}
				popupIndex={popupIndex}
				selectedValue={selectedValue}
				numberOfDropDownItems={numberOfDropDownItems}
				width={width}
				error={customError?.message ? customError?.message : errorMsg}
				searchable={searchable || false}
				multi={multi || false}
				disabled={disabled || false}
				createable={createable || false}
				clearable={clearable || false}
				marginLeft={marginLeft}
				required={required || false}
				size={size}
				type={type}
				filterOptions={filterOptions}
				filterOutSelected={filterOutSelected}
				openOnClick={openOnClick}
				showDropIcons={showDropdownIcons}
				dropDownListItemHeight={dropDownListItemHeight || 47}
				overrides={overrides}
				showDropdownIcons={showDropdownIcons}
				noResultsMsg={noResultsMsg}
				customComponentPassed={customComponent}
				customErrorMessage={customError}
				autoFocus={autoFocus}
				dataQa={dataQa}
				{...inputProps}
			/>
		</div>
	);
}

export default FormSelect;
