import { useStyletron } from 'baseui';

import { ANALYTICS } from 'shared/consts/QAConsts';

function Analytics({ className, size, fillColor }: { className?: string; size?: number; fillColor?: string }) {
	const [, theme]: any = useStyletron();

	return (
		<svg
			width={size || 40}
			height={size || 40}
			viewBox='0 0 40 40'
			fill='none'
			className={className}
			data-qa={ANALYTICS}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='inherit'>
				<path
					opacity='0.7'
					d='M14.9986 17.8566H10.7137C10.3203 17.8566 10.0023 18.1746 9.99811 18.5721V29.2845C9.99811 29.682 10.3203 30 10.7137 30H14.9986C15.3961 30 15.7141 29.6778 15.7141 29.2845V18.5721C15.7141 18.1746 15.3919 17.8566 14.9986 17.8566Z'
					fill={theme.colors.primaryA}
				/>
				<path
					d='M22.1415 10.0023H17.8566C17.4633 10.0023 17.1411 10.3203 17.1411 10.7137V29.2844C17.1411 29.682 17.4633 30 17.8566 30H22.1415C22.5391 30 22.8571 29.6778 22.8571 29.2844V10.7178C22.8571 10.3203 22.5349 10.0023 22.1415 10.0023Z'
					fill={theme.colors.primaryA}
				/>
				<path
					opacity='0.7'
					d='M29.2845 15.7141H24.9996C24.6021 15.7141 24.2841 16.0321 24.2841 16.4297V29.2844C24.2841 29.682 24.6063 30 24.9996 30H29.2845C29.6821 30 30.0001 29.6778 30.0001 29.2844V16.4297C30.0001 16.0321 29.6779 15.7141 29.2845 15.7141Z'
					fill={theme.colors.primaryA}
				/>
			</g>
		</svg>
	);
}

export default Analytics;
