import { createDarkTheme } from 'baseui';

import { THEME_COLOR_OPTIONS } from 'shared/enum';
import { hexToRgb } from 'shared/helpers';

import { DefaultDark } from './dark/ENGTDefaultDark';
import { EarthenHuesDark } from './dark/ENGTEathenHuesDark';
import { SkyDark } from './dark/ENGTSkyDark';

export interface ICustomThemeParams {
	inputSecondaryFillColor: string;
}

export interface IBrandingColors {
	themeColor: string;
	fontStyle: string;
	themePack: string;
}

let primitives: any = {};

const getPrimitives: any = (props: IBrandingColors) => {
	const { themeColor, fontStyle } = props;

	primitives = {
		primaryA: '#FFFFFF',
		primaryB: '#3E3F44',
		primaryBtnTextColor: '#FFFFFF',
		primaryTextColor: '#FFFFFF',
		backgroundPrimary: '#2B2C30',
		backgroundSecondary: '#3E3F44',
		backgroundTertiary2: '#2B2C30',
		accent: themeColor || '#AE1536',
		contentStateDisabled: '#C3C3C3',
		primaryFontFamily: fontStyle,
		accent50: '#767477',
		accent100: '#E8E6E7',
		accent200: '#6BDA76',
		accent300: '#F3F1F1',
		accent400: '#C4C4C4',
		accent500: '#DADADA',
		dotPendingFill: '#AE1536',
		dotStatusFill: '#A1D946',
		inputErrorBorder: '#AE1536',
		inputFillPrimary: '#25262A',
		inputPrimary: '#FFFFFF',
		inputPlaceholderColor: '#FFFFFF',
		inputBorderColor: '#25262A',
		inputDisabledBgColor: '#3E3F44',
		inputProgressBarColor: 'rgba(238, 39, 11, 0.4)',
		primaryBtnFontColor: '#403F42',
		focusedInputBorderColor: 'rgba(208, 208, 208, 0.49)',
		modalOverlayColor: '#000',
		horizantalDividerColor: 'rgba(213, 212, 212, 0.4)',
		pathBuilderPatternDotColor: 'rgba(196, 196, 196, 0.2)',
		defaultPrimaryAColor: '#403F42',
		navIconFillColor: '#FFFFFF',
		addNodeModalTabsBg: '#3E3F44',
		quickGuideBg: hexToRgb('#2B2C30', '0.7'),
		themeMode: 'DARK',
		engatiYellow: '#FFAC0A',
		engatiOrange2: '#EE5D0B',
		engatiPurple: '#D993D6',
		engatiGreen: '#35A541',
		engatiBlue: '#77CCD1',
		engatiLavender: '#9E87DF',
		raspberry: '#C13584',
		royalBlue: '#316FF6',
		categoryGreen: '#A1D94666',
		tags: '#AE15361A',
		noCategory: '#C4C4C466',
		engatiRedFill: 'rgba(174, 21, 54, 0.2)',
		transactionRed: '#F72A25',
		transactionGreen: '#4CAF50',
		whatsAppBackground: '#ECE3D9',
		whatsappDeploymentBackground: '#128C7E05',
	};

	return primitives;
};

const getOverrides = (props: IBrandingColors) => {
	const {
		accent,
		backgroundPrimary,
		backgroundSecondary,
		contentStateDisabled,
		dotPendingFill,
		dotStatusFill,
		engatiBlue,
		engatiYellow,
		inputErrorBorder,
		primaryA,
		primaryB,
		primaryBtnTextColor,
		horizantalDividerColor,
	} = getPrimitives(props);
	const { fontStyle } = props;

	return {
		colors: {
			advancedHorizontalDivider: horizantalDividerColor,
			backgroundPrimary,
			buttonDisabledFill: contentStateDisabled,
			buttonDisabledText: backgroundPrimary,
			buttonPrimaryActive: accent,
			buttonPrimaryFill: accent,
			buttonPrimaryHover: accent,
			buttonPrimaryText: primaryBtnTextColor,
			buttonSecondaryActive: backgroundSecondary,
			buttonSecondaryFill: backgroundSecondary,
			buttonSecondaryText: primaryA,
			buttonSecondaryHover: backgroundSecondary,
			buttonTertiaryActive: primaryB,
			buttonTertiaryFill: primaryB,
			buttonTertiaryHover: primaryB,
			buttonTertiaryText: primaryA,
			buttonMinimalActive: primaryB,
			buttonMinimalFill: primaryB,
			buttonMinimalHover: primaryB,
			buttonMinimalText: primaryA,
			channelBannerText: '#403F42',
			modalCloseColor: primaryB,
			tableBackground: '#3A3B40',
			tickBorder: primaryB,
			tickFill: primaryB,
			tickFillActive: accent,
			tickFillDisabled: contentStateDisabled,
			tickFillHover: primaryB,
			tickFillSelected: accent,
			tickFillSelectedHover: accent,
			tickFillSelectedHoverActive: accent,
			tickMarkFillDisabled: backgroundPrimary,
			inputBorderError: inputErrorBorder,
			borderSelected: accent,
			dotPendingFill,
			dotStatusFill,
			botBuilderLine: '#FFFFFF',
			botBuilderSelectedLine: '#A1D946',
			botBuilderConnectionModeLine: '#FFFFFF',
			botBuilderFromCircle: '#FFFFFF',
			botBuilderNodeInnerFill: '#4A4A4F',
			linkText: primaryA,
			linkHover: primaryA,
			channelStatsSuccess: engatiBlue,
			channelStatsFailure: engatiYellow,
			graphBackgroundColor: primaryB,
			filterIconBgColor: primaryB,
			filterBackgroundColor: backgroundPrimary,
			filterHeaderBgColor: primaryB,
			goToSettingsBtnColor: '#3E3F44',
			infoBadge: '#D993D666',
			messageOverviewBgColor: '#313236',
			userDetailsBgColor: '#3A3B40',
			noteBackgroundColor: '#EAE7E7',
			buttonFadedHightlight: hexToRgb(primaryA, '1.0'),
		},

		grid: {
			margins: ['10px', '10px', '10px'],
			gutters: ['10px', '10px', '10px'],
		},
		sizing: {
			scale50: '2px',
		},
		typography: {
			MonoLabelXSmall: {
				fontFamily: fontStyle,
			},
			ParagraphSmall: {
				fontFamily: fontStyle,
			},
			MonoLabelSmall: {
				fontFamily: fontStyle,
			},
			HeadingXLarge: {
				fontFamily: fontStyle,
				fontWeight: '700',
			},
			LabelMedium: {
				fontFamily: fontStyle,
				fontWeight: 400,
			},
			MonoLabelMedium: {
				fontFamily: fontStyle,
			},
		},
	};
};

const getInboxColors = (props: IBrandingColors) => {
	const { themePack } = props;
	const { EARTHEN_HUES, SKY_LIGHT } = THEME_COLOR_OPTIONS;

	switch (themePack) {
		case SKY_LIGHT:
			return SkyDark();
		case EARTHEN_HUES:
			return EarthenHuesDark();
		default:
			return DefaultDark();
	}
};

const DarkTheme = (props: IBrandingColors) => createDarkTheme(getPrimitives(props), getOverrides(props));

const ENGTDarkTheme = (props: IBrandingColors) => {
	const {
		accent50,
		accent100,
		accent300,
		backgroundSecondary,
		backgroundPrimary,
		engatiBlue,
		engatiOrange2,
		engatiGreen,
		engatiYellow,
		inputFillPrimary,
		primaryA,
		primaryB,
		horizantalDividerColor,
		engatiPurple,
		defaultPrimaryAColor,
	} = getPrimitives(props);

	const themePackColors = getInboxColors(props);

	return {
		...DarkTheme(props),
		cancelCircularBackground: '#3E3F4480',
		addOnBtnBackgroundColor: '#25262A',
		addNodePlaceHolderColor: '#959296',
		backgroundPrimaryFaded: 'rgba(255, 255, 255, 0.91)',
		backgroundSecondaryFaded: 'rgba(232, 230, 231, 0.4)',
		builderCanvasFillColor: '#2F3034',
		builderCanvasActionCard: '#2F3034',
		dividerDarkColor: '#D5D4D4',
		inputPlaceHolder: '#FFFFFF',
		inputCaretColor: '#AE1536',
		emailMessages: {
			page: {
				agent: themePackColors.backgroundPrimary,
				agentAttachmentFocus: backgroundPrimary,
				user: themePackColors.backgroundSecondary,
				userAttachmentFocus: backgroundSecondary,
				text: primaryA,
				icon: primaryA,
				secondaryText: '#B2B2B4',
				jumpToLatestBgColor: primaryA,
				jumpToLatestText: primaryB,
			},
			modal: {
				agent: backgroundSecondary,
				agentAttachmentFocus: accent100,
				user: accent100,
				userAttachmentFocus: backgroundSecondary,
				text: primaryB,
				icon: primaryB,
				secondaryText: accent50,
				jumpToLatestBgColor: primaryB,
				jumpToLatestText: primaryA,
			},
		},
		engatiOrange: '#FF5000',
		graphColors: {
			colorA: engatiYellow,
			colorB: engatiOrange2,
			colorC: engatiGreen,
			colorD: engatiPurple,
		},
		instagramReplyMode: {
			privateReplyColor: `${engatiYellow} !important`,
			userCommentReply: `${engatiBlue} !important`,
		},
		botMessageBgColor: backgroundSecondary,
		inputErrorMsgColor: '#AE1536',
		inputSecondaryFillColor: '#3E3F44',
		inputFillModal: '#3E3F44',
		inputFillPrimary,
		intentTagColor: '#D993D6',
		acquisitionTagColor: engatiBlue,
		retentionTagColor: engatiYellow,
		retargetingTagColor: engatiOrange2,
		tagColor: '#696969',
		tagOneviewColor: '#00ffff',
		inviteAgentOwnerBgColor: engatiOrange2,
		labelUnderLine: 'rgb(0,0,0,0.1)',
		loginPages: {
			text: '#403f42',
			whiteText: '#ffffff',
			loginFormBg: '#FBFBFB',
			otpCode: '#9A9A9A',
			pageBackground: '#FFFFFF',
			phoneNumberInfo: '#0271AF',
			loginUsingTextColor: '#979797',
			footerText: '#FFC7C7',
			inputBotder: '#7d7d7d',
			partnerMarketingBg: '#f2f2f2',
			heading: '#FFFFFFCC',
			footer: '#FFFFFF80',
		},
		messageListBgColor: '#313236',
		modalCloseBgColor: '#3E3F44',
		modalCloseStrokeColor: '#FFFFFF',
		modalPrimaryBackground: '#2B2C30 !important',
		modalSecondaryBackground: backgroundPrimary,
		modalTextColor: '#FFFFFF !important',
		modalHeaderColor: '#FFFFFF !important',
		navigationBorderColor: horizantalDividerColor,
		primaryHighlightColor: engatiOrange2,
		primaryNavBackground: '#3E3F44',
		primaryNavText: '#B2B2B4',
		pricingPlanCardBgColor: '#3E3F44',
		quickNavigator: {
			backgroundPrimary: '#252629',
			backgroundSecondary: primaryB,
			primaryWhite: primaryA,
			primaryDark: defaultPrimaryAColor,
		},
		quotedMessageFocusColor: inputFillPrimary,
		quotedUserMessageBgColor: '#F9F8F8',
		quotedAgentMessageBgColor: '#D6D6D6',
		radius250: '6px',
		radius350: '8px',
		recentConvBotMsgBg: backgroundSecondary,
		recentConvAgentMsgBg: backgroundSecondary,
		recentConvIconFill: primaryB,
		recentConvMsgFocus: accent100,
		responseTrained: '#3ECD5D',
		sentimentNegativeColor: '#DA2F47',
		sentimentPositiveColor: '#53D503',
		sentimentNeutralColor: '#FECE00',
		shopifyAccordionBackgroundColor: '#000000',
		shopifyVerifiedBackgroundColor: engatiOrange2,
		toasterBackgroundColor: '#FFFFFF',
		toasterTextColor: '#403F42',
		userDetailsBgcolor: '#3A3B40',
		userMessageBgcolor: backgroundPrimary,
		userProfileColor: '#403F42',
		upgradeMarkerBgColor: '#E96E53',
		videoLayoutColours: {
			agentNameColor: '#403F42',
			agentNameBackgroundColor: '#C2C2C2',
			backgroundColor: '#403F42',
			chatBoxIcon: '#F3F1F1',
			msgBackgroundColor: '#252629',
			messageInputBoxBorder: accent300,
			tooltipBackgroundColor: '#E8E6E7',
			tooltipTextColor: '#252629',
			userNameColor: '#FFFFFF',
		},
		whatsApp: {
			bubbleColor: '#DCF8C6',
			textColor: '#7A8784',
			backgroundColor: '#E2DAD3',
			headerColor: '#128C7E',
			carouselContainerColor: '#FFFFFF',
			descriptionColor: '#403F42',
			headerTextColor: '#FFFFFF',
		},
		whatsAppManager: {
			availableWithoutReview: '#E9B6534D',
			approved: '#3ECD5D4D',
			rejected: '#EE190B4D',
		},
		zIndex100: 1,
		zIndex200: 2,
		zIndex300: 3,
		zIndex400: 4,
		zIndex500: 5,
		zIndex600: 6,
		zIndexToolTip: 999,
		zIndexTop: 1000,
		inProgress: '#E9B653',
		failure: '#AE1536',
		success: '#3ECD5D',
		rejected: '#EE5D0B',
		completedIcon: '#3ECD5D',
		preparingIcon: '#E2CDE1',
		stoppedIcon: '#EE5D0B',
		retryingIcon: '#77CCD1',
		inProgressIcon: '#E9B653',
		sentToWhatsApp: '#D993D6',
		datePickerInputBorder: '#252629',
		templateMessagePopUpBorder: '#e0e0e0',
		agentTag: '#C7E9EB',
		whatsappBtnColor: '#53BDEB',
		templateBackground: '#767477',
		orangeInLightAndWhiteInDarkTheme: '#FFFFFF',
		ENGTInlineEditorBorderColor: '#D6D6D6',
		inbox: themePackColors,
		skeletonBackgroundImage:
			'linear-gradient(135deg, rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(200, 200, 200), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255))',
		buttonHoverColor: '#AFAFAF',
		successCellBackgroundColor: '#E1F2C5',
		errorCellBackgroundColor: '#E5B7C1',
		discountIndicatorColor: '#B88AE6',
		cloudIntegrationsConnectedAccountsBackground: '#E3F1E8',
	};
};

export default ENGTDarkTheme;
