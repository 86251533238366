export const TwoColumnRouteLinks: any = {
	builderLink: '/builder',
	buidlerFlowLink: '/builder/:flowKey',
	landingPageLink: '/bot/:id/overview',
	userDetailsLink: '/users/home/list/:globalUserId',
	usersLink: '/users',
	usersHomeLink: '/users/home',
	usersListLink: '/users/home/list',
	usersSegmentLink: '/users/home/segments',
	usersImportHistoryLink: '/users/home/import-history',
	usersExportHistoryLink: '/users/home/export-history',
	usersContactsLink: '/users/contacts',
	usersContactsListLink: '/users/contacts/list',
	usersContactsImportHistory: '/users/contacts/import-history',
	agentDetailsLink: '/analytics/livechat/agent-productivity/:userId',
	messagingHome: '/messages',
	messagingTab: '/messages/:tab',
	messagingConverastionTab: ':cid',
	simplifiedMessages: '/allmessages/',
	simplifiedMessagesConversation: ':cid',
	store: '/store',
	quickStartGuide: '/quickGuide',
	quickNavigator: '/quick-navigator-ecommerce',
	acceleratorQuickNavigator: '/accelerator-quick-guide-ecom',
	quickNavigatorBasicSetupLink: '/quick-navigator-ecommerce/basic-setup',
	acceleratorQuickNavigatorBasicSetupLink: '/accelerator-quick-guide-ecom/basic-setup',
	quickNavigatorFeatureExplorerLink: '/quick-navigator-ecommerce/feature-explorer',
	acceleratorQuickNavigatorFeatureExplorerLink: '/accelerator-quick-guide-ecom/feature-explorer',
	teamLink: '/team',
	teamMembersLink: '/account-settings/team-members',
	waQuickNavigator: '/wa-quick-guide',
};
