import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { ONEVIEW_SCREENS } from 'shared/consts/SocialInbox/constants';

import {
	currentOneViewScreenSelector,
	isFbInboxCommentsEnabledSelector,
	isInstaInboxCommentsEnabledSelector,
} from 'store/Message/PostListAndOverview/selectors';
import { RootState } from 'store/rootReducer';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

function MessagesRedirection() {
	const customerEngagePlan: any = useSelector((state: RootState) => state.User.botAdmin.data.customerEngagePlan);
	const isExternalLivechatEnabled = useSelector(
		(state: RootState) => state.Bot.configData.is_external_livechat_enabled
	);
	const isSimplifiedOneViewEnabled = useSelector(
		(state: RootState) => state.User.botAdmin.data.is_simplified_one_view_enabled
	);
	const currentOneViewScreen = useSelector(currentOneViewScreenSelector);
	const isInstaInboxCommentsEnabled: boolean = useSelector(isInstaInboxCommentsEnabledSelector);
	const isFbInboxCommentsEnabled: boolean = useSelector(isFbInboxCommentsEnabledSelector);
	const isNeutralScreenEnabled = useSelector((state: RootState) => state.User.botAdmin.data.enable_neutral_screen);

	const redirectToMessages = () =>
		isSimplifiedOneViewEnabled ? (
			<Navigate to={`../allmessages/${isNeutralScreenEnabled ? '?neutralScreen=true' : ''}`} replace />
		) : customerEngagePlan && customerEngagePlan === 'ENGATI_LIVECHAT' && !isExternalLivechatEnabled ? (
			<Navigate to={`/messages/active/${isNeutralScreenEnabled ? '?neutralScreen=true' : ''}`} replace />
		) : (
			<Navigate to={`/messages/all/${isNeutralScreenEnabled ? '?neutralScreen=true' : ''}`} replace />
		);

	return ONEVIEW_SCREENS.MESSAGES !== currentOneViewScreen ? (
		ONEVIEW_SCREENS.INSTAGRAM_COMMENTS === currentOneViewScreen && isInstaInboxCommentsEnabled ? (
			<Navigate to={ThreeColumnRouteLinks.socialInboxInstagram} />
		) : ONEVIEW_SCREENS.FACEBOOK_COMMENTS === currentOneViewScreen && isFbInboxCommentsEnabled ? (
			<Navigate to={ThreeColumnRouteLinks.socialInboxFacebook} />
		) : (
			redirectToMessages()
		)
	) : (
		redirectToMessages()
	);
}

export default MessagesRedirection;
