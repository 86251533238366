import { QUICK_START_GUIDE } from 'shared/consts/QAConsts';

function Home({
	className,
	size,
	fillColor,
	strokeColor,
}: {
	className?: string;
	size?: number;
	fillColor?: string;
	strokeColor?: string;
}) {
	return (
		<svg
			width={size || 32}
			height={size || 32}
			viewBox='0 0 32 32'
			className={className}
			data-qa={QUICK_START_GUIDE}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M16 25C20.9706 25 25 20.9706 25 16C25 11.0294 20.9706 7 16 7C11.0294 7 7 11.0294 7 16C7 20.9706 11.0294 25 16 25Z'
				fill={fillColor || '#403F42'}
			/>
			<path
				d='M13 14.0629C13.242 13.3751 13.7195 12.7951 14.3482 12.4257C14.9768 12.0562 15.7158 11.9212 16.4345 12.0444C17.1531 12.1677 17.805 12.5413 18.2745 13.0991C18.7441 13.657 19.0011 14.3629 19 15.0921C19 17.1504 15.9125 18.1796 15.9125 18.1796'
				stroke={strokeColor || '#F3F1F1'}
				strokeWidth='1.6'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M15.9951 21.1797H16.0066'
				stroke={strokeColor || '#F3F1F1'}
				strokeWidth='1.6'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default Home;
