import { useSelector } from 'react-redux';

import { isShopifyEnabledAcc, isWhatsappCampaignAnalyticsEnabledSelector } from 'store/App/User/selectors';

function useIsCampaignAnalyticsEnabledHook(): boolean {
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const isWhatsappCampaignAnalyticsEnabled = useSelector(isWhatsappCampaignAnalyticsEnabledSelector);

	return isWhatsappCampaignAnalyticsEnabled && isShopifyEnabled;
}
export default useIsCampaignAnalyticsEnabledHook;
