import { NAVIGATIONAL_INDICATOR } from 'shared/consts/QAConsts';

const NavigationIndicator = ({
	className,
	size,
	fillColor,
}: {
	className?: string;
	size?: number;
	fillColor?: string;
}) => (
	<svg
		width={size || 18}
		height={size || 49}
		viewBox='0 0 18 49'
		className={className}
		data-qa={NAVIGATIONAL_INDICATOR}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M5.63024e-06 5.98033C5.65375e-06 6.51817 0.204497 7.05601 0.616282 7.46499C1.43705 8.28576 2.76765 8.28576 3.58842 7.46499L7.46536 3.58805C8.28612 2.76728 8.28612 1.43668 7.46536 0.615916C6.64459 -0.204852 5.31399 -0.204852 4.49322 0.615916L0.616282 4.49286C0.207298 4.90464 5.60673e-06 5.44249 5.63024e-06 5.98033Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			d='M7.6255 24.3864C7.6255 25.5461 8.56672 26.4873 9.72644 26.4873L15.2085 26.4873C16.3682 26.4873 17.3094 25.5461 17.3094 24.3864C17.3094 23.2266 16.3682 22.2854 15.2085 22.2854L9.72644 22.2854C8.56672 22.2854 7.6255 23.2266 7.6255 24.3864Z'
			fill={fillColor || '#AE1536'}
		/>
		<path
			d='M5.46077e-06 42.7948C5.48428e-06 43.3326 0.204497 43.8705 0.616282 44.2795L4.49322 48.1564C5.31399 48.9772 6.64459 48.9772 7.46536 48.1564C8.28612 47.3356 8.28612 46.005 7.46536 45.1843L3.58842 41.3073C2.76765 40.4866 1.43705 40.4866 0.616282 41.3073C0.207298 41.7219 5.43727e-06 42.2569 5.46077e-06 42.7948Z'
			fill={fillColor || '#AE1536'}
		/>
	</svg>
);

export default NavigationIndicator;
