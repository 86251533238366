import React, { useEffect, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { PLACEMENT, Popover, TRIGGER_TYPE } from 'baseui/popover';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';
import ENGTCheckBox from 'components/UI/ENGTCheckBox/ENGTCheckBox';
import ModalAlert from 'components/UI/Modal/ModalAlert/ModalAlert';

import { MESSAGE_TAB_HEADERS_MAP } from 'shared/consts/consts';
import { GO_TO_CONVERSATION } from 'shared/consts/QAConsts';
import { IObjProps } from 'shared/consts/types';
import { BOT_MODE, getResponsiveVH, isValidResponseObject } from 'shared/helpers';
import { conversationIdCustomFilterSelected } from 'shared/helpers/messagesHelper';
import FilledConfigureIcon from 'shared/icons/FilledConfigureIcon';
import NotificationIcon from 'shared/icons/NotificationIcon';

import { botSelect } from 'store/App/Bot/actions';
import { getBotListAction } from 'store/App/BotList';
import { jitsiActions } from 'store/App/Jitsi';
import {
	clearNotificationsAction,
	notificationFilterTypes,
	removeNotificationAction,
	toggleNotificaitonTrayAction,
	updateNotificationAction,
} from 'store/App/Notifications';
import { themeValueSelector } from 'store/App/Preferences/selectors';
import {
	getFacetsSortFromLs,
	getLiveChatUsersAction,
	getNotificationClickUserAction,
	setLiteFacetsSortInLs,
	setLiteQuickFiltersInLs,
	setSelectedUserAction,
	showRightPanel,
} from 'store/Message';
import { messageListActions } from 'store/Message/messageList/actions';
import { messageListAPI } from 'store/Message/messageList/api';
import { selectedUserSelector } from 'store/Message/messageList/selectors';
import { RootState } from 'store/rootReducer';
import { isEngatiBrandSelector } from 'store/Users/selectors';

function Notifications() {
	const [css, theme]: any = useStyletron();
	const dispatch = useDispatch<any>();
	const { t } = useTranslation(['common', 'components']);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { search } = useLocation();
	const modal: string = new URLSearchParams(search).get('modal') || '';
	const convId: string = new URLSearchParams(search).get('convId') || '';
	const convUrl: string = new URLSearchParams(search).get('convUrl') || '';
	const notifBotRefParam: string = new URLSearchParams(search).get('notifBotRef') || '-1';
	const notifBotRef: number = parseInt(notifBotRefParam);

	const isNotifcationTrayOpen = useSelector((state: RootState) => state.Notifications.isOpen);

	const notificationList = useSelector((state: RootState) => state.Notifications.data);

	const selectedUser = useSelector(selectedUserSelector);
	const userList = useSelector((state: RootState) => state.Message.userList);
	const { active: ACTIVE_TAB, all: ALL_TAB, unassigned: UNASSIGNED_TAB, new: NEW_TAB } = MESSAGE_TAB_HEADERS_MAP;
	const botRef = useSelector((state: RootState) => state.Bot.data?.bot_ref);
	const allBots = useSelector((state: RootState) => state.BotList.data);
	const activeModule = useSelector((state: RootState) => state.User.botAdmin.data.active_module);
	const masterBotRef = useSelector((state: RootState) => state.Bot.data.master_bot_ref);
	const cid = useSelector((state: RootState) => state.User.botAdmin.data.cid);
	const botMode = useSelector((state: RootState) => state.Bot.data.mode);
	const liveBotRef = useSelector((state: RootState) => state.Bot.data?.live_bot_ref);
	const themeValue = useSelector(themeValueSelector);

	const [isNotificationModalOpen, toggleNotificationModal] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(false);
	const isSimplifiedOneViewEnabled = useSelector(
		(state: RootState) => state.User.botAdmin.data.is_simplified_one_view_enabled
	);

	const uid = useSelector((state: RootState) => state.User.botAdmin.data.uid);
	const [pauseNotifcation, setPauseNotification] = useState(
		false || localStorage.getItem(`pausednotification_${uid}`) === 'true'
	);
	const bannerTrack = localStorage.getItem('trackBanner');
	const overview_route = matchPath(`/bot/${botRef}/overview`, pathname);
	const messages_route = matchPath('/messages/', pathname);
	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const bannerVisibility = isEngatiBrand && bannerTrack === 'true' && (overview_route || messages_route);

	const notificationModalContextRef = useRef<{
		conversationId: string;
		url: string;
		botRef: number;
		heading: string;
		description: any;
	}>({
		conversationId: '',
		url: '',
		botRef: -1,
		heading: '',
		description: '',
	});
	const botRefNameMap = useRef<IObjProps>({});

	const forceClose = () => {
		dispatch(toggleNotificaitonTrayAction(false));
	};

	const toggleNotificationTray = () => {
		dispatch(toggleNotificaitonTrayAction());
	};

	const getSelectedBotLiveChatUsers = (tab: string) => {
		const filters: any = dispatch(getFacetsSortFromLs(tab));
		dispatch(getLiveChatUsersAction(filters, 1, tab));
		messageListAPI.getAllUsersCount().then((response: any) => {
			if (isValidResponseObject(response)) {
				const {
					allConversationCount,
					newConversationCount,
					unassignedConversationCount,
					activeConversationCount,
				} = response.data.responseObject;

				const data = {
					active: activeConversationCount,
					all: allConversationCount,
					new: newConversationCount,
					unassigned: unassignedConversationCount,
				};
				dispatch(messageListActions.setAllUserCountAction(data));
			}
		});
	};

	const iconWrapperCss = {
		backgroundColor: isNotifcationTrayOpen ? theme.colors.buttonDisabledFill : theme.colors.accent100,
		borderTopRightRadius: '50%',
		borderBottomRightRadius: '50%',
		borderTopLeftRadius: '50%',
		borderBottomLeftRadius: '50%',
		marginRight: '0.5rem',
		height: '2.5rem',
		width: '2.5rem',
		cursor: 'pointer',
		lineHeight: 3,
		':hover': {
			backgroundColor: theme.colors.buttonDisabledFill,
		},
	};

	const textCss = {
		fontStyle: 'normal',
		fontSize: '0.75rem',
		lineHeight: '1rem',
	};

	const titleCss = {
		...textCss,
		textOverflow: 'ellipsis',
		whiteSpace: 'break-spaces',
		overflow: 'hidden',
		marginBottom: '0.5rem',
		fontWeight: 'bold',
		'-webkit-box-orient': 'vertical',
		'-webkit-line-clamp': 2,
		display: '-webkit-box',
	};

	const descriptionCss = {
		...textCss,
		fontWeight: 'normal',
		marginBottom: '0.5rem',
		textOverflow: 'ellipsis',
		whiteSpace: 'break-spaces',
		overflow: 'hidden',
		'-webkit-box-orient': 'vertical',
		'-webkit-line-clamp': 3,
		display: '-webkit-box',
	};

	const goToConversationCss = {
		...textCss,
		textDecoration: 'underline',
		opacity: '0.5',
		display: 'flex',
		justifyContent: 'space-between',
	};

	const notificationCss = {
		backgroundColor: theme.colors.primaryB,
		backdropFilter: `blur(5.5px)`,
		boxShadow: 'rgb(0 0 0 / 17%) 8px 13px 33px 3px',
		borderTopWidth: '1px' as const,
		borderBottomWidth: '1px' as const,
		borderRightWidth: '1px' as const,
		borderLeftWidth: '1px' as const,
		borderTopStyle: 'solid' as const,
		borderRightStyle: 'solid' as const,
		borderBottomStyle: 'solid' as const,
		borderLeftStyle: 'solid' as const,
		borderTopColor: theme.colors.accent100,
		borderLeftColor: theme.colors.accent100,
		borderRightColor: theme.colors.accent100,
		borderBottomColor: theme.colors.accent100,
		borderTopRightRadius: '0.75rem' as const,
		borderTopLeftRadius: '0.75rem' as const,
		borderBottomRightRadius: '0.75rem' as const,
		borderBottomLeftRadius: '0.75rem' as const,
		cursor: 'pointer',
		marginBottom: '1rem' as const,
		marginRight: '0.5rem' as const,
		paddingTop: '0.75rem' as const,
		paddingBottom: '0.75rem' as const,
		paddingRight: '0.75rem' as const,
		paddingLeft: '0.75rem' as const,
	};

	const getTab = (location: any) => {
		let tab = null;
		if (location.indexOf('/messages/active') !== -1) {
			tab = ACTIVE_TAB;
		}
		if (location.indexOf('/messages/unassigned') !== -1) {
			tab = UNASSIGNED_TAB;
		}
		if (location.indexOf('/messages/new') !== -1) {
			tab = NEW_TAB;
		}
		if (location.indexOf('/messages/all') !== -1) {
			tab = ALL_TAB;
		}

		return tab;
	};

	const onNotificationClick = (
		conversationId: string,
		url: string,
		type: string = notificationFilterTypes.conversationId
	) => {
		dispatch(removeNotificationAction(type, conversationId));
		if (type === notificationFilterTypes.slaNonAdherence) {
			const filter = {
				botId: botRef,
				uid,
				cid,
				conversationId,
			};
			const conversationIdCustomFilter = conversationIdCustomFilterSelected(filter);

			if (isSimplifiedOneViewEnabled) {
				dispatch(
					setLiteQuickFiltersInLs({
						myConversations: false,
						unreadMessages: false,
						starredConversations: false,
						priority: true,
					})
				);
				dispatch(setLiteFacetsSortInLs(conversationIdCustomFilter));
				if (window.location.pathname.indexOf('allmessages') !== -1) {
					url += '?searchParam=filter_applied';
				}
			} else {
				localStorage.setItem(`all_${botRef}_${uid}`, JSON.stringify(conversationIdCustomFilter));
			}
		} else if (isSimplifiedOneViewEnabled && selectedUser.conversation_id !== conversationId) {
			dispatch(setSelectedUserAction({}));
		} else {
			const tab: any = getTab(url);
			const currentTab = getTab(window.location.pathname);
			if (tab === currentTab) {
				if (userList?.data?.some((item) => conversationId === item.conversation_id)) {
					if (selectedUser.conversation_id !== conversationId) {
						dispatch(setSelectedUserAction({}));
					}
				} else if (selectedUser?.conversation_id !== conversationId) {
					dispatch(getNotificationClickUserAction(conversationId, tab));
					dispatch(setSelectedUserAction({}));
				}
			} else {
				localStorage.setItem('notification_click_id', conversationId);
				localStorage.setItem('notification_click_tab', tab);
			}
		}
		navigate(url, { state: { clearSearchTerm: true } });
		toggleNotificationTray();
		dispatch(updateNotificationAction());
	};

	const renderNotification = (notification: any, index: number) => {
		const {
			conversationId,
			description,
			title,
			type,
			url,
			userName,
			videoConversationId,
			notificationId,
			notificationActionLabel,
		} = notification;

		let titleToDisplay = '';

		if (type === 'notifyNewMessages') {
			titleToDisplay = userName;
		} else {
			titleToDisplay = title;
		}

		const simplifiedNotificationCss = {
			marginTop: '0.875rem',
			borderRadius: '0.3125rem',
			padding: '0.5rem',
			':hover': {
				backgroundColor: theme.colors.backgroundPrimary,
				color: theme.colors.primaryA,
			},
		};

		const notificationMarkerCss = {
			height: '0.5rem',
			width: '0.5rem',
			backgroundColor: theme.colors.accent,
			borderTopLeftRadius: '50%',
			borderTopRightRadius: '50%',
			borderBottomLeftRadius: '50%',
			borderBottomRightRadius: '50%',
			marginTop: '0.25rem',
		};

		const notificationtitleCss = {
			width: '99%',
			...titleCss,
		};

		const simplifiedOnviewNotification = () => (
			<div className={css(simplifiedNotificationCss)} key={index}>
				<div className={css({ display: 'flex', flexWrap: 'nowrap' })}>
					<div className={css(notificationtitleCss)}>{titleToDisplay}</div>
					{notification.isLatest && <div className={css(notificationMarkerCss)} />}
				</div>
				<div className={css(descriptionCss)}>{description}</div>
				<div
					className={css(goToConversationCss)}
					onClick={() => {
						const modifiedBotRef = botMode === BOT_MODE.LIVE ? masterBotRef : botRef;
						if (
							modifiedBotRef &&
							notification?.botRef &&
							modifiedBotRef !== notification.botRef &&
							allBots.length
						) {
							notificationModalContextRef.current = {
								conversationId,
								url,
								botRef:
									notification.botRef === masterBotRef && botMode === BOT_MODE.DRAFT
										? liveBotRef
										: notification.botRef,
								heading: t('components:notifications.heading'),
								description: (
									<span>
										{t('components:notifications.description')}
										<b>{` ${botRefNameMap.current[notification.botRef] || ''}. `}</b>
										{t('components:notifications.viewConversation')}
									</span>
								),
							};
							toggleNotificationModal(true);
							toggleNotificationTray();
							localStorage.setItem('isFetchAgentData', JSON.stringify(true));

							return;
						}
						if (type === 'generalNotification') {
							if (title === 'Conversation Update') {
								onNotificationClick(conversationId, url, notificationFilterTypes.conversationId);
								dispatch(showRightPanel(true));
							} else if (title === 'SLA non-adherence') {
								onNotificationClick(conversationId, url, notificationFilterTypes.slaNonAdherence);
							} else {
								onNotificationClick(
									notificationId || conversationId,
									url,
									notificationFilterTypes.generalNotification
								);
							}
						} else {
							onNotificationClick(conversationId, url);
						}

						if (type === 'notifyGroupVideoConversationInvite') {
							dispatch(
								jitsiActions.setVideoConversationIdForUserAction(conversationId, videoConversationId)
							);
							navigate(url, { state: { clearSearchTerm: true } });
						}
						toggleNotificationTray();
					}}
				>
					<span data-qa={GO_TO_CONVERSATION}>{notificationActionLabel || t('common:goToConversation')}</span>
				</div>
			</div>
		);

		return simplifiedOnviewNotification();
	};

	const renderNotificationsIcon = (
		<div className={css(iconWrapperCss)}>
			<NotificationIcon fillColor='#3E3F44' />
		</div>
	);

	const renderNotificationDot = (
		<div
			className={css({
				position: 'absolute',
				top: '30%',
				left: '58%',
				transform: 'translate(-50%,-50%)',
				display: notificationList.length > 0 ? 'inline-block' : 'none',
				width: '0.5625rem',
				height: '0.5625rem',
				borderTopWidth: '0.1875rem',
				borderBottomWidth: '0.1875rem',
				borderRightWidth: '0.1875rem',
				borderLeftWidth: '0.1875rem',
				borderTopStyle: 'solid',
				borderRightStyle: 'solid',
				borderBottomStyle: 'solid',
				borderLeftStyle: 'solid',
				borderTopRightRadius: '50%',
				borderTopLeftRadius: '50%',
				borderBottomRightRadius: '50%',
				borderBottomLeftRadius: '50%',
				backgroundColor: theme.colors.accent,
				borderTopColor: isNotifcationTrayOpen ? theme.colors.buttonDisabledFill : theme.colors.accent100,
				borderBottomColor: isNotifcationTrayOpen ? theme.colors.buttonDisabledFill : theme.colors.accent100,
				borderRightColor: isNotifcationTrayOpen ? theme.colors.buttonDisabledFill : theme.colors.accent100,
				borderLeftColor: isNotifcationTrayOpen ? theme.colors.buttonDisabledFill : theme.colors.accent100,
			})}
		/>
	);

	useEffect(
		() => () => {
			isNotifcationTrayOpen && forceClose();
		},
		[]
	);

	useEffect(() => {
		if ((!allBots || !allBots.length) && activeModule) {
			dispatch(getBotListAction(activeModule));
		}
	}, []);

	useEffect(() => {
		const botMap: IObjProps = {};
		if (Array.isArray(allBots) && allBots.length > 0) {
			allBots.forEach((bot: any) => {
				botMap[bot.bot_ref] = bot.bot_name;
			});
		}
		botRefNameMap.current = botMap;
	}, [allBots]);

	useEffect(() => {
		if (modal === 'switchBot') {
			notificationModalContextRef.current = {
				conversationId: convId,
				url: convUrl,
				botRef: notifBotRef,
				heading: t('components:notifications.heading'),
				description: (
					<span>
						{t('components:notifications.description')}
						<b>{` ${botRefNameMap.current[notifBotRef] || ''}. `}</b>
						{t('components:notifications.viewConversation')}
					</span>
				),
			};
			toggleNotificationModal(true);
		}
	}, []);

	const renderSimplifiedNotficationHeader = () => (
		<div style={{ marginBottom: '0.25rem' }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<div className={css({ fontSize: '0.875rem', lineHeight: '1.25rem', fontWeight: 'bold' })}>
					Notifications
				</div>
				<div className={css({ fontSize: '0.75rem', lineHeight: '1rem', display: 'flex' })}>
					Pause notifications
					<ENGTCheckBox
						key='notification'
						values={{
							isChecked: pauseNotifcation,
							label: '',
						}}
						onChange={(value: any) => {
							setPauseNotification(value);
							localStorage.setItem(`pausednotification_${uid}`, value);
						}}
						width='1.75rem'
						height='1rem'
						size={0.95}
						minWidth='20%'
						type='toggle_round'
						checkmarkCss={
							pauseNotifcation
								? {
										backgroundColor: theme.colors.buttonDisabledFill,
									}
								: {}
						}
					/>
					<div
						className={css({ marginRight: '1rem', marginTop: '0.125rem' })}
						onClick={() => {
							navigate('/account-settings/notification');
						}}
					>
						<FilledConfigureIcon fill={theme.colors.navIconFillColor} />
					</div>
				</div>
			</div>
			<div
				className={css({
					display: 'flex',
					justifyContent: 'space-between',
					fontSize: '0.625rem',
					lineHeight: '0.75rem',
					marginTop: '1rem',
				})}
			>
				<div>{t('common:latest')}</div>
				<div
					onClick={() => {
						notificationList?.length > 0 && dispatch(clearNotificationsAction());
					}}
					style={{ color: notificationList?.length > 0 ? theme.colors.primaryA : theme.colors.accent400 }}
				>
					{t('components:synonyms.clearAll')}
				</div>
			</div>
		</div>
	);

	const notificationContainerCss = {
		maxHeight: window.innerHeight <= 700 ? getResponsiveVH(73) : getResponsiveVH(75),
		overflow: 'auto',
	};

	const readNotificationMessageCss = {
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		textAlign: 'center',
		opacity: 0.8,
		color: theme.colors.primaryA,
		padding: '1rem 1.5rem',
	};

	const SimplifiedNotificationFlow = () => (
		<Popover
			isOpen={isNotifcationTrayOpen}
			onClick={() => {
				toggleNotificationTray();
				if (isNotifcationTrayOpen) {
					dispatch(updateNotificationAction());
				}
			}}
			onClickOutside={() => {
				toggleNotificationTray();
				dispatch(updateNotificationAction());
			}}
			triggerType={TRIGGER_TYPE.click}
			returnFocus
			autoFocus
			placement={PLACEMENT.bottomRight}
			overrides={{
				Inner: {
					style: () => ({
						...notificationCss,
					}),
				},
				Body: {
					style: () => ({
						boxShadow: 'none',
						backgroundColor: 'transparent',
						zIndex: theme.zIndex300,
						top:
							window.innerHeight <= 700 && notificationList?.length > 5
								? bannerVisibility
									? '6rem'
									: '0rem'
								: '0rem',
					}),
				},
				Arrow: {
					style: () => ({
						boxShadow: 'none',
						backgroundColor: 'transparent',
					}),
				},
			}}
			content={() => (
				<div
					className={css({
						width: '17.125rem',
						borderImageSource: 'unset',
						boxShadow: 'unset',
						backgroundColor: 'transparent',
					})}
				>
					{renderSimplifiedNotficationHeader()}
					<HorizontalDivider height={0} />
					{notificationList?.length ? (
						<div className={css(notificationContainerCss)}>
							{notificationList.map((notification: any, index: number) =>
								renderNotification(notification, index)
							)}
						</div>
					) : (
						<div className={css(readNotificationMessageCss)}>{t('common:readAllNotification')}</div>
					)}
				</div>
			)}
		>
			<div className={css({ cursor: 'pointer', position: 'relative' })}>
				{renderNotificationsIcon}
				{renderNotificationDot}
			</div>
		</Popover>
	);

	return (
		<div>
			{SimplifiedNotificationFlow()}
			<ModalAlert
				isLoading={isLoading}
				description=''
				children={notificationModalContextRef.current.description}
				heading={notificationModalContextRef.current.heading}
				isOpen={isNotificationModalOpen}
				confirmBtnLabel={t('common:continue')}
				closeBtnLabel={t('common:cancel')}
				onClose={() => {
					notificationModalContextRef.current = {
						conversationId: '',
						url: '',
						botRef: -1,
						heading: '',
						description: '',
					};
					toggleNotificationModal(false);
				}}
				onConfirm={() => {
					setLoading(true);
					dispatch(
						botSelect(notificationModalContextRef.current.botRef, () => {
							toggleNotificationModal(false);
							const urlParams = notificationModalContextRef?.current?.url?.split('/');
							const tab = urlParams?.[2] ? urlParams[2] : '';
							tab && getSelectedBotLiveChatUsers(tab);
							onNotificationClick(
								notificationModalContextRef.current.conversationId,
								notificationModalContextRef.current.url
							);
							setLoading(false);
						})
					);
				}}
			/>
		</div>
	);
}

export default React.memo(Notifications);
