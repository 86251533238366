import { useStyletron } from 'baseui';

import { FILTER_TYPES } from 'shared/consts/consts';
import { IObjProps, ISelectValue } from 'shared/consts/types';
import CloseIcon from 'shared/icons/CloseIcon';

interface IAppliedFilterProps {
	value: string | React.ReactNode;
	id?: string | number;
	setState: Function;
	state: any;
	type: string;
	disabled?: boolean;
	enableCloseIcon?: boolean;
	style?: IObjProps;
}

const AppliedFilter = ({
	value,
	id,
	setState,
	state,
	type,
	disabled,
	enableCloseIcon = true,
	style,
}: IAppliedFilterProps) => {
	const [css, theme] = useStyletron();

	const filterCss: any = {
		backgroundColor: theme.colors.backgroundSecondary,
		borderRadius: '0.25rem',
		color: theme.colors.primaryA,
		display: 'flex',
		alignItems: 'center',
		fontSize: '0.75rem',
		marginTop: '0.25rem',
		marginRight: '0.25rem',
		paddingTop: style?.paddingTop || '0.5rem',
		paddingRight: style?.paddingLeft || '0.5rem',
		paddingBottom: style?.paddingTop || '0.5rem',
		paddingLeft: style?.paddingLeft || '0.5rem',
		opacity: disabled ? 0.6 : 1,
	};

	const removeFilter = () => {
		switch (type) {
			case FILTER_TYPES.select:
				const filteredState = state.filter((val: ISelectValue) => val.id !== id);
				setState(filteredState);
				break;
			case FILTER_TYPES.date:
				setState([null, null]);
				break;
		}
	};

	return (
		<div className={css(filterCss)}>
			<span className={css({ marginRight: style?.marginRight || '0.75rem' })}>{value}</span>
			{enableCloseIcon && (
				<div
					className={css({ cursor: 'pointer', pointerEvents: disabled ? 'none' : 'all', display: 'flex' })}
					onClick={removeFilter}
				>
					<CloseIcon fillColor={theme.colors.primaryA} height={12} width={12} viewX={16} viewY={16} />
				</div>
			)}
		</div>
	);
};

export default AppliedFilter;
