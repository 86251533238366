import React from 'react';
import { useStyletron } from 'baseui';

import PrivacyLink from './PrivacyLink';
import TermsAndCondition from './TermsAndCondition';

function PoliciesCTA() {
	const [css, theme]: [any, any] = useStyletron();

	const linkContainerCss = {
		lineHeight: '1.25rem',
		display: 'flex',
		fontSize: '0.625rem',
		justifyContent: 'flex-start',
		paddingLeft: '0.625rem',
		overflow: 'overlay',
		listStyleType: 'none',
		fontWeight: 400,
		color: theme.colors.accent50,
	};

	return (
		<div className='account-dropdown-menu__link'>
			<ul className={css(linkContainerCss)}>
				<PrivacyLink />
				<TermsAndCondition />
			</ul>
		</div>
	);
}

export default PoliciesCTA;
