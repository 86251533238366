import { createSlice } from '@reduxjs/toolkit';

import { modalReducer } from './reducers';
import { modalState } from './state';

export const modalSlice = createSlice({
	name: 'Modal',
	initialState: modalState,
	reducers: modalReducer,
});

export const { actions, reducer } = modalSlice;
