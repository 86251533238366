import { createTheme } from 'baseui';

import { THEME_COLOR_OPTIONS } from 'shared/enum';
import { hexToRgb } from 'shared/helpers';

import { DefaultLight } from './light/ENGTDefaultLight';
import { EarthenHuesLight } from './light/ENGTEathenHuesLight';
import { SkyLight } from './light/ENGTSkyLight';

export interface ICustomThemeParams {
	inputSecondaryFillColor: string;
}

export interface IBrandingColors {
	themeColor: string;
	fontStyle: string;
	themePack: string;
}

let primitives: any = {};

const getPrimitives: any = (props: IBrandingColors) => {
	const { themeColor, fontStyle } = props;

	primitives = {
		primaryA: '#403F42',
		primaryB: '#FFFFFF',
		primaryBtnTextColor: '#FFFFFF',
		primaryTextColor: '#FFFFFF',
		backgroundPrimary: '#F3F1F1',
		backgroundSecondary: '#E8E6E7',
		backgroundTertiary2: '#FFFFFF',
		accent: themeColor || '#AE1536',
		contentStateDisabled: '#C3C3C3',
		primaryFontFamily: fontStyle,
		accent50: '#767477', // Color code for users text on bot card
		accent100: '#E8E6E7', // Color code for default divider color
		accent200: '#6BDA76', // Color code for botStatusDeployed icon
		accent300: '#F3F1F1', // Color code for bot card menu item hover background
		accent400: '#C4C4C4', // vertical divider color after resources link in header
		accent500: '#DADADA',
		dotPendingFill: '#AE1536',
		dotStatusFill: '#A1D946',
		inputBorderError: '#AE1536',
		inputFillPrimary: '#FFFFFF',
		inputPrimary: '#000000',
		defaultBlack: '#000000',
		inputPlaceholderColor: '#403F42',
		inputBorderColor: '#E8E6E7',
		inputDisabledBgColor: '#e8e6e7',
		inputProgressBarColor: 'rgba(238, 39, 11, 0.4)',
		primaryBtnFontColor: '#403F42',
		focusedInputBorderColor: '#767477',
		modalOverlayColor: '#000',
		horizantalDividerColor: 'rgba(196, 196, 196, 0.4)',
		pathBuilderPatternDotColor: '#DAD9D9',
		defaultPrimaryAColor: '#403F42',
		navIconFillColor: '#767477',
		addNodeModalTabsBg: '#E8E6E7',
		quickGuideBg: hexToRgb('#C3C3C3', '0.15'),
		themeMode: 'LIGHT',
		engatiYellow: '#FFAC0A',
		engatiOrange2: '#EE5D0B',
		engatiPurple: '#D993D6',
		engatiGreen: '#35A541',
		engatiBlue: '#77CCD1',
		engatiLavender: '#9E87DF',
		raspberry: '#C13584',
		royalBlue: '#316FF6',
		categoryGreen: '#A1D94666',
		tags: '#AE15361A',
		noCategory: '#C4C4C466',
		engatiRedFill: 'rgba(174, 21, 54, 0.2)',
		transactionRed: '#F72A25',
		transactionGreen: '#4CAF50',
		whatsAppBackground: '#ECE3D9',
		whatsappDeploymentBackground: '#128C7E05',
	};

	return primitives;
};

const getOverrides = (props: IBrandingColors) => {
	const {
		accent,
		backgroundPrimary,
		backgroundSecondary,
		contentStateDisabled,
		dotPendingFill,
		dotStatusFill,
		engatiBlue,
		engatiYellow,
		inputBorderError,
		primaryA,
		primaryB,
		primaryBtnTextColor,
		inputSecondaryFillColor,
	} = getPrimitives(props);
	const { fontStyle } = props;

	return {
		colors: {
			advancedHorizontalDivider: '#C3C3C3',
			backgroundPrimary,
			backgroundSecondary,
			buttonDisabledFill: contentStateDisabled,
			buttonDisabledText: backgroundPrimary,
			buttonMinimalActive: primaryB,
			buttonMinimalFill: primaryB,
			buttonMinimalText: primaryA,
			buttonMinimalHover: primaryB,
			buttonPrimaryActive: accent,
			buttonPrimaryFill: accent,
			buttonPrimaryHover: accent,
			buttonPrimaryText: primaryBtnTextColor,
			buttonSecondaryFill: backgroundSecondary,
			buttonSecondaryText: primaryA,
			buttonSecondaryHover: backgroundSecondary,
			buttonSecondaryActive: backgroundSecondary,
			buttonTertiaryActive: primaryB,
			buttonTertiaryFill: primaryB,
			buttonTertiaryHover: primaryB,
			buttonTertiaryText: primaryA,
			channelBannerText: '#403F42',
			modalCloseColor: primaryA,
			tableBackground: '#FDFCFC',
			tickBorder: primaryB,
			tickFill: primaryB,
			tickFillActive: accent,
			tickFillDisabled: contentStateDisabled,
			tickFillHover: primaryB,
			tickFillSelected: accent,
			tickFillSelectedHover: accent,
			tickFillSelectedHoverActive: accent,
			tickMarkFillDisabled: backgroundPrimary,
			inputBorderError,
			borderSelected: accent,
			dotPendingFill,
			dotStatusFill,
			botBuilderLine: '#C3C3C3',
			botBuilderSelectedLine: '#A1D946',
			botBuilderConnectionModeLine: '#403F42',
			botBuilderFromCircle: '#403F42',
			botBuilderNodeInnerFill: '#F3F1F1',
			linkText: primaryA,
			linkHover: primaryA,
			channelStatsSuccess: engatiBlue,
			channelStatsFailure: engatiYellow,
			graphBackgroundColor: '#979797',
			filterIconBgColor: inputSecondaryFillColor,
			filterBackgroundColor: primaryB,
			filterHeaderBgColor: backgroundPrimary,
			goToSettingsBtnColor: '#3E3F44',
			infoBadge: '#D993D666',
			messageOverviewBgColor: '#F7F5F5',
			userDetailsBgColor: '#FDFCFC',
			noteBackgroundColor: '#EAE7E7',
			buttonFadedHightlight: hexToRgb(accent, '0.2'),
		},
		grid: {
			margins: ['10px', '10px', '10px'],
			gutters: ['10px', '10px', '10px'],
		},
		sizing: {
			scale50: '2px',
		},
		typography: {
			MonoLabelXSmall: {
				fontFamily: fontStyle,
			},
			ParagraphSmall: {
				fontFamily: fontStyle,
			},
			MonoLabelSmall: {
				fontFamily: fontStyle,
			},
			HeadingXLarge: {
				fontFamily: fontStyle,
				fontWeight: '700',
			},
			LabelMedium: {
				fontFamily: fontStyle,
				fontWeight: 400,
			},
			MonoLabelMedium: {
				fontFamily: fontStyle,
			},
		},
		breakpoints: {
			small: 1,
			medium: 600,
			large: 900,
		},
	};
};

const getInboxColors = (props: IBrandingColors) => {
	const { themePack } = props;
	const { EARTHEN_HUES, SKY_LIGHT } = THEME_COLOR_OPTIONS;

	switch (themePack) {
		case SKY_LIGHT:
			return SkyLight();
		case EARTHEN_HUES:
			return EarthenHuesLight();
		default:
			return DefaultLight();
	}
};

const LightTheme = (props: IBrandingColors) => createTheme(getPrimitives(props), getOverrides(props));

const ENGTLightTheme = (props: IBrandingColors) => {
	const {
		accent,
		backgroundSecondary,
		contentStateDisabled,
		accent50,
		accent100,
		inputFillPrimary,
		engatiBlue,
		engatiOrange2,
		engatiGreen,
		engatiYellow,
		primaryA,
		primaryB,
		primaryBtnTextColor,
		horizantalDividerColor,
		engatiPurple,
	} = getPrimitives(props);

	const themePackColors = getInboxColors(props);

	return {
		...LightTheme(props),
		cancelCircularBackground: '#E8E6E780',
		addOnBtnBackgroundColor: '#E8E6E7',
		addNodePlaceHolderColor: '#767477',
		backgroundPrimaryFaded: 'rgba(255, 255, 255, 0.91)',
		backgroundSecondaryFaded: 'rgba(232, 230, 231, 0.4)',
		builderCanvasFillColor: '#F8F7F7',
		builderCanvasActionCard: '#FFFFFF',
		dividerDarkColor: '#D5D4D4',
		inputCaretColor: '#767477',
		emailMessages: {
			page: {
				agent: themePackColors.backgroundPrimary,
				agentAttachmentFocus: backgroundSecondary,
				user: themePackColors.backgroundSecondary,
				userAttachmentFocus: primaryB,
				text: primaryA,
				icon: primaryA,
				secondaryText: accent50,
				jumpToLatestBgColor: primaryA,
				jumpToLatestText: primaryBtnTextColor,
			},
			modal: {
				agent: '#F6F4F4',
				agentAttachmentFocus: backgroundSecondary,
				user: backgroundSecondary,
				userAttachmentFocus: '#F6F4F4',
				text: primaryA,
				icon: primaryA,
				secondaryText: accent50,
				jumpToLatestBgColor: primaryA,
				jumpToLatestText: primaryBtnTextColor,
			},
		},
		engatiOrange: '#FF5000',
		graphColors: {
			colorA: engatiYellow,
			colorB: engatiOrange2,
			colorC: engatiGreen,
			colorD: engatiPurple,
		},
		instagramReplyMode: {
			privateReplyColor: `${engatiYellow} !important`,
			userCommentReply: `${engatiBlue} !important`,
		},
		botMessageBgColor: primaryB,
		inputErrorMsgColor: '#AE1536',
		inputSecondaryFillColor: '#E8E6E7',
		inputFillModal: '#FFFFFF',
		inputFillPrimary,
		intentTagColor: '#D993D6',
		acquisitionTagColor: engatiBlue,
		retentionTagColor: engatiYellow,
		retargetingTagColor: engatiOrange2,
		tagColor: '#696969',
		tagOneviewColor: 'rgba(119, 204, 209, 0.4)',
		inviteAgentOwnerBgColor: engatiOrange2,
		labelUnderLine: 'rgb(0,0,0,0.1)',
		loginPages: {
			text: '#403f42',
			whiteText: '#ffffff',
			loginFormBg: '#FBFBFB',
			pageBackground: '#FFFFFF',
			phoneNumberInfo: '#0271AF',
			loginUsingTextColor: '#979797',
			otpCode: '#9A9A9A',
			footerText: '#FFC7C7',
			inputBotder: '#7d7d7d',
			partnerMarketingBg: '#f2f2f2',
			heading: '#FFFFFFCC',
			footer: '#FFFFFF80',
		},
		messageListBgColor: '#F7F5F5',
		modalCloseBgColor: '#E8E6E7',
		modalCloseStrokeColor: '#122344',
		modalPrimaryBackground: '#FFFFFF',
		modalSecondaryBackground: '#FFFFFF',
		modalTextColor: '#403F42',
		modalHeaderColor: '#403F42 !important',
		navigationBorderColor: horizantalDividerColor,
		pricingPlanCardBgColor: '#FFFFFF',
		primaryHighlightColor: engatiOrange2,
		primaryNavBackground: '#FFFFFF',
		primaryNavText: '#767477',
		quickNavigator: {
			backgroundPrimary: primaryB,
			backgroundSecondary: hexToRgb(accent, '0.06'),
			primaryWhite: primaryB,
			primaryDark: primaryA,
		},
		quotedMessageFocusColor: contentStateDisabled,
		quotedUserMessageBgColor: '#F9F8F8',
		quotedAgentMessageBgColor: '#D6D6D6',
		radius250: '6px',
		radius350: '8px',
		recentConvBotMsgBg: '#F6F4F4',
		recentConvAgentMsgBg: backgroundSecondary,
		recentConvIconFill: primaryA,
		recentConvMsgFocus: accent100,
		responseTrained: '#3ECD5D',
		sentimentNegativeColor: '#DA2F47',
		sentimentPositiveColor: '#53D503',
		sentimentNeutralColor: '#FECE00',
		shopifyAccordionBackgroundColor: '#FFFFFF',
		shopifyVerifiedBackgroundColor: engatiOrange2,
		toasterBackgroundColor: '#FFFFFF',
		toasterTextColor: '#403F42',
		userDetailsBgcolor: '#FDFCFC',
		userMessageBgcolor: backgroundSecondary,
		userProfileColor: '#403F42',
		upgradeMarkerBgColor: '#E96E53',
		videoLayoutColours: {
			agentNameColor: '#403F42',
			agentNameBackgroundColor: '#C2C2C2',
			backgroundColor: '#403F42',
			chatBoxIcon: '#403F42',
			msgBackgroundColor: '#F7F5F5',
			messageInputBoxBorder: '#D8D8D8',
			tooltipBackgroundColor: '#E8E6E7',
			tooltipTextColor: '#252629',
			userNameColor: '#FFFFFF',
		},
		whatsApp: {
			bubbleColor: '#DCF8C6',
			textColor: '#7A8784',
			backgroundColor: '#E2DAD3',
			headerColor: '#128C7E',
			carouselContainerColor: '#FFFFFF',
			descriptionColor: '#403F42',
			headerTextColor: '#FFFFFF',
		},
		whatsAppManager: {
			availableWithoutReview: '#E9B6534D',
			approved: '#3ECD5D4D',
			rejected: '#EE190B4D',
		},
		zIndex100: 1,
		zIndex200: 2,
		zIndex300: 3,
		zIndex400: 4,
		zIndex500: 5,
		zIndex600: 6,
		zIndexToolTip: 999,
		zIndexTop: 1000,
		inProgress: '#E9B653',
		failure: '#AE1536',
		success: '#3ECD5D',
		rejected: '#EE5D0B',
		completedIcon: '#3ECD5D',
		preparingIcon: '#E2CDE1',
		stoppedIcon: '#EE5D0B',
		retryingIcon: '#77CCD1',
		inProgressIcon: '#E9B653',
		sentToWhatsApp: '#D993D6',
		datePickerInputBorder: '#DADADA56',
		templateMessagePopUpBorder: '#e0e0e0',
		agentTag: '#C7E9EB',
		whatsappBtnColor: '#53BDEB',
		templateBackground: '#F7F3ED',
		orangeInLightAndWhiteInDarkTheme: '#EE5D0B',
		ENGTInlineEditorBorderColor: '#D6D6D6',
		inbox: themePackColors,
		skeletonBackgroundImage:
			'linear-gradient(135deg, rgb(64, 63, 66), rgb(64, 63, 66), rgb(64, 63, 66), rgb(64, 63, 66), rgb(64, 63, 66), rgb(64, 63, 66), rgb(0, 0, 0), rgb(64, 63, 66), rgb(64, 63, 66), rgb(64, 63, 66), rgb(64, 63, 66), rgb(64, 63, 66), rgb(64, 63, 66))',
		buttonHoverColor: '#AFAFAF',
		successCellBackgroundColor: '#E1F2C5',
		errorCellBackgroundColor: '#E5B7C1',
		discountIndicatorColor: '#7732BB',
		cloudIntegrationsConnectedAccountsBackground: '#E3F1E8',
	};
};

export default ENGTLightTheme;
