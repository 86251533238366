import React from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ListItemWithIcon } from 'components/UI/ListItemWithIcon/ListItemWithIcon';

import { clearLsOnLogout } from 'shared/helpers';
import LogoutIcon from 'shared/icons/LogoutIcon';

import { redirectToLoginAction } from 'store/App/Token';
import { RootState } from 'store/rootReducer';

const LogOut = () => {
	const [, theme]: [any, any] = useStyletron();
	const { t }: any = useTranslation(['common']);
	const dispatch = useDispatch<any>();

	const uid = useSelector((state: RootState) => state.User.botAdmin.data.uid);
	const botRef = useSelector((state: RootState) => state.Bot.data.bot_ref);

	const logoutIcon = () => <LogoutIcon fill={theme.colors.navIconFillColor} />;

	return (
		<ListItemWithIcon
			handleClick={() => {
				clearLsOnLogout(botRef, uid);
				dispatch(redirectToLoginAction() as any);
			}}
			iconElement={logoutIcon}
			itemLabel={t('common:logout')}
		/>
	);
};

export default LogOut;
