import { useStyletron } from 'baseui';
import { LabelSmall } from 'baseui/typography';
import { useTranslation } from 'react-i18next';

import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { GridColumns, GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { LegendOrdinal } from '@visx/legend';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { useTooltip } from '@visx/tooltip';

import { IObjProps } from 'shared/consts/types';
import { borderRadius } from 'shared/helpers';

import { ILineGraphProps, ILinePathProps } from './ENGTGraphProps';
import ENGTGraphToolTip from './ENGTGraphToolTip';

const ENGTLinePath = ({ width, height, data, paths, xAccessor, ...additionals }: ILineGraphProps) => {
	const { t } = useTranslation();
	const [css, theme] = useStyletron();

	const {
		radius = 8,
		background,
		description,
		leftNumTicks = 5,
		axisBottomLeft,
		xFormatter = (value: any) => value,
		yFormatter = (value: any) => value,
		gridStroke = theme.colors.accent100,
		labelFormat = (value: any) => value,
		margin = { top: 40, right: 32, bottom: 24, left: 32 },
	} = additionals;

	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;

	const xAxis = scaleBand<string>({
		domain: [...data.map(xAccessor)],
	});

	const yAxis = scaleLinear<number>({
		domain: [0, Math.max(...paths.map((path) => Math.max(...data.map(path.yAccessor))))],
	});

	xAxis.rangeRound([0, xMax]);
	yAxis.range([yMax, 0]);

	let tooltipTimeout: any;
	const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<any>();
	let currentPoint: any = {};

	const xCoordinate = (data: IObjProps) => {
		const xCoord = xAxis(xAccessor(data));
		currentPoint = { ...currentPoint, [`${xCoord}`]: `${data.du}_${data.nu}` };

		return xCoord || 0;
	};

	const yCoordinate = (data: IObjProps, path: ILinePathProps) => yAxis(path.yAccessor(data)) || 0;

	const labelScale = scaleOrdinal<string, string>({
		domain: paths.map((path) => path.key),
		range: paths.map((path) => path.color),
	});

	const getClosestCoordinate = (x: number) => {
		const closestValue = Object.keys(currentPoint).reduce((a: any, b: any) =>
			Math.abs(b - x) < Math.abs(a - x) ? b : a
		);

		return closestValue;
	};

	return (
		<div style={{ position: 'relative' }}>
			<div
				style={{
					position: 'absolute',
					fontSize: '0.875rem',
					display: 'flex',
					width: '100%',
					marginTop: '1rem',
					justifyContent: 'space-between',
				}}
			>
				{description && (
					<LabelSmall
						color={theme.colors.primaryA}
						overrides={{
							Block: {
								style: {
									fontWeight: 700,
									marginLeft: `${margin.left}px`,
								},
							},
						}}
					>
						{description}
					</LabelSmall>
				)}
				<LegendOrdinal
					scale={labelScale}
					direction='column'
					itemMargin={2}
					labelAlign='flex-start'
					labelMargin='0 30px 0 0'
					className={css({
						color: theme.colors.primaryA,
						fontSize: '0.75rem',
						fontWeight: 500,
						lineHeight: '1rem',
						overflow: 'hidden',
						marginLeft: description ? '' : '1rem',
					})}
					shapeStyle={() => ({
						width: '0.75rem',
						height: '0.75rem',
						marginBottom: '0.125rem',
						...borderRadius('0.25rem'),
					})}
					labelFormat={(label) => `${t(`pages:analytics.bot.engagement.graph.${label}`)}`}
				/>
			</div>
			<svg width={width} height={height}>
				<rect x={0} y={0} width={width} height={height} fill={background} rx={radius} />
				<Group left={margin.left} top={margin.top}>
					<GridRows scale={yAxis} width={xMax} numTicks={leftNumTicks} stroke={gridStroke} opacity={0.2} />
					<GridColumns scale={xAxis} height={yMax} stroke={gridStroke} opacity={0.2} />
					<AxisLeft
						scale={yAxis}
						hideTicks
						numTicks={leftNumTicks}
						tickFormat={yFormatter}
						stroke={theme.colors.accent100}
						tickLabelProps={() => ({
							fontSize: 10,
							textAnchor: 'end',
							fill: theme.colors.primaryA,
						})}
					/>

					<AxisBottom
						top={yMax}
						scale={xAxis}
						left={axisBottomLeft}
						hideTicks
						stroke={gridStroke}
						tickFormat={xFormatter}
						tickLabelProps={() => ({
							fontSize: 10,
							textAnchor: 'middle',
							fill: theme.colors.primaryA,
						})}
					/>
					{paths.map((path, index) => (
						<LinePath
							key={index}
							data={data}
							x={xCoordinate}
							y={(data) => yCoordinate(data, path)}
							stroke={path.color}
							strokeWidth={path.stroke}
							pointerEvents='stroke'
							onMouseMove={(event) => {
								if (tooltipTimeout) {
									clearTimeout(tooltipTimeout);
								}
								const point = localPoint(event);
								const roundedX = point?.x ? Math.floor(point.x - 32) : -1;
								const closestX = getClosestCoordinate(roundedX);
								const currentData = currentPoint[closestX].split('_');
								showTooltip({
									tooltipData: {
										key: path.labelFormat,
										value: currentData[index],
									},
									tooltipLeft: point?.x,
									tooltipTop: point?.y,
								});
							}}
							onMouseLeave={() => {
								tooltipTimeout = window.setTimeout(() => hideTooltip(), 200);
							}}
						/>
					))}
				</Group>
			</svg>
			{tooltipOpen && tooltipTop && tooltipLeft && (
				<ENGTGraphToolTip top={tooltipTop} left={tooltipLeft} data={tooltipData} label={labelFormat} />
			)}
		</div>
	);
};

export default ENGTLinePath;
