import React, { FunctionComponent } from 'react';
import { useStyletron } from 'baseui';
import { HeadingLevel } from 'baseui/heading';

import ENGTImage from 'components/UI/ENGTImage/ENGTImage';

import { IObjProps } from 'shared/consts/types';

export interface INoDataTemplate {
	/**
	  Banner image for the page
	*/
	bannerImage?: {
		altText: string;
		imageUrl: string | FunctionComponent<React.PropsWithChildren<unknown>>;
	};
	/**
	  Banner image CSS for the page
	*/
	imageCss?: IObjProps;
	/**
	  Description for that page, expects a JSX element wrapped in <paragraph2> to support hyperlinks
	*/
	children?: JSX.Element;
	/**
	 Property to handle any css apart from default css
	 */
	className?: string;
	/**
		 Data content style changes
	 */
	contentCss?: IObjProps;
	/**
	  Footer may contain any jsx element, like buttons
	*/
	footer?: JSX.Element | string;
	/**
	  Heading label for the page when no data is present
	*/
	heading?: string | React.ReactNode;
	/**
	  TO handle Css apart from default applied
	*/
	headingCss?: IObjProps;
	/**
	 	Main container style changes
	 */
	mainStyle?: IObjProps;
}

const paragraphCss = (overrides: IObjProps) => ({
	opacity: 0.8,
	lineHeight: '1.25rem !important',
	...overrides,
});

function NoDataTemplate({
	bannerImage,
	imageCss,
	children,
	className,
	heading,
	footer,
	headingCss = {},
	contentCss = {},
	mainStyle,
}: INoDataTemplate) {
	const [css, theme]: [any, any] = useStyletron();

	const renderImage = () => {
		if (bannerImage && bannerImage.imageUrl) {
			return typeof bannerImage.imageUrl === 'string' ? (
				<ENGTImage
					lazy
					src={bannerImage.imageUrl}
					alt={bannerImage.altText}
					className={css({ width: 'max-content', ...imageCss })}
				/>
			) : (
				<bannerImage.imageUrl />
			);
		}
	};

	return (
		<div
			className={`${css({
				height: '100%',
				textAlign: 'center',
				marginLeft: 'auto',
				marginTop: 'auto',
				marginRight: 'auto',
				marginBottom: 'auto',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				...mainStyle,
			})} ${className}`}
		>
			{renderImage()}
			<HeadingLevel>
				<div
					className={css({
						color: theme.colors.primaryA,
						fontSize: '1.25rem',
						lineHeight: '1.625rem',
						fontStyle: 'normal',
						fontWeight: '700',
						...headingCss,
					})}
				>
					{heading}
				</div>
				<div className={css(paragraphCss(contentCss))}>{children}</div>
			</HeadingLevel>
			{footer}
		</div>
	);
}

export default NoDataTemplate;
