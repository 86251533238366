import { IApiDataProps, IObjProps } from 'shared/consts/types';

interface IOnboardingStatus<T> {
	data: T;
	error: boolean;
}
export interface IShopifyAccount {
	account: IApiDataProps<{}>;
	zipCodeStatus?: IApiDataProps<{}>;
	onboardingStatus?: IOnboardingStatus<{}>;
	productInitialLoadData?: IObjProps;
	productDiscoveryConfig?: IObjProps;
	segmentsStatus?: boolean;
}

export const shopifyState: IShopifyAccount = {
	account: {
		data: {},
		error: false,
		loading: true,
	},
	zipCodeStatus: {
		data: {
			botRef: '',
			disabled: false,
		},
		error: false,
		loading: true,
	},
	onboardingStatus: {
		data: {},
		error: false,
	},
	productInitialLoadData: {},
	segmentsStatus: false,
	productDiscoveryConfig: {},
};
