export enum ONEVIEW_SCREENS {
	MESSAGES = 'MESSAGES',
	FACEBOOK_COMMENTS = 'FACEBOOK',
	INSTAGRAM_COMMENTS = 'INSTAGRAM',
}

export enum SOCIAL_INBOX_MEDIA_TYPES {
	IMAGE = 'IMAGE',
	TEXT = 'TEXT',
	VIDEO = 'VIDEO',
	CAROUSEL = 'CAROUSEL_ALBUM',
}

export const PRIVATE_REPLY_LIMIT = 7;
