import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import { Label3 } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ModeToggle } from 'components/ModeToggle/ModeToggle';
import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';
import ENGTModalWithoutFooter from 'components/UI/Modal/ModalWithoutFooter/ModalWithoutFooter';

import { ENGATI_ROLES } from 'shared/consts/consts';

import { RootState } from 'store/rootReducer';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

function PublishLiveStatus({ setIsLoading }: { setIsLoading: Function }) {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['common']);
	const navigate = useNavigate();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const isLiveDraftEntitlement = useSelector((state: RootState) => state.User.botAdmin.data.is_live_draft_enabled);
	const draftBotRef = useSelector((state: RootState) => state.Bot.data?.draft_bot_ref);
	const isAgent = useSelector(
		(state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT
	);

	if (isLiveDraftEntitlement && draftBotRef && !isAgent) {
		return (
			<>
				<HorizontalDivider
					height='0.5px'
					overrides={{
						width: '70%',
					}}
				/>
				<ModeToggle navItem='botMode' setIsModalOpen={setIsModalOpen} setIsLoading={setIsLoading} />
				<ENGTModalWithoutFooter
					isOpen={isModalOpen}
					heading={t('pages:primaryNavigation.publishToLive')}
					onClose={() => {
						setIsModalOpen(false);
					}}
					width='24.375rem'
					noOverflow
				>
					<>
						<Label3
							className={css({
								color: theme.colors.accent50,
							})}
						>
							{t('pages:primaryNavigation.publishMessage')}
						</Label3>
						<Label3
							className={css({
								color: theme.colors.accent50,
								marginTop: '0.5rem',
							})}
						>
							<a
								onClick={() => {
									navigate(ThreeColumnRouteLinks.configurePublishLink);
									setIsModalOpen(false);
								}}
								className={css({
									color: `${theme.colors.primaryA}`,
									textDecoration: 'underline',
									cursor: 'pointer',
								})}
								target='_blank'
								rel='noreferrer'
							>
								{t('common:clickHereCap')}
							</a>
							{t('pages:primaryNavigation.toPublish')}
						</Label3>
					</>
				</ENGTModalWithoutFooter>
			</>
		);
	}

	return <></>;
}

export default PublishLiveStatus;
