import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const genericProxyRequestUrl = new BaseService('/genericProxyRequest/');
const genericProxyRequestUrlV2 = new BaseService('/v2/genericProxyRequest/');
const getExportHistoryUrl = new BaseService('/api/v1/export/history/');

export const storeAPI = {
	fetchShopifyRequests(data, headers) {
		const params = {
			targetApi: 'shopifyRequests',
		};
		const api = new BaseService('/v2/genericProxyRequest/', {
			headers,
		});

		return api.postRequest(data, serialize(params, '?'));
	},

	getExportHistory(params) {
		return getExportHistoryUrl.getRequestWithParams(params);
	},

	getCustomerSegments(page, size, segmentType) {
		const params = {
			page,
			size,
			segmentType,
			targetApi: 'getShopifySegmentsAll',
		};
		return genericProxyRequestUrlV2.getRequest(serialize(params, '?'));
	},

	getCustomerSegmentOptions(data) {
		const params = {
			targetApi: 'customerSegmentFeildSearch',
			botRef: '{{botRef}}',
			...data,
		};

		return genericProxyRequestUrlV2.getRequest(serialize(params, '?'));
	},
	createCustomerSegment(payload) {
		return genericProxyRequestUrl.putRequest({
			target_url: 'addUpdateShopifyCustomerSegment',
			...payload,
		});
	},

	deleteCustomerSegment(segmentId) {
		return genericProxyRequestUrl.deleteRequest(
			serialize(
				{
					target_url: 'deleteShopifyCustomerSegment',
					params: {
						segmentId,
					},
				},
				'?'
			)
		);
	},

	refreshCustomerSegment(segmentId) {
		return genericProxyRequestUrl.getRequest(
			serialize(
				{
					target_url: 'refreshShopifySegment',
					params: {
						segmentId,
					},
				},
				'?'
			)
		);
	},

	downloadSegments(segmentName) {
		const params = {
			targetApi: 'downloadCustomerSegments',
			objectKey: segmentName,
		};

		return genericProxyRequestUrlV2.getRequest(serialize(params, '?'));
	},
};
