import { IObjProps } from 'shared/consts/types';

import { IUserDetailsStateInterface, userDetailsState } from './state';

export const userDetailsReducer = {
	getBotAdmin: (state: IUserDetailsStateInterface, action: { payload: IObjProps }) => {
		state.botAdmin = {
			data: action.payload,
			error: false,
			loading: false,
		};
	},
	getBotAdminError: (state: IUserDetailsStateInterface) => {
		state.botAdmin = {
			data: {},
			error: true,
			loading: false,
		};
	},
	getProfileDetails: (state: IUserDetailsStateInterface, action: { payload: IObjProps }) => {
		state.profile = {
			data: action.payload,
			error: false,
			loading: false,
		};
	},
	getProfileDetailsError: (state: IUserDetailsStateInterface) => {
		state.profile = {
			data: {},
			error: true,
			loading: false,
		};
	},
	getSecurityConfigDetails: (state: IUserDetailsStateInterface, action: { payload: IObjProps }) => {
		state.security = {
			data: action.payload,
			error: false,
			loading: false,
		};
	},
	getSecurityDetailsError: (state: IUserDetailsStateInterface) => {
		state.security = {
			data: {},
			error: true,
			loading: false,
		};
	},
	getCategories: (state: IUserDetailsStateInterface, action: { payload: Array<IObjProps> }) => {
		state.categoriesMap = action.payload;
	},
	getPermittedPlatformsForCustomer: (state: IUserDetailsStateInterface, action: { payload: Array<IObjProps> }) => {
		state.permittedPlatforms = action.payload;
	},
	getIntegratedAccounts: (state: IUserDetailsStateInterface, action: { payload: IObjProps }) => {
		state.integrationAccountDetails = action.payload;
	},
	resetAllUserData: (state: IUserDetailsStateInterface) => {
		Object.keys(userDetailsState).forEach((key) => {
			(state as IObjProps)[key] = (userDetailsState as IObjProps)[key];
		});
	},
	setInstaInboxCommentsEnablement: (state: IUserDetailsStateInterface, action: { payload: boolean }) => {
		state.botAdmin.data = {
			...state.botAdmin.data,
			is_instagram_comments_enabled: action.payload,
		};
	},
	setGeoLocationData: (state: IUserDetailsStateInterface, action: { payload: IObjProps }) => {
		state.geoLocation = action.payload;
	},
	setJounrneyPhaseData: (state: IUserDetailsStateInterface, action: { payload: IObjProps }) => {
		state.journey = {
			error: false,
			loading: false,
			data: {
				...action.payload,
			},
		};
	},
	setJounrneyPhaseLoading: (state: IUserDetailsStateInterface) => {
		state.journey = {
			...state.journey,
			loading: true,
		};
	},
	setJounrneyPhaseError: (state: IUserDetailsStateInterface) => {
		state.journey = {
			data: {},
			loading: false,
			error: true,
		};
	},
	setIfStripeIdExistsInCustomerDetails: (state: IUserDetailsStateInterface, action: { payload: boolean }) => {
		state.stripeIdInCustomerDetails = action.payload;
	},
};
