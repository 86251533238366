const LogoutIcon = ({ size = '13', fill = '#403F42' }) => (
	<svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g opacity='0.7'>
			<path
				d='M8.12488 7.04167C7.82534 7.04167 7.58321 7.28433 7.58321 7.58333V9.75C7.58321 10.0485 7.34054 10.2917 7.04154 10.2917H5.41654V2.16667C5.41654 1.70408 5.12188 1.29079 4.67879 1.13696L4.51846 1.08333H7.04154C7.34054 1.08333 7.58321 1.32654 7.58321 1.625V3.25C7.58321 3.549 7.82534 3.79167 8.12488 3.79167C8.42442 3.79167 8.66654 3.549 8.66654 3.25V1.625C8.66654 0.729083 7.93746 0 7.04154 0H1.21863C1.19804 0 1.18071 0.00920833 1.16067 0.0119167C1.13467 0.00975 1.10975 0 1.08321 0C0.485753 0 -0.00012207 0.485875 -0.00012207 1.08333V10.8333C-0.00012207 11.2959 0.294545 11.7092 0.737628 11.863L3.99738 12.9496C4.10788 12.9838 4.21784 13 4.33321 13C4.93067 13 5.41654 12.5141 5.41654 11.9167V11.375H7.04154C7.93746 11.375 8.66654 10.6459 8.66654 9.75V7.58333C8.66654 7.28433 8.42442 7.04167 8.12488 7.04167Z'
				fill={fill}
			/>
			<path
				opacity='0.7'
				d='M12.8412 5.03367L10.6745 2.867C10.5196 2.71209 10.2867 2.6655 10.0841 2.74946C9.88207 2.83342 9.7499 3.03113 9.7499 3.24996V4.87496H7.58323C7.28423 4.87496 7.04156 5.11763 7.04156 5.41663C7.04156 5.71563 7.28423 5.95829 7.58323 5.95829H9.7499V7.58329C9.7499 7.80213 9.88207 7.99983 10.0841 8.08379C10.2867 8.16775 10.5196 8.12117 10.6745 7.96625L12.8412 5.79958C13.053 5.58779 13.053 5.24546 12.8412 5.03367Z'
				fill={fill}
			/>
		</g>
	</svg>
);

export default LogoutIcon;
