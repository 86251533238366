import { useTranslation } from 'react-i18next';

import ModalAlert from 'components/UI/Modal/ModalAlert/ModalAlert';

interface IStopBroadcastModal {
	toggleStop: Function;
	onConfirm: any;
}

const StopBroadcastModal = ({ toggleStop, onConfirm }: IStopBroadcastModal) => {
	const { t } = useTranslation(['common', 'pages']);

	const errorModalDetails = {
		cancelBtnLabel: t('common:cancel'),
		confirmBtnLabel: t('common:continue'),
		description: t('pages:broadcast.broadcastHistory.stopBroadcastSubheading'),
		heading: t('pages:broadcast.broadcastHistory.stopBroadcastHeading'),
	};

	return (
		<ModalAlert
			closeBtnLabel={errorModalDetails.cancelBtnLabel}
			confirmBtnLabel={errorModalDetails.confirmBtnLabel}
			description={errorModalDetails.description}
			heading={errorModalDetails.heading}
			isConfirmBtnDisabled={false}
			isOpen
			onClose={() => toggleStop(false)}
			onConfirm={onConfirm}
			width='25rem'
		/>
	);
};

export default StopBroadcastModal;
