import { IObjProps } from 'shared/consts/types';

import { RootState } from 'store/rootReducer';

import { API } from './api';
import { actions } from './store';

const { getBotsByQuery, getBotList, getBotListError, updateBotList } = actions;

export const getBotListAction = (activeModule: any) => (dispatch: any) =>
	API.fetchBotList(activeModule).then((resp: any) => {
		if (resp?.data?.responseObject) {
			dispatch(getBotList(resp.data.responseObject));
		} else {
			dispatch(getBotListError());
		}
	});

export const getBotsByQueryAction = (query: string, list: IObjProps[]) => (dispatch: any) => {
	const regexQuery = new RegExp(query.trim(), 'i');
	const filteredBots = list.filter((bot) => bot?.bot_name?.search(regexQuery) > -1);

	dispatch(getBotsByQuery({ content: filteredBots, query }));
};

export const removeBotFromListAction = (id: string | number) => (dispatch: any, getState: () => RootState) => {
	let allBots = getState().BotList.data;

	allBots = allBots?.filter((bot: IObjProps) => bot?.bot_ref !== id);

	allBots && dispatch(updateBotList(allBots));
};

export const markBotEnabledAction = (botRef: number) => (dispatch: any, getState: () => RootState) => {
	const allBots = getState().BotList.data;
	const updatedBotData = allBots?.map((bot: IObjProps) =>
		bot?.bot_ref === botRef && bot?.hasOwnProperty('is_enabled') ? { ...bot, is_enabled: true } : bot
	);
	updatedBotData && dispatch(updateBotList(updatedBotData));
};
