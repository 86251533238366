import { matchPath } from 'react-router-dom';

import { IObjProps } from 'shared/consts/types';

import { NoAuthenticationRouteLinks } from 'router/links/NoAuthenticationRouteLinks';

const isAuthenticatedRoute = (() => {
	const cache: IObjProps = {};

	return (pathname: string) => {
		if (cache[pathname] !== undefined) {
			return cache[pathname];
		}

		let isAuthenticated = true;
		const NoAuthRouteKeys = Object.keys(NoAuthenticationRouteLinks);

		for (let i = 0; i < NoAuthRouteKeys.length; i++) {
			if (matchPath(NoAuthenticationRouteLinks[NoAuthRouteKeys[i]], pathname)) {
				isAuthenticated = false;
				break;
			}
		}

		cache[pathname] = isAuthenticated;

		return isAuthenticated;
	};
})();

export default isAuthenticatedRoute;
