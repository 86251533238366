import BaseService from 'services/baseService';
import { API_GATEWAY_URL } from 'shared/consts/consts';
import { serialize } from 'shared/helpers';

const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const genericProxyRequestApi = new BaseService(genericProxyRequestUrl);
const apiGatewayRequest = new BaseService(API_GATEWAY_URL);

export const API = {
	fetchIntegratedAccount() {
		const params = {
			targetApi: 'shopifyIntegratedAccount',
			botRef: '{{botRef}}',
		};

		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
	fetchIsZipcodeRequired(botRef) {
		const params = {
			targetApi: 'verifyShopifyZipcode',
			botRef,
		};

		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
	setZipcodeStatus(botRef, disabled) {
		const params = {
			targetApi: 'verifyShopifyZipcode',
		};
		const body = {
			bot_ref: botRef,
			disabled,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	getShopifyStorePolicyUrl(shopify_domain_name) {
		const params = {
			targetApi: 'getShopifyStorePolicyUrl',
			myShopifyDomain: shopify_domain_name,
		};

		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
	getShopifyEngagedUsers(startTime, endTime) {
		const params = {
			targetApi: 'getShopifyEngagedUsers',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	getShopifyAOV(startTime, endTime) {
		const params = {
			targetApi: 'getShopifyAOV',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	getShopifyOrderCount(startTime, endTime) {
		const params = {
			targetApi: 'getShopifyOrderCount',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	getShopifyAbandonedCheckouts(startTime, endTime) {
		const params = {
			targetApi: 'getShopifyAbandonedCheckouts',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	getShopifyTransactions(startTime, endTime) {
		const params = {
			targetApi: 'getShopifyTransactions',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	getShopifyTransactionRevenue(startTime, endTime) {
		const params = {
			targetApi: 'getShopifyTransactionRevenue',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	getShopifyConversationIntentBreakdown(startTime, endTime) {
		const params = {
			targetApi: 'getShopifyConversationIntentBreakdown',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	getShopifyConversationSentimentBreakdown(startTime, endTime) {
		const params = {
			targetApi: 'getShopifyConversationSentimentBreakdown',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	getShopifyEngagedUsersPerPlatform(startTime, endTime) {
		const params = {
			targetApi: 'getShopifyEngagedUsersPerPlatform',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	shopifyBotQueriesChart(startTime, endTime) {
		const params = {
			targetApi: 'shopifyBotQueriesChart',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	mostPurchasedProducts(startTime, endTime) {
		const params = {
			targetApi: 'mostPurchasedProducts',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	mostAbandonedProducts(startTime, endTime) {
		const params = {
			targetApi: 'mostAbandonedProducts',
		};
		const body = {
			startTime,
			endTime,
		};

		return genericProxyRequestApi.postRequest(body, serialize(params, '?'));
	},
	getShopifyDashboardLastupdatedOn() {
		const params = {
			targetApi: 'getShopifyDashboardLastupdatedOn',
		};

		return genericProxyRequestApi.postRequest({}, serialize(params, '?'));
	},
	getRevenueDashboardData() {
		const params = {
			targetApi: 'revenueDashboard',
		};

		return genericProxyRequestApi.getRequest(serialize(params, '?'));
	},
	getRevenueBreakdown() {
		const params = {
			targetApi: 'getRevenueBreakdown',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	getAttributedOrderAndRevenue() {
		const params = {
			targetApi: 'getAttributedOrderAndRevenue',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
	getCtwaAttribution() {
		const params = {
			targetApi: 'ctwaAttribution',
		};

		return apiGatewayRequest.getRequest(serialize(params, '?'));
	},
};
