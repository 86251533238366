import { IApiDataProps, IObjProps } from 'shared/consts/types';

export interface IPlanStateInterface {
	current: IApiDataProps<IObjProps>;
	details: IObjProps;
}

export const planState: IPlanStateInterface = {
	current: {
		data: {},
		error: false,
		loading: true,
	},
	details: {
		data: {},
		error: false,
		loading: true,
	},
};
