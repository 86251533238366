import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const exportBotUrl = '/v2/genericProxyRequest/';
const exportBotApi = new BaseService(exportBotUrl);

const moveBotUrl = '/api/v1/moveBotsAcrossAccounts';
const moveBotApi = new BaseService(moveBotUrl);

const downloadCsvUrl = '/api/v1/download/botExportZipFile';
const downloadCsvApi = new BaseService(downloadCsvUrl, {
	dataType: 'binary',
	processData: false,
	responseType: 'arraybuffer',
});

export const API = {
	exportBot: (botRef, activeModule) => {
		const params = {
			targetApi: 'exportBot',
			customerId: '{{customerId}}',
			userId: '{{userId}}',
			module: activeModule,
			replaceable_params: {
				bot_ref: botRef,
			},
		};

		return exportBotApi.postRequest({}, serialize(params, '?'));
	},
	moveBot: (email) => {
		const params = {
			email,
		};

		return moveBotApi.postRequest(params);
	},
	downloadCsv: (botRef) => {
		const params = {
			bot_ref: botRef,
		};

		return downloadCsvApi.getRequest(serialize(params, '?'));
	},
};
