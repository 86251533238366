import { TRANSLATED_LAST_MESSAGE_STATE, TRANSLATION_STATUS } from 'shared/consts/consts';
import {
	IApiDataProps,
	IAutoTranslationFeatureProps,
	IMessagePositionProps,
	IMessagesPaginationDetailsProps,
	IObjProps,
	IPaginatedApiStateProps,
	IPaginationPayloadProps,
	ISmartAssistStateProps,
} from 'shared/consts/types';

export interface ImessageOverviewStateInterface {
	userMessages: IApiDataProps<Array<IObjProps>>;
	agentsListByCategory: IApiDataProps<Array<IObjProps>>;
	quickReplies: IPaginatedApiStateProps<Array<any>, IPaginationPayloadProps>;
	lastMessage: any;
	isEmailTranscriptEnabled: boolean;
	liveChatConfig: IApiDataProps<IObjProps>;
	pathVariables: IObjProps;
	autoTranslationConfigs: IAutoTranslationFeatureProps;
	messagePosition: IMessagePositionProps;
	messagesPagination: IMessagesPaginationDetailsProps;
	agentId: any;
	isSmartAssistEnabled: boolean;
	bringToView: any;
	showPriorityQuickFilter: any;
	isEmailConfigured: any;
	smartAssistConfig: ISmartAssistStateProps;
	userAttributes: IObjProps;
}

export const messageOverviewState: ImessageOverviewStateInterface = {
	userMessages: {
		data: [],
		error: false,
		loading: false,
	},
	bringToView: null,
	showPriorityQuickFilter: null,
	isEmailConfigured: false,
	agentsListByCategory: {
		data: [],
		error: false,
		loading: false,
	},
	quickReplies: {
		data: [],
		error: false,
		loading: false,
		pagination: {
			first: true,
			last: false,
			number: 10,
			numberOfElements: 10,
			size: 10,
			sort: 'updatedOn,desc',
			totalElements: 0,
			totalPages: 0,
		},
	},
	lastMessage: {},
	isEmailTranscriptEnabled: false,
	liveChatConfig: {
		data: {},
		loading: false,
		error: false,
	},
	pathVariables: {},
	autoTranslationConfigs: {
		isTranslateIntegrationEnabled: false,
		translationStatusOfAgent: true,
		translationStatusOfMessages: TRANSLATION_STATUS.SUCCESS,
		endUserLanguage: '',
		isAutoTranslateEnabledInLiveChat: false,
		lastTranslatedMessageState: TRANSLATED_LAST_MESSAGE_STATE.PROCESSED,
	},
	messagesPagination: {
		pageNo: 1,
		lastVisitedRow: null,
		firstVisitedRow: null,
	},
	messagePosition: {
		append: false,
		prepend: false,
	},
	agentId: '',
	isSmartAssistEnabled: false,
	smartAssistConfig: {
		isEnabled: false,
		isOpened: false,
	},
	userAttributes: {
		data: {},
		loading: false,
		error: false,
	},
};
