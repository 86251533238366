export const sendToMessenger = [
	{
		type: 'blue',
		value: 'standard',
	},
	{
		type: 'white',
		value: 'standard',
	},
	{
		type: 'blue',
		value: 'large',
	},
	{
		type: 'white',
		value: 'large',
	},
	{
		type: 'blue',
		value: 'xlarge',
	},
	{
		type: 'white',
		value: 'xlarge',
	},
];

export enum SYNC_UP_STATUS {
	SUCCESS = 'SUCCESS',
	IN_PROGRESS = 'IN_PROGRESS',
	FAILED = 'FAILED',
}

export const basicMuTemplate =
	"<div class='fb-messengermessageus' id='fb-messengermessageus' messenger_app_id='__APP_ID__' page_id='__PAGE_ID__' color='__BTN_COLOR__' size='__BTN_SIZE__'></div><script> window.fbAsyncInit = function() { FB.init({ appId : '__APP_ID__', xfbml : true, version : 'v2.6' }); }; (function(d, s, id){ var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = '//connect.facebook.net/en_US/sdk.js'; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'facebook-jssdk'));</script>";

export const basicSmTemplate =
	"<script> window.fbAsyncInit = function() { FB.init({ appId : '__APP_ID__', xfbml : true, version : 'v2.6' }); }; (function(d, s, id){ var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = '//connect.facebook.net/en_US/sdk.js'; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'facebook-jssdk'));</script><div class='fb-send-to-messenger' id='fb-send-to-messenger' messenger_app_id='__APP_ID__' page_id='__PAGE_ID__' color='__BTN_COLOR__' size='__BTN_SIZE__'></div>";
