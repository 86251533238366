import { IApiDataProps, IObjProps } from 'shared/consts/types';

export const jitsiState: IJItsiInterface = {
	isLaunchScreenOpen: false,
	videoCallInitialisationDetails: {},
	selectedVideoUser: {},
	videoUserMessages: {
		data: [],
		error: false,
		loading: false,
	},
	participantsCount: 0,
	isGroupConversationAllowedForVideo: false,
	videoConversationIdForUserMap: {},
	userInstanceUrl: {},
};

export interface IJItsiInterface {
	isLaunchScreenOpen: boolean;
	videoCallInitialisationDetails: IObjProps;
	selectedVideoUser: IObjProps;
	videoUserMessages: IApiDataProps<Array<IObjProps>>;
	participantsCount: number;
	isGroupConversationAllowedForVideo: boolean;
	videoConversationIdForUserMap: IObjProps;
	userInstanceUrl: IObjProps;
}
