const AdminModeIcon = ({ size = '11', fill = '#403F42' }: { size?: string; fill?: string }) => (
	<svg
		width={size}
		height={size}
		viewBox={`0 0 ${size} ${size}`}
		opacity='0.8'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M1.87449 3.08091C1.87449 1.3821 3.25659 0 4.9554 0C6.65422 0 8.03631 1.3821 8.03631 3.08091C8.03631 4.77973 6.65422 6.16182 4.9554 6.16182C3.25659 6.16182 1.87449 4.77973 1.87449 3.08091ZM5.8195 6.16183C7.00643 6.16183 8.12462 6.63348 8.9681 7.48993C9.80746 8.34218 10.2697 9.46714 10.2697 10.6577C10.2697 10.8467 10.1164 11 9.92738 11H0.342324C0.15327 11 0 10.8467 0 10.6577C0 9.46714 0.462251 8.34218 1.30161 7.48993C2.14509 6.63348 3.2633 6.16183 4.45021 6.16183H5.8195Z'
			fill={fill}
		/>
	</svg>
);

export default AdminModeIcon;
