import { messageDetailsActions } from './messageDetails/actions';
import { messageHelperActions } from './messageHelpers/actions';
import { messageListActions } from './messageList/actions';
import { messageLiteListActions } from './messageLiteList/actions';
import { messageOverviewActions } from './messageOverview/actions';
import messagesCommon from './messagesCommon/actions';
import { postCommentsActions } from './PostComments/actions';
import { postListAndOverviewActions } from './PostListAndOverview/actions';
import { actions } from './store';

const { resetAllMessagesData } = actions;

export const { openMessagesLeftNav, openMessagesContent, openMessageDetails } = messagesCommon;

export const {
	getAvailableAgentsAction,
	setAllAvailableAgentsAction,
	getAgentsOnlineByCategoryAction,
	getCsRequestAction,
	getFacetsSortFromLs,
	getLiveChatUsersAction,
	getMessagesSearchData,
	getNotificationClickUserAction,
	setMessageCountAction,
	setConversationReadStatusAction,
	fetchAllUsersCountApiAction,
	removeMessageCountForIdAction,
	getAllUsersCountAction,
	getActiveCSRequestsByConversationIdsAction,
	getUsersSolarQueryAction,
	getUsersSolarQueryActionForNewUserCreation,
	setFacetsSortInLs,
	setAgentChannelAndCategory,
	setAgentDataAction,
	getPathListAction,
	getBotLevelTags,
	setBotLevelTags,
	setPathListAction,
	subSetSelectedUserAction,
	setSelectedUserAction,
	setUserListAction,
	setUserListLoadingAction,
	removeSelectedUserFromListAction,
	toggleOnlineStatus,
	updateAllUsersCountAction,
	updateUserInUserListAction,
	updateSelectedUserInUserListAction,
	updateUserListAndSelectedUserAction,
	getSentimentData,
	getSentimentAttributeIdFromGetFlowAttributes,
	setSentimentAttributeDataAction,
	setUserLevelTagsAction,
	markConversationAsReadOrUnread,
	removeUserFromListAction,
	getMessageSearchTagFromLs,
	getMessageSearchQueryFromLs,
	setMessageSearchQueryToLs,
	removeShortcutQuickFilterFromLs,
	getShortcutQuickFilterFromLs,
	setShortcutQuickFilterToLs,
} = messageListActions;

export const {
	blockUserAction,
	getUserMessagesAction,
	fetchLastMessageAction,
	joinConversationAction,
	markToTrainAction,
	resolveConversationAction,
	setStateMessageOverviewAction,
	pendingResolutionAction,
	transferChatToAgentAction,
	getAgentsByCategoryAction,
	setEmailTranscriptAction,
	updateUserNameAction,
	checkForTranslationAndSendMessageAction,
	sendMessageAction,
	getLiveChatConfigAction,
	setPathVariablesAction,
	startConversationAction,
	inviteToGroupConversationAction,
	getTranslatedMessagesAction,
	setTranslateIntegrationAction,
	updateMessagesWithTransaltedTextAction,
	setTranslationStatusOfMessagesAction,
	setTranslationStatusOfAgentAction,
	setEndUserTranslationLanguageAction,
	setIsAutoTranslateEnabledInLiveChatAction,
	setUserMessagesAction,
	updateLastTranslatedMessageStateAction,
	updateMessagesPageNoAction,
	updateLastVisitedRowAction,
	resetMessagesPaginationDetailsActions,
	markConversationStarredAndUnstarredAction,
	setSmartAssistEnablementAction,
	generateConversationSummaryAction,
	setSmartAssistOpenAction,
	setDraftMessageByConversationId,
	getDraftMessage,
	removeDraftMessageByConversationId,
	setAgentIdAction,
	getUserAttributesAction,
	setUserAttributesLoading,
	setUserAttributesError,
	setUserAttributesAction,
} = messageOverviewActions;

export const { updateUserDetailsAction, getLabelsAudit, showRightPanel, shareRecommendedProducts } =
	messageDetailsActions;

export const resetAllMessagesDataAction = () => (dispatch: any) => {
	dispatch(resetAllMessagesData());
};

export const {
	getLiteFacetsSortFromLs,
	setLiteFacetsSortInLs,
	getLiteQuickFiltersFromLS,
	setLiteQuickFiltersInLs,
	removeLiteFacetsSortFromLs,
	removeLiteQuickFiltersFromLS,
	getLiteFacetsSortFromLsMyConversation,
	removeLiteFacetsSortFromLsMyConversation,
	setLiteFacetsSortInLsMyConversation,
	setShortcutQuickFilterLiteToLs,
	getShortcutQuickFilterLiteFromLs,
	removeShortcutQuickFilterLiteFromLs,
	setShortcutQuickFilterLiteToLsMyConversation,
	getShortcutQuickFilterLiteFromLsMyConversation,
	removeShortcutQuickFilterLiteFromLsMyConversation,
} = messageLiteListActions;

export const {
	changeCurrentOneviewScreenTo,
	setPostsListAndOverviewData,
	setSelectedPostAction,
	updateSelectedPostAction,
	updateSelectedPostInPostListAction,
	setPostsLoadingAction,
	getPaginatedPostsAction,
	getPaginatedPostsBySearchTermAction,
	setPostList,
	setUnreadPostIdCountMapAction,
	incrementTotalCommentsCountForThePostAction,
	setViewStitchedUserCommentAction,
	setInstanceBotRefOfThePageAction,
} = postListAndOverviewActions;

export const {
	getCommentsForSelectedPostAction,
	appendCommentsForSelectedPost,
	discardCurrentComments,
	setCommentsLoadingAction,
	getRepliesForCommentAction,
	updateCommentListAction,
	addCommentOrReplyIntoCommentListAction,
	updatePostedCommentIdsAction,
	disablePrivateReplyAction,
} = postCommentsActions;

export const { getDefaultSort } = messageHelperActions;
