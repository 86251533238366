import React, { FC } from 'react';
import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { Modal as BaseModal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { useTranslation } from 'react-i18next';

import Button from 'components/UI/ENGTButton/ENGTButton';
import Loader from 'components/UI/Loader/Loader';

import { WINDOWS_SCROLL_BAR_CSS } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { isWindows } from 'shared/helpers';
import UploadIcon from 'shared/icons/UploadIcon';

export interface IModalProps {
	children: any;
	closeBtnLabel?: string;
	confirmBtnLabel?: string;
	heading: string | React.ReactNode;
	height?: string;
	isConfirmBtnDisabled?: boolean;
	isLoading?: boolean;
	closeable?: boolean;
	isOpen: boolean;
	overrides?: IObjProps;
	modalBodyCssOverride?: IObjProps;
	modalSubHeading?: any;
	showCancelBtn?: boolean;
	showConfirmBtn?: boolean;
	showStartEnhancerIcon?: boolean;
	footerButtonSize?: keyof SIZE;
	startEnhancerIcon?: any;
	submitType?: 'button' | 'reset' | 'submit';
	longSubHeading?: boolean;
	width?: string;
	footerCss?: any;
	headerCss?: any;
	cancelButtonCss?: any;
	actionButtonCss?: any;
	onClose: () => any;
	onConfirm: () => any;
	isActionBtnLoading?: boolean;
	cancelButtonUsage?: boolean;
	reset?: () => any;
	dataQa?: string;
}

const modalHeaderCss = ($theme: any, modalSubHeading: FC<React.PropsWithChildren<any>> | undefined) => ({
	color: $theme.modalTextColor,
	fontSize: '1.25rem !important',
	fontWeight: '700 !important',
	lineHeight: '1.65rem !important',
	textAlign: 'center',
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: $theme.colors.horizantalDividerColor,
	paddingBottom: modalSubHeading ? '0.5rem' : '1rem',
	marginTop: '1.5rem !important',
	marginRight: '0rem !important',
	marginBottom: '0rem !important',
	marginLeft: '0rem !important',
});

const modalSubHeadingCss = ($theme: any, longSubHeading: boolean) => ({
	lineHeight: '1.25rem',
	fontSize: '0.875rem',
	fontWeight: '400',
	color: $theme.colors.accent50,
	paddingTop: longSubHeading ? '1rem' : '0.5rem',
	marginLeft: longSubHeading ? '1rem' : '0rem',
	marginRight: longSubHeading ? '1rem' : '0rem',
});

const modalBodyCss = ($theme: any) => ({
	color: $theme.modalTextColor,
	paddingLeft: '2rem',
	paddingRight: '2rem',
	maxHeight: '25rem',
	overflow: 'auto',
	...(isWindows() && WINDOWS_SCROLL_BAR_CSS),
});

const modalFooterCss = ($theme: any) => ({
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: 'auto !important',
	marginRight: '0rem !important',
	marginBottom: 'auto !important',
	marginLeft: '0rem !important',
	paddingTop: '1rem !important',
	paddingRight: '3.5rem !important',
	paddingBottom: '1rem !important',
	paddingLeft: '4rem !important',
	borderTop: `1px solid${$theme.colors.horizantalDividerColor}`,
	borderTopWidth: '1px',
	borderTopStyle: 'solid',
	borderTopColor: $theme.colors.horizantalDividerColor,
});

const modalLoaderCss = (theme: any) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	background: theme.colors.primaryA,
	width: '100%',
	opacity: '0.3',
	height: '100%',
	borderRadius: '1rem',
	zIndex: theme.zIndex300,
});

const cancelButton = () => ({
	marginRight: '1rem !important',
	width: '20%',
});

const actionButton = () => ({
	minWidth: '20%',
});

const modalOverrideProps = ({
	height,
	width,
	theme,
	overrides,
}: {
	height?: string;
	width?: string;
	theme: any;
	overrides?: IObjProps;
}) => ({
	Root: {
		style: {
			zIndex: theme.zIndex300,
		},
	},
	Close: {
		style: {
			borderRadius: '50%',
			height: '3rem',
			width: '3rem',
			right: '1rem',
			top: '1rem',
			background: `${theme.modalCloseBgColor} !important`,
			stroke: `${theme.modalCloseStrokeColor} !important`,
			color: `${theme.modalCloseStrokeColor} !important`,
		},
	},
	Dialog: {
		style: {
			backgroundColor: theme.modalPrimaryBackground,
			borderTopRightRadius: '1rem',
			borderTopLeftRadius: '1rem',
			borderBottomLeftRadius: '1rem',
			borderBottomRightRadius: '1rem',
			paddingRight: '0rem',
			paddingLeft: '0rem',
			width,
			height,
		},
	},
	Backdrop: {
		style: {
			...overrides?.Backdrop,
		},
	},
});

function ModalWithSections(props: IModalProps) {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common']);

	const {
		children,
		closeBtnLabel = t('common:cancel'),
		confirmBtnLabel = t('common:confirm'),
		heading,
		height,
		isOpen,
		isConfirmBtnDisabled,
		isLoading,
		closeable,
		width,
		overrides = {},
		modalBodyCssOverride = {},
		modalSubHeading,
		longSubHeading,
		showCancelBtn,
		showConfirmBtn,
		showStartEnhancerIcon,
		footerButtonSize,
		startEnhancerIcon,
		submitType = 'button',
		onClose,
		onConfirm,
		footerCss = modalFooterCss(theme),
		headerCss = modalHeaderCss(theme, modalSubHeading),
		actionButtonCss = actionButton(),
		cancelButtonCss = cancelButton(),
		isActionBtnLoading = false,
		cancelButtonUsage = false,
		dataQa,
		reset,
	} = props;

	const ModalLoader = () =>
		isLoading ? (
			<div className={css(modalLoaderCss(theme))}>
				<Loader loaderBlockHeight='100%' />
			</div>
		) : (
			<></>
		);

	return (
		<>
			<BaseModal
				onClose={onClose}
				closeable={closeable}
				isOpen={isOpen}
				autoFocus={false}
				overrides={modalOverrideProps({ height, width, theme, overrides })}
			>
				<ModalLoader />
				<ModalHeader className={css(headerCss)}>
					{heading}
					{modalSubHeading && (
						<div className={css(modalSubHeadingCss(theme, longSubHeading || false))}>{modalSubHeading}</div>
					)}
				</ModalHeader>
				<ModalBody
					className={css({
						...modalBodyCss(theme),
						...modalBodyCssOverride,
					})}
				>
					{children}
				</ModalBody>
				{confirmBtnLabel && closeBtnLabel && (
					<ModalFooter className={css(footerCss)}>
						{showCancelBtn ? (
							<Button
								kind={KIND['secondary']}
								size={footerButtonSize || SIZE.large}
								onClick={cancelButtonUsage ? reset : onClose}
								label={closeBtnLabel}
								className={css(cancelButtonCss)}
								dataQa={`secondary-${dataQa}`}
							/>
						) : (
							<></>
						)}

						{showConfirmBtn ? (
							showStartEnhancerIcon ? (
								<Button
									type={submitType}
									kind={KIND['primary']}
									size={footerButtonSize || SIZE.large}
									label={confirmBtnLabel}
									className={css(actionButtonCss)}
									startEnhancer={startEnhancerIcon}
									isDisabled={isConfirmBtnDisabled}
									onClick={onConfirm}
									dataQa={`primary-${dataQa}`}
								/>
							) : (
								<Button
									type={submitType}
									kind={KIND['primary']}
									size={footerButtonSize || SIZE.large}
									label={confirmBtnLabel}
									className={css(actionButtonCss)}
									isDisabled={isConfirmBtnDisabled}
									onClick={onConfirm}
									isLoading={isActionBtnLoading}
									dataQa={`primary-${dataQa}`}
								/>
							)
						) : (
							<></>
						)}
					</ModalFooter>
				)}
			</BaseModal>
		</>
	);
}

ModalWithSections.defaultProps = {
	heading: 'Modal heading',
	isLoading: false,
	isOpen: false,
	showCancelBtn: true,
	showConfirmBtn: true,
	showStartEnhancerIcon: false,
	startEnhancerIcon: <UploadIcon />,
	width: '40rem',
};

export default ModalWithSections;
