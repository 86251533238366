import React, { Suspense } from 'react';

import UserProfileHeader from 'components/UserProfileHeader/UserProfileHeader';

import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import Loader from '../Loader/Loader';

type TwoColumnLayoutProps = {
	children?: any;
};

const TwoColumnLayout = ({ children }: TwoColumnLayoutProps) => {
	const { isSmallDesktopScreen } = useResponsiveSize();

	return (
		<>
			{!isSmallDesktopScreen && <UserProfileHeader />}
			<Suspense fallback={<Loader />}>{children}</Suspense>
		</>
	);
};

export default TwoColumnLayout;
