import { lazy } from 'react';
import { useSelector } from 'react-redux';

import { ENGATI_ROLES } from 'shared/consts/consts';

import { isWhatsappAcceleratorTrialEnabled } from 'store/App/User';
import { RootState } from 'store/withReducer';

import { TwoColumnRouteLinks } from 'router/links/TwoColumnRouteLinks';

let WAAccNudges: any;

const WANudgesOnLogin = () => {
	const isWhatsappAcceleratorTrial = useSelector(isWhatsappAcceleratorTrialEnabled);

	const currentPathName = window.location.pathname;
	const hasWaQuickGuideInCurrentPath = currentPathName.includes(TwoColumnRouteLinks.waQuickNavigator);

	const isAgent = useSelector(
		(state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT
	);

	if (isWhatsappAcceleratorTrial && hasWaQuickGuideInCurrentPath && !isAgent) {
		if (!WAAccNudges) {
			WAAccNudges = lazy(() => import(/* webpackChunkName: "WAAccNudges" */ './WANudgesModal'));

			return <></>;
		}

		return <WAAccNudges />;
	}

	return <></>;
};
export default WANudgesOnLogin;
