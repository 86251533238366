import React from 'react';

const Form = ({ children, onSubmit, ...props }: { children: React.ReactNode; [x: string]: any }) => {
	const preventDefaultFn = (e: any) => e.preventDefault();

	return (
		<form onSubmit={onSubmit || preventDefaultFn} {...props}>
			{children}
			<input type='submit' style={{ display: 'none', visibility: 'hidden', width: 0, height: 0 }} />
		</form>
	);
};

export default Form;
